export const initialAuthModelsState = {
  pk: null,
  fields: {
    has_user_identity_call: null,
  },
};

export const initialActivityModelsState = {
  action: { activity_fields: [] },
  query: { activity_fields: [] },
  trigger: { activity_fields: [] },
};

const initialAppBuilderModelsState = {
  appModel: {
    pk: null,
    fields: {
      name: "",
      icon_url: "",
    },
  },
  authModel: { ...initialAuthModelsState },
  activityModel: { ...initialActivityModelsState },
  userInfoEndPoint: {},
  tokenEndpoint: {},
  actionEndpoint: {},
  triggerEndpoint: {},
  triggerMainEndpoint: {},
  triggerDeleteEndpoint: {},
};

export default initialAppBuilderModelsState;
