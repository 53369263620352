/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import GroupedInfoHeader, {
  ISubInfo,
} from "components/integry-design-system/molecules/grouped-info-header";

import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import CustomTooltip from "components/integry-design-system/molecules/integry-tooltip/integry-tooltip-v2";
import Tag from "components/integry-design-system/atoms/tag";

import http from "utils/http";
import { encodeJSON } from "utils/functions";
import { integrationViewEvents as trackEvent } from "utils/mixpanel";
import useTrack from "hooks/useTrack";
// import TruncateBullets from "images/truncate_bullets.svg";
import OverflowTooltip from "components/common/overflow-tooltip";

import {
  lastPollLabel,
  integrationStatusLabels,
  IIntegration,
} from "legacy-features/integrations/integrations-view/integration-listing";

import exlaimationIconOutline from "images/exclaimation_icon_outline.svg";
import {
  IRun,
  IPollDetails,
} from "legacy-features/integrations/services/interface";
import LastSyncDetails from "legacy-features/integrations/runs-view/last-sync-details";
import { renderApiError } from "legacy-features/integrations/helper-utils";
import { useFeatureFlags } from "context/featureFlagsContext";

import OpenInTabIcon from "images/open-link.svg";

import { Template } from "../../interface";

import "legacy-features/integrations/styles.scss";
import "./styles.scss";

interface Iprops {
  integrationConfig: IIntegration | null | undefined;
  template: Template | null;
  showPollData?: boolean;
  runConfig?: IRun | undefined | null;
}
const RunViewGroupInfoHeader = ({
  showPollData = false,
  integrationConfig,
  template,
  runConfig,
}: // setlastSyncDetailModaVisiblity,
Iprops): JSX.Element => {
  const [showlastSyncDetailModal, setlastSyncDetailModaVisiblity] =
    useState(false);
  const [pollPayload, setPollPayload] = useState<
    IPollDetails | null | undefined
  >();
  const pollsListingFlag = useFeatureFlags({ flag: "polls-listing" });
  const isPollBasedIntegration =
    !!integrationConfig?.lastPollId && !pollsListingFlag;
  const { track } = useTrack();
  useEffect(() => {
    if (isPollBasedIntegration && showPollData) {
      fetchPollPayload();
    }
  }, []);

  const fetchPollPayload = (): void => {
    const uri = `/api/v1/templates/${template?.id}/integrations/${integrationConfig?.integrationId}/polls/latest/`;
    http
      .get(uri)
      .then((res) => {
        let payloadData: IPollDetails | null | undefined = null;
        const rawPayload = res.data;
        const {
          latest_poll_page = {},
          start_time,
          object_name = "",
        } = rawPayload;

        const { response = {} } = latest_poll_page;
        const {
          parsed_headers,
          parsed_object_output,
          parsed_request_template,
          parsed_response_template,
          parsed_url,
          remote_response,
          http_verb: request_http_verb,
          request_template,
          response_headers,
          response_code,
          error: lastPollError,
        } = response;

        payloadData = {
          objectName: object_name,
          responseCode: response_code,
          activityType: rawPayload.activity_type,
          appName: rawPayload.app_name,
          lastPollStartTime: start_time,
          lastPollError,
          integrationBlocked: integrationConfig
            ? integrationStatusLabels[integrationConfig.integrationStatus] ===
              "Blocked"
            : true,
          payload: {
            parsedUrl: parsed_url,
            parsedHeaders: encodeJSON(parsed_headers),
            parsedObjectOutput: encodeJSON(parsed_object_output),
            parsedRequestTemplate: encodeJSON(parsed_request_template),
            parsedResponseTemplate: parsed_response_template,
            remoteResponse: encodeJSON(remote_response),
            requestTemplate: encodeJSON(request_template),
            responseHeaders: encodeJSON(response_headers),
            requestHttpVerb: (request_http_verb || "").toUpperCase(),
          },
        };
        setPollPayload(payloadData);
      })
      .catch((error) => {
        console.error({ error });
        setPollPayload(null);
        renderApiError("Fetching Poll Payload Failed.", error.message);
      });
  };

  const lastPollTooltip = (
    <div className="integry-custom-tooltip">
      <div>Last Poll Start</div>
      <div>{integrationConfig?.lastPollTime}</div>
    </div>
  );
  const subInfo: Array<ISubInfo> = [];
  const integrationInfo: Array<ISubInfo> = [];
  if (integrationConfig) {
    const integration: IIntegration = integrationConfig || {};
    const {
      userId,
      lastPollStatus,
      dateCreated,
      dateCreatedFull,
      dateUpdated,
      dateUpdatedFull,
    } = integration;
    const { brandingAppAccount = "" } = integrationConfig;
    integrationInfo.push({
      attributes: [
        {
          label: (
            <div
              style={{
                whiteSpace: "nowrap",
                display: "flex",
                alignItems: "center",
              }}
            >
              User ID
              <IntegryTooltip tooltipText="Unique identity of this user in your app">
                <img
                  src={exlaimationIconOutline}
                  style={{
                    width: "13px",
                    height: "13px",
                    marginLeft: "5px",
                    transform: "rotateZ(180deg)",
                    cursor: "pointer",
                  }}
                  alt="tooltip_heading-"
                />
              </IntegryTooltip>
            </div>
          ),
          render: (
            <div
              style={{
                maxWidth: "200px",
              }}
            >
              <OverflowTooltip text={userId || "N/A"} allowCopy />
            </div>
          ),
        },
        {
          label: (
            <div
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {template?.branding_app.name} Account
            </div>
          ),
          render: (
            <div
              style={{
                maxWidth: "200px",
              }}
            >
              <OverflowTooltip text={brandingAppAccount || "N/A"} allowCopy />
            </div>
          ),
        },
        {
          label: "Created",
          render:
            dateCreated === dateCreatedFull ? (
              <div style={{ width: "max-content" }}>{dateCreated || "N/A"}</div>
            ) : (
              <IntegryTooltip
                hideToolTip={!dateCreatedFull || dateCreatedFull === "N/A"}
                tooltipText={dateCreatedFull}
                placement="bottom"
              >
                <div style={{ width: "max-content" }}>
                  {dateCreated || "N/A"}
                </div>
              </IntegryTooltip>
            ),
        },
        {
          label: "Last Modified",
          render:
            dateUpdated === dateUpdatedFull ? (
              <div style={{ width: "max-content" }}>{dateUpdated || "N/A"}</div>
            ) : (
              <IntegryTooltip
                hideToolTip={!dateUpdatedFull || dateUpdatedFull === "N/A"}
                tooltipText={dateUpdatedFull}
                placement="bottom"
              >
                <div style={{ width: "max-content" }}>
                  {dateUpdated || "N/A"}
                </div>
              </IntegryTooltip>
            ),
        },
        ...(showPollData && isPollBasedIntegration
          ? [
              {
                label: "Last Poll Status",
                render: (
                  <div
                    className="flex-row flex-gap-10px"
                    style={{ marginTop: "-4px", minWidth: "max-content" }}
                  >
                    <CustomTooltip
                      tooltipContent={lastPollTooltip}
                      tooltipId="last-poll-time"
                      hideToolTip={!showPollData && isPollBasedIntegration}
                    >
                      <div>
                        <Tag
                          label={
                            lastPollLabel[lastPollStatus || "N/A"] ||
                            lastPollStatus ||
                            "No runs as yet"
                          }
                          type="run"
                        />
                      </div>
                    </CustomTooltip>
                    <div
                      className="attribute--view_detail_btn"
                      onClick={() => {
                        fetchPollPayload();
                        setlastSyncDetailModaVisiblity(true);
                        track(trackEvent.VIEW_POLL_DETAILS.name, {
                          [trackEvent.VIEW_POLL_DETAILS.properties
                            .INTEGRATION_ID]: integrationConfig.integrationId,
                          [trackEvent.VIEW_POLL_DETAILS.properties
                            .NETWORK_CODE]: integrationConfig.errorCode,
                        });
                      }}
                      role="button"
                      tabIndex={-1}
                    >
                      View Details
                    </div>
                  </div>
                ),
              },
            ]
          : []),
      ],
      label: "Integration",
    });

    return (
      <>
        <GroupedInfoHeader
          renderAvatar={() => (
            <>
              <div className="integry-grouped-info--profile__meta flow-info">
                <span className="integry-grouped-info--profile__label">
                  {template?.is_direct_action ? "Action" : "Flow"}
                </span>
                <p className="integry-grouped-info--profile__title title-with-status">
                  <img
                    width={24}
                    src={template?.branding_app.icon_url}
                    alt="app-icon"
                  />

                  {template?.is_direct_action ? (
                    <>
                      <OverflowTooltip
                        style={{ fontSize: "12px" }}
                        text={template?.name || "N/A"}
                      />
                    </>
                  ) : (
                    <>
                      <OverflowTooltip
                        style={{ fontSize: "12px" }}
                        className="integry-link"
                        text={template?.name || "N/A"}
                        onClick={() => {
                          window.open(
                            `/wapp/templates/v6/${template?.id}/edit/data-flow/`,
                            "_blank"
                          );
                        }}
                      />
                      <img src={OpenInTabIcon} alt="open-in-tab" />
                    </>
                  )}
                </p>
              </div>
            </>
          )}
          attributes={integrationInfo[0]?.attributes}
          customStyle={{}}
          subInfo={subInfo}
        />

        {showlastSyncDetailModal && (
          <LastSyncDetails
            showModal={showlastSyncDetailModal}
            setModalVisiblity={setlastSyncDetailModaVisiblity}
            pollDetails={pollPayload}
            integrationConfig={integrationConfig}
          />
        )}
      </>
    );
  }
  return <></>;
};

export default RunViewGroupInfoHeader;
