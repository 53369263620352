import { createApi } from "@reduxjs/toolkit/query/react";
import { integryBaseQuery } from "utils/http";

interface TestCodeStepBody {
  code: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: { steps: any };
  version: number;
}

type TestCodeStepResponse = {
  success: boolean;
  response: Record<string, unknown>;
};

export const codeStepApi = createApi({
  reducerPath: "codeStepAPI",
  baseQuery: integryBaseQuery(),
  tagTypes: ["codeStepAPI"],
  endpoints(build) {
    return {
      testCodeStep: build.mutation<TestCodeStepResponse, TestCodeStepBody>({
        query(body) {
          let bodyToSend = body;
          try {
            bodyToSend = typeof body === "string" ? JSON.parse(body) : body;
          } catch (e) {
            console.error(e);
          }
          return {
            url: `/api/test-code-step`,
            method: "POST",
            data: bodyToSend,
          };
        },
      }),
    };
  },
});

export const { useTestCodeStepMutation } = codeStepApi;
