import { Component } from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    const { pathname: thisPathname } = this.props.location;
    const { pathname: prevPathname } = prevProps.location;
    if (thisPathname !== prevPathname) {
      if (window.Appcues) {
        window.Appcues.page();
      }
    }
    const createEndpointPath = /^\/apps\/\d+\/edit\/endpoints\/create\/$/;
    const editEndpointPath = /^\/apps\/\d+\/edit\/endpoints\/\d+\/edit\/$/;
    const createAuthorizationTypePath =
      /^\/apps\/\d+\/edit\/authorizations\/create\/$/;
    const editAuthorizationTypePath =
      /^\/apps\/\d+\/edit\/authorizations\/\d+\/edit\/$/;
    const createActionPath = /^\/apps\/\d+\/edit\/actions\/create\/$/;
    const editActionPath = /^\/apps\/\d+\/edit\/actions\/\d+\/edit\/$/;
    const createTriggerPath = /^\/apps\/\d+\/edit\/triggers\/create\/$/;
    const editTriggerPath = /^\/apps\/\d+\/edit\/triggers\/\d+\/edit\/$/;
    const dataConversionPath = /^\/apps\/v3\/\d+\/edit\/data-conversion\/$/;
    const createDataConversionPath =
      /^\/apps\/v3\/\d+\/edit\/data-conversion\/create\/$/;
    const editDataConversionPath =
      /^\/apps\/v3\/\d+\/edit\/data-conversion\/\d+\/edit\/$/;
    const integrationRun = /integrations\/\d+\/runs\/\?*/;

    const isProhibitedPathname =
      createEndpointPath.test(thisPathname) ||
      createEndpointPath.test(prevPathname) ||
      editEndpointPath.test(thisPathname) ||
      editEndpointPath.test(prevPathname) ||
      createAuthorizationTypePath.test(prevPathname) ||
      createAuthorizationTypePath.test(thisPathname) ||
      editAuthorizationTypePath.test(prevPathname) ||
      editAuthorizationTypePath.test(prevPathname) ||
      createActionPath.test(thisPathname) ||
      createActionPath.test(prevPathname) ||
      editActionPath.test(thisPathname) ||
      editActionPath.test(prevPathname) ||
      createTriggerPath.test(thisPathname) ||
      createTriggerPath.test(prevPathname) ||
      editTriggerPath.test(thisPathname) ||
      editTriggerPath.test(prevPathname) ||
      dataConversionPath.test(prevPathname) ||
      createDataConversionPath.test(prevPathname) ||
      integrationRun.test(prevPathname) ||
      editDataConversionPath.test(prevPathname);

    if (!isProhibitedPathname && this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
