import { ReactElement } from "react";

import CreatableDropdown from "components/integry-design-system/atoms/dropdown/creatable-dropdown/createable-dropdown";
import NavbarControl from "components/integry-design-system/atoms/dropdown/dropdown-controls/navbar-dropdown-control/navbar-control";
import NavbarDropdownMenu from "components/integry-design-system/atoms/dropdown/dropdown-menu/navbar-dropdown-menu/navbar-dropdown-menu";
import PreloadImages from "components/common/preload-images";

import { User } from "types/integry-user";

import gearIcon from "images/navbar-dropdown/gear-icon-hollow.svg";
import helpCenterIcon from "images/navbar-dropdown/help-center.svg";
import lockIcon from "images/navbar-dropdown/lock-icon.svg";
import logoutIcon from "images/navbar-dropdown/logout-icon.svg";
import openLinkImage from "images/open-link-image.svg";
import arrow from "images/flow-steps/grey-arrow.svg";
import blueTick from "images/turbo.v2/blue-tick.svg";
import backCircleIcon from "images/back-circle-icon.svg";
import switchWorkspaceIcon from "images/workspace/switch-workspace.svg";

import "./navbar-dropdown.scss";

interface IProps {
  user: User | undefined;
}

const NavbarDropdown = ({ user }: IProps): ReactElement => {
  const icons = [
    gearIcon,
    helpCenterIcon,
    lockIcon,
    logoutIcon,
    openLinkImage,
    arrow,
    blueTick,
    backCircleIcon,
    switchWorkspaceIcon,
  ];
  return (
    <div>
      <CreatableDropdown
        menuWrapperClass="navbar-dropdown-menu-wrapper"
        renderControl={(props) => (
          <NavbarControl user={user} isMenuOpen={props.isMenuOpen} />
        )}
        renderMenu={(props) => (
          <NavbarDropdownMenu
            closeMenu={props.closeMenu}
            icons={icons}
            user={user}
          />
        )}
      />
      <PreloadImages images={icons} />
    </div>
  );
};

export default NavbarDropdown;
