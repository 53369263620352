/* eslint-disable no-script-url */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ReactElement,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { useWorkspaceAppContext } from "context/workspaceAppContext";

import { debounce } from "lodash";
import config from "utils/config";
import { sdkQueryParams as authParams } from "utils/auth-params";
import { convertDateToLocal } from "utils/functions";
import { track, configureOAuthAppEvents } from "utils/mixpanel";
import WhiteLabelForm2, {
  WhiteLabelFormRenderModes,
} from "components/integry-design-system/organisms/marketplace/apps/whitelabel-form";
import { toast } from "react-toastify";
import { renderApiError } from "components/common/toast-utils";
import {
  IConfigureWhiteLabelAuth,
  WhiteLabelConfigObject,
  WhiteLabelFormFields,
  WhiteLabelStepObject,
} from "./interfaces";
import useWhitelabelAPI from "./apis";

const ConfigureWhiteLabelAuth = (
  props: IConfigureWhiteLabelAuth
): ReactElement => {
  const {
    configureAppObject,
    user,
    bundleId,
    handleClose,
    onEnableWhiteLabel,
    renderMode = WhiteLabelFormRenderModes.MODAL,
  } = props;
  const {
    getWhitelabelConfig,
    saveWhitelabelConfig,
    getWhiteLabelSteps,
    enableDisableWhiteLabelAuth,
  } = useWhitelabelAPI();
  const [isLoading, setIsLoading] = useState(true);
  const [whiteLabelConfig, setWhiteLabelConfig] =
    useState<WhiteLabelConfigObject>();
  const [savingWhiteLabelForm, setSavingWhiteLabelForm] = useState(false);
  const [currentFormData, setCurrentFormData] = useState<WhiteLabelFormFields>({
    client_id: "",
    client_secret: "",
    scopes: "",
  });
  const [stepsData, setStepsData] = useState<WhiteLabelStepObject[]>([]);
  const [enablingOrDisabling, setEnablingOrDisabling] = useState(false);
  const newAuthorizationTypeIdRef = useRef("");
  const initialConfigAppIdRef = useRef(configureAppObject?.id || "");
  const whiteLabelStatusRef = useRef(false);
  const lastEnabledWhiteLabelConfigRef =
    useRef<WhiteLabelConfigObject | null>();
  const defaultWhiteLabelConfigRef = useRef<WhiteLabelConfigObject | null>();

  const { workspaceApp } = useWorkspaceAppContext();

  const mapApiResponseToWhiteLabelConfig = (
    data: any
  ): WhiteLabelConfigObject => {
    const { authorization_type } = data;
    const { app = null } = authorization_type;
    const whiteLabelConfigObj = {
      id: data?.id || "",
      isActive: data.is_active,
      authPassed: data.auth_passed,
      lastEnabledDate: data.last_enabled || "",
      authorizationType: {
        id: authorization_type?.id || "",
        clientId: authorization_type.client_id,
        clientSecret: authorization_type.client_secret,
        scopes: authorization_type.scopes,
        createdDate: authorization_type?.created_date || "",
        lastUpdatedDate: authorization_type?.last_updated_date || "",
      },
    } as WhiteLabelConfigObject;
    if (app) {
      whiteLabelConfigObj.authorizationType.app = {
        id: app.id,
        name: app.name,
        description: app.description,
        icon_url: app.icon_url,
        color: app.color,
        oauthSetupGuideLink: app?.oauth_setup_guide_link,
      };
    }
    return whiteLabelConfigObj;
  };

  const mapWhiteLabelStepsApiResponseToStepsData = (
    data: any
  ): WhiteLabelStepObject[] => {
    let passedSteps = 0;
    const tempStepData = data.map((stepObj, index) => {
      const { payload } = stepObj;
      if (stepObj.status === "OK") {
        passedSteps += 1;
      }
      return {
        status: stepObj.status,
        networkCode: stepObj.network_code,
        payload: {
          parsedResponseTemplate: payload.parsed_response_template,
          parsedRequestTemplate: payload.parsed_request_template,
          requestHttpVerb: payload.request_http_verb,
          requestTemplate: payload.request_template,
          responseHeaders: payload.response_headers,
          remoteResponse: payload.remote_response,
          parsedUrl: payload.parsed_url,
          parsedHeaders: payload.parsed_headers,
          defaultTab: index === 0 ? "REQUEST" : "",
        },
        durationSeconds: stepObj.duration_seconds,
        title: stepObj.title,
        startTime: convertDateToLocal(stepObj.start_time),
        id: stepObj.id,
        responseMessage: stepObj.response_message,
      };
    }) as WhiteLabelStepObject[];
    if (passedSteps === data.length) {
      whiteLabelStatusRef.current = true;
    } else {
      whiteLabelStatusRef.current = false;
    }
    return tempStepData;
  };

  useEffect(() => {
    if (
      configureAppObject &&
      configureAppObject.id &&
      (!whiteLabelConfig ||
        configureAppObject.id !== initialConfigAppIdRef.current)
    ) {
      initialConfigAppIdRef.current = configureAppObject.id;
      setIsLoading(true);
      getWhitelabelConfig(configureAppObject?.id)
        .then((response) => {
          const { data } = response;
          if (data) {
            const newWhiteLabelConfig = mapApiResponseToWhiteLabelConfig(data);
            lastEnabledWhiteLabelConfigRef.current =
              setLastEnabledWhiteLabelConfig(newWhiteLabelConfig);
            defaultWhiteLabelConfigRef.current = { ...newWhiteLabelConfig };
            setWhiteLabelConfig({ ...newWhiteLabelConfig });
            const { authorization_type, auth_passed } = data;
            if (authorization_type.id && auth_passed) {
              newAuthorizationTypeIdRef.current = authorization_type?.id;
            } else {
              newAuthorizationTypeIdRef.current = "";
              setStepsData([]);
            }
          }
          setIsLoading(false);
        })
        .catch((err) => {
          renderApiError("Failed to fetch white-label config", err.message);
          setIsLoading(false);
        });
    }
  }, [configureAppObject?.id]);

  useEffect(() => {
    if (whiteLabelConfig && whiteLabelConfig?.id) {
      setCurrentFormData({
        client_id: whiteLabelConfig?.authorizationType.clientId,
        client_secret: whiteLabelConfig?.authorizationType.clientSecret,
        scopes: whiteLabelConfig?.authorizationType.scopes,
      });
    } else {
      setCurrentFormData({
        client_id: "",
        client_secret: "",
        scopes: whiteLabelConfig?.authorizationType.scopes || "",
      });
    }
  }, [whiteLabelConfig]);

  const resetWhiteLabelConfig = (): void => {
    if (lastEnabledWhiteLabelConfigRef.current) {
      setWhiteLabelConfig({ ...lastEnabledWhiteLabelConfigRef.current });
    } else if (defaultWhiteLabelConfigRef.current) {
      setWhiteLabelConfig({ ...defaultWhiteLabelConfigRef.current });
    }
    whiteLabelStatusRef.current = false;
    setStepsData([]);
  };

  const sdkQueryParams = (
    userEmail: string,
    appKey: string,
    appSecret: string
  ): string => {
    return authParams(userEmail, appKey, appSecret);
  };

  const addAccountFlow = (
    type_id: string,
    provider: string,
    authTypeId: string | number,
    sdkQueryParamString: string,
    callBack: (e) => void
  ): void => {
    let url = "";
    if (type_id === "OAuth2" || type_id === "api-key") {
      url = `${config.microservicesBaseUrl}${provider}/oauth2/splash?${sdkQueryParamString}`;
    } else if (type_id === "OAuth2-1") {
      url = `${config.microservicesBaseUrl}auth/login/${authTypeId}?return_error=true&${sdkQueryParamString}`;
    } else {
      url = `${config.microservicesBaseUrl}auth/login/${authTypeId}?${sdkQueryParamString}`;
    }
    (window as any).top.MyAltWindow = window.open(
      url,
      "Authorization",
      "height=600,width=1024"
    );
    window.addEventListener("message", callBack);
  };

  const fetchWhiteLabelSteps = (
    authorizationTypeId?: string | number
  ): void => {
    if (authorizationTypeId || newAuthorizationTypeIdRef?.current) {
      getWhiteLabelSteps(
        authorizationTypeId || newAuthorizationTypeIdRef?.current
      )
        .then((response) => {
          const { data } = response;
          if (data && data.length > 0) {
            setStepsData(mapWhiteLabelStepsApiResponseToStepsData(data));
          }
        })
        .catch((err) => {
          renderApiError("Failed to fetch steps", err.message);
        });
    }
  };

  const addAccountCallback = (event: any): void => {
    if (event && event.data.user_identity) {
      console.log("event.data", event.data);
      window.removeEventListener("message", addAccountCallback);
      fetchWhiteLabelSteps();
    }
  };

  const setLastEnabledWhiteLabelConfig = (
    whiteLabelConfigObj: WhiteLabelConfigObject
  ): WhiteLabelConfigObject | null => {
    if (
      whiteLabelConfigObj &&
      whiteLabelConfigObj?.id &&
      whiteLabelConfigObj?.authPassed &&
      // whiteLabelConfigObj?.lastEnabledDate
      whiteLabelConfigObj?.authorizationType.createdDate
    ) {
      return { ...whiteLabelConfigObj };
    }
    return null;
  };

  const saveWhitelabelConfigForm = (formData: WhiteLabelFormFields): void => {
    setCurrentFormData(formData);
    track(configureOAuthAppEvents.INITIATE_TEST.name, user, {
      [configureOAuthAppEvents.INITIATE_TEST.properties.APP_ID]:
        configureAppObject.id,
      [configureOAuthAppEvents.INITIATE_TEST.properties.BUNDLE_ID]: bundleId,
      [configureOAuthAppEvents.INITIATE_TEST.properties.CLIENT_ID]:
        formData.client_id,
      [configureOAuthAppEvents.INITIATE_TEST.properties.CLIENT_SECRET]:
        formData.client_secret,
      [configureOAuthAppEvents.INITIATE_TEST.properties.SCOPE]: formData.scopes,
    });
    setSavingWhiteLabelForm(true);
    if (!savingWhiteLabelForm) {
      saveWhitelabelConfig(configureAppObject?.id, formData)
        .then((response) => {
          const { data } = response;
          if (data) {
            setWhiteLabelConfig(mapApiResponseToWhiteLabelConfig(data));
            const { authorization_type } = data;
            newAuthorizationTypeIdRef.current = authorization_type.id;
            const sdkQueryParamsString = sdkQueryParams(
              user.email,
              workspaceApp?.key || "",
              workspaceApp?.secret || ""
            );
            addAccountFlow(
              authorization_type?.type,
              authorization_type?.provider,
              authorization_type.id,
              sdkQueryParamsString,
              addAccountCallback
            );
          }
          setSavingWhiteLabelForm(false);
        })
        .catch((err) => {
          renderApiError("Failed to save white-label config", err.message);
          setSavingWhiteLabelForm(false);
        });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleMixpanelEventforSwitchingAuth = useCallback(
    debounce((value: string, appObj: any): void => {
      switch (value) {
        case "white-label":
          track(configureOAuthAppEvents.SWITCH_WHITELABEL_AUTH.name, user, {
            [configureOAuthAppEvents.SWITCH_WHITELABEL_AUTH.properties
              .BUNDLE_ID]: bundleId,
            [configureOAuthAppEvents.SWITCH_WHITELABEL_AUTH.properties.APP_ID]:
              appObj.id,
          });
          break;
        default:
          track(configureOAuthAppEvents.SWITCH_DEFAULT_AUTH.name, user, {
            [configureOAuthAppEvents.SWITCH_DEFAULT_AUTH.properties.BUNDLE_ID]:
              bundleId,
            [configureOAuthAppEvents.SWITCH_DEFAULT_AUTH.properties.APP_ID]:
              appObj.id,
          });
          break;
      }
    }, 1000),
    []
  );

  const enableWhiteLabelConfig = (): void => {
    if (!enablingOrDisabling) {
      setEnablingOrDisabling(true);
      enableDisableWhiteLabelAuth(whiteLabelConfig?.id, "enable")
        .then((response) => {
          const { data } = response;
          if (data) {
            const newWhiteLabelConfig = mapApiResponseToWhiteLabelConfig(data);
            lastEnabledWhiteLabelConfigRef.current =
              setLastEnabledWhiteLabelConfig(newWhiteLabelConfig);
            setWhiteLabelConfig({ ...newWhiteLabelConfig });
            closeWhiteLabelModal();
            setEnablingOrDisabling(false);
            if (onEnableWhiteLabel) {
              onEnableWhiteLabel();
            }
            toast.success(
              <p className="toast-text">White-labeled auth is enabled!</p>
            );
          } else {
            setEnablingOrDisabling(false);
            renderApiError("Oops something went wrong", "");
          }
        })
        .catch((err) => {
          setEnablingOrDisabling(false);
          renderApiError("Failed to enable", err.message);
        });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const disableWhiteLabelConfig = (): void => {
    if (
      lastEnabledWhiteLabelConfigRef.current &&
      lastEnabledWhiteLabelConfigRef.current?.isActive
    ) {
      if (!enablingOrDisabling) {
        setEnablingOrDisabling(true);
        enableDisableWhiteLabelAuth(
          lastEnabledWhiteLabelConfigRef.current?.id,
          "disable"
        )
          .then((response) => {
            const { data } = response;
            if (data) {
              const newWhiteLabelConfig =
                mapApiResponseToWhiteLabelConfig(data);
              lastEnabledWhiteLabelConfigRef.current =
                setLastEnabledWhiteLabelConfig(newWhiteLabelConfig);
              setWhiteLabelConfig({ ...newWhiteLabelConfig });
              setEnablingOrDisabling(false);
              toast.success(
                <p className="toast-text">Default auth is enabled!</p>
              );
            } else {
              setEnablingOrDisabling(false);
              renderApiError("Oops something went wrong", "");
            }
          })
          .catch((err) => {
            setEnablingOrDisabling(false);
            renderApiError("Failed to enable", err.message);
          });
      }
    }
  };

  const closeWhiteLabelModal = (): void => {
    resetWhiteLabelConfig();
    handleClose();
  };

  return (
    <div className="configure-white-label-auth">
      <>
        <WhiteLabelForm2
          isLoading={isLoading}
          configureAppObject={configureAppObject}
          whiteLabelConfig={whiteLabelConfig}
          renderMode={renderMode}
          onSaveWhitelabelConfigForm={saveWhitelabelConfigForm}
          isSaving={savingWhiteLabelForm}
          currentFormData={currentFormData}
          handleClickClose={closeWhiteLabelModal}
          testPassed={whiteLabelStatusRef.current}
          stepsData={stepsData}
          user={user}
          bundleId={bundleId}
          handleClickEnable={enableWhiteLabelConfig}
          lastEnabledWhiteLabelConfig={lastEnabledWhiteLabelConfigRef.current}
        />
      </>
    </div>
  );
};

export default ConfigureWhiteLabelAuth;
