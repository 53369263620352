import {
  REQUEST_AUTHORIZATION_TYPES,
  RECEIVE_AUTHORIZATION_TYPES,
  REMOVE_AUTHORIZATION_TYPE,
} from "../actions/authorization-types";

export const authorizationTypesByApp = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_AUTHORIZATION_TYPES:
    case RECEIVE_AUTHORIZATION_TYPES:
    case REMOVE_AUTHORIZATION_TYPE:
      return Object.assign({}, state, {
        [action.app_id]: authorizationTypes(state[action.app_id], action),
      });
    default:
      return state;
  }
};

const authorizationTypes = (
  state = {
    isFetching: false,
    fetchCount: 0,
    items: [],
  },
  action
) => {
  switch (action.type) {
    case REQUEST_AUTHORIZATION_TYPES:
      return Object.assign({}, state, {
        isFetching: true,
        fetchCount: state.fetchCount + 1,
      });
    case RECEIVE_AUTHORIZATION_TYPES:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.authorization_types,
      });
    case REMOVE_AUTHORIZATION_TYPE:
      return {
        ...state,
        items: state.items.filter(
          (authorization_type) =>
            String(authorization_type.id) !==
            String(action.authorization_type_id)
        ),
      };
    default:
      return state;
  }
};
