import React from "react";
import ReactDOM from "react-dom";
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/addon/search/searchcursor";
import "codemirror/addon/display/placeholder";

import TagPill from "./tag-pill";

const widgetRe = /({step.*?})|({{.*?}})/;

class CodeMirrorTextArea extends React.Component {
  state = {
    value: "",
  };
  ref = React.createRef();
  nodes = [];
  handleChange = (editor, data, value) => {
    const { onChange } = this.props;

    const { from } = data;
    const fromStartOfLine = { ...from, ch: 0 };
    this.replaceShortcode(editor, fromStartOfLine);
    if (onChange) {
      onChange(value);
    }
  };
  handleLoad = (editor) => {
    this.replaceShortcode(editor, { line: 0, ch: 0 });
    setTimeout(() => editor.refresh(), 0);
  };
  handleUpdate = (editor) => {
    this.renderReactNodes(editor);
  };

  handleCursorActivity = (editor) => {
    const { setCaretPosition } = this.props;
    const { line, ch } = editor.getCursor();
    const caretPosition = this.getActualCursorLocation(editor, line, ch);
    setCaretPosition(caretPosition);
  };

  getActualCursorLocation = (editor, line, ch) => {
    let caretPosition = 0;
    for (let i = 0; i < line; i++) {
      const lineInfo = editor.lineInfo(i);
      caretPosition = caretPosition + lineInfo.text.length + 1;
    }
    caretPosition = caretPosition + ch;
    return caretPosition;
  };

  replaceShortcode = (editor, loc) => {
    const cursor = editor.getSearchCursor(widgetRe, loc, {
      multiline: "disable",
    });
    while (cursor.findNext()) {
      const markers = editor.findMarks(cursor.from(), cursor.to());
      if (markers.length === 0) {
        const from = cursor.from();
        const to = cursor.to();
        // extract if checkbox is selected or not

        const match = editor.getRange(from, to);
        const idMatches = match.match(widgetRe);
        const node = document.createElement("div");
        const tagStartIndex = this.getActualCursorLocation(
          editor,
          from.line,
          from.ch
        );
        const tagEndtIndex = this.getActualCursorLocation(
          editor,
          to.line,
          to.ch
        );
        node.setAttribute("class", "tag");
        node.setAttribute("data-wid", idMatches[0]);
        node.setAttribute("data-from", tagStartIndex);
        node.setAttribute("data-to", tagEndtIndex);
        this.nodes.push(node);
        editor.markText(from, to, { replacedWith: node });
      }
    }
  };
  renderReactNodes = (editor) => {
    const { formikSteps, flattenTree, name, includesAppendedTags } = this.props;
    let needsRefresh = false;
    this.nodes = this.nodes.filter((n) => {
      if (n.ownerDocument.body.contains(n)) {
        if (!n.dataset.__react_rendered) {
          const widgetId = n.dataset.wid;
          const tagStartIndex = n.dataset.from;
          const tagEndIndex = n.dataset.to;
          n.dataset.__react_rendered = "1";
          ReactDOM.render(
            <TagPill
              tag={widgetId}
              formikSteps={formikSteps}
              flattenTree={flattenTree}
              tagIndex={widgetId}
              removeTag={() => this.removeTag(tagStartIndex, tagEndIndex)}
              name={name}
              wid={widgetId}
              includesAppendedTags={includesAppendedTags}
            />,
            n
          );
          needsRefresh = true;
        }
        return true;
      } else {
        if (n.dataset.__react_rendered) {
          ReactDOM.unmountComponentAtNode(n);
        }
        return false;
      }
    });
    if (needsRefresh) {
      editor.refresh();
    }
  };

  removeTag = (tagStartIndex, tagEndIndex) => {
    const { value, setFieldValue, codeMirrorRef } = this.props;

    const newValue =
      value.slice(0, tagStartIndex) + value.slice(tagEndIndex, value.length);

    setFieldValue(newValue);
    if (codeMirrorRef.current) {
      //we need to refereh code mirror after  updating tag value.
      setTimeout(() => codeMirrorRef.current.editor.refresh(), 0);
    }
  };
  render() {
    const {
      value,
      setFieldValue,
      codeMirrorRef,
      validate,
      placeholder,
      onChange,
    } = this.props;
    return (
      <CodeMirror
        value={value}
        onChange={this.handleChange}
        onUpdate={this.handleUpdate}
        editorDidMount={this.handleLoad}
        onCursorActivity={this.handleCursorActivity}
        onBeforeChange={(editor, data, value) => {
          setFieldValue(value);
          validate && validate(value);
          if (onChange) {
            onChange(value);
          }
        }}
        ref={codeMirrorRef}
        options={{
          placeholder: placeholder || "Enter Value",
          lineWrapping: true,
        }}
      />
    );
  }
}

export default CodeMirrorTextArea;
