/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from "react-toastify";
import { sdkQueryParams as authParams } from "utils/auth-params";
import config from "utils/config";
import redCrossIcon from "images/red-cross-icon.svg";

export const templateErrorMessage = (title: string, msg: string): any => {
  return (
    <div>
      <div className="lable error">{title}</div>
      <div className="message">{msg}</div>
    </div>
  );
};

export const templateDefaultMessage = (title: string, msg: string): any => {
  return (
    <div>
      <div className="lable default-toast">{msg}</div>
      <div className="message default-toast">{title}</div>
    </div>
  );
};

const customCloseButton = (): any => {
  return <img className="custom-close-toast" src={redCrossIcon} alt="Close" />;
};

export const renderApiError = (title: string, msg: string): void => {
  toast.error(templateErrorMessage(title, msg), {
    autoClose: 5000,
    position: "bottom-left",
    closeButton: customCloseButton(),
  });
};

export const renderDefaultToast = (title: string, msg: string): void => {
  toast.success(templateDefaultMessage(title, msg), {
    autoClose: 5000,
    position: "bottom-left",
    // closeButton: customCloseButton(),
  });
};

export const renderToastErrorByTemplate = (template: any): void => {
  toast.error(template, {
    autoClose: 5000,
    position: "bottom-left",
    closeButton: customCloseButton(),
  });
};

const getErrorStringByCode = (code: string): string => {
  let errorString = "Failed to fetch.";
  if (code === "Error") {
    errorString = "Network Error. Please try again later.";
  } else if (code === "401") {
    errorString =
      "Unauthorized client. Please try using another authentication.";
  } else if (code === "502" || code === "500") {
    errorString = "Server Error. Please try again later.";
  }
  return errorString;
};

export const errorMessageByCodeTemplate = (code: string): any => {
  return (
    <div>
      <div className="lable error">{getErrorStringByCode(code)}</div>
      <div className="message">
        Error: {code === "Error" ? "Network Error" : code}
      </div>
    </div>
  );
};

export const sdkQueryParams = (
  userEmail: string,
  appKey: string,
  appSecret: string
): string => {
  return authParams(userEmail, appKey, appSecret);
};

export const addAccountFlow = (
  type_id,
  provider,
  authTypeId,
  sdkQueryParamString,
  callBack
): void => {
  let url = "";
  if (type_id === "OAuth2" || type_id === "api-key") {
    url = `${config.microservicesBaseUrl}${provider}/oauth2/splash?${sdkQueryParamString}`;
  } else if (type_id === "OAuth2-1") {
    url = `${config.microservicesBaseUrl}auth/login/${authTypeId}?return_error=true&${sdkQueryParamString}`;
  } else {
    url = `${config.microservicesBaseUrl}auth/login/${authTypeId}?${sdkQueryParamString}`;
  }
  (window as any).top.MyAltWindow = window.open(
    url,
    "Authorization",
    "height=600,width=1024"
  );
  window.addEventListener("message", callBack);
};

export const getActivitytagObjectByType = (activityTypeArg): string => {
  let tagObjName = "";
  if (activityTypeArg === "ACTION") {
    tagObjName = "actionTags";
  } else if (activityTypeArg === "TRIGGER") {
    tagObjName = "triggerTags";
  } else if (activityTypeArg === "QUERY") {
    tagObjName = "queryTags";
  }
  return tagObjName;
};

export const getBasicSectionStatus = (
  appBuilder: any,
  section,
  isSectionComplete = false
): any => {
  const { formStatus } = appBuilder;
  const basicInfoData = formStatus.BASIC_INFO;
  const basicSectionData = {
    BASIC_INFO: { ...basicInfoData },
    isWizardEnabled:
      section === "WIZARD" && isSectionComplete
        ? false
        : appBuilder.isWizardEnabled,
    isBasicSectionComplete:
      section === "BASIC_INFO" && isSectionComplete
        ? true
        : appBuilder.isBasicSectionComplete,
    isAuthorizationSectionComplete:
      section === "AUTHORIZATION" && isSectionComplete
        ? true
        : appBuilder.isAuthorizationSectionComplete,
    isActivitySectionComplete:
      section === "ACTIVITIES" && isSectionComplete
        ? true
        : appBuilder.isActivitySectionComplete,
    isAppFlowComplete:
      section === "ACTIVITIES" && isSectionComplete
        ? true
        : appBuilder.isAppFlowComplete,
    firstAuthID: appBuilder.firstAuthID,
    firstActionID: appBuilder.firstActionID,
    firstQueryID: appBuilder.firstQueryID,
    firstTriggerID: appBuilder.firstTriggerID,
  };
  return basicSectionData;
};
