import { toast } from "react-toastify";

const showToast = (title: string, message: string | null = null): void => {
  toast(
    <div>
      <div className="lable">{title}</div>
      {message && <div className="message">{message}</div>}
    </div>,
    {
      position: "bottom-left",
      className: "cutom-toast-container",
      autoClose: 3000,
    }
  );
};
export const showErrorToast = (
  title: string,
  message: string | null = null
): void => {
  toast.error(
    <div>
      <div className="lable">{title}</div>
      {message && <div className="message">{message}</div>}
    </div>,
    {
      position: "bottom-left",
      className: "cutom-toast-container",
      autoClose: 3000,
    }
  );
};

export const showWarningToast = (
  title: string,
  message: string | null = null
): void => {
  toast.warn(
    <div>
      <div className="lable">{title}</div>
      {message && <div className="message">{message}</div>}
    </div>,
    {
      position: "bottom-left",
      className: "cutom-toast-container",
      autoClose: 3000,
    }
  );
};

export const showSuccessToast = (
  title: string,
  message: string | null = null
): void => {
  toast.success(
    <div>
      <div className="lable">{title}</div>
      {message && <div className="message">{message}</div>}
    </div>,
    {
      position: "bottom-left",
      className: "cutom-toast-container",
      autoClose: 3000,
    }
  );
};

export default showToast;
