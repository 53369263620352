/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from "@reduxjs/toolkit/query/react";
import http, { integryBaseQuery } from "utils/http";
import config from "utils/config";

const useSDKPreviewerAPI = (): any => {
  const fetchSDKConfig = (bundleId: string | number): any => {
    return http.get(`/api/bundles/${bundleId}/`);
  };

  const saveSDKConfig = (bundleId: string | number, payload: any): any => {
    return http.put(`/api/bundles/${bundleId}/`, payload);
  };

  const fetchPayloads = (): any => {
    return http.get(`/api/v1/action-payloads/`);
  };

  const createPayload = (payload: any): any => {
    return http.post(`/api/v1/action-payloads/`, payload);
  };

  const updatePayload = (payloadId: number, payload: any): any => {
    return http.patch(`/api/v1/action-payloads/${payloadId}`, payload);
  };

  const saveActionConfig = (templateId: number, payload: any): any => {
    return http.patch(
      `/api/v2/templates/${templateId}/update_action_config/`,
      payload
    );
  };

  const saveTagInConfig = (payload: any): any => {
    return http.post(`/api/v1/sdk-tags/`, payload);
  };

  const delTagFromConfig = (tagId: number): any => {
    return http.delete(`/api/v1/sdk-tags/${tagId}/`);
  };

  const fetchFieldConfig = (
    templateId: number,
    stepId: number,
    fieldId: number
  ): any => {
    return http.get(
      `api/v1/templates/${templateId}/steps/${stepId}/fields/${fieldId}/`
    );
  };

  const saveFieldConfig = (
    templateId: number,
    stepId: number,
    fieldId: number,
    payload: any
  ): any => {
    return http.patch(
      `api/v1/templates/${templateId}/steps/${stepId}/fields/${fieldId}/`,
      payload
    );
  };

  return {
    fetchSDKConfig,
    saveSDKConfig,
    createPayload,
    updatePayload,
    fetchPayloads,
    saveActionConfig,
    saveTagInConfig,
    delTagFromConfig,
    fetchFieldConfig,
    saveFieldConfig,
  };
};

export default useSDKPreviewerAPI;

export const actionPayloadsApi = createApi({
  reducerPath: "actionPayloadsApi",
  baseQuery: integryBaseQuery({
    baseUrl: config.apiBaseURL || "",
  }),
  tagTypes: ["actionPayloadsApi"],
  endpoints(build) {
    return {
      listPayload: build.query<any, null>({
        query: () => ({
          url: "/api/v1/action-payloads/",
          method: "get",
        }),
        providesTags: ["actionPayloadsApi"],
      }),
    };
  },
});

export const { useListPayloadQuery } = actionPayloadsApi;
