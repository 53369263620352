/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useEffect, ReactElement, useRef } from "react";
import { Helpers, IntegryJS } from "@integry/sdk";
import moment from "moment";
import useOnClickOutside from "utils/use-onclick-outside";

import lang from "utils/lang/en";
import config from "utils/config";

import CLOCK from "images/clock x 16.svg";
import CLOCK_ACTIVE from "images/clock_active.svg";
import M_GREY_DROPDOWN_ACTIVE from "images/m_grey_dropdown_active.svg";
import M_GREY_DROPDOWN from "images/m_grey_dropdown.svg";
import XICON from "images/x_24.svg";
import IntegryTooltip from "components/integry-design-system/molecules/integry-tooltip/integry-tooltip-v2";
import getVersions from "./api";

import "./styles.scss";

interface IVersion {
  version: string | number;
  title: string;
}

const PreviewSetup = ({
  flow,
  integration,
  app,
  user,
  onClose,
}): React.ReactElement => {
  const [selectedVersion, setSelectedVersion] = useState("");
  const [fetching, setFetching] = useState(false);
  const [versions, setVersions] = useState<IVersion[]>([]);
  let IntegrySDK: IntegryJS;
  useEffect(() => {
    setFetching(true);
    getVersions(integration.integrationId)
      .then((response) => {
        const { data = [] } = response;
        if (data.length > 0) {
          setSelectedVersion(`${data[0].version}`);
          setVersions(data);
          renderSDK(data[0].version);
        } else {
          setSelectedVersion(`-1`);
          setVersions([]);
        }
      })
      .catch((e) => {
        console.log({
          e,
        });
        setSelectedVersion(`-1`);
        setVersions([]);
      })
      .finally(() => {
        setFetching(false);
      });
    return () => {
      if (IntegrySDK) {
        IntegrySDK.destroy();
      }
    };
  }, [app, integration.integrationId, integration.userId]);

  const renderSDK = async (versionId): Promise<null> => {
    if (app && Object.keys(app).length > 0) {
      if (IntegrySDK) {
        IntegrySDK.destroy();
      }
      const username = integration.userId || "";
      const hash = await Helpers.getAuthHash(username, app.secret);
      IntegrySDK = new IntegryJS({
        appKey: app.key,
        hash,
        userId: username,
        userConfig: {
          isReadOnly: true,
        },
      });
      IntegrySDK.renderFlowSetupForm({
        flowId: flow.flowId,
        integrationId: integration.integrationId,
        flowContainerId: "sdk-preview-container",
        versionId,
      });
      IntegrySDK.eventEmitter.on("did-click-close-button", (data) => {
        if (data.isCloseClicked) {
          handleOnClose();
        }
      });
      IntegrySDK.printVersion();
    }
    return null;
  };

  const handleOnClose = (): void => {
    if (onClose) {
      onClose();
    }
  };

  const handleOnSelect = (seletedOptionValue): void => {
    // if (IntegrySDK) {
    //   IntegrySDK.destroy();
    // }
    setSelectedVersion(seletedOptionValue);
    const sdkContainer = document.getElementById("sdk-preview-container");
    if (sdkContainer) {
      sdkContainer.innerHTML = "";
    }
    renderSDK(seletedOptionValue);
  };

  return (
    <div
      className="integrations-preview-setup"
      onClick={() => {
        handleOnClose();
      }}
      aria-hidden="true"
    >
      <div
        className="int-prev-setup__modal"
        onClick={(e) => {
          e.stopPropagation();
        }}
        aria-hidden="true"
      >
        <div className="int-prev-setup__modal--header">
          <div className="int-prev-setup__modal--header__title">
            <h1>{lang.PREVIEW_SETUP_HEADER_TITLE}</h1>
            <img
              className="int-prev-setup__close-cta"
              src={XICON}
              alt="close_ico"
              onClick={() => {
                handleOnClose();
              }}
              aria-hidden="true"
            />
          </div>
          <div className="int-prev-setup__modal--header__subtitle">
            <p>{lang.PREVIEW_SETUP_HEADER_SUBTITLE}</p>
            {!fetching && (
              <div className="version-selector-control">
                <p className="version-selector__label">{`${lang.VERSION}:`}</p>
                <VersionSelectorDropdown
                  selectedValue={
                    versions.length > 0
                      ? selectedVersion
                      : "No versions available"
                  }
                  prefix={
                    versions.length > 1 ? (
                      <img src={CLOCK_ACTIVE} alt="clock" />
                    ) : (
                      <img src={CLOCK} alt="clock" />
                    )
                  }
                  options={versions.map((version, index) => {
                    if (index === 0) {
                      return {
                        value: version.version,
                        label: moment(version.title).format("MMM DD, h:mm A"),
                        // label: `Jun 14, 05:37 AM`,
                        suffix: `(Latest)`,
                      };
                    }
                    return {
                      value: version.version,
                      label: moment(version.title).format("MMM DD, h:mm A"),
                    };
                  })}
                  onOptionSelect={(selectedOptionValue) => {
                    handleOnSelect(selectedOptionValue);
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="int-prev-setup__modal--content">
          <div id="sdk-preview-container" className="sdk-preview-container" />
        </div>
      </div>
    </div>
  );
};

const VersionSelectorDropdown = (props): React.ReactElement => {
  const { selectedValue = "", prefix, options = [], onOptionSelect } = props;
  const [active, setActive] = useState(false);
  const handleActiveState = (stateToggle): void => {
    setActive(stateToggle);
  };
  const getSelectedValueLabel = (v): string => {
    let tempValueToShow = `${v}`;
    options.forEach((option) => {
      const { id, value, label } = option;
      const valueToMatch = `${id || value || "-1"}`;
      if (valueToMatch === `${v}`) {
        tempValueToShow = label;
      }
    });
    return tempValueToShow;
  };
  const DropdownContainer = (): ReactElement => {
    const isMoreThanOne = options.length > 1;
    const menuContainerRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(menuContainerRef, () => {
      handleActiveState(false);
    });
    return (
      <div
        className="version-selector-drpdwn"
        ref={menuContainerRef}
        style={
          isMoreThanOne
            ? {}
            : {
                cursor: "not-allowed",
              }
        }
        onClick={() => {
          if (options.length > 1) {
            handleActiveState(!active);
          }
        }}
        aria-hidden="true"
      >
        {options.length < 2 && (
          <IntegryTooltip
            tooltipId="no-older-version-dropdown"
            placement="top"
            tooltipText={lang.NO_OLDER_VERSION_AVAILABLE}
          >
            <div
              style={{
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                zIndex: "2",
                position: "absolute",
              }}
            />
          </IntegryTooltip>
        )}
        {prefix && (
          <div className="version-selector-drpdwn__prefix">{prefix}</div>
        )}
        <span
          className="version-selector-drpdwn__selected-value"
          style={
            isMoreThanOne
              ? {}
              : {
                  opacity: "0.5",
                }
          }
        >
          {getSelectedValueLabel(selectedValue)}
        </span>
        <img
          className="version-selector-drpdwn__arrow"
          src={options.length > 1 ? M_GREY_DROPDOWN_ACTIVE : M_GREY_DROPDOWN}
          alt="dropdown_arrow"
          style={
            active
              ? {
                  transform: "rotate(180deg)",
                }
              : {}
          }
        />
        {active && (
          <div className="version-selector-drpdwn__options">
            {options.map((option) => {
              const { id, value, label = "", suffix } = option;
              let isSelected = false;
              if (`${selectedValue}` === `${id || value}`) {
                isSelected = true;
              }
              return (
                <div
                  key={id || value}
                  className={`version-selector-drpdwn__option${
                    isSelected
                      ? " version-selector-drpdwn__option--selected"
                      : ""
                  }`}
                  onClick={() => {
                    const selectedOption = id || value || "";
                    if (onOptionSelect && selectedOption) {
                      onOptionSelect(selectedOption);
                    }
                  }}
                  aria-hidden="true"
                >
                  <span>
                    {`${label}`}
                    {suffix && <span>{` ${suffix}`}</span>}
                  </span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };
  return <DropdownContainer />;
};

export default PreviewSetup;
