/* eslint-disable @typescript-eslint/no-explicit-any */
import { User } from "types/integry-user";
import React, { ReactElement } from "react";
import SDKSettingSection from "./sdk-setting-section";
import "./styles.scss";

interface EmbedSettingsProps {
  user: User;
}

const EmbedSettings = (props: EmbedSettingsProps): ReactElement => {
  const { user } = props;

  return (
    <div className="embed-settings-wrap">
      <h2 className="content-heading">Security</h2>
      <SDKSettingSection user={user} />
    </div>
  );
};

export default EmbedSettings;
