/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useRef, useState, useCallback, useEffect } from "react";
import AceEditor from "react-ace";
import usePrevious from "hooks/usePrevious";

import Button from "components/integry-design-system/molecules/button";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-textmate";
import { isValidJson } from "utils/json";
import useOnClickOutside from "utils/use-onclick-outside";
import useDebounce from "hooks/useDebounce";

import TagOptionTree from "../tag-option-tree";

import "./styles.scss";

interface AceEditorProps {
  path?: number[];
  height?: string;
  refProp: any;
  tags?: any;
  includeOnly?: any;
  hideTagButton?: boolean;
  showFormatJson?: boolean;
  controls?: ReactElement;
  title?: string | ReactElement;
  formikSteps?: () => any;
  onChange?: (value: string) => void;
  debounceTime?: number;
  [other: string]: any;
}

const AceEditorTagged = (props: AceEditorProps): ReactElement => {
  const {
    height,
    refProp,
    tags,
    hideTagButton,
    useFlowTags,
    formikSteps = () => ({}),
    appendedCustomTags,
    includeOnly = "",
    mode = "json",
    currentStep = null,
    controls = null,
    templateVersion = null,
    showFormatJson = false,
    value,
    onChange,
    debounceTime = 500,
    path = [],
    ...rest
  } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const [localValue, setLocalValue] = useState<string>(value);
  const [isFocused, setIsFocused] = useState(false);

  const tagDropdownConatinerRef = useRef<HTMLDivElement>(null);

  const previousFildValue = usePrevious(value);

  useEffect(() => {
    if (!isFocused && value !== previousFildValue) {
      // if field value is changed from outside, update local value
      // adding isFocused check to prevent setting local value when user is typing
      setLocalValue(value);
    }
  }, [value, isFocused, previousFildValue]);

  const debouncedOnChange = useDebounce((code: string) => {
    if (onChange) {
      onChange(code);
    }
  }, debounceTime);

  const toggleDropdown = (): void => {
    setShowDropdown((prev) => !prev);
  };

  const closeTagDropdown = (evt): void => {
    if (showDropdown) {
      toggleDropdown();
    }
  };

  const addTagFunc = useCallback(
    (tag): void => {
      if (refProp.current) {
        const aceEditor = refProp.current;
        aceEditor.editor.session.insert(
          aceEditor.editor.getCursorPosition(),
          tag
        );
      }
    },
    [refProp]
  );

  const tagOptionProps = useFlowTags
    ? { formikSteps, appendedCustomTags, includeOnly }
    : { formikSteps, isCustomTag: true, customTags: tags };

  const formatJson = (): void => {
    try {
      const formattedValue = JSON.stringify(JSON.parse(localValue), null, 2);
      setLocalValue(formattedValue);
      debouncedOnChange(formattedValue);
    } catch (e) {
      console.error(e);
    }
  };

  const isJsonValid = isValidJson(value);

  useOnClickOutside(tagDropdownConatinerRef, closeTagDropdown);
  return (
    <div className="ace-editor-tagged-wrapper">
      <div className="ace-editor-tagged--header">
        {props.title ? (
          <div className="ace-editor-tagged--title">{props.title}</div>
        ) : (
          <div />
        )}
        <div className="ace-editor-tagged--controls">
          {controls}
          {mode === "json" && showFormatJson && (
            <div className="format-json">
              <Button
                variation="link"
                action={formatJson}
                disabled={!isJsonValid}
                tooltip="Invalid JSON"
                disableTooltip={isJsonValid}
              >
                Beautify
              </Button>
            </div>
          )}
          {!hideTagButton && (
            <Button variation="link" action={toggleDropdown}>
              Add tag
            </Button>
          )}
        </div>
      </div>

      <div className="ace-editor-tagged">
        <div className="ace-json-viewer">
          <AceEditor
            mode={mode}
            theme="textmate"
            className="datap"
            fontSize={12}
            showGutter
            height={height}
            width="100%"
            wrapEnabled
            showPrintMargin={false}
            setOptions={{
              showLineNumbers: true,
              tabSize: 1,
            }}
            ref={refProp}
            value={localValue}
            onChange={(val) => {
              setLocalValue(val);
              debouncedOnChange(val);
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            {...rest}
          />
        </div>
        {!hideTagButton && (
          <div ref={tagDropdownConatinerRef}>
            <TagOptionTree
              {...tagOptionProps}
              show={showDropdown}
              key={showDropdown.toString()}
              addTag={addTagFunc}
              currentStep={currentStep}
              templateVersion={templateVersion}
              path={path}
            />
          </div>
        )}
      </div>
    </div>
  );
};

AceEditorTagged.defaultProps = {
  height: "210px",
  hideTagButton: false,
};

export default AceEditorTagged;
