import React, { createContext, useContext, useState, ReactNode } from "react";
import { EditorState } from "draft-js";

// type SetEditorType = (prevState: EditorState) => AnyARecord | EditorState;

type EditorStateContextType = {
  editorState: EditorState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setEditorState: (arg: any, srouce?: string) => void;
};

const EditorStateContext = createContext<EditorStateContextType | undefined>(
  undefined
);

type EditorStateProviderProps = {
  children: ReactNode;
};

export const EditorStateProvider: React.FC<EditorStateProviderProps> = ({
  children,
}) => {
  const [editorState, setEditorStatefn] = useState(EditorState.createEmpty());

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setEditorState = (arg: any, srouce?: string): void => {
    // if (arg?.getCurrentContent)
    // console.log(srouce || "def", arg?.getCurrentContent().getPlainText());
    setEditorStatefn(arg);
  };

  return (
    <EditorStateContext.Provider value={{ editorState, setEditorState }}>
      {children}
    </EditorStateContext.Provider>
  );
};

export const useEditorState = (): EditorStateContextType => {
  const context = useContext(EditorStateContext);
  if (context === undefined) {
    throw new Error(
      "useEditorState must be used within an EditorStateProvider"
    );
  }
  return context;
};

export default EditorStateContext;
