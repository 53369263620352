import React from "react";

// import InviteCta from './common/invite-cta';
import Header from "./main-navigation";
// import Footer from '../footer';

const DashboardWrapper = ({
  user,
  children,
  className = "",
  isLite = false,
}) => {
  const childs = React.useMemo(() => children, [children]);
  return (
    <div className={className}>
      {!isLite && <Header user={user} />}
      {childs}
    </div>
  );
};

export default React.memo(DashboardWrapper);
