import { createApi } from "@reduxjs/toolkit/query/react";
import { integryBaseQuery } from "utils/http";
import { TemplateType } from "../interface/templateType";

interface TemplateNameResponse {
  unique_template_name: string;
}

interface TemplateNameValidationRequest {
  name: string;
  template_id: number | string;
  branding_app_id: number | string;
  machineName?: string;
  type: TemplateType;
}

interface TemplateNameValidationResponse {
  is_name_unique: boolean;
}

interface TemplateNameValidateAndUpdateResponse {
  success: boolean;
}

export const useFlowname = createApi({
  reducerPath: "flowName",
  baseQuery: integryBaseQuery(),
  tagTypes: ["flowName"],
  endpoints: (builder) => ({
    getUniqueFlowName: builder.mutation<
      TemplateNameResponse,
      {
        name?: string;
        type?: TemplateType;
      }
    >({
      query: ({ name = "New Flow", type = TemplateType.STANDARD }) => ({
        url: `/api/unique-template-names/?default_name=${name}&type=${type}`,
        method: "GET",
      }),
    }),
    validateFlowName: builder.mutation<
      TemplateNameValidationResponse,
      TemplateNameValidationRequest
    >({
      query: ({ name, template_id, branding_app_id, type }) => ({
        url: `api/v1/templates/${template_id}/validate-name/?type=${type}`,
        method: "POST",
        data: branding_app_id
          ? {
              name,
              branding_app_id,
            }
          : {
              name,
            },
      }),
    }),

    validateAndUpdateFlowName: builder.mutation<
      TemplateNameValidateAndUpdateResponse,
      TemplateNameValidationRequest
    >({
      query: ({
        name,
        template_id,
        branding_app_id,
        machineName = "",
        type = TemplateType.STANDARD,
      }) => ({
        url: `api/v5/templates/${template_id}/?exclude_template_from_response=true&type=${type}`,
        method: "PATCH",
        data: branding_app_id
          ? {
              meta: {
                id: template_id,
                title: name,
                branding_app_id,
                machine_name: machineName,
              },
            }
          : {
              meta: {
                id: template_id,
                title: name,
                machine_name: machineName,
              },
            },
      }),
    }),
  }),
});

export const {
  useGetUniqueFlowNameMutation,
  useValidateFlowNameMutation,
  useValidateAndUpdateFlowNameMutation,
} = useFlowname;
