import { ReactElement, cloneElement } from "react";
import "./tooltip.scss";
import ReactTooltip from "react-tooltip";

export interface IntegryTooltipProps {
  tooltipText?: string | undefined;
  tooltipId: string;
  hideToolTip?: boolean;
  tooltipContent?: ReactElement;
  hideOnScroll?: boolean;
  className?: string;
  type?: "dark" | "success" | "warning" | "error" | "info" | "light";
  effect?: "float" | "solid";
  placement?: "bottom" | "top" | "left" | "right";
  event?: string;
  eventOff?: string;
  children: ReactElement;
}

const IntegryTooltip = (props: IntegryTooltipProps): ReactElement => {
  const {
    tooltipText,
    tooltipId,
    tooltipContent,
    hideOnScroll,
    placement,
    hideToolTip,
    className,
    children,
    ...rest
  } = props;

  const childrenProps = {};
  childrenProps["data-tip"] = true;
  childrenProps["data-for"] = tooltipId;

  return (
    <>
      <>{cloneElement(children, { ...childrenProps })}</>
      <ReactTooltip
        id={tooltipId}
        place={placement}
        disable={hideToolTip}
        className={className}
        {...rest}
      >
        {tooltipText ? (
          <div className="react-tooltip-content">{tooltipText}</div>
        ) : (
          tooltipContent
        )}
      </ReactTooltip>
    </>
  );
};

IntegryTooltip.defaultProps = {
  placement: "bottom",
  hideToolTip: false,
  hideOnScroll: true,
  effect: "solid",
  type: "light",
  event: "mouseover mouseenter",
  eventOff: "mouseleave scroll mousewheel blur",
  className: "integry-tooltip-light",
};

export default IntegryTooltip;
