import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import Tag from "components/integry-design-system/atoms/tag";

import OverflowTooltip from "components/common/overflow-tooltip";

import {
  statusLabels,
  networkCodeColors,
} from "legacy-features/integrations/runs-view/runs-listing";
import PlaceholderBar from "atomic-components/atom/placeholder-bar";
import {
  getHidePayloadButtonLabel,
  getViewPayloadButtonLabel,
} from "features/end-users/helper-utils";

import openLink from "images/open-link.svg";
import TruncateBullets from "images/truncate_bullets.svg";
import PolygonDownIcon from "images/dropdown_icon.svg";
import { Poll } from "../../../interface";

import "legacy-features/integrations/styles.scss";
import "./styles.scss";

interface StepRowProps {
  step: Poll;
  config: {
    id: string;
    subConfig?: { id: string; type: string }[];
    type?: string;
    tdStyle?: Record<string, unknown>;
    truncate?: boolean;
    showTooltip?: boolean;
    tooltipField?: string;
    hideSameValueTooltip?: boolean;
    getLink: (step: Poll) => string;
  }[];
  expandedPayloadStepId?: string;
  onViewPayloadClick?: (stepId: string) => void;
  rowStyle?: Record<string, unknown>;
  node?: number;
}

const StepRow = ({
  step,
  config,
  expandedPayloadStepId = "-1",
  onViewPayloadClick,
  rowStyle = {},
  node = 0,
}: StepRowProps): JSX.Element => {
  const shouldShowPayload = true;
  const hasPayload = true;
  return (
    <div
      className={`div-row div-row-new table-row ${
        shouldShowPayload ? "" : "disabled"
      } ${expandedPayloadStepId === step.id ? "payload-visible" : ""}`}
      style={rowStyle}
      onClick={() => {
        if (onViewPayloadClick && hasPayload && shouldShowPayload)
          onViewPayloadClick(step.id);
      }}
    >
      {config.map((configItem) => {
        const {
          id,
          subConfig,
          type = "text",
          tdStyle = {},
          truncate = false,
          showTooltip = false,
          tooltipField = "",
          hideSameValueTooltip = false,
        } = configItem;

        if (type === "loader") {
          return (
            <div className="div-column">
              <PlaceholderBar
                customStyle={{
                  maxWidth: "80%",
                  borderRadius: "4px",
                  margin: "0px",
                }}
              />
            </div>
          );
        }

        if (type === "tag") {
          const label =
            statusLabels[String(step[id]).toLowerCase()] || step[id];
          return (
            <div className="div-column">
              <Tag label={label} type="run" />
            </div>
          );
        }
        if (type === "link") {
          return (
            <div className="div-column">
              <IntegryTooltip placement="bottom" tooltipText="View Runs">
                <a
                  href={configItem.getLink(step)}
                  target="_blank"
                  rel="noreferrer"
                  className="integry-link flex gap-5px"
                  style={{
                    fontSize: "12px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {step[id]}{" "}
                  <img
                    src={openLink}
                    alt="open_link"
                    width={8}
                    height={8}
                    style={{
                      marginTop: "3px",
                    }}
                  />
                </a>
              </IntegryTooltip>
            </div>
          );
        }
        if (truncate) {
          return (
            <div className="div-column" style={tdStyle}>
              {step[id].length > 20 ? (
                <IntegryTooltip tooltipText={step[id]} placement="bottom">
                  <div>
                    {step[id].substring(0, 3)}
                    <img
                      src={TruncateBullets}
                      style={{
                        width: "15px",
                        margin: "0 2px",
                        marginTop: "-3px",
                        objectFit: "none",
                      }}
                      alt="truncate_bullets"
                    />
                    {step[id].substring(
                      step[id].length - 3 + (node > 3 ? 3 : node)
                    )}
                  </div>
                </IntegryTooltip>
              ) : (
                `${step[id]}`
              )}
            </div>
          );
        }
        return (
          <div
            className="div-column"
            style={
              id === "network_code_label" || id === "network_code"
                ? {
                    ...tdStyle,
                    color:
                      networkCodeColors[
                        `${step[id] || "N/A"}`.substring(0, 1)
                      ] || "#333333",
                  }
                : tdStyle
            }
          >
            <IntegryTooltip
              hideToolTip={
                !showTooltip ||
                step[tooltipField] === "N/A" ||
                (hideSameValueTooltip && step[id] === step[tooltipField])
              }
              tooltipText={step[tooltipField] || ""}
              placement="bottom"
            >
              <div>
                {!subConfig && step[id]}
                {subConfig && (
                  <div className="div-column__group">
                    {subConfig.map((subItem) => {
                      if (!step[id]) {
                        return null;
                      }
                      if (subItem.type === "img") {
                        return (
                          <img
                            src={step[id][subItem.id]}
                            alt={`ico_${step[id][subItem.id]}`}
                          />
                        );
                      }
                      if (subItem.type === "tag") {
                        return (
                          <>
                            {step[subItem.id] && (
                              <Tag label={`v${step[subItem.id]}`} type="grey" />
                            )}
                          </>
                        );
                      }
                      return (
                        <OverflowTooltip
                          text={step[id][subItem.id]}
                          className="sub-item-text"
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            </IntegryTooltip>
            {(id === "networkCode" || id === "network_code") &&
              (!step.payload ? (
                <IntegryTooltip
                  tooltipText="Logging data is deleted after 30 days"
                  placement="top"
                >
                  <PayloadButton
                    variant="outlined"
                    size="small"
                    isDisabled
                    endIcon={
                      <img
                        src={PolygonDownIcon}
                        className={
                          step.id === expandedPayloadStepId ? "rotateIcon" : ""
                        }
                        alt={`view_payload_${step.id}`}
                      />
                    }
                    onClick={() => ({})}
                  >
                    {step.id === expandedPayloadStepId
                      ? getHidePayloadButtonLabel("TRIGGER")
                      : getViewPayloadButtonLabel("TRIGGER")}
                  </PayloadButton>
                </IntegryTooltip>
              ) : (
                <PayloadButton
                  variant="outlined"
                  size="small"
                  endIcon={
                    <img
                      src={PolygonDownIcon}
                      className={
                        step.id === expandedPayloadStepId ? "rotateIcon" : ""
                      }
                      alt={`view_payload_${step.id}`}
                    />
                  }
                  onClick={() => {
                    if (onViewPayloadClick) {
                      onViewPayloadClick(step.id);
                    }
                  }}
                >
                  {step.id === expandedPayloadStepId
                    ? getHidePayloadButtonLabel("TRIGGER")
                    : getViewPayloadButtonLabel("TRIGGER")}
                </PayloadButton>
              ))}
          </div>
        );
      })}
    </div>
  );
};

export default StepRow;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const InterButton = styled(Button)<any>(({ isDisabled = false, variant }) => ({
  fontFamily: ["Inter"],
  // font-family: 'Inter';
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "15px",
  color: "#4250F0",
  padding: "5px 10px",
  borderColor: "#4250F0",
  borderRadius: "5px",
  textTransform: "none",
  minWidth: "90px",
  opacity: isDisabled ? 0.5 : 1,
  "&:hover":
    variant !== "text"
      ? {
          backgroundColor: !isDisabled ? "#4250F0" : "#FFFFFF",
          color: !isDisabled ? "#FFFFFF" : "#4250F0",
          cursor: !isDisabled ? "pointer" : "not-allowed",
        }
      : {
          backgroundColor: "transparent",
        },
}));

const PayloadButton = styled(InterButton)(() => ({
  padding: "4.5px 6px",
  paddingRight: "0",
  fontWeight: "400",
  fontSize: "13px",
  lineHeight: "16px",
  maxWidth: "109px",
  minWidth: "109px",
  whiteSpace: "nowrap",
}));
