import http from "../../utils/http";

export const REQUEST_STATIC_LISTS = "REQUEST_STATIC_LISTS";

export const requestStaticLists = () => ({
  type: REQUEST_STATIC_LISTS,
});

export const RECEIVE_STATIC_LISTS = "RECEIVE_STATIC_LISTS";

export const receiveStaticLists = (json) => ({
  type: RECEIVE_STATIC_LISTS,
  lists: json,
});

export const fetchStaticLists = () => {
  return (dispatch) => {
    dispatch(requestStaticLists());

    return http.get(`/api/static_lists/?q=all`).then(
      (response) => {
        dispatch(receiveStaticLists(response.data));
      },
      (error) => {
        console.log(
          `Error occurred while fetching static lists in fetchStaticLists() action.`,
          error
        );
      }
    );
  };
};
