import { Field } from "formik";
import { ReactElement } from "react";

interface CheckboxProps {
  id?: string;
  name: string;
  className?: string;
  onChangeCustom?: (val) => void;
}
const Checkbox = (props: CheckboxProps): ReactElement => {
  const { name, className, onChangeCustom } = props;
  return (
    <Field name={name}>
      {({ field }) => {
        const { value, onChange } = field;

        return (
          <input
            name={name}
            {...field}
            checked={value}
            className={`form-check-input ${className}`}
            type="checkbox"
            onChange={(e) => {
              onChange(e);
              if (onChangeCustom) {
                onChangeCustom(e);
              }
            }}
          />
        );
      }}
    </Field>
  );
};

export default Checkbox;
