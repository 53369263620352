import { ReactElement } from "react";
import IntegryTooltip from "components/integry-design-system/molecules/integry-tooltip/integry-tooltip-v2";

import { generateUserInitials } from "utils/functions";
import { User } from "types/integry-user";

import genericCompanyIcon from "images/navbar-dropdown/generic-company-logo.svg";
import "./style.scss";

interface IProps {
  user: User | undefined;
  isMenuOpen: boolean;
}

const NavbarControl = ({ user, isMenuOpen }: IProps): ReactElement => {
  const {
    email,
    first_name,
    last_name,
    account = { name: "", icon_url: "", url: "" },
  } = user || {};
  const { name: account_name, icon_url: company_icon } = account;
  const userInitials = generateUserInitials(user);
  const companyName = account_name;
  const name = `${first_name}${last_name ? ` ${last_name}` : ""}`;

  const tooltip = (
    <div className="navbar-dropdown-tooltip-wrapper">
      <div className="overflow-elipsis">{companyName}</div>
      {name && <div className="overflow-elipsis">{name}</div>}
      <div className="overflow-elipsis">{email}</div>
    </div>
  );

  return (
    <IntegryTooltip
      tooltipId="navbar-dropdown"
      tooltipContent={tooltip}
      key={`${isMenuOpen}`}
      hideToolTip
    >
      <div className={`account-setting-control ${isMenuOpen ? "open" : ""}`}>
        <img
          src={company_icon || genericCompanyIcon}
          alt="company Icon"
          className="company-icon"
        />
        <div className="account-name not-selectable-text overflow-elipsis">
          {companyName}
        </div>
        <div className="user-initials not-selectable-text">{userInitials}</div>
      </div>
    </IntegryTooltip>
  );
};

export default NavbarControl;
