export const initialAuthorizationFormState = {
  AUTH_INFO: { name: "", type_id: "" },
  CONFIGURE_OAUTH_2: {
    urlParameters: [],
    CUSTOM_TOKEN_ENDPOINT: {
      http_verb: "post",
      base_url_id: "",
      headers: [],
      base_url_option: {},
      url_path: "",
      url: "",
      language: "twig",
      custom_base_url: "",
      base_url_raw: "",
    },
  },
  TOKEN_TEST_RESULT: { testResult: {} },
  ACCESS_TOKEN_LOCATION: {
    access_token_param_name: "API Key",
    access_token_param_value: "{{authorization.token.access_token}}",
    access_token_location: "header",
  },
  TEST_API: { has_user_identity_call: true },
  API_USER_INFO: {
    http_verb: "get",
    base_url_id: "",
    headers: [],
    base_url_option: {},
    url_path: "",
    url: "",
    language: "twig",
    custom_base_url: "",
    base_url_raw: "",
  },
  AUTH_TESTING: { connected_account_name: "", testResult: {} },
  RESPONSE_PROCESSING: { response_template: "", language: "twig" },
  OTHER_INFO_OAUTH: {
    is_sandbox_enabled: false,
  },
  authorizationTags: {},
};

export const initialActivityFormState = {
  ACTION: {
    INFORMATION: {
      name: "",
      activity_description: "",
    },
    FIELDS: {
      activity_fields: [],
    },
    DATA_SOURCE_FORMS: {
      ENDPOINT: {
        http_verb: "get",
        base_url_id: "",
        headers: [],
        base_url_option: {},
        url_path: "",
        url: "",
        language: "twig",
        base_url_raw: "",
        custom_base_url: "",
      },
      ENDPOINT_TESTING: {
        connected_account_name: "",
        testResult: {},
      },
      RESPONSE_PROCESSING: { response_template: "", language: "twig" },
      dataSourceEndPoint: {},
    },
    CONNECT_ACCOUNT: { authorization_type: "", authorization_id: "" },
    FIELD_TESTING: {},
    ENDPOINT: {
      http_verb: "get",
      base_url_id: "",
      headers: [],
      base_url_option: {},
      url_path: "",
      url: "",
      language: "twig",
      custom_base_url: "",
      base_url_raw: "",
    },
    ENDPOINT_TESTING: {
      connected_account_name: "",
      testResult: {},
    },
    RESPONSE_PROCESSING: { response_template: "", language: "twig" },
    ACTION_OUTPUT: {
      activity_output: "",
    },
  },
  QUERY: {
    INFORMATION: {
      name: "",
      activity_description: "",
    },
    FIELDS: {
      activity_fields: [],
    },
    DATA_SOURCE_FORMS: {
      ENDPOINT: {
        http_verb: "get",
        base_url_id: "",
        headers: [],
        base_url_option: {},
        url_path: "",
        url: "",
        language: "twig",
        custom_base_url: "",
        base_url_raw: "",
      },
      ENDPOINT_TESTING: {
        connected_account_name: "",
        testResult: {},
      },
      RESPONSE_PROCESSING: { response_template: "", language: "twig" },
      dataSourceEndPoint: {},
    },
    CONNECT_ACCOUNT: { authorization_type: "", authorization_id: "" },
    FIELD_TESTING: {},
    ENDPOINT: {
      http_verb: "get",
      base_url_id: "",
      headers: [],
      base_url_option: {},
      url_path: "",
      url: "",
      language: "twig",
      custom_base_url: "",
      base_url_raw: "",
    },
    ENDPOINT_TESTING: {
      connected_account_name: "",
      testResult: {},
    },
    RESPONSE_PROCESSING: { response_template: "", language: "twig" },
    QUERY_OUTPUT: {
      activity_output: "",
    },
    STANDARD_OBJECT: {
      object_id: "",
      activity_action: "",
    },
  },
  TRIGGER: {
    INFORMATION: {
      name: "",
      activity_description: "",
    },
    FIELDS: {
      activity_fields: [],
    },
    DATA_SOURCE_FORMS: {
      ENDPOINT: {
        http_verb: "get",
        base_url_id: "",
        headers: [],
        base_url_option: {},
        url_path: "",
        url: "",
        language: "twig",
        custom_base_url: "",
        base_url_raw: "",
      },
      ENDPOINT_TESTING: {
        connected_account_name: "",
        testResult: {},
      },
      RESPONSE_PROCESSING: { response_template: "", language: "twig" },
      dataSourceEndPoint: {},
    },
    CONNECT_ACCOUNT: { authorization_type: "", authorization_id: "" },
    FIELD_TESTING: {},
    TRIGGER_TYPE: {
      is_polling_based: false,
    },
    ENDPOINT: {
      http_verb: "get",
      base_url_id: "",
      headers: [],
      base_url_option: {},
      url_path: "",
      skipHook: false,
      url: "",
      language: "twig",
      custom_base_url: "",
      base_url_raw: "",
    },
    ENDPOINT_TESTING: {
      connected_account_name: "",
      testResult: {},
    },
    RESPONSE_PROCESSING: { response_template: "", language: "twig" },
    INVISIBLE_ENDPOINT_TEST: {
      connected_account_name: "",
      testResult: {},
    },
    INVISIBLE_ENDPOINT_RESPONSE: {
      response_template: "",
      headers: [],
      sender_response_template: "",
      language: "twig",
    },
    STANDARD_OBJECT: {
      object_id: "",
      activity_action: "",
    },
    DELETE_ENDPOINT: {
      http_verb: "get",
      base_url_id: "",
      headers: [],
      base_url_option: {},
      url_path: "",
      url: "",
      language: "twig",
      base_url_raw: "",
      custom_base_url: "",
    },
    DELETE_ENDPOINT_TESTING: {
      connected_account_name: "",
      testResult: {},
    },
    TRIGGER_OUTPUT: {
      activity_output: "",
    },
  },
  dataSourceTags: {},
  actionTags: {},
  queryTags: {},
  triggerTags: {},
  triggerMainTags: {},
  triggerDeleteTags: {},
};

export const initialAuthorizationFormStatus = {
  AUTH_INFO: {
    status: "INCOMPLETE",
  },
  CONFIGURE_OAUTH_2: {
    status: "INCOMPLETE",
  },
  TOKEN_TEST_RESULT: {
    status: "INCOMPLETE",
  },
  ACCESS_TOKEN_LOCATION: {
    status: "INCOMPLETE",
  },
  TEST_API: {
    status: "INCOMPLETE",
  },
  API_USER_INFO: {
    status: "INCOMPLETE",
  },
  AUTH_TESTING: {
    status: "INCOMPLETE",
  },
  RESPONSE_PROCESSING: {
    status: "INCOMPLETE",
  },
  OTHER_INFO_OAUTH: {
    status: "INCOMPLETE",
  },
};

export const initialActivitiesFormStatus = {
  ACTION: {
    INFORMATION: {
      status: "INCOMPLETE",
    },
    FIELDS: {
      status: "INCOMPLETE",
    },
    DATA_SOURCE_FORMS: {
      ENDPOINT: {
        status: "INCOMPLETE",
      },
      ENDPOINT_TESTING: {
        status: "INCOMPLETE",
      },
      RESPONSE_PROCESSING: { status: "INCOMPLETE" },
    },
    CONNECT_ACCOUNT: {
      status: "INCOMPLETE",
    },
    FIELD_TESTING: {
      status: "INCOMPLETE",
    },
    ENDPOINT: {
      status: "INCOMPLETE",
    },
    ENDPOINT_TESTING: {
      status: "INCOMPLETE",
    },
    RESPONSE_PROCESSING: {
      status: "INCOMPLETE",
    },
    ACTION_OUTPUT: {
      status: "INCOMPLETE",
    },
  },
  QUERY: {
    INFORMATION: {
      status: "INCOMPLETE",
    },
    FIELDS: {
      status: "INCOMPLETE",
    },
    DATA_SOURCE_FORMS: {
      ENDPOINT: {
        status: "INCOMPLETE",
      },
      ENDPOINT_TESTING: {
        status: "INCOMPLETE",
      },
      RESPONSE_PROCESSING: { status: "INCOMPLETE" },
    },
    CONNECT_ACCOUNT: {
      status: "INCOMPLETE",
    },
    FIELD_TESTING: {
      status: "INCOMPLETE",
    },
    ENDPOINT: {
      status: "INCOMPLETE",
    },
    ENDPOINT_TESTING: {
      status: "INCOMPLETE",
    },
    RESPONSE_PROCESSING: {
      status: "INCOMPLETE",
    },
    QUERY_OUTPUT: {
      status: "INCOMPLETE",
    },
  },
  TRIGGER: {
    INFORMATION: {
      status: "INCOMPLETE",
    },
    FIELDS: {
      status: "INCOMPLETE",
    },
    DATA_SOURCE_FORMS: {
      ENDPOINT: {
        status: "INCOMPLETE",
      },
      ENDPOINT_TESTING: {
        status: "INCOMPLETE",
      },
      RESPONSE_PROCESSING: { status: "INCOMPLETE" },
    },
    CONNECT_ACCOUNT: {
      status: "INCOMPLETE",
    },
    FIELD_TESTING: {
      status: "INCOMPLETE",
    },
    TRIGGER_TYPE: {
      status: "INCOMPLETE",
    },
    ENDPOINT: {
      status: "INCOMPLETE",
    },
    ENDPOINT_TESTING: {
      status: "INCOMPLETE",
    },
    RESPONSE_PROCESSING: {
      status: "INCOMPLETE",
    },
    INVISIBLE_ENDPOINT_TEST: {
      status: "INCOMPLETE",
    },
    INVISIBLE_ENDPOINT_RESPONSE: {
      status: "INCOMPLETE",
    },
    STANDARD_OBJECT: {
      status: "INCOMPLETE",
    },
    DELETE_ENDPOINT: {
      status: "INCOMPLETE",
    },
    DELETE_ENDPOINT_TESTING: {
      status: "INCOMPLETE",
    },
    TRIGGER_OUTPUT: {
      status: "INCOMPLETE",
    },
  },
};

const initialAppBuilderState = {
  BASIC_INFO: {
    APP_URL: { app_url: "" },
    APP_DETAIL: {
      app_name: "",
      app_description: "",
      icon_url: null,
      color: "",
    },
  },
  AUTHORIZATION: { ...initialAuthorizationFormState },
  ACTIVITIES: { ...initialActivityFormState },
  SETTINGS: {
    SETTINGS: {
      link_to_user_profile: null,
      base_urls: [],
      is_workspace: true,
      api_version: null,
      last_version_checked: null,
      api_version_deprecation: null,
      network_code_documentation_link: null,
    },
    PRODUCT_PAGE: {
      images: [],
      category: [],
      long_description: "",
      tags: "",
      website_link: "",
      terms_and_conditions_url: "",
      privacy_policy_url: "",
      app_documentation_link: "",
      support_email: "",
      facebook_url: "",
      twitter_url: "",
      linkedin_url: "",
    },
  },
  formStatus: {
    BASIC_INFO: {
      APP_URL: {
        status: "INCOMPLETE",
      },
      APP_DETAIL: {
        status: "INCOMPLETE",
      },
    },
    SETTINGS: {
      SETTINGS: {
        status: "INCOMPLETE",
      },
      PRODUCT_PAGE: {
        status: "INCOMPLETE",
      },
    },
    AUTHORIZATION: { ...initialAuthorizationFormStatus },
    ACTIVITIES: { ...initialActivitiesFormStatus },
  },
  isWizardEnabled: true,
  isBasicSectionComplete: false,
  isAuthorizationSectionComplete: false,
  isActivitySectionComplete: false,
  isAppFlowComplete: false,
  firstAuthID: null,
  firstActionID: null,
  firstQueryID: null,
  firstTriggerID: null,
  flowActivityType: "",
  dontUseFlowActivityType: false,
  appLockAlertText: "",
};

export default initialAppBuilderState;
