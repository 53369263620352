import { ReactElement, useState } from "react";
import OverflowTooltip from "components/common/overflow-tooltip";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import Tag from "components/integry-design-system/atoms/tag";
import CopyButton from "components/integry-design-system/atoms/buttons/CopyButton/CopyButton";
import SearchBar from "components/common/search-input-field/searchbar-v3";
import { ReactComponent as GearIcon } from "images/navbar-dropdown/gear-icon-hollow.svg";
import { useGetFunctionsQuery } from "features/templates/api/usePublicFunctionsAPI";
import {
  IntegryFunction,
  ParameterProperties,
} from "features/templates/interface/function";
import { FunctionType } from "features/templates/interface/functionType";
import { capitalizeFirstLetter, toCurlCommand } from "utils/functions";

import ListingTable, {
  createColumnHelper,
} from "components/integry-design-system/organisms/ListingTable/ListingTable";
import useHashBasedAuth from "hooks/useHashBasedAuth";

import "./ConfigureFunctions.scss";
import config from "utils/config";

const ConfigureFunctions = (): ReactElement => {
  const [queryArgumets, setQueryArgumets] = useState<{
    search: string;
    cursor: string;
  }>({
    search: "",
    cursor: "",
  });

  const { getAuthParams } = useHashBasedAuth();
  const authParams = getAuthParams();
  const {
    data: publicFunctions,
    isLoading: isLoadingFunctions,
    isFetching: isFetchingFunctions,
  } = useGetFunctionsQuery({
    queryParams: authParams,
    include_private: false,
    cursor: queryArgumets.cursor,
    search: queryArgumets.search,
  });

  const columnHelper = createColumnHelper<IntegryFunction>();

  const columns = [
    columnHelper.accessor("meta.ui.title", {
      header: () => "Title",
      cell: (info) => (
        <div className="flex align-center gap-10px">
          <img
            src={info.row.original.meta.app.icon_url}
            alt="appicon"
            width={24}
            height={24}
            style={{ borderRadius: "50%" }}
          />
          <OverflowTooltip text={info.getValue()} />
        </div>
      ),
    }),

    columnHelper.accessor("name", {
      header: () => "Name",
      cell: (info) => <OverflowTooltip text={info.getValue()} />,
    }),

    columnHelper.accessor("meta.type", {
      header: () => "Type",
      cell: (info) => {
        return (
          <Tag
            label={capitalizeFirstLetter(info.getValue())}
            type={
              info.getValue() === FunctionType.QUERY ? "green" : "royalBlue"
            }
          />
        );
      },
    }),

    columnHelper.accessor("parameters", {
      header: () => "Parameters",
      cell: (info) => {
        const requiredParameters = Object.values(
          info.getValue().properties
        ).filter((param) => param.meta.is_required).length;
        const parameterKeys = Object.keys(info.getValue().properties);
        const tooltipText = parameterKeys.length
          ? parameterKeys.reduce((acc, key) => {
              return `${acc}${acc ? "," : acc} ${key}${
                info.getValue().properties[key].meta.is_required ? "*" : ""
              }`;
            }, "")
          : "";
        return (
          <IntegryTooltip
            tooltipText={tooltipText}
            disabled={!tooltipText}
            toolTipStyles={{
              zIndex: 100,
            }}
          >
            <span className="cursor-pointer">
              {parameterKeys.length
                ? `${parameterKeys.length} parameters ${
                    requiredParameters ? `(${requiredParameters} required)` : ""
                  }`
                : ""}
            </span>
          </IntegryTooltip>
        );
      },
    }),

    columnHelper.accessor("meta", {
      header: () => "",
      cell: (info) => {
        const tooltipText = "Coming soon!";

        return (
          <div className="flex align-center gap-10px">
            <CopyButton
              textToCopy={() =>
                copyFunctionCurl({
                  integryFunction: info.row.original,
                  authParams,
                })
              }
              showOnlyIcon
              tooltipText="Copy cURL for this function"
            />
            {/* <IntegryTooltip
              tooltipText={tooltipText}
              toolTipStyles={{
                zIndex: 100,
              }}
            >
              <GearIcon width={15} height={15} className="cursor-pointer" />
            </IntegryTooltip> */}
          </div>
        );
      },
    }),
  ];

  const showMainLoader =
    isLoadingFunctions || (isFetchingFunctions && !queryArgumets.cursor);

  return (
    <div className="configure-functions">
      <div className="configure-functions-wrapper">
        <div className="text-default txt-md semi-bold">Functions</div>
        <SearchBar
          searchQueryProp={queryArgumets.search}
          onSearch={(search) => {
            setQueryArgumets({
              cursor: "",
              search,
            });
          }}
          placeholder="Search for a function"
        />
        <div className="configure-functions-table integry-scrollbar-v2">
          <ListingTable<IntegryFunction>
            data={publicFunctions?.functions || []}
            hasMore={showMainLoader ? false : !!publicFunctions?.cursor}
            columns={columns}
            fetchMore={() => {
              setQueryArgumets((prev) => ({
                ...prev,
                cursor: publicFunctions?.cursor || "",
              }));
            }}
            isLoading={showMainLoader}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfigureFunctions;

const copyFunctionCurl = ({
  integryFunction,
  authParams,
}: {
  integryFunction: IntegryFunction;
  authParams: string;
}): string => {
  const functionURL = `${config.apiBaseURL}/functions/${integryFunction.name}/?${authParams}`;
  const getPayload = (): string => {
    const payload = {};
    const parameters = integryFunction.parameters.properties;
    Object.keys(parameters).forEach((key) => {
      payload[key] = getObjectParameterValue({
        integryFunctionParameter: parameters[key],
        paramName: key,
      });
    });
    return JSON.stringify(payload);
  };
  const request = {
    url: functionURL,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: getPayload(),
  };
  return toCurlCommand(request);
};

const getObjectParameterValue = ({
  integryFunctionParameter,
  paramName,
}: {
  integryFunctionParameter: ParameterProperties;
  paramName: string;
}):
  | number
  | string
  | boolean
  | Record<string, never>
  | Record<string, never>[] => {
  console.log(integryFunctionParameter, paramName);
  const parameterType = integryFunctionParameter?.type;
  const defaultValue = integryFunctionParameter?.meta.ui.default_value;
  switch (parameterType) {
    case "number":
      try {
        return parseInt(defaultValue, 10) || 0;
      } catch (e) {
        return 0;
      }
    case "boolean":
      return defaultValue === "true";
    case "string":
      return defaultValue;
    case "object": {
      const objectValue = {};
      const parameterFields = integryFunctionParameter.properties || {};
      Object.keys(parameterFields).forEach((fieldKey) => {
        objectValue[fieldKey] = getObjectParameterValue({
          integryFunctionParameter: parameterFields[fieldKey],
          paramName: fieldKey,
        });
      });
      return objectValue;
    }

    case "array": {
      const objectValue = {};
      const parameterFields = integryFunctionParameter.items?.properties || {};
      if (!Object.keys(parameterFields).length) {
        return [];
      }
      Object.keys(parameterFields).forEach((fieldKey) => {
        objectValue[fieldKey] = getObjectParameterValue({
          integryFunctionParameter: parameterFields[fieldKey],
          paramName: fieldKey,
        });
      });
      return [objectValue];
    }

    default:
      return defaultValue;
  }
};
