import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import React, { useState, useEffect } from "react";
import clearIcon from "../../../images/dark-grey-cancel.svg";

const SelectFieldOptionPill = ({
  value,
  options,
  removeTag,
  txtIcon,
  isFieldListPill,
  hidePillDescription,
}) => {
  const [option, setOption] = useState(null);

  useEffect(() => {
    setOption(getCurrentOption());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, value]);

  const getCurrentOption = () =>
    options.find((opt) => opt.id === value || opt.value === value);

  const getMaxLengths = (name, value) => {
    const maxTotalLength = 18;
    let maxNameLength = 0;
    let maxValueLength = 0;
    const nameLength = name.length;
    const valueLength = value ? value.length : 0;
    const totalLength = nameLength + valueLength;
    if (totalLength <= maxTotalLength) {
      maxNameLength = nameLength;
      maxValueLength = valueLength;
    } else if (totalLength > maxTotalLength) {
      if (nameLength <= 9) {
        maxNameLength = nameLength;
        maxValueLength = maxTotalLength - maxNameLength;
      } else {
        maxNameLength = 9;
        maxValueLength = 9;
      }
    }
    return { maxNameLength, maxValueLength, totalLength };
  };
  const valueKey = option && option.title ? "title" : "text";
  const idKey = option && option.value ? "value" : "id";
  return option ? (
    <IntegryTooltip
      hideToolTip={
        getMaxLengths(option[valueKey], option[idKey]).totalLength <= 18 ||
        isFieldListPill
      }
      tooltipText={`${option[valueKey]} | ${option[idKey]}`}
    >
      <span
        className={`option-capsule${
          isFieldListPill ? " field-list-capsule" : ""
        }`}
      >
        {!hidePillDescription && (
          <img
            className={`${isFieldListPill ? "field-list-icon" : "txt-icon"}`}
            src={isFieldListPill ? option.icon : txtIcon}
            alt="txt-icon"
          />
        )}
        {isFieldListPill
          ? option[valueKey]
          : option[valueKey].length >
            getMaxLengths(option[valueKey], option[idKey]).maxNameLength
          ? `${option[valueKey].substring(
              0,
              getMaxLengths(option[valueKey], option[idKey]).maxNameLength
            )}...`
          : option[valueKey]}
        {option[idKey] && !isFieldListPill && !hidePillDescription && (
          <span className="desc">
            {option[idKey].length >
            getMaxLengths(option[valueKey], option[idKey]).maxValueLength
              ? `${option[idKey].substring(
                  0,
                  getMaxLengths(option[valueKey], option[idKey]).maxValueLength
                )}...`
              : option[idKey]}
          </span>
        )}
        <span className="clearIcon">
          <img onClick={removeTag} src={clearIcon} alt="clear-icon" />
        </span>
      </span>
    </IntegryTooltip>
  ) : (
    <></>
  );
};

export default SelectFieldOptionPill;
