/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef, ReactElement } from "react";
import useDebounced from "hooks/useCancleableDebounce";
import Button from "components/integry-design-system/molecules/button";
import Tabs from "components/integry-design-system/atoms/tabs/tabs";

import { FormFeedback } from "reactstrap";

import {
  Editor,
  EditorState,
  CompositeDecorator,
  ContentState,
  ContentBlock,
  SelectionState,
  Modifier,
  KeyBindingUtil,
  getDefaultKeyBinding,
} from "draft-js";

import { extractPathFromFeildName } from "utils/template-editor";
import useOnClickOutside from "utils/use-onclick-outside";
import { isValidJson } from "utils/json";
import { getElementWidth } from "utils/html-element-utils";

import IntegryPopover from "components/integry-design-system/atoms/integry-popover-v2";
import CommonDropdownMenu from "components/integry-design-system/atoms/dropdown/dropdown-menu/CommonDropdownMenu/CommonDropdownMenu";

import usePrevious from "hooks/usePrevious";

import { useStepTagsContext } from "features/templates/context/StepsTagsContext";
import useIsMounted from "hooks/useIsMounted";

import { useField } from "formik";

import TagOptionTree from "../../v3/tag-option-tree";
import { EditorStateProvider, useEditorState } from "./EditorStateContext";

import Pill from "./Pill";
import {
  ZERO_WIDTH_NBSP,
  convertToPillFormat,
  convertToTagFormat,
  getSelectedText,
  getEditorPlainText,
} from "./utils";
import "./TagEditor.scss";

const TagEditor: React.FC<{
  name: string;
  disabled?: boolean;
  placeholder: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appendedCustomTags?: any[];
  includeArraysOnly: boolean;
  onChange?: (value: string) => void;
  controls?: ReactElement;
  mode?: "json" | "text";
  showAddTagButton?: boolean;
  title?: string | ReactElement;
  errorPlaceHolder?: boolean;
  options?: { label: string; value: string }[];
}> = ({
  disabled,
  name,
  placeholder,
  appendedCustomTags = [],
  includeArraysOnly = false,
  showAddTagButton = false,
  errorPlaceHolder = false,
  controls,
  mode = "text",
  onChange,
  title = "",
  options = [],
}) => {
  const { editorState, setEditorState } = useEditorState();

  const [showDropdown, toggleDropdown] = useState(false);
  const [showAddTagDropdown, setShowAddTagDropdown] = useState(false);

  const divRef = React.useRef<HTMLDivElement>(null);
  const editorRef = useRef<Editor>(null);
  const isFocusedRef = useRef(false);

  const currentStepPath = extractPathFromFeildName(name);

  const [field, meta, helper] = useField(name);
  const { setValue, setTouched } = helper;
  const { touched, error } = meta;
  const isInvalid = touched && error;
  const validationClass = isInvalid ? "is-invalid" : "";

  const previousFildValue = usePrevious(field.value);
  const isMounted = useIsMounted();

  useOnClickOutside(
    divRef,
    () => {
      isFocusedRef.current = false;
    },
    ["add-tag-button-v3", "tag-data-tree", "tag-editor-wrapper"]
  );

  const [debouncedSetValue] = useDebounced((value) => {
    setValue(value);
    if (onChange) {
      onChange(value);
    }
  }, 500);

  useEffect(() => {
    setEditorState(
      (prevState) => EditorState.set(prevState, { decorator }),
      "setDecorator"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (!isMounted()) {
        const plainText = convertToTagFormat(getEditorPlainText(editorState));
        if (field.value !== plainText) {
          setValue(plainText);
        }
      }
    };
  }, [editorState, isMounted, setValue, field.value]);

  useEffect(() => {
    if (field.value !== previousFildValue && !isFocusedRef.current) {
      initializeEditorWithText(field.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value, previousFildValue]);

  // Apply the decorator to the editor state

  const initializeEditorWithText = (text: string): void => {
    const convertedTags = convertToPillFormat(text);
    const contentState = ContentState.createFromText(convertedTags);
    const newEditorState = EditorState.createWithContent(
      contentState,
      decorator
    );
    setEditorState(newEditorState, "initializeEditorWithText");
  };

  // Define the decorator strategy and component inside TextEditor
  const pillStrategy = (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void
  ): void => {
    const text = contentBlock.getText();
    //  const regex = /\{(steps|storage|authorization)\.[^}]*\}/g;
    //  const regex = /<\{(steps|storage|authorization)\.[^}]*\}>/g;
    const regex = /<\{[^{}]*\}>/g;
    let match: RegExpExecArray | null;
    match = regex.exec(text);
    while (match) {
      if (match.index !== undefined) {
        const start = match.index;
        const end = start + match[0].length;
        callback(start, end);
      }
      match = regex.exec(text);
    }
  };

  const decorator = new CompositeDecorator([
    {
      strategy: pillStrategy,
      component: (props) => <Pill {...props} />,
    },
  ]);

  const keyBindingFn = (e: React.KeyboardEvent): string | null => {
    const { hasCommandModifier } = KeyBindingUtil;
    if ((e.key === "a" || e.key === "A") && hasCommandModifier(e)) {
      return "select-all";
    }
    if ((e.key === "c" || e.key === "C") && hasCommandModifier(e)) {
      return "copy";
    }
    if (e.key === "ArrowLeft") {
      return "arrow-left";
    }

    if (e.key === "ArrowRight") {
      return "arrow-right";
    }
    return getDefaultKeyBinding(e);
  };

  // Handle custom key commands (e.g., backspace for deleting or unwrapping pills)
  const handleKeyCommand = (
    command: string,
    state: EditorState
  ): "handled" | "not-handled" => {
    if (command === "select-all") {
      const contentState = state.getCurrentContent();
      const firstBlock = contentState.getFirstBlock();
      const lastBlock = contentState.getLastBlock();

      const selection = SelectionState.createEmpty(firstBlock.getKey()).merge({
        anchorKey: firstBlock.getKey(),
        anchorOffset: 0,
        focusKey: lastBlock.getKey(),
        focusOffset: lastBlock.getLength(),
        isBackward: false,
        hasFocus: true,
      }) as SelectionState;

      const newEditorState = EditorState.forceSelection(state, selection);
      setEditorState(newEditorState, "select-all");
      return "handled";
    }

    if (command === "copy") {
      const selectionState = state.getSelection();
      if (selectionState.isCollapsed()) {
        // No text is selected
        return "handled";
      }
      const contentState = state.getCurrentContent();
      const selectedText = getSelectedText(contentState, selectionState);
      const pillToTag = convertToTagFormat(selectedText);
      if (pillToTag) {
        navigator.clipboard
          .writeText(pillToTag)
          .then(() => {
            console.log("Copied to clipboard");
          })
          .catch((err) => {
            console.error("Could not copy text: ", err);
          });
      }
      return "handled";
    }

    // when user press left arrow key on the pill, it should move the cursor to the left of the pill
    if (command === "arrow-left") {
      const selection = state.getSelection();
      const currentContent = state.getCurrentContent();
      const currentBlock = currentContent.getBlockForKey(
        selection.getStartKey()
      );
      const blockText = currentBlock.getText();
      const start = selection.getStartOffset();
      if (
        blockText.substring(start - 2, start) === "}>" ||
        blockText.substring(start - 1, start) === ZERO_WIDTH_NBSP
      ) {
        const pillStartIndex = blockText.lastIndexOf("<{", start - 1);
        const newSelection = selection.merge({
          anchorOffset: pillStartIndex - 1, // -1 to move the cursor to the left of the pill
          focusOffset: pillStartIndex - 1,
        }) as SelectionState;
        const newEditorState = EditorState.forceSelection(state, newSelection);
        setEditorState(newEditorState, "arrow-left");
        return "handled";
      }

      const newSelection = selection.merge({
        anchorOffset: start - 1, // -1 to move the cursor to the left of the pill
        focusOffset: start - 1,
      }) as SelectionState;
      const newEditorState = EditorState.forceSelection(state, newSelection);
      setEditorState(newEditorState, "arrow-left");
      return "handled";
    }

    // when user press right arrow key on the pill, it should move the cursor to the right of the pill
    if (command === "arrow-right") {
      const selection = state.getSelection();
      const currentContent = state.getCurrentContent();
      const currentBlock = currentContent.getBlockForKey(
        selection.getStartKey()
      );
      const blockText = currentBlock.getText();
      const start = selection.getStartOffset();
      if (
        blockText.substring(start, start + 2) === "<{" ||
        blockText.substring(start, start + 1) === ZERO_WIDTH_NBSP
      ) {
        const pillEndIndex = blockText.indexOf("}>", start + 1);
        const newSelection = selection.merge({
          anchorOffset: pillEndIndex + 3, // +2 to move the cursor to the right of the pill
          focusOffset: pillEndIndex + 3,
        }) as SelectionState;
        const newEditorState = EditorState.forceSelection(state, newSelection);
        setEditorState(newEditorState, "arrow-right");
        return "handled";
      }
      const newSelection = selection.merge({
        anchorOffset: start + 1, // +1 to move the cursor to the Right of the pill
        focusOffset: start + 1,
      }) as SelectionState;
      const newEditorState = EditorState.forceSelection(state, newSelection);
      setEditorState(newEditorState, "arrow-left");
      return "handled";
    }

    if (command === "backspace") {
      const selection = state.getSelection();
      if (selection.isCollapsed()) {
        const currentContent = state.getCurrentContent();
        const currentBlock = currentContent.getBlockForKey(
          selection.getStartKey()
        );
        const blockText = currentBlock.getText();
        const start = selection.getStartOffset();
        if (
          blockText.substring(start - 2, start) === "}>" ||
          blockText.substring(start - 1, start) === ZERO_WIDTH_NBSP
        ) {
          const blockKey = currentBlock.getKey();
          const pillStartIndex = blockText.lastIndexOf(
            `${ZERO_WIDTH_NBSP}<{`,
            start - 1
          );
          // const pillText = blockText.substring(pillStartIndex, start - 1);

          const updatedSelection = selection.merge({
            anchorKey: blockKey,
            anchorOffset: pillStartIndex,
            focusKey: blockKey,
            focusOffset: start,
          }) as SelectionState;

          // Replace the pill with the text inside the braces
          const newContentState = Modifier.replaceText(
            currentContent,
            updatedSelection,
            ""
          );

          const newEditorState = EditorState.push(
            state,
            newContentState,
            "remove-range"
          );

          setEditorState(
            EditorState.forceSelection(
              newEditorState,
              newContentState.getSelectionAfter()
            ),
            "backspace"
          );
          return "handled";
        }
      }
    }

    return "not-handled";
  };

  const insertTextAtCursor = (textToInsert: string): void => {
    // toggleDropdown(false);
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    const tag = textToInsert.includes("{") //  check if the tag contains another tag
      ? convertToPillFormat(textToInsert)
      : `${ZERO_WIDTH_NBSP}<${textToInsert}>${ZERO_WIDTH_NBSP}`;

    // Insert the text at the current cursor position
    const newContentState = Modifier.insertText(
      contentState,
      selectionState,
      `${tag}`,
      editorState.getCurrentInlineStyle()
    );

    // Move the selection to after the inserted text
    const textLength = tag.length;
    const newOffset = selectionState.getStartOffset() + textLength;
    const newSelection = selectionState.merge({
      anchorOffset: newOffset,
      focusOffset: newOffset,
    }) as SelectionState;

    // Create a new EditorState with the updated ContentState and Selection
    let newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-characters"
    );

    newEditorState = EditorState.forceSelection(newEditorState, newSelection);

    const plainText = convertToTagFormat(getEditorPlainText(newEditorState));
    debouncedSetValue(plainText);

    // Update the EditorState
    setEditorState(newEditorState, "insertTextAtCursor");
  };

  // Handle editor state changes
  const onEditorChange = (newEditorState: EditorState): void => {
    const plainText = getEditorPlainText(newEditorState);
    const bracketedText = convertToTagFormat(plainText);

    if (bracketedText !== field.value) {
      debouncedSetValue(bracketedText);
    }

    if (!isFocusedRef.current) {
      initializeEditorWithText(plainText);
    } else {
      setEditorState(newEditorState, "onChange");
    }
  };

  const formatJson = (): void => {
    try {
      const content = editorState.getCurrentContent();
      const plainText = content.getPlainText();
      const pillToTag = convertToTagFormat(plainText);
      const json = JSON.parse(pillToTag);
      const formattedJson = JSON.stringify(json, null, 2);
      initializeEditorWithText(formattedJson);
    } catch (e) {
      console.error(e);
    }
  };

  const isJsonValid =
    mode === "json"
      ? isValidJson(editorState.getCurrentContent().getPlainText())
      : false;

  return (
    <div
      ref={divRef}
      className="tag-editor-wrapper"
      onClick={() => {
        if (editorRef.current && !isFocusedRef.current) {
          isFocusedRef.current = true;
          editorRef.current.focus();
          setTouched(true);
          if (!showAddTagButton) {
            toggleDropdown(true);
          }
        }
      }}
    >
      <div className="tag-editor--header">
        {title ? <div className="tag-editor--title">{title}</div> : <div />}
        <div className="tag-editor--controls">
          {controls}
          {mode === "json" && (
            <div className="format-json">
              <Button
                variation="link"
                action={(e) => {
                  e.stopPropagation();
                  formatJson();
                }}
                disabled={!isJsonValid}
                tooltip="Invalid JSON"
                disableTooltip={isJsonValid}
              >
                Beautify
              </Button>
            </div>
          )}
          {showAddTagButton && (
            <TagMenuWrapper
              appendedCustomTags={appendedCustomTags}
              includeArraysOnly={includeArraysOnly}
              toggleDropdown={setShowAddTagDropdown}
              showDropdown={showAddTagDropdown}
              insertTextAtCursor={insertTextAtCursor}
              currentStepPath={currentStepPath}
              parentElement={divRef.current}
              menuWidth="300px"
              popoverStyle={{ paddingRight: "306px" }}
            >
              <div>
                <Button
                  variation="link"
                  action={(e) => {
                    e.stopPropagation();
                    setShowAddTagDropdown(true);
                  }}
                >
                  Add tag
                </Button>
              </div>
            </TagMenuWrapper>
          )}
        </div>
      </div>

      <TagMenuWrapper
        appendedCustomTags={appendedCustomTags}
        includeArraysOnly={includeArraysOnly}
        toggleDropdown={toggleDropdown}
        showDropdown={showDropdown}
        insertTextAtCursor={insertTextAtCursor}
        currentStepPath={currentStepPath}
        parentElement={divRef.current}
        setOptionAsValue={(value: string) => {
          initializeEditorWithText(value);
          debouncedSetValue(value);
        }}
        options={options}
      >
        <div>
          <div
            className={`editor integry-scrollbar-v2 ${
              disabled ? "disabled" : ""
            } ${mode === "json" ? "json-editor" : ""} ${validationClass}`}
            style={{ position: "relative" }}
          >
            <Editor
              ref={editorRef}
              editorState={editorState}
              onChange={onEditorChange}
              handleKeyCommand={handleKeyCommand}
              onFocus={() => {
                setTouched(true);
                isFocusedRef.current = true;
                if (!showAddTagButton) {
                  toggleDropdown(true);
                }
              }}
              readOnly={disabled}
              placeholder={placeholder}
              keyBindingFn={keyBindingFn}
            />
          </div>
          {isInvalid ? (
            <FormFeedback style={{ display: error ? "block" : "none" }}>
              {error}
            </FormFeedback>
          ) : (
            <>{errorPlaceHolder && <div style={{ height: 23 }} />}</> // Prevent page jumping if erroe is displayed
          )}
        </div>
      </TagMenuWrapper>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ContextWrappedTagEditor = (props): ReactElement => (
  <EditorStateProvider>
    <TagEditor {...props} />
  </EditorStateProvider>
);

const TagMenuWrapper = (props): ReactElement => {
  const {
    appendedCustomTags,
    includeArraysOnly,
    toggleDropdown,
    showDropdown,
    insertTextAtCursor,
    currentStepPath,
    parentElement,
    children,
    menuWidth = "",
    popoverStyle = {},
    setOptionAsValue,
    options,
  } = props;

  const { tagSteps } = useStepTagsContext();

  return (
    <IntegryPopover
      show={showDropdown}
      close={() => toggleDropdown(false)}
      positions={["bottom"]}
      parentElement={parentElement || undefined}
      hideArrow
      arrowPosition={{ top: 0 }}
      containerStyle={{
        paddingBottom: "0px",
        zIndex: "5",
        ...popoverStyle,
      }}
      align="start"
      popoverContent={
        <TagMenu
          showDropdown={showDropdown}
          tagSteps={tagSteps}
          key={showDropdown}
          insertTextAtCursor={insertTextAtCursor}
          includeArraysOnly={includeArraysOnly}
          menuWidth={menuWidth}
          currentStepPath={currentStepPath}
          appendedCustomTags={appendedCustomTags}
          parentElement={parentElement}
          setOptionAsValue={setOptionAsValue}
          options={options}
          closeMenu={() => toggleDropdown(false)}
        />
      }
      arrowColor="#a0a7f7"
    >
      <div>{children}</div>
    </IntegryPopover>
  );
};

export default ContextWrappedTagEditor;

interface TagMenuProps {
  showDropdown: boolean;
  tagSteps: any[];
  insertTextAtCursor: (text: string) => void;
  setOptionAsValue: (text: string) => void;
  closeMenu: () => void;
  includeArraysOnly: boolean;
  currentStepPath: string;
  parentElement: HTMLElement | null;
  appendedCustomTags: any[];
  menuWidth?: string;
  options?: { label: string; value: string }[];
}

const TagMenu = ({
  showDropdown,
  tagSteps,
  options = [],
  includeArraysOnly,
  currentStepPath,
  parentElement,
  appendedCustomTags,
  menuWidth,
  insertTextAtCursor,
  setOptionAsValue,
  closeMenu,
}: TagMenuProps): ReactElement => {
  const width = menuWidth || getElementWidth(parentElement);
  return (
    <>
      {options.length > 0 ? (
        <div className="tag-menu">
          <Tabs defaultTabLabel="Options">
            <div title="Options">
              <CommonDropdownMenu
                options={options}
                onChange={setOptionAsValue}
                closeMenu={closeMenu}
                value="option1"
              />
            </div>
            <div title="Tags">
              <div style={{ width, minWidth: "400px" }}>
                <TagOptionTree
                  show={showDropdown}
                  formikSteps={() => tagSteps}
                  key={showDropdown}
                  addTag={insertTextAtCursor}
                  includeArraysOnly={includeArraysOnly}
                  customStyle={{
                    maxWidth: "inherit",
                    width,
                    minWidth: "400px",
                  }}
                  path={currentStepPath}
                  appendedCustomTags={appendedCustomTags}
                />
              </div>
            </div>
          </Tabs>
        </div>
      ) : (
        <TagOptionTree
          show={showDropdown}
          formikSteps={() => tagSteps}
          key={showDropdown}
          addTag={insertTextAtCursor}
          includeArraysOnly={includeArraysOnly}
          customStyle={{
            maxWidth: "inherit",
            width,
          }}
          path={currentStepPath}
          appendedCustomTags={appendedCustomTags}
        />
      )}
    </>
  );
};
