import React, { Component } from "react";
import { Form } from "react-form";
import { FormGroup } from "reactstrap";
import { toast } from "react-toastify";

import Button from "components/common/reusable-button";
import TagsInput from "components/forms/tags-input";
import http from "utils/http";
import IntegryLoader from "components/integry-loader";
import "./end-user-permissions.scss";

class Developers extends Component {
  constructor(props) {
    super(props);
    this.tagInputRef = React.createRef();
  }

  state = {
    isFetching: true,
    isSaving: false,
    developers: {
      users: null,
    },
  };

  componentDidMount() {
    this.fetchUsers();
  }

  handleSubmit = (values) => {
    const currentTag = this.tagInputRef.current.refs.tags.state.tag;
    if (currentTag && currentTag.trim()) {
      // check if string isnt empty and does not contain whitespaces
      const tags = values.users ? values.users.split(", ") : [];
      tags.push(currentTag);
      const tagsString = tags.join(", ");
      values.users = tagsString;
      this.tagInputRef.current.fieldApi.setValue(tagsString);
      this.tagInputRef.current.refs.tags.clearInput();
    }

    let payload = {
      user_ids: values.users.split(","),
    };
    this.setState({ isSaving: true });
    http
      .post("/accounts/whitelist_users/", payload)
      .then(() => {
        this.setState({ isSaving: false, developers: { users: values.users } });
        toast.success(
          <p className="toast-text">
            End-User Permissions have been saved successfully.
          </p>
        );
      })
      .catch((error) => {
        this.setState({ isSaving: false });
        console.log(error);
      });
  };

  fetchUsers() {
    this.setFetching(true);
    http
      .get("/accounts/whitelist_users/")
      .then((response) => {
        let developers = {
          users: response.data.user_ids.join(","),
        };
        this.setState({
          developers: developers,
          isFetching: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  setFetching = (value) => {
    this.setState({
      isFetching: value,
    });
  };

  render() {
    const { isSaving, isFetching, developers } = this.state;
    const { users: initialValues } = developers;
    return this.state.isFetching ? (
      <IntegryLoader />
    ) : (
      <div className="account-setting-wrapper end-user-permissions">
        <h2 className="page-heading">End-User Permissions</h2>
        <div className="account-settings-form">
          <Form
            onSubmit={this.handleSubmit}
            defaultValues={this.state.developers}
          >
            {(formApi) => (
              <form
                onSubmit={formApi.submitForm}
                id="app-form"
                className="app-form"
              >
                <div className="content">
                  <FormGroup>
                    <label className="main-title" htmlFor="tags">
                      Access Draft Flows
                    </label>
                    <p id="tagsHelp" className="description">
                      Add end-user emails who can view and use draft flows in
                      deployments.
                    </p>
                    <TagsInput
                      field="users"
                      id="users"
                      type="url"
                      aria-describedby="tagsHelp"
                      className="form-control-lg"
                      placeholder="Enter User ID"
                      ref={this.tagInputRef}
                    />
                  </FormGroup>
                </div>
                <Button
                  title="Save"
                  onClick={() => ({})}
                  className="btn btn-royal-blue save-btn"
                  type="submit"
                  isLoading={isSaving}
                  disabled={
                    isFetching ||
                    isSaving ||
                    initialValues === formApi.values.users
                  }
                />
              </form>
            )}
          </Form>
        </div>
      </div>
    );
  }
}

export default Developers;
