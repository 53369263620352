import { ReactElement, useState, useRef } from "react";

import IntegryPopover from "components/integry-design-system/atoms/integry-popover-v2";
import { Step } from "features/templates/template-builder-v7/services/template-builder-interface";
import { getStepIcon } from "features/templates/utils/steps-icon";
import { getNodeByMachineName } from "utils/tree";
import { getFlowBlocksForTagMenu } from "utils/template-editor";
import config from "utils/config";

import dropdownIcon from "images/Dropdown.svg";

import TagOptionTree from "../../v3/tag-option-tree";

import "./styles.scss";

interface IProps {
  templateSteps?: Step[];
  value?: string;
  onChange?: (step: Step) => void;
}

const TemplateStepSelector = (props: IProps): ReactElement => {
  const { templateSteps = [], value = "", onChange } = props;
  const [showDropdown, toggleDropdown] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  const [, triggerBlocks] = getFlowBlocksForTagMenu({
    steps: templateSteps,
    currentStepPath: [],
    isFormPage: true,
  });
  const blocks = triggerBlocks?.steps || [];

  const seletedStep = getNodeByMachineName(
    { steps: templateSteps },
    value
  ) as Step;

  const getTitle = (): string => {
    const { title, comments, activity_type } = seletedStep;
    if (activity_type === "CODE") {
      return config.code.label;
    }
    return comments || title;
  };

  return (
    <div ref={divRef}>
      <IntegryPopover
        show={showDropdown}
        close={() => toggleDropdown(false)}
        parentElement={divRef?.current || undefined}
        positions={["bottom"]}
        hideArrow
        arrowPosition={{ top: 0 }}
        containerStyle={{
          paddingBottom: "0px",
        }}
        popoverContent={
          <div onClick={(e) => e.stopPropagation()}>
            <TagOptionTree
              show={showDropdown}
              formikSteps={() => blocks}
              key={showDropdown}
              selectedStepMachineName={value}
              onStepSelect={(step) => {
                onChange?.(step);
                toggleDropdown(false);
              }}
              addTag={() => ({})}
              flattenTree={[]}
              customStyle={{
                maxWidth: "inherit",
                width: "387px",
              }}
            />
          </div>
        }
        arrowColor="#a0a7f7"
      >
        <div
          className="template-step-selector"
          onClick={() => toggleDropdown(!showDropdown)}
        >
          {seletedStep ? (
            <>
              <div className="image-container">
                <img
                  src={getStepIcon({
                    activityType: seletedStep.activity_type,
                    step: seletedStep,
                  })}
                  alt="Icon"
                />
              </div>
              <div className="title-container txt-sm-dark-gray">
                {getTitle()}
              </div>
            </>
          ) : (
            <div className="title-placeholder txt-sm-liight-gray">
              Select a step
            </div>
          )}
          <div className="dropdown-container">
            <img src={dropdownIcon} alt="Icon" />
          </div>
        </div>
      </IntegryPopover>
    </div>
  );
};

export default TemplateStepSelector;
