import { ReactElement } from "react";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import TruncateBullets from "images/truncate_bullets.svg";

interface TruncateTextMiddleProps {
  text: string;
  maxLength?: number;
}

const TruncateTextMiddle = (props: TruncateTextMiddleProps): ReactElement => {
  const { text, maxLength = 6 } = props;
  const subStringLength = Math.floor(maxLength / 2);
  return (
    <IntegryTooltip tooltipText={text} placement="bottom">
      <div>
        {text.substring(0, subStringLength)}
        <img
          src={TruncateBullets}
          style={{
            width: "15px",
            margin: "0 2px",
            marginTop: "-3px",
            objectFit: "none",
          }}
          alt="truncate_bullets"
        />
        {text.substring(text.length - subStringLength)}
      </div>
    </IntegryTooltip>
  );
};

export default TruncateTextMiddle;
