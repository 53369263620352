/* eslint-disable @typescript-eslint/no-explicit-any */
// Redux toolkit Query to fetch actions. URL: https://beta.integry.io/api/v1/apps/209/templates/

import { createApi } from "@reduxjs/toolkit/query/react";
import { integryBaseQuery } from "utils/http";
import config from "utils/config";
import { ActivityListPaginated } from "features/templates/integration-builder/services/types/activity";

export type AddActionParams = {
  bundleId: number | string;
  templates: string[] | number[];
  actionPayload: number | string;
};

export const activityApi = createApi({
  reducerPath: "flowActivityList",
  baseQuery: integryBaseQuery({
    baseUrl: config.apiBaseURL || "",
  }),
  tagTypes: ["flowActivityList"],
  endpoints: (builder) => ({
    getActivities: builder.mutation<
      ActivityListPaginated,
      {
        searchText: string;
        page: number;
        activityType: string;
        excludeType: string;
        systemActivities: boolean;
        showElseIfElse: boolean;
        flatActivityPicker: boolean;
        excludeInitializedTrigger: boolean;
        includeLoopSystemOperations: boolean;
      }
    >({
      query: ({
        searchText,
        page,
        activityType = "",
        excludeType = "",
        systemActivities = false,
        showElseIfElse = false,
        flatActivityPicker = false,
        excludeInitializedTrigger = false,
        includeLoopSystemOperations = false,
      }) => ({
        url: `/api/v1/activities/?type=${activityType}&exclude_type=${excludeType}&page=${page}&search=${encodeURIComponent(
          searchText
        )}&page_size=10${
          systemActivities ? "&system_apps=true" : ""
        }&include_pinned_activities=${flatActivityPicker}&include_else_steps=${showElseIfElse}&exclude_initialized_trigger=${excludeInitializedTrigger}&include_loop_system_operations=${includeLoopSystemOperations}`,
        method: "get",
      }),
    }),
    listActivities: builder.query<
      ActivityListPaginated,
      {
        searchText?: string;
        page: number;
        activityType?: string;
        excludeType: string;
        systemActivities?: boolean;
        showElseIfElse?: boolean;
        flatActivityPicker?: boolean;
        excludeInitializedTrigger: boolean;
        includeLoopSystemOperations?: boolean;
        include_pinned_activities?: boolean;
        appIds?: string[];
        pageSize?: number;
        storageAppVersion?: string;
      }
    >({
      query: ({
        searchText = "",
        page,
        activityType = "",
        excludeType = "",
        systemActivities = false,
        showElseIfElse = false,
        flatActivityPicker = false,
        excludeInitializedTrigger = false,
        includeLoopSystemOperations = false,
        appIds = [],
        pageSize = 12,
        storageAppVersion = "3",
      }) => ({
        url: `/api/v1/activities/?type=${activityType}&exclude_type=${excludeType}&page=${page}&search=${encodeURIComponent(
          searchText
        )}&page_size=${pageSize}${
          systemActivities ? "&system_apps=true" : ""
        }&include_pinned_activities=${flatActivityPicker}&include_else_steps=${showElseIfElse}&exclude_initialized_trigger=${excludeInitializedTrigger}&include_loop_system_operations=${includeLoopSystemOperations}&app_ids=${appIds.join(
          ","
        )}&storage_app_version=${storageAppVersion}`,
        method: "get",
      }),
      transformResponse: (response: ActivityListPaginated, meta, args) => {
        return {
          ...response,
          current_page: args.page || 1,
          next_page: args.page + 1,
          has_more: response.total_pages > args.page,
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.activityType}-${queryArgs.excludeType}-${queryArgs.showElseIfElse}-${queryArgs.excludeInitializedTrigger}-${queryArgs.includeLoopSystemOperations}-${queryArgs.searchText}`;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, { arg }) => {
        if (arg.page === 1) {
          return newItems;
        }
        return {
          ...newItems,
          results: [...currentCache.results, ...newItems.results],
        };
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        if (currentArg?.activityType !== previousArg?.activityType) {
          return true;
        }
        if (currentArg?.excludeType !== previousArg?.excludeType) {
          return true;
        }
        if (currentArg?.showElseIfElse !== previousArg?.showElseIfElse) {
          return true;
        }
        if (
          currentArg?.excludeInitializedTrigger !==
          previousArg?.excludeInitializedTrigger
        ) {
          return true;
        }
        if (
          currentArg?.includeLoopSystemOperations !==
          previousArg?.includeLoopSystemOperations
        ) {
          return true;
        }
        if (currentArg?.searchText !== previousArg?.searchText) {
          return true;
        }
        if (currentArg?.page !== previousArg?.page) {
          return true;
        }
        if (currentArg?.systemActivities !== previousArg?.systemActivities) {
          return true;
        }
        if (currentArg?.storageAppVersion !== previousArg?.storageAppVersion) {
          return true;
        }
        if (currentArg?.appIds?.join(",") !== previousArg?.appIds?.join(",")) {
          return true;
        }
        return false;
      },
      providesTags: ["flowActivityList"],
      keepUnusedDataFor: 60 * 60 * 1000, // 1 hour
    }),
  }),
});

export const { useGetActivitiesMutation, useListActivitiesQuery } = activityApi;
