/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  useContext,
  ReactNode,
  useMemo,
  ReactElement,
} from "react";
import { useFormikContext } from "formik";
import {
  getFunctionParameterTags,
  getStorageTags,
} from "features/templates/utils/steps-tags";

import {
  getFlowBlocksForTagMenu,
  getTemplateTags,
} from "utils/template-editor";
import useVariableTags from "features/templates/hooks/useVariableTags";

import { Template } from "../template-builder-v7/services/template-builder-interface";
import { useTemplateTypeContext } from "./TemplateTypeContext";
import { TemplateType } from "../interface/templateType";

// Define the context value types
interface StepsTagContexttValue {
  tagSteps: any[]; // Replace with a more specific type if possible
  tagDictionary: {
    steps: Record<string, any>; // Replace with a more specific type if possible
    storage: {
      workspace: Record<string, any>; // Replace with a more specific type if possible
      user: Record<string, any>; // Replace with a more specific type if possible
      run: Record<string, any>; // Replace with a more specific type if possible
      integration: Record<string, any>; // Replace with a more specific type if possible
    };
    authorization: {
      token: {
        access_token: string;
        extras: Record<string, any>; // Replace with a more specific type if possible
        authorization_id: string;
        user_identity: string;
      };
    };
    parameters: Record<string, any>; // Replace with a more specific type if possible
  };
}

// Create the context
const StepsTagContextt = createContext<StepsTagContexttValue | undefined>(
  undefined
);

// Provider component
export const StepsTagsProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const { workspaceVariableTags, userVariablesTags } = useVariableTags();
  const { templateType } = useTemplateTypeContext();
  const { values } = useFormikContext<Template>();
  const { steps } = values;

  const storageTags = getStorageTags(steps);

  const parametersTags =
    templateType === TemplateType.FUNCTION
      ? getFunctionParameterTags(values)
      : null;

  const tagDictionary = useMemo(
    () => ({
      steps: getTemplateTags({
        tree: values,
        childrenName: "steps",
        includeAllTags: true,
      }),
      storage: {
        workspace: workspaceVariableTags?.customTags || {},
        user: userVariablesTags?.customTags || {},
        run: storageTags?.runStorageTags?.customTags || {},
        integration: storageTags?.IntegrationStorageTags?.customTags || {},
      },
      authorization: {
        token: {
          access_token: "",
          extras: {},
          authorization_id: "",
          user_identity: "",
        },
      },
      parameters: parametersTags?.customTags || {},
    }),
    [
      values,
      workspaceVariableTags,
      userVariablesTags,
      storageTags,
      parametersTags,
    ]
  );

  const tagSteps = useMemo(
    () => [
      ...getFlowBlocksForTagMenu({
        steps,
      }),
      ...(parametersTags ? [parametersTags] : []),
      storageTags?.runStorageTags,
      storageTags?.IntegrationStorageTags,
      workspaceVariableTags,
      userVariablesTags,
    ],
    [
      steps,
      storageTags,
      workspaceVariableTags,
      userVariablesTags,
      parametersTags,
    ]
  );

  // Provide the context value
  return (
    <StepsTagContextt.Provider value={{ tagSteps, tagDictionary }}>
      {children}
    </StepsTagContextt.Provider>
  );
};

// Hook to use the context
export const useStepTagsContext = (): StepsTagContexttValue => {
  const context = useContext(StepsTagContextt);
  if (!context) {
    throw new Error(
      "useTagMenuStepsContext must be used within a TagMenuStepsProvider"
    );
  }
  return context;
};
