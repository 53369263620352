import React, { ReactElement, useState, useCallback } from "react";
//  eslint-disable-next-line import/no-extraneous-dependencies
import Cropper from "react-easy-crop";
import Button from "components/integry-design-system/molecules/button";
import ModalContent from "../../atom/modal-content";

import { getCroppedImgFile } from "../../../utils/crop-image";

import "./styles.scss";

interface Props {
  imgSrc: string;
  imgName?: string;
  onDiscard?: () => void;
  cropSquare?: boolean;
  onSave?: (croppedImage: File) => void;
  cropSize: {
    width: number;
    height: number;
  };
}

const ImageCropper = ({
  imgSrc,
  imgName = "image",
  onDiscard,
  onSave,
  cropSize,
  cropSquare = false,
}: Props): ReactElement => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const handleDiscard = (): void => {
    if (onDiscard) {
      onDiscard();
    }
  };

  const handleSave = (image: File): void => {
    if (onSave) {
      onSave(image);
      handleDiscard();
    }
  };

  const onCropChange = (cropValues): void => {
    setCrop(cropValues);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixelsValue) => {
    setCroppedAreaPixels(croppedAreaPixelsValue);
  }, []);

  const onZoomChange = (zoomValue): void => {
    setZoom(zoomValue);
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImageTemp = await getCroppedImgFile(
        imgSrc,
        croppedAreaPixels,
        imgName,
        cropSize
      );
      setCroppedImage(croppedImageTemp);
      handleSave(croppedImageTemp);
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppedAreaPixels, imgSrc]);
  return (
    <ModalContent
      onOverlayClick={() => {
        handleDiscard();
      }}
    >
      <div className="image-cropper">
        <div className="crop-container">
          {croppedImage && (
            <img src={URL.createObjectURL(croppedImage)} alt="img" />
          )}
          {!croppedImage && (
            <>
              {cropSquare ? (
                <Cropper
                  image={imgSrc}
                  crop={crop}
                  zoom={zoom}
                  showGrid={false}
                  aspect={1}
                  onCropChange={onCropChange}
                  onCropComplete={onCropComplete}
                  onZoomChange={onZoomChange}
                />
              ) : (
                <Cropper
                  image={imgSrc}
                  crop={crop}
                  zoom={zoom}
                  showGrid={false}
                  cropSize={cropSize}
                  onCropChange={onCropChange}
                  onCropComplete={onCropComplete}
                  onZoomChange={onZoomChange}
                />
              )}
            </>
          )}
        </div>
        <div className="crop-actions">
          <Button size="large" variant="solid" action={showCroppedImage}>
            Save
          </Button>
          <Button
            size="large"
            variant="solid"
            variation="cancel"
            action={handleDiscard}
          >
            Discard
          </Button>
          {/* <button
            className="btn-submitt"
            type="button"
            onClick={() => {
              showCroppedImage();
            }}
          >
            Save
          </button>
          <button
            className="btn-discard"
            type="button"
            onClick={() => {
              handleDiscard();
            }}
          >
            Discard
          </button> */}
        </div>
      </div>
    </ModalContent>
  );
};

export default ImageCropper;
