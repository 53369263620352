/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  ReactElement,
  useRef,
  useState,
  useEffect,
} from "react";
import http from "utils/http";
import MediaPreviewComponent from "components/integry-design-system/molecules/media-preview-component";
import { renderApiError } from "components/common/toast-utils";
import uploadIcon from "images/upload-media-icon.svg";
import "./styles.scss";

// eslint-disable-next-line no-shadow
export enum FileType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  FILE = "FILE",
}

export interface IUploadFile {
  id?: string;
  URL: string;
  type: FileType;
  file?: any;
  uploading?: boolean;
  index: number;
}

export interface IMedia {
  id: string;
  URL: string;
  type: FileType;
  index: number;
}

interface IMediaUploadComponent {
  allowedUploadMediaType?: string;
  allowedMediaLabel?: string;
  pasteFieldPlaceholder?: string;
  handleUploadMedia: (files: IUploadFile[]) => void;
  media: IMedia[];
  onUploadComplete?: () => void;
  hidePreview?: boolean;
  singleUpload?: boolean;
  showVideoPreviews?: boolean;
}

/** NOTE: To check all allowed media types goto following link
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 * */

const MediaUploadComponent = (props: IMediaUploadComponent): ReactElement => {
  const {
    allowedUploadMediaType = "image/png,image/jpg,image/jpeg",
    allowedMediaLabel = "Supports: JPG, JPEG, PNG",
    handleUploadMedia,
    media,
    pasteFieldPlaceholder = "Paste an image or video URL",
    onUploadComplete,
    hidePreview = false,
    singleUpload = false,
    showVideoPreviews = false,
  } = props;
  const [uploadFiles, setUploadFiles] = useState<IUploadFile[]>(media);
  const [isLoadingURL, setIsLoadingURL] = useState(false);
  const [fieldError, setFieldError] = useState("");
  const fileInpuRef = useRef<HTMLInputElement>(null);
  const newFiletoUploadRef = useRef(false);
  const pasteInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (uploadFiles.length > 0) {
      if (newFiletoUploadRef.current === true) {
        const anyFileUploading = uploadFiles.findIndex((fileObj) => {
          return fileObj.uploading === true;
        });
        if (anyFileUploading === -1) {
          handleUploadMedia(uploadFiles);
          newFiletoUploadRef.current = false;
        }
      } else {
        handleUploadMedia(uploadFiles);
      }
    } else {
      handleUploadMedia([]);
    }
  }, [uploadFiles]);

  const triggerFileSelection = (): void => {
    if (fileInpuRef.current) {
      fileInpuRef.current.click();
    }
  };

  const getFileType = (fileType: string): FileType | null => {
    if (fileType.split("/")[0] === "image") {
      return FileType.IMAGE;
    }
    if (fileType.split("/")[0] === "video") {
      return FileType.VIDEO;
    }
    return null;
  };

  const processSelectedFiles = (selectedFilesRaw: File[]): void => {
    selectedFilesRaw?.forEach((file, index) => {
      const fileObj = {
        index: Math.floor(Math.random() * 100 + 1) + index,
        file,
        URL: URL.createObjectURL(file),
        type: getFileType(file.type),
        uploading: true,
      } as IUploadFile;
      validateAndUploadFile(fileObj);
    });
  };

  const handleFileSelection = (event: ChangeEvent<HTMLInputElement>): void => {
    const selectedFilesRaw = Array.from(event.target.files || []);
    processSelectedFiles(selectedFilesRaw);
    if (fileInpuRef.current) {
      fileInpuRef.current.value = "";
    }
  };

  const dropHandler = (ev: any): void => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    // Use DataTransfer interface to access the file(s)
    const selectedFilesRaw = [...ev.dataTransfer.files];
    processSelectedFiles(selectedFilesRaw);
  };

  const dragOverHandler = (ev: any): void => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  };

  const validateAndUploadFile = (fileObj: IUploadFile): void => {
    const allowedUploadMediaTypeArray = allowedUploadMediaType.split(",");
    if (allowedUploadMediaTypeArray.includes(fileObj.file.type)) {
      if (fileObj.file.type.includes("image")) {
        const { URL } = fileObj;
        const image = new Image();
        image.onload = () => {
          newFiletoUploadRef.current = true;
          if (singleUpload) {
            setUploadFiles([fileObj]);
          } else {
            setUploadFiles((prev) => [...prev, fileObj]);
          }
          sendFileUploadRequest(fileObj);
        };
        image.src = URL;
        if (onUploadComplete) {
          onUploadComplete();
        }
      } else {
        if (singleUpload) {
          setUploadFiles([fileObj]);
        } else {
          setUploadFiles((prev) => [...prev, fileObj]);
        }
        sendFileUploadRequest(fileObj);
        if (onUploadComplete) {
          onUploadComplete();
        }
      }
    } else {
      renderApiError(
        "Failed to upload file",
        "Please upload files with supported types"
      );
    }
  };

  const sendFileUploadRequest = (fileObj: IUploadFile): void => {
    if (fileObj.file) {
      const requestData = new FormData();
      requestData.set("file", fileObj.file);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      http
        .post("/api/files/", requestData, config)
        .then((response) => {
          setFileUploadingStatus(fileObj.index, false, response.data.file_path);
        })
        .catch((error) => {
          renderApiError("Failed to upload file", error.message);
        });
    }
  };

  const setFileUploadingStatus = (
    fileIndex: number,
    uploading: boolean,
    url: string
  ): void => {
    setUploadFiles((prev) => {
      const updatedUploadedFiles = prev.map((fileObj) =>
        fileObj.index === fileIndex
          ? { ...fileObj, uploading, URL: url || fileObj.URL }
          : fileObj
      );
      return [...updatedUploadedFiles];
    });
  };

  const removeFile = (fileObj: IUploadFile): void => {
    const updatedUploadedFiles = uploadFiles.filter((tempFile) => {
      return tempFile.index !== fileObj.index;
    });
    setUploadFiles([...updatedUploadedFiles]);
  };

  const addFile = (fileObj: IUploadFile): void => {
    newFiletoUploadRef.current = true;
    setUploadFiles((prev) => {
      if (singleUpload) {
        return [fileObj];
      }
      return [...prev, fileObj];
    });
  };

  const handleURLChange = (event: any): void => {
    if (pasteInputRef.current && pasteInputRef.current.value) {
      const urlValue = pasteInputRef.current.value;
      if (isValidUrl(urlValue)) {
        setFieldError("");
        setIsLoadingURL(true);
        const contentType = getTypeFromURLString(urlValue);
        if (contentType) {
          setTimeout(() => {
            addFile({
              URL: urlValue,
              type: contentType,
              index: Math.floor(Math.random() * 100 + 1),
            });
            clearPasteInput();
            setIsLoadingURL(false);
          }, 300);
        } else {
          getTypeFromURL(urlValue);
        }
      } else {
        setFieldError("Please enter a valid URL");
      }
    } else {
      setFieldError("");
    }
  };

  const clearPasteInput = (): void => {
    if (pasteInputRef.current) {
      pasteInputRef.current.value = "";
    }
  };

  const getTypeFromURLString = (url: string): FileType | null => {
    const images = ["jpg", "jpeg", "png"];
    const videos = ["mp4"];

    const urlObj = new URL(url);
    const extension = urlObj.pathname.split(".")[1];
    if (images.includes(extension)) {
      return FileType.IMAGE;
    }
    if (videos.includes(extension)) {
      return FileType.VIDEO;
    }
    if (
      !showVideoPreviews &&
      !images.includes(extension) &&
      !videos.includes(extension)
    ) {
      return FileType.FILE;
    }
    return null;
  };

  const getTypeFromURL = (url: string): void => {
    const image = new Image();
    image.onload = () => {
      setTimeout(() => {
        addFile({
          URL: url,
          type: FileType.IMAGE,
          index: Math.floor(Math.random() * 100 + 1),
        });
        clearPasteInput();
        setIsLoadingURL(false);
      }, 300);
    };
    image.onerror = () => {
      if (isValidVideoLink(url)) {
        setTimeout(() => {
          addFile({
            URL: url,
            type: FileType.VIDEO,
            index: Math.floor(Math.random() * 100 + 1),
          });
          clearPasteInput();
          setIsLoadingURL(false);
        }, 300);
      } else {
        sendFetchURLContentRequest(url);
      }
    };
    image.src = url;
  };

  const isValidVideoLink = (url: string): boolean => {
    const youtubeRegExp =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url.match(youtubeRegExp)) {
      return true;
    }
    const dailymotionRegExp =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:dailymotion\.com\/(?:video\/))((\w){7,})(?:\S+)?$/;
    if (url.match(dailymotionRegExp)) {
      return true;
    }
    const vimeoRegExp =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:vimeo\.com\/)((\d){4,})(?:\S+)?$/;
    if (url.match(vimeoRegExp)) {
      return true;
    }

    return false;
  };

  const sendFetchURLContentRequest = (url: string): void => {
    fetch(url, { method: "HEAD" })
      .then((res) => {
        if (res.headers) {
          const contentType = res.headers.get("Content-Type");
          if (contentType && contentType.startsWith("image")) {
            addFile({
              URL: url,
              type: FileType.IMAGE,
              index: Math.floor(Math.random() * 100 + 1),
            });
            clearPasteInput();
          } else if (contentType && contentType.startsWith("video")) {
            addFile({
              URL: url,
              type: FileType.VIDEO,
              index: Math.floor(Math.random() * 100 + 1),
            });
            clearPasteInput();
          } else {
            setFieldError("Please enter an image or video URL");
          }
        }
        setIsLoadingURL(false);
      })
      .catch((error) => {
        setIsLoadingURL(false);
        setFieldError("Cannot load resource");
        renderApiError("Cannot load resource from URL", error.message);
      });
  };

  const isValidUrl = (urlString): boolean => {
    const urlPattern = new RegExp(
      "^(https:\\/\\/){1}" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,})" + // validate domain name
        // "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        // "(\\:\\d+)?" + // validate port
        "(\\/[-a-z\\d%_.~+:,=-]*)*" + // validate path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  };
  // const hideUploader = singleUpload ? uploadFiles.length > 0 : false;
  const hideUploader = false;
  return (
    <div className="media-upload-component display-state">
      {!hideUploader && (
        <div className="upload-controls-wrap">
          <div className="upload-controls">
            <input
              className="hidden-file-input"
              type="file"
              ref={fileInpuRef}
              multiple
              accept={allowedUploadMediaType}
              onChange={handleFileSelection}
            />
            <div
              className="file-upload-control"
              onDrop={dropHandler}
              onDragOver={dragOverHandler}
            >
              <img
                className="drop-upload-icon"
                alt="upload-icon"
                src={uploadIcon}
              />
              <div className="control-label">
                <p>
                  Drop your files here or{" "}
                  <span
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        triggerFileSelection();
                      }
                    }}
                    onClick={triggerFileSelection}
                    className="link"
                  >
                    browse
                  </span>
                </p>
              </div>
              <div className="control-description">{allowedMediaLabel}</div>
            </div>
            <div className="control-separator">Or</div>
            <div className="paste-url-control">
              <div className="paste-url-field-wrap">
                <input
                  className="paste-url-field"
                  placeholder={
                    pasteFieldPlaceholder || "Paste an image or video URL"
                  }
                  // onChange={handleURLChange}
                  ref={pasteInputRef}
                />
                {!isLoadingURL && (
                  <span
                    className="span-upload-cta"
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleURLChange(e);
                      }
                    }}
                    onClick={handleURLChange}
                  >
                    Upload
                  </span>
                )}
                {isLoadingURL && <span className="integry-spinner-sm" />}
                {fieldError && (
                  <div className="invalid-feedback">{fieldError}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {!hidePreview && uploadFiles.length > 0 && (
        <div
          className="media-display"
          style={
            singleUpload
              ? {
                  justifyContent: "center",
                  width: "100%",
                }
              : {}
          }
        >
          {uploadFiles.map((file: any) => {
            if (!file.type || file.type === FileType.FILE) {
              return (
                <MediaPreviewComponent
                  previewFile={file}
                  handleRemoveClick={removeFile}
                  simpleView
                />
              );
            }
            return (
              <MediaPreviewComponent
                previewFile={file}
                handleRemoveClick={removeFile}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MediaUploadComponent;
