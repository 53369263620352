/* eslint-disable no-nested-ternary */
import { createApi } from "@reduxjs/toolkit/query/react";
import { IPageConfig } from "components/integry-design-system/molecules/pagination";
import config from "utils/config";
import http, { integryBaseQuery } from "utils/http";
import { convertDateToLocal } from "utils/functions";
import { convertDateToUTCString } from "utils/date-utils";
import { AxiosPromise } from "axios";
import actionsIcon from "features/end-users/images/actions-icon.svg";
import flowsIcon from "features/end-users/images/flows-icon.svg";
import {
  EndUser,
  EndUserConnectedAccountsPaginated,
  UserApps,
  UserActions,
} from "features/end-users/services/interface";
import { lastRunLabels } from "features/integration-runs";

import { TrasformedEndUserPaginated } from "../interface";

const getDFlag = (dFlag: string | undefined): string => {
  return dFlag ? `&d=${dFlag}` : "";
};

export const endUsersApi = createApi({
  reducerPath: "endUsers",
  baseQuery: integryBaseQuery({
    baseUrl: config.apiBaseURL || "",
  }),
  tagTypes: ["endUsers"],
  endpoints(build) {
    return {
      getEndUsers: build.query<
        TrasformedEndUserPaginated,
        {
          page: IPageConfig;
          ordering: string;
          filters?: string;
          dFlag?: string;
          pinLoggedInUser?: boolean;
        }
      >({
        query: ({
          page,
          ordering,
          filters = "",
          dFlag,
          pinLoggedInUser = false,
        }) => ({
          url: `/api/v1/app-users/?page=${page.page}&page_size=${
            page.pageSize
          }&ordering=${ordering}&get_runs=true${filters}${
            dFlag ? `&d=${dFlag}` : ""
          }&pin_logged_in_user=${pinLoggedInUser}`,
          method: "get",
        }),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        transformResponse: (response: TrasformedEndUserPaginated) => {
          return {
            ...response,
            results: response.results?.map((user) => ({
              ...user,
              last_run_start_time: user.last_run_start_time
                ? convertDateToUTCString(user.last_run_start_time)
                : "No runs as yet",
              last_run_full:
                convertDateToUTCString(user.last_run_start_time, true) ??
                "No runs as yet",
              last_run_tooltip: !user.last_run_start_time
                ? "No runs as yet"
                : `Last run at ${convertDateToUTCString(
                    user.last_run_start_time,
                    true
                  )} -- ${lastRunLabels[user.last_run_status]}`,
              runs_this_month_tooltip: getRunsColumnTooltip(
                user.runs_this_month,
                user.monthly_tasks_limit
              ),
              integrations_count_tooltip: `${
                user.integrations_count
              } integration${user.integrations_count === 1 ? "" : "s"}`,
              connected_accounts_count_tooltip: `${
                user.connected_accounts_count
              } connected account${
                user.connected_accounts_count === 1 ? "" : "s"
              }`,
              created: convertDateToUTCString(user.created) ?? "N/A",
              created_tooltip:
                convertDateToUTCString(user.created, true) ?? "N/A",
              runs_this_month_title: `${user.runs_this_month.toLocaleString()} (${getPercentage(
                user.runs_this_month,
                user.monthly_tasks_limit
              )}%)`,
              total: user.monthly_tasks_limit ?? "0",
              allowed_limit: user.monthly_tasks_limit || 0,
            })),
          };
        },
        providesTags: ["endUsers"],
      }),
      getEndUserApps: build.query<
        UserApps,
        { ordering: string; filters: string; selectedUserId; dFlag?: string }
      >({
        query: ({ ordering, filters, selectedUserId, dFlag }) => ({
          url: `/api/v7/app_users/${selectedUserId}/apps/?ordering=${ordering}&get_runs=true${filters}${getDFlag(
            dFlag
          )}`,
          method: "get",
        }),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        transformResponse: (response: any, meta, arg) => {
          return {
            ...response,
            results: response.map((app) => ({
              id: app.id,
              // eslint-disable-next-line no-unneeded-ternary
              error: app.error_code ? true : false,
              error_text: app.error_code ?? "Auth Expired",
              name:
                (app.accounts || []).length > 1
                  ? `${app.name} (${app.accounts.length} accounts)`
                  : app.name,
              icon_url: app.icon_url,
              flows_count: app.flows_count,
              actions_count: app.actions_count,
              total: app.total,
              userId: arg.selectedUserId,
              current_month: app.current_month,
              previous_month: app.previous_month,
              last_run: convertDateToLocal(app.last_run) || "N/A",
              instancesCount: `${app.flows_count + app.actions_count} actions`,
              actions:
                app.flows_count + app.actions_count > 0
                  ? [app.flows_count + app.actions_count]
                  : [],
            })),
          };
        },
        providesTags: ["endUsers"],
      }),
      getEndUserActions: build.query<
        UserActions,
        {
          ordering: string;
          filters: string;
          selectedUserId;
          selectedAppId;
          dFlag?: string;
        }
      >({
        query: ({
          ordering,
          filters,
          selectedUserId,
          selectedAppId,
          dFlag,
        }) => ({
          url: `/api/v7/app_users/${selectedUserId}/apps/${selectedAppId}/templates/?ordering=${ordering}&get_runs=true${filters}${getDFlag(
            dFlag
          )}`,
          method: "get",
        }),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        transformResponse: (response: any, meta, arg) => {
          return {
            ...response,
            results: response.map((action) => ({
              id: action.id,
              // eslint-disable-next-line no-unneeded-ternary
              error: action.error_code ? true : false,
              error_text: action.error_code ?? "Auth Expired",
              name: action.name,
              instancesCount:
                (action.integration_ids || []).length > 1
                  ? `(${action.integration_ids.length} integrations)`
                  : "",
              icon_url: action.is_direct_action ? actionsIcon : flowsIcon,
              flows_count: "",
              actions_count: "",
              total: action.total,
              userId: arg.selectedUserId,
              current_month: action.current_month,
              previous_month: action.previous_month,
              is_direct_action: action.is_direct_action,
              integration_ids: action.integration_ids,
              singleInstance: !((action.integration_ids || []).length > 1),
              appId: arg.selectedAppId,
            })),
          };
        },
        providesTags: ["endUsers"],
      }),

      getEndUserIntegrations: build.query<EndUser[], void>({
        query: () => ({
          url: "/api/v2/templates/6907/users/?range=fromStart",
          method: "get",
        }),
        providesTags: ["endUsers"],
      }),
      getEndUserConnectedAccounts: build.query<
        EndUserConnectedAccountsPaginated,
        { userId: number; page: IPageConfig }
      >({
        query: ({ userId, page }) => ({
          url: `/api/v3/app_users/${userId}/authorizations/?page=${page.page}&page_size${page.pageSize}`,
          method: "get",
        }),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        transformResponse: (response: any) => {
          return {
            ...response,
            meta: {
              appUser: {
                id: response.meta?.app_user.id,
                integrations: response.meta?.app_user.integrations_count,
                connectedAccounts:
                  response.meta?.app_user.connected_accounts_count,
                email: response.meta?.app_user.external_id,
              },
            },
            results: response.results?.map((user) => ({
              app_name: user.app_name,
              id: user.id,
              integrations_count: user.integrations_count,
              user_identity: user.user_identity,
            })),
          };
        },
        providesTags: ["endUsers"],
      }),
      getConnectedAccountsIntegrations: build.query<EndUser[], void>({
        query: () => ({
          url: "/api/v2/templates/6907/users/?range=fromStart",
          method: "get",
        }),
        providesTags: ["endUsers"],
      }),
    };
  },
});

export const setBetaUser = (
  userId: number,
  isBetaUser = false
): AxiosPromise => {
  return http.post(`/api/v1/app-users/${userId}/set-beta/`, {
    is_beta_user: isBetaUser,
  });
};

export const {
  useGetEndUsersQuery,
  useLazyGetEndUsersQuery,
  useGetEndUserAppsQuery,
  useGetEndUserActionsQuery,
  useGetEndUserIntegrationsQuery,
  useGetEndUserConnectedAccountsQuery,
  useGetConnectedAccountsIntegrationsQuery,
} = endUsersApi;

const getPercentage = (value: number, total: number): string => {
  const precentage = total > 0 ? (value / total) * 100 : 0;
  return (Math.round(precentage * 100) / 100).toFixed(0);
};

const getRunsColumnTooltip = (
  runsThisMonth: number,
  totalAllowedRuns: number
): string => {
  const usagePercent = getPercentage(runsThisMonth, totalAllowedRuns);
  return `User has consumed ${usagePercent}% of their monthly usage limit of ${totalAllowedRuns.toLocaleString()} runs`;
};
