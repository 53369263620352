// react component for split button
import { ReactElement, useRef } from "react";
import { useField } from "formik";

import RecursiveTags from "components/forms/v2/recursive-tags";
import CreatableDropdown from "components/integry-design-system/atoms/dropdown/creatable-dropdown/createable-dropdown";
import ToggleSectionIcon from "components/integry-design-system/atoms/toggle-section-icon";

import { getElementWidth } from "utils/html-element-utils";

import "./ObjectPathDropdown.scss";

interface CommonProps {
  placeholder?: string;
  className?: string;
  isDisabled?: boolean;
  onChange: (value: string) => void;
  payload: { [key: string]: unknown };
  popoverAlign?: "start" | "end";
}

interface ObjectPathDropdownProps extends CommonProps {
  value: string;
}

const ObjectPathDropdown = (props: ObjectPathDropdownProps): ReactElement => {
  const {
    onChange,
    value,
    placeholder = "Select",
    className = "",
    payload,
    isDisabled = false,
    popoverAlign = "start",
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <div
      className={`object-path-dropdown ${className}`}
      onClick={(e) => e.stopPropagation()}
      ref={containerRef}
    >
      <CreatableDropdown
        popoverAlign={popoverAlign}
        renderControl={({ isMenuOpen }) => {
          return (
            <div
              className={`object-path-dropdown__control ${
                isDisabled ? "disabled" : ""
              }`}
            >
              <input
                type="url"
                autoComplete="off"
                title=""
                disabled={isDisabled}
                value={value}
                placeholder={placeholder}
                style={{ width: "100%", boxSizing: "border-box" }}
                className="txt-sm-dark-gray object-path-dropdown__control-input"
              />
              <ToggleSectionIcon isExpanded={!isMenuOpen} />
            </div>
          );
        }}
        renderMenu={({ closeMenu }) => (
          <div
            className="payload-select-menu integry-scrollbar-v2"
            style={{ minWidth: getElementWidth(containerRef.current) }}
          >
            <>
              {Object.keys(payload).length === 0 && (
                <div className="text-mid-gray">No fields available</div>
              )}
              {Object.keys(payload).map((key) => (
                <>
                  <RecursiveTags
                    objectKey={key}
                    value={payload[key]}
                    hideAddFieldButton
                    addTag={(e) => ({})}
                    root
                    selected
                    customCreateTagFunction={({ tag }) => {
                      onChange(tag);
                      closeMenu();
                    }}
                  />
                </>
              ))}
            </>
          </div>
        )}
      />
    </div>
  );
};

interface FormFieldObjectPathDropdownProps extends CommonProps {
  name: string;
}

const FormFieldObjectPathDropdown = (
  props: FormFieldObjectPathDropdownProps
): ReactElement => {
  const { name } = props;
  const [field, , helpers] = useField(name);
  return (
    <ObjectPathDropdown
      {...props}
      onChange={(value) => {
        helpers.setValue(value);
        if (props.onChange) {
          props.onChange(value);
        }
      }}
      value={field.value}
    />
  );
};

export default FormFieldObjectPathDropdown;
