/* eslint-disable object-shorthand */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import {
  CSSProperties,
  HTMLAttributes,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import Slide from "react-reveal/Slide";

import IntegryLoader from "components/integry-loader";
import Breadcrumb, {
  ICrumb,
} from "components/integry-design-system/molecules/breadcrumb";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import Tag from "components/integry-design-system/atoms/tag";
import { IPill } from "components/integry-design-system/molecules/filter-pill";
import GroupedFilterPills from "components/integry-design-system/molecules/grouped-filter-pills";
import SearchBar from "stories/Atoms/searchbar/searchbar";
import SearchBarV3 from "components/common/search-input-field/searchbar-v3";
import MeatballsMenu from "components/integry-design-system/molecules/meatballs-menu";
import IntegryPagination from "components/integry-design-system/molecules/pagination";
import RadioSelection, {
  IRadioOptions,
} from "components/integry-design-system/molecules/radio-selection";
import DateTimePicker, {
  dateTimeTypeEnum,
} from "components/integry-design-system/molecules/date-time-picker";
import PreviewSetup from "legacy-features/integrations/preview-setup";
import CheckboxSelection from "components/integry-design-system/molecules/checkbox-selection";
import { getMyAppById } from "selectors/apps";
import { sdkQueryParams as authParams } from "components/apps-v4/utils/app-builder-utils";
import useTrack from "hooks/useTrack";

import http from "utils/http";
import lang from "utils/lang/en";
import {
  integrationViewEvents as trackEvent,
  errorNotificationEvents,
} from "utils/mixpanel";

import { convertDateToStandard, debounceFunction } from "utils/functions";

import { TableConfig } from "features/end-users/services/interface";

import {
  deleteIntegration,
  enableDisableIntegration,
} from "legacy-features/templates/v3/template-view/template-view-apis";

import FunnelIconActive from "images/filter_funnel_active.svg";
// import xIcon from "images/x.svg";
import filterSidebarCloseBtn from "images/filter-sidebar-close-btn-icon.svg";
import exlaimationIconOutline from "images/exclaimation_icon_outline.svg";
import NotFoundIcon from "images/not_found_icon_lg.svg";
import SortIconDefault from "images/sort.svg";
import SortIconDesc from "images/sort-applied.svg";
import TruncateBullets from "images/truncate_bullets.svg";
import profileLinkIcon from "images/open-new-window.svg";
import EyeBlueIcon from "images/eye_blue.svg";
import DeleteBinIcon from "images/delete_bin_red.svg";
import DisableGreyIcon from "images/disable_grey.svg";
import EnableIcon from "images/enable-icon.svg";

import "legacy-features/integrations/styles.scss";
import "./styles.scss";
import useOnClickOutside from "utils/use-onclick-outside";
import IntegryDialoguePop from "components/integry-design-system/molecules/integry-dialogue-pop";
// import useOnScreen from "utils/use-on-screen";
import ErrorNotificationSetup from "components/integry-design-system/organisms/integration-view/error-notification-setup";
import { FeatureFlag } from "react-launch-darkly";
import { useGetNotificationSettingsQuery } from "utils/accounts-api";

export interface IFetchOptions {
  search?: string | undefined | null;
  filtersQuery?: Array<IPill> | undefined | null;
  showDeleted?: boolean;
  pageConfig?: {
    page: number;
    pageSize: number;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortOrder?: any;
}
export interface IIntegration {
  integrationId: string;
  userId: string;
  appAuthId: string;
  dateCreated: string;
  lastRunStart: string;
  lastRunStatus: string;
  integrationStatus: string;
  hasRuns: boolean;
  userProfileLink?: string;
  dateCreatedFull?: string;
  lastRunStartFull?: string;
  integrationStatusTooltip?: string;
  lastPollStatus?: string;
  lastPollTime?: string;
  firstErrorReceivedAt?: string;
  errorAppName?: string;
  lastPollId?: string;
  errorCode?: number | null;
  templateId?: string;
  usage_limit_reached_at?: number | null | undefined;
  runsCount?: number;
  integrationName?: string;
  apiCallsCount?: number;
  brandingAppAccount?: string;
  runsThisMonth?: number;
  dateUpdated?: string;
  dateUpdatedFull?: string;
}

export interface IFlow {
  flowName: string;
  flowId: string;
  flowLogo: string;
  flowStatus: string;
  flowColor: string;
  isDirectAction: boolean;
  flowBrandingAppName?: string;
}

export interface IProps extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties;
  flowId: string;
  integrationIdParam?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  globalState?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  app?: any;
  showPollData?: boolean;
  showBillableRunStatus?: boolean;
  tableConfig: TableConfig[];
  loading: boolean;
  fetching: boolean;
  totalCount: number;
  integrationConfigs: IIntegration[];
  renderHeading: () => JSX.Element;
  renderSubHeading: () => JSX.Element;
  generateBreadcrumbConfig: () => Array<ICrumb>;
  renderGroupInfoHeader: () => JSX.Element;
  fetchData: (
    fId: string,
    options: IFetchOptions,
    skipFetching?: boolean
  ) => void;
  setFetching: (value: boolean) => void;
  onIntegrationOpen: (
    templateId: string,
    integrationId: string,
    integration: any
  ) => void;
  flowConfig?: IFlow | undefined | null;
  serachbarPlaceholder?: string;
  tab?: string;
  hideDateCreatedFilter?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useQuery = (): any => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const InterButton = styled(Button)<any>(({ isDisabled = false }) => ({
  fontFamily: ["Inter"],
  // font-family: 'Inter';
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "15px",
  color: "#4250F0",
  padding: "5px 10px",
  borderColor: "#4250F0",
  borderRadius: "5px",
  textTransform: "none",
  opacity: isDisabled ? 0.5 : 1,
  "&:hover": {
    backgroundColor: !isDisabled ? "#4250F0" : "#FFFFFF",
    color: !isDisabled ? "#FFFFFF" : "#4250F0",
    cursor: !isDisabled ? "pointer" : "not-allowed",
  },
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FilterButton = styled(InterButton)<any>(({ isDisabled = false }) => ({
  fontWeight: 500,
  color: "#3518FB",
  padding: "10px 9px",
  background: "rgba(53, 24, 251, 0.1)",
  borderRadius: "4px",
  opacity: 1,
  width: "68px",
  heigth: "35px",
  "&:hover": {
    backgroundColor: "rgba(53, 24, 251, 0.2)",
    color: "#3518FB",
  },
}));

const IntegrationsView = ({
  customStyle = {},
  ...props
}: IProps): JSX.Element => {
  const {
    flowId = "-1",
    globalState,
    user,
    showPollData = false,
    showBillableRunStatus = false,
    fetchData,
    renderHeading,
    renderSubHeading,
    generateBreadcrumbConfig,
    renderGroupInfoHeader,
    tableConfig,
    loading,
    fetching,
    totalCount,
    integrationConfigs,
    flowConfig,
    serachbarPlaceholder = "",
    tab = "flows",
    hideDateCreatedFilter,
    setFetching,
    onIntegrationOpen,
  } = props;
  const history = useHistory();
  const query = useQuery();

  const [filters, setFilters] = useState<Array<IPill> | [] | undefined | null>(
    []
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [sorting, setSorting] = useState<any>({
    start_time: "desc",
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [draftFilters, setDraftFilters] = useState<any>({});
  const [allowApplyCTA, setAllowApplyCTA] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");
  const [page, setPage] = useState({
    page: 1,
    pageSize: 10,
  });
  const [showFilters, setShowFilters] = useState(false);
  const [idCopied, setIdCopied] = useState("");
  const [showDeleteIntegration, setShowDeleteIntegration] = useState("-1");
  const [showPreviewSetup, setShowPreviewSetup] = useState("-1");

  const sidebarDivRef = useRef<HTMLDivElement>(null);
  const queryStringRef = useRef("");

  const {
    data: notificationSettings,
    isLoading: isNotificationSettingLoading,
    refetch: refetchNotificationSettings,
  } = useGetNotificationSettingsQuery();

  const { track } = useTrack();

  useEffect(() => {
    refetchNotificationSettings();
    queryStringRef.current = window.location.search;
    mapURLQueryStringToState();
  }, []);

  const mapURLQueryStringToState = (): void => {
    const windowQueryParams = new URLSearchParams(window.location.search);
    if (windowQueryParams.has("search")) {
      setSearchTxt(windowQueryParams.get("search") || "");
      windowQueryParams.delete("search");
    }

    if (windowQueryParams.has("include_deleted_integrations")) {
      const showDeletedParam =
        windowQueryParams.get("include_deleted_integrations") === "true";
      setShowDeleted(showDeletedParam);
    } else setShowDeleted(false);

    if (windowQueryParams.has("page")) {
      setPage({
        page: Number(windowQueryParams.get("page")) || 1,
        pageSize: Number(windowQueryParams.get("page_size")) || 10,
      });
      windowQueryParams.delete("page");
      windowQueryParams.delete("page_size");
    }
    if (windowQueryParams.has("ordering")) {
      if (windowQueryParams.get("ordering") === "last_run_start_time") {
        setSorting({ last_run_start_time: "asc" });
      } else if (windowQueryParams.get("ordering") === "created") {
        setSorting({ created: "asc" });
      } else if (windowQueryParams.get("ordering") === "-created") {
        setSorting({ created: "desc" });
      } else if (windowQueryParams.get("ordering") === "-last_run_start_time") {
        setSorting({ last_run_start_time: "desc" });
      }
      windowQueryParams.delete("ordering");
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const draftFilterConfig: any = {
      dateCreated: {
        startDate: "",
        endDate: "",
        convertionType: "timestamp",
      },
      lastRunStart: {
        startDate: "",
        endDate: "",
        convertionType: "localDateString",
      },
      lastRunStatus: "",
      integrationStatus: "",
      accountId: "",
    };
    windowQueryParams.forEach((value, key) => {
      try {
        if (key === "created_date_gt" && value) {
          draftFilterConfig.dateCreated.startDate = new Date(
            Number(value) * 1000
          ).toISOString();
        } else if (key === "created_date_lt" && value) {
          draftFilterConfig.dateCreated.endDate = new Date(
            Number(value) * 1000
          ).toISOString();
        } else if (key === "run_start_gt" && value) {
          draftFilterConfig.lastRunStart.startDate = new Date(
            Number(value) * 1000
          ).toISOString();
        } else if (key === "run_start_lt" && value) {
          draftFilterConfig.lastRunStart.endDate = new Date(
            Number(value) * 1000
          ).toISOString();
        } else if (key === "run_status" && value) {
          draftFilterConfig.lastRunStatus = value;
        } else if (key === "status" && value) {
          draftFilterConfig.integrationStatus = value;
        } else if (key === "account_id" && value) {
          draftFilterConfig.accountId = value;
        }
      } catch (e) {
        console.log("failed to set filter query string, due to invalid values");
      }
    });
    if (
      !draftFilterConfig.dateCreated.startDate &&
      !draftFilterConfig.dateCreated.endDate
    ) {
      delete draftFilterConfig.dateCreated;
    }
    if (
      !draftFilterConfig.lastRunStart.startDate &&
      !draftFilterConfig.lastRunStart.endDate
    ) {
      delete draftFilterConfig.lastRunStart;
    }
    if (!draftFilterConfig.lastRunStatus) {
      delete draftFilterConfig.lastRunStatus;
    }
    if (!draftFilterConfig.integrationStatus) {
      delete draftFilterConfig.integrationStatus;
    }
    if (!draftFilterConfig.accountId) {
      delete draftFilterConfig.accountId;
    }
    setDraftFilters(draftFilterConfig);
    const tempFilters: Array<IPill> = [];
    (Object.keys(draftFilterConfig) || []).forEach((filterKey) => {
      const filterObj = draftFilterConfig[filterKey];
      const dateKeys = ["lastRunStart", "dateCreated"];
      if (dateKeys.includes(filterKey)) {
        if (filterObj) {
          const formatedStartDate = convertDateToStandard(
            `${filterObj.startDate}`
          );
          const formatedEndDate = convertDateToStandard(`${filterObj.endDate}`);
          tempFilters.push({
            id: filterKey,
            label: filterLabels[filterKey],
            valueToShow: `${formatedStartDate} - ${formatedEndDate}`,
            value: filterObj,
          });
        }
      } else {
        tempFilters.push({
          id: filterKey,
          label: filterLabels[filterKey],
          value: filterObj,
          valueToShow: filterObj,
        });
      }
    });
    setFilters(tempFilters);
  };

  const clearIdCopied = (): void => {
    if (idCopied) {
      setIdCopied("");
    }
  };

  const allowApplyFilter = (dFilter): boolean => {
    let flag = false;
    (Object.keys(dFilter) || []).forEach((dfKey) => {
      if (dFilter[dfKey]) {
        flag = true;
      }
    });
    return flag;
  };

  const handleEnableIntegration = (
    integrationId,
    status = "enable",
    appAuthId = ""
  ): void => {
    const { myApps, globallySelectedApp } = globalState;
    if (globallySelectedApp && globallySelectedApp !== "") {
      const myAppById = getMyAppById(myApps, globallySelectedApp);
      const sdkQueryParams =
        myAppById && myAppById.secret
          ? authParams(appAuthId, myAppById.key, myAppById.secret)
          : "";
      enableDisableIntegration(integrationId, status, sdkQueryParams)
        .then((response) => {
          toast(
            <div className="user-integration-toast">
              <span className="info-text">{`The integration is ${status}d successfully!`}</span>
            </div>
          );
          fetchData(
            flowId,
            {
              search: searchTxt,
              filtersQuery: filters,
              pageConfig: page,
              sortOrder: sorting,
              showDeleted: showDeleted,
            },
            true
          );
          if (props.user) {
            track(
              status === "enable"
                ? trackEvent.ENABLE_INTEGRAITON.name
                : trackEvent.DISABLE_INTEGRAITON.name,
              {
                [trackEvent.ENABLE_INTEGRAITON.properties.STATUS]: status,
                [trackEvent.ENABLE_INTEGRAITON.properties.FLOW_NAME]: (
                  flowConfig || { flowName: "" }
                ).flowName,
                [trackEvent.ENABLE_INTEGRAITON.properties.INTEGRATION_ID]:
                  integrationId,
              }
            );
          }
        })
        .catch((e) => {
          console.error("ERROR while updating status", { e });
          toast.error(
            <div className="user-integration-toast">
              <span>Failed to {status} the Integration</span>
              <span>due to {e.response.data || e.message}</span>
            </div>
          );
        });
    } else {
      toast.error(
        <div className="user-integration-toast">
          Please select a workspace to {status} the Integration.
        </div>
      );
    }
  };

  const handleEnablePollIntegration = (integrationId, templateId): void => {
    setFetching(true);
    http
      .put(
        `api/v3/templates/${templateId}/integrations/${integrationId}/enable/`
      )
      .then((response) => {
        toast(
          <div className="user-integration-toast">
            <span className="info-text">
              The integration is enabled successfully!
            </span>
          </div>
        );
        fetchData(
          flowId,
          {
            search: searchTxt,
            filtersQuery: filters,
            pageConfig: page,
            sortOrder: sorting,
            showDeleted: showDeleted,
          },
          true
        );
      })
      .catch((e) => {
        console.error("ERROR while updating status", { e });
        setFetching(false);
        toast.error(
          <div className="user-integration-toast">
            <span>Failed to Enable the Integration</span>
            <span> due to {e.response.data || e.message}</span>
          </div>
        );
      });
  };

  const handleDeleteIntegration = (integrationId, appAuthId = ""): void => {
    const { myApps, globallySelectedApp } = globalState;
    if (globallySelectedApp && globallySelectedApp !== "") {
      const myAppById = getMyAppById(myApps, globallySelectedApp);
      const sdkQueryParams =
        myAppById && myAppById.secret
          ? authParams(appAuthId, myAppById.key, myAppById.secret)
          : "";
      deleteIntegration(integrationId, sdkQueryParams)
        .then((response) => {
          const { data = `Integration has been deleted` } = response;
          toast(
            <div className="user-integration-toast">
              <span className="info-text">{`${
                data !== "" ? data : "Integration has been deleted"
              }`}</span>
            </div>
          );
          fetchData(
            flowId,
            {
              search: searchTxt,
              filtersQuery: filters,
              pageConfig: page,
              sortOrder: sorting,
              showDeleted: showDeleted,
            },
            true
          );
          if (props.user) {
            track(trackEvent.DELETE_INTEGRAITON.name, {
              [trackEvent.DELETE_INTEGRAITON.properties.STATUS]: "delete",
              [trackEvent.DELETE_INTEGRAITON.properties.FLOW_NAME]: (
                flowConfig || { flowName: "" }
              ).flowName,
              [trackEvent.DELETE_INTEGRAITON.properties.INTEGRATION_ID]:
                integrationId,
            });
          }
        })
        .catch((e) => {
          const { response = {} } = e;
          const { data = {} } = response;
          const { detail } = data;
          toast.error(
            <div className="user-integration-toast">
              {detail || `Failed to delete the Integration`}
            </div>
          );
        });
    } else {
      toast.error(
        <div className="user-integration-toast">
          Please select a workspace to delete the Integration.
        </div>
      );
    }
  };

  const handleOnSorting = (id): void => {
    let tempSorting = {};
    tempSorting = {
      [id]: sorting[id] === "desc" ? "asc" : "desc",
    };
    setSorting(tempSorting);
    fetchData(
      flowId,
      {
        search: searchTxt,
        filtersQuery: filters,
        pageConfig: page,
        sortOrder: tempSorting,
        showDeleted: showDeleted,
      },
      false
    );
  };

  const toggleShowDeleted = (value: boolean): void => {
    setShowDeleted(value);
    setPage({
      page: 1,
      pageSize: page.pageSize,
    });
    fetchData(flowId, {
      search: searchTxt,
      filtersQuery: filters,
      showDeleted: value,
      pageConfig: {
        page: 1,
        pageSize: page.pageSize,
      },
      sortOrder: sorting,
    });
  };

  const handleOnApplyFilter = (filterConfig): void => {
    const tempFilters: Array<IPill> = [];
    (Object.keys(filterConfig) || []).forEach((filterKey) => {
      const filterObj = filterConfig[filterKey];
      const dateKeys = ["lastRunStart", "dateCreated"];
      if (dateKeys.includes(filterKey)) {
        if (filterObj) {
          const formatedStartDate = convertDateToStandard(
            `${filterObj.startDate}`
          );
          const formatedEndDate = convertDateToStandard(`${filterObj.endDate}`);
          tempFilters.push({
            id: filterKey,
            label: filterLabels[filterKey],
            valueToShow: `${formatedStartDate} - ${formatedEndDate}`,
            value: filterObj,
          });
        }
      } else {
        tempFilters.push({
          id: filterKey,
          label: filterLabels[filterKey],
          value: filterObj,
          valueToShow: filterObj,
        });
      }
    });
    setFilters(tempFilters);
    // setShowFilters(false);
    setAllowApplyCTA(false);
    setPage({
      page: 1,
      pageSize: page.pageSize,
    });
    fetchData(flowId, {
      search: searchTxt || "",
      filtersQuery: tempFilters || [],
      showDeleted: showDeleted,
      pageConfig: {
        page: 1,
        pageSize: page.pageSize,
      },
      sortOrder: sorting,
    });
    if (props.user) {
      let filterNameValue = "";
      tempFilters.forEach((filter) => {
        if (filterNameValue === "") {
          filterNameValue = `${filter.label} = ${filter.valueToShow}`;
        } else {
          filterNameValue = `${filterNameValue}, ${filter.label} = ${filter.valueToShow}`;
        }
      });
      track(trackEvent.FILTER_INTEGRAITON.name, {
        [trackEvent.FILTER_INTEGRAITON.property]: filterNameValue,
      });
    }
  };

  const handlePageChange = (pageConfig): void => {
    setPage(pageConfig);
    fetchData(flowId, {
      search: searchTxt,
      filtersQuery: filters,
      showDeleted: showDeleted,
      pageConfig,
      sortOrder: sorting,
    });
  };

  const handleSearch = (searchText, currenConfigs): void => {
    setSearchTxt(searchText);
    setPage({
      page: 1,
      pageSize: page.pageSize,
    });
    fetchData(flowId, {
      search: searchText,
      filtersQuery: filters,
      showDeleted: showDeleted,

      pageConfig: {
        page: 1,
        pageSize: page.pageSize,
      },
      sortOrder: sorting,
    });
    if (props.user && searchText !== "") {
      track(trackEvent.SEARCH_INTEGRAITON.name, {
        [trackEvent.SEARCH_INTEGRAITON.property]: searchText,
      });
    }
  };

  const handleOnFilterClear = (): void => {
    setFilters([]);
    setDraftFilters({});
    setPage({
      page: 1,
      pageSize: page.pageSize,
    });
    fetchData(flowId, {
      search: searchTxt,
      filtersQuery: [],
      showDeleted: showDeleted,

      pageConfig: {
        page: 1,
        pageSize: page.pageSize,
      },
      sortOrder: sorting,
    });
  };

  const handleOnFilterClose = (pill): void => {
    const tempFilters: Array<IPill> = [];
    (filters || []).forEach((filter) => {
      if (filter.id !== pill.id) {
        tempFilters.push(filter);
      }
    });
    setFilters(tempFilters);
    let tempDraftFilters = {};
    Object.keys(draftFilters).forEach((filterKey) => {
      if (filterKey !== pill.id) {
        tempDraftFilters = {
          ...tempDraftFilters,
          [filterKey]: draftFilters[filterKey],
        };
      }
    });
    // if (draftFilters[pill.id]) {
    //   setDraftFilters({
    //     ...draftFilters,
    //     [pill.id]: null,
    //   });
    // }
    setDraftFilters(tempDraftFilters);
    setPage({
      page: 1,
      pageSize: page.pageSize,
    });
    fetchData(flowId, {
      search: searchTxt,
      filtersQuery: tempFilters,
      showDeleted: showDeleted,

      pageConfig: {
        page: 1,
        pageSize: page.pageSize,
      },
      sortOrder: sorting,
    });
  };

  const renderSearchBar = (
    currenConfigs,
    isDisabled = false,
    parentTab
  ): React.ReactElement => {
    return parentTab === "users" ? (
      <SearchBarV3
        onSearch={(searchText) => {
          if (!isDisabled) {
            handleSearch(searchText, currenConfigs);
          }
        }}
        style={{
          width: "370px",
        }}
        // disabled={isDisabled}
        placeholder={
          serachbarPlaceholder || lang.SEARCH_INTEGRATION_PLACEHOLDER
        }
        // defaultValue={searchTxt}
        searchCallbackDependency={`filters_${JSON.stringify(
          filters
        )},page_${JSON.stringify(page)}`}
      />
    ) : (
      <SearchBar
        onSearch={(searchText) => {
          if (!isDisabled) {
            handleSearch(searchText, currenConfigs);
          }
        }}
        disabled={isDisabled}
        placeholder={
          serachbarPlaceholder || lang.SEARCH_INTEGRATION_PLACEHOLDER
        }
        defaultValue={searchTxt}
        searchCallbackDependency={`filters_${JSON.stringify(
          filters
        )},page_${JSON.stringify(page)}`}
      />
    );
  };

  const showNotificationMessage = (): boolean => {
    if (
      isNotificationSettingLoading === false &&
      notificationSettings?.is_error_notification_enabled === false
    ) {
      return true;
    }
    return false;
  };

  const onClickSetupNotifications = (): void => {
    track(errorNotificationEvents.GO_TO_ERROR_NOTIFICATIONS.name, {
      [errorNotificationEvents.GO_TO_ERROR_NOTIFICATIONS.property]:
        "All Integrations",
    });
  };

  const onClickYesInNotificationPopUp = (): void => {
    track(
      errorNotificationEvents.CONTINUE_TO_ERROR_NOTIFICATIONS_FROM_DIALOG.name,
      {
        [errorNotificationEvents.CONTINUE_TO_ERROR_NOTIFICATIONS_FROM_DIALOG
          .property]: "All Integrations",
      }
    );
  };

  const onDismissNotificationSnackbar = (): void => {
    track(errorNotificationEvents.DISMISS_ERROR_NOTIFICATIONS_SNACKBAR.name, {
      [errorNotificationEvents.DISMISS_ERROR_NOTIFICATIONS_SNACKBAR.property]:
        "All Integrations",
    });
  };

  const verifyIfColumnHasData = (id): number => {
    let nbrColumnsWithData = 0;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    integrationConfigs.forEach((integrationItem: any) => {
      if (integrationItem[id] && integrationItem[id] !== "") {
        nbrColumnsWithData += 1;
      }
    });
    return nbrColumnsWithData;
  };

  const renderTable = (): JSX.Element => {
    const offset = page.pageSize * page.page;
    return (
      <>
        <table className="integry-table">
          <thead>
            <tr>
              {tableConfig.map((configItem) => {
                if (configItem.hide) {
                  return null;
                }
                if (configItem.hideColumnIfNoData) {
                  if (verifyIfColumnHasData(configItem.id) < 1) {
                    return null;
                  }
                }
                if (configItem.tooltip) {
                  return (
                    <th key={configItem.id}>
                      {configItem.label}
                      <IntegryTooltip
                        tooltipText={
                          configItem.tooltip_nonBillable
                            ? showBillableRunStatus
                              ? configItem.tooltip
                              : configItem.tooltip_nonBillable
                            : configItem.tooltip
                        }
                        placement="top"
                      >
                        <img
                          width={15}
                          height={15}
                          src={exlaimationIconOutline}
                          style={{
                            marginLeft: "5px",
                            marginTop: "-1px",
                            transform: "rotateZ(180deg)",
                          }}
                          alt="tooltip_heading"
                        />
                      </IntegryTooltip>
                    </th>
                  );
                }
                if (configItem.allowSorting) {
                  return (
                    <th>
                      {configItem.label}
                      <img
                        width={15}
                        height={15}
                        style={{
                          marginLeft: "5px",
                          marginTop: "-2px",
                          cursor: "default",
                        }}
                        src={
                          sorting[configItem.sortingIdentifier || configItem.id]
                            ? SortIconDesc
                            : SortIconDefault
                        }
                        className={
                          sorting[
                            configItem.sortingIdentifier || configItem.id
                          ] &&
                          sorting[
                            configItem.sortingIdentifier || configItem.id
                          ] === "asc"
                            ? "table-sort-icon-asc"
                            : ""
                        }
                        alt="sort_icon"
                        /*   onClick={() => { // Enable when sorting is required
                          handleOnSorting(
                            configItem.sortingIdentifier || configItem.id
                          );
                        }} */
                        aria-hidden="true"
                      />
                    </th>
                  );
                }
                return <th>{configItem.label}</th>;
              })}
              {fetching && <th>Loading...</th>}
              {!fetching && (
                <th>
                  {totalCount > 0 ? offset - (page.pageSize - 1) : 0}-
                  {offset > totalCount ? totalCount : offset} of {totalCount}
                </th>
              )}
            </tr>
          </thead>
          {integrationConfigs.length > 0 && (
            <tbody
              style={
                !fetching
                  ? {}
                  : {
                      opacity: "0.5",
                    }
              }
            >
              {integrationConfigs.map(
                (integration: IIntegration, integrationIndex) => {
                  const isIntegrationDisabled = [
                    "inactive",
                    "disabled",
                    "blocked",
                    "paused",
                    "auth_missing",
                    "5xx_errors",
                    "4xx_errors",
                  ].includes(
                    (integration.integrationStatus || "").toLowerCase()
                  );
                  return (
                    <tr key={integration.integrationId}>
                      {tableConfig.map((configItem, index) => {
                        const {
                          id,
                          type = "text",
                          tdStyle = {},
                          allowCopy = false,
                          icon = profileLinkIcon,
                          showTooltip = false,
                          label,
                          tooltipField = "",
                          hideColumnIfNoData = false,
                        } = configItem;
                        const trackHoveronBlockedStatus = debounceFunction(
                          () =>
                            track(trackEvent.HOVER_ON_BLOCK_STATUS.name, {
                              [trackEvent.HOVER_ON_BLOCK_STATUS.properties
                                .INTEGRATION_ID]: integration.integrationId,
                              [trackEvent.HOVER_ON_BLOCK_STATUS.properties
                                .BLOCKED_REASON]:
                                blockedReason[integration[id]],
                            }),
                          1000
                        );
                        if (configItem.hide) {
                          return null;
                        }
                        if (hideColumnIfNoData) {
                          if (verifyIfColumnHasData(configItem.id) < 1) {
                            return null;
                          }
                        }
                        if (type === "tag") {
                          const tagLabel =
                            lastRunLabels[integration[id]] ||
                            integrationStatusLabels[integration[id]] ||
                            integration[id];
                          return (
                            <td>
                              <div className="integry-table--column">
                                <Tag label={tagLabel} type="run" />
                              </div>
                            </td>
                          );
                        }
                        if (type === "statusTag") {
                          const hideToolTip =
                            !showTooltip ||
                            integration[tooltipField] === "N/A" ||
                            (configItem.hideSameValueTooltip &&
                              integration[id] === integration[tooltipField]);
                          const tagLabel =
                            lastRunLabels[integration[id]] ||
                            integrationStatusLabels[integration[id]] ||
                            integration[id];
                          return (
                            <td onMouseLeave={clearIdCopied}>
                              <div
                                className="integry-table--column"
                                style={{ ...tdStyle }}
                              >
                                <IntegryTooltip
                                  hideToolTip={hideToolTip}
                                  tooltipText={integration[tooltipField]}
                                  placement="bottom"
                                  tooltipId="integry-multiline-tooltip"
                                >
                                  <div>
                                    <Tag label={tagLabel} type="integration" />
                                  </div>
                                </IntegryTooltip>
                                {tagLabel === "Blocked" && (
                                  <IntegryTooltip
                                    hideToolTip={hideToolTip}
                                    tooltipText={integration[tooltipField]}
                                    placement="bottom"
                                    tooltipId="integry-multiline-tooltip"
                                  >
                                    <img
                                      src={icon}
                                      style={{
                                        width: "15px",
                                        height: "15px",
                                        marginLeft: "5px",
                                        marginTop: "-1px",
                                        transform: "rotateZ(180deg)",
                                      }}
                                      alt="tooltip_heading"
                                      onMouseLeave={() => {
                                        if (
                                          integrationStatusLabels[
                                            integration[id]
                                          ] === "Blocked"
                                        ) {
                                          trackHoveronBlockedStatus();
                                        }
                                      }}
                                    />
                                  </IntegryTooltip>
                                )}
                              </div>
                            </td>
                          );
                        }
                        if (type === "withIcon") {
                          return (
                            <td onMouseLeave={clearIdCopied}>
                              <div className="integry-table--column">
                                {allowCopy ? (
                                  <IntegryTooltip
                                    tooltipText={
                                      idCopied !==
                                      `${integration[id]}_${label}_${integrationIndex}`
                                        ? "Click to copy"
                                        : `Copied`
                                    }
                                    className="text-center"
                                  >
                                    <div
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          integration[id]
                                        );
                                        setIdCopied(
                                          `${integration[id]}_${label}_${integrationIndex}`
                                        );
                                      }}
                                      aria-hidden="true"
                                    >
                                      {integration[id]}
                                    </div>
                                  </IntegryTooltip>
                                ) : (
                                  `${integration[id]}`
                                )}
                                {integration?.userProfileLink &&
                                  integration?.userProfileLink !== null &&
                                  integration?.userProfileLink !== "" && (
                                    <a
                                      className="profile-link"
                                      href={integration?.userProfileLink}
                                      target="_blank"
                                      onClick={() =>
                                        window.open(
                                          integration?.userProfileLink,
                                          "_blank"
                                        )
                                      }
                                      rel="noopener noreferrer"
                                    >
                                      <img
                                        className="user-external-link"
                                        src={icon}
                                        alt="integration-user-id"
                                      />
                                    </a>
                                  )}
                              </div>
                            </td>
                          );
                        }
                        if (configItem.truncate) {
                          return (
                            <td>
                              <div
                                className="integry-table--column"
                                style={tdStyle}
                                onMouseLeave={clearIdCopied}
                              >
                                {integration[id].length > 20 ? (
                                  allowCopy &&
                                  idCopied ===
                                    `${integration[id]}_${label}_${integrationIndex}` ? (
                                    <IntegryTooltip
                                      tooltipText={
                                        <>
                                          {integration[id]}
                                          <br />
                                          Copied
                                        </>
                                      }
                                      placement="bottom"
                                      key={`${integration[id]}_copied`}
                                      className="text-center"
                                    >
                                      <div
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            integration[id]
                                          );
                                          setIdCopied(
                                            `${integration[id]}_${label}_${integrationIndex}`
                                          );
                                        }}
                                        aria-hidden="true"
                                      >
                                        {integration[id].substring(0, 3)}
                                        <img
                                          src={TruncateBullets}
                                          style={{
                                            width: "15px",
                                            margin: "0 2px",
                                            marginTop: "-3px",
                                            objectFit: "none",
                                          }}
                                          alt="truncate_bullets"
                                        />
                                        {integration[id].substring(
                                          integration[id].length - 6
                                        )}
                                      </div>
                                    </IntegryTooltip>
                                  ) : (
                                    <IntegryTooltip
                                      tooltipText={
                                        <>
                                          {integration[id]}
                                          <br />
                                          Click to copy
                                        </>
                                      }
                                      placement="bottom"
                                      className="text-center"
                                    >
                                      <div
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            integration[id]
                                          );
                                          setIdCopied(
                                            `${integration[id]}_${label}_${integrationIndex}`
                                          );
                                        }}
                                        aria-hidden="true"
                                      >
                                        {integration[id].substring(0, 3)}
                                        <img
                                          src={TruncateBullets}
                                          style={{
                                            width: "15px",
                                            margin: "0 2px",
                                            marginTop: "-3px",
                                            objectFit: "none",
                                          }}
                                          alt="truncate_bullets"
                                        />
                                        {integration[id].substring(
                                          integration[id].length - 6
                                        )}
                                      </div>
                                    </IntegryTooltip>
                                  )
                                ) : allowCopy && integration[id] !== "N/A" ? (
                                  <IntegryTooltip
                                    tooltipText={
                                      idCopied !==
                                      `${integration[id]}_${label}_${integrationIndex}`
                                        ? "Click to copy"
                                        : `Copied`
                                    }
                                    className="text-center"
                                  >
                                    <div
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          integration[id]
                                        );
                                        setIdCopied(
                                          `${integration[id]}_${label}_${integrationIndex}`
                                        );
                                      }}
                                      aria-hidden="true"
                                    >
                                      {integration[id]}
                                    </div>
                                  </IntegryTooltip>
                                ) : (
                                  `${integration[id]}`
                                )}
                                {type === "truncateWithIcon" &&
                                  integration?.userProfileLink &&
                                  integration?.userProfileLink !== null &&
                                  integration?.userProfileLink !== "" && (
                                    <a
                                      className="profile-link"
                                      href={integration?.userProfileLink}
                                      target="_blank"
                                      onClick={() =>
                                        window.open(
                                          integration?.userProfileLink,
                                          "_blank"
                                        )
                                      }
                                      rel="noopener noreferrer"
                                    >
                                      <img
                                        className="user-external-link"
                                        src={icon}
                                        alt="integration-user-id"
                                      />
                                    </a>
                                  )}
                              </div>
                            </td>
                          );
                        }
                        let colorCode = {};
                        if (id === "integrationStatus") {
                          colorCode = {
                            color:
                              integrationStatusColorCodes[
                                (
                                  integrationStatusLabels[
                                    integration[id] || "default"
                                  ] || "default"
                                ).toLowerCase()
                              ],
                          };
                        }
                        if (type === "tooltipOnIcon") {
                          const hideToolTip =
                            !showTooltip ||
                            integration[tooltipField] === "N/A" ||
                            (configItem.hideSameValueTooltip &&
                              integration[id] === integration[tooltipField]);

                          return (
                            <td onMouseLeave={clearIdCopied}>
                              <div
                                className="integry-table--column"
                                style={{ ...tdStyle, ...colorCode }}
                              >
                                <div>
                                  {showPollData &&
                                  (lastRunLabels[integration[id]] ||
                                    integrationStatusLabels[integration[id]] ||
                                    integration[id]) === "Paused"
                                    ? "Blocked"
                                    : lastRunLabels[integration[id]] ||
                                      integrationStatusLabels[
                                        integration[id]
                                      ] ||
                                      integration[id]}
                                </div>
                                {!hideToolTip && (
                                  <IntegryTooltip
                                    hideToolTip={hideToolTip}
                                    tooltipText={integration[tooltipField]}
                                    placement="bottom"
                                  >
                                    <img
                                      src={icon}
                                      style={{
                                        width: "15px",
                                        height: "15px",
                                        marginLeft: "5px",
                                        marginTop: "-1px",
                                        transform: "rotateZ(180deg)",
                                      }}
                                      alt="tooltip_heading"
                                      onMouseLeave={() => {
                                        if (
                                          integrationStatusLabels[
                                            integration[id]
                                          ] === "Blocked"
                                        ) {
                                          trackHoveronBlockedStatus();
                                        }
                                      }}
                                    />
                                  </IntegryTooltip>
                                )}
                              </div>
                            </td>
                          );
                        }
                        return (
                          <td onMouseLeave={clearIdCopied}>
                            <div
                              className="integry-table--column"
                              style={{ ...tdStyle, ...colorCode }}
                            >
                              {allowCopy && (
                                <IntegryTooltip
                                  tooltipText={
                                    idCopied !==
                                    `${integration[id]}_${label}_${integrationIndex}`
                                      ? "Click to copy"
                                      : `Copied`
                                  }
                                  className="text-center"
                                >
                                  <div
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      // const urlToIntegration = `${process.env.REACT_APP_API_BASE_URL}/wapp/view/flow/${flowId}/integration/${integration[id]}/runs`;
                                      // navigator.clipboard.writeText(
                                      //   urlToIntegration
                                      // );
                                      navigator.clipboard.writeText(
                                        integration[id]
                                      );
                                      setIdCopied(
                                        `${integration[id]}_${label}_${integrationIndex}`
                                      );
                                    }}
                                    aria-hidden="true"
                                  >
                                    {lastRunLabels[integration[id]] ||
                                      integrationStatusLabels[
                                        integration[id]
                                      ] ||
                                      integration[id]}
                                  </div>
                                </IntegryTooltip>
                              )}
                              {!allowCopy && (
                                <IntegryTooltip
                                  hideToolTip={
                                    !showTooltip ||
                                    integration[tooltipField] === "N/A" ||
                                    (configItem.hideSameValueTooltip &&
                                      integration[id] ===
                                        integration[tooltipField])
                                  }
                                  tooltipText={integration[tooltipField] || ""}
                                  placement="bottom"
                                >
                                  <div>
                                    {lastRunLabels[integration[id]] ||
                                      integrationStatusLabels[
                                        integration[id]
                                      ] ||
                                      integration[id]}
                                  </div>
                                </IntegryTooltip>
                              )}
                            </div>
                          </td>
                        );
                      })}
                      <td
                        style={{
                          display: "grid",
                          gridTemplateColumns: "auto auto",
                          gridGap: "11px",
                        }}
                      >
                        <InterButton
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            if (props.user) {
                              track(trackEvent.VIEW_RUNS.name, {
                                [trackEvent.VIEW_RUNS.properties.FLOW_NAME]: (
                                  flowConfig || { flowName: "" }
                                ).flowName,
                                [trackEvent.VIEW_RUNS.properties
                                  .INTEGRATION_ID]: integration.integrationId,
                              });
                            }
                            onIntegrationOpen(
                              integration.templateId || flowId,
                              integration.integrationId,
                              integration
                            );
                          }}
                          style={{
                            width: `${tab === "users" ? "80px" : "48px"}`,
                            height: "25px",
                            minWidth: "48px",
                            padding: "5px",
                          }}
                        >
                          {`View ${tab === "users" ? "Runs" : ""}`}
                        </InterButton>
                        {/*
                          status === "INACTIVE" -> ENABLE
                          status === "ACTIVE" -> DISABLE
                       */}
                        {(integration.integrationStatus || "").toLowerCase() !==
                          "deleted" && !flowConfig?.isDirectAction ? (
                          <FeatureFlag
                            flagKey="allow-preview-setup-steps"
                            renderDefaultCallback={() => (
                              <MeatballsMenu
                                items={[
                                  {
                                    label: isIntegrationDisabled
                                      ? "Enable"
                                      : "Disable",
                                    value: isIntegrationDisabled
                                      ? "enable"
                                      : "disable",
                                    onItemSelect: () => {
                                      const statusValue = isIntegrationDisabled
                                        ? "enable"
                                        : "disable";
                                      handleEnableIntegration(
                                        integration.integrationId,
                                        statusValue,
                                        integration.appAuthId
                                      );
                                    },
                                  },
                                  {
                                    label: "Delete",
                                    value: "delete",
                                    onItemSelect: () => {
                                      setShowDeleteIntegration(
                                        integration.integrationId
                                      );
                                      // handleDeleteIntegration(
                                      //   integration.integrationId
                                      // );
                                    },
                                  },
                                ]}
                              />
                            )}
                            renderFeatureCallback={() =>
                              !flowConfig?.isDirectAction ? (
                                <MeatballsMenu
                                  items={[
                                    ...(showPollData &&
                                    (integration.integrationStatus ===
                                      "4xx_errors" ||
                                      integration.integrationStatus ===
                                        "AUTH_MISSING" ||
                                      integration.integrationStatus ===
                                        "paused")
                                      ? []
                                      : [
                                          {
                                            label:
                                              (
                                                integration.integrationStatus ||
                                                ""
                                              ).toLowerCase() === "inactive" ||
                                              integration.integrationStatus ===
                                                "5xx_errors"
                                                ? "Enable"
                                                : "Disable",
                                            value:
                                              (
                                                integration.integrationStatus ||
                                                ""
                                              ).toLowerCase() === "inactive"
                                                ? "enable"
                                                : "disable",
                                            onItemSelect: () => {
                                              if (
                                                integration.integrationStatus ===
                                                "5xx_errors"
                                              ) {
                                                handleEnablePollIntegration(
                                                  integration.integrationId,
                                                  integration.templateId
                                                );
                                              } else {
                                                const statusValue =
                                                  (
                                                    integration.integrationStatus ||
                                                    ""
                                                  ).toLowerCase() === "inactive"
                                                    ? "enable"
                                                    : "disable";
                                                handleEnableIntegration(
                                                  integration.integrationId,
                                                  statusValue,
                                                  integration.appAuthId
                                                );
                                              }
                                            },
                                            iconPrefix:
                                              (
                                                integration.integrationStatus ||
                                                ""
                                              ).toLowerCase() === "inactive" ||
                                              integration.integrationStatus ===
                                                "5xx_errors"
                                                ? EnableIcon
                                                : DisableGreyIcon,
                                          },
                                        ]),
                                    {
                                      label: "Preview Setup",
                                      value: "previewSetup",
                                      onItemSelect: () => {
                                        setShowPreviewSetup(
                                          integration.integrationId || ""
                                        );
                                      },
                                      iconPrefix: EyeBlueIcon,
                                    },
                                    {
                                      label: "Delete",
                                      value: "delete",
                                      onItemSelect: () => {
                                        setShowDeleteIntegration(
                                          integration.integrationId
                                        );
                                        // handleDeleteIntegration(
                                        //   integration.integrationId
                                        // );
                                      },
                                      divider: true,
                                      iconPrefix: DeleteBinIcon,
                                    },
                                  ]}
                                />
                              ) : (
                                <MeatballsMenu
                                  items={[
                                    {
                                      label:
                                        (
                                          integration.integrationStatus || ""
                                        ).toLowerCase() === "inactive"
                                          ? "Enable"
                                          : "Disable",
                                      value:
                                        (
                                          integration.integrationStatus || ""
                                        ).toLowerCase() === "inactive"
                                          ? "enable"
                                          : "disable",
                                      onItemSelect: () => {
                                        const statusValue =
                                          (
                                            integration.integrationStatus || ""
                                          ).toLowerCase() === "inactive"
                                            ? "enable"
                                            : "disable";
                                        handleEnableIntegration(
                                          integration.integrationId,
                                          statusValue,
                                          integration.appAuthId
                                        );
                                      },
                                    },
                                    {
                                      label: "Delete",
                                      value: "delete",
                                      onItemSelect: () => {
                                        setShowDeleteIntegration(
                                          integration.integrationId
                                        );
                                        // handleDeleteIntegration(
                                        //   integration.integrationId
                                        // );
                                      },
                                    },
                                  ]}
                                />
                              )
                            }
                          />
                        ) : (
                          <div
                            style={{
                              minWidth: "20px",
                            }}
                          />
                        )}
                        {showDeleteIntegration ===
                          integration.integrationId && (
                          <IntegryDialoguePop
                            dialogueType="delete"
                            title="Delete Integration"
                            description="Are you sure you want to delete this integration?"
                            cancelBtnLabel="Cancel"
                            actionBtnLabel="Delete"
                            onCancelClick={() => {
                              setShowDeleteIntegration("-1");
                            }}
                            performActionCallback={() => {
                              handleDeleteIntegration(
                                integration.integrationId,
                                integration.appAuthId
                              );
                              setShowDeleteIntegration("-1");
                            }}
                          />
                        )}
                        {showPreviewSetup === integration.integrationId && (
                          <PreviewSetup
                            flow={
                              flowConfig || { flowId: integration.templateId }
                            }
                            integration={integration}
                            user={user}
                            app={props.app}
                            onClose={() => {
                              setShowPreviewSetup("-1");
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          )}
        </table>
        {!fetching && integrationConfigs.length < 1 && (
          <div className="view-record-not-found__placeholder">
            <img src={NotFoundIcon} alt="record_not_found" />
            <p>{lang.INTEGRATIONS_NOT_FOUND_COPY_INTEGRATIONS}</p>
          </div>
        )}
        {!fetching &&
          integrationConfigs.length > 0 &&
          showNotificationMessage() && (
            <FeatureFlag
              flagKey="setup-error-integration"
              renderFeatureCallback={() => (
                <ErrorNotificationSetup
                  userId={user.id}
                  onClickSetupNotifications={onClickSetupNotifications}
                  onClickYesInNotificationPopUp={onClickYesInNotificationPopUp}
                  onDismissNotificationSnackbar={onDismissNotificationSnackbar}
                />
              )}
              renderDefaultCallback={() => <></>}
            />
          )}
        {!fetching && integrationConfigs.length > 0 && (
          <IntegryPagination
            defaultCurrentPage={page.page}
            defaultPageSize={page.pageSize}
            total={totalCount}
            customStyle={{ marginTop: "20px" }}
            onPageChange={(pageConfig) => {
              handlePageChange(pageConfig);
            }}
            onPageSizeChange={(pageConfig) => {
              handlePageChange(pageConfig);
            }}
          />
        )}
        {fetching && integrationConfigs.length > 0 && (
          <div
            className="view-loading__placeholder"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "35px",
              left: "0",
            }}
          >
            <IntegryLoader
              styles={{
                margin: "0",
                width: "200px",
              }}
            />
          </div>
        )}
        {fetching && !(integrationConfigs.length > 0) && <IntegryLoader />}
      </>
    );
  };

  useEffect(() => {
    const filtersFromQueryParam = query.get("filters");
    if (filtersFromQueryParam) {
      let filtersJSON = {};
      try {
        filtersJSON = JSON.parse(decodeURIComponent(filtersFromQueryParam));
      } catch (e) {
        console.error("ERROR while converting filters from URI", e);
      }
      setFilters(
        Object.keys(filtersJSON).map((filterKey) => {
          return {
            id: filtersJSON[filterKey].id,
            label: filtersJSON[filterKey].label,
            value: filtersJSON[filterKey].value,
            valueToShow: filtersJSON[filterKey].value,
          };
        })
      );
      // fetchFlowDetails(flowId, {
      //   filters: filtersJSON,
      // });
      fetchData(flowId, {
        search: searchTxt,
        filtersQuery: filters,
        pageConfig: page,
        sortOrder: sorting,
        showDeleted: showDeleted,
      });
    } else {
      // fetchFlowDetails(flowId);
      fetchData(flowId, {
        search: searchTxt,
        filtersQuery: filters,
        pageConfig: page,
        sortOrder: sorting,
        showDeleted: showDeleted,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowId, query]);

  const clickOutsideSidebar = (evt): void => {
    if (showFilters) {
      setShowFilters(false);
    }
  };

  const getLastRunStatusOptions = (
    showRunAsCompletedFlag = false
  ): Array<IRadioOptions> => {
    const lastRunStatusOptions = [
      {
        label: "All",
        value: "all",
      },
    ] as Array<IRadioOptions>;
    if (showRunAsCompletedFlag) {
      lastRunStatusOptions.push(
        {
          render: <Tag label="Completed" type="run" />,
          value: "completed",
        },
        {
          render: <Tag label="Filtered" type="run" />,
          value: "filtered",
        }
      );
    } else {
      lastRunStatusOptions.push({
        render: <Tag label="OK" type="run" />,
        value: "ok",
      });
    }
    lastRunStatusOptions.push(
      {
        render: <Tag label="Aborted" type="run" />,
        value: "failed",
      },
      {
        render: (
          <Tag
            customStyle={{
              background: "#DCEAFF",
              color: "#5194F8",
            }}
            label="Running..."
            type="run"
          />
        ),
        value: "running",
      },
      {
        render: <Tag label="No runs as yet" type="run" />,
        value: "has_not_run",
      }
    );
    return lastRunStatusOptions;
  };

  useOnClickOutside(sidebarDivRef, clickOutsideSidebar);

  if (loading) {
    return <IntegryLoader />;
  }
  return (
    <div className="integry-views" style={{ ...customStyle }}>
      <div className="view-breadcrumb">
        <Breadcrumb
          crumbs={generateBreadcrumbConfig()}
          onNavClick={(url) => {
            history.push(url);
          }}
        />
      </div>
      <h2 className="view-heading">{renderHeading()}</h2>
      <div className="view-header-info">{renderGroupInfoHeader()}</div>
      {renderSubHeading()}
      <div className="view-filters">
        <div className="view-filters__search-bar">
          {/* {!fetching &&
              renderSearchBar({
                filters,
                page,
              })} */}
          {/* {fetching &&
              renderSearchBar(
                {
                  filters,
                  page,
                },
                true
              )} */}
          {renderSearchBar(
            {
              filters,
              page,
            },
            false,
            tab
          )}
        </div>
        <div className="view-filters__pills">
          <GroupedFilterPills
            onClearClick={() => {
              handleOnFilterClear();
            }}
            onClose={(pill) => {
              handleOnFilterClose(pill);
            }}
            pills={(filters || []).map((filter) => {
              if (filter.id === "lastRunStatus") {
                return {
                  ...filter,
                  value: lastRunLabels[filter.value] || filter.valueToShow,
                };
              }
              if (filter.id === "integrationStatus") {
                return {
                  ...filter,
                  value:
                    integrationStatusLabels[filter.value] || filter.valueToShow,
                };
              }
              return {
                ...filter,
                value: filter.valueToShow,
              };
            })}
            customStyle={{
              marginRight: "7px",
            }}
          />
          <div style={{ marginRight: "7px" }}>
            <CheckboxSelection
              defaultValue={showDeleted ? ["deleted"] : [""]}
              onOptionSelect={(options: string[]) => {
                if (options.length > 0 && options.indexOf("deleted") > -1) {
                  toggleShowDeleted(true);
                } else {
                  toggleShowDeleted(false);
                }
              }}
              options={[
                {
                  label: "Show deleted",
                  value: "deleted",
                },
              ]}
            />
          </div>

          <FilterButton
            startIcon={<img src={FunnelIconActive} alt="filter_icon" />}
            onClick={() => {
              setShowFilters(!showFilters);
            }}
          >
            Filter
          </FilterButton>
        </div>
      </div>
      <div className="view-table">{renderTable()}</div>
      {/* {showFilters && (
          <div
            style={{
              position: "fixed",
              left: "0",
              top: "0",
              width: "100%",
              height: "100%",
            }}
            onClick={() => {
              setShowFilters(false);
            }}
            aria-hidden="true"
          />
        )} */}
      {(true || showFilters) && (
        <div ref={sidebarDivRef} className="react-reveal-slider">
          <Slide right collapse when={showFilters}>
            <div className="wrapper-drawer">
              <button
                className="filter-sidebar-close-btn"
                type="button"
                onClick={() => {
                  setShowFilters(false);
                }}
              >
                <img src={filterSidebarCloseBtn} alt="close_drawer" />
              </button>

              <div className="view-drawer">
                <div className="view-drawer__actions--top">
                  {/* <button
                      className="view-drawer__actions__cta"
                      type="button"
                      onClick={() => {
                        setShowFilters(false);
                      }}
                    >
                      <img src={filterSidebarCloseBtn} alt="close_drawer" />
                    </button> */}
                </div>
                <div className="view-drawer__heading">
                  <span>Filter</span>
                  <button
                    type="button"
                    className="view-drawer__actions__cta"
                    onClick={() => {
                      setDraftFilters({});
                      setAllowApplyCTA(false);
                    }}
                  >
                    Clear all
                  </button>
                </div>

                {!hideDateCreatedFilter && (
                  <>
                    <div className="view-drawer__seperator" />
                    <div className="view-drawer__group">
                      <p>
                        Date Created
                        {draftFilters.dateCreated &&
                          (draftFilters.dateCreated.startDate ||
                            draftFilters.dateCreated.endDate) && (
                            <button
                              type="button"
                              className="view-drawer__actions__cta"
                              onClick={() => {
                                setDraftFilters({
                                  ...draftFilters,
                                  dateCreated: null,
                                });
                                setAllowApplyCTA(
                                  allowApplyFilter({
                                    ...draftFilters,
                                    dateCreated: null,
                                  })
                                );
                              }}
                            >
                              Clear
                            </button>
                          )}
                      </p>
                      <DateRange
                        start={(draftFilters.dateCreated || {}).startDate || ""}
                        end={(draftFilters.dateCreated || {}).endDate || ""}
                        onDateRangeChange={(dateRangeValue) => {
                          setDraftFilters({
                            ...draftFilters,
                            dateCreated: dateRangeValue,
                          });
                          if (
                            dateRangeValue?.startDate &&
                            dateRangeValue?.endDate &&
                            new Date(dateRangeValue?.startDate) >
                              new Date(dateRangeValue?.endDate)
                          ) {
                            setAllowApplyCTA(false);
                          } else {
                            setAllowApplyCTA(true);
                          }
                        }}
                      />
                    </div>
                  </>
                )}
                <div className="view-drawer__seperator" />
                <div className="view-drawer__group">
                  <p>
                    Last Run Start
                    {draftFilters.lastRunStart &&
                      (draftFilters.lastRunStart.startDate ||
                        draftFilters.lastRunStart.endDate) && (
                        <button
                          type="button"
                          className="view-drawer__actions__cta"
                          onClick={() => {
                            setDraftFilters({
                              ...draftFilters,
                              lastRunStart: null,
                            });
                            setAllowApplyCTA(
                              allowApplyFilter({
                                ...draftFilters,
                                lastRunStart: null,
                              })
                            );
                          }}
                        >
                          Clear
                        </button>
                      )}
                  </p>
                  <DateRange
                    start={(draftFilters.lastRunStart || {}).startDate || ""}
                    end={(draftFilters.lastRunStart || {}).endDate || ""}
                    onDateRangeChange={(dateRangeValue) => {
                      setDraftFilters({
                        ...draftFilters,
                        lastRunStart: dateRangeValue,
                      });
                      if (
                        dateRangeValue?.startDate &&
                        dateRangeValue?.endDate &&
                        new Date(dateRangeValue?.startDate) >
                          new Date(dateRangeValue?.endDate)
                      ) {
                        setAllowApplyCTA(false);
                      } else {
                        setAllowApplyCTA(true);
                      }
                    }}
                  />
                </div>
                <div className="view-drawer__seperator" />
                <div className="view-drawer__group">
                  <p>Last Run Status</p>
                  <FeatureFlag
                    flagKey="show-run-completed-filtered"
                    renderFeatureCallback={() => (
                      <RadioSelection
                        defaultValue={draftFilters.lastRunStatus || "all"}
                        onRadioSelection={(selection) => {
                          setDraftFilters({
                            ...draftFilters,
                            lastRunStatus: selection.value,
                          });
                          setAllowApplyCTA(true);
                        }}
                        options={getLastRunStatusOptions(true)}
                      />
                    )}
                    renderDefaultCallback={() => (
                      <RadioSelection
                        defaultValue={draftFilters.lastRunStatus || "all"}
                        onRadioSelection={(selection) => {
                          setDraftFilters({
                            ...draftFilters,
                            lastRunStatus: selection.value,
                          });
                          setAllowApplyCTA(true);
                        }}
                        options={getLastRunStatusOptions()}
                      />
                    )}
                  />
                </div>
                <div className="view-drawer__seperator" />
                <div className="view-drawer__group">
                  <p>Integration Status</p>
                  <RadioSelection
                    defaultValue={draftFilters.integrationStatus || "all"}
                    onRadioSelection={(selection) => {
                      setDraftFilters({
                        ...draftFilters,
                        integrationStatus: selection.value,
                      });
                      setAllowApplyCTA(true);
                    }}
                    options={[
                      {
                        label: "All",
                        value: "all",
                      },
                      {
                        label: "Enabled",
                        // value: "ACTIVE",
                        value: "enabled",
                      },
                      {
                        label: "Disabled",
                        // value: "INACTIVE",
                        value: "disabled",
                      },
                      {
                        label: "Deleted",
                        // value: "DELETED",
                        value: "deleted",
                      },
                      {
                        render: (
                          <span style={{ color: "#F05C42" }}>
                            {showPollData ? "Blocked" : "Paused"}
                          </span>
                        ),
                        // value: "PAUSED",
                        value: showPollData ? "blocked" : "paused",
                      },
                      {
                        label: "Initializing...",
                        // value: "INTIIALIZING",
                        value: "setting_up",
                      },
                      {
                        render: (
                          <span style={{ color: "#F05C42" }}>
                            Failed to initialize
                          </span>
                        ),
                        // value: "CREATION_FAIL",
                        value: "setup_failed",
                      },
                    ]}
                  />
                </div>
                <div className="view-drawer__seperator" />
                <div className="view-drawer__actions--bottom">
                  <button
                    className={`view-drawer__actions__cta${
                      allowApplyCTA ? "" : " disabled"
                    }`}
                    type="button"
                    onClick={() => {
                      if (allowApplyCTA) {
                        handleOnApplyFilter(draftFilters);
                      }
                    }}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </Slide>
        </div>
      )}
    </div>
  );
};

// export default IntegrationsView;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FlaggedIntegrationView = (props: IProps): JSX.Element => (
  <FeatureFlag
    flagKey="show-poll-data"
    renderFeatureCallback={() => <IntegrationsView {...props} showPollData />}
    renderDefaultCallback={() => <IntegrationsView {...props} />}
  />
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect((state: any) => {
  return {
    globalState: state,
    app: getMyAppById(state.myApps, state.globallySelectedApp),
  };
})(FlaggedIntegrationView);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const DateRange = ({ start = "", end = "", onDateRangeChange }) => {
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const onDateChange = ({ id, value }): void => {
    let tempDateRange = { ...dateRange, [id]: value };
    const date1 = new Date(tempDateRange.startDate);
    const date2 = new Date(tempDateRange.endDate);
    if (date1.getTime() > date2.getTime()) {
      tempDateRange = {
        startDate: tempDateRange.endDate,
        endDate: tempDateRange.startDate,
      };
    }
    // switch (id) {
    //   case "startDate":
    //     if (tempDateRange.endDate === "") {
    //       tempDateRange = {
    //         ...tempDateRange,
    //         endDate: `${value.split("T")[0]}T23:59`,
    //       };
    //     }
    //     break;
    //   case "endDate":
    //     if (tempDateRange.startDate === "") {
    //       tempDateRange = {
    //         ...tempDateRange,
    //         startDate: `${value.split("T")[0]}T00:00`,
    //       };
    //     }
    //     break;
    //   default:
    //     break;
    // }
    if (tempDateRange.startDate || tempDateRange.endDate || onDateRangeChange) {
      onDateRangeChange(tempDateRange);
    }
    setDateRange(tempDateRange);
  };
  useEffect(() => {
    setDateRange({
      startDate: start,
      endDate: end,
    });
  }, [start, end]);
  return (
    <>
      <DateTimePicker
        defaultValue={dateRange.startDate}
        onDateTimeSelect={(value) => {
          onDateChange({
            id: "startDate",
            value: `${value}T00:00:00`,
            // value,
          });
        }}
        placeholder="Start Date"
        type={dateTimeTypeEnum.DATE}
        customStyle={{
          marginBottom: "10px",
        }}
        max={
          dateRange.endDate.includes("T")
            ? dateRange.endDate.split("T")[0]
            : dateRange.endDate
        }
      />
      <DateTimePicker
        defaultValue={dateRange.endDate}
        onDateTimeSelect={(value) => {
          onDateChange({
            id: "endDate",
            value: `${value}T23:59:59`,
            // value,
          });
        }}
        placeholder="End Date"
        type={dateTimeTypeEnum.DATE}
        min={
          dateRange.startDate.includes("T")
            ? dateRange.startDate.split("T")[0]
            : dateRange.startDate
        }
      />
    </>
  );
};

const filterLabels = {
  dateCreated: "Date Created",
  lastRunStart: "Last Run Start",
  lastRunStatus: "Last Run Status",
  integrationStatus: "Integration Status",
  accountId: "Account Id",
};

export const lastRunLabels = {
  "200": "OK",
  ok: "OK",
  has_not_run: "No runs as yet",
  has_not_run_old: "Hasn't run",
  failed: "Aborted",
  running: "Running...",
  "running...": "Running...",
  "N/A": "N/A",
  completed: "Completed",
  filtered: "Filtered",
};

export const lastPollLabel = {
  "200": "OK",
  ok: "OK",
  has_not_run: "No runs as yet",
  has_not_run_old: "Hasn't run",
  failed: "Failed",
  running: "Running...",
  "running...": "Running...",
  "N/A": "N/A",
  completed: "OK",
  filtered: "Failed",
};

export const integrationStatusLabels = {
  all: "All",
  CREATION_FAIL: "Failed to initialize",
  creation_fail: "Failed to initialize",
  setup_failed: "Failed to initialize",
  "running...": "Running...",
  running: "Running...",
  ACTIVE: "Enabled",
  enabled: "Enabled",
  INACTIVE: "Disabled",
  disabled: "Disabled",
  DELETED: "Deleted",
  deleted: "Deleted",
  PAUSED: "Blocked",
  paused: "Blocked",
  SETTING_UP: "Initializing",
  setting_up: "Initializing...",
  initializing: "Initializing...",
  INTIIALIZING: "Initializing...",
  creation_in_process: "Initializing...",
  CREATION_IN_PROCESS: "Initializing...",
  CREATION_IN_PROGRESS: "Initializing...",
  creation_in_progress: "Initializing...",
  "N/A": "N/A",
  AUTH_MISSING: "Blocked",
  auth_missing: "Blocked",
  "5xx_errors": "Blocked",
  "5XX_ERRORS": "Blocked",
  "4xx_errors": "Blocked",
  "4XX_ERRORS": "Blocked",
  blocked: "Blocked",
  Blocked: "Blocked",
  BLOCKED: "Blocked",
};

export const integrationStatusTooltips = {
  AUTH_MISSING: "User authorization is missing",
  auth_missing: "User authorization is missing",
  PAUSED: "User limit is reached",
  paused: "User limit is reached",
  "5xx_errors":
    "Too many server errors from {app_name} since {timestamp} You can try enabling the integration. If the issue persists, please contact Integry support.",
  "4xx_errors":
    "Too many errors from {app_name} since {timestamp} due to a configuration issue. User needs to create a new integration.",
};

export const integrationStatusColorCodes = {
  auth_missing: "#F05C42",
  paused: "#F05C42",
  blocked: "#F05C42",
  "5xx_errors": "#F05C42",
  "4xx_errors": "#F05C42",
  creation_fail: "#F05C42",
  CREATION_FAIL: "#F05C42",
  "failed to initialize": "#F05C42",
  setup_failed: "#F05C42",
  default: "#666666",
};

export const blockedReason = {
  AUTH_MISSING: "Auth expired",
  auth_missing: "Auth expired",
  PAUSED: "Quota consumed",
  paused: "Quota consumed",
  "5xx_errors": "Too many 5xx errors",
  "4xx_errors": "Too many 4xx errors",
};
