/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement } from "react";
import { Field } from "formik";

import "./styles.scss";

const renderField = (props, fieldProps): ReactElement => {
  const { field } = fieldProps;
  const { value = "" } = field;
  const {
    placeholder = "Click here to configure content",
    keepFormatting = false,
  } = props;

  return (
    <>
      {value ? (
        <p style={{ fontSize: 12 }} className="integry-scrollbar-v2">
          {keepFormatting ? (
            <pre
              className="integry-scrollbar-v2"
              style={{
                fontSize: 12,
                color: "#858585",
              }}
            >
              {value}
            </pre>
          ) : (
            <p style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
              {value}
            </p>
          )}
        </p>
      ) : (
        <p className="HtmlPreviewPlaceHolder">{placeholder}</p>
      )}
    </>
  );
};

const TextPreview = (props: {
  name: string;
  placeholder?: string;
}): ReactElement => {
  const { name } = props;
  return (
    <Field name={name}>{(fieldProps) => renderField(props, fieldProps)}</Field>
  );
};

export default TextPreview;
