/* eslint-disable react-hooks/exhaustive-deps */
import {
  useState,
  useCallback,
  ReactElement,
  useEffect,
  CSSProperties,
} from "react";
import { debounce } from "lodash";
import IntegryTooltip from "components/integry-design-system/molecules/integry-tooltip/integry-tooltip-v2";
import searchBarIcon from "images/search-bar-icon.svg";
import xIcon from "images/x.svg";

import "./styles.scss";

interface SearchBarProps {
  onSearch: (searchString: string) => void;
  placeholder: string;
  debounceRate?: number;
  searchCallbackDependency?: string | number;
  searchQueryProp?: string;
  style?: CSSProperties;
  tooltip?: string;
}

const SearchBarV3 = (props: SearchBarProps): ReactElement => {
  const {
    onSearch,
    placeholder,
    debounceRate,
    searchCallbackDependency,
    searchQueryProp = "",
    style = {},
    tooltip = "",
  } = props;

  const [searchQuery, setSearchQuery] = useState(searchQueryProp || "");

  const debounceHandleSearch = useCallback(
    debounce((value) => {
      onSearch(value);
    }, debounceRate || 500),
    [searchCallbackDependency]
  );

  useEffect(() => {
    setSearchQuery(searchQueryProp);
  }, [searchQueryProp]);

  const instantHandleSearch = (value: string): void => {
    onSearch(value);
  };

  const handleSearch = (value = ""): void => {
    setSearchQuery(value);
    debounceHandleSearch(value);
  };

  return (
    <IntegryTooltip
      tooltipText={tooltip}
      tooltipId="search-bar-tooltip"
      placement="bottom"
      hideToolTip={!!searchQuery || !tooltip}
    >
      <div
        className={`searchbar-v3-container ${
          searchQuery && searchQuery !== ""
            ? "searchbar-v3-container--active"
            : ""
        }`}
        style={style}
      >
        <img className="search-icon" alt="search-icon" src={searchBarIcon} />
        <input
          placeholder={placeholder}
          value={searchQuery}
          className="search--input"
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
        />
        <span className="search-bar-icon">
          {searchQuery && searchQuery !== "" && (
            <img
              className="search-icon close-icon"
              style={{
                cursor: "pointer",
                margin: "0",
                opacity: "0.5",
              }}
              alt="search-icon"
              src={xIcon}
              onClick={() => {
                setSearchQuery("");
                instantHandleSearch("");
              }}
              aria-hidden="true"
            />
          )}
        </span>
      </div>
    </IntegryTooltip>
  );
};

export default SearchBarV3;
