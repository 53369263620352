import React, { Component } from "react";
import { Field, FastField } from "formik";
import { FormFeedback } from "reactstrap";
import onClickOutside from "react-onclickoutside";
import { FeatureFlag } from "react-launch-darkly";

import curry from "lodash/curry";

import AddTagDropdown from "../v2/add-tag-dropdown";
import TagOptionTree from "../v3/tag-option-tree";
import CodeMirrorTextArea from "./code-mirror-text-area";
import PreloadImages from "../../common/preload-images";
import { flattenWithPath } from "../../../utils/tree";
import { hasPathInTag } from "../../../utils/template-editor";

import whiteAddIcon from "../../../images/templates-v3/white-plus.svg";
import blackAddIcon from "../../../images/black-plus-icon.svg";
class TextAreaTagged extends Component {
  state = {
    showDropdown: false,
    caretPosition: 0,
  };
  inputRef = React.createRef();
  codeMirrorRef = React.createRef();
  formikField = null;

  addTag = (value, setValue, tag) => {
    const index = this.getCretPosition();
    const newValue = value
      ? value.slice(0, index) + `${tag}` + value.slice(index)
      : tag;
    setValue(this.props.name, newValue);

    if (this.codeMirrorRef.current) {
      //we need to refereh code mirror after  updating tag value.
      setTimeout(() => this.codeMirrorRef.current.editor.refresh(), 0);
    }
  };

  getCretPosition = () => {
    const { caretPosition } = this.state;
    const { current } = this.inputRef;
    const { field } = this.formikField;
    const { value } = field;

    return current
      ? current.selectionStart
      : caretPosition
      ? caretPosition
      : value
      ? value.length
      : 0;
  };

  setCaretPosition = (pos) => {
    this.setState({ caretPosition: pos });
  };

  toggleDropdown = (value) => {
    const { name } = this.props;
    const { setFieldTouched } = this.formikField.form;
    const { touched } = this.formikField.meta;
    this.setState({ showDropdown: value });
    setFieldTouched(name, !touched);
  };

  handleClickOutside = (evt) => {
    const { showDropdown } = this.state;
    showDropdown && this.toggleDropdown(false);
  };

  fieldProps = (pillTextArea) => {
    const { saveFieldSetValueFunction, selectedDataTree } = this.props;

    let flattenTree = [];
    if (
      selectedDataTree &&
      selectedDataTree.completeDataTree &&
      selectedDataTree.completeDataTree.steps
    ) {
      flattenTree = flattenWithPath(
        selectedDataTree.completeDataTree.steps[0],
        "steps",
        []
      );
    }
    return (fieldApi) => {
      this.formikField = fieldApi;
      const {
        onChange,
        onBlur,
        field,
        className,
        availableTags,
        showTagDropdown,
        formikSteps,
        eventTypes,
        outsideClickIgnoreClass,
        preventDefault,
        stopPropagation,
        disableOnClickOutside,
        enableOnClickOutside,
        disableTagField,
        disableTagButton,
        validate,
        appendedCustomTags,
        ...rest
      } = this.props;
      const { showDropdown } = this.state;
      const { touched, setFieldValue, setFieldTouched, validateField } =
        fieldApi.form;
      const { error } = disableTagField ? {} : fieldApi.meta;
      const { value } = disableTagField ? {} : fieldApi.field;
      const addTag = curry(this.addTag, 3)(value, setFieldValue);
      const validationClass = touched && error ? " is-invalid" : "";
      const hasPath = hasPathInTag(value);
      return (
        <div
          className="tagged-field-container"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={`tagged-field text-area ${validationClass}`}>
            {pillTextArea && !hasPath && !disableTagField ? (
              <CodeMirrorTextArea
                formikSteps={formikSteps}
                flattenTree={flattenTree}
                name={this.props.name}
                value={value}
                setCaretPosition={this.setCaretPosition}
                setFieldValue={curry(setFieldValue, 2)(this.props.name)}
                codeMirrorRef={this.codeMirrorRef}
                validate={() => {
                  validateField(this.props.name);
                }}
                placeholder={this.props.placeholder}
                includesAppendedTags={!!appendedCustomTags}
                onChange={onChange}
              />
            ) : (
              <textarea
                {...rest}
                value={value || ""}
                onChange={(e) => {
                  setFieldValue(this.props.name, e.target.value);
                  if (onChange) {
                    onChange(e.target.value, e);
                  }
                }}
                onBlur={(e) => {
                  setFieldTouched();
                  if (onBlur) {
                    onBlur(e);
                  }
                }}
                onClick={() => {
                  this.handleClickOutside();
                  saveFieldSetValueFunction
                    ? saveFieldSetValueFunction(
                        value || "",
                        curry(setFieldValue, 2)(this.props.name),
                        this.inputRef.current.selectionStart,
                        this.inputRef.current
                      )
                    : () => ({});
                }}
                className={`form-control${validationClass} ${className}`}
                ref={this.inputRef}
                disabled={disableTagField}
              />
            )}
            {!disableTagButton && (
              <button
                className={
                  showDropdown
                    ? "add-tag-button-v3 align-center selected"
                    : "add-tag-button-v3 align-center"
                }
                type="button"
                onClick={() => this.toggleDropdown(!showDropdown)}
              >
                <img
                  src={showDropdown ? whiteAddIcon : blackAddIcon}
                  alt="add-tag"
                />
              </button>
            )}
          </div>
          <TagOptionTree
            show={showDropdown}
            formikSteps={formikSteps}
            key={showDropdown}
            addTag={addTag}
            flattenTree={flattenTree}
            appendedCustomTags={appendedCustomTags}
          />
          {error && <FormFeedback>{error}</FormFeedback>}

          {showTagDropdown && (
            <AddTagDropdown availableTags={availableTags} addTag={addTag} />
          )}
          <PreloadImages images={[blackAddIcon, whiteAddIcon]} />
        </div>
      );
    };
  };

  render() {
    return (
      <Field
        name={this.props.name}
        availableTags={(this.props.availableTags, { ...this.state })}
        validate={this.props.validate ? this.props.validate : () => {}}
        validateOnChange={this.props.validateOnChange}
      >
        {this.fieldProps(this.props.pillTextArea)}
      </Field>
    );
  }
}

export default onClickOutside(TextAreaTagged);
