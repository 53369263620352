import { CSSProperties, ReactElement } from "react";

import "./styles.scss";

interface IIntegryModalProps {
  title?: string;
  titleImg?: string;
  subTitle?: string;
  children?: ReactElement;
  footer?: ReactElement;
  onClose?: () => void;
  className?: string;
  style?: CSSProperties;
}

const IntegryModal = (props: IIntegryModalProps): ReactElement => {
  const {
    title,
    titleImg,
    subTitle,
    children,
    footer,
    onClose,
    className = "",
    style = {},
  } = props;
  const handleOnClose = (): void => {
    if (onClose) {
      onClose();
    }
  };
  return (
    <div
      className="integry-confirmation-modal"
      style={style}
      onClick={() => {
        handleOnClose();
      }}
      aria-hidden="true"
    >
      <div
        className={`integry-modal__container ${className}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        aria-hidden="true"
      >
        {titleImg && (
          <img
            className="integry-modal__title--img"
            style={{
              minWidth: "23px",
            }}
            src={titleImg}
            alt="title_ico"
          />
        )}
        <div>
          {(title || subTitle) && (
            <div className="integry-modal__container--header">
              {title && <h1>{title}</h1>}
              {subTitle && <p>{subTitle}</p>}
            </div>
          )}
          {children && (
            <div className="integry-modal__container--content">{children}</div>
          )}
          {footer && (
            <div className="integry-modal__container--footer">{footer}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IntegryModal;
