/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import http from "utils/http";

export const setToggleStatus = (bundleId, payload): Promise<any> => {
  return http.put(`/api/bundles/${bundleId}/`, payload);
};

export const getBundleDetails = (bundleId): Promise<any> => {
  return http.get(`/api/bundles/${bundleId}/`);
};

export const getAppRequests = ({
  bundleId,
  queryParams = "",
}): Promise<any> => {
  return http.get(
    `/bundle/${bundleId}/app_requests/${
      queryParams !== "" ? `?${queryParams}` : ""
    }`
  );
};
