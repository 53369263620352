import { createApi } from "@reduxjs/toolkit/query/react";
import config from "utils/config";
import { integryBaseQuery } from "utils/http";
import {
  ListedTemplate,
  Template,
  CloneTemplateForAnotherAppPayload,
} from "../template-builder-v7/services/template-builder-interface";
import { TemplateType } from "../interface/templateType";

interface listTemplatesResponse {
  count: number;
  links: { previous?: string; next?: string };
  meta: {
    status_counts: { status: string; count: number }[];
    template_apps: { app_id: number; app_name: string }[];
  };
  results: ListedTemplate[];
  total_pages: number;
  has_more: boolean;
}

export const flowApi = createApi({
  reducerPath: "flowApi",
  baseQuery: integryBaseQuery({
    baseUrl: config.apiBaseURL || "",
  }),
  tagTypes: ["flowListing"],
  refetchOnMountOrArgChange: true, // this is to be removed once the other flow create/update endpoints are moved to rtk
  endpoints(build) {
    return {
      listTemplates: build.query<listTemplatesResponse, string>({
        query: (queryParams) => ({
          url: `/api/v1/templates/${queryParams ? `?${queryParams}` : ""}`,
          method: "get",
        }),
        providesTags: ["flowListing"],
      }),

      listTemplatesWithStats: build.mutation<
        listTemplatesResponse,
        {
          searchText: string;
          page: number;
          showArchived: boolean;
          appId: number | string;
          ordering: string;
          usage_stats: boolean;
        }
      >({
        query: ({
          searchText,
          page,
          showArchived,
          usage_stats,
          ordering,
          appId,
        }) => ({
          url: `/api/v1/templates/?version_lte=7&app=${appId}&page_size=15${
            showArchived ? "&show_archived=true" : ""
          }&page=${page}&search=${encodeURIComponent(
            searchText
          )}&ordering=${ordering}
          ${usage_stats ? "&usage_stats=true" : ""}&types=${
            TemplateType.STANDARD
          }`,
          method: "get",
        }),
      }),

      listPaginatedTemplates: build.query<
        listTemplatesResponse,
        {
          searchText: string;
          page: number;
          showArchived: boolean;
          appId: number | string;
          ordering: string;
          usage_stats: boolean;
          type: TemplateType.FUNCTION | TemplateType.STANDARD;
        }
      >({
        query: ({ searchText, page, showArchived, ordering, appId, type }) => ({
          url: `/api/v1/templates/?version_lte=7&app=${appId}&page_size=15${
            showArchived ? "&show_archived=true" : ""
          }&page=${page}&search=${encodeURIComponent(
            searchText
          )}&ordering=${ordering}&types=${type}`,
          method: "get",
        }),
        serializeQueryArgs: ({ endpointName, queryArgs }) => {
          const {
            searchText = "",
            showArchived = false,
            appId = 0,
            ordering = "",
          } = queryArgs;
          return `${endpointName}-${searchText}-${showArchived}-${appId}-${ordering}`;
        },
        // Always merge incoming data to the cache entry
        merge: (currentCache, newItems, { arg }) => {
          if (arg.page === 1) {
            return newItems;
          }
          return {
            ...newItems,
            results: [...currentCache.results, ...newItems.results],
          };
        },
        // Refetch when the page arg changes
        forceRefetch({ currentArg, previousArg }) {
          return currentArg !== previousArg;
        },
      }),

      cloneFlow: build.mutation({
        query(flowId) {
          return {
            url: `/api/v2/templates/${flowId}/duplicate/`,
            method: "POST",
          };
        },
      }),
      cloneFlowForAnotherApp: build.mutation<
        Template,
        { flowId: string | number; payload: CloneTemplateForAnotherAppPayload }
      >({
        query({ flowId, payload }) {
          return {
            url: `/api/v2/templates/${flowId}/duplicate/`,
            method: "POST",
            data: payload,
          };
        },
      }),
      addFlowActions: build.mutation({
        query({ workspaceAppId, templates, actionPayload }) {
          return {
            url: `/api/v1/templates/action_flows/?app=${workspaceAppId}`,
            method: "POST",
            data: {
              templates,
              action_payload: actionPayload,
            },
          };
        },
      }),
      pollCloningActionFlows: build.mutation({
        query({ workspaceAppId }) {
          return {
            url: `/api/v1/templates/flows_in_cloning/?app=${workspaceAppId}`,
            method: "GET",
          };
        },
      }),
      archiveFlow: build.mutation({
        query(flowId) {
          return {
            url: `/api/templates/${flowId}/?publish=false&archive=true`,
            method: "PUT",
          };
        },
      }),
      unArchiveFlow: build.mutation({
        query(flowId) {
          return {
            url: `/api/templates/${flowId}/?publish=false`,
            method: "PUT",
          };
        },
      }),
      moveFlow: build.mutation({
        query({ flowId, ...data }) {
          return {
            url: `/api/v2/templates/${flowId}/app/`,
            method: "PUT",
            data,
          };
        },
      }),
      convertActivity: build.mutation({
        query({ appId, stepId }) {
          return {
            url: `/api/v1/apps/${appId}/activities/?template_step_id=${stepId}`,
            method: "POST",
          };
        },
      }),

      setFlowVisiblityInMarketplace: build.mutation({
        query({ templateId, isVisible }) {
          return {
            url: `/api/v1/marketplace-templates/${templateId}/`,
            method: "PATCH",
            data: { is_visible_in_marketplace: isVisible },
          };
        },
      }),
      optimisticSetFlowVisiblityInMarketplace: build.mutation({
        query({ templateId, isVisible }) {
          return {
            url: `/api/v1/marketplace-templates/${templateId}/`,
            method: "PATCH",
            data: { is_visible_in_marketplace: isVisible },
          };
        },
        async onQueryStarted(
          { templateId, isVisible },
          { dispatch, getState, queryFulfilled }
        ) {
          // Access the current state and the cache entries
          const state = getState();
          const cacheEntries = (state.flowApi.queries || {}) as Record<
            string,
            never
          >;

          // Iterate over all cache entries of listPaginatedTemplates
          Object.entries(cacheEntries).forEach(([cacheKey, entry]) => {
            if (cacheKey.startsWith("listPaginatedTemplates")) {
              const { originalArgs } = entry;
              dispatch(
                flowApi.util.updateQueryData(
                  "listPaginatedTemplates",
                  originalArgs,
                  (draft: listTemplatesResponse) => {
                    const item = draft.results.find(
                      (item_: ListedTemplate) => item_.id === templateId
                    );
                    if (item) {
                      item.is_visible_in_marketplace = isVisible;
                    }
                  }
                )
              );
            }
          });

          try {
            await queryFulfilled;
          } catch {
            // Optionally, handle rollback logic here
          }
        },
      }),
    };
  },
});

export const {
  useListTemplatesQuery,
  useListTemplatesWithStatsMutation,
  useCloneFlowMutation,
  useMoveFlowMutation,
  useConvertActivityMutation,
  useArchiveFlowMutation,
  useUnArchiveFlowMutation,
  useLazyListTemplatesQuery,
  useAddFlowActionsMutation,
  usePollCloningActionFlowsMutation,
  useSetFlowVisiblityInMarketplaceMutation,
  useOptimisticSetFlowVisiblityInMarketplaceMutation,
  useCloneFlowForAnotherAppMutation,
  useListPaginatedTemplatesQuery,
} = flowApi;
