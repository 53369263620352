/* eslint-disable no-shadow */
export interface DefaultWorkspaceApp {
  icon_url: string;
  name: string;
  id: number;
  key: string;
  secret: string;
  is_public: boolean;
  machine_name: string;
  authorization_type: {
    provider: string;
    id: number;
    type_id: string;
  };
}

export enum LandingPage {
  FLOWS_TAB = "FLOWS_TAB",
  FUNCTION_CALLS_TAB = "FUNCTION_CALLS_TAB",
}
export interface User {
  account_id: number;
  account_name: string;
  can_view_logs: boolean;
  date_joined: number;
  email: string;
  id: number;
  intercom_hash: string;
  username: string;
  company_icon?: string;
  first_name?: string;
  last_name?: string;
  default_marketplace?: number;
  flows_enabled: boolean;
  default_workspace_app_hash: string;
  account?: {
    id: number;
    name: string;
    icon_url: string;
    url: string;
    has_direct_actions: boolean;
    has_v6_and_earlier_version_flows: boolean;
    landing_page: LandingPage;
    default_workspace_app: DefaultWorkspaceApp;
  };
  billing_type?: string;
  partner_api_key?: string;
  notifications_sdk_hash?: string;
  is_admin_user?: boolean;
  accounts?: {
    id: number;
    name: string;
    is_currently_selected: boolean;
    icon_url: string;
  }[];
}
