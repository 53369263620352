import React from "react";
import { Field, FastField } from "formik";

const fieldProps = (props) => {
  return (fieldProps) => {
    const {
      onChange,
      onBlur,
      name,
      className,
      preChangeHook,
      isNotBoolean,
      ...rest
    } = props;
    const { field, form } = fieldProps;
    const { value } = field;
    const { setFieldValue, setFieldTouched } = form;

    return (
      <input
        {...rest}
        checked={
          isNotBoolean
            ? value === isNotBoolean[0] //isNotBoolean should always have the first instance to signify the toggled option.
              ? true
              : false
            : value || ""
        }
        onChange={(e) => {
          if (isNotBoolean)
            setFieldValue(
              name,
              e.target.checked ? isNotBoolean[0] : isNotBoolean[1]
            );
          else setFieldValue(name, e.target.checked);
          if (onChange) {
            onChange(e.target.checked, e);
          }
        }}
        onBlur={(e) => {
          setFieldTouched(name);
          if (onBlur) {
            onBlur(e);
          }
        }}
        className={`form-check-input ${className}`}
        type="checkbox"
      />
    );
  };
};

const Checkbox = (props) =>
  props.isFastField ? (
    <FastField name={props.name}>{fieldProps(props)}</FastField>
  ) : (
    <Field name={props.name}>{fieldProps(props)}</Field>
  );

export default Checkbox;
