import { REFRESH_PREVIEW } from "../actions/app-preview";

export const refreshPreview = (state = { iframeKey: 0 }, action) => {
  switch (action.type) {
    case REFRESH_PREVIEW:
      return Object.assign({}, state, {
        iframeKey: state.iframeKey + 1,
      });
    default:
      return state;
  }
};
