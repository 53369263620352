import { ReactElement } from "react";
import ReactDOM from "react-dom";
import { setupListeners } from "@reduxjs/toolkit/query";
import { Provider } from "react-redux";
// import {createLogger} from 'redux-logger';
import ReactModal from "react-modal";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPencilAlt,
  faGripVertical,
  faCheck,
  faTrashAlt,
  faPlusCircle,
  faMinusCircle,
  faEye,
  faEyeSlash,
  faDatabase,
  faSave,
  faQuestionCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

import config from "utils/config";
import store from "store";
import Integry from "components/integry";
import { injectStore } from "utils/http";

import { fetchStaticLists } from "store/actions/static-lists";
import { fetchMyApps, fetchAllApps } from "store/actions/apps";
import { fetchActivityFieldsForSteps } from "store/actions/activity-fields";
import { fetchGenericObjects } from "store/actions/generic-objects";
import { fetchOnboardingState } from "store/actions/onboarding-state";
import { hide404Page } from "store/actions/toggle-404-page";

import { unregister } from "./registerServiceWorker";

import "./styles.scss";

library.add(faPencilAlt);
library.add(faGripVertical);
library.add(faCheck);
library.add(
  faTrashAlt,
  faPlusCircle,
  faMinusCircle,
  faEye,
  faEyeSlash,
  faDatabase,
  faSave,
  faQuestionCircle,
  faInfoCircle
);

if (process.env.NODE_ENV === "production") {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const noop = (): void => {};

  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

const Root = ({ appStore }): ReactElement => (
  <Provider store={appStore}>
    <Integry />
  </Provider>
);

setupListeners(store.dispatch);
injectStore(store); // add store to http client interceptors
store.dispatch(fetchStaticLists());
store.dispatch(fetchMyApps());
store.dispatch(fetchAllApps());
store.dispatch(fetchGenericObjects());
store.dispatch(fetchOnboardingState());
store.dispatch(hide404Page());

const activities = [
  config.conditions.ifActivity.conditionJsonField.id,
  config.conditions.elseIfActivity.id,
  config.loop.activityId,
  config.schedule.activityId,
];
activities.forEach((activity) =>
  store.dispatch(fetchActivityFieldsForSteps(activity))
);

ReactDOM.render(<Root appStore={store} />, document.getElementById("root"));

ReactModal.setAppElement("#root");

unregister();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
