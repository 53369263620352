/* eslint-disable import/prefer-default-export */
import { Step } from "features/templates/template-builder-v7/services/template-builder-interface";
import triggerIcon from "images/templates-v2/trigger-icon.svg";
import ifIcon from "images/templates-v3/if-icon-v3.svg";
import elseIfIcon from "images/templates-v3/elseif-icon-v3.svg";
import elseIcon from "images/templates-v3/else-icon-v3.svg";
import loopIcon from "images/loop-emblem-2.svg";
import setupIcon from "images/setup-icon-v3.svg";
import scheduleIcon from "images/templates-v3/schedule-icon.svg";
import varIcon from "images/templates-v3/var-app-icon.svg";
import codeIcon from "images/code-icon-on.svg";
import makeHttpIcon from "images/make-http-icon.svg";
import incomingWebhookIcon from "images/incoming-webhook-icon.svg";
import notificationApp from "images/notification/notification-app.png";
import config from "utils/config";

export const getStepIcon = ({
  activityType,
  step,
}: {
  activityType: string;
  step?: Step;
}): string => {
  let defaultIcon;
  switch (activityType) {
    case "LOOP":
      defaultIcon = loopIcon;
      break;
    case "CONDITION_IF":
      defaultIcon = ifIcon;
      break;
    case "CONDITION_ELSEIF":
      defaultIcon = elseIfIcon;
      break;
    case "CONDITION_ELSE":
      defaultIcon = elseIcon;
      break;
    case "SETUP":
      defaultIcon = setupIcon;
      break;
    case "SCHEDULE":
      defaultIcon = scheduleIcon;
      break;
    case "VARIABLE":
      defaultIcon = varIcon;
      break;
    case "CODE":
      defaultIcon = codeIcon;
      break;
    case "MAKE_HTTP_CALL":
      defaultIcon = step?.authorization_type?.app?.icon_url || makeHttpIcon;
      break;
    case "NOTIFICATION":
      defaultIcon = notificationApp;
      break;
    case config.external_action.activityType:
      defaultIcon = config.external_action.appIcon;
      break;
    case config.doWhileLoop.activity.type:
    case config.doWhileLoop.dummyStepType:
      defaultIcon = config.doWhileLoop.icon;
      break;
    case "TRIGGER":
      if (step?.app_id === config.incomingWebhook.appId) {
        defaultIcon =
          step?.authorization_type?.app?.icon_url || incomingWebhookIcon;
      } else {
        defaultIcon = step?.app?.icon_url || triggerIcon;
      }
      break;
    default:
      defaultIcon =
        step?.app?.icon_url || step?.activity?.app?.icon_url || triggerIcon;
  }

  return defaultIcon;
};
