/* eslint-disable no-shadow */
export enum TemplateStepFieldDataType {
  STRING = "STRING",
  NUMBER = "NUMBER",
  BOOLEAN = "BOOLEAN",
  OBJECT = "OBJECT",
  STRING_ARRAY = "STRING[]",
  NUMBER_ARRAY = "NUMBER[]",
  OBJECT_ARRAY = "OBJECT[]",
}

export enum TemplateStepFieldDataTypeTitle {
  STRING = "String",
  NUMBER = "Number",
  BOOLEAN = "Boolean",
  OBJECT = "Object",
  "STRING[]" = "String Array",
  "NUMBER[]" = "Number Array",
  "OBJECT[]" = "Object Array",
}

export const fieldDataTypeOptions = [
  {
    title: TemplateStepFieldDataTypeTitle.STRING,
    id: TemplateStepFieldDataType.STRING,
  },
  {
    title: TemplateStepFieldDataTypeTitle.NUMBER,
    id: TemplateStepFieldDataType.NUMBER,
  },
  {
    title: TemplateStepFieldDataTypeTitle.BOOLEAN,
    id: TemplateStepFieldDataType.BOOLEAN,
  },
  {
    title: TemplateStepFieldDataTypeTitle.OBJECT,
    id: TemplateStepFieldDataType.OBJECT,
  },
  {
    title: TemplateStepFieldDataTypeTitle["STRING[]"],
    id: TemplateStepFieldDataType.STRING_ARRAY,
  },
  {
    title: TemplateStepFieldDataTypeTitle["NUMBER[]"],
    id: TemplateStepFieldDataType.NUMBER_ARRAY,
  },
  {
    title: TemplateStepFieldDataTypeTitle["OBJECT[]"],
    id: TemplateStepFieldDataType.OBJECT_ARRAY,
  },
];
