import { useRef, useEffect, useState } from "react";

import TooltipWrapper from "./tooltip-wrapper";

const OverflowTooltip = ({
  className = "",
  text,
  tooltipId = "tag-tooltip", // pass tooltipId='tag-tooltip' for tooltip with white background
  onClick = () => {},
  style = {},
  allowCopy = false,
  link = "",
}) => {
  const textElementRef = useRef();
  const compareSize = () => {
    const compare =
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
  }, []);

  // remove resize listener again on "componentWillUnmount"
  useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize);
    },
    []
  );

  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);
  const [copied, setCopied] = useState(false);

  const tooltip = allowCopy ? (
    copied ? (
      <>
        {hoverStatus && (
          <>
            {text}
            <br />
          </>
        )}
        Copied!
      </>
    ) : (
      <>
        {hoverStatus && (
          <>
            {text}
            <br />
          </>
        )}
        Click to copy
      </>
    )
  ) : (
    text
  );

  return (
    <TooltipWrapper
      tooltipText={tooltip}
      tooltipId={tooltipId}
      disabled={!hoverStatus && !allowCopy}
    >
      {link ? (
        <a
          ref={textElementRef}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: hoverStatus || allowCopy ? "pointer" : "",
            ...style,
          }}
          className={className}
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          {text}
        </a>
      ) : (
        <div
          ref={textElementRef}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: hoverStatus || allowCopy ? "pointer" : "",
            ...style,
          }}
          className={className}
          onClick={() => {
            if (allowCopy) {
              navigator.clipboard.writeText(text);
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 2000);
            }
            if (onClick) {
              onClick();
            }
          }}
        >
          {text}
        </div>
      )}
    </TooltipWrapper>
  );
};

export default OverflowTooltip;
