import { useState, useEffect, useRef } from "react";

import ControlledAutosizeInput from "./auto-size-text-field";
import TagPill from "./tag-pill";

import { containTags } from "utils/tree";

const BetterTemplateTag = (props) => {
  const {
    formikSteps,
    flattenTree,
    tag,
    formikField,
    setLastInputRef,
    setCretPosition,
    placeholder,
    name,
    setFocusClass,
    isCustomTag,
    disableTagField,
    customChangeCallback,
    customTags,
    simpleTags = false,
    includesAppendedTags,
    type,
    onChange,
    shortenTagPill,
  } = props;
  const fieldProps = formikField;
  let tagArray = [""];
  if (includesAppendedTags) {
    const reg = /({steps.*?})|({{[^{}"]*?}})+/g;
    tagArray = tag ? tag.split(reg).filter((str) => !!str) : [""];
    tagArray = tagArray.length ? tagArray : [""];
  } else if (!isCustomTag) {
    tagArray = tag ? tag.split(/({steps.*?})/gi) : [""];
  } else {
    const re = /({{[^{}"]*?}})/g;
    tagArray = tag ? tag.split(re).filter(Boolean) : [""];
    if (tag && tag.length > 0) {
      if (tagArray[0][0] === "{") {
        tagArray.unshift("");
      }
      if (
        tagArray[tagArray.length - 1][
          tagArray[tagArray.length - 1].length - 1
        ] === "}"
      ) {
        tagArray.push("");
      }
    }
  }
  const tagArrayRef = useRef(null);

  const [focusedRef, setFocusedRef] = useState(null);

  useEffect(() => {
    tagArrayRef.current = tagArray;
  }, [tagArray]);

  const moveCursor = (e) => {
    const { key } = e;

    switch (key) {
      case "ArrowRight":
        let nextNode = null;
        try {
          nextNode =
            focusedRef.current.input.parentNode.nextSibling.nextSibling
              ?.firstChild;
        } catch {}

        if (
          nextNode &&
          focusedRef.current.input.selectionStart ===
            focusedRef.current.input.value.length
        ) {
          nextNode.focus();
        }
        break;

      case "ArrowLeft":
        let previousNode = null;
        try {
          previousNode =
            focusedRef.current.input.parentNode.previousSibling.previousSibling
              ?.firstChild;
        } catch {}

        if (previousNode && focusedRef.current.input.selectionStart === 0) {
          previousNode.focus();
        }
        break;

      default:
        break;
    }
  };

  const setTextFieldValueInTag = async (
    index,
    value,
    inputRef,
    isEnterPressed = false,
    customEvent = ""
  ) => {
    const tagArrayCopy = tagArrayRef?.current;
    tagArrayCopy[index] = value;
    const updatedTag = tagArrayCopy.join("");
    let caretPosition;

    if (inputRef)
      caretPosition =
        tagArrayCopy.slice(0, index).join("").length +
        inputRef.current.input.selectionStart;
    await fieldProps.form.setFieldValue(name, updatedTag);
    await fieldProps.form.setFieldTouched(name);
    if (onChange) {
      onChange(updatedTag);
    }
    if (isCustomTag && customChangeCallback) {
      customChangeCallback(updatedTag, isEnterPressed);
    } else if (customEvent === "blur" && isCustomTag && customChangeCallback) {
      customChangeCallback(updatedTag, true);
    }
    if (inputRef) setCretPosition(caretPosition);
    try {
      fieldProps.form.validateField(name);
    } catch (e) {
      console.log(`Field ${name} not exist`);
    }
  };

  const removeTag = async (index) => {
    const tagArrayCopy = [...tagArray];
    tagArrayCopy.splice(index, 1);
    const updatedTag = tagArrayCopy.join("");
    const caretPosition = tagArrayCopy.slice(0, index).join("").length;
    await fieldProps.form.setFieldValue(name, updatedTag);
    await fieldProps.form.setFieldTouched(name);
    fieldProps.form.validateField(name);
    if (onChange) {
      onChange(updatedTag);
    }
    if (isCustomTag && customChangeCallback) {
      customChangeCallback(updatedTag);
    }
    setCretPosition(caretPosition);
  };
  return (
    <div
      className="tags-pills-container"
      onKeyDown={moveCursor}
      style={tagArray.length < 2 ? { width: "100%" } : {}}
    >
      {!!tagArray &&
        tagArray.length &&
        tagArray.map((str, index) =>
          str[0] === "{" && str[str.length - 1] === "}" && containTags(str) ? (
            <TagPill
              tag={str}
              formikSteps={formikSteps}
              flattenTree={flattenTree}
              tagIndex={index}
              removeTag={() => removeTag(index)}
              name={name}
              isCustomTag={isCustomTag}
              disableTagField={disableTagField}
              customTags={customTags}
              simpleTag={simpleTags}
              shortenTagPill={shortenTagPill}
              includesAppendedTags
            />
          ) : (
            <>
              <ControlledAutosizeInput
                initialValue={str}
                setLastInputRef={
                  index === tagArray.length - 1 ? setLastInputRef : null
                }
                setFocusedRef={setFocusedRef}
                key={str}
                index={index}
                setTextFieldValueInTag={setTextFieldValueInTag}
                tagsLength={tagArray.length}
                placeholder={placeholder}
                setFocusClass={setFocusClass}
                formikField={formikField}
                disableTagField={disableTagField}
                isCustomTag={isCustomTag}
                type={type}
              />
            </>
          )
        )}
    </div>
  );
};

export default BetterTemplateTag;
