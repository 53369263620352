/* eslint-disable no-else-return */
/* eslint-disable no-useless-return */
/* eslint-disable arrow-body-style */
import http from "../../utils/http";

export const REQUEST_TEMPLATES = "REQUEST_TEMPLATES";

const requestTemplates = (appId) => ({
  type: REQUEST_TEMPLATES,
  appId,
});

export const RECEIVE_TEMPLATES = "RECEIVE_TEMPLATES";

const receiveTemplates = (appId, json) => ({
  type: RECEIVE_TEMPLATES,
  templates: json,
  appId,
});

export const fetchTemplates = (appId) => {
  return (dispatch) => {
    if (appId) {
      dispatch(requestTemplates(appId));

      return http.get(`/api/v2/templates/?app=${appId}`).then(
        (response) => {
          dispatch(receiveTemplates(appId, response.data));
        },
        (error) => {
          console.log(
            "Error occurred while fetching templates in fetchTemplates() action.",
            error
          );
        }
      );
    }
    console.error("Error: App id is not defined");
    return;
  };
};

export const SET_TEMPLATE_PUBLISHING_STATUS_START =
  "SET_TEMPLATE_PUBLISHING_STATUS_START";
const setTemplatePublishingStatusStart = (appId, templateId, status) => ({
  type: SET_TEMPLATE_PUBLISHING_STATUS_START,
  appId,
  templateId,
  status,
});

export const SET_TEMPLATE_PUBLISHING_STATUS_SUCCESS =
  "SET_TEMPLATE_PUBLISHING_STATUS_SUCCESS";
export const setTemplatePublishingStatusSuccess = (
  appId,
  templateId,
  status
) => ({
  type: SET_TEMPLATE_PUBLISHING_STATUS_SUCCESS,
  appId,
  templateId,
  status,
});

export const SET_TEMPLATE_PUBLISHING_STATUS_FAILURE =
  "SET_TEMPLATE_PUBLISHING_STATUS_FAILURE";
const setTemplatePublishingStatusFailure = (appId, templateId) => ({
  type: SET_TEMPLATE_PUBLISHING_STATUS_FAILURE,
  appId,
  templateId,
});

export const updateTemplatePublishingStatus = (appId, templateId, status) => {
  return (dispatch) => {
    dispatch(setTemplatePublishingStatusStart(appId, templateId, status));

    return http
      .put(`/api/templates/${templateId}/?publish=${status === 1}`)
      .then(
        (response) => {
          dispatch(
            setTemplatePublishingStatusSuccess(appId, templateId, status)
          );
        },
        (error) => {
          console.log("Error occurred in setTemplateStatus() action.", error);
          dispatch(setTemplatePublishingStatusFailure(appId, templateId));
        }
      );
  };
};

export const REMOVE_TEMPLATE = "REMOVE_TEMPLATE";

export const removeTemplate = (templateId, appId) => ({
  type: REMOVE_TEMPLATE,
  templateId,
  appId,
});

export const ADD_NEW_TEMPLATE = "ADD_NEW_TEMPLATE";

export const addNewTemplate = (appId, template) => ({
  type: ADD_NEW_TEMPLATE,
  template,
  appId,
});

export const RENAME_TEMPLATE_START = "RENAME_TEMPLATE_START";
const renameTemplateStart = (appId, templateId, name) => ({
  type: RENAME_TEMPLATE_START,
  appId,
  templateId,
  name,
});

export const RENAME_TEMPLATE_SUCCESS = "RENAME_TEMPLATE_SUCCESS";
export const renameTemplateSuccess = (appId, templateId, name) => ({
  type: RENAME_TEMPLATE_SUCCESS,
  appId,
  templateId,
  name,
});

export const RENAME_TEMPLATE_FAILURE = "RENAME_TEMPLATE_FAILURE";
const renameTemplateFailure = (appId, templateId) => ({
  type: RENAME_TEMPLATE_FAILURE,
  appId,
  templateId,
});

export const updateTemplateName = (appId, templateId, name) => {
  return (dispatch) => {
    dispatch(renameTemplateStart(appId, templateId, name));

    return http
      .put(`/api/templates/${templateId}/`, {
        meta: { title: name },
      })
      .then(
        (response) => {
          dispatch(renameTemplateSuccess(appId, templateId, name));
        },
        (error) => {
          console.log("Error occurred in updateTemplateName() action.", error);
          dispatch(renameTemplateFailure(appId, templateId));
        }
      );
  };
};

function shouldFetchTemplates(state, app_id) {
  const template =
    state.templatesByApp && state.templatesByApp[app_id]
      ? state.templatesByApp[app_id]
      : null;

  if (!template) {
    return true;
  } else if (template.isFetching) {
    return false;
  } else {
    const validityPeriod = 10000; // in milliseconds: 10 seconds
    return Date.now() - template.lastUpdated > validityPeriod; // was last fetched more than 10 seconds ago
  }
}

export function fetchAppTemplatesIfNeeded(app_id) {
  return (dispatch, getState) => {
    if (shouldFetchTemplates(getState(), app_id)) {
      return dispatch(fetchTemplates(app_id));
    } else {
      return Promise.resolve("already exist");
    }
  };
}
