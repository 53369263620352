/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { useState, ReactElement } from "react";
import DashboardWrapper from "components/dashboard-wrapper";
import SearchBar from "components/common/search-input-field/searchbar-v3";
import ListingTable, {
  createColumnHelper,
} from "components/integry-design-system/organisms/ListingTable/ListingTable";
import DateRangePickerV2, {
  DateRangeValue,
} from "components/integry-design-system/molecules/date-range-picker/date-range-v2";

import StatusFilterDropdown from "features/FunctionCalls/status-filter-dropdown";
import { RunStatusIndicator } from "features/integration-runs";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import Button from "components/integry-design-system/molecules/button";
import UsageWidgets, {
  UsageWidgetLoading,
} from "components/integry-design-system/atoms/usage-widget";

import EmptyPageMessage from "components/integry-design-system/molecules/empty-page-content";
import MarketplaceModal from "features/markteplace/MarketplaceModal/MarketplaceModal";
import { getQueryParamAsObject } from "utils/query-params";
import { useFetchUserQuery } from "utils/user/user-api";
import lang from "utils/lang/en";
import { useFeatureFlags } from "context/featureFlagsContext";
import OverflowTooltip from "components/common/overflow-tooltip";

import arrowUpIcon from "images/arrow-up-icon.svg";
import exlaimationIconOutline from "images/exclaimation_icon_outline.svg";
import { ReactComponent as MarketplaceIcon } from "images/marketplace-icon.svg";

import { useGetPollsQuery } from "./api/useFunctionCallsApi";
import { FunctionCall } from "./interface";
import PollPages from "./FunctionExecutionLog/FunctionExecutionLog";

import "./FunctionCalls.scss";
import { FunctionsUsage } from "./interface/functionUsage";
import { useGetFunctionsUsageQuery } from "./api/useFunctionsUsageApi";

type QueryParams = {
  search?: string;
  poll_statuses?: string | string[];
  start_time_range_start?: string;
  start_time_range_end?: string;
  run_id?: string;
  has_runs?: string[];
};

const FunctionCalls = (): ReactElement => {
  const integrationId = "305119";
  const { data: user } = useFetchUserQuery();

  const runCountfilter = useFeatureFlags({ flag: "polls-listing" });
  const columnHelper = createColumnHelper<FunctionCall>();
  const [showSDKPreview, setShowSDKPreview] = useState(false);

  const [selectedPoll, setSelectedPoll] = useState<FunctionCall | null>(null);
  const [queryArguements, setQueryArguements] = useState<{
    search: string;
    stepStatus: string[];
    page: number;
    has_runs: string[];
  }>({
    search: "",
    stepStatus: [],
    page: 1,
    has_runs: runCountfilter ? [] : [],
  });

  const {
    data: functionsUsage,
    isLoading: isFunctionUsageLoading,
    isFetching: isFunctionsUsageFetching,
  } = useGetFunctionsUsageQuery();

  const queryParams = getQueryParamAsObject() as QueryParams;
  const [runStartTimeRange, setRunStartTimeRange] =
    useState<DateRangeValue | null>(() => {
      const start = queryParams.start_time_range_start
        ? new Date(queryParams.start_time_range_start)
        : "";
      const end = queryParams.start_time_range_end
        ? new Date(queryParams.start_time_range_end)
        : "";

      if (start && end) {
        return {
          startDate: start,
          endDate: end,
          key: "selection",
        };
      }
      return null;
    });
  const {
    data: runSteps,
    isLoading: isLoadingSteps,
    isFetching: isFetchingSteps,
  } = useGetPollsQuery(
    {
      search: queryArguements.search,
      stepStatus: queryArguements.stepStatus,
      page: queryArguements.page,
      start_time_range_start: runStartTimeRange?.startDate?.toISOString() || "",
      start_time_range_end: runStartTimeRange?.endDate?.toISOString() || "",
      has_runs:
        queryArguements.has_runs.length > 0 ? queryArguements.has_runs[0] : "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const columns = [
    columnHelper.accessor("start_time", {
      header: () => (
        <span>
          Start Time
          <img
            width={10}
            height={10}
            style={{
              marginLeft: "5px",
              marginTop: "-2px",
              cursor: "default",
              transform: "rotateZ(180deg)",
              opacity: "0.7",
            }}
            src={arrowUpIcon}
            alt="arrow-up"
          />
        </span>
      ),
    }),

    columnHelper.accessor("title", {
      header: () => "Function",
      cell: (info) => (
        <div className="flex align-center gap-10px">
          <img
            src={info.row.original.icon_url}
            alt="appicon"
            width={24}
            height={24}
            style={{ borderRadius: "50%" }}
          />
          <div>{info.getValue()}</div>
        </div>
      ),
    }),

    columnHelper.accessor("app_user_external_id", {
      header: () => "User ID",
      cell: (info) => <OverflowTooltip text={info.getValue()} />,
    }),

    columnHelper.accessor("steps_count", {
      header: () => "Steps",
    }),

    columnHelper.accessor("duration_string", {
      header: () => "Duration",
    }),

    columnHelper.accessor("status", {
      header: () => (
        <span>
          Status
          <IntegryTooltip tooltipText={lang.FUCTION_STATUS}>
            <img
              width={12}
              height={12}
              className="cursor-pointer"
              src={exlaimationIconOutline}
              style={{
                marginLeft: "5px",
                marginTop: "-1px",
                transform: "rotateZ(180deg)",
              }}
              alt="tooltip_heading"
            />
          </IntegryTooltip>
        </span>
      ),
      cell: (info) => (
        <RunStatusIndicator
          statusType={info.getValue()}
          networkCode={info.row.original.network_code}
          hideIcon
        />
      ),
    }),
  ];

  const showMainLoader =
    isLoadingSteps || (isFetchingSteps && queryArguements.page === 1);

  const getUsageTooltip = ({
    currentUsage,
    prevUsage,
    trending = true,
    showPercentChange = false,
  }: {
    currentUsage: FunctionsUsage;
    prevUsage: FunctionsUsage;
    trending: boolean;
    showPercentChange: boolean;
  }): string => {
    const { percent_change, is_current_month } = currentUsage;
    const { month: prevMonth } = prevUsage;
    const prefix = is_current_month ? "this month" : `in ${currentUsage.month}`;
    if (percent_change === null) {
      return "";
    }
    if (percent_change === 0) {
      return `Calls ${prefix} are the same compared to ${prevMonth}`;
    }
    if (trending) {
      return `Calls ${prefix} are trending ${Math.abs(percent_change)}% ${
        percent_change > 0 ? "higher" : "lower"
      } compared to ${prevMonth}`;
    }
    if (showPercentChange) {
      return `Calls ${prefix} were ${Math.abs(percent_change)}% ${
        percent_change > 0 ? "higher" : "lower"
      } compared to ${prevMonth}`;
    }
    return "";
  };

  return (
    <DashboardWrapper user={user} className="" alertBar>
      <div className="function-call-page">
        <h2 className="text-black txt-xxl bold">Function Calls</h2>

        <div className="mt-20px">
          {!functionsUsage ||
          isFunctionUsageLoading ||
          isFunctionsUsageFetching ? (
            <UsageWidgetLoading widgetCount={3} />
          ) : (
            <UsageWidgets
              usage={
                functionsUsage?.map((u, index) => {
                  const prevUsage =
                    functionsUsage.length > index + 1
                      ? functionsUsage[index + 1]
                      : null;
                  const percentChangeTooltip = prevUsage
                    ? getUsageTooltip({
                        currentUsage: u,
                        prevUsage,
                        trending: u.is_current_month,
                        showPercentChange: index === 1,
                      })
                    : "";
                  return {
                    count: u.count,
                    subtitle: u.is_current_month
                      ? `Calls this month`
                      : `Calls in ${u.month}`,
                    percentChange: u.percent_change,
                    showTrendIcon: u.is_current_month,
                    showPercentChange: index === 1,
                    percentChangeTooltip,
                  };
                }) || []
              }
            />
          )}
        </div>

        <div className="flex gap-10px">
          <SearchBar
            searchQueryProp={queryArguements.search}
            onSearch={(value) => {
              setQueryArguements((prev) => ({
                ...prev,
                search: value,
                page: 1,
              }));
            }}
            placeholder="Search by ID, function name, or data in parameters"
          />
          <DateRangePickerV2
            value={runStartTimeRange}
            onChange={(date) => {
              setRunStartTimeRange(date);
              setQueryArguements((prev) => ({
                ...prev,
                page: 1,
              }));
            }}
            tooltipText="Filter on Start Time"
            plaseholder={["From", "To"]}
          />

          <StatusFilterDropdown
            runStatuses={queryArguements.stepStatus}
            setRunStatuses={(status) => {
              setQueryArguements((prev) => ({
                ...prev,
                stepStatus: status,
                page: 1,
              }));
            }}
            completedStatusNetworkCode=""
            setCompletedStatusNetworkCode={(value) => {
              setQueryArguements((prev) => ({
                ...prev,
                page: 1,
              }));
            }}
          />

          {!isLoadingSteps && (
            <div className="txt-sm-liight-gray align-center">{`${
              runSteps?.count.toLocaleString() || 0
            } ${runSteps?.count === 1 ? "Call" : "Calls"}`}</div>
          )}
          <div className="view-marketplace-btn">
            <Button
              size="large-wide"
              action={() => {
                setShowSDKPreview(true);
              }}
              style={{
                minHeight: "36px",
              }}
              variant="outline"
            >
              <MarketplaceIcon width={15} height={15} /> Marketplace
            </Button>
          </div>
        </div>

        <ListingTable<FunctionCall>
          showHoverOnRow
          data={runSteps?.results || []}
          columns={columns}
          hasMore={showMainLoader ? false : !!runSteps?.has_more}
          isLoading={showMainLoader}
          onRowClick={(row) => {
            setSelectedPoll(row);
          }}
          fetchMore={() => {
            setQueryArguements((prev) => ({
              ...prev,
              page: prev.page + 1,
            }));
          }}
        />
        {!runSteps?.meta.has_function_calls &&
          !isLoadingSteps &&
          !isFetchingSteps && (
            <EmptyPageMessage
              content={
                <>
                  <p>
                    You can call Integry Public Functions via the Functions API
                    or invoke them using the SDK.
                  </p>
                  <p>View and debug all calls made from your workspace here.</p>
                </>
              }
              docLink="https://integry.gitbook.io/docs/getting-started/functions"
            />
          )}

        {runSteps?.meta.has_function_calls &&
          queryArguements.search &&
          runSteps?.results.length === 0 && (
            <EmptyPageMessage
              content={
                <>
                  <p>
                    No Calls found for &quot;
                    <span className="bold">{queryArguements.search}</span>
                    &quot;.
                    <br />
                    Try searching with a different keyword.
                  </p>
                </>
              }
            />
          )}

        {selectedPoll && (
          <PollPages
            show={!!selectedPoll}
            onClose={() => {
              setSelectedPoll(null);
            }}
            pollId={selectedPoll.id}
            integrationId={integrationId}
          />
        )}
      </div>
      {showSDKPreview && (
        <MarketplaceModal onClose={() => setShowSDKPreview(false)} />
      )}
    </DashboardWrapper>
  );
};

export default FunctionCalls;
