import { ReactElement, useEffect, useState } from "react";
import { Alert } from "antd";

import ImportWithAI from "components/integry-design-system/organisms/import-with-AI";
import DefaultAppActionsList from "components/integry-design-system/organisms/default-app-actions-listing";

const SettingsActionsListWithImportAI = (props): ReactElement => {
  const { defaultApp, globallySelectedApp } = props;
  const [showJobImportStatus, setShowJobImportStatus] = useState(false);
  const [jobImportStatus, setJobImportStatus] = useState<any>(null);
  useEffect(() => {
    setShowJobImportStatus(false);
    setJobImportStatus(null);
  }, [globallySelectedApp]);
  return (
    <div className="account-setting-wrapper end-user-permissions">
      <h2
        className="page-heading"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        Actions{" "}
        <ImportWithAI
          app={defaultApp}
          onStatusUpdate={(jobStatus) => {
            if (defaultApp.id === jobStatus.app.id) {
              setShowJobImportStatus(true);
              setJobImportStatus(jobStatus);
            }
          }}
          onClearJobStatus={() => {
            setShowJobImportStatus(false);
            setJobImportStatus(null);
          }}
        />
      </h2>
      <div className="account-settings-form">
        {showJobImportStatus && jobImportStatus && (
          <Alert
            closable
            onClose={() => {
              setShowJobImportStatus(false);
              setJobImportStatus(null);
            }}
            message={
              jobImportStatus.summary ||
              (jobImportStatus.status === "COMPLETED"
                ? "Import job has been completed"
                : "Import job has failed")
            }
            type={jobImportStatus.status === "COMPLETED" ? "success" : "error"}
            showIcon
          />
        )}
        <DefaultAppActionsList
          defaultApp={defaultApp}
          jobImportStatus={jobImportStatus}
        />
      </div>
    </div>
  );
};

export default SettingsActionsListWithImportAI;
