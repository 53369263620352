import { useState, ReactElement } from "react";
import { DatePicker } from "antd";
import moment from "moment/moment";
import {
  getStartOfUtcDay,
  getEndOfUtcDay,
  getStartOfLocalDay,
  getEndOfLocalDay,
} from "utils/date-utils";

import greyArrrow from "images/flow-steps/grey-arrow.svg";
import IntegryTooltip from "../integry-tooltip/integry-tooltip-v2";

import "./styles-v2.scss";

export interface DateRangeValue {
  startDate: Date | null;
  endDate: Date | null;
}
interface IProps {
  tooltipText?: string;
  value: DateRangeValue | null;
  plaseholder?: [string, string];
  onChange: (value: DateRangeValue | null) => void;
}

const IntegryDateRangePicker = (props: IProps): ReactElement => {
  const { RangePicker } = DatePicker;
  const { tooltipText, value, plaseholder, onChange } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const dateFormat = "DD/MM/YYYY";
  const rangeValue = (
    value?.startDate && value?.endDate
      ? [
          moment(getStartOfLocalDay(value?.startDate), dateFormat),
          moment(getEndOfLocalDay(value?.endDate), dateFormat),
        ]
      : []
  ) as [moment.Moment, moment.Moment];

  const onDatesChange = (
    selectedDateRange: [moment.Moment, moment.Moment]
  ): void => {
    const startDate = selectedDateRange?.[0]?.format(dateFormat) || null;
    const endDate = selectedDateRange?.[1]?.format(dateFormat) || null;
    onChange({
      startDate: startDate ? getStartOfUtcDay(startDate) : null,
      endDate: endDate ? getEndOfUtcDay(endDate) : null,
    });
  };
  return (
    <IntegryTooltip
      tooltipText={tooltipText}
      hideToolTip={!tooltipText || isMenuOpen}
      tooltipId="date_range_picker_tooltip"
    >
      <div
        className={`date_range_picker ${
          !value?.startDate || !value?.endDate ? "" : "selected"
        }`}
      >
        <RangePicker
          onChange={(date) => {
            onDatesChange(date as [moment.Moment, moment.Moment]);
          }}
          placeholder={plaseholder || ["Start Date", "End Date"]}
          onOpenChange={(open) => {
            setIsMenuOpen(open);
          }}
          value={rangeValue}
          disabledDate={(current) => {
            // disable future dates and dates before 365 days from today
            return (
              current &&
              (current > moment().endOf("day") ||
                current < moment().subtract(365, "days"))
            );
          }}
          separator={
            <img
              src={greyArrrow}
              alt="arrow"
              className="date_range_picker__separator"
            />
          }
        />
      </div>
    </IntegryTooltip>
  );
};

export default IntegryDateRangePicker;
