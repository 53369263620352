import {
  useState,
  ReactElement,
  CSSProperties,
  useRef,
  useEffect,
} from "react";
import searchBarIcon from "images/search-bar-icon.svg";
import xIcon from "images/x.svg";

import "./styles.scss";

interface IProps {
  disabled?: boolean;
  placeholder?: string;
  onChange?: (searchTxt: string) => void;
  style?: CSSProperties;
  className?: string;
  enableFocus?: boolean;
  showSearchResultCount?: boolean;
  searchResultCount?: number;
  crossIcon?: string;
}

const SearchBar = (props: IProps): ReactElement => {
  const {
    disabled = false,
    placeholder = "Search here",
    onChange,
    style = {},
    className = "",
    enableFocus = false,
    searchResultCount = -1,
    showSearchResultCount = false,
    crossIcon,
  } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const inputFieldRef = useRef<HTMLInputElement>(null);
  // focus on input field on mount when enableFocus is true
  useEffect(() => {
    inputFieldRef.current?.focus();
  }, []);

  const handleSearch = (searchTxt): void => {
    setSearchQuery(searchTxt);
    if (onChange) {
      onChange(searchTxt);
    }
  };

  return (
    <div className={`integry__search-container ${className}`} style={style}>
      <span className="integry__search-bar-icon">
        <img
          className="integry__search-icon"
          alt="search-icon"
          src={searchBarIcon}
        />
      </span>
      {searchQuery && searchResultCount !== -1 && (
        <span className="integry__search-results-count">
          {searchResultCount} results found
        </span>
      )}
      {searchQuery && searchQuery !== "" && (
        <span className="integry__search-bar-icon--x">
          <img
            className="integry__search-icon"
            alt="clear-search-icon"
            src={crossIcon || xIcon}
            width="15px"
            height="15px"
            onClick={() => {
              handleSearch("");
            }}
            style={{ cursor: "pointer" }}
            aria-hidden="true"
          />
        </span>
      )}
      <input
        className={`integry__search-bar ${
          enableFocus ? "focus-highlight" : ""
        } ${showSearchResultCount ? "show-search-result-count" : ""}`}
        placeholder={placeholder}
        value={searchQuery}
        ref={inputFieldRef}
        onChange={(e) => {
          if (!disabled) {
            handleSearch(e.target.value);
          }
        }}
      />
    </div>
  );
};

export default SearchBar;
