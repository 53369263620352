import http from "../../utils/http";

export const REQUEST_ENDPOINTS = "REQUEST_ENDPOINTS";

export const requestEndpoints = (app_id) => ({
  type: REQUEST_ENDPOINTS,
  app_id,
});

export const RECEIVE_ENDPOINTS = "RECEIVE_ENDPOINTS";

export const receiveEndpoints = (app_id, json) => ({
  type: RECEIVE_ENDPOINTS,
  app_id,
  endpoints: json,
});

export const fetchEndpoints = (app_id) => (dispatch) => {
  dispatch(requestEndpoints(app_id));

  return http.get(`/api/endpoints/?app=${app_id}`).then(
    (response) => {
      dispatch(receiveEndpoints(app_id, response.data));
    },
    (error) => {
      console.log(
        "Error occurred while fetching endpoints in fetchEndpoints() action.",
        error
      );
    }
  );
};

export const REMOVE_ENDPOINT = "REMOVE_ENDPOINT";

export const removeEndpoint = (app_id, endpoint_id) => ({
  type: REMOVE_ENDPOINT,
  app_id,
  endpoint_id,
});

export const REQUEST_ENDPOINT_TYPES = "REQUEST_ENDPOINT_TYPES";
export const RECEIVE_ENDPOINT_TYPES = "RECEIVE_ENDPOINT_TYPES";
export const REQUEST_ENDPOINT_TYPES_FAILED = "REQUEST_ENDPOINT_TYPES_FAILED";

export const requestEndpointTypes = () => ({
  type: REQUEST_ENDPOINT_TYPES,
  isFetching: true,
});

export const recieveEndpointTypes = (json) => ({
  type: RECEIVE_ENDPOINT_TYPES,
  isFetching: false,
  endpoint_types: json,
});

export const requestEndpointTypesFailed = () => ({
  type: REQUEST_ENDPOINT_TYPES_FAILED,
  isFetching: false,
});

export const fetchEndpointTypes = () => (dispatch) => {
  dispatch(requestEndpointTypes());

  http.get(`api/endpoint/types/`).then(
    (response) => {
      dispatch(recieveEndpointTypes(parseEndpointTypesJSON(response.data)));
    },
    (error) => {
      console.log(
        "Error occurred while fetching endpoint types in fetchEndpointTypes() action.",
        error
      );
      dispatch(requestEndpointTypesFailed());
    }
  );
};

export const parseEndpointTypesJSON = (endpointTypes) =>
  JSON.parse(JSON.stringify(endpointTypes)).map((endpointType) => {
    try {
      endpointType.preset_json = JSON.parse(endpointType.preset_json);
    } catch (e) {
      const error_message = `
      Failed to parse JSON for endpoint type
      ${endpointType.name}
      ${e.message}: ${e.message}
      `;
      console.log(error_message);
      endpointType.preset_json = {};
    }
    return endpointType;
  });
