import { ReactElement, useState } from "react";
import SettingWrapper from "features/templates/integration-builder/general-settings/settings-wrapper";
import ModalWithHeader from "components/integry-design-system/atoms/modals/model-with-header";
import Button from "components/integry-design-system/molecules/button";

import useDisableScroll from "hooks/useDisableScroll";
import { useFetchUserQuery } from "utils/user/user-api";
import { useFetchAppUserVariableQuery } from "features/account-settings/variables/api/useUserVariables";
import { useWorkspaceAppContext } from "context/workspaceAppContext";

import { ReactComponent as DocIcon } from "images/misc-doc.svg";
import openLinkIcon from "images//open-link.svg";
import downloadIcon from "images/download.png";

import ConfigureApps from "./ConfigureApps/ConfigureApps";
import ImpersonateUser from "./ImpersonateUser";
import ConfigureFunctions from "./ConfigureFunctions/ConfigureFunctions";
import "./MarketplaceModal.scss";
import { generateEmbedCodeForFunctions } from "./utils/generateCode";

interface IProps {
  onClose: () => void;
}

const Modal = ({ onClose }: IProps): ReactElement => {
  const { data: user } = useFetchUserQuery();

  const [selectedTab, setSelectedTab] = useState<string>("configure-apps");
  const [selectedUser, setSelectedUser] = useState<string>(
    user?.email || user?.username || ""
  );

  const { workspaceApp } = useWorkspaceAppContext();
  const { data: appUserVariables, isLoading: isLoadingAppUserVariables } =
    useFetchAppUserVariableQuery();

  useDisableScroll(true);
  const tabs = [
    { title: "Configure apps", id: "configure-apps" },
    { title: "Configure functions", id: "configure-functions" },
    { title: "Impersonate a user", id: "impersonate-user" },
  ];

  const renderSelectedTab = (): JSX.Element => {
    switch (selectedTab) {
      case "impersonate-user":
        return (
          <ImpersonateUser
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
          />
        );
      case "configure-apps":
        return <ConfigureApps />;
      case "configure-functions":
        return <ConfigureFunctions />;
      default:
        return <></>;
    }
  };

  const onGenerateCodeClick = (): void => {
    const userVariables = appUserVariables?.results.reduce((acc, curr) => {
      if (curr.key === "userId") return acc;
      acc[curr.key] = "";
      return acc;
    }, {}) as { [key: string]: string };

    if (workspaceApp) {
      const codeString = generateEmbedCodeForFunctions({
        config: {
          appKey: workspaceApp.key,
          secret: workspaceApp.secret,
          userId: selectedUser,
          userVariables,
        },
      });
      const element = document.createElement("a");
      const file = new Blob([codeString.replace(/\n/g, "\r\n")], {
        type: "text/html",
      });
      element.href = URL.createObjectURL(file);
      element.download = `Integry SDK sample code.html`;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
  };

  return (
    <ModalWithHeader
      show
      close={onClose}
      modalClassName="marketplace-modal"
      title="Marketplace"
    >
      <>
        <div className="preview-marketplace-subheader__links">
          <Button
            className="embed-with-sdk"
            variation="link"
            icon={<DocIcon width={12} height={12} />}
            size="auto"
            typographVariation="2"
            action={() => {
              window.open(
                "https://docs.integry.ai/hc/en-us/articles/23189976901529-Embed-the-marketplace-in-your-app",
                "_blank"
              );
            }}
          >
            Embed with SDK <img src={openLinkIcon} alt="open-link" />
          </Button>
          <Button
            variation="link"
            icon={downloadIcon}
            size="auto"
            typographVariation="2"
            action={() => onGenerateCodeClick()}
            disabled={isLoadingAppUserVariables}
          >
            Download sample code
          </Button>
        </div>
        <SettingWrapper
          tabs={tabs}
          disbledSaveButton={false}
          selectedTab={selectedTab}
          isLoading={false}
          showFooter={false}
          title=""
          renderSelectedTab={renderSelectedTab}
          onTabSwitch={(tab) => {
            setSelectedTab(tab);
          }}
          onCancel={() => {
            onClose();
          }}
          onSave={() => {
            onClose();
          }}
        />
      </>
    </ModalWithHeader>
  );
};

export default Modal;
