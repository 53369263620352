import http from "../../utils/http";

export const REQUEST_ACTIVITY_FIELDS = "REQUEST_ACTIVITY_FIELDS";

export const requestActivityFields = () => ({
  type: REQUEST_ACTIVITY_FIELDS,
});

export const RECEIVE_ACTIVITY_FIELDS = "RECEIVE_ACTIVITY_FIELDS";

export const receiveActivityFields = (json, activityId) => ({
  type: RECEIVE_ACTIVITY_FIELDS,
  lists: json,
  activityId: activityId,
});

export const fetchActivityFields = (activityId) => {
  return (dispatch) => {
    dispatch(requestActivityFields());

    return http.get(`/api/activity_fields/?activity=${activityId}`).then(
      (response) => {
        dispatch(receiveActivityFields(response.data, activityId));
      },
      (error) => {
        console.log(
          `Error occurred while fetching fetchActivityFields() action.`,
          error
        );
      }
    );
  };
};

export function fetchActivityFieldsForSteps(activityId) {
  return (dispatch, getState) => {
    return dispatch(fetchActivityFields(activityId));
  };
}
