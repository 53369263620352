import { Fragment, ReactElement, useState } from "react";
import { useFormikContext } from "formik";
import CommonIntegryModal from "components/common/common-integry-modal";
import { toast } from "react-toastify";
import { uniqueId } from "lodash-es";

import useDebounced from "hooks/useCancleableDebounce";
import TextFieldWithTitle from "components/forms/v4/fields-with-title-and-description";

import MeatballsMenu, {
  IMenuItems,
} from "components/integry-design-system/molecules/meatballs-menu";

import deleteIcon from "images/delete_bin_red.svg";
import deletePopupIcon from "images/delete-tag.svg";

import {
  useCreateAppUserVariableMutation,
  useUpdateAppUserVariableMutation,
  useDeleteAppUserVariableMutation,
} from "../../api/useUserVariables";
import { AppUserVariable } from "../../interface";

import { validateKey } from "../../utils/validations";

const AppUserVariableRow = ({
  appUserVariable,
  fieldName,
  isLast,
}: {
  appUserVariable: AppUserVariable;
  fieldName: string;
  isLast: boolean;
}): ReactElement => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { values, getFieldMeta, setFieldValue } = useFormikContext<{
    variables: AppUserVariable[];
  }>();

  const [createAppUserVariable] = useCreateAppUserVariableMutation();
  const [updateAppUserVariable] = useUpdateAppUserVariableMutation();
  const [deleteAppUserVariable] = useDeleteAppUserVariableMutation();

  const option: IMenuItems = {
    label: "Delete",
    value: "delete",
    iconPrefix: deleteIcon,
    className: "delete",
    disabled: isLast || appUserVariable.is_system_generated,
    tooltip: "",
    tooltipPlacement: "bottom",
  };

  const onTagChange = async (variable: AppUserVariable): Promise<void> => {
    const { key, isNew } = variable;

    if (!key) {
      return;
    }
    if (isNew) {
      try {
        const newVariable = await createAppUserVariable(variable).unwrap();
        if (newVariable) {
          setFieldValue(`${fieldName}.id`, newVariable.id);
          toast.success("User variable created successfully");
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      updateAppUserVariable(variable);
    }
  };

  const [debouncedOnTagChange] = useDebounced(onTagChange, 500);

  //   const keyError = getFieldMeta(`${fieldName}.key`).error;

  return (
    <Fragment key={appUserVariable.id}>
      <div
        className={`tag-row member-row ${isLast ? "last" : ""}`}
        key={appUserVariable.id}
      >
        <div className="tag-col">
          <TextFieldWithTitle
            name={`${fieldName}.key`}
            placeholder="Key"
            control="input"
            disabled={appUserVariable.is_system_generated}
            validate={(input: string) =>
              validateKey(input, values.variables, !appUserVariable.isNew)
            }
            onBlur={() => {
              const fieldMeta = getFieldMeta(`${fieldName}.key`);
              if (!fieldMeta.error) {
                debouncedOnTagChange(appUserVariable);
              }
            }}
            onChange={(key: string) => {
              // When the key is changed of last variable, add new variable
              setTimeout(() => {
                const variables = getFieldMeta("variables")
                  .value as AppUserVariable[];
                if (isLast) {
                  setFieldValue("variables", [
                    ...variables.slice(0, variables.length - 1),
                    { ...appUserVariable, key },
                    {
                      id: uniqueId("workspaceVariable_"),
                      key: "",
                      value: "",
                      isNew: true,
                    },
                  ]);
                }
              }, 10);
            }}
          />
        </div>
        {/*    <div className="tag-col">
          <TextFieldWithTitle
            name={`${fieldName}.is_required`}
            control="toggle"
            disabled={
              appUserVariable.is_system_generated ||
              !appUserVariable.key ||
              keyError
            }
            onChange={(value: boolean) => {
              debouncedOnTagChange({
                ...appUserVariable,
                is_required: value,
              });
            }}
          />
        </div> */}
        <div className="tag-col">
          <MeatballsMenu
            items={[option]}
            onSelect={() => {
              // delete new variable directly
              if (appUserVariable.isNew) {
                const newVariables = values.variables.filter(
                  (v) => v.id !== appUserVariable.id
                );
                setFieldValue("variables", newVariables);
              } else {
                setShowDeleteModal(true);
              }
            }}
            customStyle={{ marginLeft: "5px" }}
          />
        </div>
      </div>

      <CommonIntegryModal
        show={showDeleteModal}
        close={() => {
          setShowDeleteModal(false);
        }}
        title={`Delete ‘${appUserVariable.key}’`}
        message={`Deleting the ‘${appUserVariable.key}’ variable will cause any flows that use it to stop working. \n\nAre you sure?`}
        icon={deletePopupIcon}
        action={() => {
          const newVariables = values.variables.filter(
            (v) => v.id !== appUserVariable.id
          );

          setFieldValue("variables", newVariables);
          deleteAppUserVariable({
            id: appUserVariable.id,
          });
          setShowDeleteModal(false);
        }}
        actionButtonTitle="Delete"
        modalClass="remove-user-modal"
      />
    </Fragment>
  );
};

export default AppUserVariableRow;
