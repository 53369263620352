import {
  REQUEST_GENERIC_OBJECTS,
  RECEIVE_GENERIC_OBJECTS,
} from "../actions/generic-objects";

export const genericObjects = (
  state = { isFetching: false, items: [] },
  action
) => {
  switch (action.type) {
    case REQUEST_GENERIC_OBJECTS:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_GENERIC_OBJECTS:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.generic_objects,
      });
    default:
      return state;
  }
};
