/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
import {
  ComponentType,
  Fragment,
  ReactElement,
  useEffect,
  useState,
} from "react";
import PlaceholderBar from "atomic-components/atom/placeholder-bar";

import { useInView } from "react-intersection-observer";

import "./styles.scss";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  getExpandedRowModel,
  Row,
  ExpandedState,
} from "@tanstack/react-table";

export interface IntegryTableProps<T> {
  data: T[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<T, any>[];
  showFooter?: boolean;
  hasMore?: boolean;
  isLoading?: boolean;
  loadingRowCount?: number;
  fetchMore?: () => void;
  onRowClick?: (row: T) => void;
  getRowCanExpand?: (row: Row<T>) => boolean;
  getRowId?: (row: T) => string;
  getRowClassName?: (row: T) => string;
  expandedState?: ExpandedState;
  RowWrapperComponent?: ComponentType<{
    row: T;
    isExpanded: boolean;
    children: ReactElement;
    index: number;
  }>;
  ExpandedRowComponent?: ComponentType<{
    row: T;
  }>;
}

function IntegryTable<T>(props: IntegryTableProps<T>): ReactElement {
  const {
    data,
    columns,
    showFooter = false,
    hasMore = false,
    isLoading = false,
    fetchMore = null,
    onRowClick = null,
    getRowCanExpand = () => false,
    getRowClassName = () => "",
    getRowId = (originalRow: T, index: number, parent?: Row<T>) => `${index}`,
    expandedState = null,
    loadingRowCount = 10,
    RowWrapperComponent = EmptyWrapperComponent,
    ExpandedRowComponent = null,
  } = props;

  const [expanded, setExpanded] = useState({});

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded: expandedState || expanded,
    },
    onExpandedChange: setExpanded,
    getRowCanExpand, // Enable expanding for all rows
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowId,
  });

  const cellCount = table.getHeaderGroups()?.[0].headers.length || 0;

  return (
    <table>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {isLoading ? (
          <RnderLoadingRows
            cellCount={cellCount}
            rowCount={loadingRowCount}
            fetchMore={null}
          />
        ) : (
          <>
            {table.getRowModel().rows.map((row) => (
              <div>
                <RowWrapperComponent
                  row={row.original}
                  isExpanded={row.getIsExpanded()}
                  index={row.index}
                  key={row.id}
                >
                  <tr
                    key={row.id}
                    onClick={() => {
                      if (onRowClick) {
                        onRowClick(row.original);
                      } else if (row.getCanExpand()) {
                        row.toggleExpanded();
                      }
                    }}
                    className={`${
                      row.getIsExpanded() ? "expanded" : ""
                    } ${getRowClassName(row.original)}`}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                </RowWrapperComponent>
                {row.getIsExpanded() && ExpandedRowComponent && (
                  <ExpandedRowComponent row={row.original} />
                )}
              </div>
            ))}
          </>
        )}
        {hasMore && (
          <RnderLoadingRows
            cellCount={cellCount}
            rowCount={3}
            fetchMore={fetchMore}
          />
        )}
      </tbody>
      {showFooter && (
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      )}
    </table>
  );
}

export default IntegryTable;

const RnderLoadingRows = ({ rowCount, cellCount, fetchMore }): ReactElement => {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView && fetchMore) {
      fetchMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <>
      {Array.from({ length: rowCount }).map((_, index) => (
        <tr key={`${index}_loading-row`} ref={index === 0 ? ref : null}>
          {/* set ref to first loader row */}
          {Array.from({ length: cellCount }).map((__, cellIndex) => (
            <td key={`${cellIndex}_loading-col`}>
              <PlaceholderBar
                customStyle={{
                  maxWidth: "80%",
                  borderRadius: "4px",
                  margin: "0px",
                }}
              />
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

const EmptyWrapperComponent = ({ children, row }): ReactElement => (
  <>{children}</>
);
