import { REQUEST_AUTH_TYPES, RECEIVE_AUTH_TYPES } from "../actions/auth-types";

export const authTypes = (state = { isFetching: false, items: [] }, action) => {
  switch (action.type) {
    case REQUEST_AUTH_TYPES:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_AUTH_TYPES:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.auth_types,
      });
    default:
      return state;
  }
};
