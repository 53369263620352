import React, { ReactElement } from "react";
import { Field } from "formik";
import { FormFeedback } from "reactstrap";
import ReactTagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

type Modify<T, R> = Omit<T, keyof R> & R;
interface TagsInputProps
  extends Modify<
    React.HTMLProps<HTMLInputElement>,
    {
      onChange?(value: string): void;
    }
  > {
  name: string;
  validate?(value): string;
  errorPlaceHolder?: boolean;
}

const TagsInput = React.forwardRef<ReactTagsInput, TagsInputProps>(
  (props, forwardedRef): ReactElement => {
    const {
      onChange,
      onBlur,
      name,
      className,
      errorPlaceHolder,
      validate,
      ...rest
    } = props;
    return (
      <Field name={name} validate={validate}>
        {(fieldProps) => {
          const { field, meta, form } = fieldProps;
          const { touched, error } = meta;
          const { value } = field;
          const { setFieldValue } = form;
          const validationClass = touched && error ? " is-invalid" : "";
          return (
            <div>
              <ReactTagsInput
                className={`h-auto react-tagsinput form-control${validationClass} ${className}`}
                value={value ? value.split(", ") : []}
                onChange={(tags) => {
                  const tagsString = tags.join(", ");
                  setFieldValue(name, tagsString);
                  if (onChange) {
                    onChange(tagsString);
                  }
                }}
                inputProps={{
                  ...rest,
                }}
                ref={forwardedRef}
              />

              {error ? (
                <FormFeedback>{error}</FormFeedback>
              ) : (
                <>{errorPlaceHolder && <div style={{ height: 23 }} />}</> // Prevent page jumping if erroe is displayed
              )}
            </div>
          );
        }}
      </Field>
    );
  }
);

TagsInput.defaultProps = {
  validate: () => "",
  errorPlaceHolder: false,
};

export default TagsInput;
