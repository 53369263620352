import {
  REQUEST_ENDPOINTS,
  RECEIVE_ENDPOINTS,
  REMOVE_ENDPOINT,
  REQUEST_ENDPOINT_TYPES,
  RECEIVE_ENDPOINT_TYPES,
  REQUEST_ENDPOINT_TYPES_FAILED,
} from "../actions/endpoints";

export const endpointsByApp = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ENDPOINTS:
    case RECEIVE_ENDPOINTS:
    case REMOVE_ENDPOINT:
      return Object.assign({}, state, {
        [action.app_id]: endpoints(state[action.app_id], action),
      });
    default:
      return state;
  }
};

export const endpoints = (
  state = { isFetching: false, fetchCount: 0, items: [] },
  action
) => {
  switch (action.type) {
    case REQUEST_ENDPOINTS:
      return Object.assign({}, state, {
        isFetching: true,
        fetchCount: state.fetchCount + 1,
      });
    case RECEIVE_ENDPOINTS:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.endpoints,
      });
    case REMOVE_ENDPOINT:
      return {
        ...state,
        items: state.items.filter(
          (endpoint) => String(endpoint.id) !== String(action.endpoint_id)
        ),
      };
    default:
      return state;
  }
};

export const endpointTypes = (
  state = { isFetching: false, items: [] },
  action
) => {
  switch (action.type) {
    case REQUEST_ENDPOINT_TYPES:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
      });
    case REQUEST_ENDPOINT_TYPES_FAILED:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
      });
    case RECEIVE_ENDPOINT_TYPES:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        items: action.endpoint_types,
      });
    default:
      return state;
  }
};
