import { useState, CSSProperties, useEffect } from "react";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";

import "./styles.scss";

export interface ICheckboxProps {
  label?: string;
  value: string;
  render?: JSX.Element;
  labelToolTip?: string;
  disabled?: boolean;
  labelToolTipPosition?: "top" | "left" | "right" | "bottom";
}

export interface IProps {
  customStyle?: CSSProperties;
  onOptionSelect?: (options) => void;
  options: Array<ICheckboxProps>;
  defaultValue?: Array<string>;
  fadeEffect?: boolean;
}

const CheckboxSelection = ({
  customStyle = {},
  ...props
}: IProps): JSX.Element => {
  const [value, setValue] = useState<Array<string>>([]);

  const {
    onOptionSelect,
    options = [],
    defaultValue,
    fadeEffect = false,
  } = props;

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleCheck = (event): void => {
    if (event.target.type === "checkbox") {
      let updatedList = [...value];
      if (event.target.checked) {
        updatedList = [...value, event.target.value];
      } else {
        updatedList.splice(value.indexOf(event.target.value), 1);
      }
      setValue(updatedList);
      if (onOptionSelect) {
        onOptionSelect(updatedList);
      }
    }
  };

  return (
    <div className="integry-checkbox-selection-container">
      <div className="integry-checkbox-selection">
        {options.map((option) => {
          return (
            <div className="integry-control-group" key={option.value}>
              <input
                type="checkbox"
                id={option.value}
                className="integry-checkbox-selection__input"
                value={option.value}
                onChange={handleCheck}
                checked={value.includes(option.value)}
                disabled={option.disabled}
              />

              {!option.render && (
                <IntegryTooltip
                  tooltipText={option.labelToolTip}
                  placement={option.labelToolTipPosition || "bottom"}
                  hideToolTip={!option.labelToolTip}
                >
                  <label
                    htmlFor={option.value}
                    className={`integry-checkbox-selection__label ${
                      option.disabled ? "disabled" : ""
                    } `}
                    aria-hidden="true"
                    onClick={(e) => {
                      if (!option.disabled) {
                        handleCheck(e);
                      }
                    }}
                  >
                    <span
                      className={`custom-checkbox ${
                        option.disabled ? "disabled" : ""
                      } ${
                        value.includes(option.value) ? "checked" : "unchecked"
                      }`}
                    />
                    {option.label}
                  </label>
                </IntegryTooltip>
              )}
              {option.render && (
                <div
                  className={`integry-checkbox-selection__label ${
                    option.disabled ? "disabled" : ""
                  } `}
                  onClick={(e) => {
                    if (!option.disabled) {
                      handleCheck(e);
                    }
                  }}
                  aria-hidden="true"
                >
                  {option.render}
                </div>
              )}
            </div>
          );
        })}
      </div>
      {fadeEffect && <div className="fade-effect" />}
    </div>
  );
};

export default CheckboxSelection;
