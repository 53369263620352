import { createApi } from "@reduxjs/toolkit/query/react";
import { integryBaseQuery } from "utils/http";
import { PaginatedResponse } from "types/paginated-response";
import { HTTPCallApp } from "features/templates/interface/http-call";

type HTTPCallAppsResponse = PaginatedResponse<
  HTTPCallApp[],
  Record<string, unknown>
>;

export const useHttpCallApps = createApi({
  reducerPath: "httpCallApps",
  baseQuery: integryBaseQuery(),
  tagTypes: ["httpCallApps"],
  endpoints: (builder) => ({
    getHttpCallApps: builder.query<
      HTTPCallAppsResponse,
      {
        public_only: boolean;
      } | void
    >({
      query: (args) => ({
        url: `/api/v1/apps/?page=1&page_size=10000&http_call_apps_list=true&search=&public_only=${
          args?.public_only ? "true" : "false"
        }`,
        method: "GET",
      }),
      providesTags: ["httpCallApps"],
    }),
  }),
});

export const { useGetHttpCallAppsQuery } = useHttpCallApps;
