import { useEffect, useState } from "react";
import { IntercomProvider } from "react-use-intercom";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { LaunchDarkly, FeatureFlag } from "react-launch-darkly";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { useListActivitiesQuery } from "features/templates/integration-builder/services/api/activity-listing-api";
import { useGetHttpCallAppsQuery } from "features/templates/api/useHttpCallApps";
import { useFetchWorkspaceVariableQuery } from "features/account-settings/variables/api/useWorkspaceVariables";
import { useFetchAppUserVariableQuery } from "features/account-settings/variables/api/useUserVariables";
import useVariableTags from "features/templates/hooks/useVariableTags";

// import userflow from "userflow.js";

import config from "../utils/config";
import { useFetchUserQuery } from "utils/user/user-api";

import ScrollToTop from "./scroll-to-top";
import Dashboard from "./dashboard";
import StepWizard from "components/turbo.v1/step-wizard";
import OnboardingSuccessPortal from "components/turbo.v1/onboarding-success-portal";
import NoMatchFound from "components/no-match-found";
import removeToastButton from "../images/toastCancelButton.svg";
import { setUserId } from "store/actions/apps";

const CloseButton = ({ closeToast }) => (
  <img
    className="custom-close-toast"
    src={removeToastButton}
    onClick={closeToast}
    alt="Close"
  />
);

const Integry = (props) => {
  const { globallySelectedApp } = props;
  const [authenticated, setAuthenticated] = useState(false);
  // const [user, setUser] = useState(null);
  const [showOnboardinSuccess, setShowOnboardinSuccess] = useState(false);
  const [feedback, setFeedback] = useState({
    visible: false,
    success: true,
    message: "This is a placeholder feedback alert message!",
  });
  const { data: user, isLoading: fetchingUser } = useFetchUserQuery(
    globallySelectedApp || ""
  );
  const { accounts, ...restUser } = user || {};
  useEffect(() => {
    if (user?.id) {
      // run if user is loggedin
      initializeExternalLibraries();
      setAuthenticated(true);
      props.setUserIdGlobally(user.id);
    }
  }, [user?.id]);

  // pre-fetching app list for http call step dropdown in flow builder
  useGetHttpCallAppsQuery();

  // pre-fetching workspace variables for use in flow builders tag menu
  useFetchWorkspaceVariableQuery();

  // pre-fetching user variables for use in flow builders tag menu
  useFetchAppUserVariableQuery();

  useListActivitiesQuery({
    // initiaal trigger list
    searchText: "",
    page: 1,
    activityType: "TRIGGER",
    excludeType: "",
    systemActivities: false,
    showElseIfElse: false,
    excludeInitializedTrigger: false,
    includeLoopSystemOperations: false,
    flatActivityPicker: true,
  });

  useListActivitiesQuery({
    // trigger list excluding initialized trigger (Setup)
    searchText: "",
    page: 1,
    activityType: "TRIGGER",
    excludeType: "",
    systemActivities: false,
    showElseIfElse: false,
    excludeInitializedTrigger: true,
    includeLoopSystemOperations: false,
    flatActivityPicker: true,
  });

  useListActivitiesQuery({
    // Step list
    searchText: "",
    page: 1,
    activityType: "",
    excludeType: "TRIGGER",
    systemActivities: false,
    showElseIfElse: false,
    excludeInitializedTrigger: false,
    includeLoopSystemOperations: false,
    flatActivityPicker: true,
  });

  useListActivitiesQuery({
    // Step list including Else If, Else
    searchText: "",
    page: 1,
    activityType: "",
    excludeType: "TRIGGER",
    systemActivities: false,
    showElseIfElse: true,
    excludeInitializedTrigger: false,
    includeLoopSystemOperations: false,
    flatActivityPicker: true,
  });

  // pre-fetching variable tags for use in flow builders tag menu
  useVariableTags();

  const setFeedbackMessage = (feedback) => {
    setFeedback({
      visible: true,
      success: feedback.success,
      message: feedback.message,
    });
  };

  const initializeExternalLibraries = () => {
    // call it in use effect

    appcuesIdentify(user.id, user.account_name, user.email, user.date_joined);
    if (localStorage.getItem("isMeetingScheduled")) {
      setShowOnboardinSuccess(true);
    }
  };

  const appcuesIdentify = (id, email, signupTimestamp) => {
    window.Appcues?.identify(id, { email, createdAt: signupTimestamp });
  };

  const getMeetingScheduleStatus = () => {
    const meetingScheduledStatus =
      localStorage.getItem("isMeetingScheduled") || "not_yet_scheduled";
    if (meetingScheduledStatus === "scheduled") {
      return true;
    }
    return false;
  };
  return (
    <>
      {authenticated && user ? (
        <LaunchDarkly
          clientId={config.launchDarklyClientId}
          user={{
            key: user.email,
            email: user.email,
            name: user.username,
            custom: {
              // Custom attributes specific to your application
              accountId: user.account_id,
              account_has_direct_actions: !!user.account?.has_direct_actions,
              account_has_v6_and_earlier_version_flows:
                !!user.account?.has_v6_and_earlier_version_flows,
            },
            ...restUser,
          }}
          clientOptions={{ hash: user.launch_darkly.ld_hash }}
        >
          <Router basename="/wapp">
            <FeatureFlag
              flagKey="onboarding-by-website"
              renderFeatureCallback={() => (
                <ScrollToTop>
                  <Switch>
                    <Route
                      path="/"
                      render={(routeProps) => (
                        <Dashboard
                          {...routeProps}
                          user={user}
                          setFeedbackMessage={setFeedbackMessage}
                        />
                      )}
                    />
                  </Switch>
                </ScrollToTop>
              )}
              renderDefaultCallback={() => (
                <>
                  {user?.onboarding_completed ? (
                    <ScrollToTop>
                      <Switch>
                        {user.email && user.email.includes("@integry.io") && (
                          <Route
                            path="/get-started"
                            render={(routeProps) => (
                              <IntercomProvider appId={config.intercomAppId}>
                                <StepWizard user={user} {...routeProps} />
                              </IntercomProvider>
                            )}
                          />
                        )}
                        <Route
                          path="/"
                          render={(routeProps) => (
                            <Dashboard
                              {...routeProps}
                              user={user}
                              setFeedbackMessage={setFeedbackMessage}
                            />
                          )}
                        />
                      </Switch>
                      {user.onboarding_completed &&
                        user.onboarding_completed &&
                        showOnboardinSuccess && (
                          <OnboardingSuccessPortal
                            meetingScheduled={getMeetingScheduleStatus()}
                            user={user}
                            onClose={() => {
                              localStorage.removeItem("isMeetingScheduled");
                              setShowOnboardinSuccess(false);
                            }}
                          />
                        )}
                    </ScrollToTop>
                  ) : (
                    <Switch>
                      <Route
                        path="/"
                        exact
                        render={(routeProps) => (
                          <Redirect to="/get-started" {...routeProps} />
                        )}
                      />
                      <Route
                        path="/get-started"
                        render={(routeProps) => (
                          <IntercomProvider appId={config.intercomAppId}>
                            <StepWizard user={user} {...routeProps} />
                          </IntercomProvider>
                        )}
                      />
                      <Route component={NoMatchFound} />
                    </Switch>
                  )}
                </>
              )}
            />

            <ToastContainer
              position="bottom-left"
              autoClose={7000}
              hideProgressBar
              closeButton={<CloseButton />}
            />
          </Router>
        </LaunchDarkly>
      ) : (
        <></>
      )}
    </>
  );
};

export default connect(
  (state) => ({
    globallySelectedApp: state.globallySelectedApp,
  }),
  (dispatch) => ({
    setUserIdGlobally(userId) {
      dispatch(setUserId(userId));
    },
  })
)(Integry);
