import { ReactElement } from "react";
import AceEditor from "react-ace";
import Button from "components/integry-design-system/molecules/button";

import "./styles.scss";

interface IProps {
  onChange: (value: string) => void;
  value: string;
  theme?: string;
  mode?: string;
  name?: string;
  height?: string;
  showFormatJson?: boolean;
}

const CodeEditor = (props: IProps): ReactElement => {
  const {
    onChange,
    value = "",
    theme = "textmate",
    mode = "json",
    name = "",
    height = "300px",
    showFormatJson = true,
  } = props;

  const formatJson = (): void => {
    try {
      const formattedValue = JSON.stringify(JSON.parse(value), null, 2);
      onChange(formattedValue);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="code-editor-container">
      {mode === "json" && showFormatJson && (
        <div className="format-json">
          <Button variation="link" action={formatJson}>
            Beautify
          </Button>
        </div>
      )}
      <AceEditor
        name={name}
        mode={mode}
        theme={theme}
        className="datap code-editor"
        fontSize={12}
        showGutter
        height={height}
        width="100%"
        value={value}
        onChange={(code: string) => {
          onChange(code);
        }}
        wrapEnabled
        showPrintMargin={false}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
    </div>
  );
};

export default CodeEditor;
