/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { useState, ReactElement, useRef } from "react";
import SearchBar from "components/common/search-input-field/searchbar-v3";
import { ICheckboxProps } from "components/integry-design-system/molecules/checkbox-selection";
import CheckboxDropdown from "components/integry-design-system/molecules/dropdowns/checkbox-dropdown";
import ModalWithHeader from "components/integry-design-system/atoms/modals/model-with-header";
import Tag from "components/integry-design-system/atoms/tag";
import NumberRangeInput from "components/integry-design-system/molecules/number-range-input";

import useOnClickOutside from "utils/use-onclick-outside";

import { useGetStepsOfRunQuery } from "../api/useRunStepsApi";

import StepListingTable from "./listing";

import "./styles.scss";

interface ExpandableSectionProps {
  flowId: string;
  integrationId: string;
  runId: string;
  onClose: () => void;
}

const ExpandableSection = (props: ExpandableSectionProps): ReactElement => {
  const { flowId, integrationId, runId, onClose } = props;

  const [expandedPayloadStepId, setExpandedPayloadStepId] =
    useState<string>("-1");

  const [queryArguements, setQueryArguements] = useState<{
    search: string;
    stepStatus: string[];
    page: number;
    sequenceNumberRange?: string;
    sort: {
      [key: string]: "asc" | "desc";
    };
  }>({
    search: "",
    stepStatus: [],
    page: 1,
    sequenceNumberRange: "",
    sort: {
      execution_sequence_number: "asc",
    },
  });

  const contentRef = useRef<HTMLDivElement>(null);

  const {
    data: runSteps,
    isLoading: isLoadingSteps,
    isFetching: isFetchingSteps,
  } = useGetStepsOfRunQuery(
    {
      run_id: runId,
      itnegration_id: integrationId,
      search: queryArguements.search,
      stepStatus: queryArguements.stepStatus,
      page: queryArguements.page,
      executionSequenceNumber: queryArguements.sequenceNumberRange,
      orderBy:
        queryArguements.sort.execution_sequence_number === "asc"
          ? "execution_sequence_number"
          : "-execution_sequence_number",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useOnClickOutside(contentRef, onClose);

  const tableConfig = {
    steps: {
      config: [
        {
          id: "execution_sequence_number",
          label: "#",
          sortingIdentifier: "execution_sequence_number",
          allowSorting: true,
        },
        {
          id: "app",
          subConfig: [
            {
              id: "icon_url",
              type: "img",
            },
            {
              id: "step_title",
            },
            {
              type: "tag",
              id: "activity_version",
            },
          ],
          label: "Name",
          type: "",
        },
        {
          id: "start_time",
          label: "Start Time",
        },
        {
          id: "duration_string",
          label: "Duration",
        },
        {
          id: "status",
          label: "Status",
          type: "tag",
        },
        {
          id: "network_code_label",
          label: "Network Code",
          tdStyle: {
            justifyContent: "space-between",
          },
          showTooltip: true,
          tooltipField: "network_code_description",
        },
      ],
    },
  };

  const getStepStatusOptions = (): Array<ICheckboxProps> => {
    const lastRunStatusOptions = [] as Array<ICheckboxProps>;
    lastRunStatusOptions.push(
      {
        render: <Tag label="OK" type="run" />,
        value: "OK",
        label: "OK",
      },
      {
        render: <Tag label="Failed" type="run" />,
        value: "FAILED",
        label: "Failed",
      },
      {
        render: (
          <Tag
            customStyle={{
              background: "#DCEAFF",
              color: "#5194F8",
            }}
            label="Running..."
            type="run"
          />
        ),
        value: "RUNNING",
        label: "Running...",
      }
      /* {
        render: <Tag label="True" type="run" />,
        value: "TRUE",
        label: "True",
      },
      {
        render: <Tag label="False" type="run" />,
        value: "FALSE",
        label: "False",
      } */
    );
    return lastRunStatusOptions;
  };

  return (
    <ModalWithHeader
      show
      title={`Execution log -- Run ID: ${runId}`}
      close={onClose}
      modalClassName="run-steps-expandable-section expanded container run-steps-modal"
    >
      <div className="content">
        <div className="flex gap-10px">
          <SearchBar
            searchQueryProp={queryArguements.search}
            onSearch={(value) => {
              setQueryArguements((prev) => ({
                ...prev,
                search: value,
                page: 1,
              }));
            }}
            placeholder="Search for step, app, or data in payload..."
          />
          <NumberRangeInput
            onChange={(value) => {
              setQueryArguements((prev) => ({
                ...prev,
                sequenceNumberRange: value,
                page: 1,
              }));
            }}
            placeholder="Step #"
          />
          <CheckboxDropdown
            options={getStepStatusOptions()}
            value={queryArguements.stepStatus}
            onChange={(value) => {
              setQueryArguements((prev) => ({
                ...prev,
                stepStatus: value,
                page: 1,
              }));
            }}
            placeholder="Step Status"
            tooltipText="Filter by step status"
          />

          {!isLoadingSteps && (
            <div className="txt-sm-liight-gray align-center">{`${
              runSteps?.count.toLocaleString() || 0
            } ${runSteps?.count === 1 ? "Step" : "Steps"}`}</div>
          )}
        </div>

        <div className="integry-views">
          <div className="view-table">
            <StepListingTable
              integrationIdParam={integrationId}
              expandedPayloadStepId={expandedPayloadStepId}
              tableConfig={tableConfig}
              setExpandedPayloadStepId={setExpandedPayloadStepId}
              handleOnSorting={(value) => {
                setQueryArguements((prev) => ({
                  ...prev,
                  sort: {
                    [value]: prev.sort[value] === "asc" ? "desc" : "asc",
                  },
                  page: 1,
                }));
              }}
              steps={runSteps?.results || []}
              flowId={flowId}
              sorting={queryArguements.sort}
              runIdParam={runId}
              fetching={
                isLoadingSteps ||
                (isFetchingSteps && queryArguements.page === 1)
              }
              key={runId}
              hasMore={runSteps?.has_more || false}
              fetchMoreSteps={() => {
                setQueryArguements((prev) => ({
                  ...prev,
                  page: prev.page + 1,
                }));
              }}
            />
          </div>
        </div>
      </div>
    </ModalWithHeader>
  );
};

export default ExpandableSection;
