import { createApi } from "@reduxjs/toolkit/query/react";
import { PaginatedResponse } from "types/paginated-response";
import { Runs } from "types/run";
import {
  RunStepsResponse,
  hideNetworkCode,
  hideStatus,
} from "features/integration-run-steps";
import { convertDateToUTCString } from "utils/date-utils";
import { integryBaseQuery } from "utils/http";
import { Step } from "../template-builder-v7/services/template-builder-interface";

interface ValidateTemplateResponse {
  is_setup_form_correctly_configured: boolean;
  details: null | {
    // this will be null if form is correctly configured
    message: string;
    error_type: "REQUIRED_FIELD_MISSING_CONFIGURATION" | "MISSING_AUTH";
    field_machine_name: string;
  };
}

export const testTriggerAPI = createApi({
  reducerPath: "testTriggerAPI",
  baseQuery: integryBaseQuery(),
  tagTypes: ["trigger-test-api"],
  endpoints(build) {
    return {
      testTrigger: build.mutation({
        query: (body) => ({
          baseUrl: "",
          url: body.url,
          method: "POST",
        }),
      }),
      validateTemplateForTesting: build.mutation<
        ValidateTemplateResponse,
        { templateId: number | string; steps: Step[] }
      >({
        query: ({ templateId, steps }) => ({
          url: `/api/test-integration-validation/?template_id=${templateId}`,
          method: "POST",
          data: steps,
        }),
      }),
      pollRuns: build.query<
        PaginatedResponse<Runs>,
        { template_id: number; trigger_id: number }
      >({
        query: ({ template_id, trigger_id }) => ({
          url: `/api/v1/templates/${template_id}/test-triggers/${trigger_id}/runs/?use_updated_response_format=true&page=1&page_size=50`,
          method: "GET",
          headers: {
            "skip-404-page": true,
          },
        }),
      }),
      getStepsOfRun: build.query<
        RunStepsResponse,
        {
          template_id: number;
          trigger_id: number;
          run_id: string;
          stepStatus: string[];
          search?: string;
          execution_sequence_number_range_start: number;
        }
      >({
        query: ({
          template_id,
          trigger_id,
          run_id,
          search,
          stepStatus,
          execution_sequence_number_range_start = 1,
        }) => ({
          url: `/api/v1/templates/${template_id}/test-triggers/${trigger_id}/runs/${run_id}/steps/?use_updated_response_format=true&search=${
            search || ""
          }&page_size=5&status=${stepStatus.join(
            ","
          )}&execution_sequence_number_range_start=${execution_sequence_number_range_start}`,
          method: "GET",
        }),
        transformResponse: (response: RunStepsResponse, meta, args) => {
          return {
            ...response,
            results: response.results.map((step) => ({
              ...step,
              start_time: convertDateToUTCString(step.start_time),
              network_code_label: hideNetworkCode(step)
                ? "N/A"
                : `${step.network_code}`,
              status: hideStatus(step) ? "N/A" : step.status,
            })),
            has_more:
              response.meta.run.status === "RUNNING" ||
              response.results.length > 0,
          };
        },
        serializeQueryArgs: ({ endpointName, queryArgs }) => {
          return `${endpointName}-${queryArgs.run_id}`;
        },
        // Always merge incoming data to the cache entry
        merge: (currentCache, newItems, { arg }) => {
          if (arg.execution_sequence_number_range_start === 1) {
            return newItems;
          }
          return {
            ...newItems,
            results: [...currentCache.results, ...newItems.results],
          };
        },
        // Refetch when the page arg changes
        forceRefetch({ currentArg, previousArg }) {
          return (
            currentArg?.search !== previousArg?.search ||
            currentArg?.stepStatus !== previousArg?.stepStatus
          );
        },
      }),
    };
  },
});

export const {
  useTestTriggerMutation,
  usePollRunsQuery,
  useGetStepsOfRunQuery,
  useValidateTemplateForTestingMutation,
} = testTriggerAPI;
