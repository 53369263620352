/* eslint-disable import/prefer-default-export */
export const teamMemberActions = [
  {
    label: "Resend Invite",
    value: "resendInvite",
  },
  {
    label: "Cancel Invite",
    value: "cancelInvite",
    className: "cancle-invite-action",
  },
  {
    label: "Remove Access",
    value: "revokeAccess",
    className: "revoke-access-action",
  },
];
