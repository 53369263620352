/* eslint-disable import/prefer-default-export */
import editIcon from "images/edit-icon-small.svg";
import deleteIcon from "images/delete_bin_red.svg";

export const TagActions = [
  {
    label: "Rename",
    value: "rename",
    iconPrefix: editIcon,
  },
  {
    label: "Delete",
    value: "delete",
    iconPrefix: deleteIcon,
    className: "delete",
  },
];
