/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import lang from "utils/lang/en";

import "./styles.scss";
import cancelIcon from "images/cancel-turbo-v1.svg";

const MissingInfoPortal = (props) => {
  const {
    onContinueClick,
    onCancelClick,
    onboardingSurvey = {},
    hideConnectorInfo = false,
  } = props;
  const [appsYouWant, setAppsYouWant] = useState("");
  const [flowYouWant, setFlowYouWant] = useState("");
  const [error, setError] = useState({});

  const validate = () => {
    let isValid = true;
    let tempError = {};
    if (!hideConnectorInfo && appsYouWant === "") {
      tempError = {
        ...tempError,
        appsYouWant: `Required field is missing`,
      };
      isValid = false;
    }
    if (flowYouWant === "") {
      tempError = {
        ...tempError,
        flowYouWant: `Required field is missing`,
      };
      isValid = false;
    }
    setError(tempError);
    return isValid;
  };
  const handleOnContinueClick = (data) => {
    if (onContinueClick && validate()) {
      onContinueClick(data);
    }
  };
  const handleOnCancelClick = () => {
    if (onCancelClick) {
      onCancelClick();
    }
  };
  useEffect(() => {
    if (onboardingSurvey.appsYouWant) {
      setAppsYouWant(appsYouWant);
    }
    if (onboardingSurvey.flowYouWant) {
      setFlowYouWant(flowYouWant);
    }
  }, []);
  return (
    <div className="missing-info-portal">
      <div className="mip-container">
        <span className="mip-close-icon">
          <img
            src={cancelIcon}
            alt="cancel-icon"
            onClick={handleOnCancelClick}
            role="button"
            tabIndex={0}
            onKeyDown={handleOnCancelClick}
          />
        </span>
        <div className="mip-container__heading">
          <p>{lang.TELL_US_MORE}</p>
        </div>
        <div className="mip-container__content">
          {!hideConnectorInfo && (
            <div
              className={`mip-container__control-group${
                error.appsYouWant ? " error-field" : ""
              }`}
            >
              <p>{lang.SELECT_CONNECTOR_PLACEHOLDER}</p>
              <input
                type="text"
                placeholder={lang.ENTER_CONNECTOR_PLACEHOLDER}
                value={appsYouWant}
                onChange={(e) => {
                  setAppsYouWant(e.target.value);
                }}
              />
            </div>
          )}
          <div
            className={`mip-container__control-group${
              error.flowYouWant ? " error-field" : ""
            }`}
          >
            <p>{lang.SUGGEST_FLOW_PLACEHOLDER_LABEL}</p>
            <textarea
              style={{ height: "83px", minHeight: "83px", maxHeight: "300px" }}
              value={flowYouWant}
              placeholder={lang.SUGGEST_FLOW_PLACEHOLDER}
              onChange={(e) => {
                setFlowYouWant(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="mip-container__actions">
          <button
            className="mip-container__actions_btn btn-outline"
            type="button"
            onClick={() => {
              handleOnCancelClick();
            }}
          >
            {lang.CANCEL}
          </button>
          <button
            className="mip-container__actions_btn"
            type="button"
            onClick={() => {
              if (hideConnectorInfo) {
                handleOnContinueClick({
                  appsYouWant: "",
                  flowYouWant,
                });
              } else {
                handleOnContinueClick({
                  appsYouWant,
                  flowYouWant,
                });
              }
            }}
          >
            {lang.CONTINUE}
          </button>
        </div>
      </div>
    </div>
  );
};

MissingInfoPortal.propTypes = {
  onContinueClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  onboardingSurvey: PropTypes.any,
  hideConnectorInfo: PropTypes.bool,
};

export default MissingInfoPortal;
