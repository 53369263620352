import React from "react";
import "./styles.scss";

interface IProps {
  children: JSX.Element | JSX.Element[];
  onOverlayClick?: () => void;
}
const ModalContent = (props: IProps): JSX.Element | null => {
  const { children, onOverlayClick } = props;
  return (
    <div className="modal-content">
      <div
        className="modal-overlay"
        onClick={() => {
          if (onOverlayClick) onOverlayClick();
        }}
        aria-hidden="true"
      />
      <div
        className="modal-container"
        onClick={(e) => e.preventDefault()}
        aria-hidden="true"
      >
        {children}
      </div>
    </div>
  );
};
export default ModalContent;
