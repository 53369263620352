import { ReactElement, CSSProperties } from "react";
import IntegryTooltip from "components/integry-design-system/molecules/integry-tooltip/integry-tooltip-v2";
import { uniqueId } from "lodash-es";

import { RunStatus } from "../../interface";

import "./styles.scss";

type StatusIndicatorProps = {
  statusType: RunStatus;
  hideText?: boolean;
  hideIcon?: boolean;
  disableTooltip?: boolean;
  networkCode?: number | string;
  style?: CSSProperties;
};

const statusConfig = {
  COMPLETED: {
    text: "Completed",
    icon: (
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="6" cy="6.5" r="6" fill="#C4E9D2" />
        <path
          d="M8.49951 4.5L5.22003 8.25L3.49951 6.28264"
          stroke="#3BB568"
          strokeLinecap="round"
        />
      </svg>
    ),
  },
  FAILED: {
    text: "Aborted",
    icon: (
      <svg
        width="12"
        height="12"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle data-hint="Auth failed" cx="12" cy="12" r="12" fill="#FDEFEC" />
        <path
          d="M16 8L8 16"
          stroke="#F05C42"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 8L16 16"
          stroke="#F05C42"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  ABORTED: {
    text: "Aborted",
    icon: (
      <svg
        width="12"
        height="12"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle data-hint="Auth failed" cx="12" cy="12" r="12" fill="#FDEFEC" />
        <path
          d="M16 8L8 16"
          stroke="#F05C42"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 8L16 16"
          stroke="#F05C42"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  FILTERED: {
    text: "Filtered",
    icon: (
      <svg
        width="12"
        height="12"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle data-hint="Auth failed" cx="12" cy="12" r="12" fill="#F1F0ED" />
        <path
          d="M16 8L8 16"
          stroke="#999999"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 8L16 16"
          stroke="#999999"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  RUNNING: { text: "Running...", icon: "~" },
  HAS_NOT_RUN: { text: "No runs as yet", icon: "" },
  OK: { text: "OK", icon: "" },
  ERROR: { text: "Error", icon: "" },
};

const RunStatusIndicator = ({
  statusType,
  disableTooltip,
  hideText = false,
  hideIcon = false,
  style = {},
  networkCode,
}: StatusIndicatorProps): ReactElement => {
  const { text, icon } = statusConfig[statusType] || {};
  const id = uniqueId("run-status-indicator-tooltip-");
  return (
    <IntegryTooltip
      tooltipId={id}
      tooltipText={text}
      hideToolTip={!text || !hideText || disableTooltip}
    >
      <div
        className={`status-indicator ${statusType?.toLowerCase()}`}
        style={style}
      >
        {!hideText && <span className="text">{text || statusType}</span>}
        {icon && !hideIcon && (
          <span className={`icon ${disableTooltip ? "" : "cursor-pointer"}`}>
            {icon}
          </span>
        )}
        {networkCode && (
          <>
            <span
              style={{
                color:
                  statusType === "ABORTED"
                    ? "rgb(240, 92, 66)"
                    : "rgb(116, 157, 72)",
              }}
            >
              :
            </span>
            <span
              style={{
                color:
                  statusType === "ABORTED"
                    ? "rgb(240, 92, 66)"
                    : "rgb(116, 157, 72)",
              }}
            >
              {networkCode}
            </span>
          </>
        )}
      </div>
    </IntegryTooltip>
  );
};

export default RunStatusIndicator;
