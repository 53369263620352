import React, { ReactElement } from "react";
import cancelIcon from "images/template-cancel-icon.png";
import TrashIconOrange from "images/trash_active_orange.svg";
import WarningIcon from "images/dialogue-pop-warning-icon.svg";

interface IDialoguePop {
  onCancelClick?: () => void;
  performActionCallback: () => void;
  title: string;
  description: string;
  actionBtnLabel: string;
  cancelBtnLabel: string;
  hideActionBtn: boolean;
  dialogueType?: string;
}

const DeletePop = (props: IDialoguePop): ReactElement => {
  const {
    onCancelClick,
    performActionCallback,
    title,
    description,
    actionBtnLabel,
    cancelBtnLabel,
    hideActionBtn,
    dialogueType = "",
  } = props;
  const handleOnClose = (): void => {
    if (onCancelClick) {
      onCancelClick();
    }
  };
  const handleOnDelete = (): void => {
    if (performActionCallback) {
      performActionCallback();
    }
  };
  return (
    <div
      className="popup"
      onClick={() => {
        handleOnClose();
      }}
      aria-hidden="true"
    >
      <div
        className="popup__container"
        onClick={(e) => {
          e.stopPropagation();
        }}
        aria-hidden="true"
      >
        <div className="popup__content">
          <div className="popup__heading">
            <p>{title}</p>
          </div>
          <div className="popup__text">
            <p>{description}</p>
          </div>
          <div className="popup__actions">
            <div
              className="popup__cta"
              onClick={() => {
                handleOnClose();
              }}
              aria-hidden="true"
            >
              {cancelBtnLabel}
            </div>
            {!hideActionBtn && (
              <div
                className="popup__cta cta__orange"
                onClick={() => {
                  handleOnDelete();
                }}
                aria-hidden="true"
              >
                {actionBtnLabel}
              </div>
            )}
          </div>
        </div>
        <img
          className="popup__icon"
          src={dialogueType === "warning" ? WarningIcon : TrashIconOrange}
          alt="delete_trash_icon"
          style={{
            top: "30px",
            left: "30px",
            right: "auto",
          }}
        />
        <img
          className="popup__icon"
          src={cancelIcon}
          alt="cancel_cross_icon"
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            if (onCancelClick) {
              onCancelClick();
            }
          }}
          aria-hidden="true"
        />
      </div>
    </div>
  );
};

export default DeletePop;
