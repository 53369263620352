/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement } from "react";
import StepPayloadCompnent from "components/integry-design-system/organisms/integration-view/step-payload-component";
import IntegryLoader from "components/integry-loader";
import { Poll } from "../../../interface";
import StepRow from "./row";

interface RenderTableProps {
  config: any[];
  data?: Poll[];
  node?: number;
  expandedStepIdRef?: any;
  expandedPayloadStepId?: string;
  setExpandedPayloadStepId?: any;
  setViewStepPayloadData?: any;
  fetchStepPayloadData?: any;
  viewStepPayloadData?: any;
  expandedStepId?: any;
  fetching: boolean;
}

const RenderTable = (props: RenderTableProps): ReactElement => {
  const {
    config,
    data = [],
    node = 0,
    expandedStepIdRef,
    expandedPayloadStepId,
    setExpandedPayloadStepId,
    setViewStepPayloadData,
    fetchStepPayloadData,
    viewStepPayloadData,
    expandedStepId,
    fetching,
  } = props;

  return (
    <>
      {data.length > 0 && (
        <div
          className="div-body"
          style={
            !fetching
              ? {}
              : {
                  opacity: "0.5",
                }
          }
        >
          {data.map((step, index) => {
            let rowStyle = {};
            if (expandedStepIdRef?.current?.includes(step.id)) {
              rowStyle = {
                ...rowStyle,
                borderRadius: "6px 6px 0px 0px",
              };
            }
            if (node > 0) {
              const indent = {
                left: ((111 - node * 10) / 1209) * 100,
                right: ((203 - node * 10) / 1209) * 100,
                padding: 24 - node * 10,
              };
              rowStyle = {
                ...rowStyle,
                padding: indent.padding < 0 ? "0px" : `0 ${indent.padding}px`,
              };
            }
            if (step.id === expandedPayloadStepId) {
              rowStyle = {
                ...rowStyle,
                borderBottom: "none",
                borderRadius: "6px 6px 0 0px",
              };
            }
            return (
              <div
                className={`row-container${
                  expandedStepId.includes(step.id)
                    ? " row-container__expanded"
                    : ""
                } ${index === 0 ? "first-row" : ""}
                ${index === data.length - 1 ? "last-row" : ""}`}
                key={step.id}
              >
                <StepRow
                  config={config}
                  step={step}
                  expandedPayloadStepId={expandedPayloadStepId}
                  onViewPayloadClick={(stepIdToShowPayload) => {
                    if (expandedPayloadStepId === step.id) {
                      setExpandedPayloadStepId("-1");
                      setViewStepPayloadData({});
                    } else {
                      setViewStepPayloadData({});
                      fetchStepPayloadData(stepIdToShowPayload, step);
                      setExpandedPayloadStepId(stepIdToShowPayload);
                    }
                  }}
                  rowStyle={rowStyle}
                  node={node}
                />
                {step.id === expandedPayloadStepId && (
                  <div className="payload-container">
                    <StepPayloadCompnent
                      payloadData={viewStepPayloadData}
                      activityType="Trigger"
                      isLoading={Object.keys(viewStepPayloadData).length === 0}
                      increaseHeightOnFullScreen
                      hideObjectTab
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
      {fetching && node === 0 && <IntegryLoader />}
    </>
  );
};

export default RenderTable;
