/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { CSSProperties, useState } from "react";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import useDebounce from "hooks/useDebounce";
import { Checkbox } from "antd";

import "./styles.scss";

export interface ICheckboxProps {
  label?: string;
  value: string;
  render?: JSX.Element;
  subRender?: JSX.Element;
  labelToolTip?: string;
  disabled?: boolean;
  labelToolTipPosition?: "top" | "left" | "right" | "bottom";
}

export interface IProps {
  customStyle?: CSSProperties;
  onOptionSelect: (options) => void;
  options: Array<ICheckboxProps>;
  value: Array<string>;
  fadeEffect?: boolean;
  debounceTime?: number;
}

const CheckboxSelection = ({
  onOptionSelect,
  options,
  value,
  fadeEffect = false,
  debounceTime = 1000,
}: IProps): JSX.Element => {
  const [localValue, setLocalValue] = useState<IProps["value"]>(value);

  const debouncedOptionSelect = useDebounce((selectedOptions: string[]) => {
    onOptionSelect(selectedOptions);
  }, debounceTime);

  const handleCheck = (optionValue: string): void => {
    let updatedList = [...localValue];
    // if option is in the list, remove it, else add it
    if (updatedList.includes(optionValue)) {
      updatedList = updatedList.filter((item) => item !== optionValue);
    } else {
      updatedList.push(optionValue);
    }
    setLocalValue(updatedList);
    debouncedOptionSelect(updatedList);
  };

  return (
    <div className="integry-checkbox-selection-container-v2">
      <div className="integry-checkbox-selection">
        {options.map((option) => {
          return (
            <div key={option.value}>
              <div className="integry-control-group" key={option.value}>
                <Checkbox
                  // className="integry-checkbox-selection__input"
                  onChange={(e) => handleCheck(option.value)}
                  checked={localValue.includes(option.value)}
                  disabled={option.disabled}
                />

                <IntegryTooltip
                  tooltipText={option.labelToolTip}
                  placement={option.labelToolTipPosition || "bottom"}
                  hideToolTip={!option.labelToolTip}
                >
                  <label
                    htmlFor={option.value}
                    onClick={() => handleCheck(option.value)}
                    className="cursor-pointer"
                    style={{
                      cursor: "pointer",
                      userSelect: "none",
                      marginBottom: 0,
                    }}
                  >
                    {option.render ? (
                      <> {option.render}</>
                    ) : (
                      <div className="txt-sm-dark-gray">{option.label}</div>
                    )}
                  </label>
                </IntegryTooltip>
              </div>
              {localValue.includes(option.value) && option.subRender ? (
                <div className="checkbox-option--sub-render">
                  {option.subRender}
                </div>
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </div>
      {fadeEffect && <div className="fade-effect" />}
    </div>
  );
};

export default CheckboxSelection;
