/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ReactElement } from "react";
import CheckIconBlue from "images/turbo.v2/blue-tick.svg";
import CheckIcon from "images/turbo.v2/white-tick.svg";

import "./styles.scss";

export interface ISquareCheckboxProps {
  checkboxState: "selected" | "disabled" | "default" | "intermediate";
  lable?: string;
  description?: string;
  isDisabled?: boolean;
  onChange?: (checkboxState) => void;
}

const SquareCheckbox = ({
  checkboxState,
  lable,
  description,
  isDisabled,
  onChange,
}: ISquareCheckboxProps): ReactElement => {
  return (
    <div
      className="square-checkbox-container"
      onClick={() => {
        if (onChange && !isDisabled) onChange(checkboxState);
      }}
    >
      <div
        className={`square-checkbox square-checkbox--${checkboxState} ${
          isDisabled ? "disabled" : ""
        }`}
      >
        {checkboxState === "intermediate" ? (
          <div className="intermediate-state" />
        ) : (
          <>
            {checkboxState !== "default" && (
              <img src={CheckIconBlue} alt="check_icon" />
            )}
          </>
        )}
      </div>
      {lable && <div className="square-checkbox-label">{lable}</div>}
      {description && (
        <div className="square-checkbox-description">{description}</div>
      )}
    </div>
  );
};

export default SquareCheckbox;
