// import TooltipWrapper from "components/common/tooltip-wrapper";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import { ReactElement } from "react";
import Tag, {
  IProps as TagProps,
} from "components/integry-design-system/atoms/tag";

import "./label.scss";

interface LabelProps {
  title: string;
  forControl?: string;
  isRequired?: boolean;
  isUnderlined?: boolean;
  toolTip?: string;
  className?: string;
  subCaption?: string;
  toolTipPlacement?: "top" | "left" | "right" | "bottom";
  tag?: TagProps;
}

const Label = (props: LabelProps): ReactElement => {
  const {
    title,
    forControl,
    isRequired,
    isUnderlined,
    toolTip,
    className,
    subCaption,
    toolTipPlacement,
    tag,
  } = props;
  return (
    <div className="integry-common-label">
      <IntegryTooltip
        hideToolTip={!toolTip}
        tooltipText={toolTip}
        placement={toolTipPlacement || "bottom"}
      >
        <label
          htmlFor={forControl || ""}
          className={`app-form-label  ${className}`}
        >
          <span className={isUnderlined ? "underlined" : "normal"}>
            {title}
          </span>
          {isRequired && <span className="required">*</span>}
        </label>
      </IntegryTooltip>
      {subCaption && <p className="sub-caption">{subCaption}</p>}
      {tag && <Tag {...tag} />}
    </div>
  );
};

Label.defaultProps = {
  isRequired: false,
  isUnderlined: false,
  toolTip: "",
  className: "",
};

export default Label;
