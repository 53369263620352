import { createApi } from "@reduxjs/toolkit/query/react";
import { integryBaseQuery } from "utils/http";

import { RunsUsage } from "../interface/runs-usage";

export const useRunsUsageAPI = createApi({
  reducerPath: "runs-usage",
  baseQuery: integryBaseQuery(),
  tagTypes: ["runs-usage"],
  endpoints(build) {
    return {
      getRunsUsage: build.query<RunsUsage[], void>({
        query: () => ({
          url: `/api/v1/usage/runs/`,
          method: "GET",
        }),
      }),
    };
  },
});

export const { useGetRunsUsageQuery } = useRunsUsageAPI;
