import { CSSProperties, ReactElement } from "react";
import "./styles.scss";

interface IScrollFadeEffectProps {
  position?: "top" | "bottom";
  style?: CSSProperties;
}

const ScrollFadeEffect = (props: IScrollFadeEffectProps): ReactElement => {
  const { position = "bottom", style = {} } = props;
  return (
    <div className={`scroll-fade-effect ${position}--fade`} style={style} />
  );
};

export default ScrollFadeEffect;
