import { convertDateToUTCString } from "utils/date-utils";
import { Run } from "../interface";

// eslint-disable-next-line import/prefer-default-export
export const transfromRuns = (runs: Run[]): Run[] => {
  const transformedRuns = runs.map((result) => {
    const { start_time, status = "N/A", network_code = "N/A" } = result;
    return {
      ...result,
      start_time: convertDateToUTCString(start_time),
      start_time_full: convertDateToUTCString(start_time, true),
      status: status || "N/A",
      networkCode: network_code || "N/A",
    };
  });
  return transformedRuns;
};

export const lastRunLabels = {
  "200": "OK",
  OK: "OK",
  HAS_NOT_RUN: "No runs as yet",
  has_not_run_old: "Hasn't run",
  FAILED: "Aborted",
  ABORTED: "Aborted",
  RUNNING: "Running...",
  "running...": "Running...",
  "N/A": "N/A",
  COMPLETED: "Completed",
  FILTERED: "Filtered",
};
