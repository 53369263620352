import { ReactElement, useState } from "react";
import ModalWithHeader from "components/integry-design-system/atoms/modals/model-with-header";
import SearchBar from "components/common/search-input-field/searchbar-v3";
import Tag from "components/integry-design-system/atoms/tag";
import StepPayloadCompnent, {
  sectionNames,
} from "components/integry-design-system/organisms/integration-view/step-payload-component";
import webappConfig from "utils/config";
import useDisableScroll from "hooks/useDisableScroll";
import ListingTable, {
  createColumnHelper,
  ExpandedState,
} from "components/integry-design-system/organisms/ListingTable/ListingTable";
import {
  statusLabels,
  networkCodeColors,
} from "legacy-features/integrations/runs-view/runs-listing";
import { ActivityType } from "enums/activity-types";

import arrowUpIcon from "images/arrow-up-icon.svg";

import {
  useGetPollPagesQuery,
  useGetPollPayloadQuery,
} from "../api/useFunctionCallsApi";
import { FunctionSteps } from "../interface";

import "./FunctionExecutionLog.scss";

interface IProps {
  onClose: () => void;
  show: boolean;
  pollId: string;
  integrationId: string;
}

const PollPages = (props: IProps): ReactElement => {
  const { show, pollId, onClose, integrationId } = props;

  const [expandedPoll, setExpandedPoll] = useState<ExpandedState>({});
  const [queryArguements, setQueryArguements] = useState<{
    search: string;
    page: number;
  }>({
    search: "",
    page: 1,
  });
  const columnHelper = createColumnHelper<FunctionSteps>();

  useDisableScroll(show);

  const {
    data: pollPages,
    isLoading: loadingPollPages,
    isFetching: fetchingPollPages,
  } = useGetPollPagesQuery({
    itnegration_id: integrationId,
    pollId,
    page: queryArguements.page,
    search: queryArguements.search,
  });

  const showMainLoader =
    loadingPollPages || (fetchingPollPages && queryArguements.page === 1);

  const columns = [
    columnHelper.accessor("execution_sequence_number", {
      header: () => (
        <span>
          #
          <img
            width={10}
            height={10}
            style={{
              marginLeft: "5px",
              cursor: "default",
              opacity: "0.7",
            }}
            src={arrowUpIcon}
            alt="arrow-up"
          />
        </span>
      ),
      cell: (info) => info.row.index + 1,
    }),

    columnHelper.accessor("title", {
      header: () => "Name",
      cell: (info) => (
        <div className="flex align-center gap-10px">
          <img
            src={info.row.original.icon_url}
            alt="appicon"
            width={24}
            height={24}
            style={{ borderRadius: "50%" }}
          />
          <div>{info.row.original.title}</div>
        </div>
      ),
    }),

    columnHelper.accessor("start_time", {
      header: () => "Start Time",
    }),

    columnHelper.accessor("duration_string", {
      header: () => "Duration",
    }),

    columnHelper.accessor("status", {
      header: () => "Status",
      cell: (info) => {
        const status = info.getValue();
        const label = status ? statusLabels[status.toLowerCase()] : "";
        return label ? <Tag label={label} type="run" /> : "";
      },
    }),

    columnHelper.accessor("network_code", {
      header: () => "Network Code",
      cell: (info) => (
        <div
          style={{
            color:
              networkCodeColors[
                `${info.getValue()} || "N/A"}`.substring(0, 1)
              ] || "#333333",
          }}
        >
          {info.getValue()}
        </div>
      ),
    }),
  ];

  const ExpandedComponent = ({ row }: { row: FunctionSteps }): ReactElement => {
    const { data: pollPageData, isLoading } = useGetPollPayloadQuery({
      pollId,
      integrationId,
      pageId: row.id,
    });

    const payloadData = {
      parsedUrl: pollPageData?.payload?.parsed_url,
      parsedHeaders: pollPageData?.payload?.parsed_headers || "",
      parsedRequestTemplate:
        pollPageData?.payload?.parsed_request_template || "",
      requestTemplate: pollPageData?.payload?.request_template || "",
      requestHttpVerb:
        pollPageData?.payload?.request_method?.toLocaleUpperCase() || "",
      responseHeaders: pollPageData?.payload?.response_headers || "",
      parsedResponseTemplate: pollPageData?.payload?.parsed_response_template,
      remoteResponse: pollPageData?.payload?.remote_response,
    };

    let hiddenSections: sectionNames[] = [];
    if (
      row.activity_type === ActivityType.Code ||
      row.app_id === webappConfig.storage.appId ||
      row.activity_type === ActivityType.Trigger ||
      row.activity_type === ActivityType.Loop
    ) {
      hiddenSections = [
        sectionNames.REQUEST_URL,
        sectionNames.REQUEST_HEADERS,
        sectionNames.RESPONSE_HEADERS,
      ];
    }

    let requestTabTitle = "Request";
    if (row.activity_type === "CODE") {
      requestTabTitle = "Code";
    } else if (row.app_id === webappConfig.storage.appId) {
      requestTabTitle = "Input";
    } else if (row.activity_type === ActivityType.Trigger) {
      requestTabTitle = "Parameters";
    }

    return (
      <div className="payload-container">
        <StepPayloadCompnent
          payloadData={payloadData}
          isLoading={isLoading}
          increaseHeightOnFullScreen
          activityType={row.activity_type}
          //  showConditionTab={!!viewStepPayloadData?.stepCondition}
          hideObjectTab={
            row.activity_type === ActivityType.Code ||
            row.app_id === webappConfig.storage.appId ||
            row.activity_type === ActivityType.Trigger ||
            row.activity_id === webappConfig.abortStep.activityId ||
            row.activity_type === ActivityType.Loop
          }
          hideRequestTab={
            row.activity_id === webappConfig.abortStep.activityId ||
            row.activity_type === ActivityType.Loop
          }
          hideResponseTab={row.activity_type === ActivityType.Trigger}
          hiddenSection={hiddenSections}
          tabConfig={{
            request: {
              title: requestTabTitle,
            },
            response: {
              title:
                row.activity_type === ActivityType.Code ||
                row.app_id === webappConfig.storage.appId ||
                row.activity_id === webappConfig.abortStep.activityId ||
                row.activity_type === ActivityType.Loop
                  ? "Output"
                  : "Response",
            },
          }}
        />
      </div>
    );
  };

  return (
    <ModalWithHeader
      title={`Execution Log — Call ID: ${pollId}`}
      close={onClose}
      show={show}
      modalClassName="poll-pages-modal"
    >
      <div className="content integry-scrollbar-v2">
        <div className="flex gap-10px">
          <SearchBar
            searchQueryProp={queryArguements.search}
            onSearch={(value) => {
              setQueryArguements((prev) => ({
                ...prev,
                search: value,
                page: 1,
              }));
            }}
            placeholder="Search for data in payload..."
          />
        </div>

        <ListingTable<FunctionSteps>
          showHoverOnRow
          hasMore={loadingPollPages ? false : !!pollPages?.has_more}
          data={pollPages?.results || []}
          columns={columns}
          isLoading={showMainLoader}
          fetchMore={() => {
            setQueryArguements((prev) => ({
              ...prev,
              page: prev.page + 1,
            }));
          }}
          onRowClick={(row) => {
            setExpandedPoll((prev) => ({
              [row.id]: !prev[row.id],
            }));
          }}
          expandedState={expandedPoll}
          getRowCanExpand={() => true}
          getRowId={(row) => row.id}
          ExpandedRowComponent={ExpandedComponent}
        />
      </div>
    </ModalWithHeader>
  );
};

export default PollPages;
