import {
  REQUEST_TEMPLATES,
  RECEIVE_TEMPLATES,
  REMOVE_TEMPLATE,
  SET_TEMPLATE_PUBLISHING_STATUS_START,
  SET_TEMPLATE_PUBLISHING_STATUS_SUCCESS,
  SET_TEMPLATE_PUBLISHING_STATUS_FAILURE,
  ADD_NEW_TEMPLATE,
  RENAME_TEMPLATE_START,
  RENAME_TEMPLATE_SUCCESS,
  RENAME_TEMPLATE_FAILURE,
} from "../actions/templates";

export const templatesByApp = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_TEMPLATES:
    case RECEIVE_TEMPLATES:
    case REMOVE_TEMPLATE:
    case ADD_NEW_TEMPLATE:
    case SET_TEMPLATE_PUBLISHING_STATUS_START:
    case SET_TEMPLATE_PUBLISHING_STATUS_SUCCESS:
    case SET_TEMPLATE_PUBLISHING_STATUS_FAILURE:
    case RENAME_TEMPLATE_START:
    case RENAME_TEMPLATE_SUCCESS:
    case RENAME_TEMPLATE_FAILURE:
      return Object.assign({}, state, {
        [action.appId]: templates(state[action.appId], action),
      });
    default:
      return state;
  }
};

const templates = (
  state = {
    isFetching: false,
    fetchCount: 0,
    items: [],
  },
  action
) => {
  switch (action.type) {
    case REQUEST_TEMPLATES:
      return Object.assign({}, state, {
        isFetching: true,
        fetchCount: state.fetchCount + 1,
      });
    case RECEIVE_TEMPLATES:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.templates,
      });
    case SET_TEMPLATE_PUBLISHING_STATUS_START:
      return Object.assign({}, state, {
        items: state.items.map((item) => {
          return item.id === action.templateId
            ? Object.assign({}, item, {
                processing: true,
              })
            : item;
        }),
      });
    case SET_TEMPLATE_PUBLISHING_STATUS_SUCCESS:
      return Object.assign({}, state, {
        items: state.items.map((item) => {
          return item.id === action.templateId
            ? Object.assign({}, item, {
                processing: false,
                publishing_status: action.status === 1 ? "PUBLISHED" : "DRAFT",
              })
            : item;
        }),
      });
    case SET_TEMPLATE_PUBLISHING_STATUS_FAILURE:
      return Object.assign({}, state, {
        items: state.items.map((item) => {
          return item.id === action.templateId
            ? Object.assign({}, item, {
                processing: false,
              })
            : item;
        }),
      });
    case REMOVE_TEMPLATE:
      return Object.assign({}, state, {
        items: state.items.filter((item) => item.id !== action.templateId),
      });

    case ADD_NEW_TEMPLATE:
      return Object.assign({}, state, {
        items: [...state.items, action.template],
      });

    case RENAME_TEMPLATE_START:
      return Object.assign({}, state, {
        items: state.items.map((item) => {
          return item.id === action.templateId
            ? Object.assign({}, item, {
                processing: true,
              })
            : item;
        }),
      });
    case RENAME_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        items: state.items.map((item) => {
          return item.id === action.templateId
            ? Object.assign({}, item, {
                processing: false,
                name: action.name,
              })
            : item;
        }),
      });
    case RENAME_TEMPLATE_FAILURE:
      return Object.assign({}, state, {
        items: state.items.map((item) => {
          return item.id === action.templateId
            ? Object.assign({}, item, {
                processing: false,
              })
            : item;
        }),
      });
    default:
      return state;
  }
};
