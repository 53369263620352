import http from "utils/http";
import { AxiosPromise } from "axios";

export const sendInvite = (emails: string): AxiosPromise => {
  return http.post("/api/invites/", { emails });
};
export const listInvites = (): AxiosPromise => {
  return http.get("/api/invites/");
};
export const deleteInvite = (invite_id: number | string): AxiosPromise => {
  return http.delete(`/api/invites/${invite_id}/`);
};
