/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { ReactElement, useState, useEffect } from "react";
import SearchBar from "components/integry-design-system/molecules/search-bar";
import DashedButton from "components/integry-design-system/atoms/dashed-button";
import ScrollFadeEffect from "components/integry-design-system/atoms/scroll-fade-effect";
import addIcon from "images/plus+dark.svg";
import "./styles.scss";
import { encodeJSON, sortAtoZByField } from "utils/functions";

import {
  fetchSystemGeneratedObjects,
  setSystemGeneratedObjectsToLS,
  getSystemGeneratedObjectsFromLS,
} from "./api";

export interface IObject {
  name: string;
  fields: any;
}

const ObjectCard = ({ id, object, onObjectSelect }): ReactElement => {
  // show object title in card
  return (
    <div
      key={`${id}-${object.name}`}
      className="object-card"
      onClick={onObjectSelect}
      role="button"
    >
      <div className="object-card__title">{object.name}</div>
    </div>
  );
};

const ObjectList = ({ onObjectSelect, onCreatePayload }): ReactElement => {
  // filter objects based on search and save in filteredObjects state
  const [filteredObjects, setFilteredObjects] = useState<IObject[]>([]);
  const [fetchingObjects, setFetchingObjects] = useState(true);
  const [systemObjects, setSystemObjects] = useState<IObject[]>([]);
  const [scrollConfig, setScrollConfig] = useState({
    scrollTop: 0,
    scrollHeight: 0,
    clientHeight: 0,
  });

  useEffect(() => {
    const objectsFromLS = getSystemGeneratedObjectsFromLS();
    if (objectsFromLS.length > 0) {
      setSystemObjects(objectsFromLS);
      setFilteredObjects(objectsFromLS);
    }
    getObjects();
    const objectCardList = document.getElementById("object-card-list");
    if (objectCardList) {
      objectCardList.addEventListener("scroll", (e) => {
        handleScroll(e);
      });
    }
    return () => {
      if (objectCardList) {
        objectCardList.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleScroll = (e): void => {
    const { scrollTop, scrollHeight, clientHeight } = e.srcElement;
    setScrollConfig({
      scrollTop,
      scrollHeight,
      clientHeight,
    });
    // if (scrollTop + clientHeight >= scrollHeight) {
    //   console.log("Reached bottom");
    // } else {
    //   console.log("Not reached bottom");
    // }
  };

  const getObjects = (): void => {
    setFetchingObjects(true);
    fetchSystemGeneratedObjects({
      pageSize: "30",
    })
      .then((response: any) => {
        const { data = {} } = response;
        const { results = [] } = data;
        const tempObjects = results.map((obj) => {
          return {
            id: obj.name.toLowerCase(),
            name: obj.name,
            fields: obj.data,
          };
        });
        setSystemGeneratedObjectsToLS(tempObjects);
        setSystemObjects(tempObjects);
        setFilteredObjects(tempObjects);
      })
      .catch((error) => {
        console.error("ERROR while fetching object:", {
          error,
        });
      })
      .finally(() => {
        setFetchingObjects(false);
      });
  };

  const handleSearch = (searchValue: string): void => {
    const tempObjects = systemObjects.filter((object: IObject) =>
      object.name.toLowerCase().includes(searchValue.trim().toLowerCase())
    );
    setFilteredObjects(tempObjects);
  };
  return (
    <div className="object-cards-container">
      <div className="search-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          Select an object
        </div>
        <div>
          <SearchBar onChange={handleSearch} placeholder="Search for object" />
        </div>
      </div>
      <div
        id="object-card-list"
        className="object-card-list integry-scrollbar-v2 scroll-shadow"
      >
        {scrollConfig.scrollTop > 10 && (
          <ScrollFadeEffect
            position="top"
            style={{
              top: "84px",
            }}
          />
        )}
        <DashedButton onClick={onCreatePayload}>
          <div className="create-payload-btn">
            <img src={addIcon} alt="Add" className="add-icon" />
            <div>Custom object</div>
          </div>
        </DashedButton>
        {sortAtoZByField({ data: filteredObjects, field: "name" }).map(
          (object: IObject, index) => (
            <ObjectCard
              id={index}
              object={object}
              onObjectSelect={() =>
                onObjectSelect({
                  name: object.name,
                  fields: encodeJSON(object.fields),
                })
              }
            />
          )
        )}
      </div>
    </div>
  );
};

export default ObjectList;
