import { ReactElement, useRef } from "react";
import { Formik, FormikProps, Form, FieldArray } from "formik";
import Button from "components/integry-design-system/molecules/button";
import linkIcon from "images/open-link.svg";

import AppUserVariableRow from "./workspace-variable-row";
import { useFetchAppUserVariableQuery } from "../../api/useUserVariables";
import { AppUserVariable } from "../../interface";

const AppUserVariables = (): ReactElement => {
  const { data: paginatedAppUserVariables, isLoading } =
    useFetchAppUserVariableQuery({
      includeEmptyRow: true,
    });

  const formikRef = useRef<
    FormikProps<{
      variables: AppUserVariable[];
    }>
  >();

  return (
    <>
      {!isLoading && paginatedAppUserVariables?.results ? (
        <Formik
          initialValues={{
            variables: paginatedAppUserVariables.results || [],
          }}
          onSubmit={() => {
            // do nothing
          }}
        >
          {(formik) => {
            formikRef.current = formik;
            const { values } = formik;

            return (
              <Form>
                <div className="variables app-user">
                  <div className="account-settings-form">
                    <div className="flex justify-space-between align-center">
                      <h3 className="main-title">User variables</h3>
                      <Button
                        variation="link"
                        icon={linkIcon}
                        size="auto"
                        renderIconRight
                        typographVariation="2"
                        action={() => {
                          window.open(
                            "https://docs.integry.ai/hc/en-us/articles/25167805503769-User-variables",
                            "_blank"
                          );
                        }}
                      >
                        Learn more
                      </Button>
                    </div>
                    <div>
                      <div className="tag-row heading-row">
                        <div className="tag-col-head">Key</div>
                        <div className="tag-col-4 description" />
                      </div>

                      <FieldArray
                        name="variables"
                        render={() => (
                          <>
                            {values.variables &&
                              values.variables.length > 0 &&
                              values.variables.map((variable, index) => (
                                <AppUserVariableRow
                                  appUserVariable={variable}
                                  fieldName={`variables.${index}`}
                                  key={variable.id}
                                  isLast={index === values.variables.length - 1}
                                />
                              ))}
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : null}
    </>
  );
};

export default AppUserVariables;
