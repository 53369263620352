/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  CSSProperties,
  HTMLAttributes,
  useEffect,
  useState,
  useRef,
} from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { FeatureFlag } from "react-launch-darkly";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import Breadcrumb, {
  ICrumb,
} from "components/integry-design-system/molecules/breadcrumb";

import PreviewSetup from "legacy-features/integrations/preview-setup";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import { IPill } from "components/integry-design-system/molecules/filter-pill";
import SearchBarV3 from "components/common/search-input-field/searchbar-v3";
import MeatballsMenu from "components/integry-design-system/molecules/meatballs-menu";
import CheckboxDropdown from "components/integry-design-system/molecules/dropdowns/checkbox-dropdown";

import http from "utils/http";
import lang from "utils/lang/en";
import { track, errorNotificationEvents } from "utils/mixpanel";
import { getMyAppById } from "selectors/apps";
import { useInView } from "react-intersection-observer";

import exlaimationIconOutline from "images/exclaimation_icon_outline.svg";
import NotFoundIcon from "images/not_found_icon_lg.svg";
import SortIconDefault from "images/sort.svg";
import SortIconDesc from "images/sort-applied.svg";
import arrowUpIcon from "images/arrow-up-icon.svg";
import CopyIconActive from "images/copy_icon_active.svg";
import TruncateBullets from "images/truncate_bullets.svg";
import EyeIcon from "images/eye_primary.svg";
import NoRunsFoundIcon from "images/no-runs-icon.svg";

import { IIntegration } from "legacy-features/integrations/integrations-view/integration-listing";
import "legacy-features/integrations/styles.scss";
import useOnClickOutside from "utils/use-onclick-outside";
import IntegryDialoguePop from "components/integry-design-system/molecules/integry-dialogue-pop";
import ErrorNotificationSetup from "components/integry-design-system/organisms/integration-view/error-notification-setup";
import EmptyPageMessage from "components/integry-design-system/molecules/empty-page-content";
import Tabs from "components/integry-design-system/atoms/tabs/tabs";
import PollListing, { PollListingV2 } from "features/integrataion-polls";

import { useGetNotificationSettingsQuery } from "utils/accounts-api";
import { ICheckboxProps } from "components/integry-design-system/molecules/checkbox-selection-v2";
import { useFetchUserQuery } from "utils/user/user-api";
import { useFeatureFlags } from "context/featureFlagsContext";

import DateRangePickerV2, {
  DateRangeValue,
} from "components/integry-design-system/molecules/date-range-picker/date-range-v2";

import OverflowTooltip from "components/common/overflow-tooltip";

import urls from "utils/router-urls";
import { useDebounce } from "utils/use-debounce";

import UserRunsPlaceholder from "../runs-placeholder";
import { Run, Template } from "../../interface";
import RunStatusIndicator from "../run-status";

import "./styles.scss";

export interface IFetchOptions {
  search?: string | undefined | null;
  filtersQuery?: Array<IPill> | undefined | null;
  pageConfig?: {
    page: number;
    pageSize: number;
    nextPageToken?: any;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortOrder?: any;
  searchSteps?: boolean;
}
export interface IRun {
  runId: string;
  triggerApp: {
    iconSrc: string;
    name: string;
    triggerType?: string;
  };
  triggerName: string;
  triggerType: string;
  startTime: string;
  duration: string;
  status: string;
  networkCode: string;
  gcpLog: string;
  hasSteps: boolean;
  networkCodeDescription?: string;
}

export interface IFlow {
  flowName: string;
  flowId: string;
  flowLogo: string;
  flowStatus: string;
  flowColor: string;
  isDirectAction: boolean;
}

export interface IProps extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties;
  isFlow?: boolean;
  flowId: string;
  integrationIdParam?: string;
  user?: any;
  app?: any;
  showBillableRunStatus?: boolean;
  tableConfig: any;
  setFetching: (arg: boolean) => void;
  loading: boolean;
  fetching: boolean;
  totalCount: number;
  showPaginationLoader: boolean;
  setShowPaginationLoader: any;
  searchTxt: string;
  setSearchTxt: (arg: string) => void;
  filters: any;
  setFilters: (arg: any) => void;
  sorting: Record<string, string>;
  setSorting: (arg: Record<string, string>) => void;
  allowStepSearch: boolean;
  setAllowStepSearch: (arg: boolean) => void;
  page: any;
  setPage: (arg: any) => void;
  runs: Run[];
  hasMore: boolean;
  nextToken: string | null;
  template: Template | undefined | null;
  integrationConfig: IIntegration | undefined | null;
  runsConfigs: any;
  defaultTab: "runs" | "polls";
  isPollBasedIntegration: boolean;

  generateBreadcrumbConfig: () => Array<ICrumb>;
  renderHeadingTitle: (integrationId: string) => JSX.Element;
  renderGroupedInfoHeader: () => JSX.Element;
  onRowSelect: (run: Run) => void;
  selectedRunId?: string;
  runStatuses: string[];
  runStartTimeRange: DateRangeValue | null;
  hasRuns: boolean;
  setRunStartTimeRange: (arg: DateRangeValue | null) => void;
  setRunStatuses: (arg: string[]) => void;
  onRetryRun: (runId: string) => void;
  networkCode: string;
  setNetworkCode: (value: string) => void;
  completedStatusNetworkCode: string;
  setCompletedStatusNetworkCode: (value: string) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
/* const useQuery = (): any => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}; */

const IntegrationsView = ({
  customStyle = {},
  ...props
}: IProps): JSX.Element => {
  const {
    flowId = "-1",
    integrationIdParam = "-1",
    app,
    showBillableRunStatus = false,
    tableConfig,
    setFetching,
    loading,
    fetching,
    totalCount,
    template,
    integrationConfig,
    runsConfigs,
    generateBreadcrumbConfig,
    renderHeadingTitle,
    renderGroupedInfoHeader,
    showPaginationLoader,
    runs,
    searchTxt,
    setSearchTxt,
    filters,
    sorting,
    allowStepSearch,
    page,
    setPage,
    onRowSelect,
    selectedRunId = "",
    hasMore,
    runStatuses,
    runStartTimeRange,
    setRunStartTimeRange,
    setRunStatuses,
    onRetryRun,
    hasRuns,
    networkCode,
    isPollBasedIntegration,
    setNetworkCode,
    completedStatusNetworkCode,
    setCompletedStatusNetworkCode,
  } = props;
  const history = useHistory();

  const [showFilters, setShowFilters] = useState(false);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [showRetryRun, setShowRetryRun] = useState("-1");
  const [showPreviewSetup, setShowPreviewSetup] = useState(false);

  const sidebarDivRef = useRef<HTMLDivElement>(null);
  const queryStringRef = useRef("");

  const pollsListingFlag = useFeatureFlags({ flag: "polls-listing" });
  const resumeRunFlag = useFeatureFlags({ flag: "resume-aborted-runs" });
  const pollsListingFlagV2 = useFeatureFlags({ flag: "poll-listing-v2" });

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const {
    data: notificationSettings,
    isLoading: isNotificationSettingLoading,
    refetch: refetchNotificationSettings,
  } = useGetNotificationSettingsQuery();

  const { data: user } = useFetchUserQuery();

  useEffect(() => {
    if (inView) {
      setPage({
        page: page.page + 1,
        pageSize: page.pageSize,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  /*   const handleOnSorting = (id): void => {
    let tempSorting = {};
    tempSorting = {
      [id]: sorting[id] === "desc" ? "asc" : "desc",
    };
    setSorting(tempSorting);
    setPage({
      page: 1,
      pageSize: page.pageSize,
      nextPageToken: "",
    });
  }; */

  const handleCopyIntegrationLink = (): void => {
    let stringifiedFilters = ``;
    if ((filters || []).length > 0) {
      try {
        stringifiedFilters = JSON.stringify(filters);
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        stringifiedFilters = "";
        console.error("ERROR converting filters to string", e);
      }
    }
    navigator.clipboard.writeText(window.location.href);
  };

  const handleCopyRunLink = (runId): void => {
    navigator.clipboard.writeText(
      `${window.location.host}/wapp${urls.integrationRuns.replace(
        ":integrationId",
        integrationIdParam
      )}?search=${runId}`
    );
  };

  const handleRetryRunLink = (runId): void => {
    setFetching(true);
    setShowRetryRun("-1");
    http
      .post(
        `api/v1/templates/${flowId}/integrations/${integrationIdParam}/runs/${runId}/retry/`
      )
      .then(() => {
        toast.success(`Your retry request is being processed.`);
        onRetryRun(runId);
      })
      .catch((e) => {
        console.error({ e });
        toast.error(`Failed to retry the run. ${e.message}`);
        setFetching(false);
      })
      .finally(() => {
        setShowRetryRun("-1");
      });
  };

  const handleResumeRunLink = (runId): void => {
    http
      .post(`api/v1/integrations/${integrationIdParam}/runs/${runId}/resume/`)
      .then(() => {
        toast.success(`Your resume request is being processed.`);
      })
      .catch((e) => {
        console.error({ e });
        toast.error(
          `Cannot resume the run. ${e.response.data.message ?? e.message}`
        );
      });
  };

  const renderHeading = (): JSX.Element => {
    document.title = `Runs of Integration - Flows - Integry`;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {renderHeadingTitle(integrationIdParam)}
        <div>
          <FeatureFlag
            flagKey="allow-preview-setup-steps"
            renderDefaultCallback={() => null}
            renderFeatureCallback={() => (
              <Button
                style={{
                  color: "#4250F0",
                  marginRight: "14px",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "15px",
                  textAlign: "right",
                  textTransform: "none",
                }}
                endIcon={<img src={EyeIcon} alt="copy_link" />}
                size="small"
                onClick={() => {
                  setShowPreviewSetup(true);
                }}
              >
                Preview Setup
              </Button>
            )}
          />

          <IntegryTooltip
            tooltipText={isLinkCopied ? "Link Copied" : "Copy Link"}
            placement="bottom"
          >
            <Button
              style={{
                color: "#4250F0",
                marginRight: "-7px",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "15px",
                textAlign: "right",
                textTransform: "none",
              }}
              endIcon={<img src={CopyIconActive} alt="copy_link" />}
              size="small"
              onClick={() => {
                handleCopyIntegrationLink();
                setIsLinkCopied(true);
                setTimeout(() => {
                  setIsLinkCopied(false);
                }, 5000);
              }}
            >
              Copy Link
            </Button>
          </IntegryTooltip>
        </div>
      </div>
    );
  };

  const renderSubHeading = (): JSX.Element => {
    return <h2 className="view-sub-heading">Runs</h2>;
  };

  const renderSearchBar = (
    currenConfigs,
    isDisabled = false
  ): React.ReactElement => {
    return (
      <SearchBarV3
        onSearch={(searchText) => {
          if (!isDisabled) {
            setSearchTxt(searchText);
          }
        }}
        searchQueryProp={searchTxt}
        style={{
          width: "370px",
        }}
        placeholder="Search by ID, trigger name, or data in payload"
        searchCallbackDependency={`allowStepSearch_${allowStepSearch},filters_${JSON.stringify(
          filters
        )},page_${JSON.stringify(page)}`}
      />
    );
  };

  const renderMeatballsMenu = (
    run: Run,
    allowGCPAccess = false,
    retryRun = false
  ): JSX.Element => {
    const isIntegrationDisabled =
      (
        integrationConfig || { integrationStatus: "INACTIVE" }
      ).integrationStatus.toLowerCase() !== "active";

    const canRetryRun = run.can_be_retried;

    const menuItems = [
      {
        label: "Copy Link",
        value: "copy-run-link",
        onItemSelect: () => {
          handleCopyRunLink(run.id);
        },
        disabled: false,
        tooltip: "",
      },
    ];
    if (allowGCPAccess) {
      menuItems.push({
        label: "View GCP Log",
        value: "view_gcp_log",
        onItemSelect: () => {
          if (run.log_link) {
            window.open(run.log_link, "_blank");
          }
        },
        tooltip: "",
        disabled: false,
      });
    }
    if (retryRun) {
      menuItems.unshift({
        label: "Retry",
        value: "retry-run",
        tooltip: isIntegrationDisabled ? lang.DISABLED_RETRY_RUN_COPY : "",
        disabled: isIntegrationDisabled || !canRetryRun,
        onItemSelect: () => {
          setShowRetryRun(run.id);
        },
      });
    }

    if (resumeRunFlag && run.was_executed_after_resume_feature) {
      let resumeOptionTooltip = isIntegrationDisabled
        ? lang.DISABLED_RESUME_RUN_COPY
        : run.not_resumable_reason;
      if (run.is_resumable) {
        resumeOptionTooltip = lang.ENABLED_RESUME_RUN_COPY;
      }
      menuItems.unshift({
        label: "Resume",
        value: "resume-run",
        tooltip: resumeOptionTooltip,
        disabled: isIntegrationDisabled || !run.is_resumable,
        onItemSelect: () => {
          handleResumeRunLink(run.id);
        },
      });
    }

    menuItems.unshift({
      label: "View Execution log",
      value: "view-logs",
      disabled: false,
      tooltip: "",
      onItemSelect: () => {
        onRowSelect(run);
      },
    });
    return <MeatballsMenu items={menuItems} />;
  };

  const showNotificationMessage = (): boolean => {
    if (
      isNotificationSettingLoading === false &&
      notificationSettings?.is_error_notification_enabled === false
    ) {
      return true;
    }
    return false;
  };

  const onClickSetupNotifications = (): void => {
    track(errorNotificationEvents.GO_TO_ERROR_NOTIFICATIONS.name, user, {
      [errorNotificationEvents.GO_TO_ERROR_NOTIFICATIONS.property]: "Runs",
    });
  };

  const onClickYesInNotificationPopUp = (): void => {
    track(
      errorNotificationEvents.CONTINUE_TO_ERROR_NOTIFICATIONS_FROM_DIALOG.name,
      user,
      {
        [errorNotificationEvents.CONTINUE_TO_ERROR_NOTIFICATIONS_FROM_DIALOG
          .property]: "Runs",
      }
    );
  };

  const onDismissNotificationSnackbar = (): void => {
    track(
      errorNotificationEvents.DISMISS_ERROR_NOTIFICATIONS_SNACKBAR.name,
      user,
      {
        [errorNotificationEvents.DISMISS_ERROR_NOTIFICATIONS_SNACKBAR.property]:
          "Runs",
      }
    );
  };

  const renderTable = (): JSX.Element => {
    return (
      <>
        <table
          className={`integry-table ${
            fetching && !showPaginationLoader ? "disabled" : ""
          }`}
        >
          <thead>
            <tr>
              {tableConfig.config.map((configItem) => {
                if (configItem.hide) {
                  return null;
                }
                if (configItem.tooltip && configItem.allowSorting) {
                  return (
                    <th>
                      {configItem.label}
                      <IntegryTooltip
                        tooltipText={
                          configItem.tooltip_nonBillable
                            ? showBillableRunStatus
                              ? configItem.tooltip
                              : configItem.tooltip_nonBillable
                            : configItem.tooltip
                        }
                        placement="top"
                      >
                        <img
                          width={15}
                          height={15}
                          src={exlaimationIconOutline}
                          style={{
                            marginLeft: "5px",
                            marginTop: "-1px",
                            transform: "rotateZ(180deg)",
                          }}
                          alt="tooltip_heading"
                        />
                      </IntegryTooltip>
                      <img
                        width={15}
                        height={15}
                        style={{
                          marginLeft: "5px",
                          marginTop: "-2px",
                          cursor: "default",
                        }}
                        src={
                          sorting[configItem.sortingIdentifier || configItem.id]
                            ? SortIconDesc
                            : SortIconDefault
                        }
                        className={
                          sorting[
                            configItem.sortingIdentifier || configItem.id
                          ] &&
                          sorting[
                            configItem.sortingIdentifier || configItem.id
                          ] === "asc"
                            ? "table-sort-icon-asc"
                            : ""
                        }
                        alt="sort_icon"
                        /*  onClick={() => {
                          handleOnSorting(
                            configItem.sortingIdentifier || configItem.id
                          );
                        }} */
                        aria-hidden="true"
                      />
                    </th>
                  );
                }
                if (configItem.tooltip) {
                  return (
                    <th>
                      {configItem.label}
                      <IntegryTooltip
                        tooltipText={
                          configItem.tooltip_nonBillable
                            ? showBillableRunStatus
                              ? configItem.tooltip
                              : configItem.tooltip_nonBillable
                            : configItem.tooltip
                        }
                        placement="top"
                      >
                        <img
                          width={15}
                          height={15}
                          src={exlaimationIconOutline}
                          style={{
                            marginLeft: "5px",
                            marginTop: "-1px",
                            transform: "rotateZ(180deg)",
                          }}
                          alt="tooltip_heading"
                        />
                      </IntegryTooltip>
                    </th>
                  );
                }
                if (configItem.allowSorting) {
                  return (
                    <th>
                      {configItem.label}
                      <img
                        width={10}
                        height={10}
                        style={{
                          marginLeft: "5px",
                          marginTop: "-2px",
                          cursor: "default",
                          transform: "rotateZ(180deg)",
                          opacity: "0.7",
                        }}
                        src={arrowUpIcon}
                        className={
                          sorting[
                            configItem.sortingIdentifier || configItem.id
                          ] &&
                          sorting[
                            configItem.sortingIdentifier || configItem.id
                          ] === "asc"
                            ? "table-sort-icon-asc"
                            : ""
                        }
                        alt="sort_icon"
                        onClick={() => {
                          /* handleOnSorting(
                            configItem.sortingIdentifier || configItem.id
                          ); */
                        }}
                        aria-hidden="true"
                      />
                    </th>
                  );
                }
                return <th>{configItem.label}</th>;
              })}
              <th style={{ overflow: "visible" }} />
            </tr>
          </thead>
          {runs.length > 0 && (
            <tbody
              style={
                fetching && !showPaginationLoader ? { opacity: "0.5" } : {}
              }
            >
              {runs.map((run: Run, index) => {
                return (
                  <tr
                    className={`cursor-pointer ${
                      selectedRunId === run.id ? "highlight-row" : ""
                    } highlight-row-on-hover`}
                    onClick={() => onRowSelect(run)}
                  >
                    {tableConfig.config.map((configItem) => {
                      const {
                        id,
                        subConfig,
                        type = "text",
                        tdStyle = {},
                        itemStyle = {},
                        showTooltip = false,
                        tooltipField = "",
                        hideSameValueTooltip = false,
                        subColumnId,
                        subColumnType,
                      } = configItem;
                      if (configItem.hide) {
                        return null;
                      }
                      if (type === "code") {
                        const codeLabel =
                          statusLabels[run[id]] ||
                          networkCodeLabels[run[id]] ||
                          run[id];
                        const codeColor =
                          networkCodeColors[
                            `${run[id] || "N/A"}`.substring(0, 1)
                          ] || "#333333";
                        return (
                          <td>
                            <div className="integry-table--column">
                              <IntegryTooltip
                                hideToolTip={
                                  !showTooltip ||
                                  (!networkCodeDescriptionLabels[run[id]] &&
                                    run[tooltipField] === "N/A")
                                }
                                tooltipText={
                                  networkCodeDescriptionLabels[run[id]] ||
                                  run[tooltipField] ||
                                  ""
                                }
                                placement="bottom"
                              >
                                <span
                                  style={{
                                    color: codeColor,
                                  }}
                                >
                                  {codeLabel}
                                </span>
                              </IntegryTooltip>
                            </div>
                          </td>
                        );
                      }
                      if (type === "tag") {
                        return (
                          <td>
                            <div
                              className="integry-table--column gap-5px"
                              style={tdStyle}
                            >
                              {/* <Tag label={tagLabel} type="run" /> */}
                              <div className="flex">
                                <RunStatusIndicator
                                  statusType={run[id]}
                                  hideIcon={
                                    run[id] === "ABORTED" ||
                                    run[id] === "COMPLETED"
                                  }
                                />
                                {subColumnId &&
                                  subColumnType === "code" &&
                                  (run[id] === "ABORTED" ||
                                    run[id] === "COMPLETED") &&
                                  run[subColumnId] !== "0" &&
                                  run[subColumnId] && (
                                    <span
                                      style={{
                                        color:
                                          run[id] === "ABORTED"
                                            ? "rgb(240, 92, 66)"
                                            : "rgb(116, 157, 72)",
                                      }}
                                    >
                                      :
                                    </span>
                                  )}
                              </div>

                              {subColumnId &&
                                subColumnType === "code" &&
                                (run[id] === "ABORTED" ||
                                  run[id] === "COMPLETED") &&
                                run[subColumnId] !== "0" && (
                                  <>
                                    <IntegryTooltip
                                      hideToolTip={
                                        !showTooltip ||
                                        (!networkCodeDescriptionLabels[
                                          run[subColumnId]
                                        ] &&
                                          run[tooltipField] === "N/A")
                                      }
                                      tooltipText={
                                        networkCodeDescriptionLabels[
                                          run[subColumnId]
                                        ] ||
                                        run[tooltipField] ||
                                        ""
                                      }
                                      placement="bottom"
                                    >
                                      <span
                                        style={{
                                          color:
                                            run[id] === "ABORTED"
                                              ? "rgb(240, 92, 66)"
                                              : "rgb(116, 157, 72)",
                                        }}
                                      >
                                        {statusLabels[run[subColumnId]] ||
                                          networkCodeLabels[run[subColumnId]] ||
                                          run[subColumnId]}
                                      </span>
                                    </IntegryTooltip>
                                  </>
                                )}
                            </div>
                          </td>
                        );
                      }
                      if (configItem.truncate) {
                        return (
                          <td>
                            <div
                              className="integry-table--column"
                              style={tdStyle}
                            >
                              {run[id].length > 20 ? (
                                <IntegryTooltip
                                  tooltipText={run[id]}
                                  placement="bottom"
                                >
                                  <div>
                                    {run[id].substring(0, 3)}
                                    <img
                                      src={TruncateBullets}
                                      style={{
                                        width: "15px",
                                        margin: "0 2px",
                                        marginTop: "-3px",
                                        objectFit: "none",
                                      }}
                                      alt="truncate_bullets"
                                    />
                                    {run[id].substring(run[id].length - 3)}
                                  </div>
                                </IntegryTooltip>
                              ) : (
                                `${run[id]}`
                              )}
                            </div>
                          </td>
                        );
                      }
                      return (
                        <td
                          style={{
                            minWidth: "100px",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          <div
                            className="integry-table--column"
                            style={tdStyle}
                          >
                            <IntegryTooltip
                              hideToolTip={
                                !showTooltip ||
                                run[tooltipField] === "N/A" ||
                                (hideSameValueTooltip &&
                                  run[id] === run[tooltipField])
                              }
                              tooltipText={run[tooltipField] || ""}
                              placement="bottom"
                            >
                              <div style={itemStyle}>
                                {!subConfig &&
                                  (statusLabels[run[id]] ||
                                    networkCodeLabels[run[id]] || [run[id]])}
                                {subConfig &&
                                  subConfig.map((subItem) => {
                                    if (subItem.type === "img") {
                                      return (
                                        <IntegryTooltip
                                          placement="bottom"
                                          tooltipText={run?.trigger_app?.name}
                                        >
                                          <img
                                            src={run[id][subItem.id]}
                                            alt={`ico_${run[id][subItem.id]}`}
                                          />
                                        </IntegryTooltip>
                                      );
                                    }
                                    return (
                                      <OverflowTooltip
                                        text={run[id][subItem.id]}
                                      />
                                    );
                                  })}
                              </div>
                            </IntegryTooltip>
                          </div>
                        </td>
                      );
                    })}
                    <td>
                      <FeatureFlag
                        flagKey="allow-gcp-log-access"
                        renderFeatureCallback={() => (
                          <FeatureFlag
                            flagKey="iu-retry-run"
                            renderFeatureCallback={() => {
                              return renderMeatballsMenu(run, true, true);
                            }}
                            renderDefaultCallback={() => {
                              return renderMeatballsMenu(run, true);
                            }}
                          />
                        )}
                        renderDefaultCallback={() => (
                          <FeatureFlag
                            flagKey="iu-retry-run"
                            renderFeatureCallback={() => {
                              return renderMeatballsMenu(run, false, true);
                            }}
                            renderDefaultCallback={() => {
                              return renderMeatballsMenu(run);
                            }}
                          />
                        )}
                      />
                      {showRetryRun === run.id && (
                        <IntegryDialoguePop
                          dialogueType="warning"
                          title="Retry Run"
                          description="Are you sure you want to retry this run? This will generate a new run with the payload of this run, and it may impact the user's data in the target app(s)."
                          cancelBtnLabel="Cancel"
                          actionBtnLabel="Yes"
                          onCancelClick={() => {
                            setShowRetryRun("-1");
                          }}
                          performActionCallback={() => {
                            handleRetryRunLink(run.id);
                          }}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        {!fetching && runsConfigs.length < 1 && (
          <div className="view-record-not-found__placeholder">
            {!integrationConfig?.hasRuns ? (
              <img src={NoRunsFoundIcon} alt="record_not_found" />
            ) : (
              <img src={NotFoundIcon} alt="record_not_found" />
            )}
            <FeatureFlag
              flagKey="allow-preview-setup-steps"
              renderDefaultCallback={() => (
                <p>
                  {!integrationConfig?.hasRuns
                    ? lang.INTEGRATION_HAS_NOT_RUN
                    : lang.INTEGRATIONS_NOT_FOUND_COPY_RUNS}
                </p>
              )}
              renderFeatureCallback={() => (
                <p>
                  {!integrationConfig?.hasRuns ? (
                    <span>
                      {lang.INTEGRATION_HAS_NOT_RUN_1},{" "}
                      <span
                        className="view-record-not-found__placeholder--cta"
                        onClick={() => {
                          setShowPreviewSetup(true);
                        }}
                        aria-hidden="true"
                      >
                        Preview Setup
                      </span>
                      .
                    </span>
                  ) : (
                    lang.INTEGRATIONS_NOT_FOUND_COPY_RUNS
                  )}
                </p>
              )}
            />
          </div>
        )}
        {!fetching && runsConfigs.length > 0 && showNotificationMessage() && (
          <FeatureFlag
            flagKey="setup-error-integration"
            renderFeatureCallback={() => (
              <ErrorNotificationSetup
                userId={user?.id}
                onClickSetupNotifications={onClickSetupNotifications}
                onClickYesInNotificationPopUp={onClickYesInNotificationPopUp}
                onDismissNotificationSnackbar={onDismissNotificationSnackbar}
              />
            )}
            renderDefaultCallback={() => <></>}
          />
        )}

        {hasMore && <div ref={ref} className="loader" />}
      </>
    );
  };

  useEffect(() => {
    refetchNotificationSettings();
    queryStringRef.current = window.location.search;
  }, []);

  const clickOutsideSidebar = (evt): void => {
    if (showFilters) {
      setShowFilters(false);
    }
  };

  useOnClickOutside(sidebarDivRef, clickOutsideSidebar);

  const deboundedSetNetwrokCode = useDebounce(setNetworkCode, 500);
  const deboundedSetCompletedStatusNetworkCode = useDebounce(
    setCompletedStatusNetworkCode,
    500
  );

  const getRunStatusOptions = (): Array<ICheckboxProps> => {
    const lastRunStatusOptions = [] as Array<ICheckboxProps>;
    lastRunStatusOptions.push(
      {
        render: (
          <RunStatusIndicator
            statusType="COMPLETED"
            style={{
              marginTop: "2px",
            }}
          />
        ),
        subRender: (
          <input
            type="text"
            defaultValue={completedStatusNetworkCode}
            className="network-code-filter"
            placeholder="Network Code"
            onChange={(e) => {
              deboundedSetCompletedStatusNetworkCode(e.target.value);
            }}
          />
        ),
        value: "COMPLETED",
        label: "Completed",
      },
      {
        render: (
          <RunStatusIndicator
            statusType="FAILED"
            style={{
              marginTop: "2px",
            }}
          />
        ),
        subRender: (
          <input
            type="text"
            defaultValue={networkCode}
            className="network-code-filter"
            placeholder="Network Code"
            onChange={(e) => {
              deboundedSetNetwrokCode(e.target.value);
            }}
          />
        ),
        value: "FAILED",
        label: "Aborted",
      },
      {
        render: (
          <RunStatusIndicator
            statusType="FILTERED"
            style={{
              marginTop: "2px",
            }}
          />
        ),
        value: "FILTERED",
        label: "Filtered",
      },
      {
        render: (
          <RunStatusIndicator
            statusType="RUNNING"
            style={{
              marginTop: "2px",
            }}
          />
        ),
        value: "RUNNING",
        label: "Running...",
      }
    );
    return lastRunStatusOptions;
  };

  const renderRunsListing = (): JSX.Element => {
    return (
      <>
        <div className="view-filters">
          <div className="flex gap-10px">
            {renderSearchBar({
              filters,
              page,
              allowStepSearch,
            })}
            <DateRangePickerV2
              value={runStartTimeRange}
              onChange={setRunStartTimeRange}
              tooltipText="Filter on Start Time"
              plaseholder={["From", "To"]}
            />
            <CheckboxDropdown
              options={getRunStatusOptions()}
              value={runStatuses}
              onChange={setRunStatuses}
              placeholder="Status"
              tooltipText="Filter by run status"
            />
            {!loading && (
              <div className="txt-sm-liight-gray align-center">{`${
                totalCount?.toLocaleString() || 0
              } ${totalCount === 1 ? "Run" : "Runs"}`}</div>
            )}
          </div>
        </div>
        {loading && !fetching ? ( // when loading is true and pooling is not happening then show loader
          <UserRunsPlaceholder />
        ) : (
          <>
            {hasRuns ? (
              <div className="view-table">{renderTable()}</div>
            ) : (
              <EmptyPageMessage
                content={
                  <>
                    <p>
                      An integration runs when it is triggered by a webhook
                      (from your app, or another app), on a schedule, or when it
                      is initialized by your user. Once triggered, it attempts
                      to execute the steps in that trigger’s block.
                    </p>
                    <p>
                      Monitor the runs in real-time, and troubleshoot issues
                      down to individual steps over here.
                    </p>
                  </>
                }
                docLink="https://docs.integry.io/docs/flows/runs"
              />
            )}
          </>
        )}
      </>
    );
  };

  return (
    <div className="integry-views" style={{ ...customStyle }}>
      {integrationConfig &&
      (integrationConfig.integrationName || integrationConfig.integrationId) ? (
        <div className="view-breadcrumb">
          <Breadcrumb
            crumbs={generateBreadcrumbConfig()}
            onNavClick={(url) => {
              history.push(url);
            }}
          />
        </div>
      ) : (
        <div className="view-breadcrumbs-loading mt-20px" />
      )}
      {template && template.name ? (
        <h2 className="view-heading">{renderHeading()}</h2>
      ) : (
        <div className="view-heading-loading" />
      )}
      {integrationConfig &&
      (integrationConfig.integrationName || integrationConfig.integrationId) ? (
        <div className="view-header-info">
          <>{renderGroupedInfoHeader()}</>
        </div>
      ) : (
        <div className="view-header-info-loading" />
      )}

      {pollsListingFlag && isPollBasedIntegration ? (
        <>
          <Tabs
            defaultTabLabel={props.defaultTab}
            onTabChange={(tab) => {
              if (tab === "Polls") {
                history.push(
                  urls.integrationPolls.replace(
                    ":integrationId",
                    integrationIdParam
                  )
                );
              } else {
                history.push(
                  urls.integrationRuns.replace(
                    ":integrationId",
                    integrationIdParam
                  )
                );
              }
            }}
          >
            <div title="Runs">
              <>{renderRunsListing()}</>
            </div>
            <div title="Polls">
              <>
                {pollsListingFlagV2 ? (
                  <PollListingV2 integrationId={integrationIdParam} />
                ) : (
                  <PollListing integrationId={integrationIdParam} />
                )}
              </>
            </div>
          </Tabs>
        </>
      ) : (
        <>
          <>{renderSubHeading()}</>
          <>{renderRunsListing()}</>
        </>
      )}

      {integrationConfig?.integrationId && showPreviewSetup && (
        <PreviewSetup
          flow={{
            flowId: template?.id,
          }}
          integration={integrationConfig}
          user={user}
          app={app}
          onClose={() => {
            setShowPreviewSetup(false);
          }}
        />
      )}
    </div>
  );
};

// export default IntegrationsView;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect((state: any) => {
  return {
    app: getMyAppById(state.myApps, state.globallySelectedApp),
  };
})(IntegrationsView);

export const statusLabels = {
  all: "All",
  ok: "OK",
  failed: "Aborted",
  Failed: "Aborted",
  ABORTED: "Aborted",
  running: "Running",
  completed: "Completed",
  filtered: "Filtered",
  FILTERED: "Filtered",
  COMPLETED: "Completed",
};

export const networkCodeLabels = {
  all: "All",
  n_a: "N/A",
  Loop: "200",
  loop: "200",
};

export const networkCodeDescriptionLabels = {
  Loop: "OK",
  loop: "OK",
};

export const networkCodeColors = {
  "2": "#749D48",
  "3": "#FFFAE2",
  "4": "#F05C42",
  "5": "#F05C42",
  N: "#666666",
  n: "#666666",
  l: "#749D48",
  L: "#749D48",
};
