/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import startsWith from "lodash/startsWith";
import { IIntegration } from "legacy-features/integrations/integrations-view/integration-listing";
import { IRun } from "legacy-features/integrations/runs-view/runs-listing";
import { convertDateToLocal } from "utils/functions";
import { convertDateToUTCString } from "utils/date-utils";
import { getIntegrationTooltip } from "features/end-users/helper-utils";
import redCrossIcon from "images/red-cross-icon.svg";

export const templateErrorMessage = (
  title: string,
  msg: string
): ReactElement => {
  return (
    <div>
      <div className="lable error">{title}</div>
      <div className="message">{msg}</div>
    </div>
  );
};

const customCloseButton = (): ReactElement => {
  return <img className="custom-close-toast" src={redCrossIcon} alt="Close" />;
};

export const renderApiError = (title: string, msg: string): void => {
  toast.error(templateErrorMessage(title, msg), {
    autoClose: 5000,
    position: "bottom-left",
    closeButton: customCloseButton(),
  });
};

export const getIntegrationStatus = (
  integrationStatus: string,
  errorCode: number
): string => {
  return integrationStatus; // BE will send the correct status
  /* if (errorCode === 401 || errorCode === 403) { */
  /*   return "AUTH_MISSING"; */
  /* } */
  /* if (errorCode >= 400 && errorCode <= 499) { */
  /*   return "4xx_errors"; */
  /* } */
  /* if (errorCode >= 500 && errorCode <= 599) { */
  /*   return "5xx_errors"; */
  /* } */
  /* return integrationStatus; */
};

const getRunStatus = (
  showBillableRunStatus,
  runStatusValue: string
): string => {
  let lastRunStatusValue = runStatusValue || "N/A";
  if (
    !showBillableRunStatus &&
    ["filtered", "completed"].includes((runStatusValue || "").toLowerCase())
  ) {
    lastRunStatusValue = "ok";
  }
  return lastRunStatusValue;
};

export const generateIntegrationConfig = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  integration,
  showBillableRunStatus: boolean,
  isDirectAction: boolean
): IIntegration => {
  const intStatus = isDirectAction
    ? integration.status === "ACTIVE"
      ? "Active"
      : integration.status
    : integration.status;
  const integrationConfig = {
    integrationId: `${integration.id}`,
    userId: integration.app_auth_id ?? integration.app_user_external_id,
    appAuthId: `${
      integration?.user_name
        ? integration.user_name !== integration.app_auth_id
          ? integration.user_name !== "FALSE"
            ? integration.user_name
            : "N/A"
          : ""
        : "N/A"
    }`,
    dateCreated: convertDateToUTCString(integration.created),
    dateCreatedFull: convertDateToUTCString(integration.created, true),
    dateUpdated: convertDateToUTCString(integration.last_modified_on),
    dateUpdatedFull: convertDateToUTCString(integration.last_modified_on, true),
    lastRunStart: convertDateToUTCString(
      integration.last_run_start_time || integration.last_run_start
    ),
    lastRunStartFull: convertDateToUTCString(
      integration.last_run_start_time || integration.last_run_start,
      true
    ),
    lastRunStatus: getRunStatus(
      showBillableRunStatus,
      integration.last_run_status
    ),
    integrationStatus: getIntegrationStatus(intStatus, integration.error_code),
    integrationStatusTooltip: getIntegrationTooltip(
      getIntegrationStatus(intStatus, integration.error_code),
      integration.error_app_name,
      integration.first_error_received_at,
      integration.last_updated_date,
      integration.last_modified_by
    ),
    hasRuns: integration.has_runs,
    userProfileLink: integration.user_profile_link,
    lastPollStatus: integration.error_code ? "failed" : "completed",
    lastPollTime: convertDateToUTCString(integration.last_poll_time),
    firstErrorReceivedAt: integration.first_error_received_at,
    errorAppName: integration.error_app_name,
    lastPollId: integration.last_poll_id,
    errorCode: integration.error_code,
    usage_limit_reached_at: integration.usage_limit_reached_at,
    runsCount: integration.runs_count,
    apiCallsCount: integration.api_calls_count,
    integrationName: integration.integration_name,
    brandingAppAccount: integration.user_identity,
    runsThisMonth: integration.runs_this_month,
  };
  return integrationConfig;
};

export const generateRunsConfig = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  results,
  showBillableRunStatus: boolean
): IRun[] => {
  const runs = results.map((result) => {
    const {
      id,
      trigger_app,
      trigger_name,
      trigger_type,
      start_time,
      duration_seconds = 0,
      status = "N/A",
      network_code = "N/A",
      api_calls_count,
      log_link,
      type,
      title,
      has_steps = false,
      run_type,
      integration_step_guid = "",
      billing_weight,
      status_message = "",
      status_type = "",
      network_code_description = "",
    } = result;
    return {
      runId: id,
      triggerApp: {
        iconSrc: trigger_app?.icon_url,
        name: trigger_app?.name,
        triggerType: trigger_type,
      },
      triggerAppandName: {
        iconSrc: trigger_app?.icon_url,
        name: trigger_name,
        triggerType: trigger_type,
      },
      triggerName: trigger_name,
      triggerType: trigger_type,
      startTime: convertDateToLocal(start_time),
      startTimeFull: convertDateToLocal(start_time, true),
      duration:
        (duration_seconds || 0) < 1 ? "<1s" : `${duration_seconds || "<1"}s`,
      status: getRunStatus(showBillableRunStatus, status) || "N/A",
      statusMessage: status_message,
      statusType: status_type,
      networkCode: network_code || "N/A",
      networkCodeDescription: network_code_description || "N/A",
      APICallsCount: api_calls_count,
      gcpLog: log_link,
      runType: run_type,
      type,
      hasSteps: has_steps,
      runTitle: title,
      integrationStepGuid: integration_step_guid,
      billingWeight: billing_weight,
    };
  });
  return runs;
};

// Steps View Config utils
const isLoop = (step): boolean => {
  let isLoopFlag = false;
  const { app, start_time } = step;
  /** NOTE: ADDED DATE DIFF LOGIC TO DIFFERENTIATE OLD CREATED INTEGRATIONS FROM NEW ONES */
  const dateDiffInMins = moment("2022-07-05T13:00:00").diff(
    start_time,
    "minutes"
  );
  const { name = "" } = app || {};
  const lowerCaseName = (name || "").toLowerCase();
  if (
    (lowerCaseName.startsWith("loop") && dateDiffInMins < 1) ||
    lowerCaseName.startsWith("do while loop") ||
    lowerCaseName.startsWith("for each loop")
  ) {
    isLoopFlag = true;
  }
  return isLoopFlag;
};

const getShouldHideStatusDetails = (step?: {
  activity_type: string;
}): boolean => {
  return (
    startsWith(step?.activity_type, "CONDITION") ||
    step?.activity_type === "LOOP" ||
    step?.activity_type === "DO_WHILE_LOOP" ||
    step?.activity_type === "CODE" ||
    step?.activity_type === "SETUP" ||
    step?.activity_type === "SCHEDULE"
  );
};

const getStepStatus = (step?: {
  activity_type: string;
  status: string;
  title: string;
}): string => {
  const stepStatus = step?.status || "N/A";

  const isWhilePredicateStep =
    step?.activity_type === "DO_WHILE_LOOP" &&
    String(step?.title).startsWith("While");
  if (isWhilePredicateStep) {
    return stepStatus;
  }

  let status = "N/A";

  switch (step?.activity_type) {
    case "CONDITION_IF":
    case "CONDITION_ELSEIF":
      {
        const isEvaluationResult =
          stepStatus === "true" || stepStatus === "false";
        status = isEvaluationResult ? stepStatus : "N/A";
      }
      break;
    case "CODE":
    case "LOOP":
    case "DO_WHILE_LOOP":
    case "SETUP":
    case "SCHEDULE":
      status = "N/A";
      break;
    default:
      status = stepStatus || "N/A";
  }
  return status || "N/A";
};

const getNestedLoopSteps = (subSteps): any => {
  let nestedSteps: any = [];
  let loopedSteps: any = [];
  subSteps.forEach((loop, loopIndex) => {
    if ((loop.child_steps || []).length > 0) {
      loopedSteps = [
        ...loopedSteps,
        ...loop.child_steps.map((childStep, index) => {
          const shouldHideStatusDetails = getShouldHideStatusDetails(childStep);
          let rowStyle: any = {
            margin: "0",
          };
          let rowDivStyle = {};
          if (loop.child_steps.length === 1) {
            rowDivStyle = {
              marginBottom: "10px",
            };
          } else if (index === 0) {
            rowStyle = {
              ...rowStyle,
              marginTop: loopIndex > 0 ? "10px" : "0",
            };
            rowDivStyle = {
              borderRadius: "6px 6px 0 0",
            };
          } else if (
            index === loop.child_steps.length - 1 &&
            loopIndex < subSteps.length - 1
          ) {
            rowDivStyle = {
              borderRadius: "0 0 6px 6px",
              borderTop: "none",
            };
          } else if (
            index === loop.child_steps.length - 1 &&
            loopIndex === subSteps.length - 1
          ) {
            rowStyle = { ...rowStyle, marginBottom: "10px" };
            rowDivStyle = {
              borderRadius: "0 0 6px 6px",
              borderTop: "none",
            };
          } else {
            rowDivStyle = {
              borderRadius: "0",
              borderTop: "none",
            };
          }
          // return { ...childStep, rowStyle };
          return {
            id: childStep.id,
            stepApp: childStep.app,
            activityType: childStep.activity_type,
            stepName: `${
              childStep.is_internal
                ? `${childStep.title} (Internal)`
                : childStep.title
            }`,
            startTime: convertDateToLocal(childStep.start_time),
            startTimeFull: convertDateToLocal(childStep.start_time, true),
            startTimeRaw: childStep.start_time,
            duration:
              (childStep.duration_seconds || 0) < 1
                ? "<1s"
                : `${childStep.duration_seconds || "<1"}s`,
            // duration: `${result.duration_seconds || 0}s`,
            status: getStepStatus(childStep),
            networkCode: shouldHideStatusDetails
              ? "N/A"
              : `${childStep.network_code || "N/A"}`,
            networkCodeDescription: `${
              shouldHideStatusDetails
                ? "N/A"
                : childStep.network_code_description || "N/A"
            }`,
            hasPayload: !!childStep.payload || true,
            payload: childStep?.payload || {},
            // eslint-disable-next-line no-nested-ternary
            subSteps:
              (childStep.child_steps || []).length > 0
                ? getSteps(childStep)
                : [],
            rowStyle,
            rowDivStyle,
            stepAppandName: {
              ...childStep,
              iconSrc: childStep.app?.icon_url,
              icon_url: childStep.app?.icon_url,
              name: childStep.title,
            },
          };
        }),
      ];
    }
    nestedSteps = [...nestedSteps, ...getSteps(loop)];
  });
  return loopedSteps;
  return nestedSteps;
};

const getNestedSteps = (subSteps): any => {
  let nestedSteps: any = [];
  subSteps.forEach((subStep) => {
    nestedSteps = [...nestedSteps, ...getSteps(subStep)];
  });
  return nestedSteps;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getSteps = (result): any => {
  let steps: any = [];
  let allowNesting = false;
  const allowNestingWhitelist = [
    "do while loop", // DO_WHILE_LOOP
    "for each loop", // `LOOP` activity renamed to "For Each Loop"
    "loop", // LOOP
    "if ", // CONDITION_IF
    "conditions",
    "else", // CONDITION_ELSE
  ];
  const shouldHideStatusDetails = getShouldHideStatusDetails(result);
  const tempSubsteps = result.child_steps || [];
  allowNestingWhitelist.forEach((whitelistLabel) => {
    if (
      (result.app.name || result.title || "")
        .toLowerCase()
        .startsWith(whitelistLabel) ||
      result.activity_type === "QUERY"
    ) {
      allowNesting = true;
    }
  });
  steps.push({
    id: result.id,
    stepApp: isLoop(result)
      ? { ...result.app, name: `Loop (${tempSubsteps.length} Times)` }
      : result.app,
    stepName: `${
      result.is_internal ? `${result.title} (Internal)` : result.title
    }`,
    stepAppandName: isLoop(result)
      ? { ...result.app, name: `Loop (${tempSubsteps.length} Times)` }
      : {
          ...result.app,
          name: `${
            result.is_internal ? `${result.title} (Internal)` : result.title
          }`,
        },
    startTime: convertDateToLocal(result.start_time),
    startTimeFull: convertDateToLocal(result.start_time, true),
    startTimeRaw: result.start_time,
    duration:
      (result.duration_seconds || 0) < 1
        ? "<1s"
        : `${result.duration_seconds || "<1"}s`,
    // duration: `${result.duration_seconds || 0}s`,
    status: getStepStatus(result),
    networkCode: shouldHideStatusDetails
      ? "N/A"
      : `${result.network_code || "N/A"}`,
    networkCodeDescription: `${
      shouldHideStatusDetails ? "N/A" : result.network_code_description || "N/A"
    }`,
    hasPayload: !!result.payload || true,
    isExpired: !result.has_payload,
    payload: result?.payload || {},
    // eslint-disable-next-line no-nested-ternary
    subSteps: allowNesting
      ? isLoop(result)
        ? getNestedLoopSteps(tempSubsteps)
        : getNestedSteps(tempSubsteps)
      : [],
    activityType: result.activity_type,
    template_step_id: result.template_step_id,
  });
  if (tempSubsteps.length > 0 && !allowNesting) {
    tempSubsteps.forEach((subStep) => {
      steps = [...steps, ...getSteps(subStep)];
    });
  }
  return steps;
};
