/* eslint-disable no-else-return */
/* eslint-disable no-lonely-if */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable no-script-url */
/* eslint-disable react/no-unescaped-entities */
import React, { ReactElement, useEffect, useState } from "react";
import FormikWrapper from "components/forms/v4/formik-wrapper";
import TextField from "components/forms/v4/text-field";
import Label from "components/forms/v4/label";
import Textarea from "components/forms/v4/text-area";
import { useFormikContext } from "formik";
import StepsTable from "components/integry-design-system/molecules/steps-table";
import IntegryTooltip from "components/integry-design-system/molecules/integry-tooltip/integry-tooltip-v2";
import IntegryLoader from "components/integry-loader";
import Button from "components/common/reusable-button";
import openLinkImage from "images/previewIcon.svg";
import testAuthIcon from "images/test-auth-icon.svg";
import testAuthIconWhite from "images/test-auth-icon-white.svg";
import copyIcon from "images/copy-icon-editor.svg";
import closeIcon from "images/whitelabel-close.svg";
import checkIcon from "images/white-label-status-check.svg";
import failIcon1 from "images/whitelabel-fail-3.svg";
import seeDetailArrow from "images/see-detail-arrow.svg";
import toggleSecretIcon from "images/toggle-secret-icon.svg";
import closedEyeIcon from "images/closed-eye-icon.svg";
import lang from "utils/lang/en";
import { convertDateToLocal } from "utils/functions";
import useOnClickOutside from "utils/use-onclick-outside";
import { whiteLabelFormValidationSchema } from "./validations";
import {
  WhiteLabelConfigObject,
  WhiteLabelFormFields,
  WhiteLabelStepObject,
} from "./interfaces";
import "./styles.scss";

// eslint-disable-next-line no-shadow
export enum WhiteLabelFormRenderModes {
  PLAIN,
  MODAL,
}

const REDIRECT_URI = "https://app.integry.io/auth/redirect/";

const StepsTableConfig = [
  {
    id: "title",
    label: "Step",
    // tdStyle: {
    //   whiteSpace: "break-spaces",
    //   wordBreak: "break-word",
    //   fontWeight: "500",
    //   opacity: "0.8",
    // },
  },
  {
    id: "startTime",
    label: "Start Time",
    // allowSorting: true,
    // sortingIdentifier: "start_time",
  },
  {
    id: "status",
    label: "Status",
    type: "tag",
  },
  {
    id: "networkCode",
    label: "Network Code",
    tooltip: lang.NETWORK_CODE_TOOLTIP,
    tdStyle: {
      justifyContent: "space-between",
    },
  },
];

export interface IWhiteLabelForm {
  configureAppObject: any;
  whiteLabelConfig?: WhiteLabelConfigObject;
  renderMode: WhiteLabelFormRenderModes;
  hideEnableSection?: boolean;
  isSaving: boolean;
  onSaveWhitelabelConfigForm: (formData: WhiteLabelFormFields) => void;
  handleClickEnable?: () => void;
  currentFormData: WhiteLabelFormFields;
  handleClickClose: () => void;
  testPassed: boolean;
  stepsData: WhiteLabelStepObject[];
  user: any;
  bundleId: string;
  lastEnabledWhiteLabelConfig?: WhiteLabelConfigObject | null;
  isLoading?: boolean;
}

const WhiteLabelForm = (props: IWhiteLabelForm): ReactElement => {
  const {
    configureAppObject,
    whiteLabelConfig,
    onSaveWhitelabelConfigForm,
    renderMode,
    isSaving,
    currentFormData,
    hideEnableSection,
    handleClickEnable,
    handleClickClose,
    testPassed,
    stepsData,
    user,
    bundleId,
    lastEnabledWhiteLabelConfig,
    isLoading = false,
  } = props;

  const [isTestDetailVisible, setIsTestDetailVisible] = useState(false);
  const divRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showSeeDetialOption()) {
      if (!testPassed && !isTestDetailVisible) {
        setIsTestDetailVisible(true);
      } else if (testPassed && isTestDetailVisible) {
        setIsTestDetailVisible(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testPassed, stepsData]);

  const showSeeDetialOption = (): boolean => {
    if (
      stepsData &&
      stepsData.length > 0 &&
      whiteLabelConfig &&
      whiteLabelConfig?.id &&
      (!lastEnabledWhiteLabelConfig ||
        (lastEnabledWhiteLabelConfig &&
          lastEnabledWhiteLabelConfig?.id !== whiteLabelConfig?.id))
    ) {
      return true;
    }
    return false;
  };

  const ConfigureFormSection = (): ReactElement => {
    const [isRedirectURICopied, setIsRedirectURICopied] = useState(false);
    const [showSecret, setShowSecret] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { submitForm, isValid, values } = useFormikContext() as {
      values: WhiteLabelFormFields;
      submitForm;
      isValid: boolean;
    };

    const copyRedirectURI = (): void => {
      navigator.clipboard.writeText(REDIRECT_URI);
      //   track(configureOAuthAppEvents.COPY_REDIRECT_URL.name, user, {
      //     [configureOAuthAppEvents.COPY_REDIRECT_URL.properties.MARKETPLACE_ID]:
      //       bundle.id,
      //     [configureOAuthAppEvents.COPY_REDIRECT_URL.properties.APP_ID]:
      //       configureAppObject.id,
      //   });
      setIsRedirectURICopied(true);
      setTimeout(() => {
        setIsRedirectURICopied(false);
      }, 1600);
    };

    // const disableContinue = (): boolean => {
    //   if (!values.client_id || !values.client_secret || !isValid || isSaving) {
    //     return true;
    //   }
    //   return false;
    // };

    return (
      <div className="configure-form-section">
        <p className="title">Configure</p>
        <p className="subheading">
          Go to your {configureAppObject?.name} account and generate Client ID
          and Client Secret. If you are not sure how to do that,{" "}
          <IntegryTooltip
            tooltipId="url-not-defined"
            tooltipText="Not defined. Please contact support."
            effect="solid"
            placement="bottom"
            hideToolTip={
              !!whiteLabelConfig?.authorizationType?.app?.oauthSetupGuideLink
            }
          >
            <a
              href={`${
                whiteLabelConfig?.authorizationType?.app?.oauthSetupGuideLink ||
                "javascript:;"
              }`}
              rel="noopener noreferrer"
              target="_blank"
              className={`link-with-preview-icon click-here-link ${
                !whiteLabelConfig?.authorizationType?.app?.oauthSetupGuideLink
                  ? "disabled"
                  : ""
              }`}
              onClick={(e) => {
                if (
                  !whiteLabelConfig?.authorizationType?.app?.oauthSetupGuideLink
                ) {
                  e.preventDefault();
                }
              }}
            >
              click here
              <img
                className="preview-icon"
                src={openLinkImage}
                alt="open-link-icon"
              />
            </a>
          </IntegryTooltip>
          .
        </p>
        <div className="app-detail-form-grid">
          <div
            className="app-detail-form-label-container directory-label"
            style={{ marginTop: "0px" }}
          >
            <Label
              title="Redirect URL"
              forControl=""
              toolTip="This is where the end-user will be redirected upon successful or failed authentications"
              isUnderlined
              toolTipPlacement="top"
            />
          </div>
          <div
            className="app-detail-form-field-container directory-field"
            style={{ minHeight: "auto" }}
          >
            <div style={{ color: "#333333" }}>
              {REDIRECT_URI}
              <IntegryTooltip
                tooltipId="copy-body"
                tooltipText={isRedirectURICopied ? "Copied" : "Copy"}
                effect="solid"
                placement="bottom"
              >
                <img
                  src={copyIcon}
                  onClick={copyRedirectURI}
                  tabIndex={0}
                  role="button"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      copyRedirectURI();
                    }
                  }}
                  alt="copy-icon"
                  className="copy-icon copy-redirect-uri-icon"
                />
              </IntegryTooltip>
            </div>
          </div>
          <div className="app-detail-form-label-container directory-label">
            <Label
              title="Client ID"
              forControl="client_id"
              toolTip={`The client ID of your app in ${configureAppObject?.name}`}
              isRequired
              isUnderlined
              toolTipPlacement="top"
            />
          </div>
          <div className="app-detail-form-field-container directory-field">
            <TextField
              className=" form-control-lg form-control app-form-field "
              name="client_id"
              id="client_id"
              type="text"
              placeholder="E.g. 267a89b7878c"
            />
          </div>
          <div className="app-detail-form-label-container directory-label">
            <Label
              title="Client Secret"
              forControl="client_secret"
              toolTip={`The client secret of your app in ${configureAppObject?.name}`}
              isRequired
              isUnderlined
              toolTipPlacement="top"
            />
          </div>
          <div className="app-detail-form-field-container directory-field">
            <TextField
              className=" form-control-lg form-control app-form-field right-icon"
              name="client_secret"
              id="client_secret"
              type={showSecret ? "text" : "password"}
              placeholder="E.g. BAyfVL7YWtp6gudLIB"
              rightIcon={
                <div className="right-icon-span">
                  <img
                    src={showSecret ? closedEyeIcon : toggleSecretIcon}
                    alt="toggle-secret-icon"
                    className="toggle-secret-icon"
                    onClick={() => {
                      setShowSecret((prev) => !prev);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setShowSecret((prev) => !prev);
                      }
                    }}
                    role="button"
                    tabIndex={0}
                  />
                </div>
              }
            />
          </div>
          <div className="app-detail-form-label-container directory-label">
            <Label
              title="Scopes"
              forControl="scopes"
              toolTip={`Permission scopes of your app in ${configureAppObject?.name}`}
              isUnderlined
              toolTipPlacement="top"
            />
          </div>
          <div className="app-detail-form-field-container directory-field">
            <Textarea
              className=" form-control-lg form-control app-form-field "
              name="scopes"
              id="scopes"
              type="text"
              placeholder="E.g. content oauth crm.schemas.deals.read"
            />
            <p className="field-help-text">
              Before changing scopes in this field, please go through{" "}
              <a
                href="https://support.integry.io/hc/en-us/articles/10887314172441-Rebrand-OAuth-of-a-Pre-built-App-Connector"
                rel="noopener noreferrer"
                target="_blank"
                className="link-with-preview-icon learn-scope-link"
                onClick={() => {
                  // track(
                  //   configureOAuthAppEvents.VIEW_LEARN_MORE_SCOPES_CLICK.name,
                  //   user,
                  //   {
                  //     [configureOAuthAppEvents.VIEW_LEARN_MORE_SCOPES_CLICK
                  //       .properties.APP_ID]: configureAppObject.id,
                  //     [configureOAuthAppEvents.VIEW_LEARN_MORE_SCOPES_CLICK
                  //       .properties.BUNDLE_ID]: bundle.id,
                  //   }
                  // );
                }}
              >
                {`${configureAppObject?.name}'s API documentation`}
                <img
                  className="preview-icon"
                  src={openLinkImage}
                  alt="open-link-icon"
                />
              </a>
              .
            </p>
          </div>
        </div>
        <div className="continue-button-container">
          {showSeeDetialOption() && (
            <div className="whitelabel-status-div">
              <span
                className="see-detail-span"
                onClick={() => {
                  setIsTestDetailVisible((prev) => !prev);
                }}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setIsTestDetailVisible((prev) => !prev);
                  }
                }}
              >
                {isTestDetailVisible ? "Hide details" : "See details"}
                <img
                  src={seeDetailArrow}
                  alt="see-arrow"
                  className={
                    isTestDetailVisible ? "see-arrow  open" : "see-arrow"
                  }
                />
              </span>
              <img
                className={`${"status-icon"}`}
                alt="status-icon"
                src={testPassed === true ? checkIcon : failIcon1}
              />
            </div>
          )}
          <div className="continue-button-flex">
            <div className="continue-button-flex-end">
              <Button
                title="Test"
                onClick={() => {
                  if (!isSaving) {
                    submitForm();
                  }
                }}
                className="btn btn-royal-blue-secondary test-btn"
                type="button"
                icon={testAuthIcon}
                hoverIcon={testAuthIconWhite}
                rightIcon
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const EnableSection = (): ReactElement => {
    const allowEnable = (): boolean => {
      if (lastEnabledWhiteLabelConfig) {
        if (lastEnabledWhiteLabelConfig?.isActive) {
          if (
            testPassed &&
            whiteLabelConfig &&
            whiteLabelConfig?.id &&
            !whiteLabelConfig?.isActive &&
            lastEnabledWhiteLabelConfig?.id !== whiteLabelConfig?.id
          ) {
            return true;
          }
        } else {
          if (
            lastEnabledWhiteLabelConfig?.authPassed &&
            whiteLabelConfig &&
            whiteLabelConfig?.id &&
            whiteLabelConfig?.id === lastEnabledWhiteLabelConfig?.id
          ) {
            return true;
          } else if (
            testPassed &&
            whiteLabelConfig &&
            !whiteLabelConfig?.isActive &&
            whiteLabelConfig?.id &&
            whiteLabelConfig?.id !== lastEnabledWhiteLabelConfig?.id
          ) {
            return true;
          }
        }
      } else if (
        testPassed &&
        whiteLabelConfig &&
        whiteLabelConfig?.id &&
        !whiteLabelConfig?.isActive
      ) {
        return true;
      }
      return false;
    };

    const canEnable = allowEnable();

    return (
      <div className="enable-section">
        <div className="divider" />
        <p className="title">Enable</p>
        <p className="subheading">
          {`Enable this configuration to let your users see your app's name and logo on ${configureAppObject.name}'s auth screens.`}
          <br />
          {`Users that have already connected to ${configureAppObject.name} will not be impacted.`}
        </p>
        {lastEnabledWhiteLabelConfig &&
          // lastEnabledWhiteLabelConfig?.lastEnabledDate
          lastEnabledWhiteLabelConfig?.authorizationType?.createdDate && (
            <p className="date">
              Last Saved:{" "}
              {convertDateToLocal(
                lastEnabledWhiteLabelConfig?.lastEnabledDate ||
                  lastEnabledWhiteLabelConfig?.authorizationType?.createdDate
              )}
            </p>
          )}
        <div className="continue-button-container">
          <div className="continue-button-flex">
            <div className="continue-button-flex-end">
              <IntegryTooltip
                tooltipId="enable-btn-tooltip"
                tooltipText="You need to test the configuration first"
                effect="solid"
                placement="bottom"
                hideToolTip={canEnable}
              >
                <button
                  className={
                    canEnable
                      ? "btn-continue basic-info-continue"
                      : "btn-continue basic-info-continue disabled"
                  }
                  type="button"
                  onClick={() => {
                    if (canEnable && handleClickEnable) {
                      handleClickEnable();
                    }
                  }}
                >
                  Enable
                </button>
              </IntegryTooltip>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBody = (): ReactElement => {
    return (
      <>
        <FormikWrapper
          validationSchema={whiteLabelFormValidationSchema}
          saveCallback={onSaveWhitelabelConfigForm}
          initialState={currentFormData}
          formContent={<ConfigureFormSection />}
          reInitialize
        />
        {stepsData &&
          stepsData.length > 0 &&
          isTestDetailVisible &&
          (!lastEnabledWhiteLabelConfig ||
            (lastEnabledWhiteLabelConfig &&
              lastEnabledWhiteLabelConfig?.id !== whiteLabelConfig?.id)) && (
            <div className="step-table-wrap">
              <StepsTable
                config={StepsTableConfig}
                data={stepsData}
                user={user}
                bundleId={bundleId}
                appId={configureAppObject.id}
              />
            </div>
          )}
        {!hideEnableSection && <EnableSection />}
      </>
    );
  };

  const clickOutside = (evt): void => {
    handleClickClose();
  };

  useOnClickOutside(divRef, clickOutside);

  return renderMode === WhiteLabelFormRenderModes.MODAL ? (
    <div className="white-label-form modal">
      <div className="modal-wrap" ref={divRef}>
        <div className="modal-header">
          <div className="modal-title-wrap">
            <img
              src={configureAppObject.iconUrl}
              alt="app-icon"
              className="app-icon"
            />
            <div>
              <p className="app-name">{configureAppObject?.name}</p>
              <p className="configure-label">Auth Settings</p>
            </div>
          </div>
          <img
            src={closeIcon}
            alt="close-icon"
            className="close-icon"
            role="button"
            tabIndex={0}
            onClick={handleClickClose}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleClickClose();
              }
            }}
          />
        </div>
        <div className="divider" />
        <div className="body-scroll-wrap ">
          <div className={`content-body ${isLoading ? "loading" : ""}`}>
            {isLoading && (
              <div className="loading-state">
                <IntegryLoader />
              </div>
            )}
            {renderBody()}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="white-label-form plain">{renderBody()}</div>
  );
};

export default WhiteLabelForm;
