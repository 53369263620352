import { createApi } from "@reduxjs/toolkit/query/react";
import config from "utils/config";
import { integryBaseQuery } from "utils/http";
import { ListedTemplate } from "features/templates/template-builder-v7/services/template-builder-interface";

interface listTemplatesResponse {
  count: number;
  links: { previous?: string; next?: string };
  meta: {
    status_counts: { status: string; count: number }[];
    template_apps: { app_id: number; app_name: string }[];
  };
  results: ListedTemplate[];
  total_pages: number;
}

export const marketplaceFlowAPI = createApi({
  reducerPath: "maketplaceFlows",
  baseQuery: integryBaseQuery({
    baseUrl: config.apiBaseURL || "",
  }),
  tagTypes: ["maketplaceFlows"],
  refetchOnMountOrArgChange: true, // this is to be removed once the other flow create/update endpoints are moved to rtk
  endpoints(build) {
    return {
      listTemplatesWithStats: build.mutation<
        listTemplatesResponse,
        {
          searchText: string;
          page: number;
        }
      >({
        query: ({ searchText, page }) => ({
          url: `/api/v1/marketplace-templates/?page_size=15&page=${page}&search=${searchText}`,
          method: "get",
        }),
      }),
      cloneFlow: build.mutation({
        query(flowId) {
          return {
            url: `/api/v2/templates/${flowId}/duplicate/`,
            method: "POST",
          };
        },
      }),
      addFlowInMarketPlace: build.mutation({
        query({ templateId }) {
          console.log("templateId", templateId);
          return {
            url: `/api/v1/marketplace-templates/`,
            method: "POST",
            data: {
              template_id: templateId,
            },
          };
        },
      }),
      removeFlowFromMarketPlace: build.mutation({
        query({ templateId }) {
          return {
            url: `/api/v1/marketplace-templates/${templateId}/`,
            method: "DELETE",
            data: {
              template_id: templateId,
            },
          };
        },
      }),
      pollCloningActionFlows: build.mutation({
        query({ workspaceAppId }) {
          return {
            url: `/api/v1/templates/flows_in_cloning/?app=${workspaceAppId}`,
            method: "GET",
          };
        },
      }),
      archiveFlow: build.mutation({
        query(flowId) {
          return {
            url: `/api/templates/${flowId}/?publish=false&archive=true`,
            method: "PUT",
          };
        },
      }),
      unArchiveFlow: build.mutation({
        query(flowId) {
          return {
            url: `/api/templates/${flowId}/?publish=false`,
            method: "PUT",
          };
        },
      }),
      moveFlow: build.mutation({
        query({ flowId, ...data }) {
          return {
            url: `/api/v2/templates/${flowId}/app/`,
            method: "PUT",
            data,
          };
        },
      }),
      convertActivity: build.mutation({
        query({ appId, stepId }) {
          return {
            url: `/api/v1/apps/${appId}/activities/?template_step_id=${stepId}`,
            method: "POST",
          };
        },
      }),
      updateTemplateSettings: build.mutation({
        query({ templateId, settings }) {
          return {
            url: `/api/v5/templates/${templateId}/`,
            method: "PATCH",
            data: settings,
          };
        },
      }),
    };
  },
});

export const {
  useListTemplatesWithStatsMutation,
  useCloneFlowMutation,
  useMoveFlowMutation,
  useConvertActivityMutation,
  useArchiveFlowMutation,
  useUnArchiveFlowMutation,
  useAddFlowInMarketPlaceMutation,
  usePollCloningActionFlowsMutation,
  useRemoveFlowFromMarketPlaceMutation,
  useUpdateTemplateSettingsMutation,
} = marketplaceFlowAPI;
