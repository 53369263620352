import { ReactElement } from "react";
import RadioGroup, { RadioOptions, RadioGroupProps } from "../radio-group";

import "./styles.scss";

export type { RadioOptions };

const RadioGroupV2 = (props: RadioGroupProps): ReactElement => {
  return (
    <div className="radio-group-v2">
      <RadioGroup {...props} />
    </div>
  );
};

export default RadioGroupV2;
