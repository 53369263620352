import React from "react";
import "./styles.scss";

const payloadCompnentLoader: React.FC = () => {
  return (
    <div className="step-payload-skeleton">
      <div className="tab-skeleton">
        <div className="skeleton tab-item" />
        <div className="skeleton tab-item" />
        <div className="skeleton tab-item" />
      </div>
      <div className="url-section-skeleton skeleton" />
      <div className="header-section-skeleton skeleton" />
      <div className="body-section-skeleton skeleton" />
    </div>
  );
};

export default payloadCompnentLoader;
