/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useEffect, useRef, useState } from "react";

import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import { stepMetaObjectPayload } from "components/integry-design-system/organisms/integration-view/step-payload-component";
import lang from "utils/lang/en";

import { Poll } from "features/integrataion-polls/interface";

import exlaimationIconOutline from "images/exclaimation_icon_outline.svg";
import NotFoundIcon from "images/not_found_icon_lg.svg";
import SortIconDefault from "images/sort.svg";
import SortIconDesc from "images/sort-applied.svg";

import RenderTable from "./table";
import "legacy-features/integrations/styles.scss";
import "./styles.scss";
import SkeletonLoader from "./skeleton-loader";

interface StepListigTableProps {
  steps: Poll[];
  fetching: boolean;
  hasMore: boolean;
  expandedPayloadStepId: string;
  sorting: Record<string, string>;
  tableConfig: any;
  setExpandedPayloadStepId: (id: string) => void;
  handleOnSorting: (sortingIdentifier: string) => void;
  fetchMoreSteps: () => void;
}

const StepListigTable = (props: StepListigTableProps): ReactElement => {
  const {
    steps,
    fetching,
    sorting,
    expandedPayloadStepId,
    tableConfig,
    hasMore,
    fetchMoreSteps,
    handleOnSorting,
    setExpandedPayloadStepId,
  } = props;

  const [expandedStepId, setExpandedStepId] = useState<Array<string>>([]);

  const expandedStepIdRef = useRef(expandedStepId);

  useEffect(() => {
    expandedStepIdRef.current = expandedStepId;
  }, [expandedStepId]);

  const [viewStepPayloadData, setViewStepPayloadData] =
    useState<stepMetaObjectPayload>({});

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleStepExpansion = (stepId: string): void => {
    addToExpandedSteps(stepId);
  };

  const addToExpandedSteps = (stepId: string): void => {
    let tempExpandedIds: Array<string> = [];
    const tempCollapsedIds: Array<string> = [];
    if (expandedStepIdRef.current.includes(stepId)) {
      expandedStepIdRef.current.forEach((id) => {
        if (id !== stepId) {
          tempExpandedIds.push(id);
        } else {
          tempCollapsedIds.push(id);
        }
      });
    } else {
      tempExpandedIds = [...expandedStepIdRef.current, stepId];
    }
    setExpandedStepId(tempExpandedIds);
    expandedStepIdRef.current = tempExpandedIds;
    setExpandedPayloadStepId("-1");
  };

  const fetchStepPayloadData = (stepId: number, step: Poll): void => {
    let payloadData: stepMetaObjectPayload = {};
    const { payload } = step;
    const { request, response } = payload;

    const {
      parsed_object_output,
      parsed_response_template,
      remote_response,
      response_headers,
    } = response;

    const {
      url,
      headers,
      request_method,
      request_template,
      parsed_request_template,
    } = request;

    payloadData = {
      parsedUrl: url,
      parsedHeaders: headers,
      parsedObjectOutput: parsed_object_output,
      parsedRequestTemplate: parsed_request_template,
      parsedResponseTemplate: parsed_response_template,
      remoteResponse: remote_response,
      requestTemplate: request_template,
      responseHeaders: response_headers,
      requestHttpVerb: (request_method || "").toUpperCase(),
    };
    setViewStepPayloadData(payloadData);
  };

  return (
    <div className="step-listing-table">
      <div className="div-table">
        <div className="div-header div-header-new table-row">
          {tableConfig.steps.config.map((configItem) => {
            const { tooltip, allowSorting } = configItem;
            if (tooltip && allowSorting) {
              return (
                <div className="div-heading">
                  {configItem.label}
                  <IntegryTooltip
                    tooltipText={configItem.tooltip}
                    placement="top"
                  >
                    <img
                      width={15}
                      height={15}
                      src={exlaimationIconOutline}
                      style={{
                        marginLeft: "5px",
                        marginTop: "-2px",
                        transform: "rotateZ(180deg)",
                      }}
                      alt="tooltip_heading"
                    />
                  </IntegryTooltip>
                  <img
                    width={15}
                    height={15}
                    style={{
                      marginLeft: "5px",
                      marginTop: "-2px",
                      cursor: "pointer",
                      opacity: !fetching ? "1" : "0.4",
                    }}
                    src={
                      sorting[configItem.sortingIdentifier || configItem.id]
                        ? SortIconDesc
                        : SortIconDefault
                    }
                    className={
                      sorting[configItem.sortingIdentifier || configItem.id] &&
                      sorting[configItem.sortingIdentifier || configItem.id] ===
                        "asc"
                        ? "table-sort-icon-asc"
                        : ""
                    }
                    alt="sort_icon"
                    onClick={() => {
                      if (!fetching) {
                        handleOnSorting(
                          configItem.sortingIdentifier || configItem.id
                        );
                      }
                    }}
                    aria-hidden="true"
                  />
                </div>
              );
            }
            if (tooltip) {
              return (
                <div className="div-heading">
                  {configItem.label}
                  <IntegryTooltip
                    tooltipText={configItem.tooltip}
                    placement="top"
                  >
                    <img
                      width={15}
                      height={15}
                      src={exlaimationIconOutline}
                      style={{
                        marginLeft: "5px",
                        marginTop: "-2px",
                        transform: "rotateZ(180deg)",
                      }}
                      alt="tooltip_heading"
                    />
                  </IntegryTooltip>
                </div>
              );
            }
            if (allowSorting) {
              return (
                <div className="div-heading">
                  {configItem.label}
                  <img
                    width={15}
                    height={15}
                    style={{
                      marginLeft: "5px",
                      marginTop: "-2px",
                      cursor: "pointer",
                      opacity: !fetching ? "1" : "0.4",
                    }}
                    src={
                      sorting[configItem.sortingIdentifier || configItem.id]
                        ? SortIconDesc
                        : SortIconDefault
                    }
                    className={
                      sorting[configItem.sortingIdentifier || configItem.id] &&
                      sorting[configItem.sortingIdentifier || configItem.id] ===
                        "asc"
                        ? "table-sort-icon-asc"
                        : ""
                    }
                    alt="sort_icon"
                    onClick={() => {
                      if (!fetching) {
                        handleOnSorting(
                          configItem.sortingIdentifier || configItem.id
                        );
                      }
                    }}
                    aria-hidden="true"
                  />
                </div>
              );
            }
            return <div className="div-heading">{configItem.label}</div>;
          })}
        </div>
        {!fetching &&
          steps.length < 1 && ( // Empty Message
            <div className="view-record-not-found__placeholder">
              <img src={NotFoundIcon} alt="record_not_found" />
              <p>{lang.INTEGRATIONS_NOT_FOUND_COPY_POLLS}</p>
            </div>
          )}
        {!fetching && (
          <RenderTable
            config={tableConfig.steps.config}
            data={steps}
            node={0}
            expandedStepIdRef={expandedStepIdRef}
            expandedPayloadStepId={expandedPayloadStepId}
            setExpandedPayloadStepId={setExpandedPayloadStepId}
            setViewStepPayloadData={setViewStepPayloadData}
            fetchStepPayloadData={fetchStepPayloadData}
            viewStepPayloadData={viewStepPayloadData}
            expandedStepId={expandedStepId}
            fetching={fetching}
          />
        )}
        {fetching && (
          <SkeletonLoader config={tableConfig.steps.config} rowCount={3} />
        )}
        {hasMore && (
          <SkeletonLoader
            config={tableConfig.steps.config}
            rowCount={1}
            onInViewport={fetchMoreSteps}
          />
        )}
      </div>
    </div>
  );
};

export default StepListigTable;
