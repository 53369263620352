// react component for split button
import { ReactElement } from "react";
import "./styles.scss";

interface dashedButtonProps {
  onClick: () => void;
  children: ReactElement;
}

const DashedButton = ({
  children,
  onClick,
}: dashedButtonProps): ReactElement => {
  return (
    <button className="dashed-container" onClick={onClick} type="button">
      {children}
    </button>
  );
};

export default DashedButton;
