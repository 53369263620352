import { decodeJSON } from "legacy-features/integration-view/utils";
import * as yup from "yup";

const ValidatePayloadForm = yup.object().shape({
  name: yup
    .string()
    .required("This field is required")
    .matches(/^[a-zA-Z0-9]*$/, "Title can only contain alphanumeric characters")
    .nullable(),
  payload: yup
    .string()
    .required("This field is required")
    .test({
      name: "payload",
      message: "Please enter a valid JSON",
      test: (value) => {
        try {
          return JSON.parse(value) instanceof Object && !!value;
        } catch (err) {
          console.error("Failed to parse JSON", err);
        }
        return false;
      },
    })
    .test({
      name: "payload",
      message: "JSON should have atleast one key-value pair",
      test: (value) => {
        const decodedJSON = decodeJSON(value);

        return !(
          Object.keys(decodedJSON).length === 0 ||
          (Object.keys(decodedJSON).length === 1 &&
            Object.keys(decodedJSON).filter((key) => key.length === 0)
              .length !== 0)
        );
      },
    })
    .nullable(),
});

export default ValidatePayloadForm;
