/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, ReactElement } from "react";

import { track } from "utils/mixpanel";
import showToast, {
  showErrorToast,
} from "components/common/custom-toast/custom-toast";
import OverflowTooltip from "components/common/overflow-tooltip";
import MeatballsMenu, {
  IMenuItems,
} from "components/integry-design-system/molecules/meatballs-menu";
import { payloadInterface } from "features/account-settings/services/interface";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
// import editIcon from "images/edit-tag-gray.svg";
// import deleteIcon from "images/delete-tag.svg";
import { convertDateToLocal } from "utils/functions";
import TruncateBullets from "images/truncate_bullets.svg";
import deleteIcon from "images/delete_bin_red.svg";
import {
  deleteActionPayload,
  updateActionPayload,
} from "./services/payloads-apis";

const ActionPayload = (props: payloadInterface): ReactElement => {
  const {
    index,
    id,
    actions_count,
    total_payloads,
    name,
    description,
    payload,
    modified,
    removePayloadFromList,
    validatePayload,
    editPayloadIndex,
    setEditPayloadIndex,
    updatePayloadInList,
    setShowEditor,
    user,
  } = props;
  let tooltipMsg = "";
  if (actions_count > 0) {
    tooltipMsg =
      actions_count === 1
        ? "This object is currently being used in an action and cannot be deleted"
        : "This object is currently being used in actions and cannot be deleted";
  } else if (total_payloads === 1) {
    tooltipMsg = "Cannot delete object. You need to have atleast one object.";
  }

  const option: IMenuItems = {
    label: "Delete",
    value: "delete",
    iconPrefix: deleteIcon,
    className: "delete",
    disabled: !!(actions_count > 0 || total_payloads === 1),
    tooltip: tooltipMsg,
    tooltipPlacement: "bottom",
  };
  const menuOptions = [option];

  // const [showRenameModal, setShowRenameModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [actionPayload, setActionPayload] = useState({
    name,
    description,
    payload,
  });
  const updatePayload = (e, attrib) => {
    const value = attrib === "payload" ? e.target.value : e;
    setActionPayload({
      ...actionPayload,
      [attrib]: value,
    });
  };

  const meatballOnSelect = (value: string): void => {
    switch (value) {
      // case "rename":
      //   handleEditPayload();
      //   break;
      case "delete":
      case "deleteTag":
        onPayloadRemove();
        track("Object delete clicked", user, { object_name: name });
        break;
      default:
    }
  };

  const onPayloadRemove = (): void => {
    if (total_payloads > 1) {
      setIsDeleting(true);
      const tagId = id;
      deleteActionPayload(tagId)
        .then((res) => {
          showToast("Object  deleted", `‘${name}’ object deleted`);
          track("Object deleted", user, { object_name: name });
          setIsDeleting(false);
          removePayloadFromList(id);
        })
        .catch((e) => {
          setIsDeleting(false);
        });
    }
  };

  const handleEditPayload = (event): void => {
    event.target.parentNode.classList.remove("show");
    const div = event.currentTarget;
    const menuOption = div.querySelector(".meatballs-menu__item");
    if (menuOption !== event.target) {
      setEditPayloadIndex(index);
      setShowEditor(true);
    }
  };

  const handlePayloadChange = (e): void => {
    // setTag(e.target.value);
  };
  const handleUpdatePayload = (): void => {
    setIsUpdating(true);
    console.log("aaa");
    updateActionPayload(
      id,
      actionPayload.name,
      actionPayload.description,
      actionPayload.payload
    )
      .then((res) => {
        setIsUpdating(false);
        setShowEditor(false);
        // setIsEditing(false);
        updatePayloadInList(
          id,
          actionPayload.name,
          actionPayload.description,
          actionPayload.payload
        );
        showToast("Object updated", `‘${name}’ object updated`);
      })
      .catch((error) => {
        setIsUpdating(false);
        // setIsEditing(false);
        console.log(error.response);
        showErrorToast(
          "Object  update failed",
          `${error.response.data.unique_error}`
        );
      });
  };
  return (
    <tr className="tag-row member-row" onClick={handleEditPayload}>
      <div className="tag-col">
        <>
          <div className="tag-name">
            {name?.length > 20 ? (
              <IntegryTooltip
                className="dark-tooltip"
                tooltipText={name}
                placement="bottom"
              >
                <span>
                  {name.substring(0, 20)}
                  <img
                    src={TruncateBullets}
                    style={{
                      width: "15px",
                      margin: "0 2px",
                      marginTop: "-3px",
                      objectFit: "none",
                    }}
                    alt="truncate_bullets"
                  />
                </span>
              </IntegryTooltip>
            ) : (
              name
            )}
          </div>
        </>
      </div>
      <div className="tag-col">
        {description?.length > 70 ? (
          <IntegryTooltip
            className="dark-tooltip"
            tooltipText={description}
            placement="bottom"
          >
            <span>
              {description.substring(0, 70)}
              <img
                src={TruncateBullets}
                style={{
                  width: "15px",
                  margin: "0 2px",
                  marginTop: "-3px",
                  objectFit: "none",
                }}
                alt="truncate_bullets"
              />
            </span>
          </IntegryTooltip>
        ) : (
          description
        )}
      </div>
      <div className="tag-col account-count">{actions_count}</div>

      <div className="tag-col">
        <OverflowTooltip
          className="tag-date"
          text={convertDateToLocal(modified)}
        />
      </div>
      <div className="tag-col">
        <MeatballsMenu
          items={menuOptions}
          onSelect={meatballOnSelect}
          customStyle={actions_count > 0 ? { opacity: "0.9" } : {}}
        />
      </div>
    </tr>
  );
};

export default ActionPayload;
