import React, { ReactElement, CSSProperties } from "react";
import uuid from "uuid";
import xIcon from "images/x.svg";
import "./styles.scss";

interface IIntegryModalProps {
  id?: string;
  title?: string;
  subTitle?: string;
  children?: ReactElement;
  footer?: ReactElement | null;
  customHeader?: ReactElement | null;
  onClose?: () => void;
  className?: string;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
  contentStyle?: CSSProperties;
  hideCloseCTA?: boolean;
  footerStyle?: CSSProperties;
}

const IntegryModal = (props: IIntegryModalProps): ReactElement => {
  const {
    id = uuid.v4(),
    title,
    subTitle,
    children,
    footer,
    onClose,
    className = "",
    style = {},
    containerStyle = {},
    contentStyle = {},
    customHeader,
    hideCloseCTA = false,
    footerStyle = {},
  } = props;
  const handleOnClose = (): void => {
    if (onClose) {
      onClose();
    }
  };
  return (
    <div
      className="integry-modal"
      style={style}
      onClick={() => {
        handleOnClose();
      }}
      aria-hidden="true"
    >
      <div
        className={`integry-modal__container ${className}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        aria-hidden="true"
        style={containerStyle}
      >
        {!hideCloseCTA && (
          <img
            className="integry-modal__close-cta"
            src={xIcon}
            alt="close_ico"
            onClick={() => {
              handleOnClose();
            }}
            aria-hidden="true"
          />
        )}
        {customHeader ? (
          <div className="integry-modal__container--header">{customHeader}</div>
        ) : (
          (title || subTitle) && (
            <div className="integry-modal__container--header">
              {title && <h1>{title}</h1>}
              {subTitle && <p>{subTitle}</p>}
            </div>
          )
        )}
        {children && (
          <div
            style={contentStyle}
            id={id}
            className="integry-modal__container--content"
          >
            {children}
          </div>
        )}
        {footer && (
          <div style={footerStyle} className="integry-modal__container--footer">
            {footer}
          </div>
        )}
      </div>
    </div>
  );
};

export default IntegryModal;
