/* eslint-disable @typescript-eslint/no-explicit-any */
// Redux toolkit Query to fetch actions. URL: https://beta.integry.io/api/v1/apps/209/templates/

import { createApi } from "@reduxjs/toolkit/query/react";
import { integryBaseQuery } from "utils/http";
import config from "utils/config";
import {
  Action,
  ActionListPaginated,
} from "components/turbo.v2/actions-container/types";
import { PaginatedUserAction } from "../interfaces";

export type AddActionParams = {
  bundleId: number | string;
  templates: string[] | number[];
  actionPayload: number | string;
};

export const actionsApi = createApi({
  reducerPath: "actionsApi",
  baseQuery: integryBaseQuery({
    baseUrl: config.apiBaseURL || "",
  }),
  tagTypes: ["actionsApi"],
  endpoints: (builder) => ({
    getActions: builder.mutation<
      ActionListPaginated,
      { searchText: string; page: number; version: string }
    >({
      query: ({ searchText, page, version }) => ({
        url: `/api/v1/actions/?page=${page}&search=${searchText}&page_size=${
          searchText ? 1000000 : 30 // adding a large page size to get all actions when searching
        }&${version}`,
        method: "get",
      }),
    }),
    addActions: builder.mutation<Action, AddActionParams>({
      query: ({ bundleId, templates, actionPayload }) => ({
        url: `/api/bundles/${bundleId}/add_actions/`,
        method: "post",
        data: {
          templates,
          bundle_tags: [],
          action_payload: actionPayload,
        },
      }),
    }),

    getUserActions: builder.mutation<
      PaginatedUserAction,
      {
        searchText;
        page;
        showArchived;
        tags: string[];
        apps: string[];
        ordering: string;
      }
    >({
      query: ({ searchText, page, showArchived, tags, apps, ordering }) => ({
        url: `/api/v1/cloned-actions/?page_size=100000&${
          showArchived ? "show_archived=true" : ""
        }&page=${page}&search=${searchText}&tags=${tags.join(
          ","
        )}&apps=${apps.join(",")}&ordering=${ordering}`,
        method: "get",
      }),
    }),
    archiveActions: builder.mutation<any, number[]>({
      query: (action_ids) => ({
        url: `/api/v1/cloned-actions/archive/`,
        method: "post",
        data: {
          action_ids,
        },
      }),
    }),
    restoreActions: builder.mutation<any, number[]>({
      query: (action_ids) => ({
        url: `/api/v1/cloned-actions/restore/`,
        method: "post",
        data: {
          action_ids,
        },
      }),
    }),
  }),
});

export const {
  useGetActionsMutation,
  useAddActionsMutation,
  useGetUserActionsMutation,
  useArchiveActionsMutation,
  useRestoreActionsMutation,
} = actionsApi;
