import { ReactElement, useEffect, useState } from "react";
import moment from "moment";
import IntegryLoader from "components/integry-loader";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import Tag from "components/integry-design-system/atoms/tag";
import IntegryPagination from "components/integry-design-system/molecules/pagination";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import http from "utils/http";

import exlaimationIconOutline from "images/exclaimation_icon_outline.svg";
import NotFoundIcon from "images/not_found_icon_lg.svg";
import SortIconDefault from "images/sort.svg";
import SortIconDesc from "images/sort-applied.svg";
import TruncateBullets from "images/truncate_bullets.svg";
import NoRunsFoundIcon from "images/no-runs-icon.svg";

import "components/integry-design-system/molecules/integry-table/styles.scss";
import "./styles.scss";

interface IProps {
  defaultApp: any;
  jobImportStatus?: any;
}

const DefaultAppActionsList = (props: IProps): ReactElement => {
  const { defaultApp, jobImportStatus } = props;
  const [actions, setActions] = useState([]);
  const [fetching, setFetchingActions] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState({
    page: 1,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState<any>({
    last_modified: "desc",
  });
  useEffect(() => {
    document.title = `Actions - App Settings - Integry`;
    setPage({
      page: 1,
      pageSize: 10,
    });
    fetchData({
      appId: defaultApp.id,
      pageConfig: {
        page: 1,
        pageSize: 10,
      },
      sortConfig: sorting,
    });
  }, [defaultApp.id, (jobImportStatus || { id: -1 }).id]);

  const fetchData = ({
    appId,
    pageConfig,
    sortConfig,
    skipFetching = false,
  }): void => {
    if (!skipFetching) {
      setFetchingActions(true);
    }
    let queryParams = ``;
    if (pageConfig) {
      if (!queryParams.startsWith("?")) {
        queryParams = `?page=${pageConfig.page}&page_size=${pageConfig.pageSize}`;
        // queryParams = `?page=${pageConfig.page}`;
      } else {
        queryParams = `${queryParams}&page=${pageConfig.page}&page_size=${pageConfig.pageSize}`;
        // queryParams = `${queryParams}&page=${pageConfig.page}`;
      }
    }
    if (Object.keys(sortConfig).length > 0) {
      const sortKey = Object.keys(sortConfig)[0];
      if (!queryParams.startsWith("?")) {
        queryParams = `?ordering=${
          sortConfig[sortKey] === "desc" ? "-" : ""
        }${sortKey}`;
      } else {
        queryParams = `${queryParams}&ordering=${
          sortConfig[sortKey] === "desc" ? "-" : ""
        }${sortKey}`;
      }
    }
    http
      .get(`/api/v1/apps/${appId}/activities/${queryParams}`)
      .then((res: any) => {
        const { data = {} } = res;
        const { results = [], count = 0, total_pages = 1 } = data;
        let tempActions = [];
        try {
          tempActions = results.map((action) => {
            const {
              method = { value: "get" },
              last_modified,
              template_count = 0,
            } = action;
            const last_modified_full = moment(last_modified).isValid()
              ? moment
                  .utc(last_modified)
                  .local()
                  .format("MMM DD, yyyy hh:mm:ss")
              : "N/A";
            const last_modified_short = moment(last_modified).isValid()
              ? moment.utc(last_modified).local().format("MMM DD, yyyy")
              : "N/A";
            return {
              ...action,
              actionName: {
                name: action.name,
                iconSrc: defaultApp.icon_url,
                verb: method ? (method.value || "GET").toUpperCase() : "GET",
              },
              last_modified: last_modified_short,
              last_modified_full,
              integrationsUsedTooltipValue: `Used in ${template_count} integrations`,
            };
          });
        } catch (e) {
          console.log({ e });
        }

        setActions(tempActions);
        setTotalCount(count);
        setTotalPage(total_pages);
      })
      .catch((e) => {
        setActions([]);
        setTotalCount(0);
        setTotalPage(1);
      })
      .finally(() => {
        setFetchingActions(false);
      });
  };
  const handleOnSorting = (id): void => {
    let tempSorting = {};
    tempSorting = {
      [id]: sorting[id] === "desc" ? "asc" : "desc",
    };
    setSorting(tempSorting);
    fetchData({
      appId: defaultApp.id,
      pageConfig: page,
      sortConfig: tempSorting,
    });
  };
  const handlePageChange = (pageConfig): void => {
    setPage(pageConfig);
    fetchData({
      appId: defaultApp.id,
      pageConfig,
      sortConfig: sorting,
    });
  };

  const renderTable = (): JSX.Element => {
    const offset = page.pageSize * page.page;
    return (
      <>
        <table className="integry-table">
          <thead>
            <tr>
              {tableConfig.config.map((configItem) => {
                if (configItem.tooltip) {
                  return (
                    <th>
                      {configItem.label}
                      <IntegryTooltip
                        tooltipText={configItem.tooltip}
                        placement="top"
                      >
                        <img
                          width={15}
                          height={15}
                          src={exlaimationIconOutline}
                          style={{
                            marginLeft: "5px",
                            marginTop: "-1px",
                            transform: "rotateZ(180deg)",
                          }}
                          alt="tooltip_heading"
                        />
                      </IntegryTooltip>
                    </th>
                  );
                }
                if (configItem.allowSorting) {
                  return (
                    <th>
                      {configItem.label}
                      <img
                        width={15}
                        height={15}
                        style={{
                          marginLeft: "5px",
                          marginTop: "-2px",
                          cursor: "pointer",
                        }}
                        src={
                          sorting[configItem.sortingIdentifier || configItem.id]
                            ? SortIconDesc
                            : SortIconDefault
                        }
                        className={
                          sorting[
                            configItem.sortingIdentifier || configItem.id
                          ] &&
                          sorting[
                            configItem.sortingIdentifier || configItem.id
                          ] === "asc"
                            ? "table-sort-icon-asc"
                            : ""
                        }
                        alt="sort_icon"
                        onClick={() => {
                          handleOnSorting(
                            configItem.sortingIdentifier || configItem.id
                          );
                        }}
                        aria-hidden="true"
                      />
                    </th>
                  );
                }
                return <th>{configItem.label}</th>;
              })}
              {fetching && <th>Loading...</th>}
              {!fetching && (
                <th>
                  {totalCount > 0 ? offset - (page.pageSize - 1) : 0}-
                  {offset > totalCount ? totalCount : offset} of {totalCount}
                </th>
              )}
            </tr>
          </thead>
          {actions.length > 0 && (
            <tbody
              style={
                !fetching
                  ? {}
                  : {
                      opacity: "0.5",
                    }
              }
            >
              {actions.map((action: any) => {
                return (
                  <tr>
                    {tableConfig.config.map((configItem: any) => {
                      const {
                        id,
                        subConfig,
                        type = "text",
                        tdStyle = {},
                        showTooltip = false,
                        tooltipField = "",
                        hideSameValueTooltip = false,
                      } = configItem;
                      if (type === "code") {
                        const codeLabel =
                          statusLabels[action[id]] ||
                          networkCodeLabels[action[id]] ||
                          action[id];
                        const codeColor =
                          networkCodeColors[
                            `${action[id] || "N/A"}`.substring(0, 1)
                          ] || "#333333";
                        return (
                          <td>
                            <div className="integry-table--column">
                              <IntegryTooltip
                                hideToolTip={
                                  !showTooltip ||
                                  (!networkCodeDescriptionLabels[action[id]] &&
                                    action[tooltipField] === "N/A")
                                }
                                tooltipText={
                                  networkCodeDescriptionLabels[action[id]] ||
                                  action[tooltipField] ||
                                  ""
                                }
                                placement="bottom"
                              >
                                <span
                                  style={{
                                    color: codeColor,
                                  }}
                                >
                                  {codeLabel}
                                </span>
                              </IntegryTooltip>
                            </div>
                          </td>
                        );
                      }
                      if (type === "tag") {
                        const tagLabel = statusLabels[action[id]] ||
                          networkCodeLabels[action[id]] || [action[id]];
                        return (
                          <td>
                            <div className="integry-table--column">
                              <Tag label={tagLabel} type="run" />
                            </div>
                          </td>
                        );
                      }
                      if (configItem.truncate) {
                        return (
                          <td>
                            <div
                              className="integry-table--column"
                              style={tdStyle}
                            >
                              {action[id].length > 20 ? (
                                <IntegryTooltip
                                  tooltipText={action[id]}
                                  placement="bottom"
                                >
                                  <div>
                                    {action[id].substring(0, 3)}
                                    <img
                                      src={TruncateBullets}
                                      style={{
                                        width: "15px",
                                        margin: "0 2px",
                                        marginTop: "-3px",
                                        objectFit: "none",
                                      }}
                                      alt="truncate_bullets"
                                    />
                                    {action[id].substring(
                                      action[id].length - 6
                                    )}
                                  </div>
                                </IntegryTooltip>
                              ) : (
                                `${action[id]}`
                              )}
                            </div>
                          </td>
                        );
                      }
                      return (
                        <td>
                          <div
                            className="integry-table--column"
                            style={tdStyle}
                          >
                            <IntegryTooltip
                              hideToolTip={
                                !showTooltip ||
                                action[tooltipField] === "N/A" ||
                                (hideSameValueTooltip &&
                                  action[id] === action[tooltipField])
                              }
                              tooltipText={action[tooltipField] || ""}
                              placement="bottom"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                }}
                              >
                                {!subConfig &&
                                  (statusLabels[action[id]] ||
                                    networkCodeLabels[action[id]] || [
                                      action[id],
                                    ])}
                                {subConfig &&
                                  subConfig.map((subItem) => {
                                    if (subItem.type === "img") {
                                      return (
                                        <img
                                          src={action[id][subItem.id]}
                                          alt={`ico_${action[id][subItem.id]}`}
                                        />
                                      );
                                    }
                                    if (subItem.type === "tag") {
                                      const tagLabel =
                                        statusLabels[action[id][subItem.id]] ||
                                        networkCodeLabels[
                                          action[id][subItem.id]
                                        ] ||
                                        action[id][subItem.id];
                                      return (
                                        <Tag label={tagLabel} type="run" />
                                      );
                                    }
                                    return (
                                      <span>{action[id][subItem.id]}</span>
                                    );
                                  })}
                              </div>
                            </IntegryTooltip>
                          </div>
                        </td>
                      );
                    })}
                    <td
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto auto",
                        gridGap: "11px",
                      }}
                    >
                      {/* <InterButton
                        variant="outlined"
                        size="small"
                        isDisabled={!action.hasSteps}
                        style={{
                          width: "48px",
                          height: "25px",
                          minWidth: "48px",
                          padding: "5px",
                        }}
                      >
                        View
                      </InterButton> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        {!fetching && actions.length < 1 && (
          <div className="view-record-not-found__placeholder">
            {/* {!integrationConfig?.hasRuns ? (
              <img src={NoRunsFoundIcon} alt="record_not_found" />
            ) : (
              <img src={NotFoundIcon} alt="record_not_found" />
            )} */}
            <img src={NoRunsFoundIcon} alt="record_not_found" />
          </div>
        )}
        {!fetching && actions.length > 0 && (
          <IntegryPagination
            defaultCurrentPage={page.page}
            defaultPageSize={page.pageSize}
            total={totalCount}
            customStyle={{ marginTop: "20px" }}
            onPageChange={(pageConfig) => {
              handlePageChange(pageConfig);
            }}
            onPageSizeChange={(pageConfig) => {
              handlePageChange(pageConfig);
            }}
          />
        )}
        {fetching && <IntegryLoader />}
      </>
    );
  };
  console.log({
    actions,
  });
  return (
    <div className="default-app-actions">
      <div>
        {fetching && <IntegryLoader />}
        {!fetching && <div>{renderTable()}</div>}
      </div>
    </div>
  );
};

export default DefaultAppActionsList;

const tableConfig = {
  config: [
    {
      id: "actionName",
      tdStyle: {
        whiteSpace: "break-spaces",
        minWidth: "400px",
      },
      subConfig: [
        {
          id: "iconSrc",
          type: "img",
        },
        {
          id: "name",
        },
        {
          id: "verb",
          type: "tag",
          tooltip: (
            <div dangerouslySetInnerHTML={{ __html: "tooltip with URL" }} />
          ),
        },
      ],
      label: "Name",
    },
    {
      id: "template_count",
      label: "Integrations",
      tooltip: "No. of integraitons",
      showTooltip: true,
      tooltipField: "integrationsUsedTooltipValue",
    },
    {
      id: "last_modified",
      label: "Last Updated",
      allowSorting: true,
      sortingIdentifier: "modified",
      showTooltip: true,
      tooltipField: "last_modified_full",
      hideSameValueTooltip: true,
      // tdStyle: {
      //   whiteSpace: "break-spaces",
      //   minWidth: "200px",
      // },
    },
  ],
};

export const statusLabels = {
  all: "All",
  ok: "OK",
  failed: "Failed",
  running: "Running",
  completed: "Completed",
  filtered: "Filtered",
  FILTERED: "Filtered",
  COMPLETED: "Completed",
};

export const networkCodeLabels = {
  all: "All",
  n_a: "N/A",
  Loop: "200",
  loop: "200",
};

export const networkCodeDescriptionLabels = {
  Loop: "OK",
  loop: "OK",
};

export const networkCodeColors = {
  "2": "#749D48",
  "3": "#FFFAE2",
  "4": "#F05C42",
  "5": "#F05C42",
  N: "#666666",
  n: "#666666",
  l: "#749D48",
  L: "#749D48",
};
