import { createApi } from "@reduxjs/toolkit/query/react";
import { User } from "types/integry-user";
import config from "utils/config";
import { integryBaseQuery } from "utils/http";

export const userApi = createApi({
  reducerPath: "user",
  baseQuery: integryBaseQuery({
    baseUrl: config.apiBaseURL || "",
  }),
  tagTypes: ["user"],
  endpoints(build) {
    return {
      fetchUser: build.query<User, string | void>({
        query: (workspace_app_id = "") => ({
          url: `/accounts/me/?workspace_app_id=${workspace_app_id}`,
          method: "get",
        }),
        serializeQueryArgs: ({ endpointName, queryArgs }) => {
          return `${endpointName}-${queryArgs}`;
        },
        forceRefetch({ currentArg, previousArg }) {
          return currentArg !== previousArg;
        },
        providesTags: ["user"],
      }),
      updateUserCompanyInfo: build.mutation({
        query(data: Pick<User, "account">) {
          return {
            url: "/accounts/me/",
            method: "PUT",
            data,
          };
        },
        invalidatesTags: ["user"],
      }),
      changeWorkspace: build.mutation({
        query({ accountId }) {
          return {
            url: "/accounts/select-workspace/?no_redirect=true ",
            method: "POST",
            data: { account_id: accountId },
          };
        },
      }),
    };
  },
});

export const {
  useFetchUserQuery,
  useUpdateUserCompanyInfoMutation,
  useChangeWorkspaceMutation,
} = userApi;
