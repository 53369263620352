/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types";
import Select from "react-select";

import "./styles.scss";

const InputField = (props) => {
  const {
    id = "-1",
    value = "",
    customStyle = {},
    label = "",
    error,
    options = [],
    onChange,
    placeholder = "",
    isRequired = false,
    isLoading = false,
  } = props;
  const getLabelFromOptions = (valueToFind) => {
    let tempLabel = valueToFind;
    options.forEach((option) => {
      if (option.value) {
        if (option.value === valueToFind) {
          tempLabel = option.label || option.value;
        }
      }
    });
    return tempLabel;
  };
  return (
    <div
      className={`turbo-input-field${
        error && error !== "" ? " error-field" : ""
      }`}
    >
      <p>
        {label}
        {isRequired && (
          <span className="turbo-input-field__required-sterik">*</span>
        )}
      </p>
      {isLoading ? (
        <div className="placeholder" style={{ height: "50px" }}>
          <div className="animated-background" />
        </div>
      ) : options.length > 0 ? (
        <Select
          className="turbo-input-field__select"
          classNamePrefix="select"
          value={
            value && value !== ""
              ? { value, label: getLabelFromOptions(value) }
              : null
          }
          placeholder={placeholder}
          isClearable={false}
          isSearchable
          name={id}
          options={options.map((option) => ({
            value: option.value,
            label: option.label,
          }))}
          onChange={(e) => {
            if (onChange) {
              onChange({
                target: {
                  value: e.value,
                },
              });
            }
          }}
        />
      ) : (
        <input style={customStyle} value={value} {...props} />
      )}
      {error && <p className="turbo-input-field__error-msg">{error}</p>}
    </div>
  );
};

InputField.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  customStyle: PropTypes.any,
  label: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  isLoading: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default InputField;
