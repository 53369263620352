import { ReactElement, useRef } from "react";
import { FeatureFlag } from "react-launch-darkly";
import Button from "components/common/reusable-button";
import TextField from "components/forms/v4/text-field";
import { Formik, Form, FormikProps } from "formik";
import { useSendSampleNotificationMutation } from "utils/accounts-api";

import "./notification-url.scss";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";

export interface NotificationUrlProps {
  onSave?: (notificationSettings) => void;
  isSavingUrl?: boolean;
  notificationSettings?: {
    error_notification_url: string;
  };
}

const NotificationUrl = (props: NotificationUrlProps): ReactElement => {
  const { onSave, notificationSettings, isSavingUrl } = props;
  const formikRef = useRef<
    FormikProps<{
      error_notification_url: string;
    }>
  >();
  const [sendSampleNotificaion, { isLoading: sendingSampleNotification }] =
    useSendSampleNotificationMutation();

  const handleSubmitt = async (values): Promise<void> => {
    const { error_notification_url } = values;
    if (error_notification_url) {
      try {
        await sendSampleNotificaion(error_notification_url).unwrap();
        if (onSave) {
          onSave(values);
          formikRef.current?.resetForm({ values });
        }
      } catch (error) {
        const { status } = error as { status: number };
        formikRef.current?.setErrors({
          error_notification_url: `Invalid Webhook URL. Please enter a valid URL and save again. ${
            status ? `Status Code: ${status}` : ""
          }`,
        });
      }
    } else if (onSave) {
      onSave(values);
    }
  };
  return (
    <Formik
      initialValues={notificationSettings || { error_notification_url: "" }}
      validate={(values) => {
        let errors;
        if (
          !/(?!.*[*[]$)^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/.test(
            values?.error_notification_url
          ) &&
          !!values?.error_notification_url
        ) {
          errors = { error_notification_url: "Please enter a valid URL" };
        }
        return errors;
      }}
      onSubmit={handleSubmitt}
    >
      {(formik) => {
        formikRef.current = formik;
        return (
          <Form>
            <div className="url-settings-container">
              <FeatureFlag
                flagKey="global-notifications"
                renderFeatureCallback={() => (
                  <h3 className="error-section-heading">
                    Receive run aborted notifications on a webhook
                  </h3>
                )}
                renderDefaultCallback={() => (
                  <h3 className="error-section-heading">
                    Send to a{" "}
                    <IntegryTooltip tooltipText="Receive run aborted notifications on this webhook URL">
                      <span className="underline">URL</span>
                    </IntegryTooltip>
                  </h3>
                )}
              />
              <p className="notifications-url"> URL </p>

              <TextField
                className="notifications-input"
                name="error_notification_url"
                placeholder="Enter URL in https format (e.g. https://website.com)"
              />

              <Button
                className="save-button"
                type="button"
                isLoading={sendingSampleNotification || isSavingUrl}
                disabled={
                  !!formik.errors.error_notification_url ||
                  sendingSampleNotification ||
                  isSavingUrl ||
                  !formik.dirty
                }
                title={<span className="preview-btn-title">Save</span>}
                onClick={() => {
                  formik.submitForm();
                }}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default NotificationUrl;
