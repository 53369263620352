/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import InputField from "components/turbo.v1/common/InputField";

import http from "utils/http";

import "./styles.scss";
import lang from "utils/lang/en";

const DemographicForm = (props) => {
  const { onSubmit } = props;
  const [formData, setFormData] = useState({});
  const [error, setError] = useState({});
  const [metaData, setMetaData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const isValidURL = (urlPath) => {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    return regex.test(urlPath);
  };
  const validate = () => {
    let isValid = true;
    const requiredFields = ["role", "category", "companySize"];
    let tempError = {};
    requiredFields.forEach((id) => {
      if (!formData[id]) {
        tempError = {
          ...tempError,
          [id]: lang.ERROR_MISSING_REQUIRED_FIELD,
        };
        if (isValid) {
          isValid = false;
        }
      }
    });
    if (formData.companyLink) {
      if (!isValidURL(formData.companyLink)) {
        if (isValid) {
          isValid = false;
        }
        tempError = {
          ...tempError,
          companyLink: lang.ERROR_VALID_URL,
        };
      } else {
        tempError = {
          ...tempError,
          companyLink: null,
        };
      }
    }
    setError(tempError);
    return isValid;
  };
  const handleFormData = ({ id = "-1", value = "" }) => {
    setFormData({
      ...formData,
      [id]: value,
    });
  };
  const handleSubmitFormdata = () => {
    if (validate() && onSubmit) {
      onSubmit(formData);
    }
  };
  const getMetaData = () => {
    setIsLoading(true);
    http
      .get("/update_user_details")
      .then((res) => {
        const { data = {} } = res;
        let tempMetaData = {};
        Object.keys(data).forEach((key) => {
          if ((data[key] || []).length > 0) {
            tempMetaData = {
              ...tempMetaData,
              [key]: data[key].map((item) => {
                const { display = "", value = "" } = item;
                return {
                  label: display,
                  value,
                  id: `${value}_${key}`,
                };
              }),
            };
          }
        });
        setMetaData(tempMetaData);
      })
      .catch((e) => {
        console.log({
          e,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const { formData: formDataInit } = props;
    if (formDataInit) {
      setFormData({
        ...formDataInit,
      });
    }
    getMetaData();
  }, []);

  return (
    <div className="demographic-form">
      <InputField
        id="role"
        placeholder={lang.SELECT_A_ROLE}
        label={`${lang.SELECT_ROLE}`}
        isRequired
        value={formData.role || ""}
        error={error.role}
        options={metaData.role}
        isLoading={isLoading}
        onChange={(e) => {
          if (e) {
            const { value } = e.target;
            handleFormData({
              id: "role",
              value,
            });
          }
        }}
      />
      <InputField
        id="category"
        placeholder={lang.CATEGORY}
        label={`${lang.PLATFORM_TYPE}`}
        isRequired
        value={formData.category || ""}
        error={error.category}
        options={metaData.platform_type}
        isLoading={isLoading}
        onChange={(e) => {
          if (e) {
            const { value } = e.target;
            handleFormData({
              id: "category",
              value,
            });
          }
        }}
      />
      <InputField
        id="companySize"
        placeholder={lang.COMAPNY_SIZE_PLACEHOLDER}
        label={`${lang.COMPANY_SIZE}`}
        isRequired
        value={formData.companySize || ""}
        error={error.companySize}
        options={metaData.company_size}
        isLoading={isLoading}
        onChange={(e) => {
          if (e) {
            const { value } = e.target;
            handleFormData({
              id: "companySize",
              value,
            });
          }
        }}
      />
      <InputField
        id="companyLink"
        placeholder="https://"
        label={`${lang.COMPANY_LINK}`}
        value={formData.companyLink || ""}
        error={error.companyLink}
        onChange={(e) => {
          if (e) {
            const { value } = e.target;
            handleFormData({
              id: "companyLink",
              value,
            });
          }
        }}
      />
      {/* <InputField
        id="promoCode"
        label={lang.HAVE_A_PROMO_CODE}
        placeholder={`${lang.ENTER_PROMO_CODE}`}
        value={formData.promoCode || ""}
        error={error.promoCode}
        onChange={(e) => {
          if (e) {
            const { value } = e.target;
            handleFormData({
              id: "promoCode",
              value,
            });
          }
        }}
      /> */}
      <button
        className="turbo-btn btn-block"
        type="button"
        onClick={() => {
          handleSubmitFormdata();
        }}
      >
        Continue
      </button>
    </div>
  );
};

DemographicForm.propTypes = {
  onSubmit: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  formData: PropTypes.any,
};

export default DemographicForm;
