import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { BaseQueryFn } from "@reduxjs/toolkit/query";

import { show404Page } from "store/actions/toggle-404-page";

import config from "./config";
import automatedTestCreds from "./user-creds.json";

// import automatedTestCreds from "./user-creds-existing-user-test.json";

const axiosOptions: AxiosRequestConfig = {
  baseURL: config.apiBaseURL,
};

const isLocal = process.env.NODE_ENV !== "production";

const { pathname } = window.location;

let store;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const injectStore = (_store): any => {
  store = _store;
};

const liteTemplateCheck = pathname
  .substring(pathname.length - 5, pathname.length)
  .includes("lite");

if (config.useHttpBasicAuth) {
  // axiosOptions.auth = config.httpAuth;
  console.log("CYPRESS LOG - http", {
    REACT_APP_CYPRESS: process.env.REACT_APP_CYPRESS,
  });
  // axiosOptions.auth = process.env.REACT_APP_CYPRESS
  //   ? automatedTestCreds
  //   : config.httpAuth;
  axiosOptions.auth = isLocal
    ? automatedTestCreds
    : { username: "", password: "" };
} else {
  axiosOptions.xsrfCookieName = "csrftoken";
  axiosOptions.xsrfHeaderName = "X-CSRFToken";
}

const existingParams = window.location.search;

// If Lite templateBuilder then add query params in api calls
if (existingParams && liteTemplateCheck) {
  let params = existingParams.substring(1);
  params = `{"${params.replace(/&/g, '","').replace(/=/g, '":"')}"}`;
  try {
    const allPrams = JSON.parse(params);
    const paramsParsed = {
      user_id: allPrams.user_id,
      app_key: allPrams.app_key,
      hash: allPrams.hash,
    };
    axiosOptions.params = paramsParsed;
    axiosOptions.withCredentials = false;
  } catch (error) {
    console.log(error);
  }
}

const http = axios.create(axiosOptions);

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      (error.response.status === 403 || error.response.status === 404) &&
      !liteTemplateCheck
    ) {
      const request_config = error.response?.config;
      const request_url = request_config.url;
      const isSignedRequest =
        request_url.includes("user_id=") &&
        request_url.includes("app_key=") &&
        request_url.includes("hash=");
      if (!isSignedRequest) {
        if (error.response.status === 403) {
          // If request is not signed and user is not authenticated then redirect user to login screen
          window.location.href = config.getLoginUrl();
        }
        if (error.response.status === 404) {
          // If request is not signed and user dont have access to the resource they are trying to access then redirect user to 404 screen
          store.dispatch(show404Page());
          // window.open(`/wapp${urls.notFound}`, "_self");
        }
      }
    }
    return Promise.reject(error);
  }
);

export const integryBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data }) => {
    try {
      const result = await http({ url: baseUrl + url, method, data });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };

export default http;
