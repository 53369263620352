import http from "utils/http";
import { AxiosPromise } from "axios";

export const createActionPayload = (
  name: string,
  description: string,
  payload: string | null
): AxiosPromise => {
  return http.post("/api/v1/action-payloads/", {
    name,
    description,
    payload,
  });
};
export const updateActionPayload = (
  id: number,
  name: string,
  description: string,
  payload: string | null
): AxiosPromise => {
  return http.put(`/api/v1/action-payloads/${id}/`, {
    name,
    description,
    payload,
  });
};
export const deleteActionPayload = (id: number): AxiosPromise => {
  return http.delete(`/api/v1/action-payloads/${id}/`);
};
export const listActionPayloads = (search: string, sortOrder): AxiosPromise => {
  let queryParams = "";
  if (search) {
    queryParams = `?search=${search}`;
  }
  if (Object.keys(sortOrder).length > 0) {
    const sortKey = Object.keys(sortOrder)[0];
    if (!queryParams.startsWith("?")) {
      queryParams = `?order_by=${
        sortOrder[sortKey] === "desc" ? "-" : ""
      }${sortKey}`;
    } else {
      queryParams = `${queryParams}&order_by=${
        sortOrder[sortKey] === "desc" ? "-" : ""
      }${sortKey}`;
    }
  }
  const uri = `/api/v1/action-payloads/${queryParams}`;
  return http.get(uri);
};
