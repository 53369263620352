import http from "../../utils/http";

export const REQUEST_AUTHORIZATION_TYPES = "REQUEST_AUTHORIZATION_TYPES";

export const requestAuthorizationTypes = (app_id) => ({
  type: REQUEST_AUTHORIZATION_TYPES,
  app_id,
});

export const RECEIVE_AUTHORIZATION_TYPES = "RECEIVE_AUTHORIZATION_TYPES";

export const receiveAuthorizationTypes = (app_id, json) => ({
  type: RECEIVE_AUTHORIZATION_TYPES,
  app_id,
  authorization_types: json,
});

export const fetchAuthorizationTypes = (app_id) => {
  return (dispatch) => {
    dispatch(requestAuthorizationTypes(app_id));

    return http.get(`/api/authorization_types/?app=${app_id}`).then(
      (response) => {
        dispatch(receiveAuthorizationTypes(app_id, response.data));
      },
      (error) => {
        console.log(
          "Error occurred while fetching authorization types in fetchAuthorizationTypes() action.",
          error
        );
      }
    );
  };
};

export const REMOVE_AUTHORIZATION_TYPE = "REMOVE_AUTHORIZATION_TYPE";

export const removeAuthorizationType = (app_id, authorization_type_id) => ({
  type: REMOVE_AUTHORIZATION_TYPE,
  app_id,
  authorization_type_id,
});
