import cloneDeep from "lodash/cloneDeep";

import {
  Field,
  Template,
  StepPath,
} from "features/templates/template-builder-v7/services/template-builder-interface";
import { getNode, replaceNode } from "utils/tree";

export const getStepFiledDefaultValue = (
  fields: Field[],
  fieldMachineName: string
): string => {
  const field = fields.find(
    (f) =>
      (f.activity_field_machine_name || f.machine_name) === fieldMachineName
  );
  return field?.default_value || "";
};

export const addFieldInTemplateStep = ({
  template,
  path,
  field,
  fieldIndex,
}: {
  template: Template;
  path: StepPath;
  field: Field;
  fieldIndex?: number;
}): Template => {
  const oldTemplate = cloneDeep(template);
  const oldStep = getNode(oldTemplate, "steps", path);

  let newFields: Field[] = [];

  if (fieldIndex !== undefined) {
    newFields = oldStep.fields.map((f, index) => {
      if (index === fieldIndex) {
        return {
          ...f,
          fields: [...f.fields, field],
        };
      }
      return f;
    });
  } else {
    newFields = [...oldStep.fields, field];
  }

  console.log("newFields", newFields);

  const newStep = {
    ...oldStep,
    fields: newFields,
  };
  const newTemplate = replaceNode(oldTemplate, "steps", path, newStep);
  return newTemplate as Template;
};

export const removeFieldFromTemplateStep = (
  template: Template,
  path: StepPath,
  fieldIndex: number,
  childFieldIndex?: number
): Template => {
  const oldTemplate = cloneDeep(template);
  const oldStep = getNode(oldTemplate, "steps", path);
  let newFields: Field[] = [];

  if (childFieldIndex !== undefined) {
    newFields = oldStep.fields.map((f, index) => {
      if (index === fieldIndex) {
        return {
          ...f,
          fields: f.fields.filter((_, i) => i !== childFieldIndex),
        };
      }
      return f;
    });
  } else {
    newFields = oldStep.fields.filter((_, index) => index !== fieldIndex);
  }

  const newStep = {
    ...oldStep,
    fields: newFields,
  };
  const newTemplate = replaceNode(oldTemplate, "steps", path, newStep);
  return newTemplate as Template;
};
