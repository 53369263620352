/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */

export enum ActivityType {
  Trigger = "TRIGGER",
  Query = "QUERY",
  Action = "ACTION",
  Condition_If = "CONDITION_IF",
  Condition_Else_If = "CONDITION_ELSEIF",
  Condition_Else = "CONDITION_ELSE",
  Setup = "SETUP",
  Loop = "LOOP",
  DoWhile_Loop = "DO_WHILE_LOOP", // Replace with the actual value from config if needed
  Variable = "VARIABLE",
  Code = "CODE",
  Schedule = "SCHEDULE",
  Make_Http_Call = "MAKE_HTTP_CALL",
  Notification = "NOTIFICATION",
  External_Action = "EXTERNAL_ACTION", // Replace with the actual value from config if needed
  Return = "RETURN",
  System_Activity = "SYSTEM_ACTIVITY",
}
