import { decodeJSON } from "utils/functions";
import http from "../../utils/http";

export const REQUEST_ONBOARDING_STATE = "REQUEST_ONBOARDING_STATE ";
export const RECEIVE_ONBOARDING_STATE = "RECEIVE_ONBOARDING_STATE ";
export const CLEAR_CONNECTOR_STATE = "CLEAR_CONNECTOR_STATE";
export const UPDATE_ONBOARDING_STATE = "UPDATE_ONBOARDING_STATE";

export const requestonboardingState = () => ({
  type: REQUEST_ONBOARDING_STATE,
});

export const receiveonboardingState = (json) => ({
  type: RECEIVE_ONBOARDING_STATE,
  ONBOARDING_STATE: json,
});

// this is probably achievable with the action above
// dangerous because it can overwrite existing keys
export const dangerouslyUpdateOnboardingState = (json) => ({
  type: UPDATE_ONBOARDING_STATE,
  ONBOARDING_STATE: json,
});

export const clearConnectorState = () => ({
  type: CLEAR_CONNECTOR_STATE,
});

export const fetchOnboardingState = () => (dispatch) => {
  dispatch(requestonboardingState());
  http.get("/user_interactions/").then((response) => {
    const initialFlowCompleted = response.data.initial_flow_completed || false;
    const onboardingState = decodeJSON(response.data.onboarding_state);
    dispatch(
      receiveonboardingState({
        ...onboardingState,
        initialFlowCompleted,
        isFetching: false,
      })
    );
  });
};

export const updateOnboardingState = (state) => (dispatch, getState) => {
  dispatch(requestonboardingState());
  const payload = {
    ...getState().onboardingState,
    ...state,
  };
  try {
    const stringifyPayload = { onboarding_state: JSON.stringify(payload) };
    http.put("/user_interactions/", stringifyPayload).then((response) => {
      const onboardingState = decodeJSON(response.data.onboarding_state);
      dispatch(receiveonboardingState(onboardingState));
    });
  } catch (e) {
    console.log(e);
  }
};
