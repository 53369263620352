import http from "../../utils/http";

export const REQUEST_ACTIVITIES = "REQUEST_ACTIVITIES";

export const requestActivities = (app_id, activity_type) => ({
  type: REQUEST_ACTIVITIES,
  app_id,
  activity_type,
});

export const RECEIVE_ACTIVITIES = "RECEIVE_ACTIVITIES";

export const receiveActivities = (app_id, activity_type, json) => ({
  type: RECEIVE_ACTIVITIES,
  activities: json,
  receivedAt: Date.now(),
  app_id,
  activity_type,
});

export const fetchActivities = (
  app_id,
  activity_type,
  includeWhitelabelledAuthTypes = false
) => {
  return (dispatch) => {
    dispatch(requestActivities(app_id, activity_type));
    if (app_id) {
      const whitelabelledAuthTypesFlag = includeWhitelabelledAuthTypes
        ? "&include_whitelabelled_auth_types=true"
        : "";
      const activityType =
        activity_type === "all" ? "" : `&type=${activity_type}`;
      const apiUrl = `/api/activities/?app=${app_id}${activityType}${whitelabelledAuthTypesFlag}`;

      return http.get(apiUrl).then(
        (response) => {
          dispatch(receiveActivities(app_id, activity_type, response.data));
        },
        (error) => {
          console.log(
            "Error occurred while fetching activities in fetchActivities() action.",
            error
          );
        }
      );
    } else return {};
  };
};

function shouldFetchActivities(state, app_id, activity_type) {
  const activities =
    state.activitiesByApp &&
    state.activitiesByApp[app_id] &&
    state.activitiesByApp[app_id][activity_type]
      ? state.activitiesByApp[app_id][activity_type]
      : null;
  if (
    activity_type === "SETUP" ||
    activity_type === "LOOP" ||
    activity_type === "CONDITION_IF" ||
    activity_type === "CONDITION_ELSEIF" ||
    activity_type === "CONDITION_ELSE" ||
    activity_type === "SCHEDULE"
  ) {
    return false;
  }
  if (!activities) {
    return true;
  } else if (activities.isFetching) {
    return false;
  } else {
    /* const validityPeriod = 10000; // in milliseconds: 10 seconds
    return Date.now() - activities.lastUpdated > validityPeriod; // was last fetched more than 10 seconds ago */
    return false;
  }
}

export function fetchActivitiesIfNeeded(
  app_id,
  activity_type,
  includeWhitelabelledAuthTypes = false
) {
  return (dispatch, getState) => {
    if (shouldFetchActivities(getState(), app_id, activity_type)) {
      return dispatch(
        fetchActivities(app_id, activity_type, includeWhitelabelledAuthTypes)
      );
    } else {
      return Promise.resolve("already exist");
    }
  };
}

export const REMOVE_ACTIVITY = "REMOVE_ACTIVITY";

export const removeActivity = (app_id, activity_type, activity_id) => ({
  type: REMOVE_ACTIVITY,
  app_id,
  activity_type,
  activity_id,
});

export const RESET_ACTIVITIES = "RESET_ACTIVITIES";
export const resetActivities = () => ({
  type: RESET_ACTIVITIES,
});
