/* eslint-disable no-use-before-define */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { InlineWidget } from "react-calendly";
// import axios from "axios";
import { FeatureFlag } from "react-launch-darkly";

import lang from "utils/lang/en";
import config from "utils/config";
import http from "utils/http";
import { track, turboMixpanelEvents } from "utils/mixpanel";

import "./styles.scss";

const CalendlyWidget = ({
  onMeetingScheduled,
  onMeetingScheduleSkipped,
  user = {},
  stretchWithinEle = false,
  title,
  description,
  customStyle = {},
  sourcePage = "Not defined",
}) => {
  const [meetingScheduled, setMeetingScheduled] = useState(false);
  const [meetingMetaConfig, setMeetingMetaConfig] = useState({});
  const isCalendlyEvent = (e) =>
    e.origin === "https://calendly.com" &&
    e.data.event &&
    e.data.event.indexOf("calendly.") === 0;

  useEffect(() => {
    window.addEventListener("message", (e) => {
      if (isCalendlyEvent(e)) {
        /* Example to get the name of the event */
        // REDIRECT ON calendly.event_scheduled
        // console.log("Event name:", e.data.event);

        /* Example to get the payload of the event */
        // console.log("Event details:", e.data.payload);
        if (e.data.event === "calendly.event_scheduled") {
          const { payload } = e.data;
          // const { event } = payload;
          // eslint-disable-next-line no-use-before-define
          // getMeetingDetails(event.uri, payload);
          trackScheduleCallMixpanelEvent(payload);
          handleUserInteraction(payload);
          if (onMeetingScheduled) {
            setMeetingScheduled(true);
          }
        }
      }
    });
    // initMeetingMeta();
  }, []);
  // const initMeetingMeta = () => {
  //   try {
  //     const meetingDetailsObj = JSON.parse(
  //       localStorage.getItem("MEETING_META") || "{}"
  //     );
  //     const meetingDetails = meetingDetailsObj[user.email] || {};
  //     const { payload = {} } = meetingDetails;
  //     if (payload.event) {
  //       getMeetingDetails(payload.event.uri, payload, false);
  //       getMeetingStatus({ ...payload, ...meetingDetails });
  //     }
  //   } catch (e) {
  //     console.error("ERROR GETTING MEETING META DETAILS", e);
  //   }
  // };
  // eslint-disable-next-line no-unused-vars
  const trackScheduleCallMixpanelEvent = ({ invitee }, callTimeStamp) => {
    const inviteeSplitted = invitee.uri.split("/");
    const inviteeUUID = inviteeSplitted[inviteeSplitted.length - 1];
    if (callTimeStamp) {
      track(turboMixpanelEvents.SCHEDULE_ONBOARDING_CALL.name, user, {
        [turboMixpanelEvents.SCHEDULE_ONBOARDING_CALL.property.SOURCE_PAGE]:
          sourcePage,
        [turboMixpanelEvents.SCHEDULE_ONBOARDING_CALL.property
          .MEETING_STATUS]: `${config.calendlyMeetingLink}/invitees/${inviteeUUID}`,
        [turboMixpanelEvents.SCHEDULE_ONBOARDING_CALL.property.CALL_TIMESTAMP]:
          callTimeStamp,
      });
    } else {
      track(turboMixpanelEvents.SCHEDULE_ONBOARDING_CALL.name, user, {
        [turboMixpanelEvents.SCHEDULE_ONBOARDING_CALL.property.SOURCE_PAGE]:
          sourcePage,
        [turboMixpanelEvents.SCHEDULE_ONBOARDING_CALL.property
          .MEETING_STATUS]: `${config.calendlyMeetingLink}/invitees/${inviteeUUID}`,
      });
    }
  };
  // eslint-disable-next-line no-unused-vars
  const handleMeetingMetaInfo = (meetingConfig) => {
    let meetingMeta = {};
    try {
      meetingMeta = JSON.parse(localStorage.getItem("MEETING_META"));
    } catch (e) {
      console.error("ERROR GETTING MEETING META DETAILS", e);
    }
    localStorage.setItem(
      "MEETING_META",
      JSON.stringify({ ...meetingMeta, [user.email]: meetingConfig })
    );
  };

  const handleUserInteraction = (payload) => {
    const { event = { uri: "" }, invitee = { uri: "" } } = payload;
    const splittedEventURI = event.uri.split("/");
    const splittedInviteeURI = invitee.uri.split("/");
    const eventUUID = splittedEventURI[splittedEventURI.length - 1];
    const inviteeUUID = splittedInviteeURI[splittedInviteeURI.length - 1];
    http
      .put("/user_interactions/", {
        event_uuid: eventUUID,
        invitee_uuid: inviteeUUID,
      })
      .catch((e) => {
        console.error("ERROR while updating calendly details", e);
      });
  };
  // const getMeetingDetails = (eventURI, payload, trackEvent = true) => {
  //   axios
  //     .get(eventURI, {
  //       headers: {
  //         Authorization: `Bearer ${process.env.REACT_APP_CALENDLY_ACCESS_TOKEN}`,
  //         "Content-Type": "application/json",
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //     })
  //     .then((res) => {
  //       const { data = {} } = res;
  //       const { resource = {} } = data;
  //       handleMeetingMetaInfo({ data, payload });
  //       if (trackEvent) {
  //         if (resource.start_time) {
  //           trackScheduleCallMixpanelEvent(payload, resource.start_time);
  //         } else {
  //           trackScheduleCallMixpanelEvent(payload);
  //         }
  //       }
  //     })
  //     .catch((e) => {
  //       console.error("ERROR WHILE FETCHING CALENDLY MEETING DETAILS", { e });
  //       if (trackEvent) {
  //         trackScheduleCallMixpanelEvent(payload);
  //       }
  //     });
  // };

  // const getMeetingStatus = (meetingConfig) => {
  //   const { invitee = {} } = meetingConfig;
  //   if (invitee.uri) {
  //     axios
  //       .get(invitee.uri, {
  //         headers: {
  //           Authorization: `Bearer ${process.env.REACT_APP_CALENDLY_ACCESS_TOKEN}`,
  //           "Content-Type": "application/json",
  //           "Access-Control-Allow-Origin": "*",
  //         },
  //       })
  //       .then((res) => {
  //         const { data = {} } = res;
  //         const { resource = {} } = data;
  //         const { cancel_url, cancellation, reschedule_url, new_invitee } =
  //           resource;
  //         if (!cancellation || new_invitee) {
  //           setMeetingMetaConfig({
  //             ...meetingConfig,
  //             cancel_url,
  //             reschedule_url,
  //             new_invitee,
  //           });
  //         } else {
  //           setMeetingMetaConfig({});
  //         }
  //       })
  //       .catch((e) => {
  //         console.error("ERROR WHILE FETCHING CALENDLY MEETING STATUS", { e });
  //       });
  //   }
  // };
  const getInviteeUUID = (invitee) => {
    const { uri = "" } = invitee;
    let uuid = "-1";
    const splittedURI = uri.split("/");
    if (splittedURI.length > 0) {
      uuid = splittedURI[splittedURI.length - 1];
    }
    return uuid;
  };
  return (
    <div
      className={`calendly-widget${stretchWithinEle ? " stretch" : ""}`}
      style={customStyle}
    >
      {title && <p className="calendly-widget__title">{title}</p>}
      {description && (
        <p className="calendly-widget__description">{description}</p>
      )}
      {meetingMetaConfig.invitee && (
        <FeatureFlag
          flagKey="reschedule-cancel-calendly-cta"
          renderFeatureCallback={() => (
            <iframe
              title="Calendly call status"
              className="calendly-status__iframe"
              src={`${config.calendlyMeetingLink}/invitees/${getInviteeUUID(
                meetingMetaConfig.new_invitee
                  ? { uri: meetingMetaConfig.new_invitee }
                  : meetingMetaConfig.invitee || ""
              )}`}
            />
          )}
          renderDefaultCallback={() => (
            <InlineWidget
              // url="https://calendly.com/usman-integry/30min"
              url={config.calendlyMeetingLink}
              pageSettings={{
                hideGdprBanner: true,
                hideEventTypeDetails: false,
                hideLandingPageDetails: false,
              }}
              prefill={{
                email: encodeURIComponent(user.email || ""),
                firstName: user.first_name || "",
                lastName: user.last_name || "",
                name: `${user.first_name || ""} ${user.last_name || ""}`,
              }}
            />
          )}
        />
      )}
      {!meetingMetaConfig.invitee && (
        <InlineWidget
          // url="https://calendly.com/usman-integry/30min"
          url={config.calendlyMeetingLink}
          pageSettings={{
            hideGdprBanner: true,
            hideEventTypeDetails: false,
            hideLandingPageDetails: false,
          }}
          prefill={{
            email: encodeURIComponent(user.email || ""),
            firstName: user.first_name || "",
            lastName: user.last_name || "",
            name: `${user.first_name || ""} ${user.last_name || ""}`,
          }}
        />
      )}
      <div className="calendly-widget__actions">
        {meetingMetaConfig.reschedule_url && (
          <FeatureFlag
            flagKey="reschedule-cancel-calendly-cta"
            renderFeatureCallback={() => (
              <button
                type="button"
                className="meeting_cta-flat"
                onClick={() => {
                  // if (meetingMetaConfig.new_invitee) {
                  //   window.open(
                  //     `https://calendly.com/reschedulings/${getInviteeUUID({
                  //       uri: meetingMetaConfig.new_invitee,
                  //     })}`,
                  //     "_blank"
                  //   );
                  // } else {
                  //   window.open(meetingMetaConfig.reschedule_url, "_blank");
                  // }
                  setMeetingMetaConfig({});
                }}
                style={{
                  width: "238px",
                }}
              >
                {lang.RESCHEDULE_MEETING}
              </button>
            )}
            renderDefaultCallback={() => <></>}
          />
        )}
        {meetingMetaConfig.cancel_url && (
          <FeatureFlag
            flagKey="reschedule-cancel-calendly-cta"
            renderFeatureCallback={() => (
              <button
                type="button"
                className="meeting_cta-primary"
                onClick={() => {
                  if (meetingMetaConfig.new_invitee) {
                    window.open(
                      `https://calendly.com/cancellations/${getInviteeUUID({
                        uri: meetingMetaConfig.new_invitee,
                      })}`,
                      "_blank"
                    );
                  } else {
                    window.open(meetingMetaConfig.cancel_url, "_blank");
                  }
                }}
              >
                {lang.CANCEL_MEETING}
              </button>
            )}
            renderDefaultCallback={() => <></>}
          />
        )}
        {onMeetingScheduleSkipped && (
          <button
            type="button"
            onClick={() => {
              if (onMeetingScheduled && meetingScheduled) {
                onMeetingScheduled();
              }
              if (onMeetingScheduleSkipped && !meetingScheduled) {
                onMeetingScheduleSkipped();
                track(turboMixpanelEvents.SKIP_SCHEDULING_CALL.name, user, {
                  [turboMixpanelEvents.SKIP_SCHEDULING_CALL.property]:
                    sourcePage,
                  [turboMixpanelEvents.SKIP_SCHEDULING_CALL
                    .property]: `Do this later`,
                });
              }
            }}
          >
            {meetingScheduled ? lang.CONTINUE : lang.DO_THIS_LATER}
          </button>
        )}
      </div>
    </div>
  );
};

CalendlyWidget.propTypes = {
  onMeetingScheduled: PropTypes.func,
  onMeetingScheduleSkipped: PropTypes.func,
  user: PropTypes.any,
  stretchWithinEle: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  customStyle: PropTypes.any,
  sourcePage: PropTypes.string,
};
export default CalendlyWidget;
