import { createApi } from "@reduxjs/toolkit/query/react";
import config from "utils/config";
import { integryBaseQuery } from "utils/http";
import { AuthorizationType } from "./interfaces";

export const setupAthAPI = createApi({
  reducerPath: "setupAuthorization",
  baseQuery: integryBaseQuery({
    baseUrl: config.apiBaseURL || "",
  }),
  tagTypes: ["setupAuthorization"],
  endpoints(build) {
    return {
      getAuthorizationType: build.query<AuthorizationType, string | number>({
        query: (authorizationTypeId) => ({
          url: `/api/authorization_types/${authorizationTypeId}/`,
          method: "GET",
        }),
        providesTags: ["setupAuthorization"],
      }),
      updateAuthorizationType: build.mutation({
        query(data) {
          return {
            url: `/api/authorization_types/${data.authorizationTypeId}/`,
            method: "PUT",
            data: data.payload,
          };
        },
        invalidatesTags: ["setupAuthorization"],
      }),
      createAuthorizationType: build.mutation({
        query(data) {
          return {
            url: `/api/v1/authorization_types/`,
            method: "POST",
            data,
          };
        },
        invalidatesTags: ["setupAuthorization"],
      }),
      getLoginLinkToTestOAuth: build.mutation({
        query({ payload, params }) {
          return {
            url: `/auth/test-oauth2?${params}`,
            method: "POST",
            data: payload,
          };
        },
        invalidatesTags: ["setupAuthorization"],
      }),
    };
  },
});

export const {
  useGetAuthorizationTypeQuery,
  useUpdateAuthorizationTypeMutation,
  useCreateAuthorizationTypeMutation,
  useGetLoginLinkToTestOAuthMutation,
} = setupAthAPI;
