/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import {
  REQUEST_ONBOARDING_STATE,
  RECEIVE_ONBOARDING_STATE,
  CLEAR_CONNECTOR_STATE,
  UPDATE_ONBOARDING_STATE,
} from "../actions/onboarding-state";

export const FLOW_TYPES = {
  SDK: "sdk",
  MARKETPLACE: "marketplace",
};

export interface OnboardingState {
  isFetching: boolean;
  default_marketplace: string;
  default_marketplace_url: string;
  default_sdk: number;
  showSmartBox: boolean;
  showCreateFlowFocus: boolean;
  connectorState: any;
  onboardingFlowType: keyof typeof FLOW_TYPES;
}

export const onboardingState = (
  state = { isFetching: false },
  action: { type: any; ONBOARDING_STATE: any }
): Partial<OnboardingState> => {
  switch (action.type) {
    case REQUEST_ONBOARDING_STATE:
      return { ...state, isFetching: true };
    case RECEIVE_ONBOARDING_STATE:
      return { ...state, isFetching: false, ...action.ONBOARDING_STATE };
    case UPDATE_ONBOARDING_STATE:
      return { ...state, ...action.ONBOARDING_STATE };
    case CLEAR_CONNECTOR_STATE:
      return { ...state, connectorState: null };
    default:
      return state;
  }
};
