import { createApi } from "@reduxjs/toolkit/query/react";
import { integryBaseQuery } from "utils/http";
import { ListIntegryFunctionResponse } from "../interface/function";

// Define additional interfaces if needed

export const usePublicFunctionsApi = createApi({
  reducerPath: "functions",
  baseQuery: integryBaseQuery(),
  tagTypes: ["Functions"],
  endpoints: (builder) => ({
    getFunctions: builder.query<
      ListIntegryFunctionResponse,
      {
        workspaceAppMachineName?: string;
        queryParams: string;
        include_private?: boolean;
        cursor?: string;
        search?: string;
      }
    >({
      query: ({
        workspaceAppMachineName = "",
        queryParams,
        include_private = true,
        cursor = "",
        search = "",
      }) => ({
        url: `/functions/?&include=meta&include_private=${include_private}&app=${workspaceAppMachineName}&${queryParams}&cursor=${cursor}&search=${search}`,
        method: "GET",
      }),

      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { search = "" } = queryArgs || {};
        return `${endpointName}-${search}`;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, { arg }) => {
        if (arg.cursor === "") {
          return newItems;
        }
        return {
          ...newItems,
          functions: [...currentCache.functions, ...newItems.functions],
        };
      },
      // Refetch when the cursor arg changes
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg?.cursor !== previousArg?.cursor ||
          currentArg?.search !== previousArg?.search
        );
      },

      providesTags: ["Functions"],
    }),
  }),
});

export const { useGetFunctionsQuery } = usePublicFunctionsApi;
