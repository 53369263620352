/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CSSProperties, ReactElement } from "react";
import ReactModal from "react-modal";
import "./styles.scss";

export interface ModalWithHeaderProps {
  show: boolean;
  close: any;
  title: string;
  description?: string;
  children?: ReactElement;
  footer?: ReactElement;
  modalClassName?: string;
  style?: CSSProperties;
}

const CloseIcon = ({ onClose }): ReactElement => (
  <div
    onClick={onClose}
    className="cursor-pointer cancel-icon"
    tabIndex={0}
    role="button"
    onKeyDown={(e) => {
      if (e.key === "Enter") {
        onClose();
      }
    }}
  >
    <svg
      width="10"
      height="10"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 1L1 11"
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 1L11 11"
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

const ModalWithHeader = (props: ModalWithHeaderProps): ReactElement => {
  const {
    show,
    close,
    title,
    children,
    modalClassName = "",
    description = "",
    style = {},
  } = props;
  return (
    <>
      <ReactModal
        isOpen={show}
        onRequestClose={close}
        style={style}
        // shouldCloseOnOverlayClick={false}
        overlayClassName="IntegryModal__Overlay"
        className={`IntegryModal__Content modal-with-header ${modalClassName}`}
        shouldCloseOnOverlayClick
      >
        <div className="modal-header">
          <div className="modal-title-desc">
            <div className="modal-title">{title}</div>
            <div className="modal-desc">{description}</div>
          </div>
          <CloseIcon onClose={close} />
        </div>
        <div className="modal-body">{children}</div>
        <div className="modal--footer">{props.footer}</div>
      </ReactModal>
    </>
  );
};

export default ModalWithHeader;
