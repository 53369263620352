export const REFRESH_PREVIEW = "REFRESH_PREVIEW";

export const executeAppPreview = (json) => ({
  type: REFRESH_PREVIEW,
});

export const refreshAppPreview = () => {
  return (dispatch) => {
    dispatch(executeAppPreview());
  };
};
