import { INCREMENT_RETRY_COUNT } from "../actions/worker";

export const retryCountByApp = (state = {}, action) => {
  switch (action.type) {
    case INCREMENT_RETRY_COUNT:
      return Object.assign({}, state, {
        [action.app_id]: state.hasOwnProperty(action.app_id)
          ? state[action.app_id] + 1
          : 1,
      });
    default:
      return state;
  }
};
