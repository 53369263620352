import { useEffect, ReactElement } from "react";
import {
  Popover,
  ArrowContainer,
  PopoverPosition,
  PopoverAlign,
} from "react-tiny-popover";
import useIsMounted from "hooks/useIsMounted";
import useElementVisibility from "hooks/useElementVisibility";

type ArrowPosition = {
  top?: number;
  left?: number;
};
interface Iprops {
  close: () => void;
  children: ReactElement;
  popoverContent: ReactElement;
  show: boolean;
  arrowColor?: string;
  containerStyle?: Partial<CSSStyleDeclaration>;
  arrowPosition?: ArrowPosition;
  parentElement?: HTMLDivElement;
  boundaryElement?: HTMLDivElement;
  positions?: PopoverPosition[]; // 'left' | 'right' | 'top' | 'bottom' |
  hideArrow?: boolean;
  align?: PopoverAlign;
}
const IntegryPopover = (props: Iprops): React.ReactElement => {
  const {
    children,
    show,
    popoverContent,
    arrowColor = "#f6f6fe",
    containerStyle = {},
    arrowPosition = {},
    parentElement = undefined,
    boundaryElement = undefined,
    hideArrow = false,
    positions = ["right"],
    align = "start",
    close,
  } = props;
  const { elementRef, isElementVisible } = useElementVisibility();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted() && !isElementVisible) {
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isElementVisible]);
  return (
    <div style={{ position: "relative" }} ref={elementRef}>
      <Popover
        isOpen={show}
        onClickOutside={() => {
          if (isMounted()) {
            close();
          }
        }}
        containerStyle={{
          zIndex: "1000",
          paddingBottom: "20px",
          ...containerStyle,
        }}
        transform={{ top: -10, ...arrowPosition }}
        transformMode="relative"
        align={align}
        parentElement={parentElement}
        boundaryElement={boundaryElement}
        content={({ position, childRect, popoverRect, hasViolations }) => {
          return (
            <>
              {hideArrow ? (
                <>{popoverContent}</>
              ) : (
                <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                  position={position}
                  childRect={childRect}
                  popoverRect={popoverRect}
                  arrowColor={arrowColor}
                  arrowSize={10}
                  className="popover-arrow-container"
                  arrowClassName="popover-arrow"
                >
                  {popoverContent}
                </ArrowContainer>
              )}
            </>
          );
        }}
        positions={positions}
      >
        {children}
      </Popover>
    </div>
  );
};

export default IntegryPopover;
