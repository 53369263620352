/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, ReactElement, useRef } from "react";
import { Link } from "react-router-dom";

import CommonIntegryModal from "components/common/common-integry-modal";
import { track } from "utils/mixpanel";
import showToast, {
  showErrorToast,
} from "components/common/custom-toast/custom-toast";
import OverflowTooltip from "components/common/overflow-tooltip";
import MeatballsMenu from "components/integry-design-system/molecules/meatballs-menu";
import TextField from "components/forms/v4/text-field";
import { tagInterface } from "features/account-settings/services/interface";
import useOutsideClick from "hooks/useOutsideClick";
import editIcon from "images/edit-tag-gray.svg";
import deleteIcon from "images/delete-tag.svg";
import { convertDateToLocal } from "utils/functions";
import { TagActions } from "./services/constants";
import { deleteAccountTag, updateAccountTag } from "./services/tags-apis";

const AccountTag = (props: tagInterface): ReactElement => {
  const {
    id,
    actions_count,
    name,
    updated_on,
    removeTagFromList,
    validateTag,
    editTagId,
    setEditTagId,
    updateTagInList,
    user,
  } = props;
  const [ref, isComponentVisible, setIsComponentVisible] =
    useOutsideClick(false);
  const [tag, setTag] = useState(name);
  const [showModal, setShowModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const valueRef = useRef("");

  const meatballOnSelect = (value: string): void => {
    switch (value) {
      case "rename":
        handleEditTag();
        break;
      case "delete":
      case "deleteTag":
        setShowModal(true);
        track("Tag delete clicked", user);
        break;
      default:
    }
  };

  const onTagRemove = (): void => {
    setIsDeleting(true);
    const tagId = id;
    deleteAccountTag(tagId)
      .then((res) => {
        showToast("Tag deleted", `‘${name}’ tag deleted`);
        setShowModal(false);
        setIsDeleting(false);
        removeTagFromList(id);
        track("Tag deleted", user);
      })
      .catch((e) => {
        setShowModal(false);
        setIsDeleting(false);
      });
  };

  const handleEditTag = (): void => {
    setShowRenameModal(true);
  };

  const onTagEdit = (): void => {
    setEditTagId(id);
    // setIsEditing(true);
    setIsComponentVisible(true);
    setShowRenameModal(false);
    setTag(name);
    valueRef.current = name;
  };

  const handleTagChange = (e): void => {
    setTag(e.target.value);
  };
  const handleUpdateTag = (event): void => {
    if (event.key === "Enter") {
      setIsComponentVisible(true);
      setIsUpdating(true);
      event.preventDefault();
      event.stopPropagation();
      const tagValue = event.target.value;
      updateAccountTag(id, tagValue)
        .then((res) => {
          setIsUpdating(false);
          // setIsEditing(false);
          setIsComponentVisible(false);
          setTag(tagValue);
          updateTagInList(id, tagValue);
          showToast("Tag updated", `‘${tagValue}’ tag updated`);
          track("Tag renamed", user);
        })
        .catch((error) => {
          setIsUpdating(false);
          // setIsEditing(false);
          setIsComponentVisible(false);
          console.log(error.response);
          showErrorToast(
            "Tag update failed",
            `${error.response.data.unique_error}`
          );
        });
    }
  };
  return (
    <tr className="tag-row member-row">
      <div className="tag-col">
        <>
          <TextField
            placeholder=""
            name={`name-${id}`}
            defaultValue={valueRef.current}
            className={`integry-text-field tag-name-field ${
              isComponentVisible && editTagId === id ? "" : "d-none"
            }`}
            validate={validateTag}
            onKeyDown={handleUpdateTag}
            onKeyPress={handleTagChange}
            errorPlaceHolder
            ref={ref}
          />
          {isUpdating && <div className="integry-spinner-sm ml-mr-auto" />}
          <div
            className={`tag-name ${
              isComponentVisible && editTagId === id ? "d-none" : ""
            }`}
          >
            {name}
          </div>
        </>
      </div>
      <div className="tag-col account-count">
        {actions_count <= 0 ? (
          <span className="account-count-text-disbaled"> {actions_count}</span>
        ) : (
          <Link
            className="account-count-text"
            to={`/actions/?tags=${id}`}
            onClick={() =>
              track("Actions for a tag clicked", user, {
                number_of_actions: actions_count,
                tag: name,
              })
            }
          >
            {" "}
            {actions_count}
          </Link>
        )}
      </div>

      <div className="tag-col">
        <OverflowTooltip
          className="tag-date"
          text={convertDateToLocal(updated_on)}
        />
      </div>
      <div className="tag-col">
        <MeatballsMenu
          items={TagActions}
          onSelect={meatballOnSelect}
          customStyle={{ marginLeft: "5px" }}
        />
      </div>

      <CommonIntegryModal
        show={showModal}
        close={() => setShowModal(false)}
        title={`Delete ‘${name}’`}
        message={`If you are using ‘${name}’ tag to serve filtered flows in the embed, deleting it will hide those actions from your users. \n\nAre you sure?`}
        icon={deleteIcon}
        action={onTagRemove}
        actionButtonTitle="Delete"
        isLoading={isDeleting}
        modalClass="remove-user-modal"
      />
      <CommonIntegryModal
        show={showRenameModal}
        close={() => setShowRenameModal(false)}
        title={`Rename ‘${name}’`}
        message={`If you are using ‘${tag}’ tag to serve filtered flows in the embed, renaming it will hide those actions from your users. \n\nAre you sure?`}
        icon={editIcon}
        action={onTagEdit}
        actionButtonTitle="Rename"
        isLoading={isDeleting}
        modalClass="rename-tag-modal"
      />
    </tr>
  );
};

export default AccountTag;
