/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import "./styles.scss";

const TrialPeriodBanner = (props) => {
  const { remainingDays } = props;

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <div className="banner-container">
      <div className="turbo-signup-trial-banner">
        <span>Your trial ends in {remainingDays} days </span>
        <a
          className="cta"
          href="https://calendly.com/integry-io"
          target="_blank"
          rel="noreferrer"
        >
          Talk to Sales
        </a>{" "}
      </div>
    </div>
  );
};

TrialPeriodBanner.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  remainingDays: propTypes.string,
};

export default TrialPeriodBanner;
