import { useEffect, ReactElement } from "react";
import hmacSHA256 from "crypto-js/hmac-sha256";

import FormikWrapper from "components/forms/v4/formik-wrapper";
import { IntegryJS } from "@integry/sdk";
import Button from "components/integry-design-system/molecules/button";

import { useWorkspaceAppContext } from "context/workspaceAppContext";
import { useFetchUserQuery } from "utils/user/user-api";
import { useLazyGetEndUsersQuery } from "features/users/api/end-users-apis";
import useAddAuthorization from "hooks/useAddAuthorization";
import { useFeatureFlags } from "context/featureFlagsContext";

import AsyncDropdown, {
  LoadOptionsResponse,
} from "components/integry-design-system/molecules/AsyncDropdown/AsyncDropdown";

import "./MarketplaceModal.scss";

interface ImpersonateUserProps {
  selectedUser: string;
  setSelectedUser: (selectedUser: string) => void;
}

const ImpersonateUser = (props: ImpersonateUserProps): ReactElement => {
  const { selectedUser, setSelectedUser } = props;
  const { data: user } = useFetchUserQuery();

  const { workspaceApp } = useWorkspaceAppContext();

  const addNewUserFlag = useFeatureFlags({ flag: "add-new-eu" });

  const [fetchEndusers] = useLazyGetEndUsersQuery();

  const { addNewAccount } = useAddAuthorization({
    onAddAccount: () => ({}),
  });

  useEffect(() => {
    setTimeout(() => {
      const userId = selectedUser;
      initSDK(userId);
    }, 100); // added timeout to wait for the modal to render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  const initSDK = async (userId: string): Promise<void> => {
    if (!workspaceApp) return;

    const hash = hmacSHA256(userId, workspaceApp.secret).toString();
    try {
      const { key: appKey } = workspaceApp;
      const integryHandle = new IntegryJS({
        appKey,
        hash,
        userId,
        xIntegryConfig: {},
        options: {
          title: "Apps" /* Add title here E.g. "Flows" */,
          tags: [] /* What Flows do you want to show? Pass tag names in array E.g. ["Production","Beta"] */,
          objects: {},
        },
      });
      integryHandle.showApps(
        [],
        IntegryJS.MarketplaceRenderModes.INLINE,
        "x-integry-container" /* Add the dom container id where you want to render sdk */
      );
    } catch (error) {
      console.log(error);
    }
  };

  const loadEndUserOptions = async ({
    page,
    searchString,
  }: {
    page: number;
    searchString: string;
  }): Promise<LoadOptionsResponse> => {
    const endUsers = await fetchEndusers({
      page: { page, pageSize: 50 },
      ordering: "external_id",
      filters: `&search=${encodeURIComponent(searchString)}`,
      pinLoggedInUser: true,
    }).unwrap();

    const users = [
      ...(endUsers
        ? endUsers.results.map((eu) => ({
            label: eu.external_id,
            id: eu.id,
          }))
        : []),
    ];

    return { options: users, hasMore: !!endUsers.links.next };
  };

  const addUser = (): void => {
    if (workspaceApp)
      addNewAccount({
        userId: "NEW_USER",
        authorizationType: {
          type_id: workspaceApp.authorization_type.type_id,
          provider: workspaceApp.authorization_type.provider,
          id: workspaceApp.authorization_type.id,
        },
      });
  };

  return (
    <FormikWrapper
      saveCallback={() => ({})}
      initialState={{
        user_id: user?.username || user?.email,
      }}
    >
      <div className="flex-column">
        <div className="preview-marketplace-subheader">
          <p className="txt-sm-dark-gray txt-md semi-bold">User ID</p>
          <AsyncDropdown
            placeholder="Select user"
            defaultValue={selectedUser}
            addBottomBorderOnFirstOption
            onChange={(selectedOption) => {
              setSelectedUser(selectedOption.label);
            }}
            loadOptions={({ search, page }) =>
              loadEndUserOptions({
                searchString: search,
                page,
              })
            }
            menuFooter={
              addNewUserFlag ? (
                <Button
                  variation="link"
                  size="auto"
                  typographVariation="2"
                  action={() => addUser()}
                  className="add-user-button"
                  style={{ padding: "8px" }}
                >
                  + Add user
                </Button>
              ) : null
            }
          />
        </div>
        <div id="x-integry-container" className="x-integry-container" />
      </div>
    </FormikWrapper>
  );
};

export default ImpersonateUser;
