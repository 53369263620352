import http from "../../utils/http";

export const REQUEST_GENERIC_OBJECTS = "REQUEST_GENERIC_OBJECTS";

const requestGenericObjects = () => ({
  type: REQUEST_GENERIC_OBJECTS,
});

export const RECEIVE_GENERIC_OBJECTS = "RECEIVE_GENERIC_OBJECTS";

const receiveGenericObjects = (json) => ({
  type: RECEIVE_GENERIC_OBJECTS,
  generic_objects: json,
});

export const fetchGenericObjects = () => {
  return (dispatch) => {
    dispatch(requestGenericObjects());

    return http.get("/api/object/").then(
      (response) => {
        dispatch(receiveGenericObjects(response.data));
      },
      (error) => {
        console.log(
          "Error occurred while fetching generic objects in fetchGenericObjects() action.",
          error
        );
      }
    );
  };
};
