import { createApi } from "@reduxjs/toolkit/query/react";
import { integryBaseQuery } from "utils/http";
import { Activity } from "../template-builder-v7/services/template-builder-interface";

interface ActivityVersion {
  id: number;
  version_number: number;
  created: string;
  changelog: string | null;
  is_published: boolean;
}

interface ActivityVersionsResponse {
  count: number;
  meta: Record<string, unknown>;
  total_pages: number;
  results: ActivityVersion[];
  links: {
    previous: string | null;
    next: string | null;
  };
}

export const activityVersionsApi = createApi({
  reducerPath: "activityVersions",
  baseQuery: integryBaseQuery(),
  tagTypes: ["ActivityVersions"],
  endpoints: (builder) => ({
    getActivityVersions: builder.query<
      ActivityVersionsResponse,
      { activityId: string | number }
    >({
      query: ({ activityId }) => ({
        url: `/api/v1/activities/${activityId}/versions/`,
        method: "GET",
      }),
      providesTags: ["ActivityVersions"],
    }),

    getActivityByVersion: builder.mutation<
      Activity,
      { activityId: string | number; version: number }
    >({
      query: ({ activityId, version }) => ({
        url: `/api/v1/activities/${activityId}/?version=${version}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetActivityVersionsQuery, useGetActivityByVersionMutation } =
  activityVersionsApi;
