/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect, ReactElement, useRef } from "react";
import { Formik, FormikProps } from "formik";
import { FeatureFlag } from "react-launch-darkly";

import { payloadInterface } from "features/account-settings/services/interface";
import addIcon from "images/account-setting/add-action-icon.svg";
import ModalWithHeader from "components/integry-design-system/atoms/modals/model-with-header";
import ScrollFadeEffect from "components/integry-design-system/atoms/scroll-fade-effect";

import { track } from "utils/mixpanel";

import SortIconDefault from "images/sort.svg";
import SortIconDesc from "images/sort-applied.svg";
import { listActionPayloads } from "./services/payloads-apis";
import PayloadForm from "./payload-form";
import ActionPayload from "./action-payload";
import ObjectList from "./object-list";

import "./payloads.scss";

interface PayloadFormType {
  name: string;
  // description: string;
  // payload: string;
}

const PayloadsManagement = (props): ReactElement => {
  const { user } = props;
  const [newPayload, setNewPayload] = useState({
    name: "",
    description: "",
    payload: "",
    type: "",
  });
  const [isLoading, setIsloading] = useState(false);
  const [payloads, setPayloads] = useState<payloadInterface[]>([]);
  const [editPayloadIndex, setEditPayloadIndex] = useState(-1);
  const [showPredefinedObjectModal, setShowPredefinedObjectModal] =
    useState(false);
  const [search, setSearch] = useState("");
  const [showEditor, setShowEditor] = useState(false);
  const [sorting, setSorting] = useState<any>({
    modified: "asc",
  });
  const formikRef = useRef<
    FormikProps<{
      name: string;
    }>
  >();

  useEffect(() => {
    fetchPayloadsList(search, sorting);
  }, []);

  const resetQueryParams = (): void => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("payload")) {
      queryParams.delete("payload");
      window.history.replaceState(
        null,
        "Payloads Management",
        `${window.location.pathname}?${queryParams.toString()}`
      );
    }
  };

  const mapURLQueryStringToState = (payloadArray): void => {
    const windowQueryParams = new URLSearchParams(window.location.search);
    if (windowQueryParams.has("payload")) {
      const payloadId = windowQueryParams.get("payload") || "";
      if (payloadId === "new" || payloadId === "-1") {
        setEditPayloadIndex(-1);
        setShowPredefinedObjectModal(true);
      } else {
        const payloadIndex = payloadArray.findIndex(
          (item) => item.id === Number(payloadId)
        );
        if (payloadIndex !== -1) {
          setEditPayloadIndex(payloadIndex);
          setShowEditor(true);
        }
      }
    }
  };

  const fetchPayloadsList = (item, sortOrder): void => {
    setIsloading(true);
    listActionPayloads(item, sortOrder)
      .then((res) => {
        const results = res.data;
        setPayloads(results);
        setIsloading(false);
        mapURLQueryStringToState(results);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const removePayloadFromList = (id: number): void => {
    setPayloads((prevState) => prevState.filter((item) => item.id !== id));
  };

  const updatePayloadInList = (
    id: number,
    name: string,
    description: string,
    payload: string
  ): void => {
    setPayloads((prevState) =>
      prevState.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            name,
            description,
            payload,
          };
        }
        return item;
      })
    );
  };

  const AddPayloadInList = (): void => {
    fetchPayloadsList("", sorting);
  };
  const handleCreatePayload = (): void => {
    setNewPayload({
      name: "",
      description: "",
      payload: "",
      type: "",
    });
    setEditPayloadIndex(-1);
    setShowEditor(true);
  };

  const handleObjectSelection = (object): void => {
    const payloadIndex = payloads.findIndex(
      (item) => item.name === object.name
    );
    if (payloadIndex !== -1) {
      // setShowPredefinedObjectModal(false);
      // setEditPayloadIndex(payloadIndex);
      // setShowEditor(true);
      setShowPredefinedObjectModal(false);
      setEditPayloadIndex(-1);
      setShowEditor(true);
      setNewPayload({
        name: incrementString(object.name),
        description: "",
        payload: object.fields,
        type: "predefined",
      });
    } else {
      setShowPredefinedObjectModal(false);
      setEditPayloadIndex(-1);
      setShowEditor(true);
      setNewPayload({
        name: object.name,
        description: "",
        payload: object.fields,
        type: "predefined",
      });
    }
  };

  const incrementString = (stringToMatch): string => {
    let matchedCount = 0;
    payloads.forEach((item) => {
      if (item.name.includes(stringToMatch)) {
        matchedCount += 1;
      }
    });
    return `${stringToMatch}${matchedCount}`;
  };

  const onsubmit = (values: PayloadFormType, resetForm: () => void): void => {
    console.log("submit");
  };
  const validatePayload = (name): string => {
    return "";
  };
  const handleOnSorting = (id): void => {
    let tempSorting = {};
    tempSorting = {
      [id]: sorting[id] === "desc" ? "asc" : "desc",
    };
    setSorting(tempSorting);
    fetchPayloadsList(search, tempSorting);
  };
  return (
    <>
      <Formik
        initialValues={{ name: "" }}
        onSubmit={(values, { resetForm }) => onsubmit(values, resetForm)}
        validate={(values) => {
          const error = validatePayload(values.name);
          if (error) {
            return { email: error };
          }
          return {};
        }}
      >
        {(formik) => {
          formikRef.current = formik;
          return (
            <>
              <div className="account-setting-wrapper payload-management-wrapper">
                <h2 className="page-heading">Objects</h2>
                <div className="account-settings-form">
                  <>
                    <h3 className="main-title">Manage objects</h3>
                    {/* <SearchBarV3 placeholder="Search" onSearch={searchTags} /> */}
                    <div>
                      <div className="tag-row heading-row">
                        <div
                          className="tag-col-head sortable-col-head"
                          onClick={() => {
                            handleOnSorting("name");
                          }}
                        >
                          Name
                          <img
                            src={sorting.name ? SortIconDesc : SortIconDefault}
                            className={
                              sorting.name && sorting.name === "asc"
                                ? "table-sort-icon-asc"
                                : ""
                            }
                            alt="sort_icon"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="tag-col-head">Description</div>
                        <div
                          className="tag-col-head account-count-head sortable-col-head"
                          onClick={() => {
                            handleOnSorting("actions_count");
                          }}
                        >
                          Actions
                          <img
                            src={
                              sorting.actions_count
                                ? SortIconDesc
                                : SortIconDefault
                            }
                            className={
                              sorting.actions_count &&
                              sorting.actions_count === "asc"
                                ? "table-sort-icon-asc"
                                : ""
                            }
                            alt="sort_icon"
                            aria-hidden="true"
                          />
                        </div>
                        <div
                          className="tag-col-head sortable-col-head"
                          onClick={() => {
                            handleOnSorting("modified");
                          }}
                        >
                          Last Modified
                          <img
                            src={
                              sorting.modified ? SortIconDesc : SortIconDefault
                            }
                            className={
                              sorting.modified && sorting.modified === "asc"
                                ? "table-sort-icon-asc"
                                : ""
                            }
                            alt="sort_icon"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="tag-col-4 description" />
                      </div>

                      {payloads.length > 0 &&
                        payloads.map((item, index) => (
                          <ActionPayload
                            {...item}
                            total_payloads={payloads.length}
                            removePayloadFromList={removePayloadFromList}
                            validatePayload={validatePayload}
                            editPayloadIndex={editPayloadIndex}
                            setEditPayloadIndex={setEditPayloadIndex}
                            updatePayloadInList={updatePayloadInList}
                            setShowEditor={setShowEditor}
                            index={index}
                            user={user}
                          />
                        ))}
                      <FeatureFlag
                        flagKey="predefined-objects"
                        renderDefaultCallback={() => (
                          <div
                            className="tag-row member-row  action-row"
                            onClick={() => {
                              track("Object add button clicked", user);
                              handleCreatePayload();
                            }}
                          >
                            <div className="tag-col">
                              <span className="add-tag">
                                <img src={addIcon} alt="Add" /> Add Object
                              </span>
                            </div>
                          </div>
                        )}
                        renderFeatureCallback={() => (
                          <div
                            className="tag-row member-row  action-row"
                            onClick={() => {
                              track("Object add button clicked", user);
                              setShowPredefinedObjectModal(true);
                            }}
                          >
                            <div className="tag-col">
                              <span className="add-tag">
                                <img src={addIcon} alt="Add" /> Add Object
                              </span>
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </>
                  {/* {isLoading && <div className="integry-spinner-sm ml-mr-auto" />} */}
                </div>
              </div>
              {showEditor && (
                <FeatureFlag
                  flagKey="predefined-objects"
                  renderDefaultCallback={() => (
                    <PayloadForm
                      payloadData={
                        editPayloadIndex === -1
                          ? newPayload
                          : payloads[editPayloadIndex]
                      }
                      setShowEditor={(show) => {
                        setShowEditor(show);
                        if (!show) resetQueryParams();
                      }}
                      AddPayloadInList={AddPayloadInList}
                      updatePayloadInList={updatePayloadInList}
                      allPayloads={payloads}
                      user={user}
                    />
                  )}
                  renderFeatureCallback={() => (
                    <PayloadForm
                      payloadData={
                        editPayloadIndex === -1
                          ? newPayload
                          : payloads[editPayloadIndex]
                      }
                      setShowEditor={(show) => {
                        setShowEditor(show);
                        if (!show) resetQueryParams();
                      }}
                      AddPayloadInList={AddPayloadInList}
                      updatePayloadInList={updatePayloadInList}
                      allPayloads={payloads}
                      user={user}
                      onBackClick={() => {
                        setShowEditor(false);
                        setEditPayloadIndex(-1);
                        setShowPredefinedObjectModal(true);
                      }}
                    />
                  )}
                />
              )}
              <ModalWithHeader
                title="Add an object"
                show={showPredefinedObjectModal}
                close={() => {
                  setShowPredefinedObjectModal(false);
                }}
              >
                <>
                  <ObjectList
                    onObjectSelect={handleObjectSelection}
                    onCreatePayload={() => {
                      track("Object add button clicked", user);
                      setShowPredefinedObjectModal(false);
                      handleCreatePayload();
                    }}
                  />
                  <ScrollFadeEffect position="bottom" />
                </>
              </ModalWithHeader>
            </>
          );
        }}
      </Formik>
    </>
  );
};
export default PayloadsManagement;
