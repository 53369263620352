/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement } from "react";

interface SuggestionsDropdownProps {
  options: any;
  className?: string;
  searchValue?: string;
  onSelect: any;
  setFocusClass: any;
}

const SuggestionsDropdown = (props: SuggestionsDropdownProps): ReactElement => {
  const { className, options, searchValue, onSelect, setFocusClass } = props;

  const getFilteredOptions = (): any => {
    if (searchValue) {
      return options.filter((opt) => {
        if (
          opt.label.toLowerCase().includes(searchValue.toLowerCase()) &&
          opt.label.toLowerCase() !== searchValue.toLowerCase()
        ) {
          return opt.label;
        }
        return null;
      });
    }
    return options;
  };

  const filteredOptions = getFilteredOptions();

  return filteredOptions && filteredOptions.length > 0 ? (
    <div className={`suggestions-dropdown ${className}`}>
      {filteredOptions.map((opt) => {
        return (
          <div
            className="option-row"
            onClick={() => {
              onSelect(opt);
              setFocusClass("");
            }}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSelect(opt);
                setFocusClass("");
              }
            }}
            role="button"
          >
            {opt.label}
          </div>
        );
      })}
    </div>
  ) : (
    <></>
  );
};

SuggestionsDropdown.defaultProps = {
  className: "",
  searchValue: "",
};

export default SuggestionsDropdown;
