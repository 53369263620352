/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useState, useRef } from "react";
import { Field, useFormikContext, FormikTouched } from "formik";
import AppIconPlaceholder from "images/app-icon.svg";
import AppIconError from "images/app-icon-error.svg";
import { toast } from "react-toastify";
import { IntegryApp } from "components/apps-v4/interfaces/interfaces";
import ImageCropper from "atomic-components/molecules/image-cropper";
import Resizer from "react-image-file-resizer";
import http from "../../../utils/http";

type TouchedTypes = {
  app_name: string;
  app_description: string;
  icon_url: boolean;
};
type AppIconFieldProps = {
  name: string;
  app?: IntegryApp;
  displayName?: string;
  width?: number;
  height?: number;
  minWidth?: number;
  minHeight?: number;
  description?: string;
  buttonText?: string;
  fieldId?: string;
  checkTransparent?: boolean;
  exactDimensions?: boolean;
  onIconUpload?: any;
  resizeFile?: boolean;
  cropSquare?: boolean;
  showLoader?: boolean;
};
const ImageInput = (props): ReactElement => {
  const {
    name,
    displayName,
    updatePreview,
    form,
    buttonText,
    width,
    height,
    fieldId,
    checkTransparent,
    minHeight,
    minWidth,
    exactDimensions,
    onIconUpload = null,
    resizeFile,
    cropSquare = false,
    setUploadingLogo,
  } = props;
  const [sourceImg, setSourceImg] = useState<any | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { setFieldValue, setFieldError } = form;

  const resizeFileFunction = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        width,
        height,
        "png",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });

  const uploadLogo = async (logoFile): Promise<void> => {
    setUploadingLogo(true);
    const image = resizeFile
      ? ((await resizeFileFunction(logoFile)) as Blob) // rezise Image before uploading.
      : logoFile;

    if (image) {
      const requestData = new FormData();
      requestData.set("file", image);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      http
        .post("/api/files/", requestData, config)
        .then((response) => {
          if (onIconUpload) {
            onIconUpload();
          }
          updatePreview(response.data.file_path);
          setFieldValue(name, response.data.file_path);
          setUploadingLogo(false);
        })

        .catch((error) => {
          console.log(error);
          setUploadingLogo(false);
        });
    }
  };

  const hasAlpha = (context, canvas) => {
    const { data } = context.getImageData(0, 0, canvas.width, canvas.height);
    let hasAlphaPixels = false;
    for (let i = 3, n = data.length; i < n; i += 4) {
      if (data[i] < 255) {
        hasAlphaPixels = true;
        break;
      }
    }
    return hasAlphaPixels;
  };

  const checkTransparentBackground = (image) => {
    const canvas = canvasRef.current;

    if (canvas) {
      const context = canvas.getContext("2d");
      canvas.width = width;
      canvas.height = height;
      context?.drawImage(image, 0, 0, canvas.width, canvas.height);
      if (hasAlpha(context, canvas)) {
        return true;
      }
    }
    return false;
  };

  const imageErrorMessage = (title, msg) => {
    return (
      <div>
        <div className="lable error">{title}</div>
        <div className="message">{msg}</div>
      </div>
    );
  };

  const handleOnImageChange = (e) => {
    const file = e.target.files[0];
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
      if (file.size <= 2097152) {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          let imageError = false;
          if (img.height < 128 || img.width < 128) {
            imageError = true;
            toast.error(
              imageErrorMessage(
                `${displayName} Upload Error`,
                "Image dimensions should not be smaller than 128 x 128px"
              )
            );
          }
          if (!imageError) {
            const aspectRatio = img.height / img.width;
            if (cropSquare && aspectRatio === 1) {
              // if image is square dont show crop image modal
              uploadLogo(file);
            } else {
              setSourceImg(file);
            }
          }
        };
        img.remove();
      } else {
        toast.error(
          imageErrorMessage(
            `${displayName} Upload Error`,
            "Size must be 2 MB or less"
          )
        );
      }
    } else {
      toast.error(
        imageErrorMessage(
          `${displayName} Upload Error`,
          "Please upload either a PNG, JPG or JPEG file"
        )
      );
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleOnImageChange_Old = (e) => {
    const file = e.target.files[0];
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
      if (e.target.files[0].size <= 2097152) {
        const img = new Image();
        img.src = URL.createObjectURL(e.target.files[0]);
        img.onload = () => {
          let imageError = false;
          if (
            exactDimensions &&
            (img.height !== height || img.width !== width)
          ) {
            let errorTitle = "";
            if (img.height < height || img.width > width) {
              errorTitle = "Image too small";
            } else if (img.height > height || img.width > width) {
              errorTitle = "Image too large";
            }
            imageError = true;
            e.target.value = "";
            setFieldError(name, `Dimensions must be ${width} x ${height}`);
            toast.error(
              imageErrorMessage(
                `Uploaded image should be ${width}px x ${height}px`,
                `${errorTitle}`
              )
            );
          } else if (img.height < minHeight || img.width < minWidth) {
            imageError = true;
            e.target.value = "";
            setFieldError(name, `Dimensions must be ${width} x ${height}`);
            toast.error(
              imageErrorMessage(
                `Uploaded image should be ${width}px x ${height}px`,
                `Image too small`
              )
            );
          } else if (img.height > height || img.width > width) {
            imageError = true;
            e.target.value = "";
            setFieldError(name, `Dimensions must be ${width} x ${height}`);
            toast.error(
              imageErrorMessage(
                `Uploaded image should be ${width}px x ${height}px`,
                `Image too large`
              )
            );
          } else if (checkTransparent) {
            let transparent = true;
            if (file.type === "image/png") {
              transparent = checkTransparentBackground(img);
            } else {
              transparent = false;
            }
            if (transparent === false) {
              imageError = true;
              toast.error(
                imageErrorMessage(
                  `${displayName} Upload Error`,
                  `${displayName} should be PNG and have transparent background.`
                )
              );
            }
          }
          if (!imageError) {
            uploadLogo(file);
          }
        };
        img.remove();
      } else {
        e.target.value = "";
        toast.error(
          imageErrorMessage(
            `${displayName} Upload Error`,
            "Size must be 2 MB or less"
          )
        );
      }
    } else {
      toast.error(
        imageErrorMessage(
          `${displayName} Upload Error`,
          "Please upload either a PNG, JPG or JPEG file"
        )
      );
    }
  };
  return (
    <div>
      {sourceImg && (
        <ImageCropper
          imgSrc={URL.createObjectURL(sourceImg)}
          imgName={sourceImg.name || "image"}
          onDiscard={() => {
            setSourceImg(null);
          }}
          cropSize={{
            width,
            height,
          }}
          cropSquare={cropSquare}
          onSave={(croppedImage) => {
            uploadLogo(croppedImage);
          }}
        />
      )}
      <canvas ref={canvasRef} style={{ display: "None" }} id="canvas" />

      {!sourceImg && (
        <input
          id={fieldId}
          className="app-icon-upload-button "
          type="file"
          name={name}
          accept="image/*"
          onChange={(e) => {
            if (e.target.files) {
              if (e.target.files.length > 0) {
                handleOnImageChange(e);
              }
            }
          }}
          {...props}
        />
      )}

      <label className="app-icon-upload-label" htmlFor={fieldId}>
        <span className="app-icon-upload-text">{buttonText}</span>
      </label>
    </div>
  );
};
export default (props: AppIconFieldProps): ReactElement => {
  const {
    name,
    app,
    width = 128,
    height = 128,
    minWidth = 0,
    minHeight = 0,
    description = "",
    buttonText = "Upload App Emblem",
    fieldId = "app-icon-input",
    displayName = "Emblem",
    checkTransparent = false,
    exactDimensions = false,
    onIconUpload = null,
    resizeFile = false,
    cropSquare = false,
    showLoader = false,
  } = props;
  const { errors, touched, values } = useFormikContext() as {
    values: Record<string, any>;
    errors: Record<string, any>;
    touched: FormikTouched<TouchedTypes>;
  };
  // eslint-disable-next-line
  const [preview, setPreview] = useState<string | undefined>(
    values ? values[name] : null
  );
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const isValid = touched[name] && errors[name];
  return (
    <>
      <div className="app-icon-field-container">
        <div className="app-icon-preview-container">
          {showLoader && uploadingLogo && (
            <span className="integry-spinner-sm" />
          )}
          <div>
            <img
              className="app-icon-preview"
              alt="something"
              src={
                isValid && errors[name] === "This field is required."
                  ? AppIconError
                  : preview || AppIconPlaceholder
              }
            />
          </div>
        </div>
        <div className="app-icon-input-label-container">
          <Field name={name}>
            {({ form }) => (
              <ImageInput
                app={app}
                name={name}
                displayName={displayName}
                form={form}
                updatePreview={setPreview}
                buttonText={buttonText}
                width={width}
                height={height}
                minWidth={minWidth}
                minHeight={minHeight}
                fieldId={fieldId}
                checkTransparent={checkTransparent}
                exactDimensions={exactDimensions}
                onIconUpload={onIconUpload}
                resizeFile={resizeFile}
                cropSquare={cropSquare}
                setUploadingLogo={setUploadingLogo}
              />
            )}
          </Field>
          <div>
            {description === "" ? (
              <span className="app-icon-tooltip-text">
                The emblem must be a square, sized 128px × 128px and max 2 MB in
                size
              </span>
            ) : (
              <span className="app-icon-tooltip-text">{description}</span>
            )}
          </div>
        </div>
      </div>
      {isValid && errors[name] === "This field is required." && (
        <div className="icon-errors">{errors[name]}</div>
      )}
    </>
  );
};
