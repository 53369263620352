/* eslint-disable import/prefer-default-export */
export const generateEmbedCodeForFunctions = ({
  config,
}: {
  config: {
    appKey: string;
    secret: string;
    userId: string;
    userVariables: Record<string, string>;
  };
}): string =>
  `<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8" />
    <meta
      http-equiv="Content-Security-Policy"
      content="upgrade-insecure-requests"
    />
    <title>Apps - Integry SDK Playground</title>
    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <link
      href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;400&display=swap"
      rel="stylesheet"
    />
    <style>
      html,
      body {
        height: 100%;
        margin: 0;
        padding: 0;
      }
      #x-integry-container {
        font-family: "Inter";
        height: 100%;
      }
    </style>
  </head>
  <body>
    <div id="x-integry-container"></div>
    <script>
      window.addEventListener("DOMContentLoaded", async function () {
        const appKey = "${
          config.appKey
        }"; /* Disclaimer: App key added just for prototype use. Don’t add it to your production code.” */
        const appSecret = "${
          config.secret
        }"; /* Disclaimer: App secret added just for prototype use. Don’t add it to your production code.” */

        const userId = "${config.userId}";

        /* This function hashes the User ID and App Secret for prototype use. */
        const hash = await IntegryJS.Helpers.getAuthHash(userId, appSecret);
        /* Caution: For production deployments, perform hash calculations on the server side for enhanced security */

        const integry = new IntegryJS({
          appKey,
          hash,
          user: {
            userId: userId,
             ${Object.keys(config.userVariables).map((key, index) => {
               return `${index > 0 ? "\n" : ""}"${key}": ""`;
             })}
          },
          options: {
            title: "Apps" /* Add title here E.g. "Apps" */,
            tags: [] /* Pass tag names in array E.g. ["Production","Beta"] */,
            debug: false /* Enable debug mode */,
          },
        });

        /**
         * General note: following are three blocks to connect, disconnect and render function UI
         * you have to enable one of the block at a time because thats what makes sense
         */

        /**
         * Step-1: Connect to an App
         */
        // integry
        //   .connectApp("slack")
        //   .then((response) => {
        //     /**
        //      * Here you will get the authorization_id in response that you can use further
        //      */
        //     console.log(response);
        //   })
        //   .catch((error) => {
        //     console.error("Connection failed:", error);
        //   });

        /**
         * Step-2: You may optionally want to disconnect from an App
         */
        // integry.disconnectApp("slack").then((response) => {
        //   console.log(response);
        // });

        /**
         * Step-3: Render Function UI
         * and also invoke the function
         */
        integry.isAppConnected("slack").then((authorization_id) => {
          /**
           * returns the authorization_id if the app is connected
           * otherwise returns false
           */
          if (authorization_id) {
            showFunctionUI();
          } else {
            integry.connectApp("slack").then((response) => {
              showFunctionUI();
            });
          }
        })
        .catch((error) => {
          console.error("Error1:", error);
        });

        function showFunctionUI() {
          integry
            .showFunctionUI("slack-post-message", {
              channel: "general",
              text: "hello world",
            })
            .then((result) => {
              integry
                .invokeFunction("slack-post-message", result)
                .then((response) => {
                  console.log(response);
                })
                .catch((error) => {
                  console.error("Error2:", error);
                });
            })
            .catch((error) => {
              console.error("Error3:", error);
            });
        }
      });
    </script>
    <script src="https://unpkg.com/@integry/sdk/dist/umd/index.umd.js"></script>
  </body>
</html>`;
