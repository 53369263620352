/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import initialAppBuilderModelsState, {
  initialActivityModelsState,
  initialAuthModelsState,
} from "utils/app-builder-models-state";
import initialAppBuilderState, {
  initialActivitiesFormStatus,
  initialActivityFormState,
  initialAuthorizationFormState,
  initialAuthorizationFormStatus,
} from "utils/app-builder-state";
import {
  SET_APP_BUILDER_STATE,
  SET_APP_BUILDER_STATE_ACTIVITIES,
  SET_APP_BUILDER_FORM_STATUS,
  SET_APP_BUILDER_ACTIVITIES_FORM_STATUS,
  SET_APP_BUILDER_WIZARD_MODE,
  SET_APP_MODEL,
  UPDATE_APP_MODEL,
  SET_AUTH_MODEL,
  UPDATE_AUTH_MODEL,
  SET_USER_INFO_ENDPOINT,
  SET_ACTIVITY_IN_ACTIVITY_MODEL,
  UPDATE_USER_INFO_ENDPOINT,
  SET_ACTION_ENDPOINT,
  SET_TRIGGER_ENDPOINT,
  SET_TRIGGER_ENDPOINT_MAIN,
  SET_TRIGGER_DELETE_ENDPOINT,
  UPDATE_ACTION_ENDPOINT,
  UPDATE_ACTIVITY_IN_ACTIVITY_MODEL,
  SET_APP_BUILDER_ACTIVITIES_DATA_SOURCE_FORM_STATUS,
  RESET_APP_BUILDER,
  RESET_APP_BUILDER_MODELS,
  RESET_AUTHORIZATION_FORM,
  RESET_AUTHORIZATION_MODEL,
  RESET_ACTIVITY_MODELS,
  RESET_ACTIVITY_FORM,
  SET_APP_AUTHORIZATION_SECTION_COMPLETE,
  SET_APP_ACTIVITY_SECTION_COMPLETE,
  SET_APP_BASIC_SECTION_COMPLETE,
  SET_APP_FLOW_COMPLETE,
  SET_FIRST_ACTION_ID,
  SET_FIRST_QUERY_ID,
  SET_FIRST_TRIGGER_ID,
  SET_FIRST_AUTH_ID,
  UPDATE_TRIGGER_ENDPOINT,
  UPDATE_TRIGGER_ENDPOINT_MAIN,
  UPDATE_TRIGGER_ENDPOINT_DELETE,
  SET_FLOW_ACTIVITY_TYPE,
  SET_DONT_USE_FLOW_ACTIVITY_TYPE,
  SET_TOKEN_ENDPOINT,
  UPDATE_TOKEN_ENDPOINT,
  APP_LOCK_ALERT_BAR_TEXT,
} from "../actions/app-builder";

const setSection = (action, sectionData) => {
  return {
    ...sectionData,
    [action.form]: {
      ...sectionData[action.form],
      ...action.data,
    },
  };
};

export const appBuilder = (state = { ...initialAppBuilderState }, action) => {
  switch (action.type) {
    case SET_APP_BUILDER_STATE:
      return {
        ...state,
        [action.section]: setSection(action, state[action.section]),
      };
    case SET_APP_BUILDER_STATE_ACTIVITIES:
      return {
        ...state,
        ACTIVITIES: {
          ...state.ACTIVITIES,
          [action.section]: setSection(
            action,
            state.ACTIVITIES[action.section]
          ),
        },
      };
    case SET_APP_BUILDER_FORM_STATUS:
      return {
        ...state,
        formStatus: {
          ...state.formStatus,
          [action.section]: setSection(
            action,
            state.formStatus[action.section]
          ),
        },
      };
    case SET_APP_BUILDER_ACTIVITIES_FORM_STATUS:
      return {
        ...state,
        formStatus: {
          ...state.formStatus,
          ACTIVITIES: {
            ...state.formStatus.ACTIVITIES,
            [action.section]: setSection(
              action,
              state.formStatus.ACTIVITIES[action.section]
            ),
          },
        },
      };
    case SET_APP_BUILDER_ACTIVITIES_DATA_SOURCE_FORM_STATUS:
      return {
        ...state,
        formStatus: {
          ...state.formStatus,
          ACTIVITIES: {
            ...state.formStatus.ACTIVITIES,
            [action.section]: {
              ...state.formStatus.ACTIVITIES[action.section],
              DATA_SOURCE_FORMS: setSection(
                action,
                state.formStatus.ACTIVITIES[action.section].DATA_SOURCE_FORMS
              ),
            },
          },
        },
      };
    case SET_APP_BUILDER_WIZARD_MODE:
      return {
        ...state,
        isWizardEnabled: action.mode,
      };
    case SET_APP_BASIC_SECTION_COMPLETE:
      return {
        ...state,
        isBasicSectionComplete: action.mode,
      };
    case SET_APP_AUTHORIZATION_SECTION_COMPLETE:
      return {
        ...state,
        isAuthorizationSectionComplete: action.mode,
      };
    case SET_APP_ACTIVITY_SECTION_COMPLETE:
      return {
        ...state,
        isActivitySectionComplete: action.mode,
      };
    case SET_APP_FLOW_COMPLETE:
      return {
        ...state,
        isAppFlowComplete: action.mode,
      };
    case SET_FIRST_AUTH_ID:
      return {
        ...state,
        firstAuthID: action.data,
      };
    case SET_FIRST_ACTION_ID:
      return {
        ...state,
        firstActionID: action.data,
      };
    case SET_FLOW_ACTIVITY_TYPE:
      return {
        ...state,
        flowActivityType: action.data,
      };
    case SET_DONT_USE_FLOW_ACTIVITY_TYPE:
      return {
        ...state,
        dontUseFlowActivityType: action.data,
      };
    case SET_FIRST_QUERY_ID:
      return {
        ...state,
        firstQueryID: action.data,
      };
    case SET_FIRST_TRIGGER_ID:
      return {
        ...state,
        firstTriggerID: action.data,
      };
    case RESET_APP_BUILDER:
      return { ...initialAppBuilderState };
    case RESET_AUTHORIZATION_FORM:
      return {
        ...state,
        AUTHORIZATION: { ...initialAuthorizationFormState },
        formStatus: {
          ...state.formStatus,
          AUTHORIZATION: { ...initialAuthorizationFormStatus },
        },
      };
    case RESET_ACTIVITY_FORM:
      return {
        ...state,
        ACTIVITIES: { ...initialActivityFormState },
        formStatus: {
          ...state.formStatus,
          ACTIVITIES: { ...initialActivitiesFormStatus },
        },
      };
    case APP_LOCK_ALERT_BAR_TEXT:
      return {
        ...state,
        appLockAlertText: action.warning,
      };
    default:
      return state;
  }
};

const setModelFields = (action, Model) => {
  return {
    ...Model,
    fields: {
      ...Model.fields,
      ...action.data,
    },
  };
};

export const appBuilderModels = (
  state = { ...initialAppBuilderModelsState },
  action
) => {
  switch (action.type) {
    case SET_APP_MODEL:
      return {
        ...state,
        appModel: action.data,
      };
    case UPDATE_APP_MODEL:
      return {
        ...state,
        appModel: setModelFields(action, state.appModel),
      };
    case SET_AUTH_MODEL:
      return {
        ...state,
        authModel: action.data,
      };
    case UPDATE_AUTH_MODEL:
      return {
        ...state,
        authModel: setModelFields(action, state.authModel),
      };
    case SET_USER_INFO_ENDPOINT:
      return {
        ...state,
        userInfoEndPoint: action.data,
      };
    case UPDATE_USER_INFO_ENDPOINT:
      return {
        ...state,
        userInfoEndPoint: {
          ...state.userInfoEndPoint,
          ...action.data,
        },
      };
    case SET_TOKEN_ENDPOINT:
      return {
        ...state,
        tokenEndpoint: action.data,
      };
    case UPDATE_TOKEN_ENDPOINT:
      return {
        ...state,
        tokenEndpoint: {
          ...state.tokenEndpoint,
          ...action.data,
        },
      };
    case SET_ACTION_ENDPOINT:
      return {
        ...state,
        actionEndpoint: action.data,
      };
    case SET_TRIGGER_ENDPOINT:
      return {
        ...state,
        triggerEndpoint: action.data,
      };
    case SET_TRIGGER_ENDPOINT_MAIN:
      return {
        ...state,
        triggerMainEndpoint: action.data,
      };
    case SET_TRIGGER_DELETE_ENDPOINT:
      return {
        ...state,
        triggerDeleteEndpoint: action.data,
      };
    case UPDATE_ACTION_ENDPOINT:
      return {
        ...state,
        actionEndpoint: {
          ...state.actionEndpoint,
          ...action.data,
        },
      };
    case UPDATE_TRIGGER_ENDPOINT:
      return {
        ...state,
        triggerEndpoint: {
          ...state.triggerEndpoint,
          ...action.data,
        },
      };
    case UPDATE_TRIGGER_ENDPOINT_MAIN:
      return {
        ...state,
        triggerMainEndpoint: {
          ...state.triggerMainEndpoint,
          ...action.data,
        },
      };
    case UPDATE_TRIGGER_ENDPOINT_DELETE:
      return {
        ...state,
        triggerDeleteEndpoint: {
          ...state.triggerDeleteEndpoint,
          ...action.data,
        },
      };
    case SET_ACTIVITY_IN_ACTIVITY_MODEL:
      // eslint-disable-next-line no-case-declarations
      const activityType1: string = action.activityType.toLowerCase();
      return {
        ...state,
        activityModel: {
          ...state.activityModel,
          [activityType1]: action.data,
        },
      };
    case UPDATE_ACTIVITY_IN_ACTIVITY_MODEL:
      // eslint-disable-next-line no-case-declarations
      const activityType2: string = action.activityType.toLowerCase();
      return {
        ...state,
        activityModel: {
          ...state.activityModel,
          [activityType2]: {
            ...state.activityModel[activityType2],
            ...action.data,
          },
        },
      };
    case RESET_APP_BUILDER_MODELS:
      return { ...initialAppBuilderModelsState };
    case RESET_AUTHORIZATION_MODEL:
      return {
        ...state,
        authModel: { ...initialAuthModelsState },
        userInfoEndPoint: {},
      };
    case RESET_ACTIVITY_MODELS:
      return {
        ...state,
        activityModel: { ...initialActivityModelsState },
        actionEndpoint: {},
        triggerEndpoint: {},
        triggerMainEndpoint: {},
        triggerDeleteEndpoint: {},
      };
    default:
      return state;
  }
};
