/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unescaped-entities */
import React, { ReactElement, useState, useEffect } from "react";
import Switch from "@mui/material/Switch";

import IntegryPagination from "components/integry-design-system/molecules/pagination";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import IntegryLoader from "components/integry-loader";

// import lang from "utils/lang/en";
import { convertDateToLocal } from "utils/functions";

import MagnifierIcon from "images/misc_search.svg";
import MagnifierIconGrey from "images/misc_search_grey.svg";
import CloseIcon from "images/x.svg";
import exlaimationIconOutline from "images/exclaimation_icon_outline.svg";
import SortIconDefault from "images/sort.svg";
import SortIconDesc from "images/sort-applied.svg";
// import CopyIconActive from "images/copy_icon_active.svg";
import TruncateBullets from "images/truncate_bullets.svg";
import { setToggleStatus, getBundleDetails, getAppRequests } from "./api";
// import EyeIcon from "images/eye_primary.svg";

import "./styles.scss";
import "legacy-features/integrations/styles.scss";

interface IFetchOptions {
  search?: string | undefined | null;

  pageConfig?: {
    page: number;
    pageSize: number;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortOrder?: any;
  searchSteps?: boolean;
}
interface IRequest {
  appName: string;
  usecase: string;
  userId: string;
  username: string;
  submittedOn: string;
}

const AppsRequest = (props): ReactElement => {
  const {
    bundle: bundleProp = {},
    dontFetchBundle,
    onToggleStatusUpdate,
    ignoreSignInMethod = false,
  } = props;
  const [bundle, setBundle] = useState(bundleProp);
  const {
    logo_url,
    title,
    header_color = "#EEEEE",
    accept_app_requests = false,
    signin_method: signInMethodProp = "none",
  } = bundle;
  const [isAppRequestAllowed, setIsAppRequestAllowed] = useState(false);
  const [fetchingBundle, setFetchingBundle] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [settingToggle, setSettingToggle] = useState(false);
  const [hasRequests, setHasRequests] = useState(false);
  const [appRequests, setAppRequests] = useState<IRequest[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sorting, setSorting] = useState<any>({
    created: "desc",
  });
  const [searchTxt, setSearchTxt] = useState("");
  const [page, setPage] = useState({
    page: 1,
    pageSize: 10,
  });
  const [copiedText, setCopiedText] = useState({});
  const [signInMethod, setSignInMethod] = useState("none");
  useEffect(() => {
    if (!dontFetchBundle) {
      setFetchingBundle(true);
      getBundleDetails(bundle.id)
        .then((bundleResponse) => {
          const { data = {} } = bundleResponse;
          const {
            accept_app_requests: acceptAppRequests = false,
            signin_method = "none",
          } = data;
          setBundle(data);
          setIsAppRequestAllowed(acceptAppRequests);
          setSignInMethod((signin_method || "").toLowerCase());
        })
        .catch((e) => {
          console.error("ERROR while fetching bundle details", {
            e,
          });
          setIsAppRequestAllowed(accept_app_requests);
        })
        .finally(() => {
          setFetchingBundle(false);
        });
    } else {
      setIsAppRequestAllowed(accept_app_requests);
      setSignInMethod((signInMethodProp || "").toLowerCase());
      setFetchingBundle(false);
    }
    fetchData({
      search: searchTxt,
      pageConfig: page,
      sortOrder: sorting,
    });
  }, []);

  const fetchData = (
    options: IFetchOptions,
    skipFetching = false,
    pollFrequency = 0
  ): void => {
    if (!skipFetching) {
      setFetching(true);
    }
    const { search = "", sortOrder, pageConfig } = options;
    let queryParams = "";

    if (search && search !== "") {
      queryParams = `search=${search}`;
    }
    if (sortOrder.created) {
      // const sortValue = sortOrder.created === "desc" ? "created" : "created";
      let sortValue = "";
      if (sortOrder.created !== "desc") {
        sortValue = "created";
      }
      if (sortValue !== "") {
        queryParams =
          queryParams !== ""
            ? `${queryParams}&ordering=${sortValue}`
            : `ordering=${sortValue}`;
      }
      const { page: pageValue = 1, pageSize = 10 } = pageConfig || {};
      queryParams =
        queryParams !== ""
          ? `${queryParams}&page=${pageValue}&page_size=${pageSize}`
          : `page=${pageValue}&page_size=${pageSize}`;
    }
    getAppRequests({
      bundleId: bundle.id,
      queryParams,
    })
      .then((requestsResponse) => {
        const { data = {} } = requestsResponse || {};
        const { count = 0, results = [] } = data;
        // const { count = 0, results = [] } = staticData;
        if (results.length > 0) {
          setHasRequests(true);
        }
        const tempRequests: IRequest[] = results.map((request): IRequest => {
          return {
            appName: request.app || "N/A",
            usecase: request.description || "N/A",
            userId: request.user_id || "N/A",
            username: request.user_name || "N/A",
            submittedOn: request.created
              ? convertDateToLocal(request.created)
              : "N/A",
          };
        });
        setAppRequests(tempRequests);
        setTotalCount(count);
      })
      .catch((e) => {
        setTotalCount(0);
        setAppRequests([]);
        console.error("ERROR while fetching app requests", { e });
      })
      .finally(() => {
        setFetching(false);
      });
  };

  const handleAppRequestToggle = (toggleValue = false): void => {
    setSettingToggle(true);
    setToggleStatus(bundle.id, { accept_app_requests: toggleValue })
      .then((response) => {
        setIsAppRequestAllowed(toggleValue);
        if (onToggleStatusUpdate) {
          onToggleStatusUpdate(toggleValue, response.data);
        }
        if (toggleValue) {
          fetchData({
            search: searchTxt,
            pageConfig: page,
            sortOrder: sorting,
          });
        }
      })
      .catch((e) => {
        console.error("ERROR while allowing app requests", { e });
      })
      .finally(() => {
        setSettingToggle(false);
      });
  };
  const handleOnSearch = (text): void => {
    setPage({
      page: 1,
      pageSize: page.pageSize,
    });
    fetchData(
      {
        search: text,
        pageConfig: {
          page: 1,
          pageSize: page.pageSize,
        },
        sortOrder: sorting,
      },
      true
    );
  };

  const handleOnSorting = (id): void => {
    let tempSorting = {};
    tempSorting = {
      [id]: sorting[id] === "desc" ? "asc" : "desc",
    };
    setSorting(tempSorting);
    fetchData({
      search: searchTxt,
      pageConfig: page,
      sortOrder: tempSorting,
    });
  };

  const handlePageChange = (pageConfig): void => {
    setPage(pageConfig);
    fetchData({
      search: searchTxt,
      pageConfig,
      sortOrder: sorting,
    });
  };

  const renderTable = (): JSX.Element => {
    const offset = page.pageSize * page.page;
    return (
      <>
        <table className="integry-table">
          <thead>
            <tr>
              {tableConfig.request.config.map((configItem: any) => {
                if (configItem.tooltip) {
                  return (
                    <th>
                      {configItem.label}
                      <IntegryTooltip
                        tooltipText={configItem.tooltip}
                        placement="top"
                      >
                        <img
                          width={15}
                          height={15}
                          src={exlaimationIconOutline}
                          style={{
                            marginLeft: "5px",
                            marginTop: "-1px",
                            transform: "rotateZ(180deg)",
                          }}
                          alt="tooltip_heading"
                        />
                      </IntegryTooltip>
                    </th>
                  );
                }
                if (configItem.allowSorting) {
                  return (
                    <th>
                      {configItem.label}
                      <img
                        width={15}
                        height={15}
                        style={{
                          marginLeft: "5px",
                          marginTop: "-2px",
                          cursor: "pointer",
                        }}
                        src={
                          sorting[configItem.sortingIdentifier || configItem.id]
                            ? SortIconDesc
                            : SortIconDefault
                        }
                        className={
                          sorting[
                            configItem.sortingIdentifier || configItem.id
                          ] &&
                          sorting[
                            configItem.sortingIdentifier || configItem.id
                          ] === "asc"
                            ? "table-sort-icon-asc"
                            : ""
                        }
                        alt="sort_icon"
                        onClick={() => {
                          handleOnSorting(
                            configItem.sortingIdentifier || configItem.id
                          );
                        }}
                        aria-hidden="true"
                      />
                    </th>
                  );
                }
                return <th>{configItem.label}</th>;
              })}
              {fetching && <th>Loading...</th>}
              {!fetching && (
                <th>
                  {totalCount > 0 ? offset - (page.pageSize - 1) : 0}-
                  {offset > totalCount ? totalCount : offset} of {totalCount}
                </th>
              )}
            </tr>
          </thead>
          {appRequests.length > 0 && (
            <tbody
              style={
                !fetching
                  ? {}
                  : {
                      opacity: "0.5",
                    }
              }
            >
              {appRequests.map((row: any) => {
                return (
                  <tr>
                    {tableConfig.request.config.map((configItem: any) => {
                      const {
                        id,
                        subConfig,
                        // type = "text",
                        tdStyle = {},
                        showTooltip = false,
                        tooltipField = "",
                        hideSameValueTooltip = false,
                        truncateLimit = 15,
                      } = configItem;
                      if (configItem.truncate) {
                        const tooltipStyle = {
                          p: {
                            fontSize: "9px",
                            lineHeight: "15px",
                            margin: "0",
                            color: "#333333",
                          },
                          p_lg: {
                            fontSize: "12px",
                            lineHeight: "15px",
                            margin: "0",
                            color: "#333333",
                          },
                          div: {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          },
                        };
                        return (
                          <td>
                            <div
                              className="integry-table--column"
                              style={tdStyle}
                            >
                              {row[id].length > truncateLimit ? (
                                <IntegryTooltip
                                  tooltipText={
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <p style={tooltipStyle.p_lg}>{row[id]}</p>
                                      <p style={tooltipStyle.p}>
                                        {copiedText[id] &&
                                        copiedText[id] === row[id]
                                          ? "(Copied)"
                                          : "(Click to copy)"}
                                      </p>
                                    </div>
                                  }
                                  placement="bottom"
                                >
                                  <div
                                    onClick={() => {
                                      navigator.clipboard.writeText(row[id]);
                                      setCopiedText({
                                        [id]: row[id],
                                      });
                                    }}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    aria-hidden="true"
                                  >
                                    {truncateLimit < 16 &&
                                      row[id].substring(0, 3)}
                                    {truncateLimit > 15 &&
                                      row[id].substring(0, truncateLimit - 4)}
                                    <img
                                      src={TruncateBullets}
                                      style={{
                                        width: "15px",
                                        margin:
                                          truncateLimit > 15 ? "0" : "0 2px",
                                        marginTop:
                                          truncateLimit > 15 ? "-6px" : "-3px",
                                        objectFit: "none",
                                      }}
                                      alt="truncate_bullets"
                                    />
                                    {truncateLimit < 16 &&
                                      row[id].substring(row[id].length - 4)}
                                  </div>
                                </IntegryTooltip>
                              ) : (
                                `${row[id]}`
                              )}
                            </div>
                          </td>
                        );
                      }
                      return (
                        <td>
                          <div
                            className="integry-table--column"
                            style={tdStyle}
                          >
                            <IntegryTooltip
                              hideToolTip={
                                !showTooltip ||
                                row[tooltipField] === "N/A" ||
                                (hideSameValueTooltip &&
                                  row[id] === row[tooltipField])
                              }
                              tooltipText={row[tooltipField] || ""}
                              placement="bottom"
                            >
                              <div>
                                {!subConfig && [row[id]]}
                                {subConfig &&
                                  subConfig.map((subItem) => {
                                    if (subItem.type === "img") {
                                      return (
                                        <img
                                          src={row[id][subItem.id]}
                                          alt={`ico_${row[id][subItem.id]}`}
                                        />
                                      );
                                    }
                                    return <span>{row[id][subItem.id]}</span>;
                                  })}
                              </div>
                            </IntegryTooltip>
                          </div>
                        </td>
                      );
                    })}
                    <td
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto auto",
                        gridGap: "11px",
                      }}
                    />
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        {!fetching && appRequests.length > 0 && (
          <IntegryPagination
            defaultCurrentPage={page.page}
            defaultPageSize={page.pageSize}
            total={totalCount}
            customStyle={{ marginTop: "20px" }}
            onPageChange={(pageConfig) => {
              handlePageChange(pageConfig);
            }}
            onPageSizeChange={(pageConfig) => {
              handlePageChange(pageConfig);
            }}
          />
        )}
        {fetching && <IntegryLoader />}
      </>
    );
  };

  if (fetchingBundle) {
    return <IntegryLoader />;
  }
  return (
    <div id="apps-request" className="apps-request">
      <div className="apps-request--toggle_container">
        <div
          className={`apps-request--toggle_control_group${
            settingToggle ? " setting-toggle__loader" : ""
          }`}
        >
          <div className="field-switch-title-wrap">
            {ignoreSignInMethod || signInMethod !== "none" ? (
              <Switch
                disableRipple
                disableFocusRipple
                disabled={signInMethod === "none" && !ignoreSignInMethod}
                className={
                  isAppRequestAllowed
                    ? `apps-request__mui-swtich`
                    : "apps-request__mui-swtich inactive-switch"
                }
                checked={
                  isAppRequestAllowed &&
                  (signInMethod !== "none" || ignoreSignInMethod)
                }
                onChange={(e) => {
                  handleAppRequestToggle(e.target.checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            ) : (
              <IntegryTooltip
                placement="top"
                tooltipText="This enables when user sign-in method is selected"
              >
                <div>
                  <Switch
                    disableRipple
                    disableFocusRipple
                    disabled={signInMethod === "none"}
                    className="apps-request__mui-swtich inactive-switch apps-request__mui-swtich--disabled"
                    checked={isAppRequestAllowed && signInMethod !== "none"}
                    onChange={(e) => {
                      handleAppRequestToggle(e.target.checked);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
              </IntegryTooltip>
            )}
            <div
              style={{
                maxWidth: "295px",
              }}
            >
              <p className="title">
                <b>Accept Requests</b>
              </p>
              <p
                className="description"
                style={{
                  minWidth: "280px",
                  width: "280px",
                }}
              >
                Your users will be able to request apps from the marketplace
              </p>
            </div>
          </div>
        </div>
        <div className="apps-request--toggle_control_group">
          <div className="apps-request--marketplace-preview">
            <div
              className="apps-request--marketplace-preview__container"
              style={
                bundle.background_color
                  ? {
                      background: bundle.background_color,
                    }
                  : {}
              }
            >
              <div className="apps-request--marketplace-preview__container--header">
                {logo_url && (
                  <img src={logo_url} alt={`logo_${bundle.id || "-1"}`} />
                )}
                {title && (
                  <span
                    style={{
                      color: header_color,
                    }}
                  >
                    {title}
                  </span>
                )}
              </div>
              <div className="apps-request--marketplace-preview__container--content">
                <div className="apps-request--marketplace-preview__content-search-bar">
                  <div>
                    <img
                      width={12}
                      height={12}
                      src={MagnifierIcon}
                      alt="search_ico"
                      style={{
                        marginRight: "7px",
                      }}
                    />
                    <span>Clickup</span>
                  </div>
                  <img width={10} height={10} src={CloseIcon} alt="close_ico" />
                </div>
                <div className="apps-request--marketplace-preview__content-search-result">
                  <p className="marketplace-preview__content-search-result__faded-text">
                    No results for “Clickup”
                  </p>
                  <div className="marketplace-preview__content-search-result__tell-more-cta">
                    <span>Can't find what you're looking for?</span>
                    <span className="tell-more-cta">Tell us more</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isAppRequestAllowed && appRequests.length < 1 && (
        <p className="marketplace-preview__help-text">
          This is what your users will see
        </p>
      )}
      {isAppRequestAllowed && appRequests.length < 1 && !hasRequests && (
        <div className="apps-request--table_container">
          <div className="apps-request--table_no_items_found">
            <p>No request received. User requests will be shown here.</p>
          </div>
        </div>
      )}
      {/* {appRequests.length > 0 && ( */}
      {hasRequests && (
        <div className="apps-request--table_container">
          <div className="apps-request--table__search-container">
            <input
              className="apps-request--table__search-text"
              placeholder="Search by App or Usecase"
              value={searchTxt}
              style={{
                lineHeight: "15px",
              }}
              onChange={(e) => {
                if (e) {
                  setSearchTxt(e.target.value);
                  handleOnSearch(e.target.value);
                }
              }}
            />
            {searchTxt !== "" ? (
              <img
                src={CloseIcon}
                alt="search_ico"
                style={{
                  cursor: "pointer",
                  width: "15px",
                  height: "15px",
                }}
                aria-hidden="true"
                onClick={() => {
                  setSearchTxt("");
                  handleOnSearch("");
                }}
              />
            ) : (
              <img
                style={{
                  width: "15px",
                  height: "15px",
                }}
                src={MagnifierIconGrey}
                alt="search_ico"
              />
            )}
          </div>
          <div className="apps-request--table__content view-table">
            {renderTable()}
          </div>
          {isAppRequestAllowed && appRequests.length < 1 && !fetching && (
            <div className="apps-request--table_container">
              <div
                className="apps-request--table_no_items_found"
                style={{
                  marginTop: "-20px",
                }}
              >
                <p style={{ wordBreak: "break-word", padding: "10px 20px" }}>
                  {searchTxt !== ""
                    ? `We can't find any requests for "${searchTxt}". Try adjusting your search.`
                    : "We can't find any requests. Try adjusting your search."}
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const tableConfig = {
  request: {
    config: [
      {
        id: "appName",
        label: "App",
      },
      {
        id: "usecase",
        label: "Usecase",
        truncate: true,
        truncateLimit: 135,
        tdStyle: {
          whiteSpace: "break-spaces",
          wordBreak: "break-word",
          minWidth: "250px",
          maxWidth: "300px",
        },
      },
      {
        id: "userId",
        label: "User ID",
        truncate: true,
        truncateLimit: 92,
        tdStyle: {
          // whiteSpace: "break-spaces",
          maxWidth: "230px",
          // minWidth: "120px",
          whiteSpace: "break-spaces",
          wordBreak: "break-all",
        },
      },
      // {
      //   id: "username",
      //   label: "User Name",
      //   tdStyle: {
      //     whiteSpace: "break-spaces",
      //     wordBreak: "break-all",
      //   },
      // },
      {
        id: "submittedOn",
        label: "Submitted",
        allowSorting: true,
        sortingIdentifier: "created",
      },
    ],
  },
};

export default AppsRequest;

const staticData = {
  count: 25,
  meta: null,
  total_pages: 3,
  results: [
    {
      id: 25,
      app: "clickup123",
      description:
        "Send me a message on slack when a task is created in clickup",
      bundle_id: 6319,
      created: "2022-11-23T09:51:42.661231Z",
      user_id:
        "maryum@integry.io/maryum@integry.io-748/maryum@integry.io/maryum@integry.io-74812345678912345",
      user_name: "maryum@integry.io/maryum@integry.io-748",
    },
    {
      id: 24,
      app: "clickup123",
      description:
        "Send me a message on slack when a task is created in clickup. Send me a message on slack when a task is created in clickup.Send me a message on slack when a task is created in clickup. Send me a message on slack when a task is created in clickup.",
      bundle_id: 6319,
      created: "2022-11-23T09:51:41.893657Z",
      user_id: "api-key---nwmb-87531",
      user_name: "api-key---nwmb-8753",
    },
    {
      id: 23,
      app: "clickup123",
      description: "description123",
      bundle_id: 6319,
      created: "2022-11-23T09:51:41.211672Z",
      user_id: "api-key---nwmb-8753",
      user_name: "api-key---nwmb-8753",
    },
    {
      id: 22,
      app: "clickup123",
      description: "description123",
      bundle_id: 6319,
      created: "2022-11-23T09:51:40.522039Z",
      user_id: "api-key---nwmb-8753",
      user_name: "api-key---nwmb-8753",
    },
    {
      id: 21,
      app: "clickup123",
      description: "description123",
      bundle_id: 6319,
      created: "2022-11-23T09:51:39.892425Z",
      user_id: "api-key---nwmb-8753",
      user_name: "api-key---nwmb-8753",
    },
    {
      id: 20,
      app: "clickup123",
      description: "description123",
      bundle_id: 6319,
      created: "2022-11-23T09:51:39.209215Z",
      user_id: "api-key---nwmb-8753",
      user_name: "api-key---nwmb-8753",
    },
    {
      id: 19,
      app: "clickup123",
      description: "description123",
      bundle_id: 6319,
      created: "2022-11-23T09:51:38.491852Z",
      user_id: "api-key---nwmb-8753",
      user_name: "api-key---nwmb-8753",
    },
    {
      id: 18,
      app: "clickup123",
      description: "description123",
      bundle_id: 6319,
      created: "2022-11-23T09:51:37.763200Z",
      user_id: "api-key---nwmb-8753",
      user_name: "api-key---nwmb-8753",
    },
    {
      id: 17,
      app: "clickup123",
      description: "description123",
      bundle_id: 6319,
      created: "2022-11-23T09:51:37.061924Z",
      user_id: "api-key---nwmb-8753",
      user_name: "api-key---nwmb-8753",
    },
    {
      id: 16,
      app: "clickup123",
      description: "description123",
      bundle_id: 6319,
      created: "2022-11-23T09:51:36.395482Z",
      user_id: "api-key---nwmb-8753",
      user_name: "api-key---nwmb-8753api-key---nwmb-8753api-key---nwmb-8753",
    },
  ],
  links: {
    previous: null,
    next: "http://localhost:8001/bundle/6319/app_requests/?page=2",
  },
};
