import { createApi } from "@reduxjs/toolkit/query/react";
import config from "utils/config";
import { integryBaseQuery } from "utils/http";
import { AppInMarketplace } from "../interface";

interface MaketplaceAppsResponse {
  count: number;
  links: { previous?: string; next?: string };
  meta: never;
  results: AppInMarketplace[];
  total_pages: number;
}

export const useAppsInMarketplace = createApi({
  reducerPath: "appsInMarketplace",
  baseQuery: integryBaseQuery({
    baseUrl: config.apiBaseURL || "",
  }),
  tagTypes: ["appsInMarketplace"],
  refetchOnMountOrArgChange: true, // this is to be removed once the other flow create/update endpoints are moved to rtk
  endpoints(build) {
    return {
      listAppsInMarketplace: build.query<
        MaketplaceAppsResponse,
        {
          searchText: string;
          page: number;
        }
      >({
        query: ({ searchText, page }) => ({
          url: `/api/v1/marketplace-apps/?page_size=1000&page=1&search=${searchText}`,
          method: "get",
        }),
        providesTags: ["appsInMarketplace"],
      }),
      addAppInMarketPlace: build.mutation({
        query({ appId }) {
          return {
            url: `/api/v1/marketplace-apps/`,
            method: "POST",
            data: {
              app_id: appId,
            },
          };
        },
        invalidatesTags: ["appsInMarketplace"],
      }),
      removeAppFromMarketPlace: build.mutation({
        query({ appId }) {
          return {
            url: `/api/v1/marketplace-apps/${appId}/`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["appsInMarketplace"],
      }),
    };
  },
});

export const {
  useListAppsInMarketplaceQuery,
  useAddAppInMarketPlaceMutation,
  useRemoveAppFromMarketPlaceMutation,
} = useAppsInMarketplace;
