import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { getAllFeatureFlags } from "react-launch-darkly";

interface FeatureFlagsContextProps {
  flags: Record<string, boolean>;
  loading: boolean;
}

const FeatureFlagsContext = createContext<FeatureFlagsContextProps | undefined>(
  undefined
);

const useFeatureFlags = ({ flag }: { flag: string }): boolean => {
  const context = useContext(FeatureFlagsContext);
  if (!context) {
    throw new Error(
      "useFeatureFlags must be used within a FeatureFlagsProvider"
    );
  }
  return context?.flags ? context.flags[flag] : false;
};

interface FeatureFlagsProviderProps {
  children: ReactNode;
}

const FeatureFlagsProvider: React.FC<FeatureFlagsProviderProps> = ({
  children,
}) => {
  const [flags, setFlags] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getAllFeatureFlags().then((featureflags: Record<string, boolean>) => {
      setFlags(featureflags);
      setLoading(false);
    });
  }, []);

  return (
    <FeatureFlagsContext.Provider value={{ flags, loading }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export { FeatureFlagsProvider, useFeatureFlags };
