import React from "react";
const PreloadImages = ({ images = [] }) => {
  return (
    <div style={{ display: "None" }}>
      {images.map((image, index) => (
        <img src={image} key={index} alt="preload" />
      ))}
    </div>
  );
};
export default React.memo(PreloadImages);
