/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from "react-toastify";
import redCrossIcon from "images/red-cross-icon.svg";

export const templateErrorMessage = (title: string, msg: string): any => {
  return (
    <div>
      <div className="lable error">{title}</div>
      <div className="message">{msg}</div>
    </div>
  );
};

const customCloseButton = (): any => {
  return <img className="custom-close-toast" src={redCrossIcon} alt="Close" />;
};

export const renderApiError = (title: string, msg: string): void => {
  toast.error(templateErrorMessage(title, msg), {
    autoClose: 5000,
    position: "bottom-left",
    closeButton: customCloseButton(),
  });
};

export const renderSuccessToast = (msg: string): void => {
  toast.success(<p className="toast-text">{msg}</p>);
};

export const renderInfoToast = (msg: string): void => {
  toast.info(<p className="toast-text">{msg}</p>);
};
