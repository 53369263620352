import { ReactElement } from "react";

import Button from "components/integry-design-system/molecules/button";

import { ReactComponent as OpenLinkImage } from "images/open-link-image.svg";

import "./styles.scss";

interface EmptyMessageProps {
  content: ReactElement;
  actions?: ReactElement;
  docLink?: string;
}

const EmptyPageMessage = (props: EmptyMessageProps): ReactElement => {
  const { content, actions, docLink = "" } = props;
  return (
    <div className="empty-page-message">
      <div className="empty-page-message__content">{content}</div>
      <div className="empty-page-message__actions">
        {actions && <>{actions}</>}
        {docLink && (
          <Button
            className="empty-page-message__actions__button--docs"
            variation="link"
            action={() => {
              window.open(docLink, "_blank");
            }}
          >
            Read the docs <OpenLinkImage />
          </Button>
        )}
      </div>
    </div>
  );
};

export default EmptyPageMessage;
