/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { useState, ReactElement } from "react";
import SearchBar from "components/common/search-input-field/searchbar-v3";
import { ICheckboxProps } from "components/integry-design-system/molecules/checkbox-selection";
import CheckboxDropdown from "components/integry-design-system/molecules/dropdowns/checkbox-dropdown";
import Tag from "components/integry-design-system/atoms/tag";

import DateRangePickerV2, {
  DateRangeValue,
} from "components/integry-design-system/molecules/date-range-picker/date-range-v2";
import urls from "utils/router-urls";
import { getQueryParamAsObject } from "utils/query-params";

import { useFeatureFlags } from "context/featureFlagsContext";
import { useGetPollsQuery } from "../../api/usePollApi";
import StepListingTable from "./listing";

import "./styles.scss";
import { Poll } from "../../interface";

interface IProps {
  integrationId: string;
}

type QueryParams = {
  search?: string;
  poll_statuses?: string | string[];
  start_time_range_start?: string;
  start_time_range_end?: string;
  run_id?: string;
  has_runs?: string[];
};

const PollsListing = (props: IProps): ReactElement => {
  const { integrationId } = props;
  const runCountfilter = useFeatureFlags({ flag: "polls-listing" });

  const [expandedPayloadStepId, setExpandedPayloadStepId] =
    useState<string>("-1");

  const [queryArguements, setQueryArguements] = useState<{
    search: string;
    stepStatus: string[];
    page: number;
    has_runs: string[];
  }>({
    search: "",
    stepStatus: [],
    page: 1,
    has_runs: runCountfilter ? [] : [],
  });

  const queryParams = getQueryParamAsObject() as QueryParams;
  const [runStartTimeRange, setRunStartTimeRange] =
    useState<DateRangeValue | null>(() => {
      const start = queryParams.start_time_range_start
        ? new Date(queryParams.start_time_range_start)
        : "";
      const end = queryParams.start_time_range_end
        ? new Date(queryParams.start_time_range_end)
        : "";

      if (start && end) {
        return {
          startDate: start,
          endDate: end,
          key: "selection",
        };
      }
      return null;
    });
  const {
    data: runSteps,
    isLoading: isLoadingSteps,
    isFetching: isFetchingSteps,
  } = useGetPollsQuery(
    {
      itnegration_id: integrationId,
      search: queryArguements.search,
      stepStatus: queryArguements.stepStatus,
      page: queryArguements.page,
      start_time_range_start: runStartTimeRange?.startDate?.toISOString() || "",
      start_time_range_end: runStartTimeRange?.endDate?.toISOString() || "",
      has_runs:
        queryArguements.has_runs.length > 0 ? queryArguements.has_runs[0] : "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const tableConfig = {
    steps: {
      config: [
        {
          id: "id",
          label: "Poll ID",
          truncate: true,
        },
        {
          id: "start_time",
          label: "Start Time",
        },
        {
          id: "app",
          subConfig: [
            {
              id: "icon_url",
              type: "img",
            },
            {
              id: "activity_name",
            },
            {
              type: "tag",
              id: "activity_version",
            },
          ],
          label: "Event",
          type: "",
        },
        {
          id: "status",
          label: "Status",
          type: "tag",
        },
        {
          id: "network_code",
          label: "Network Code",
          tdStyle: {
            gap: "10px",
          },
          showTooltip: true,
          tooltipField: "network_code_description",
        },
        {
          id: "runs_count",
          label: "Runs",
          type: "link",
          tooltipField: "View Runs",
          getLink: (step: Poll) =>
            `/wapp${urls.integrationRuns.replace(
              ":integrationId",
              integrationId
            )}?search=${step.id}&poll_id=${step.id}`,
        },
      ],
    },
  };

  const getStepStatusOptions = (): Array<ICheckboxProps> => {
    const lastRunStatusOptions = [] as Array<ICheckboxProps>;
    lastRunStatusOptions.push(
      {
        render: <Tag label="OK" type="run" />,
        value: "OK",
        label: "OK",
      },
      {
        render: <Tag label="Failed" type="run" />,
        value: "FAILED",
        label: "Failed",
      },
      {
        render: (
          <Tag
            customStyle={{
              background: "#DCEAFF",
              color: "#5194F8",
            }}
            label="Running..."
            type="run"
          />
        ),
        value: "RUNNING",
        label: "Running...",
      }
      /* {
        render: <Tag label="True" type="run" />,
        value: "TRUE",
        label: "True",
      },
      {
        render: <Tag label="False" type="run" />,
        value: "FALSE",
        label: "False",
      } */
    );
    return lastRunStatusOptions;
  };

  const showMainLoader =
    isLoadingSteps || (isFetchingSteps && queryArguements.page === 1);

  return (
    <div className="integration-polls-section expanded">
      <div className="polls-content">
        <div className="flex gap-10px">
          <SearchBar
            searchQueryProp={queryArguements.search}
            onSearch={(value) => {
              setQueryArguements((prev) => ({
                ...prev,
                search: value,
                page: 1,
              }));
            }}
            placeholder="Search by ID, event name, or data in payload"
          />
          <DateRangePickerV2
            value={runStartTimeRange}
            onChange={setRunStartTimeRange}
            tooltipText="Filter on Start Time"
            plaseholder={["From", "To"]}
          />
          <CheckboxDropdown
            options={getStepStatusOptions()}
            value={queryArguements.stepStatus}
            onChange={(value) => {
              setQueryArguements((prev) => ({
                ...prev,
                stepStatus: value,
                page: 1,
              }));
            }}
            placeholder="Status"
            tooltipText="Filter by step status"
          />
          {runCountfilter && (
            <CheckboxDropdown
              options={[
                {
                  value: "true",
                  label: "1+ runs",
                },
              ]}
              value={queryArguements.has_runs}
              onChange={(value) => {
                setQueryArguements((prev) => ({
                  ...prev,
                  has_runs: value,
                  page: 1,
                }));
              }}
              placeholder="Runs"
              tooltipText="Filter by run count"
            />
          )}
          {!isLoadingSteps && (
            <div className="txt-sm-liight-gray align-center">{`${
              runSteps?.count.toLocaleString() || 0
            } ${runSteps?.count === 1 ? "Poll" : "Polls"}`}</div>
          )}
        </div>

        <div className="integry-views integry-scrollbar-v2">
          <div className="view-table">
            <StepListingTable
              expandedPayloadStepId={expandedPayloadStepId}
              tableConfig={tableConfig}
              setExpandedPayloadStepId={setExpandedPayloadStepId}
              handleOnSorting={() => ({})}
              steps={runSteps?.results || []}
              sorting={{
                start_time: "desc",
              }}
              fetching={showMainLoader}
              hasMore={showMainLoader ? false : !!runSteps?.has_more}
              fetchMoreSteps={() => {
                setQueryArguements((prev) => ({
                  ...prev,
                  page: prev.page + 1,
                }));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PollsListing;
