/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useState } from "react";
import StepPayloadCompnent, {
  sectionNames,
  stepMetaObjectPayload,
} from "components/integry-design-system/organisms/integration-view/step-payload-component";
import IntegryLoader from "components/integry-loader";

import webappConfig from "utils/config";
import { ActivityType } from "enums/activity-types";

import { RunSteps } from "../../interface";
import SkeletonLoader from "./skeleton-loader";
import { useGetStepChildrenQuery } from "../../api/useRunStepsApi";
import StepRow from "./row";

interface RenderTableProps {
  config: any[];
  data?: RunSteps[];
  node?: number;
  hideHeading?: boolean;
  autoExpandInError?: string[];
  expandedStepIdRef?: any;
  initiallyToExpandedSteps?: any;
  expandedPayloadStepId?: string;
  handleStepExpansion?: any;
  setExpandedPayloadStepId?: any;
  setViewStepPayloadData?: any;
  fetchStepPayloadData?: any;
  viewStepPayloadData: stepMetaObjectPayload;
  expandedStepId?: any;
  fetching: boolean;
  integrationId: string;
  runId: string;
}

const RenderTable = (props: RenderTableProps): ReactElement => {
  const {
    config,
    data = [],
    node = 0,
    expandedStepIdRef,
    expandedPayloadStepId,
    handleStepExpansion,
    setExpandedPayloadStepId,
    setViewStepPayloadData,
    fetchStepPayloadData,
    viewStepPayloadData,
    expandedStepId,
    fetching,
  } = props;

  return (
    <>
      {data.length > 0 && (
        <div
          className="div-body integry-scrollbar-v2"
          style={
            !fetching
              ? {}
              : {
                  opacity: "0.5",
                }
          }
        >
          {data.map((step, index) => {
            let rowStyle = {};
            if (expandedStepIdRef?.current?.includes(step.id)) {
              rowStyle = {
                ...rowStyle,
                borderRadius: "6px 6px 0px 0px",
              };
            }
            if (node > 0) {
              const indent = {
                left: ((111 - node * 10) / 1209) * 100,
                right: ((203 - node * 10) / 1209) * 100,
                padding: 24 - node * 10,
              };
              rowStyle = {
                ...rowStyle,
                padding: indent.padding < 0 ? "0px" : `0 ${indent.padding}px`,
              };
            }
            if (step.id === expandedPayloadStepId) {
              rowStyle = {
                ...rowStyle,
                borderBottom: "none",
                borderRadius: "6px 6px 0 0px",
              };
            }

            let hiddenSections: sectionNames[] = [];
            if (
              step.activity_type === "CODE" ||
              step.app.id === webappConfig.storage.appId ||
              step.activity_type === ActivityType.Loop
            ) {
              hiddenSections = [
                sectionNames.REQUEST_URL,
                sectionNames.REQUEST_HEADERS,
                sectionNames.RESPONSE_HEADERS,
              ];
            }

            let requestTabTitle = "Request";
            if (step.activity_type === "CODE") {
              requestTabTitle = "Code";
            } else if (step.app.id === webappConfig.storage.appId) {
              requestTabTitle = "Input";
            } else if (
              step.activity_id === webappConfig.incomingWebhook.activityId
            ) {
              requestTabTitle = "Payload";
            }

            return (
              <div
                className={`row-container${
                  expandedStepId.includes(step.id)
                    ? " row-container__expanded"
                    : ""
                } ${index === 0 ? "first-row" : ""}
                ${index === data.length - 1 ? "last-row" : ""}`}
                key={step.id}
              >
                <StepRow
                  config={config}
                  step={step}
                  expandedStepId={expandedStepId}
                  expandedPayloadStepId={expandedPayloadStepId}
                  onExpandClick={handleStepExpansion}
                  onViewPayloadClick={(stepIdToShowPayload) => {
                    if (expandedPayloadStepId === step.id) {
                      setExpandedPayloadStepId("-1");
                      setViewStepPayloadData({});
                    } else {
                      setViewStepPayloadData({});
                      fetchStepPayloadData(stepIdToShowPayload, step);
                      setExpandedPayloadStepId(stepIdToShowPayload);
                    }
                  }}
                  rowStyle={rowStyle}
                  node={node}
                />
                {step.id === expandedPayloadStepId && (
                  <div className="payload-container">
                    <StepPayloadCompnent
                      payloadData={viewStepPayloadData}
                      activityType={step.activity_type}
                      isLoading={Object.keys(viewStepPayloadData).length === 0}
                      increaseHeightOnFullScreen
                      showConditionTab={!!viewStepPayloadData?.stepCondition}
                      hideObjectTab={
                        step.activity_type === "CODE" ||
                        step.app.id === webappConfig.storage.appId ||
                        step.activity_id ===
                          webappConfig.incomingWebhook.activityId ||
                        step.activity_id ===
                          webappConfig.abortStep.activityId ||
                        step.activity_type === ActivityType.Loop
                      }
                      hideRequestTab={
                        step.activity_id ===
                          webappConfig.abortStep.activityId ||
                        step.activity_type === ActivityType.Loop
                      }
                      hideResponseTab={
                        step.activity_id ===
                        webappConfig.incomingWebhook.activityId
                      }
                      hiddenSection={hiddenSections}
                      tabConfig={{
                        request: {
                          title: requestTabTitle,
                        },
                        response: {
                          title:
                            step.activity_type === "CODE" ||
                            step.app.id === webappConfig.storage.appId ||
                            step.activity_id ===
                              webappConfig.abortStep.activityId ||
                            step.activity_type === ActivityType.Loop
                              ? "Output"
                              : "Response",
                        },
                      }}
                    />
                  </div>
                )}

                {expandedStepId.includes(step.id) && (
                  <div className="sub-table">
                    <ChildSteps {...props} node={node + 1} stepId={step.id} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
      {fetching && node === 0 && <IntegryLoader />}
    </>
  );
};

interface ChildStepsProps extends RenderTableProps {
  stepId: string;
}

const ChildSteps = (props: ChildStepsProps): ReactElement => {
  const { integrationId, runId, stepId, config } = props;
  const [page, setPage] = useState<number>(1);
  const { data: childSteps, isLoading } = useGetStepChildrenQuery(
    {
      itnegration_id: integrationId,
      run_id: runId,
      step_id: stepId,
      page,
    },
    {
      skip: !page,
    }
  );

  const data = childSteps?.results || [];

  return (
    <>
      {isLoading ? (
        <SkeletonLoader config={config} rowCount={3} />
      ) : (
        <>
          <RenderTable {...props} data={data} />
          {childSteps?.has_more && (
            <SkeletonLoader
              config={config}
              rowCount={1}
              onInViewport={() => {
                setPage(childSteps?.next_page || page + 1);
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default RenderTable;
