import { REQUEST_SETS, RECEIVE_SETS } from "../actions/sets";

export const setsByApp = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_SETS:
    case RECEIVE_SETS:
      return Object.assign({}, state, {
        [action.app_id]: sets(state[action.app_id], action),
      });
    default:
      return state;
  }
};

export const sets = (
  state = { isFetching: false, fetchCount: 0, items: [] },
  action
) => {
  switch (action.type) {
    case REQUEST_SETS:
      return Object.assign({}, state, {
        isFetching: true,
        fetchCount: state.fetchCount + 1,
      });
    case RECEIVE_SETS:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.sets,
      });
    default:
      return state;
  }
};
