import ReactModal from "react-modal";

import InfoMessage from "components/integry-design-system/molecules/info-message";
import StepPayloadCompnent from "components/integry-design-system/organisms/integration-view/step-payload-component";
import { FeatureFlag } from "react-launch-darkly";
import CancelButton from "images/cross.svg";
import { IPollDetails } from "legacy-features/integrations/services/interface";
import { convertDateToUTCString } from "utils/date-utils";
import { IIntegration } from "legacy-features/integrations/integrations-view/integration-listing";
import GroupedInfoHeader from "components/integry-design-system/molecules/grouped-info-header";

interface Iprop {
  showModal: boolean;
  setModalVisiblity: (value: boolean) => void;
  pollDetails: IPollDetails | null | undefined;
  integrationConfig: IIntegration | null | undefined;
}

const LastSyncDetails = ({
  showModal,
  setModalVisiblity,
  pollDetails,
  integrationConfig,
}: Iprop): JSX.Element => {
  const closeModal = (): void => {
    setModalVisiblity(false);
  };

  const getModalDescription = (): string => {
    switch (integrationConfig?.integrationStatus) {
      case "5xx_errors":
      case "5XX_ERRORS":
        return `There were too many server errors at ${pollDetails?.appName}. Try enabling the
        integration to see if it works now. If the issue persists, please
        contact Integry support.`;
      case "4xx_errors":
      case "4XX_ERRORS":
        return "Too many errors due to some configuration issues in this integration. Please see the response below to learn more. To resolve the issue, the user may need to create a new integration.";
      case "AUTH_MISSING":
        return `Authentication failed for ${pollDetails?.appName}. User needs to re-authenticate their account and enable the integration.`;
      default:
        return "This is the response received during the last poll.";
    }
  };

  const getLabel = (): string => {
    if (pollDetails?.objectName) {
      return `Last poll to get  ${pollDetails?.objectName.toLowerCase()} from ${
        pollDetails?.appName
      } ${pollDetails?.lastPollError ? "failed" : "succeeded"}`;
    }
    return `Last poll to ${pollDetails?.appName} ${
      pollDetails?.lastPollError ? "failed" : "succeeded"
    }`;
  };
  return (
    <div>
      <ReactModal
        isOpen={showModal}
        shouldCloseOnOverlayClick
        onRequestClose={closeModal}
        overlayClassName="IntegryModal__Overlay integry-pop-overlay"
        className="IntegryModal__Content last-sync-detail__popup"
      >
        <div
          className="close-button"
          onClick={closeModal}
          onKeyDown={closeModal}
          tabIndex={-1}
          role="button"
        >
          <img
            src={CancelButton}
            alt="Cancel"
            className="float-right cursor-pointer"
          />
        </div>

        <FeatureFlag
          flagKey="poll-detail-info-header"
          renderFeatureCallback={() => (
            <div className="last-sync-detail__info_header">
              <GroupedInfoHeader
                attributes={[
                  {
                    label: "Last Poll",
                    render: (
                      <>
                        {convertDateToUTCString(
                          pollDetails?.lastPollStartTime || ""
                        )}
                      </>
                    ),
                  },
                  {
                    label: "Network Code",
                    render: <div>{pollDetails?.responseCode}</div>,
                  },
                ]}
                avatar={{
                  iconSrc: ``,
                  label: getLabel(),
                  title: `${
                    pollDetails?.lastPollError ? "" : getModalDescription()
                  }`,
                }}
              />

              {pollDetails?.lastPollError && (
                <InfoMessage
                  infoMessage={
                    <>
                      <>{getModalDescription()}</>{" "}
                    </>
                  }
                  showMessageProp
                />
              )}
            </div>
          )}
          renderDefaultCallback={() => (
            <div className="last-sync-detail__popup_header">
              <div>
                <h1>{`Last poll to ${pollDetails?.appName} received a ${
                  pollDetails?.responseCode
                } ${pollDetails?.lastPollError ? "Error" : "OK"}`}</h1>
                <p>{getModalDescription()}</p>
              </div>
              <div>
                <h2>Last poll start</h2>
                <p className="font-size-12px">
                  {convertDateToUTCString(pollDetails?.lastPollStartTime || "")}
                </p>
              </div>
            </div>
          )}
        />
        {pollDetails && (
          <StepPayloadCompnent
            payloadData={pollDetails?.payload}
            hideObjectTab
            customHeight={["122px", "195px"]}
          />
        )}
      </ReactModal>
    </div>
  );
};

export default LastSyncDetails;
