/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { useState, ReactElement } from "react";
import SearchBar from "components/common/search-input-field/searchbar-v3";
import { ICheckboxProps } from "components/integry-design-system/molecules/checkbox-selection";
import CheckboxDropdown from "components/integry-design-system/molecules/dropdowns/checkbox-dropdown";
import Tag from "components/integry-design-system/atoms/tag";
import TruncateTextMiddle from "components/integry-design-system/atoms/TruncateTextMiddle/TruncateTextMiddle";
import ListingTable, {
  createColumnHelper,
} from "components/integry-design-system/organisms/ListingTable/ListingTable";
import Button from "components/integry-design-system/molecules/button";
import DateRangePickerV2, {
  DateRangeValue,
} from "components/integry-design-system/molecules/date-range-picker/date-range-v2";
import urls from "utils/router-urls";
import { getQueryParamAsObject } from "utils/query-params";

import {
  statusLabels,
  networkCodeColors,
} from "legacy-features/integrations/runs-view/runs-listing";

import { ReactComponent as OpenLink } from "images/open-link.svg";

import { useFeatureFlags } from "context/featureFlagsContext";
import { useGetPollsQuery } from "../../api/usePollApi";

import "./styles.scss";
import { Poll } from "../../interface";
import PollPages from "./poll-pages";

interface IProps {
  integrationId: string;
}

type QueryParams = {
  search?: string;
  poll_statuses?: string | string[];
  start_time_range_start?: string;
  start_time_range_end?: string;
  run_id?: string;
  has_runs?: string[];
};

const PollsListing = (props: IProps): ReactElement => {
  const { integrationId } = props;
  const runCountfilter = useFeatureFlags({ flag: "polls-listing" });
  const columnHelper = createColumnHelper<Poll>();

  const [selectedPoll, setSelectedPoll] = useState<Poll | null>(null);
  const [queryArguements, setQueryArguements] = useState<{
    search: string;
    stepStatus: string[];
    page: number;
    has_runs: string[];
  }>({
    search: "",
    stepStatus: [],
    page: 1,
    has_runs: runCountfilter ? [] : [],
  });

  const queryParams = getQueryParamAsObject() as QueryParams;
  const [runStartTimeRange, setRunStartTimeRange] =
    useState<DateRangeValue | null>(() => {
      const start = queryParams.start_time_range_start
        ? new Date(queryParams.start_time_range_start)
        : "";
      const end = queryParams.start_time_range_end
        ? new Date(queryParams.start_time_range_end)
        : "";

      if (start && end) {
        return {
          startDate: start,
          endDate: end,
          key: "selection",
        };
      }
      return null;
    });
  const {
    data: runSteps,
    isLoading: isLoadingSteps,
    isFetching: isFetchingSteps,
  } = useGetPollsQuery(
    {
      itnegration_id: integrationId,
      search: queryArguements.search,
      stepStatus: queryArguements.stepStatus,
      page: queryArguements.page,
      start_time_range_start: runStartTimeRange?.startDate?.toISOString() || "",
      start_time_range_end: runStartTimeRange?.endDate?.toISOString() || "",
      has_runs:
        queryArguements.has_runs.length > 0 ? queryArguements.has_runs[0] : "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const columns = [
    columnHelper.accessor("id", {
      header: () => "Poll ID",
      cell: (info) => <TruncateTextMiddle text={info.getValue()} />,
    }),

    columnHelper.accessor("start_time", {
      header: () => "Start Time",
    }),

    columnHelper.accessor("app", {
      header: () => "Event",
      cell: (info) => (
        <div className="flex align-center gap-10px">
          <img
            src={info.getValue()?.icon_url}
            alt="appicon"
            width={24}
            height={24}
          />
          <div>{info.getValue()?.activity_name}</div>
        </div>
      ),
    }),

    columnHelper.accessor("status", {
      header: () => "Status",
      cell: (info) => {
        const status = info.getValue();
        const label = statusLabels[status?.toLowerCase()] || status;
        return <Tag label={label} type="run" />;
      },
    }),

    columnHelper.accessor("network_code", {
      header: () => "Network Code",
      cell: (info) => (
        <div
          style={{
            color:
              networkCodeColors[
                `${info.getValue()} || "N/A"}`.substring(0, 1)
              ] || "#333333",
          }}
        >
          {info.getValue()}
        </div>
      ),
    }),

    columnHelper.accessor("runs_count", {
      header: () => "Runs",
      cell: (info) => (
        <Button
          tooltip="View Runs"
          variation="link"
          icon={<OpenLink width={10} height={10} />}
          size="auto"
          renderIconRight
          action={(e) => {
            e.preventDefault();
            e.stopPropagation();
            const poll = info.row.original;
            const url = `/wapp${urls.integrationRuns.replace(
              ":integrationId",
              integrationId
            )}?search=${poll.id}&poll_id=${poll.id}`;
            window.open(url, "_blank");
          }}
        >
          {info.getValue()}
        </Button>
      ),
    }),
  ];

  const getStepStatusOptions = (): Array<ICheckboxProps> => {
    const lastRunStatusOptions = [] as Array<ICheckboxProps>;
    lastRunStatusOptions.push(
      {
        render: <Tag label="OK" type="run" />,
        value: "OK",
        label: "OK",
      },
      {
        render: <Tag label="Failed" type="run" />,
        value: "FAILED",
        label: "Failed",
      },
      {
        render: (
          <Tag
            customStyle={{
              background: "#DCEAFF",
              color: "#5194F8",
            }}
            label="Running..."
            type="run"
          />
        ),
        value: "RUNNING",
        label: "Running...",
      }
      /* {
        render: <Tag label="True" type="run" />,
        value: "TRUE",
        label: "True",
      },
      {
        render: <Tag label="False" type="run" />,
        value: "FALSE",
        label: "False",
      } */
    );
    return lastRunStatusOptions;
  };

  const showMainLoader =
    isLoadingSteps || (isFetchingSteps && queryArguements.page === 1);

  return (
    <div className="integration-polls-section-v2 expanded">
      <div className="polls-content">
        <div className="flex gap-10px">
          <SearchBar
            searchQueryProp={queryArguements.search}
            onSearch={(value) => {
              setQueryArguements((prev) => ({
                ...prev,
                search: value,
                page: 1,
              }));
            }}
            placeholder="Search by ID, event name, or data in payload"
          />
          <DateRangePickerV2
            value={runStartTimeRange}
            onChange={(date) => {
              setRunStartTimeRange(date);
              setQueryArguements((prev) => ({
                ...prev,
                page: 1,
              }));
            }}
            tooltipText="Filter on Start Time"
            plaseholder={["From", "To"]}
          />
          <CheckboxDropdown
            options={getStepStatusOptions()}
            value={queryArguements.stepStatus}
            onChange={(value) => {
              setQueryArguements((prev) => ({
                ...prev,
                stepStatus: value,
                page: 1,
              }));
            }}
            placeholder="Status"
            tooltipText="Filter by step status"
          />
          {runCountfilter && (
            <CheckboxDropdown
              options={[
                {
                  value: "true",
                  label: "1+ runs",
                },
              ]}
              value={queryArguements.has_runs}
              onChange={(value) => {
                setQueryArguements((prev) => ({
                  ...prev,
                  has_runs: value,
                  page: 1,
                }));
              }}
              placeholder="Runs"
              tooltipText="Filter by run count"
            />
          )}
          {!isLoadingSteps && (
            <div className="txt-sm-liight-gray align-center">{`${
              runSteps?.count.toLocaleString() || 0
            } ${runSteps?.count === 1 ? "Poll" : "Polls"}`}</div>
          )}
        </div>

        <ListingTable<Poll>
          showHoverOnRow
          data={runSteps?.results || []}
          columns={columns}
          hasMore={showMainLoader ? false : !!runSteps?.has_more}
          isLoading={showMainLoader}
          onRowClick={(row) => {
            setSelectedPoll(row);
          }}
          fetchMore={() => {
            setQueryArguements((prev) => ({
              ...prev,
              page: prev.page + 1,
            }));
          }}
        />

        {selectedPoll && (
          <PollPages
            show={!!selectedPoll}
            onClose={() => {
              setSelectedPoll(null);
            }}
            pollId={selectedPoll.id}
            integrationId={integrationId}
          />
        )}
      </div>
    </div>
  );
};

export default PollsListing;
