import { ReactElement } from "react";

/* eslint-disable no-shadow */
export interface WebAppUser {
  accountId: number;
  id: number;
  accountName: string;
  canViewLogs: boolean;
  companySize: string;
  email: string;
  username: string;
}

interface routerLocation {
  hash: string;
  pathname: string;
  search: string;
}

export interface singleModule {
  moduleName: string;
  moduleLink: string;
}
export interface breadCrumbConfigObject {
  moduleName: string;
  moduleLink: string;
  currentPageName: string;
  nestedModules?: singleModule[];
}

export interface IntegrationViewSkeletonProps {
  breadCrumbsConfig: breadCrumbConfigObject;
  pageTitle: string;
  showCopyPageLink?: boolean;
  metaInfoComponent: ReactElement;
  listingComponent: ReactElement;
}

export enum integrationViewTypeEnum {
  ALL_INTEGRATIONS,
  INTEGRATION_RUNS,
  INTEGRATION_RUN_STEPS,
}

export interface IntegrationViewWrapperProps {
  user: WebAppUser;
  location: routerLocation;
  integrationViewType: integrationViewTypeEnum;
}

export interface MetaInfoComponentWrapperProps {
  integrationViewType: integrationViewTypeEnum;
  flowMetaData: flowMetaObject;
  integrationMetaData?: integrationMetaObject;
  integrationRunMetaData?: runMetaObject;
}

export interface IQueryParam {
  pageSize: string;
  currentPage: string;
  queryFilterString: string;
}
export interface ListingComponentWrapperProps {
  listingData:
    | integrationMetaObject[]
    | runMetaObject[]
    | stepMetaObject[]
    | undefined;
  integrationViewType: integrationViewTypeEnum;
  isListingReloading: boolean;
  loadPageData: (reloadListing: boolean, queryParams?: string) => void;
  totalPages?: number;
  count?: number;
  windowQueryParams?: IQueryParam;
}

export interface integrationViewDataObject {
  flowId: string;
  flowName: string;
  flowLogo: string;
  flowStatus: string;
  integration?: integrationMetaObject;
  integrations?: integrationMetaObject[];
  run?: runMetaObject;
  runs?: runMetaObject[];
  count?: number;
  totalPages?: number;
  steps?: stepMetaObject[];
}

export interface flowMetaObject {
  flowId: string;
  flowName: string;
  flowLogo: string;
  flowStatus: string;
}

export interface integrationMetaObject {
  integrationId: string;
  userId: string;
  appAuthId: string;
  dateCreated: string;
  lastRunStart: string;
  lastRunStatus: string;
  integrationStatus: string;
  hasRuns: boolean;
}

export interface triggerAppObject {
  iconSrc: string;
  name: string;
}
export interface runMetaObject {
  runId: string;
  triggerName: string;
  triggerType: string;
  startTime: string;
  duration: string;
  status: string;
  networkCode: string;
  hasSteps: boolean;
  triggerApp: triggerAppObject;
  logLink?: string;
}

export interface stepMetaObjectPayload {
  parsedUrl: string;
  parsedHeaders: string;
  parsedObjectOutput: string;
  parsedRequestTemplate: string;
  parsedResponseTemplate: string;
  remoteResponse: string;
  requestTemplate: string;
  responseHeaders: string;
  infoMessage?: [{ message: string; type: "INFO" | "WARNING" | "ERROR" }];
  requestHttpVerb: string;
}

export interface stepMetaObject {
  stepId: string;
  stepName: string;
  startTime: string;
  duration: string;
  status: string;
  networkCode: string;
  hasSteps: boolean;
  stepApp: triggerAppObject;
  payload: stepMetaObjectPayload;
  count: number;
  steps?: stepMetaObject[] | undefined;
}

export interface FlowMetaComponentProps {
  flowMetaData: flowMetaObject;
}

export interface IntegrationMetaComponentProps {
  integrationMetaData: integrationMetaObject | undefined;
  flowMetaData: flowMetaObject;
}

export interface IntegrationRunStepMetaComponentProps {
  integrationRunMetaData: runMetaObject | undefined;
  integrationMetaData: integrationMetaObject | undefined;
  flowMetaData: flowMetaObject;
}

export interface ListingComponentProps {
  count?: number;
  listingData:
    | integrationMetaObject[]
    | runMetaObject[]
    | stepMetaObject[]
    | undefined;
}

interface IAttribute {
  label: string;
  render: JSX.Element;
}
export interface IntegrationMetaSubInfoArrayProps {
  attributes: Array<IAttribute>;
}

export interface FiltersWrapperProps {
  integrationViewType: integrationViewTypeEnum;
  updateFilterQueryParam: (queryParams: string) => void;
  initQueryString: string;
}

export interface IFilterObj {
  startTime?: string;
  startTimeStart?: string;
  startTimeEnd?: string;
  status?: string;
  networkCode?: string;
  dateCreated?: string;
  dateCreatedStart?: string;
  dateCreatedEnd?: string;
  lastRunStart?: string;
  lastRunStartStart?: string;
  lastRunStartEnd?: string;
  lastRunStaus?: string;
  integrationStatus?: string;
}
export interface ISidebarContentProps {
  onApplyFilters: (selectedFilters: IFilterObj) => void;
  filterState: IFilterObj;
}

export interface IStepPayloadComponent {
  payloadData: stepMetaObjectPayload;
}
