import http from "../../utils/http";

export const REQUEST_ACCOUNTS = "REQUEST_ACCOUNTS";

export const requestAccounts = (app_id, authorization_type_id) => ({
  type: REQUEST_ACCOUNTS,
  app_id,
  authorization_type_id,
});

export const RECEIVE_ACCOUNTS = "RECEIVE_ACCOUNTS";

export const receiveAccounts = (app_id, authorization_type_id, json) => ({
  type: RECEIVE_ACCOUNTS,
  app_id,
  authorization_type_id,
  accounts: json,
});

export const fetchAccounts = (app_id, authorization_type_id) => {
  return (dispatch) => {
    dispatch(requestAccounts(app_id, authorization_type_id));

    return http
      .get("/connected-accounts/", {
        params: {
          authorization_type_id,
          app_id,
        },
      })
      .then(
        (response) => {
          dispatch(
            receiveAccounts(app_id, authorization_type_id, response.data)
          );
        },
        (error) => {
          console.log(
            "Error occurred while fetching authorizations in fetchAccounts() action.",
            error
          );
        }
      );
  };
};

export const fetchOrCreateAccounts = (
  app_id,
  authorization_type_id,
  self_api_key,
  user_name
) => {
  return (dispatch) => {
    dispatch(requestAccounts(app_id, authorization_type_id));

    return http
      .get("/connected-accounts/list_connected_accounts/", {
        params: {
          authorization_type_id,
          app_id,
          self_api_key,
          user_name,
        },
      })
      .then(
        (response) => {
          dispatch(
            receiveAccounts(app_id, authorization_type_id, response.data)
          );
        },
        (error) => {
          console.log(
            "Error occurred while fetching authorizations in fetchAccounts() action.",
            error
          );
        }
      );
  };
};

export const REMOVE_ACCOUNT = "REMOVE_ACCOUNT";

export const removeAccount = (app_id, authorization_type_id, account_id) => ({
  type: REMOVE_ACCOUNT,
  app_id,
  authorization_type_id,
  account_id,
});

export const ADD_ACCOUNT = "ADD_ACCOUNT";

export const addAccount = (app_id, authorization_type_id, account) => ({
  type: ADD_ACCOUNT,
  app_id,
  authorization_type_id,
  account,
});

export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";

export const updateAccount = (app_id, authorization_type_id, account) => ({
  type: UPDATE_ACCOUNT,
  app_id,
  authorization_type_id,
  account,
});

export const SET_CONNECTED_ACCOUNT = "SET_CONNECTED_ACCOUNT";

export const setConnectedAccount = (
  app_id,
  authorization_type_id,
  account
) => ({
  type: SET_CONNECTED_ACCOUNT,
  app_id,
  authorization_type_id,
  account,
});

export const RESET_ACCOUNTS = "RESET_ACCOUNTS";

export const resetAccounts = () => ({
  type: RESET_ACCOUNTS,
});
