/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import http from "utils/http";
// import {Template, Integration} from './template-builder-interface';

const useTemplateBuilderAPI = () => {
  const getTemplate = (templateId: number) =>
    http.get(`/api/v2/templates/${templateId}/`);

  const getTemplateActivities = (templateId: number) =>
    http.get(`/api/v2/templates/${templateId}/template_app_activities/`);

  const getActivityObjects = () => http.get("/api/object/");

  const createTemplateApi = (data: any, publish: any) => {
    const url = `/api/v2/templates/${
      publish !== null ? `?publish=${publish}` : ""
    }`;
    return http.post(url, data);
  };

  /*   const updateTemplateApi = (templateId: number, data: any, publish: any) => {
    const url = `/api/v2/templates/${templateId}/${
      publish !== null ? `?publish=${publish}` : ""
    }`;
    return http.put(url, data);
  }; */

  const updateTemplateApi = (templateId: number, data: any, publish: any) => {
    const url = `/api/v5/templates/${templateId}/?push_updates=true${
      publish !== null && publish !== undefined ? `&publish=${publish}` : ""
    }`;
    return http.patch(url, data);
  };

  const partialUpdateTemplateApi = (
    templateId: number,
    data: any,
    publish: any,
    bundleId?: number,
    pushUpdates?: boolean
  ) => {
    const url = `/api/v2/templates/${templateId}/template_partial_update/?update_integration=${true}${
      publish !== null && publish !== undefined ? `&publish=${publish}` : ""
    }${bundleId ? `&bundle_id=${bundleId}` : ""}${
      pushUpdates ? `&push_updates=${pushUpdates}` : ""
    }`;
    return http.post(url, data);
  };

  const createIntegrationApi = (values: any) =>
    http.post(`/v2/integration/?async=false`, values);

  const disableIntegrationApi = (templateId: number) =>
    http.put(
      `/v2/disable_latest_integration/${templateId}/?async=false&delete=true`
    );

  const updateUserName = (userName) =>
    http.put("/api/update_app_user_name/", { user_name: userName });

  const addFlowInbundle = (templateId: number, bundleId: number) =>
    http.put(`/api/bundles/${bundleId}/add_template_in_bundle/`, {
      template_id: templateId,
    });

  const deleteFlow = (templateId) => http.delete(`/template/${templateId}/`);

  const getDirectory = (directoryId: number) =>
    http.get(`api/bundles/${directoryId}/`);

  const createLock = (flowId: number) =>
    http.post(`api/v2/templates/${flowId}/lock/`);

  const refreshLock = (flowId: number) =>
    http.post(`/refresh_lock/`, {
      type: "template",
      id: flowId,
    });

  const getTemplateJobStatus = (templateId: number) =>
    http.get(`/api/v2/templates/${templateId}/integration_update_status/`);

  return {
    getTemplate,
    createTemplateApi,
    updateTemplateApi,
    getTemplateActivities,
    getActivityObjects,
    partialUpdateTemplateApi,
    disableIntegrationApi,
    createIntegrationApi,
    updateUserName,
    deleteFlow,
    getDirectory,
    addFlowInbundle,
    createLock,
    refreshLock,
    getTemplateJobStatus,
  };
};

export default useTemplateBuilderAPI;
