import React, { ReactElement } from "react";
import PlaceholderBar from "atomic-components/atom/placeholder-bar";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";

import Tag from "../tag";

import "./styles.scss";

interface UsageWidgetProps {
  count: number;
  subtitle: string;
  percentChange: number | null;
  showTrendIcon?: boolean;
  showPercentChange?: boolean;
  percentChangeTooltip?: string;
}

interface UsageWidgetsProps {
  usage: UsageWidgetProps[];
}

const UsageWidgets: React.FC<UsageWidgetsProps> = ({ usage }) => {
  return (
    <div className="usage-widget-container flex gap-10px">
      {usage.map(
        (
          {
            count,
            subtitle,
            percentChange,
            showPercentChange,
            showTrendIcon,
            percentChangeTooltip,
          },
          index
        ) => (
          <UsageWidget
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            count={count}
            subtitle={subtitle}
            percentChange={percentChange}
            showPercentChange={showPercentChange}
            showTrendIcon={showTrendIcon}
            percentChangeTooltip={percentChangeTooltip}
          />
        )
      )}
    </div>
  );
};

export const UsageWidget: React.FC<UsageWidgetProps> = ({
  count,
  subtitle,
  showPercentChange,
  percentChange,
  showTrendIcon,
  percentChangeTooltip,
}) => {
  return (
    <div className="usage-widget">
      <div className="count-wrapper">
        <div className="value txt-lg">{count.toLocaleString()}</div>
        <IntegryTooltip
          tooltipText={percentChangeTooltip}
          disabled={!percentChangeTooltip}
        >
          <div className="cursor-pointer">
            {percentChange === 0 && (showPercentChange || showTrendIcon) ? (
              <Tag label="0%" type="grey" />
            ) : null}
            {showPercentChange && !!percentChange && (
              <Tag
                label={`${
                  percentChange > 0 ? `+${percentChange}` : percentChange
                }%`}
                type={percentChange > 0 ? "green" : "lightRed"}
              />
            )}
            {showTrendIcon && !!percentChange && (
              <>
                {percentChange && percentChange > 0 ? (
                  <TrendUpIcon />
                ) : (
                  <TrendDownIcon />
                )}
              </>
            )}
          </div>
        </IntegryTooltip>
      </div>

      <div className="text-mid-gray">{subtitle}</div>
    </div>
  );
};

export const UsageWidgetLoading = ({
  widgetCount,
}: {
  widgetCount: 3;
}): React.ReactElement => {
  return (
    <div className="usage-widget-container flex gap-10px">
      {Array.from({ length: widgetCount }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className="usage-widget">
          <PlaceholderBar
            customStyle={{
              height: "18px",
            }}
          />
          <PlaceholderBar
            customStyle={{
              height: "18px",
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default UsageWidgets;

const TrendUpIcon = (): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path
      fill="#749D48"
      d="M384 160c-17.7 0-32-14.3-32-32s14.3-32 32-32l160 0c17.7 0 32 14.3 32 32l0 160c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-82.7L342.6 374.6c-12.5 12.5-32.8 12.5-45.3 0L192 269.3 54.6 406.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160c12.5-12.5 32.8-12.5 45.3 0L320 306.7 466.7 160 384 160z"
    />
  </svg>
);

const TrendDownIcon = (): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path
      fill="#F05C42"
      d="M384 352c-17.7 0-32 14.3-32 32s14.3 32 32 32l160 0c17.7 0 32-14.3 32-32l0-160c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 82.7L342.6 137.4c-12.5-12.5-32.8-12.5-45.3 0L192 242.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0L320 205.3 466.7 352 384 352z"
    />
  </svg>
);
