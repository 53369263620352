/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { ReactElement, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import FormikWrapper from "components/forms/v4/formik-wrapper";
import Button from "components/common/reusable-button";
import IntegryTooltip from "components/integry-design-system/molecules/integry-tooltip/integry-tooltip-v2";
import AceEditor from "react-ace";
import { track } from "utils/mixpanel";

// import SearchBarV3 from "components/common/search-input-field/searchbar-v3";
import IntegryModal from "components/integry-design-system/molecules/integry-modal";
import TextField from "components/forms/v4/text-field";
import TextArea from "components/forms/v4/text-area";
import Label from "components/forms/v4/label";
import showToast from "components/common/custom-toast/custom-toast";
import IntegryConfirmationModal from "components/integry-design-system/molecules/integry-confirmation-modal";

import CircularBackIcon from "images/turbo.v2/circular-back-btn.svg";
import FloppyIcon from "images/turbo.v2/floppy.svg";
import copyIcon from "images/copy-icon-editor.svg";

import ValidatePayloadForm from "../validations";
import {
  updateActionPayload,
  createActionPayload,
} from "./services/payloads-apis";

import "./styles.scss";

const PayloadForm = (props): ReactElement => {
  const {
    setShowEditor,
    AddPayloadInList,
    updatePayloadInList,
    payloadData,
    allPayloads,
    user,
    onBackClick,
  } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [titleError, setTitleError] = useState("");
  const [showEditConfirmationDialog, setShowEditConfirmationDialog] =
    useState(false);
  const [tempFormData, setTempFormData] = useState({
    name: "",
    description: "",
    payload: "",
  });
  const [isBodyCopied, setIsBodyCopied] = useState(false);

  const handleSavePayload = (formData): void => {
    setIsSaving(true);
    if (payloadData?.id) {
      setIsSaving(true);
      updateActionPayload(
        payloadData.id,
        formData.name,
        formData.description,
        formData.payload
      )
        .then((res) => {
          const windowQueryParams = new URLSearchParams(window.location.search);
          const source = windowQueryParams.has("payload")
            ? "Configure Action"
            : "Object Management";

          track("Object edited", user, { source });
          setIsSaving(false);
          setShowEditor(false);
          updatePayloadInList(
            payloadData.id,
            formData.name,
            formData.description,
            formData.payload
          );
          showToast("Object updated", `‘${formData.name}’ object updated`);
        })
        .catch((error) => {
          setIsSaving(false);
          // setShowEditor(false);
          console.log(error.response);
          if (error.response.data.non_field_errors) {
            setTitleError("An object with this title already exists");
          }

          // showErrorToast(
          //   "Payload  update failed",
          //   `${error.response.data.non_field_errors}`
          // );
        });
    } else {
      createActionPayload(formData.name, formData.description, formData.payload)
        .then((res) => {
          setShowEditor(false);
          AddPayloadInList();
          track("New object added", user, { object_name: formData.name });
        })
        .catch((error) => {
          setIsSaving(false);
          if (error.response.data.non_field_errors) {
            setTitleError("An object with this title already exists");
          }
          // setShowEditor(false);
          // showErrorToast(
          //   "Payload creation failed",
          //   `${error.response.data.non_field_errors}`
          // );
        });
    }
  };

  const PayloadAddEditForm = (): ReactElement => {
    const {
      submitForm,
      setFieldValue,
      values,
      errors,
      isValid,
      dirty,
      setFieldTouched,
      touched,
    } = useFormikContext() as {
      submitForm: any;
      setFieldValue: any;
      values: { name: ""; description: ""; payload: "" };
      errors: any;
      isValid: boolean;
      dirty: any;
      setFieldTouched: any;
      touched: any;
    };

    // if payloadData type=predefined then check if paylod with same name exists
    useEffect(() => {
      if (
        payloadData?.type === "predefined" &&
        values.name &&
        allPayloads.find(
          (payload) =>
            payload.name.toLowerCase() === values.name.toLowerCase() &&
            payload.id !== payloadData?.id
        )
      ) {
        setTitleError("An object with this title already exists");
      } else if (payloadData?.type === "predefined") {
        setFieldTouched("name", true);
      }
    }, []);

    const copyBody = (): void => {
      navigator.clipboard.writeText(values.payload);
      setIsBodyCopied(true);
      setTimeout(() => {
        setIsBodyCopied(false);
      }, 1600);
    };

    return (
      <form>
        <div className="payload-form">
          {onBackClick && !payloadData.id && (
            <div className="payload-form--header">
              {onBackClick && (
                <img
                  src={CircularBackIcon}
                  alt="back_payload_selection"
                  aria-hidden="true"
                  onClick={() => {
                    if (onBackClick) {
                      onBackClick();
                    }
                  }}
                />
              )}
              <h1>Object details</h1>
            </div>
          )}
          <div className="field-wrap">
            <Label title="Title" forControl="title" />
            <TextField
              placeholder="Enter a title for your object..."
              name="name"
              id="name"
              defaultValue=""
              className=""
              onChange={() => {
                setTitleError("");
              }}
              onBlur={(value) => {
                if (
                  allPayloads.find(
                    (payload) =>
                      payload.name.toLowerCase() === value.toLowerCase() &&
                      payload.id !== payloadData?.id
                  )
                ) {
                  setTitleError("An object with this title already exists");
                }
              }}
            />
            {titleError !== "" && <p className="error-message">{titleError}</p>}
          </div>
          <div className="field-wrap">
            <Label title="Description" forControl="description" />
            <TextArea
              name="description"
              id="description"
              placeholder="Enter a description for your object..."
              className="integry-text-field payload-desc"
              value=""
            />
          </div>
          <div className="field-wrap field-wrap-json">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <Label title="JSON Payload" forControl="title" />
              <IntegryTooltip
                tooltipId="copy-body"
                tooltipText={isBodyCopied ? "Copied" : "Copy"}
                effect="solid"
                placement="bottom"
              >
                <img
                  src={copyIcon}
                  style={{
                    position: "absolute",
                    right: "0px",
                    bottom: "2px",
                    zIndex: "99999",
                  }}
                  onClick={copyBody}
                  tabIndex={0}
                  role="button"
                  alt="copy-object-icon"
                  className="copy-icon"
                />
              </IntegryTooltip>
            </div>
            <AceEditor
              name="payload"
              mode="json"
              theme="textmate"
              className="datap payload-editor"
              fontSize={12}
              showGutter
              height="235px"
              width="100%"
              // onValidate={validateJSON}
              value={values.payload}
              onChange={(code: string) => {
                setFieldValue("payload", code);
              }}
              onBlur={() => {
                setFieldTouched("payload");
              }}
              wrapEnabled
              showPrintMargin={false}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}

              // {...rest}
            />
            {touched.payload && (
              <p className="error-message">{errors.payload}</p>
            )}
          </div>
          <div className="actions">
            <Button
              title={payloadData?.id ? "Save object" : "Add object"}
              onClick={() => {
                submitForm();
              }}
              className="btn save-btn"
              type="button"
              isLoading={isSaving}
              disabled={
                isSaving ||
                !isValid ||
                (!dirty && !(payloadData.type === "predefined")) ||
                !!titleError
              }
            />
            <Button
              title="Cancel"
              onClick={() => {
                setShowEditor(false);
              }}
              className="btn btn-secondary cancel-btn "
              type="button"
              disabled={isSaving}
            />
          </div>
        </div>
      </form>
    );
  };
  let payloadStr = payloadData?.payload || "";
  if (payloadStr && isJsonString(payloadStr)) {
    payloadStr = JSON.stringify(JSON.parse(payloadStr), null, 2);
  }

  function isJsonString(str): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  return (
    <IntegryModal
      className="edit-payload-modal"
      hideCloseCTA
      onClose={() => {
        setShowEditor(false);
      }}
    >
      <>
        <div className="modal-heading">
          <div className="left-content">
            <h2 className="title">{payloadData.id ? "Edit" : "Add"} Object</h2>
          </div>
        </div>
        <div className="modal-body">
          <FormikWrapper
            initialState={{
              name: payloadData?.name || "",
              description: payloadData?.description || "",
              payload: payloadStr,
            }}
            // saveCallback={handleSavePayload}
            saveCallback={(formData) => {
              if (payloadData.id && payloadData.actions_count > 0) {
                setTempFormData(formData);
                setShowEditConfirmationDialog(true);
              } else {
                handleSavePayload(formData);
              }
            }}
            validationSchema={ValidatePayloadForm}
            formContent={<PayloadAddEditForm />}
            // validateOnMount
          />
        </div>
        {showEditConfirmationDialog && (
          <IntegryConfirmationModal
            title={`Edit “${payloadData.name || "object"}”?'`}
            titleImg={FloppyIcon}
            onClose={() => {
              setShowEditConfirmationDialog(false);
            }}
            footer={
              <div className="edit-payload-modal--actions">
                <button
                  type="button"
                  className="edit-payload-modal--cta edit-payload-modal--cta-secondary"
                  onClick={() => {
                    setShowEditConfirmationDialog(false);
                    setTempFormData({
                      name: "",
                      description: "",
                      payload: "",
                    });
                  }}
                >
                  No
                </button>
                <button
                  type="button"
                  className="edit-payload-modal--cta edit-payload-modal--cta-primary"
                  onClick={() => {
                    setShowEditConfirmationDialog(false);
                    if (tempFormData.name && tempFormData.name !== "") {
                      handleSavePayload(tempFormData);
                    }
                    setTempFormData({
                      name: "",
                      description: "",
                      payload: "",
                    });
                  }}
                >
                  Save
                </button>
              </div>
            }
          >
            <div className="edit-payload-modal--confirmation">
              <p>
                Editing may affect actions where the object fields are being
                used. You will have to update all affected actions and the embed
                code to avoid any unintended consequences.
              </p>
              <br />
              <p>This cannot be reversed. Are you sure?</p>
            </div>
          </IntegryConfirmationModal>
        )}
      </>
    </IntegryModal>
  );
};
export default PayloadForm;
