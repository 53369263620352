/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from "react";
import ReactPlayer from "react-player/lazy";
import cancelIcon from "images/cancel.svg";
// import SquaredPlaceHolderIcon from "images/square-placeholder.svg";
import SquaredPlaceHolderIcon from "images/file-document-check-outline.svg";
import "./styles.scss";

// eslint-disable-next-line no-shadow
export enum FileType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  FILE = "FILE",
}

export interface IPreviewFile {
  id?: string;
  URL: string;
  type: FileType;
  file?: any;
  uploading?: boolean;
  index: number;
}

interface IMediaPreviewComponent {
  previewFile: IPreviewFile;
  handleRemoveClick?: (fileObj: IPreviewFile) => void;
  simpleView?: boolean;
}

const MediaPreviewComponent = (props: IMediaPreviewComponent): ReactElement => {
  const { previewFile, handleRemoveClick, simpleView = false } = props;
  const { URL, file, index, uploading, type } = previewFile;

  const handleRemoveMedia = (): void => {
    if (handleRemoveClick) {
      handleRemoveClick(previewFile);
    }
  };

  if (simpleView) {
    return (
      <div className="media-preview-component simple-view">
        <div className="preview-display">
          <div className="preview-img">
            <img
              src={SquaredPlaceHolderIcon}
              alt={(file && file.name) || `File ${index + 1}`}
              width={24}
            />
            <span className="name">{file ? file.name : URL}</span>
          </div>
        </div>
        <div className="cancel-icon">
          <img
            role="button"
            src={cancelIcon}
            className="remove-icon"
            onClick={handleRemoveMedia}
            alt="remove-icon"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleRemoveMedia();
              }
            }}
          />
        </div>
        {uploading && <span className="integry-spinner-sm" />}
      </div>
    );
  }
  return (
    <div className="media-preview-component">
      <div className="preview-display">
        {type === FileType.IMAGE && (
          <img
            className="preview-img"
            src={URL}
            alt={(file && file.name) || `File ${index + 1}`}
          />
        )}
        {type === FileType.VIDEO && (
          <div className="preview-react-player">
            <ReactPlayer
              className="react-player"
              width="100%"
              height="100%"
              url={URL}
              controls
            />
          </div>
        )}
        <div className="cancel-icon">
          <img
            role="button"
            src={cancelIcon}
            className="remove-icon"
            onClick={handleRemoveMedia}
            alt="remove-icon"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleRemoveMedia();
              }
            }}
          />
        </div>
        {uploading && <span className="integry-spinner-sm" />}
      </div>
    </div>
  );
};

export default MediaPreviewComponent;
