/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback, ReactElement } from "react";
import { debounce } from "lodash";

import "./searchbar.scss";

import searchBarIcon from "images/search-bar-icon.svg";
import xIcon from "images/x.svg";

interface SearchBarProps {
  onSearch: (searchString: string) => void;
  placeholder: string;
  debounceRate?: number;
  searchCallbackDependency?: string | number;
  defaultValue?: string | null;
  disabled?: boolean;
}

const SearchBar = (props: SearchBarProps): ReactElement => {
  const {
    onSearch,
    placeholder,
    debounceRate,
    searchCallbackDependency,
    defaultValue = "",
    disabled = false,
  } = props;
  const [searchQuery, setSearchQuery] = useState("");

  const debounceHandleSearch = useCallback(
    debounce((value) => {
      onSearch(value);
    }, debounceRate || 1000),
    [searchCallbackDependency]
  );

  const instantHandleSearch = (value: string): void => {
    onSearch(value);
  };

  const handleSearch = (value = ""): void => {
    setSearchQuery(value);
    debounceHandleSearch(value);
  };

  useEffect(() => {
    if (defaultValue) {
      setSearchQuery(defaultValue);
    }
  }, []);
  return (
    <div className="searchbar-v2-container">
      <input
        id="integry-search-bar"
        placeholder={placeholder}
        value={searchQuery}
        disabled={disabled}
        onChange={(e) => {
          handleSearch(e.target.value);
        }}
      />
      <span className="search-bar-icon">
        {searchQuery && searchQuery !== "" ? (
          <img
            className="search-icon cross-icon"
            alt="search-icon"
            src={xIcon}
            onClick={() => {
              setSearchQuery("");
              instantHandleSearch("");
            }}
            style={{ cursor: "pointer" }}
            aria-hidden="true"
          />
        ) : (
          <img className="search-icon" alt="search-icon" src={searchBarIcon} />
        )}
      </span>
    </div>
  );
};

export default SearchBar;
