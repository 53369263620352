import orderBy from "lodash/orderBy";

export const getVisibleMyApps = (myApps) => {
  const appItems = JSON.parse(JSON.stringify(myApps.items));
  return appItems.sort((a, b) => (a.id === b.id ? 0 : a.id > b.id ? -1 : 1));
};

export const getExternalApps = (myApps) => ({
  isFetching: myApps.isFetching,
  items: myApps.items.filter((item) => item.app_type === "EXTERNAL"),
});

export const getMyAppById = (myApps, id) =>
  myApps.isFetching || !id
    ? {}
    : myApps.items.find((app) => String(app.id) === String(id));

export const getSortedMyAppsByStatus = (myApps) =>
  orderBy(
    myApps.items,
    ["publishing_status_sort_order", "id"],
    ["asc", "desc"]
  );

export const getOnlyPublishedApps = (myApps) => {
  return removeNullItems(
    myApps.items.map((app) => {
      if (app.publishing_status === "PUBLISHED") {
        return app;
      }
      return null;
    })
  );
};

const removeNullItems = (arr = []) => {
  const tempArr = [];
  arr.forEach((item) => {
    if (item) {
      tempArr.push(item);
    }
  });
  return tempArr;
};
