export const SHOW_404_PAGE = "SHOW_404_PAGE";
export const HIDE_404_PAGE = "HIDE_404_PAGE";

export const show404Page = () => ({
  type: SHOW_404_PAGE,
});

export const hide404Page = () => ({
  type: HIDE_404_PAGE,
});
