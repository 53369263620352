/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
// cropImage.js

// create the image with a src of the base64 string
const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

const blobToFile = (theBlob, fileName) =>
  new File([theBlob], fileName, {
    lastModified: new Date().getTime(),
    type: theBlob.type,
  });

export const getCroppedImg = async (imageSrc, crop, imageName, cropSize) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  /* setting canvas width & height allows us to 
    resize from the original image resolution */
  canvas.width = cropSize.width;
  canvas.height = cropSize.height;

  ctx.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    canvas.width,
    canvas.height
  );
  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      console.log("CROPIMAGE FILE", {
        file: blobToFile(file, imageName),
      });
      resolve(URL.createObjectURL(file));
    }, "image/jpeg");
  });
};

export const getCroppedImgFile = async (
  imageSrc,
  crop,
  imageName,
  cropSize
) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  /* setting canvas width & height allows us to 
    resize from the original image resolution */
  canvas.width = cropSize.width;
  canvas.height = cropSize.height;

  ctx.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    canvas.width,
    canvas.height
  );
  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      resolve(blobToFile(file, imageName));
    }, "image/png");
  });
};
