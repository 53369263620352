/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, FastField } from "formik";
import React, { ReactElement, useEffect, useState } from "react";
import { FormFeedback } from "reactstrap";
import useOnClickOutside from "utils/use-onclick-outside";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import { ignoreKeys } from "utils/functions";
import useDebounce from "hooks/useDebounce";
import usePrevious from "hooks/usePrevious";

import SuggestionsDropdown from "./suggestions-dropdown";

const renderField = (props, ref) => (fieldProps) => {
  const {
    onChange,
    onBlur,
    name,
    className,
    preChangeHook,
    formSubmitError,
    defaultValue,
    errorPlaceHolder,
    showSuggestions = false,
    suggestionOptions = [],
    onSuggestionSelect,
    forceDisplayError = "",
    rightIcon,
    hideValidationError = false,
    title,
    lazyField,
    debounceTime = 500,
    ...rest
  } = props;
  const { meta, field, form } = fieldProps;
  const { value } = field;
  const { touched, error } = meta;
  const { setFieldValue, setFieldTouched } = form;
  const isInvalid =
    ((touched && error) ||
      (formSubmitError && error) ||
      (touched && forceDisplayError)) &&
    !hideValidationError;

  const validationClass = isInvalid ? " is-invalid" : "";

  const [showSuggestionsDropdown, setShowSuggestionsDropdown] = useState(false);
  const [showMaxLengthTooltip, setShowMaxLengthTooltip] = useState(false);
  const [focusClass, setFocusClass] = useState("");
  const divRef = React.useRef<HTMLDivElement>(null);

  const [localValue, setLocalValue] = useState<string>(value);
  const [isFocused, setIsFocused] = useState(false);

  const previousFildValue = usePrevious(field.value);

  useEffect(() => {
    if (lazyField && !isFocused && field.value !== previousFildValue) {
      // if field value is changed from outside, update local value
      // adding isFocused check to prevent setting local value when user is typing
      setLocalValue(field.value);
    }
  }, [field.value, isFocused, previousFildValue, lazyField]);

  const debouncedOnChange = useDebounce((val: string) => {
    setFieldValue(name, val);
    if (onChange) {
      onChange(val);
    }
  }, debounceTime);

  if (
    showSuggestions &&
    !showSuggestionsDropdown &&
    suggestionOptions.length > 0 &&
    focusClass
  ) {
    setShowSuggestionsDropdown(true);
  } else if (!focusClass && showSuggestionsDropdown) {
    setShowSuggestionsDropdown(false);
  }

  const clickOutside = (evt): void => {
    if (showSuggestionsDropdown) {
      setShowSuggestionsDropdown(false);
    }
    setFocusClass("");
  };

  useOnClickOutside(divRef, clickOutside);
  const inputValue = lazyField ? localValue : value;
  return (
    <div className="text-field-container" ref={divRef}>
      <IntegryTooltip
        tooltipText={`${title || "This field"} can’t be longer than ${
          props.maxLength
        } characters`}
        disabled={!props.maxLength}
        triggerManually
        show={showMaxLengthTooltip}
        placement="bottom"
      >
        <input
          ref={ref}
          {...rest}
          value={inputValue || defaultValue || ""}
          onChange={(e) => {
            setShowMaxLengthTooltip(false);
            let valueShouldChange = true;
            if (preChangeHook) {
              valueShouldChange = preChangeHook(e.target.value);
            }
            if (valueShouldChange) {
              if (lazyField) {
                setLocalValue(e.target.value);
                debouncedOnChange(e.target.value);
              } else {
                setFieldValue(name, e.target.value);
                if (onChange) {
                  onChange(e.target.value, e);
                }
              }
            }
          }}
          onBlur={(e) => {
            setIsFocused(false);
            setFieldTouched(name);
            if (onBlur) {
              onBlur(e.target.value, e);
            }
          }}
          onClick={(e) => {
            setIsFocused(true);
            setFocusClass("focused");
          }}
          onKeyDown={(e) => {
            const ignoreEvent = ignoreKeys(e);
            if (!ignoreEvent && value?.length >= props.maxLength) {
              setShowMaxLengthTooltip(true);
              setTimeout(() => {
                setShowMaxLengthTooltip(false);
              }, 5000);
            }
          }}
          className={`form-control${validationClass} ${className}`}
          autoComplete="off"
        />
      </IntegryTooltip>

      {isInvalid ? (
        <FormFeedback>{error || forceDisplayError}</FormFeedback>
      ) : (
        <>{errorPlaceHolder && <div style={{ height: 23 }} />}</> // Prevent page jumping if erroe is displayed
      )}
      {showSuggestions && showSuggestionsDropdown && focusClass && (
        <SuggestionsDropdown
          searchValue={value}
          options={suggestionOptions}
          onSelect={(option) => {
            onSuggestionSelect(option);
            setLocalValue(option.label);
          }}
          setFocusClass={setFocusClass}
        />
      )}
      {rightIcon && <>{rightIcon}</>}
    </div>
  );
};

interface TextFieldProps extends React.HTMLProps<HTMLInputElement> {
  isFastField?: boolean;
  name: string;
  validate?(value): string;
  errorPlaceHolder?: boolean;
  rightIcon?: ReactElement;
  [otherOptions: string]: any;
}

const TextField = React.forwardRef(
  (props: TextFieldProps, ref): ReactElement => {
    const { isFastField, name, validate } = props;
    return isFastField ? (
      <FastField name={name} validate={validate}>
        {renderField(props, ref)}
      </FastField>
    ) : (
      <Field name={name} validate={validate}>
        {renderField(props, ref)}
      </Field>
    );
  }
);

TextField.defaultProps = {
  isFastField: false,
  validate: () => "",
  errorPlaceHolder: false,
  rightIcon: null,
};

export default TextField;
