import { createApi } from "@reduxjs/toolkit/query/react";
import { integryBaseQuery } from "utils/http";
import { convertDateToUTCString } from "utils/date-utils";

import {
  FunctionCallsResponse,
  Payload,
  PaginatedFunctionCallSteps,
} from "../interface";

export const useFunctionCallsApi = createApi({
  reducerPath: "integryFunctionCalls",
  baseQuery: integryBaseQuery(),
  tagTypes: ["integryFunctionCalls"],
  endpoints(build) {
    return {
      getPolls: build.query<
        FunctionCallsResponse,
        {
          page: number;
          stepStatus: string[];
          search?: string;
          start_time_range_start?: string;
          start_time_range_end?: string;
          has_runs?: string;
        }
      >({
        query: ({
          page,
          search,
          stepStatus,
          start_time_range_start,
          start_time_range_end,
          has_runs,
        }) => ({
          url: `/api/v1/usage/function-calls/?search=${encodeURIComponent(
            search || ""
          )}&page=${page}&page_size=10&status=${stepStatus.join(
            ","
          )}&start_time_range_start=${start_time_range_start}&start_time_range_end=${start_time_range_end}&has_runs=${has_runs}`, // &start_time_range_start=${runStartTime}&start_time_range_end=${runEndTime}
          method: "GET",
        }),
        transformResponse: (response: FunctionCallsResponse, meta, args) => {
          return {
            ...response,
            results: response.results.map((item) => ({
              ...item,
              start_time: convertDateToUTCString(item.start_time),
            })),
            current_page: args.page || 1,
            next_page: args.page + 1,
            has_more: response.total_pages > args.page,
          };
        },
        serializeQueryArgs: ({ endpointName, queryArgs }) => {
          const {
            stepStatus = [],
            search = "",
            start_time_range_start = "",
            start_time_range_end = "",
          } = queryArgs;
          return `${endpointName}-${stepStatus.join(",")}-${search || ""}-${
            start_time_range_start || ""
          }-${start_time_range_end || ""}`;
        },
        // Always merge incoming data to the cache entry
        merge: (currentCache, newItems, { arg }) => {
          if (arg.page === 1) {
            return newItems;
          }
          return {
            ...newItems,
            results: [...currentCache.results, ...newItems.results],
          };
        },
        // Refetch when the page arg changes
        forceRefetch({ currentArg, previousArg }) {
          return (
            currentArg !== previousArg ||
            currentArg?.stepStatus !== previousArg?.stepStatus
          );
        },
      }),

      getPollPages: build.query<
        PaginatedFunctionCallSteps,
        {
          itnegration_id: string;
          page: number;
          search?: string;
          pollId: string;
        }
      >({
        query: ({ itnegration_id, page, search, pollId }) => ({
          url: `/api/v1/usage/function-calls/${pollId}/steps?search=${encodeURIComponent(
            search || ""
          )}&page=${page}&page_size=10`, // &start_time_range_start=${runStartTime}&start_time_range_end=${runEndTime}
          method: "GET",
        }),
        transformResponse: (
          response: PaginatedFunctionCallSteps,
          meta,
          args
        ) => {
          return {
            ...response,
            results: response.results.map((item) => ({
              ...item,
              start_time: convertDateToUTCString(item.start_time),
            })),
            current_page: args.page || 1,
            next_page: args.page + 1,
            has_more: response.total_pages > args.page,
          };
        },
        serializeQueryArgs: ({ endpointName, queryArgs }) => {
          const { itnegration_id, search = "", pollId } = queryArgs;
          return `${endpointName}-${itnegration_id}-${search}-${pollId}`;
        },
        // Always merge incoming data to the cache entry
        merge: (currentCache, newItems, { arg }) => {
          if (arg.page === 1) {
            return newItems;
          }
          return {
            ...newItems,
            results: [...currentCache.results, ...newItems.results],
          };
        },
        // Refetch when the page arg changes
        forceRefetch({ currentArg, previousArg }) {
          return currentArg !== previousArg;
        },
      }),

      getPollPayload: build.query<
        Payload,
        {
          integrationId: string;
          pollId: string;
          pageId: string;
        }
      >({
        query: ({ integrationId, pollId, pageId }) => ({
          url: `/api/v1/usage/function-calls/${pollId}/steps/${pageId}/payload`,
          method: "GET",
        }),
      }),
    };
  },
});

export const {
  useGetPollsQuery,
  useGetPollPayloadQuery,
  useGetPollPagesQuery,
} = useFunctionCallsApi;
