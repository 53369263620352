import config from "../../utils/config";

import {
  REQUEST_ACTIVITIES,
  RECEIVE_ACTIVITIES,
  REMOVE_ACTIVITY,
  RESET_ACTIVITIES,
} from "../actions/activities";

export const activitiesByApp = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ACTIVITIES:
    case RECEIVE_ACTIVITIES:
    case REMOVE_ACTIVITY:
      return Object.assign({}, state, {
        [action.app_id]: activitiesByType(state[action.app_id], action),
      });
    case RESET_ACTIVITIES:
      return {};
    default:
      return state;
  }
};

const activitiesByType = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ACTIVITIES:
    case RECEIVE_ACTIVITIES:
    case REMOVE_ACTIVITY:
      return Object.assign({}, state, {
        [action.activity_type]: activities(state[action.activity_type], action),
      });
    default:
      return state;
  }
};

const activities = (
  state = { isFetching: false, fetchCount: 0, items: [] },
  action
) => {
  switch (action.type) {
    case REQUEST_ACTIVITIES:
      return Object.assign({}, state, {
        isFetching: true,
        fetchCount: state.fetchCount + 1,
      });
    case RECEIVE_ACTIVITIES:
      return Object.assign({}, state, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        items: action.activities.map((activity) =>
          config.microservicesOutputObjects.hasOwnProperty(activity.app.id) &&
          config.microservicesOutputObjects[activity.app.id].hasOwnProperty(
            activity.id
          )
            ? {
                ...activity,
                endpoint_obj:
                  config.microservicesOutputObjects[activity.app.id][
                    activity.id
                  ],
              }
            : activity
        ),
      });
    case REMOVE_ACTIVITY:
      return {
        ...state,
        items: state.items.filter(
          (activity) => String(action.activity_id) !== String(activity.id)
        ),
      };
    default:
      return state;
  }
};
