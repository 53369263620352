/* eslint-disable import/prefer-default-export */
import { SHOW_404_PAGE, HIDE_404_PAGE } from "store/actions/toggle-404-page";

export const toggle404Page = (state = {}, action) => {
  switch (action.type) {
    case SHOW_404_PAGE:
      return { ...state, show404Page: true };
    case HIDE_404_PAGE:
      return { ...state, show404Page: false };
    default:
      return state;
  }
};
