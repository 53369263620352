/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, ReactElement, useEffect } from "react";

import CommonIntegryModal from "components/common/common-integry-modal";
import showToast from "components/common/custom-toast/custom-toast";
import OverflowTooltip from "components/common/overflow-tooltip";
import MeatballsMenu from "components/integry-design-system/molecules/meatballs-menu";

import { deleteInvite } from "features/account-settings/services/multiplayer-apis";
import { inviteInterface } from "features/account-settings/services/interface";
import Tag from "components/integry-design-system/atoms/tag";
import templateAlertIcon from "images/template-alert-icon.svg";
import { useFetchUserQuery } from "utils/user/user-api";
import { teamMemberActions } from "./services/constants";

const TeamMember = (props: inviteInterface): ReactElement => {
  const {
    user_image,
    email,
    is_accepted,
    id,
    removeInviteFromList,
    user,
    inviteTeamMember,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [colorCode, SetColorCode] = useState("");
  const { data: loggedinUser } = useFetchUserQuery();

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * 3);
    const randomColorCode = ["#BB6BD9", "#27AE60", "#2F80ED"][randomIndex];
    SetColorCode(randomColorCode);
  }, []);

  const meatballOnSelect = (value: string): void => {
    switch (value) {
      case "resendInvite":
        inviteTeamMember(email);
        break;
      case "revokeAccess":
      case "cancelInvite":
        setShowModal(true);
        break;
      default:
    }
  };

  const onMemberRemove = (): void => {
    setIsDeleting(true);
    const userId = user?.is_admin_user ? "admin" : id;
    deleteInvite(userId)
      .then((res) => {
        showToast(
          `${email} removed from ${loggedinUser?.account_name}`,
          "Member removed"
        );
        setShowModal(false);
        setIsDeleting(false);
        removeInviteFromList(id);
      })
      .catch((e) => {
        setShowModal(false);
        setIsDeleting(false);
      });
  };
  const userName = (): string => {
    try {
      if (is_accepted && user?.first_name && user?.last_name) {
        return `${user?.first_name} ${user?.last_name}`;
      }
      return email.split("@")[0];
    } catch (error) {
      return "";
    }
  };
  const getInitials = (): string => {
    if (is_accepted && user?.first_name && user?.last_name) {
      return `${user?.first_name?.[0]?.toUpperCase()}${user?.last_name?.[0]?.toUpperCase()}`;
    }
    try {
      return email[0]?.toUpperCase();
    } catch (error) {
      return "";
    }
  };

  return (
    <tr className="team-row member-row">
      <div className="team-col-1 member-name-container">
        {user_image ? (
          <img src={user_image} alt="user" />
        ) : (
          <div
            className="user-name-initials align-center grid-item"
            style={{ background: colorCode }}
          >
            {getInitials()}
          </div>
        )}

        <OverflowTooltip
          className="font-size-14px grid-item color-333333 name"
          text={`${userName()}`}
        />
      </div>

      <div className="team-col-2 member-email-container">
        <OverflowTooltip
          className="font-size-14px color-999999 email"
          text={email}
        />
        {!is_accepted && <Tag type="team_member" label="Invited" />}
      </div>
      <div>
        {loggedinUser?.email !== email && (
          <MeatballsMenu
            items={teamMemberActions.filter((action) =>
              is_accepted
                ? action.value === "revokeAccess"
                : action.value !== "revokeAccess"
            )}
            onSelect={meatballOnSelect}
            customStyle={{ marginLeft: "5px" }}
          />
        )}
      </div>

      <CommonIntegryModal
        show={showModal}
        close={() => setShowModal(false)}
        title="Remove Member"
        message="This will remove your existing team member."
        icon={templateAlertIcon}
        action={onMemberRemove}
        actionButtonTitle="Remove"
        isLoading={isDeleting}
        modalClass="remove-user-modal"
      />
    </tr>
  );
};

export default TeamMember;
