/* eslint-disable @typescript-eslint/no-explicit-any */
import "./styles.scss";

import { CSSProperties, HTMLAttributes } from "react";
// import XIcon from "images/x.svg";
import XIcon from "images/x_grey.svg";

export interface IPill {
  id: string;
  label: string;
  value: any;
  valueToShow?: string;
}

export interface IProps extends HTMLAttributes<HTMLDivElement> {
  id: string;
  label: string;
  value: any;
  valueToShow?: string;
  customStyle?: CSSProperties;
  onClose?: (pill: IPill) => void;
}

const FilterPill = ({ customStyle = {}, ...props }: IProps): JSX.Element => {
  const { id, label, value, onClose } = props;
  const handleOnClose = (pill: IPill): void => {
    if (onClose) {
      onClose(pill);
    }
  };
  return (
    <div className="integry-filter-pill" style={{ ...customStyle }}>
      <div className="integry-filter-pill__content">
        <p className="integry-filter-pill__content--label">{label}</p>
        <p className="integry-filter-pill__content--value">{value}</p>
      </div>
      <div className="integry-filter-pill__actions">
        <img
          src={XIcon}
          alt={`${value}-close-icon`}
          aria-hidden="true"
          onClick={() => {
            handleOnClose({
              id,
              label,
              value,
            });
          }}
        />
      </div>
    </div>
  );
};

export default FilterPill;
