import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import Tag from "components/integry-design-system/atoms/tag";

import OverflowTooltip from "components/common/overflow-tooltip";
import { useFeatureFlags } from "context/featureFlagsContext";

import {
  statusLabels,
  networkCodeColors,
} from "legacy-features/integrations/runs-view/runs-listing";
import PlaceholderBar from "atomic-components/atom/placeholder-bar";

import TruncateBullets from "images/truncate_bullets.svg";
import expandTableIcon from "images/plus-circle.svg";

import webapp from "utils/config";

import { RunSteps } from "../../interface";
import "legacy-features/integrations/styles.scss";
import "./styles.scss";

interface StepRowProps {
  step: RunSteps;
  config: {
    id: string;
    subConfig?: { id: string; type: string }[];
    type?: string;
    tdStyle?: Record<string, unknown>;
    truncate?: boolean;
    showTooltip?: boolean;
    tooltipField?: string;
    hideSameValueTooltip?: boolean;
  }[];
  expandedStepId?: string[];
  expandedPayloadStepId?: string;
  onExpandClick?: (stepId: string) => void;
  onViewPayloadClick?: (stepId: string) => void;
  rowStyle?: Record<string, unknown>;
  node?: number;
}

const StepRow = ({
  step,
  config,
  expandedStepId = ["-1"],
  expandedPayloadStepId = "-1",
  onExpandClick,
  onViewPayloadClick,
  rowStyle = {},
  node = 0,
}: StepRowProps): JSX.Element => {
  const returnStepFlag = useFeatureFlags({
    flag: "return-step",
  });
  const shouldShowPayload = (() => {
    if (returnStepFlag && step.activity_id === webapp.abortStep.activityId) {
      return true;
    }
    return !nonPayloadActivities.includes(step.activity_type);
  })();

  const hasPayload = step.has_payload;
  return (
    <IntegryTooltip
      tooltipText={
        hasPayload ? "View Payload" : "Payload is deleted after 30 days"
      }
      placement="bottom"
      hideToolTip={!shouldShowPayload || expandedPayloadStepId === step.id}
    >
      <div
        className={`div-row div-row-new table-row ${
          shouldShowPayload ? "" : "disabled"
        } ${expandedPayloadStepId === step.id ? "payload-visible" : ""}`}
        style={rowStyle}
        onClick={() => {
          if (onViewPayloadClick && hasPayload && shouldShowPayload)
            onViewPayloadClick(step.id);
        }}
      >
        {config.map((configItem) => {
          const {
            id,
            subConfig,
            type = "text",
            tdStyle = {},
            truncate = false,
            showTooltip = false,
            tooltipField = "",
            hideSameValueTooltip = false,
          } = configItem;

          if (type === "loader") {
            return (
              <div className="div-column">
                <PlaceholderBar
                  customStyle={{
                    maxWidth: "80%",
                    borderRadius: "4px",
                    margin: "0px",
                  }}
                />
              </div>
            );
          }

          if (type === "tag") {
            const label =
              statusLabels[String(step[id]).toLowerCase()] || step[id];
            return (
              <div className="div-column">
                <Tag label={label} type="run" />
              </div>
            );
          }
          if (truncate) {
            return (
              <div className="div-column" style={tdStyle}>
                {step[id].length > 20 ? (
                  <IntegryTooltip tooltipText={step[id]} placement="bottom">
                    <div>
                      {step[id].substring(0, 3)}
                      <img
                        src={TruncateBullets}
                        style={{
                          width: "15px",
                          margin: "0 2px",
                          marginTop: "-3px",
                          objectFit: "none",
                        }}
                        alt="truncate_bullets"
                      />
                      {step[id].substring(
                        step[id].length - 6 + (node > 5 ? 5 : node)
                      )}
                    </div>
                  </IntegryTooltip>
                ) : (
                  `${step[id]}`
                )}
              </div>
            );
          }
          return (
            <div
              className="div-column"
              style={
                id === "network_code_label" || id === "network_code"
                  ? {
                      ...tdStyle,
                      color:
                        networkCodeColors[
                          `${step[id] || "N/A"}`.substring(0, 1)
                        ] || "#333333",
                    }
                  : tdStyle
              }
            >
              <IntegryTooltip
                hideToolTip={
                  !showTooltip ||
                  step[tooltipField] === "N/A" ||
                  (hideSameValueTooltip && step[id] === step[tooltipField])
                }
                tooltipText={step[tooltipField] || ""}
                placement="bottom"
              >
                <div>
                  {!subConfig && step[id]}
                  {subConfig && (
                    <div className="div-column__group">
                      {subConfig.map((subItem) => {
                        if (!step[id]) {
                          return null;
                        }
                        if (subItem.type === "img") {
                          return (
                            <img
                              src={step[id][subItem.id]}
                              alt={`ico_${step[id][subItem.id]}`}
                            />
                          );
                        }
                        if (subItem.type === "tag") {
                          return (
                            <>
                              {step[subItem.id] && (
                                <Tag
                                  label={`v${step[subItem.id]}`}
                                  type="grey"
                                />
                              )}
                            </>
                          );
                        }
                        return (
                          <OverflowTooltip
                            text={step[id][subItem.id]}
                            className="sub-item-text"
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              </IntegryTooltip>
            </div>
          );
        })}
        {step.has_children && (
          <img
            className="expand-sub-steps"
            style={
              expandedStepId.includes(step.id)
                ? {
                    transform: "rotate(180deg)",
                  }
                : {}
            }
            src={expandTableIcon}
            alt={`expand-sub-steps-${step.id}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (onExpandClick) {
                onExpandClick(step.id);
              }
            }}
            aria-hidden="true"
          />
        )}
      </div>
    </IntegryTooltip>
  );
};

export default StepRow;

const nonPayloadActivities = [
  "CONDITION_IF",
  "CONDITION_ELSEIF",
  "CONDITION_ELSE",
  "SYSTEM_ACTIVITY",
  "DO_WHILE_LOOP",
];
