import PropTypes from "prop-types";

import lang from "utils/lang/en";
import { track, turboMixpanelEvents } from "utils/mixpanel";

// import CheckCircleIcon from "images/check-circle.svg";
// import CheckCircleIcon from "images/success_icon_3d.svg";
import CheckCircleIcon from "images/success_icon_flat.svg";
import xIcon from "images/x.svg";
import "./styles.scss";

const OnboardingSuccessPortal = (props) => {
  const { onClose, meetingScheduled = true, user = {} } = props;

  const handleOnCloseClick = () => {
    if (onClose) {
      onClose();
    }
  };
  return (
    <div
      className="onboarding-success-portal"
      onClick={handleOnCloseClick}
      aria-hidden="true"
    >
      <div
        className="osp-container"
        onClick={(e) => e.stopPropagation()}
        aria-hidden="true"
        style={
          !meetingScheduled
            ? {
                maxWidth: "595px",
              }
            : {}
        }
      >
        <div className="osp-container__heading">
          <img src={CheckCircleIcon} alt="check_circle_ico" />
          <p>{lang.THANKYOU_FOR_SIGNUP}</p>
        </div>
        <div className="osp-container__content">
          {!meetingScheduled ? (
            <>
              <p style={{ marginBottom: "30px", fontWeight: "500" }}>
                {lang.EXPLORE_CREATE_OWN_FLOW_SUBTITLE}
              </p>
              <p>
                {`When you are ready to build flows with your own app, we will build your connector for you! Go to `}
                <span
                  onClick={() => {
                    handleOnCloseClick();
                    window.open("/wapp/apps/v4/", "_self");
                    if (user.email) {
                      track(
                        turboMixpanelEvents.GOTO_CONNECTORS_CTA_CLICK.name,
                        user,
                        {
                          [turboMixpanelEvents.GOTO_CONNECTORS_CTA_CLICK
                            .property]: "Goto connectors CTA click",
                        }
                      );
                    }
                  }}
                  aria-hidden="true"
                >
                  Connectors
                </span>
                {` to book your free onboarding call.`}
              </p>
            </>
          ) : (
            <>
              <p style={{ marginBottom: "30px", fontWeight: "500" }}>
                Your call with our integration expert has been booked. We will
                build your app&apos;s connector so you can get started!
              </p>
              <p>Meanwhile, explore the app and sample flows.</p>
            </>
          )}
        </div>
        <div className="osp-container__actions">
          <button onClick={handleOnCloseClick} type="button">
            {lang.IM_READY}
          </button>
        </div>
        <img
          src={xIcon}
          alt="close_icon"
          className="osp-close-btn"
          aria-hidden="true"
          onClick={() => {
            handleOnCloseClick();
          }}
        />
      </div>
    </div>
  );
};

OnboardingSuccessPortal.propTypes = {
  onClose: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  meetingScheduled: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.any,
};

export default OnboardingSuccessPortal;
