import React from "react";
import "./styles.scss";

const tableConfig = {
  config: [
    {
      id: "runId",
      label: "Run ID",
    },
    {
      id: "triggerName",
      label: "Flow Name",
    },
    {
      id: "triggerApp",
      label: "Trigger",
    },
    {
      id: "startTime",
      label: "Start Time",
    },
    {
      id: "duration",
      label: "Duration",
    },
    {
      id: "status",
      label: "Status",
      type: "tag",
    },
    {
      id: "networkCode",
      label: "Network Code",
    },
  ],
};
const renderTable = (): JSX.Element => {
  return (
    <>
      <table className="integry-table">
        <thead>
          <tr>
            {tableConfig.config.map((configItem) => {
              return <th>{configItem.label}</th>;
            })}
          </tr>
        </thead>
      </table>
    </>
  );
};
const RunsPlaceholder = () => {
  return (
    <>
      <div className="integry-views loading">
        {/* <div className="view-filters" /> */}
        <div className="view-table">{renderTable()}</div>
        <table className="integry-table">
          <thead>
            <tr>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td />
            </tr>
            <tr>
              <td />
            </tr>
            <tr>
              <td />
            </tr>
            <tr>
              <td />
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
export default React.memo(RunsPlaceholder);
