/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState, useEffect } from "react";
import { Field } from "formik";
import IntegryPopover from "components/integry-design-system/atoms/integry-popover-v2";
import TextareaAutosize from "react-textarea-autosize";
// eslint-disable-next-line import/no-extraneous-dependencies
import curry from "lodash/curry";

import {
  hasPathInTag,
  isValidTag,
  extractPathFromFeildName,
} from "utils/template-editor";

import { FormFeedback } from "reactstrap";
import FieldMapping from "components/forms/v4/field-mapping";
import whiteAddIcon from "images/templates-v3/white-plus.svg";
import blackAddIcon from "images/black-plus-icon.svg";
import txtIcon from "images/select-field-txt-icon.svg";

import { useFeatureFlags } from "context/featureFlagsContext";

import AddTagDropdown from "../v2/add-tag-dropdown";
import TagOptionTree from "../v3/tag-option-tree";
import BetterTemplateTag from "../v3/better-template-tag";
import PreloadImages from "../../common/preload-images";
import { isValidDate, isValidTime24H } from "../../../utils/functions";
import { flattenWithPath } from "../../../utils/tree";

import useOnClickOutside from "../../../utils/use-onclick-outside";
import SuggestionsDropdown from "./suggestions-dropdown";
import SuggestedTags from "./suggested-tags";
import TextEditor from "./TagEditor/TagEditor";
import "./text-field-tagged.scss";

interface TextFieldTaggedProps {
  name: string;
  validate?(value): string;
  [otherOptions: string]: any;
  customInput?: ReactElement;
  customStyle?: any;
  customTagsWithDescription?: boolean;
  simpleTags?: boolean;
  hideInfoText?: boolean;
  largerHeight?: boolean;
  errorPlaceHolder?: boolean;
  onFocusChange?: (change: "focus" | "blur") => void;
}
let TagDisappearTimeout: any;
const TextFieldTagged = (props: TextFieldTaggedProps): ReactElement => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSuggestionsDropdown, setShowSuggestionsDropdown] = useState(false);
  const [lastInputRef, setLastInputRef] = useState<any>(null);
  const [focusClass, setFocusClass] = useState("");
  const [cretPosition, setCretPosition] = useState(0);
  const [showCustomInput, setShowCustomInput] = useState(true);

  let formikField: Record<string, any>;
  let inputRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const divRef = React.useRef<HTMLDivElement>(null);

  const {
    name,
    saveFieldSetValueFunction,
    isFieldList,
    validate,
    validateOnChange,
    availableTags,
    onChange,
    onTagSelect = () => null,
    onBlur,
    onKeyDown,
    className,
    formikSteps,
    showTagDropdown,
    eventTypes,
    outsideClickIgnoreClass,
    preventDefault,
    stopPropagation,
    disableOnClickOutside,
    enableOnClickOutside,
    fixeWidthToParent = true,
    shortenHeight,
    treeStyles,
    buttonStyles,
    includeOnly,
    includeArraysOnly,
    stepHelpText,
    learnMoreUrl,
    formSubmitError,
    validationError,
    path,
    nestedFieldPath,
    fieldObject,
    parentFieldName,
    isCustomTag,
    customTags,
    disableTagField,
    disableTagDropDown,
    customInput,
    customChangeCallback,
    customOnBlur,
    customStyle = {},
    customTagsWithDescription = false,
    simpleTags = false,
    showSuggestions = false,
    suggestionOptions = [],
    onSuggestionSelect,
    showSuggestedTags = false,
    suggestedTagLimit = 0,
    hideInfoText = false,
    largerHeight,
    appendedCustomTags,
    errorPlaceHolder = false,
    lightIcon = false,
    onFocusChange = () => null,
    renderTextArea,
    ...rest
  } = props;

  useEffect(() => {
    const { field, form } = formikField;
    const { value } = field;
    const { setFieldValue } = form;
    const caretPosition = getCretPosition();
    if (saveFieldSetValueFunction) {
      saveFieldSetValueFunction(
        value || "",
        curry(setFieldValue, 2)(name),
        caretPosition,
        null,
        isFieldList
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cretPosition]);

  useEffect(() => {
    if (!showCustomInput) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [showCustomInput]);

  const addTagFunc = (value, setValue, tag): void => {
    const index = getCretPosition();
    const newValue =
      value && !isFieldList
        ? `${value.slice(0, index)}${tag}${value.slice(index)}`
        : tag;
    setValue(name, newValue);
    setFocusClass("focused");
    if (TagDisappearTimeout) {
      clearTimeout(TagDisappearTimeout);
      TagDisappearTimeout = null;
    }
    setShowDropdown(false);
    onTagSelect(newValue);
  };

  const getCretPosition = (): number => {
    const { current } = inputRef;
    const { field } = formikField;
    const { value } = field;

    return current
      ? current.selectionStart
      : cretPosition || (value ? value.length : 0);
  };

  const toggleDropdown = (value): void => {
    // const { setFieldTouched } = formikField.form;
    // const { touched } = formikField.meta;
    if (!disableTagField && !disableTagDropDown) {
      if (value === true) {
        setShowSuggestionsDropdown(false);
      }
      setShowDropdown(value);
      // setFieldTouched(name, !touched);
    }
  };

  const clickOutside = (evt): void => {
    if (showDropdown) {
      toggleDropdown(false);
    }
    setShowSuggestionsDropdown(false);
    if (customOnBlur && isCustomTag) {
      customOnBlur("");
    }
    if (focusClass) {
      TagDisappearTimeout = null;
      clearTimeout(TagDisappearTimeout);
      TagDisappearTimeout = setTimeout(() => {
        setFocusClass("");
      }, 700);
    }
  };

  const setLastInputRefFunc = (ref): void => {
    setLastInputRef(ref);
  };

  const setFocusClassFunc = (val): void => {
    if (!val) {
      setFocusClass("");
    } else {
      setFocusClass(val);
    }
  };

  const setCretPositionFunc = (pos): void => {
    setCretPosition(pos);
  };

  const getFilteredSuggestedOptions = (searchValue): any => {
    if (searchValue) {
      return suggestionOptions.filter((opt) => {
        if (opt.label.includes(searchValue) && opt.label !== searchValue) {
          return opt.label;
        }
        return null;
      });
    }
    return suggestionOptions;
  };

  const renderField = (betterTagField) => (fieldProps) => {
    const { selectedDataTree = {}, showTagPills = true } = props;
    let flattenTree: any = [];
    if (
      selectedDataTree &&
      selectedDataTree.completeDataTree &&
      selectedDataTree.completeDataTree.steps
    ) {
      flattenTree = flattenWithPath(
        selectedDataTree.completeDataTree.steps[0],
        "steps",
        []
      );
    }

    formikField = fieldProps;
    let { type } = betterTagField || props;
    const { meta, field, form } = fieldProps;
    const { value } = field;
    const { touched, error } = meta;
    const { setFieldValue, setFieldTouched } = form;
    const addTag = curry(addTagFunc, 3)(value, setFieldValue);
    const isInvalid =
      !disableTagField && ((touched && error) || (formSubmitError && error));
    const validationClass =
      isInvalid && !showSuggestionsDropdown ? " is-invalid" : "";
    const hasPath = hasPathInTag(value);
    const isValidTagFlag = isValidTag(value, isCustomTag);

    if (customInput && showCustomInput && value) {
      setShowCustomInput(false);
    } else if (customInput && !value && !showCustomInput) {
      setShowDropdown(false);
      setShowCustomInput(true);
    }

    if (
      showSuggestions &&
      !showSuggestionsDropdown &&
      !showDropdown &&
      suggestionOptions.length > 0 &&
      focusClass
    ) {
      setShowSuggestionsDropdown(true);
    } else if (!focusClass && showSuggestionsDropdown) {
      setShowSuggestionsDropdown(false);
    }

    if (type === "date" && value) {
      const isValidValue = isValidDate(value);
      if (!isValidValue) {
        type = "text";
      }
    } else if (type === "time" && value) {
      const isValidValue = isValidTime24H(value);
      if (!isValidValue) {
        type = "text";
      }
    }
    const inputField = (): ReactElement => {
      if (renderTextArea) {
        return (
          <TextareaAutosize
            ref={(ref) => {
              if (ref) {
                inputRef = {
                  current: ref,
                };
              }
            }}
            disabled={rest.disabled}
            spellCheck={false}
            value={value || ""}
            onChange={(e) => {
              setFieldValue(props.name, e.target.value);
              if (onChange) {
                onChange(e.target.value, e);
              }
              // setHeightBasedOnContent(e);
            }}
            onBlur={(e) => {
              setFieldTouched();
              if (onBlur) {
                onBlur(e);
              }
            }}
            onClick={() =>
              saveFieldSetValueFunction
                ? saveFieldSetValueFunction(
                    value || "",
                    curry(setFieldValue, 2)(props.name),
                    inputRef.current && inputRef.current.selectionStart,
                    inputRef.current
                  )
                : () => ({})
            }
            className={`form-control${validationClass} ${className} integry-scrollbar-v2`}
            placeholder={rest.placeholder}
            maxRows={5}
            minRows={1}
          />
        );
      }

      return value &&
        betterTagField &&
        (isCustomTag ? true : !hasPath) &&
        isValidTagFlag &&
        showTagPills ? (
        <div
          onClick={(e) => {
            if (lastInputRef.current) {
              lastInputRef.current.focus();
            }
          }}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (lastInputRef.current) {
                lastInputRef.current.focus();
              }
            }
          }}
          role="button"
          className={`better-tagged-text-field form-control ${validationClass} ${className} ${
            disableTagField ? "disabled" : ""
          }`}
          data-placeholder="Enter value"
          spellCheck={false}
          id={props.id}
        >
          <BetterTemplateTag
            tag={value}
            name={name}
            formikSteps={formikSteps}
            flattenTree={flattenTree}
            setLastInputRef={setLastInputRefFunc}
            setCretPosition={setCretPositionFunc}
            formikField={fieldProps}
            setFocusClass={setFocusClassFunc}
            isCustomTag={isCustomTag}
            disableTagField={disableTagField}
            customChangeCallback={customChangeCallback}
            customTags={customTags}
            simpleTags={simpleTags}
            includesAppendedTags={!!appendedCustomTags}
            {...rest}
          />
        </div>
      ) : (
        <input
          autoComplete="off"
          style={{
            border: 0,
          }}
          {...rest}
          type={type}
          value={value || ""}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
            if (onChange) {
              onChange(e.target.value, e);
            }
            if (saveFieldSetValueFunction) {
              saveFieldSetValueFunction(
                e.target.value || "",
                curry(setFieldValue, 2)(name),
                inputRef.current && inputRef.current.selectionStart,
                inputRef.current,
                isFieldList
              );
            }
          }}
          onFocus={(e) => {
            onFocusChange("focus");
          }}
          onBlur={(e) => {
            setFieldTouched(name);
            if (!showSuggestions && !isCustomTag) {
              setFocusClass("");
            }
            if (onBlur) {
              onBlur(e);
            }
            onFocusChange("blur");
          }}
          className={`form-control${validationClass} ${className}`}
          ref={inputRef as React.RefObject<HTMLInputElement>}
          onClick={(e) => {
            setFocusClass("focused");
            if (TagDisappearTimeout) {
              clearTimeout(TagDisappearTimeout);
              TagDisappearTimeout = null;
            }
            if (saveFieldSetValueFunction) {
              saveFieldSetValueFunction(
                value || "",
                curry(setFieldValue, 2)(name),
                inputRef.current && inputRef.current.selectionStart,
                inputRef.current,
                isFieldList
              );
            }
          }}
          onKeyDown={(e) => {
            if (onKeyDown) {
              onKeyDown(e);
            }
            if (e.key === "Enter") {
              if (isCustomTag && customChangeCallback) {
                customChangeCallback(value, true);
              }
            }
          }}
        />
      );
    };

    const getElementWidth = (element): number => {
      if (!fixeWidthToParent) {
        return 400;
      }
      if (element?.getBoundingClientRect) {
        const rect = element.getBoundingClientRect();
        return rect.width;
      }
      return 0;
    };
    return (
      <>
        <div
          ref={divRef}
          style={customStyle}
          className={fixeWidthToParent ? "tagged-field-container" : ""}
        >
          <IntegryPopover
            show={showDropdown}
            close={() => toggleDropdown(false)}
            positions={["bottom"]}
            parentElement={divRef?.current || undefined}
            hideArrow
            arrowPosition={{ top: 0 }}
            containerStyle={{
              paddingBottom: "0px",
            }}
            popoverContent={
              <TagOptionTree
                show={showDropdown}
                formikSteps={formikSteps}
                key={showDropdown}
                addTag={addTag}
                treeStyles={treeStyles}
                includeOnly={includeOnly}
                includeArraysOnly={includeArraysOnly}
                stepHelpText={stepHelpText}
                learnMoreUrl={learnMoreUrl}
                isFieldList={isFieldList}
                isCustomTag={isCustomTag}
                customTags={customTags}
                showTagsWithDescription={customTagsWithDescription}
                infoText={
                  !hideInfoText &&
                  "Tags are replaced with actual values during usage by your users (and during testing by you)"
                }
                customStyle={{
                  maxWidth: "inherit",
                  width: getElementWidth(divRef?.current),
                }}
                appendedCustomTags={appendedCustomTags}
                path={extractPathFromFeildName(name)}
              />
            }
            arrowColor="#a0a7f7"
          >
            <div
              className={
                isFieldList && value && isValidTagFlag
                  ? `field-mapping tagged-field ${validationClass} ${focusClass} ${
                      disableTagField ? "disabled" : ""
                    }`
                  : `tagged-field ${validationClass} ${focusClass} ${
                      disableTagField ? "disabled" : ""
                    } ${shortenHeight ? "shorten-height" : ""} ${
                      largerHeight ? "larger-height" : ""
                    } ${renderTextArea ? "text-area-tagged" : ""}`
              }
              style={
                renderTextArea
                  ? {}
                  : shortenHeight
                  ? { height: 35 }
                  : largerHeight
                  ? { height: 42 }
                  : { height: 37.5 }
              }
            >
              {customInput && showCustomInput ? customInput : inputField()}
              <button
                className={
                  showDropdown
                    ? `add-tag-button-v3 ${
                        disableTagDropDown ? "disabled" : ""
                      } align-center selected`
                    : `add-tag-button-v3 ${
                        disableTagDropDown ? "disabled" : ""
                      } align-center`
                }
                type="button"
                onClick={() => toggleDropdown(!showDropdown)}
                style={
                  shortenHeight
                    ? { height: 33, ...buttonStyles }
                    : largerHeight
                    ? { height: 40, ...buttonStyles }
                    : { height: 35, ...buttonStyles }
                }
              >
                <img
                  src={showDropdown || lightIcon ? whiteAddIcon : blackAddIcon}
                  alt="add-tag"
                />
              </button>
            </div>
          </IntegryPopover>

          {isInvalid ? (
            <FormFeedback style={{ display: error ? "block" : "none" }}>
              {error}
            </FormFeedback>
          ) : (
            <>{errorPlaceHolder && <div style={{ height: 23 }} />}</> // Prevent page jumping if erroe is displayed
          )}

          {showTagDropdown && (
            <AddTagDropdown availableTags={availableTags} addTag={addTag} />
          )}
          {showSuggestions && showSuggestionsDropdown && focusClass && (
            <SuggestionsDropdown
              searchValue={value}
              options={suggestionOptions}
              onSelect={onSuggestionSelect}
              setFocusClass={setFocusClass}
            />
          )}
          {getFilteredSuggestedOptions(value).length === 0 &&
            (showSuggestedTags || isCustomTag) &&
            focusClass && (
              <SuggestedTags
                tags={customTags}
                onSelect={addTag}
                setFocusClass={setFocusClass}
                fieldValue={value}
                tagLimit={suggestedTagLimit}
              />
            )}
          <PreloadImages images={[blackAddIcon, whiteAddIcon, txtIcon]} />
        </div>

        {isFieldList && (
          <FieldMapping
            formikSteps={formikSteps}
            path={path}
            tag={value}
            nestedFieldPath={nestedFieldPath}
            fieldObject={fieldObject}
            setFieldValue={setFieldValue}
            txtIcon={txtIcon}
            flattenTree={flattenTree}
            parentFieldName={parentFieldName}
          />
        )}
      </>
    );
  };
  useOnClickOutside(divRef, clickOutside);
  return (
    <Field name={name} validate={validate} validateOnChange={validateOnChange}>
      {renderField(false)}
    </Field>
  );
};

TextFieldTagged.defaultProps = {
  validate: () => "",
};

const FlaggedTextFieldTagged = (props: TextFieldTaggedProps): ReactElement => {
  const { name, disabled, placeholder, appendedCustomTags, includeArraysOnly } =
    props;
  const showTagEditor = useFeatureFlags({
    flag: "tag-editor",
  });

  // get current page URL
  const isTemplateV6 = window.location.href.includes("templates/v6"); // check if the current URL is templates/v6

  return (
    <>
      {showTagEditor && !isTemplateV6 ? (
        <TextEditor
          name={name}
          key={name}
          disabled={disabled}
          placeholder={placeholder}
          includeArraysOnly={includeArraysOnly}
          appendedCustomTags={appendedCustomTags}
        />
      ) : (
        <TextFieldTagged {...props} />
      )}
    </>
  );
};

export default FlaggedTextFieldTagged;
