/* eslint-disable import/no-cycle */
import http from "utils/http";
import { IObject } from "./index";

interface IParams {
  pageSize?: string;
}

export const fetchSystemGeneratedObjects = (params: IParams): Promise<null> => {
  const { pageSize = "30" } = params;
  return http.get(`/api/v1/objects/?page_size=${pageSize}`);
};

export const setSystemGeneratedObjectsToLS = (objects: IObject[]): void => {
  localStorage.setItem("SYSTEM_OBJECTS", JSON.stringify(objects));
};

export const getSystemGeneratedObjectsFromLS = (): IObject[] => {
  const objects = localStorage.getItem("SYSTEM_OBJECTS");
  return objects ? JSON.parse(objects) : [];
};

export const fetchAndSetSystemGeneratedObjects = (params: IParams): void => {
  fetchSystemGeneratedObjects(params)
    .then((response: any) => {
      const { data = {} } = response;
      const { results = [] } = data;
      const tempObjects = results.map((obj) => {
        return {
          id: obj.name.toLowerCase(),
          name: obj.name,
          fields: obj.data,
        };
      });
      setSystemGeneratedObjectsToLS(tempObjects);
    })
    .catch((error) => {
      console.error("ERROR while fetching object:", {
        error,
      });
      setSystemGeneratedObjectsToLS([]);
    });
};
