import http from "../../utils/http";

export const REQUEST_GRANT_TYPES = "REQUEST_GRANT_TYPES";
export const RECEIVE_GRANT_TYPES = "RECEIVE_GRANT_TYPES";

export const requestGrantTypes = () => ({
  type: REQUEST_GRANT_TYPES,
});

export const receiveGrantTypes = (json) => ({
  type: RECEIVE_GRANT_TYPES,
  grant_types: normalizeGrantTypes(json),
});

export const fetchGrantTypes = () => {
  return (dispatch) => {
    dispatch(requestGrantTypes());

    http.get("/api/authorization_types/grant_types/").then(
      (response) => {
        dispatch(receiveGrantTypes(response.data));
      },
      (error) => {
        console.log(
          "Error occurred while fetching grant types in fetchGrantTypes() action.",
          error
        );
      }
    );
  };
};

export const normalizeGrantTypes = (grantTypes) => {
  return grantTypes
    .map((val) => ({
      name: Object.values(val)[0],
      value: Object.keys(val)[0],
    }))
    .filter((grantType) => grantType.value !== "password");
};
