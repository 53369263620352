/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ReactElement, useState } from "react";

import { copyStringToClipboard } from "utils/functions";

import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import "./styles.scss";

const EyeClosed = (): ReactElement => (
  <svg
    width="18"
    height="15"
    viewBox="0 0 19 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.47814 9.44496C3.40804 11.9472 6.49752 13.4332 9.73769 13.4332C11.0992 13.4332 12.4106 13.1795 13.6463 12.6841C13.9842 12.5543 14.1629 12.1767 14.0129 11.836C13.8763 11.5097 13.4833 11.3425 13.1324 11.4847C12.0593 11.9152 10.9112 12.1305 9.73769 12.1305C6.87888 12.1305 4.27075 10.8625 2.5737 8.67413L2.5737 8.67412C2.22357 8.22287 2.22407 7.62302 2.57331 7.18447L2.57407 7.18348C2.93539 6.70588 3.35942 6.27549 3.82164 5.88041L3.82166 5.88043L3.82282 5.87938C4.0934 5.63303 4.12144 5.22889 3.87662 4.95517L3.87667 4.95513L3.87486 4.95331C3.61859 4.69478 3.20045 4.6686 2.91659 4.90176L2.91659 4.90176L2.91608 4.90219C2.38651 5.34928 1.89455 5.85694 1.47806 6.4012C0.773953 7.30919 0.773979 8.53699 1.47814 9.44496ZM1.47814 9.44496C1.47812 9.44493 1.4781 9.44491 1.47808 9.44488L1.51767 9.41434L1.47816 9.44499C1.47816 9.44498 1.47815 9.44497 1.47814 9.44496ZM16.9146 8.66159L16.914 8.66232C16.465 9.24809 15.9286 9.78605 15.3299 10.2403C15.0301 10.4625 14.9904 10.8679 15.2205 11.1534C15.3565 11.3229 15.5473 11.4006 15.749 11.4006C15.8952 11.4006 16.0454 11.3624 16.1694 11.2573C16.8627 10.7257 17.4677 10.1216 17.9971 9.44516L17.9972 9.44499C18.7014 8.53699 18.7014 7.30912 17.9972 6.40112C16.0673 3.89891 12.9779 2.41289 9.73769 2.41289C8.38865 2.41289 7.06482 2.66661 5.82924 3.1499C5.48928 3.28031 5.32629 3.65859 5.46206 3.99688L5.46205 3.99688L5.46232 3.99752C5.59861 4.32407 5.99173 4.49153 6.34266 4.34945C7.41597 3.93087 8.56427 3.71556 9.73769 3.71556C12.5966 3.71556 15.2047 4.97161 16.9142 7.17197C17.2644 7.62321 17.2638 8.22305 16.9146 8.66159Z"
      strokeWidth="0.1"
    />
    <path
      d="M6.5252 7.88765C6.5252 9.54086 7.93007 10.8842 9.64986 10.8842C10.3755 10.8842 11.077 10.6525 11.6263 10.1998C11.9104 9.97885 11.9665 9.56156 11.7206 9.28603C11.4903 9.01395 11.0581 8.96162 10.7728 9.19592C10.4561 9.45265 10.0659 9.58153 9.64986 9.58153C8.67147 9.58153 7.88016 8.82019 7.88016 7.88765C7.88016 7.5213 7.57965 7.23631 7.20268 7.23631C6.8257 7.23631 6.5252 7.5213 6.5252 7.88765ZM11.4196 7.89968C11.4196 8.26602 11.7201 8.55101 12.097 8.55101C12.4747 8.55101 12.7745 8.25336 12.7745 7.89968C12.7745 6.24647 11.3696 4.90312 9.64986 4.90312C8.94969 4.90312 8.28638 5.11058 7.73743 5.52647C7.43921 5.74749 7.38406 6.15253 7.61544 6.43962C7.66953 6.50758 7.73706 6.56446 7.81401 6.60704C7.89102 6.64965 7.97599 6.67714 8.06399 6.68799C8.15199 6.69883 8.24138 6.69282 8.32698 6.6703C8.41252 6.6478 8.49267 6.60923 8.56276 6.55674C8.87995 6.32296 9.25841 6.2058 9.64986 6.2058C10.6282 6.2058 11.4196 6.96713 11.4196 7.89968Z"
      strokeWidth="0.1"
    />
    <path
      d="M14.8338 14.4162L14.8339 14.4164C14.9684 14.5581 15.1445 14.6364 15.3345 14.6364C15.4967 14.6364 15.6453 14.5843 15.7791 14.4818L15.7792 14.4819L15.7814 14.4799C16.0669 14.2323 16.0799 13.8274 15.8361 13.5549L15.8362 13.5549L15.8353 13.554L4.20181 1.16644L4.20171 1.16634C3.94422 0.893604 3.52529 0.881715 3.2426 1.11392L3.24259 1.11391L3.24158 1.11478C2.95607 1.36234 2.94315 1.7673 3.18687 2.03979L3.18686 2.0398L3.18772 2.04072L14.8338 14.4162Z"
      strokeWidth="0.1"
    />
  </svg>
);

const EyeVisible = (): ReactElement => (
  <svg
    width="18"
    height="11"
    viewBox="0 0 18 11"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.42678 2.64519L2.426 2.6458C1.89515 3.06596 1.4018 3.5432 0.983945 4.05511C0.270967 4.91713 0.270976 6.08457 0.983972 6.94659C2.91945 9.29923 6.01607 10.6947 9.2619 10.6947C10.6259 10.6947 11.9399 10.4564 13.1782 9.99104C13.5259 9.86576 13.7186 9.49584 13.5593 9.15654C13.4158 8.83526 13.0084 8.6767 12.649 8.81315C11.5785 9.21574 10.433 9.4172 9.2619 9.4172C6.40878 9.4172 3.80771 8.23082 2.1162 6.18591L2.11619 6.18589C1.7749 5.77354 1.77572 5.22788 2.11557 4.82778L2.11677 4.82632C2.47658 4.38045 2.89913 3.97829 3.36027 3.60877L3.36029 3.6088L3.36206 3.60729C3.64422 3.36646 3.67486 2.96735 3.41784 2.69794L3.41791 2.69787L3.4151 2.69522C3.15006 2.44457 2.71978 2.41956 2.42678 2.64519ZM15.6149 8.47531L15.6157 8.47469C16.1465 8.05453 16.6399 7.57729 17.0577 7.06539C17.7708 6.20331 17.7707 5.03578 17.0576 4.17375C15.1221 1.8212 12.0255 0.425781 8.77979 0.425781C7.41576 0.425781 6.10181 0.664063 4.86351 1.12944C4.51579 1.25471 4.32311 1.62464 4.48237 1.96395C4.62595 2.28531 5.03352 2.44386 5.39293 2.30725L5.36628 2.23714L5.39269 2.30734C6.46314 1.90475 7.60873 1.70329 8.77979 1.70329C11.6329 1.70329 14.234 2.88967 15.9255 4.93459V4.9346C16.2668 5.34695 16.266 5.89262 15.9261 6.29271L15.9249 6.29417C15.5651 6.74004 15.1426 7.1422 14.6814 7.51172V7.51169L14.6796 7.5132C14.3975 7.75403 14.3668 8.15314 14.6239 8.42255L14.6238 8.42262L14.6266 8.42527C14.8916 8.67592 15.3219 8.70094 15.6149 8.47531Z"
      strokeWidth="0.15"
    />
    <path
      d="M9.28923 8.15586C10.8404 8.15586 12.1034 6.95001 12.1034 5.45586C12.1034 3.96172 10.8404 2.75586 9.28923 2.75586C7.73807 2.75586 6.4751 3.96172 6.4751 5.45586C6.4751 6.95001 7.73807 8.15586 9.28923 8.15586ZM9.28923 7.09282C8.34102 7.09282 7.57784 6.35695 7.57784 5.45586C7.57784 4.55477 8.34102 3.81891 9.28923 3.81891C10.2374 3.81891 11.0006 4.55477 11.0006 5.45586C11.0006 6.35695 10.2374 7.09282 9.28923 7.09282Z"
      strokeWidth="0.15"
    />
  </svg>
);

const CopyBtn = (): ReactElement => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="1.5"
      width="7.5"
      height="9.5"
      rx="0.75"
      strokeWidth="1.5"
    />
    <rect x="3.75" y="5" width="7.5" height="9.5" rx="0.75" strokeWidth="1.5" />
  </svg>
);

interface RedactedInputFieldProps {
  value: string;
  tooltip?: string;
  initialVisible?: boolean;
  inputClassName?: string;
}

const RedactedInputField = (props: RedactedInputFieldProps): ReactElement => {
  const {
    value = "",
    tooltip,
    initialVisible = false,
    inputClassName = "",
  } = props;

  const [visible, setVisible] = useState(initialVisible);
  const [copyClicked, setCopyClicked] = useState(false);

  const onCopy = (): void => {
    setCopyClicked(true);
    copyStringToClipboard(value);
  };

  return (
    <div className="redacted-input-field">
      <div className="redacted-input-wrapper">
        <input
          type={visible ? "text" : "password"}
          className={`value ${inputClassName}`}
          value={value}
          disabled
          autoComplete="off"
        />
        <IntegryTooltip hideToolTip={false} tooltipText={tooltip}>
          <div
            className="toggleView"
            role="button"
            tabIndex={-1}
            onClick={() => setVisible((prev) => !prev)}
          >
            {visible ? <EyeClosed /> : <EyeVisible />}
          </div>
        </IntegryTooltip>
        <IntegryTooltip
          hideToolTip={false}
          tooltipText={copyClicked ? "Copied!" : "Copy"}
        >
          <div
            className="copyBtn"
            onClick={onCopy}
            role="button"
            tabIndex={-1}
            onMouseLeave={() => setCopyClicked(false)}
          >
            <CopyBtn />
          </div>
        </IntegryTooltip>
      </div>
    </div>
  );
};

export default RedactedInputField;
