import React, { ReactElement } from "react";
import DeletePop from "components/integry-design-system/molecules/integry-dialogue-pop/delete-pop";
import "./styles.scss";

export interface IIntegryDialoguePopProps {
  onCancelClick?: () => void;
  performActionCallback: () => void;
  dialogueType: "warning" | "delete";
  title: string;
  description: string;
  actionBtnLabel: string;
  cancelBtnLabel: string;
  hideActionBtn?: boolean;
}

const IntegryDialoguePop = (props: IIntegryDialoguePopProps): ReactElement => {
  const {
    onCancelClick,
    performActionCallback,
    dialogueType,
    title,
    description,
    actionBtnLabel,
    cancelBtnLabel,
    hideActionBtn = false,
  } = props;

  const renderDialoguePop = (): ReactElement => {
    if (dialogueType === "warning" || dialogueType === "delete") {
      return (
        <DeletePop
          onCancelClick={onCancelClick}
          performActionCallback={performActionCallback}
          title={title}
          description={description}
          actionBtnLabel={actionBtnLabel}
          cancelBtnLabel={cancelBtnLabel}
          hideActionBtn={hideActionBtn}
          dialogueType={dialogueType}
        />
      );
    }
    return <></>;
  };

  return <div className="integry-dialogue-pop">{renderDialoguePop()}</div>;
};

export default IntegryDialoguePop;
