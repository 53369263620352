/* eslint-disable jsx-a11y/label-has-associated-control */
import { ReactElement, useEffect, useRef } from "react";
import { Formik, FormikProps, Form } from "formik";
import Checkbox from "components/forms/v4/checkbox";
import Select from "components/forms/v4/select";
import "./notification-throttle.scss";

export interface NotificationThrottleProps {
  onSave?: (notificationSettings) => void;
  notificationSettings?: {
    error_throttling_duration: number;
  };
}

export interface formikRefProps {
  values?: {
    error_throttling_duration: string | number;
  };
  setFieldValue?: (fieldName: string, value: unknown) => void;
}

const initialValues = {
  error_throttling_duration: 1,
};

const NotificationThrottle = (
  props: NotificationThrottleProps
): ReactElement => {
  const { onSave, notificationSettings } = props;
  const formikRef = useRef<
    FormikProps<{
      error_throttling_duration: number;
    }>
  >();

  const throttleOptions = [
    { label: "1 hour", id: "1" },
    { label: "2 hours", id: "2" },
    { label: "3 hours", id: "3" },
    { label: "4 hours", id: "4" },
    { label: "5 hours", id: "5" },
    { label: "6 hours", id: "6" },
    { label: "7 hours", id: "7" },
    { label: "8 hours", id: "8" },
    { label: "9 hours", id: "9" },
    { label: "10 hours", id: "10" },
    { label: "11 hours", id: "11" },
    { label: "12 hours", id: "12" },
    { label: "13 hours", id: "13" },
    { label: "14 hours", id: "14" },
    { label: "15 hours", id: "15" },
    { label: "16 hours", id: "16" },
    { label: "17 hours", id: "17" },
    { label: "18 hours", id: "18" },
    { label: "19 hours", id: "19" },
    { label: "20 hours", id: "20" },
    { label: "21 hours", id: "21" },
    { label: "22 hours", id: "22" },
    { label: "23 hours", id: "23" },
    { label: "24 hours", id: "24" },
  ];

  useEffect(() => {
    if (
      formikRef &&
      !formikRef.current?.values?.error_throttling_duration &&
      formikRef.current?.setFieldValue
    )
      formikRef.current?.setFieldValue("error_throttling_duration", 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitt = (values): void => {
    if (onSave) onSave(values);
  };

  return (
    <div className="settings-container">
      <h3 className="error-section-heading">Settings</h3>
      <Formik
        initialValues={notificationSettings || initialValues}
        onSubmit={handleSubmitt}
      >
        {(formik) => {
          formikRef.current = formik;
          return (
            <Form>
              <>
                <div className="notifications-throttle-container">
                  {formik ? (
                    <Checkbox
                      name="is_error_throttling_enabled"
                      className="throttle-checkbox"
                      onChangeCustom={formik.submitForm}
                    />
                  ) : (
                    <input
                      className="throttle-checkbox"
                      id="throttle-checkbox"
                      type="checkbox"
                    />
                  )}
                  <label className="throttle-label" htmlFor="throttle-checkbox">
                    Mute recurring run aborted notifications and send only once
                    every
                  </label>

                  <Select
                    className="throttle-duration"
                    classNamePrefix="throttle-duration-select"
                    name="error_throttling_duration"
                    options={throttleOptions}
                    isClearable={false}
                    disableSeparator
                    onChange={() => {
                      formik?.setFieldValue(
                        "is_error_throttling_enabled",
                        true
                      );
                      formik.submitForm();
                    }}
                    selectV3
                  />
                </div>
              </>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default NotificationThrottle;
