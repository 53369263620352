import { useCallback, useRef } from "react";

// Define the hook with generic type F to represent a function type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useDebounced<F extends (...args: any[]) => void>(
  fn: F,
  delay: number
): (...args: Parameters<F>) => void {
  const timeoutRef = useRef<NodeJS.Timeout>();

  const debouncedFn = useCallback(
    (...args: Parameters<F>) => {
      // Clear the existing timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      // Set a new timeout
      timeoutRef.current = setTimeout(() => {
        fn(...args);
      }, delay);
    },
    [fn, delay]
  ); // Dependency array includes the function and the delay

  return debouncedFn;
}

export default useDebounced;
