import { ReactElement, useRef } from "react";
import { Formik, Form, FormikProps } from "formik";
import { FeatureFlag } from "react-launch-darkly";
import { User } from "types/integry-user";
import Button from "components/common/reusable-button";
import TextField from "components/forms/v4/text-field";
import AppIcon from "components/forms/v4/app-icon";
import "./company-profile.scss";
import { useUpdateUserCompanyInfoMutation } from "utils/user/user-api";
import { toast } from "react-toastify";

interface IProps {
  user: User;
  switchAccount?: boolean;
}
const CompanyProfile = ({
  user,
  switchAccount = false,
}: IProps): ReactElement => {
  const [updateCompanyInfo, { isLoading }] = useUpdateUserCompanyInfoMutation();
  const formikRef = useRef<
    FormikProps<{
      name: string;
      url: string;
      icon_url: string;
    }>
  >();

  const onSubmit = async (values): Promise<void> => {
    try {
      await updateCompanyInfo(values).unwrap();
      toast.success(
        <p className="toast-text">
          {`Your ${
            switchAccount ? "workspace" : "company"
          } profile has been updated successfully.`}
        </p>
      );
      formikRef.current?.resetForm({ values });
    } catch (e) {
      console.log(e);
    }
  };
  const checkUrlTest = (url: string): boolean => {
    return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(
      url
    );
  };
  const initialValues = {
    name: user.account?.name || "",
    url: user.account?.url || "",
    icon_url: user.account?.icon_url || "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={(values) => {
        let errors;
        if (!!values?.url && !checkUrlTest(values?.url)) {
          errors = { url: "Please enter a valid URL" };
        }
        if (!values.name) {
          errors = { name: "This field is required" };
        }
        return errors;
      }}
    >
      {(formik) => {
        formikRef.current = formik;
        return (
          <>
            <div className="account-setting-wrapper team-management-wrapper">
              <h2 className="page-heading">{`${
                switchAccount ? "Workspace" : "Company"
              } Profile`}</h2>

              <div className="account-settings-form">
                <div>
                  <Form>
                    <div className="company-profile-form">
                      <div className="company-logo-container">
                        <AppIcon
                          name="icon_url"
                          buttonText="Update Logo"
                          resizeFile
                          cropSquare
                        />

                        <div />
                      </div>
                      <div className="conpmay-info-update-container">
                        <div className="field-label-container">
                          <p className="field-label">{`${
                            switchAccount ? "Workspace" : "Company"
                          } Name`}</p>
                          <span className="required">*</span>
                        </div>
                        <TextField
                          placeholder={`${
                            switchAccount ? "Workspace" : "Company"
                          } Name`}
                          name="name"
                          className="integry-text-field"
                          errorPlaceHolder
                        />
                        <p className="field-label">Website URL</p>
                        <TextField
                          placeholder="www.doneday.net"
                          name="url"
                          className="integry-text-field"
                          errorPlaceHolder
                          validate
                        />
                        <Button
                          title="Save"
                          onClick={() => ({})}
                          className="btn btn-royal-blue save-btn"
                          type="submit"
                          isLoading={isLoading}
                          disabled={
                            isLoading ||
                            !!formik.errors.name ||
                            !!formik.errors.url ||
                            !formik.dirty
                          }
                        />
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export const FlaggedCompanyProfile = ({
  user,
}: Pick<IProps, "user">): ReactElement => (
  <FeatureFlag
    flagKey="workspace"
    renderFeatureCallback={() => <CompanyProfile user={user} switchAccount />}
    renderDefaultCallback={() => <CompanyProfile user={user} />}
  />
);

export default FlaggedCompanyProfile;
