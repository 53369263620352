import { useRef } from "react";
import { useWorkspaceAppContext } from "context/workspaceAppContext";
import { useFetchUserQuery } from "utils/user/user-api";
import { sdkQueryParams as authParams } from "utils/auth-params";
import config from "utils/config";
import { ConnectedAccuount } from "features/templates/interface/authorization";

const useAddAuthorization = ({
  onAddAccount,
}: {
  onAddAccount: (account: ConnectedAccuount) => void;
}): {
  addNewAccount: (arg0: {
    userId?: string;
    authorizationType: { type_id: string; provider: string; id: number };
  }) => void;
} => {
  const { workspaceApp } = useWorkspaceAppContext();
  const { data: user } = useFetchUserQuery();
  const isEventListened = useRef(false);

  const onAccountRecieve = (event: {
    data: {
      authorization_id: string;
      user_identity: string;
      extras: Record<string, unknown>;
    };
  }): void => {
    if (event && event.data.user_identity) {
      window.removeEventListener("message", onAccountRecieve);
      isEventListened.current = false;
      const account = {
        id: Number(event.data.authorization_id),
        user_identity: event.data.user_identity,
        integration_count: 0,
        app_name: "",
        app_icon_url: "",
        extras: event.data.extras || {},
      };
      onAddAccount(account);
    }
  };
  const userRef = useRef(user);
  const addNewAccount = ({
    authorizationType,
    userId = `INTEGRY_TEST_INTEGRATION_CREATOR_${userRef.current?.account_id}`,
  }: {
    userId?: string;
    authorizationType: {
      type_id: string;
      provider: string;
      id: number;
    };
  }): void => {
    const sdkQueryParams =
      workspaceApp && workspaceApp.secret
        ? authParams(userId, workspaceApp.key, workspaceApp.secret)
        : "";
    let url = "";
    if (
      authorizationType &&
      (authorizationType.type_id === "OAuth2" ||
        authorizationType.type_id === "api-key")
    ) {
      url = `${config.microservicesBaseUrl}${authorizationType.provider}/oauth2/splash?${sdkQueryParams}`;
    } else {
      url = `${config.microservicesBaseUrl}auth/login/${authorizationType.id}?${sdkQueryParams}`;
    }
    window.open(url, "Authorization", "height=600,width=1024");
    if (!isEventListened.current) {
      isEventListened.current = true;
      window.addEventListener("message", onAccountRecieve);
    }
  };

  return { addNewAccount };
};

export default useAddAuthorization;
