/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import AppsRequest from "components/bundles-v3/deployments/apps-request";
import { User } from "types/integry-user";
import "./styles.scss";

interface AppRequestSettingsProps {
  user: User;
}

const AppRequestSettings = (props: AppRequestSettingsProps): ReactElement => {
  const { user } = props;
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const bundleId = queryParams.get("bundle_id") || user.default_marketplace;
  const bundleConfig = {
    id: bundleId,
    logo_url: "",
    title: "",
    header_color: "",
    accept_app_requests: "",
    signin_method: "",
  };

  return (
    <div className="app-request-settings-wrap">
      <h2 className="content-heading">App Requests</h2>
      <AppsRequest bundle={bundleConfig} ignoreSignInMethod />
    </div>
  );
};

export default AppRequestSettings;
