import { createApi } from "@reduxjs/toolkit/query/react";
import { integryBaseQuery } from "utils/http";
import { ConnectedAccuount } from "features/templates/interface/authorization";

export const useConnectedAccounts = createApi({
  reducerPath: "conntectedAccounts",
  baseQuery: integryBaseQuery(),
  tagTypes: ["conntectedAccounts"],
  endpoints: (builder) => ({
    getConntectedAccounts: builder.query<
      ConnectedAccuount[],
      {
        authorization_type_id: number;
        app_id: number;
      }
    >({
      query: ({ authorization_type_id, app_id }) => ({
        url: `/connected-accounts/list_connected_accounts/?authorization_type_id=${authorization_type_id}&app_id=${app_id}`,
        method: "GET",
      }),
      providesTags: ["conntectedAccounts"],
    }),
    deleteConnectedAccount: builder.mutation<
      void,
      {
        id: number;
      }
    >({
      query: ({ id }) => ({
        url: `/connected-accounts/${id}`,
        method: "DELETE",
        headers: {
          "skip-404-page": true,
        },
      }),
      invalidatesTags: ["conntectedAccounts"],
    }),
  }),
});

export const {
  useGetConntectedAccountsQuery,
  useDeleteConnectedAccountMutation,
} = useConnectedAccounts;
