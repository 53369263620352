/* eslint-disable jsx-a11y/anchor-is-valid */
import { CSSProperties, Fragment, HTMLAttributes } from "react";
import { Link } from "react-router-dom";
import ArrowRightIcon from "images/arrow_right_icon.svg";
import "./styles.scss";

export interface ICrumb {
  url: string;
  label: JSX.Element | string;
  disable?: boolean;
}

export interface IProps extends HTMLAttributes<HTMLDivElement> {
  crumbs: Array<ICrumb>;
  customStyle?: CSSProperties;
  onNavClick?: (url) => void;
}

const Breadcrumb = ({ customStyle = {}, ...props }: IProps): JSX.Element => {
  const { crumbs = [], onNavClick } = props;
  const handleNavClick = (url: string): void => {
    if (onNavClick) {
      onNavClick(url);
    }
  };
  return (
    <div className="integry-breadcrumb" style={{ ...customStyle }}>
      {crumbs.map((crumb: ICrumb, index) => {
        if (crumb.disable) {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={index}>
              <p className="disabled-link">{crumb.label}</p>
              {index < crumbs.length - 1 && (
                <img src={ArrowRightIcon} alt="arrow-right" />
              )}
            </Fragment>
          );
        }
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            <Link
              onClick={() => {
                if (!crumb.disable) handleNavClick(crumb.url);
              }}
              style={index === crumbs.length - 1 ? { opacity: "1" } : {}}
              aria-hidden="true"
              to={!crumb.disable ? crumb.url : "#"}
            >
              {crumb.label}
            </Link>
            {index < crumbs.length - 1 && (
              <img src={ArrowRightIcon} alt="arrow-right" />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
