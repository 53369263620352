import { ReactElement, useState } from "react";
import ModalWithHeader from "components/integry-design-system/atoms/modals/model-with-header";
import SearchBar from "components/common/search-input-field/searchbar-v3";
import Tag from "components/integry-design-system/atoms/tag";
import StepPayloadCompnent from "components/integry-design-system/organisms/integration-view/step-payload-component";
import {
  useGetPollPagesQuery,
  useGetPollPayloadQuery,
} from "features/integrataion-polls/api/usePollApi";
import useDisableScroll from "hooks/useDisableScroll";
import ListingTable, {
  createColumnHelper,
  ExpandedState,
} from "components/integry-design-system/organisms/ListingTable/ListingTable";
import {
  statusLabels,
  networkCodeColors,
} from "legacy-features/integrations/runs-view/runs-listing";

import { PollPage } from "../../../interface";
import "./styles.scss";

interface IProps {
  onClose: () => void;
  show: boolean;
  pollId: string;
  integrationId: string;
}

const PollPages = (props: IProps): ReactElement => {
  const { show, pollId, onClose, integrationId } = props;

  const [expandedPoll, setExpandedPoll] = useState<ExpandedState>({});
  const [queryArguements, setQueryArguements] = useState<{
    search: string;
    page: number;
  }>({
    search: "",
    page: 1,
  });
  const columnHelper = createColumnHelper<PollPage>();

  useDisableScroll(show);

  const {
    data: pollPages,
    isLoading: loadingPollPages,
    isFetching: fetchingPollPages,
  } = useGetPollPagesQuery({
    itnegration_id: integrationId,
    pollId,
    page: queryArguements.page,
    search: queryArguements.search,
  });

  const showMainLoader =
    loadingPollPages || (fetchingPollPages && queryArguements.page === 1);

  const columns = [
    columnHelper.accessor("execution_sequence_number", {
      header: () => "#",
      cell: (info) => info.row.index + 1,
    }),

    columnHelper.accessor("title", {
      header: () => "Name",
      cell: (info) => (
        <div className="flex align-center gap-10px">
          <img
            src={info.row.original.icon_url}
            alt="appicon"
            width={24}
            height={24}
          />
          <div>{info.row.original.title}</div>
        </div>
      ),
    }),

    columnHelper.accessor("start_time", {
      header: () => "Start Time",
    }),

    columnHelper.accessor("duration", {
      header: () => "Duration",
      cell: (info) => "<1",
    }),

    columnHelper.accessor("status", {
      header: () => "Status",
      cell: (info) => {
        const status = info.getValue();
        const label = statusLabels[status.toLowerCase()] || status;
        return <Tag label={label} type="run" />;
      },
    }),

    columnHelper.accessor("network_code", {
      header: () => "Network Code",
      cell: (info) => (
        <div
          style={{
            color:
              networkCodeColors[
                `${info.getValue()} || "N/A"}`.substring(0, 1)
              ] || "#333333",
          }}
        >
          {info.getValue()}
        </div>
      ),
    }),
  ];

  const ExpandedComponent = ({ row }: { row: PollPage }): ReactElement => {
    const { data: pollPageData, isLoading } = useGetPollPayloadQuery({
      pollId,
      integrationId,
      pageId: row.id,
    });
    const payloadData = {
      parsedUrl: pollPageData?.request.url,
      parsedHeaders: pollPageData?.request.headers,
      parsedRequestTemplate: pollPageData?.request.parsed_request_template,
      requestTemplate: pollPageData?.request.request_template,
      requestHttpVerb:
        pollPageData?.request.request_method?.toLocaleUpperCase(),

      responseHeaders: pollPageData?.response.response_headers,
      parsedResponseTemplate: pollPageData?.response.parsed_response_template,
      remoteResponse: pollPageData?.response.remote_response,
    };
    return (
      <div className="payload-container">
        <StepPayloadCompnent
          payloadData={payloadData}
          activityType="Trigger"
          isLoading={isLoading}
          increaseHeightOnFullScreen
          hideObjectTab
        />
      </div>
    );
  };

  return (
    <ModalWithHeader
      title={`Poll Pages -- Poll ID: ${pollId}`}
      close={onClose}
      show={show}
      modalClassName="poll-pages-modal"
    >
      <div className="content integry-scrollbar-v2">
        <div className="flex gap-10px">
          <SearchBar
            searchQueryProp={queryArguements.search}
            onSearch={(value) => {
              setQueryArguements((prev) => ({
                ...prev,
                search: value,
                page: 1,
              }));
            }}
            placeholder="Search for data in payload..."
          />
        </div>

        <ListingTable<PollPage>
          showHoverOnRow
          hasMore={loadingPollPages ? false : !!pollPages?.has_more}
          data={pollPages?.results || []}
          columns={columns}
          isLoading={showMainLoader}
          fetchMore={() => {
            setQueryArguements((prev) => ({
              ...prev,
              page: prev.page + 1,
            }));
          }}
          onRowClick={(row) => {
            setExpandedPoll((prev) => ({
              [row.id]: !prev[row.id],
            }));
          }}
          expandedState={expandedPoll}
          getRowCanExpand={() => true}
          getRowId={(row) => row.id}
          ExpandedRowComponent={ExpandedComponent}
        />
      </div>
    </ModalWithHeader>
  );
};

export default PollPages;
