import { ReactElement } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/Overlay";

export interface TooltipWrapperProps {
  tooltipText: string | JSX.Element | HTMLElement | undefined;
  tooltipId?: string;
  disabled?: boolean;
  hideToolTip?: boolean;
  toolTipStyles?: Record<string, unknown>;
  placement?: Placement;
  children: ReactElement;
  className?: string;
  triggerManually?: boolean; // Pass this prop if you want to trigger the tooltip manually
  show?: boolean; // Pass this prop if you want to show the tooltip manually
}

const TooltipWrapper = (props: TooltipWrapperProps): ReactElement => {
  const {
    tooltipText,
    children,
    tooltipId,
    disabled,
    hideToolTip,
    toolTipStyles,
    placement = "bottom",
    className = "",
    triggerManually,
    show,
  } = props;
  const overlayProps = triggerManually
    ? {
        placement,
        show,
      }
    : {
        placement,
      };
  return (
    <>
      {disabled || hideToolTip ? (
        <>{children}</>
      ) : (
        <OverlayTrigger
          {...overlayProps}
          overlay={
            <Tooltip
              id={tooltipId || `tooltip`}
              style={{ ...toolTipStyles, fontSize: 11, zIndex: 99 }}
              className={`${className}`}
              key={`${tooltipId}_${tooltipText}`}
            >
              <div>{tooltipText}</div>
            </Tooltip>
          }
        >
          {children}
        </OverlayTrigger>
      )}
    </>
  );
};

TooltipWrapper.defaultProps = {
  tooltipId: "",
  disabled: false,
  hideToolTip: false,
  toolTipStyles: {},
  placement: "bottom",
};

export default TooltipWrapper;
