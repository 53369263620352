import {
  REQUEST_MY_APPS,
  RECEIVE_MY_APPS,
  REQUEST_ALL_APPS,
  RECEIVE_ALL_APPS,
  SET_GLOBALLY_SELECTED_APP,
  SET_GLOBALLY_SELECTED_APP_AUTO,
  REMOVE_ALL_APP,
  REMOVE_MY_APP,
  SET_USER_ID,
} from "../actions/apps";

const publishing_status_enum = {
  PUBLISHED: 1,
  SUBMITTED: 2,
  DRAFT: 3,
};

export const myApps = (state = { isFetching: false, items: [] }, action) => {
  switch (action.type) {
    case REQUEST_MY_APPS:
      return { ...state, isFetching: true };
    case RECEIVE_MY_APPS:
      return {
        ...state,
        isFetching: false,
        items: action.apps.map((app) => ({
          ...app,
          publishing_status_sort_order:
            publishing_status_enum[app.publishing_status],
        })),
      };
    case REMOVE_MY_APP:
      return {
        ...state,
        isFetching: false,
        items: state.items.filter((app) => app.id !== action.appId),
      };
    default:
      return state;
  }
};

export const allApps = (state = { isFetching: false, items: [] }, action) => {
  switch (action.type) {
    case REQUEST_ALL_APPS:
      return { ...state, isFetching: true };
    case RECEIVE_ALL_APPS:
      return { ...state, isFetching: false, items: action.apps };
    case REMOVE_ALL_APP:
      return {
        ...state,
        isFetching: false,
        items: state.items.filter((app) => app.id !== action.appId),
      };
    default:
      return state;
  }
};

export const globallySelectedApp = (state = "", action) => {
  switch (action.type) {
    case SET_GLOBALLY_SELECTED_APP:
      return action.appId;
    case SET_GLOBALLY_SELECTED_APP_AUTO:
      return state || action.appId;
    default:
      return state;
  }
};

export const globalUserId = (state = "", action) => {
  switch (action.type) {
    case SET_USER_ID:
      return action.userId;
    default:
      return state;
  }
};
