import React, { ReactElement } from "react";
import { Field } from "formik";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import "./radio-group.scss";

const renderField = (props, option) => {
  return (fieldProps) => {
    const { onChange, onBlur, name, className, options, id, ...rest } = props;
    const { form, field } = fieldProps;
    const { value: selectedValue } = field;
    const { setFieldValue, setFieldTouched } = form;
    const { value, label, disabled = false } = option;

    return (
      <input
        {...rest}
        onChange={(e) => {
          setFieldValue(name, value);
          if (onChange) {
            onChange(e.target.value, e);
          }
        }}
        onBlur={(e) => {
          setFieldTouched(name);
          if (onBlur) {
            onBlur(e);
          }
        }}
        disabled={disabled}
        className={`${className}`}
        type="radio"
        value={value}
        name={name}
        id={`${id}-${label}`}
        tabIndex={-1}
        checked={
          typeof selectedValue === "boolean"
            ? `${selectedValue}` === value
            : selectedValue === value
        }
      />
    );
  };
};

const RadioButton = (option, props, validate): ReactElement => {
  const { name } = props;
  return (
    <Field name={name} validate={validate}>
      {renderField(props, option)}
    </Field>
  );
};

export interface RadioOptions {
  label: string;
  value: string | boolean;
  tooltip?: string;
  validate?(value): string;
  errors?: string[];
  isLoading?: boolean;
  description?: string;
  subRender?: ReactElement;
  descriptionStyle?: React.CSSProperties;
  hideValidation?: boolean;
  disabled?: boolean;
}

export interface RadioGroupProps extends React.HTMLProps<HTMLInputElement> {
  // eslint-disable-next-line react/no-unused-prop-types
  name: string;
  options: RadioOptions[];
  id: string;
  validate?(value): string;
  errors?: string[];
  isLoading?: boolean;
  parentClassName?: string;
  hideValidation?: boolean;
  onChange?(value): void;
}

const RadioGroup = (props: RadioGroupProps): ReactElement => {
  const {
    options,
    id,
    validate,
    name,
    errors,
    isLoading,
    parentClassName = "",
    hideValidation = false,
  } = props;

  return (
    <div
      role="group"
      aria-labelledby="my-radio-group"
      className={`${parentClassName}`}
    >
      {isLoading && <span className="radio-check-info">Loading ...</span>}
      {options?.length === 0 && !isLoading && (
        <span className="radio-check-info">No Data Found</span>
      )}
      {options.map((option) => (
        <>
          <div
            className={`radio-option-continer ${
              option.disabled ? "disabled" : ""
            }`}
          >
            <>
              <div className="radio-option">
                {RadioButton(option, props, validate)}
                <IntegryTooltip
                  tooltipText={option.tooltip}
                  disabled={!option.tooltip}
                >
                  <label
                    htmlFor={`${id}-${option.label}`}
                    style={{ height: "auto" }}
                  >
                    {option.label}
                  </label>
                </IntegryTooltip>
                <div className="check" />
              </div>
              {option.description && (
                <p
                  className="form-text description-text"
                  style={option.descriptionStyle || {}}
                >
                  {option.description}
                </p>
              )}
            </>
          </div>
          {option.value === props.value && option.subRender ? (
            <div className="radio-option--sub-render">{option.subRender}</div>
          ) : null}
        </>
      ))}
      {!hideValidation && (
        <span className="validation-check">{errors?.[name]}</span>
      )}
    </div>
  );
};

export default RadioGroup;
