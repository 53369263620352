import React, { Fragment, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Form, FormGroup, Label } from "reactstrap";
import orderBy from "lodash/orderBy";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import { setGloballySeletedApp } from "../../store/actions/apps";
import { getVisibleMyApps } from "../../selectors/apps";
import CustomHeaderDropdown from "./custom-header-dropdown";
import "./global-app-form-v3.scss";

const GlobalAppFormPlain = ({
  apps,
  setGloballySeletedApp,
  globallySelectedApp,
  disabled = false,
  hideWorkspaceDropdown,
}) => {
  const [selectedApp, setSelectedApp] = useState({});
  const [options, setOptions] = useState([]);
  const [tooltip, setTooltip] = useState(false);

  useEffect(() => {
    setSelectedApp(
      apps?.items?.filter?.((app) => app.id == globallySelectedApp)?.[0]
    );
  }, [globallySelectedApp]);

  useEffect(() => {
    const options = [];
    const filteredOptions = orderBy(
      apps?.items?.filter((item) => item.is_workspace),
      "name",
      "asc"
    );
    filteredOptions?.forEach(
      (option, index) =>
        option?.name &&
        options?.push({
          name: option?.name,
          id: option?.id,
          icon_url: option?.icon_url,
        })
    );
    setOptions(options);
  }, [apps]);

  return (
    <>
      <Form inline className="global-app">
        {!hideWorkspaceDropdown &&
          options.length > 1 && ( // if account has only one workspace hide workspace dropdown
            <FormGroup>
              <IntegryTooltip
                hideToolTip={selectedApp?.name?.length <= 15}
                tooltipText={selectedApp?.name}
              >
                {selectedApp?.icon_url ? (
                  <img
                    className="global-app_Img"
                    loading="lazy"
                    src={selectedApp?.icon_url}
                    alt="icon"
                  />
                ) : (
                  <div className="global-app_Img" />
                )}
              </IntegryTooltip>
              <div className="global-app_Select" id="gloabalappselect">
                <CustomHeaderDropdown
                  options={options}
                  value={selectedApp}
                  onChange={setGloballySeletedApp}
                  setTooltip={setTooltip}
                  loading={disabled}
                />
              </div>
            </FormGroup>
          )}
      </Form>
      {!hideWorkspaceDropdown && options.length > 1 && (
        <div className="global-app-partition" />
      )}
    </>
  );
};

const GlobalAppForm = connect(
  (state) => ({
    apps: {
      isFetching: state.myApps.isFetching,
      items: getVisibleMyApps(state.myApps),
    },
    globallySelectedApp: state.globallySelectedApp,
  }),
  (dispatch) => ({
    setGloballySeletedApp(value) {
      dispatch(setGloballySeletedApp(value?.id));
    },
  })
)(GlobalAppFormPlain);

export default GlobalAppForm;
