import { ReactElement } from "react";
import { createColumnHelper, ExpandedState } from "@tanstack/react-table";

import IntegryTable, {
  IntegryTableProps,
} from "components/integry-design-system/atoms/integry-table";

import "./ListingTable.scss";

interface ListingTableProps<T> extends IntegryTableProps<T> {
  showHoverOnRow?: boolean;
}

function ListingTable<T>(props: ListingTableProps<T>): ReactElement {
  const { showHoverOnRow, ...integryTableProps } = props;
  return (
    <div
      className={`integry-common-listing-table ${
        showHoverOnRow ? "enable-row-hover" : ""
      }`}
    >
      <IntegryTable<T> {...integryTableProps} />
    </div>
  );
}

export default ListingTable;

export { createColumnHelper };
export type { ExpandedState, ListingTableProps };
