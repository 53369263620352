import React from "react";
import { Field } from "react-form";
import { FormFeedback } from "reactstrap";
import ReactTagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

const TagsInput = React.forwardRef((props, forwardedRef) => (
  <Field ref={forwardedRef} field={props.field}>
    {(fieldApi) => {
      const { onChange, onBlur, field, className, ...rest } = props;
      const { value, error, touched, setValue } = fieldApi;
      const validationClass = touched && error ? " is-invalid" : "";

      return (
        <div>
          <ReactTagsInput
            className={`h-auto react-tagsinput form-control${validationClass} ${className}`}
            value={value ? value.split(", ") : []}
            onChange={(tags) => {
              const tagsString = tags.join(", ");
              setValue(tagsString);
              if (onChange) {
                onChange(tagsString);
              }
            }}
            addKeys={[13, 9, 188, 32]}
            inputProps={{
              ...rest,
            }}
            ref="tags"
          />
          {error && <FormFeedback>{error}</FormFeedback>}
        </div>
      );
    }}
  </Field>
));

export default TagsInput;
