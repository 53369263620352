import React, { memo, ReactElement } from "react";
import { uniqueId } from "lodash-es";
import resolveObjectPath from "lodash/get";

import {
  EditorState,
  SelectionState,
  Modifier,
  DraftDecoratorComponentProps,
} from "draft-js";

import { getMachineNameFromTag } from "features/templates/utils";
import { getNodeByMachineName } from "utils/tree";

import IntegryTooltip from "components/integry-design-system/molecules/integry-tooltip/integry-tooltip-v2";
import { useStepTagsContext } from "features/templates/context/StepsTagsContext";

import { useFormikContext } from "formik";
import {
  Template,
  Step,
} from "features/templates/template-builder-v7/services/template-builder-interface";

import storageIcon from "images/intg_view_storage.svg";
import AuthTagIcon from "images/auth-tag-icon.svg";

import config from "utils/config";

import { useEditorState } from "./EditorStateContext";

import "./TagEditor.scss";

const Pill = (props: DraftDecoratorComponentProps): ReactElement => {
  const { children, decoratedText, start, end, contentState, blockKey } = props;
  const pillRef = React.useRef<HTMLSpanElement>(null);

  let step: Step | undefined;
  let pillIcon = "";
  let pillType = "";

  const { values } = useFormikContext<Template>();
  const { tagDictionary } = useStepTagsContext();
  const tagValue = decoratedText.slice(2, -2);

  const isValidTag = resolveObjectPath(tagDictionary, tagValue) !== undefined;

  const { editorState, setEditorState } = useEditorState();

  const handleClick = (e): void => {
    e.preventDefault();
    e.stopPropagation();
    if (pillRef.current) {
      updatePillText();
    }
  };
  if (tagValue.startsWith("steps.")) {
    const stepMachineName = getMachineNameFromTag(tagValue);
    step = getNodeByMachineName(values, stepMachineName, true);
    if (step) {
      pillIcon = step.app?.icon_url || "";
      pillType = step.is_form_step ? "Setup Form: " : "Step: ";
    }
  } else if (tagValue.startsWith("storage.user")) {
    pillIcon = storageIcon;
    pillType = "User variable: ";
  } else if (tagValue.startsWith("storage.workspace")) {
    pillIcon = storageIcon;
    pillType = "Workspace variable: ";
  } else if (tagValue.startsWith("storage")) {
    pillIcon = storageIcon;
    pillType = "Storage: ";
  } else if (tagValue.startsWith("authorization")) {
    pillIcon = AuthTagIcon;
    pillType = "Authorization: ";
  } else if (tagValue.startsWith("parameters")) {
    pillIcon = config.parameters.icon;
    pillType = "Parameter: ";
  }

  const updatePillText = (): void => {
    // const blockKey = getBlockKeyFromOffsetKey(offsetKey);

    // Create a selection that spans the pill text
    const selection = SelectionState.createEmpty(blockKey).merge({
      anchorOffset: start,
      focusOffset: end,
    }) as SelectionState;

    // Replace the pill text with the new value (enclosed in braces)
    const tag = decoratedText.slice(1, -1);
    const newContentState = Modifier.replaceText(
      contentState,
      selection.merge({
        anchorOffset: start,
        focusOffset: end,
      }) as SelectionState,
      tag
    );

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-characters"
    );

    setEditorState(
      EditorState.forceSelection(
        newEditorState,
        newContentState.getSelectionAfter()
      ),
      "Pill"
    );
  };

  const tagFieldName = tagValue.split(".").pop(); // Get the last element of the array
  const tooltipText = (
    <div
      style={{
        fontSize: "11px",
        padding: "5px",
        textAlign: "center",
      }}
    >
      {isValidTag
        ? decoratedText.slice(1, -1)
        : "Tag not found. It may not resolve correctly at run-time."}{" "}
      &mdash; Click to edit
    </div>
  );
  return (
    <IntegryTooltip tooltipContent={tooltipText} tooltipId={uniqueId(tagValue)}>
      <span
        className={`pill ${isValidTag ? "" : "invalid"}`}
        onClick={handleClick}
        ref={pillRef}
        contentEditable={false}
      >
        {isValidTag ? (
          <>
            {pillIcon && (
              <span className="pill-icon" contentEditable={false}>
                <img src={pillIcon} alt="tag" />
              </span>
            )}
            <span contentEditable={false} className="pill-value">
              {`${pillType}${tagFieldName || tagValue}`}
            </span>
          </>
        ) : (
          <>
            <span contentEditable={false} className="pill-value">
              {tagValue}
            </span>
            <svg width="8" height="8">
              <circle cx="4" cy="4" r="4" fill="rgb(240, 92, 66)" />
            </svg>
          </>
        )}
        <span
          contentEditable={false}
          style={{
            display: "none",
          }}
        >
          {children}
        </span>
      </span>
    </IntegryTooltip>
  );
};

export default memo(Pill);
