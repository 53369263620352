import { useEffect, useRef, useState } from "react";
import { FormFeedback } from "reactstrap";
import { Field } from "formik";
import curry from "lodash/curry";

import TooltipWrapper from "components/common/tooltip-wrapper";
import dropdownIcon from "../../../images/step-collapse-icon.svg";
import dotLoader from "../../../images/three-dot-loader.gif";
import SelectFieldOptionPill from "./select-field-option-pill";
import SelectFieldDropOptions from "./select-field-drop-options";
import FieldMapping from "./field-mapping";
import { flattenWithPath } from "../../../utils/tree";

const SelectFieldDropDown = (props) => {
  const [selectDropdown, setSelectDropdown] = useState(false);
  const [dropDownOptions, setDropdownOptions] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [focusClass, setFocusClass] = useState("");
  const [mounted, setMounted] = useState(false);
  const [autoFillFlag, setAutoFillFlag] = useState(false);

  const {
    options,
    txtIcon,
    onChange,
    onTagRemove,
    isLoading,
    errorField,
    hidePillDescription = false,
    testSectionFields,
  } = props;

  const formikField = useRef(null);
  const inputRef = useRef(null);
  const nodeRef = useRef();

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClickOutside);
    setMounted(true);
    setOptionsLoading(true);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDropdownOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const getDropdownOptions = () => {
    const { slideMenuField, isFieldList } = props;
    setDropdownOptions(options);
    if (options) {
      setOptionsLoading(false);
      if (
        isFieldList &&
        options.length === 1 &&
        !autoFillFlag &&
        !slideMenuField
      ) {
        const { setFieldValue } = formikField.current?.form;
        const { name } = props;
        if (!formikField.current.field.value) {
          // If step is alrady selected or user entered a tag mannually then we dont auto-select
          addOption(setFieldValue, name, options[0]);
        }
        // autofill should initiate only once
        setAutoFillFlag(true);
      }
    }
  };

  const toggleSelectDropdown = (value) => {
    setSelectDropdown(value);
  };

  const getSelectedOption = () => {
    if (mounted) {
      const { value } = formikField.current.field;
      if (value && dropDownOptions) {
        let option = dropDownOptions.find((opt) => {
          return opt.id === value || opt.value === value;
        });
        if (option) {
          setSelectedOption(option);
          setMounted(false);
        }
      }
    }
    return selectedOption;
  };

  const removeTag = (name, setFieldValue) => {
    setFieldValue(name, "");
    setSelectedOption(null);
    if (onTagRemove) {
      onTagRemove();
    }
  };

  const addOption = (setFieldValue, name, option) => {
    setFieldValue(name, option.id || option.value);
    setSelectedOption(option);
    toggleSelectDropdown(false);
    if (onChange) {
      onChange(option);
    }
  };

  const getFilteredOptions = () => {
    if (!selectedOption && searchString) {
      let titleKey = "";
      if (dropDownOptions && dropDownOptions.length)
        titleKey = dropDownOptions[0].title ? "title" : "text";
      return dropDownOptions.filter((opt) => {
        return opt[titleKey].toLowerCase().includes(searchString);
      });
    }
    return dropDownOptions;
  };

  const handleClickOutside = (evt) => {
    if (!nodeRef?.current?.contains(evt.target)) {
      // outside click
      toggleSelectDropdown(false);
      return;
    }
  };

  let flattenTree = [];
  const { selectedDataTree } = props;
  if (
    selectedDataTree &&
    selectedDataTree.completeDataTree &&
    selectedDataTree.completeDataTree.steps
  ) {
    flattenTree = flattenWithPath(
      selectedDataTree.completeDataTree.steps[0],
      "steps",
      []
    );
  }

  const fieldProps = () => {
    return (fieldProps) => {
      formikField.current = fieldProps;
      const {
        onChange,
        onBlur,
        name,
        className,
        fieldObject,
        formikSteps,
        nestedFieldPath,
        parentFieldName,
        isFieldList,
        options,
        isDisabled = false,
        ...rest
      } = props;

      const { field, form, meta } = fieldProps;
      const { touched, error } = meta;
      const isInvalid = touched && error && !selectedOption;
      const validationClass = errorField || isInvalid ? " is-invalid" : "";
      const { value } = field;
      const { setFieldValue, setFieldTouched } = form;

      const inputField = (
        <input
          autoComplete="off"
          {...rest}
          value={value || ""}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
            setSearchString(e.target.value);
            if (onChange) {
              onChange(e.target.value, e);
            }
          }}
          onBlur={(e) => {
            setFieldTouched(name);
            setFocusClass("");
            if (onBlur) {
              onBlur(e);
            }
          }}
          className={`form-control ${className}`}
          ref={inputRef}
          onClick={(e) => {
            setFocusClass("focused");
          }}
        />
      );
      return (
        <TooltipWrapper
          tooltipText="Mapping is being used from another step and cannot be modified here."
          tooltipId="tag-tooltip"
          hideToolTip={!isDisabled} // show tooltip when dropdown are disbaled,
        >
          <div
            ref={nodeRef}
            className={
              optionsLoading || isLoading || isDisabled
                ? "select-field-container disabled"
                : "select-field-container"
            }
          >
            <div className={`select-field ${focusClass}`}>
              <div
                className={`input-div${validationClass}${
                  isFieldList ? " field-list-input" : ""
                }`}
                onClick={(e) => {
                  if (!optionsLoading || isLoading) {
                    toggleSelectDropdown(!selectDropdown);
                  }
                }}
              >
                {!getSelectedOption() ? (
                  inputField
                ) : optionsLoading || isLoading ? (
                  <span className="placeholder">Select a field</span>
                ) : (
                  ""
                )}
                {getSelectedOption() &&
                  (dropDownOptions.length > 0 ? (
                    <SelectFieldOptionPill
                      value={value}
                      options={dropDownOptions}
                      isFieldListPill={isFieldList}
                      removeTag={(e) => {
                        e.stopPropagation();
                        removeTag(name, setFieldValue);
                      }}
                      txtIcon={txtIcon}
                      hidePillDescription={hidePillDescription}
                    ></SelectFieldOptionPill>
                  ) : (
                    !optionsLoading ||
                    (isLoading && (
                      <span className="placeholder">Select a field</span>
                    ))
                  ))}
                <img
                  src={optionsLoading || isLoading ? dotLoader : dropdownIcon}
                  alt="drop-icon"
                  className="drop-icon"
                />
              </div>
            </div>

            <SelectFieldDropOptions
              show={selectDropdown}
              options={getFilteredOptions()}
              setSelectedOption={curry(addOption, 3)(setFieldValue)(name)}
              txtIcon={txtIcon}
              isFieldList={isFieldList}
              parentFieldName={parentFieldName}
              hidePillDescription={hidePillDescription}
            ></SelectFieldDropOptions>
            {isFieldList && (
              <FieldMapping
                formikSteps={formikSteps}
                path={props.path}
                tag={
                  !testSectionFields
                    ? fieldObject.default_value
                    : fieldObject.test_value
                }
                nestedFieldPath={nestedFieldPath}
                fieldObject={fieldObject}
                setFieldValue={setFieldValue}
                txtIcon={txtIcon}
                flattenTree={flattenTree}
                parentFieldName={parentFieldName}
              ></FieldMapping>
            )}
            {isInvalid && (
              <FormFeedback style={{ display: error ? "block" : "none" }}>
                {error}
              </FormFeedback>
            )}
          </div>
        </TooltipWrapper>
      );
    };
  };

  return (
    <Field
      name={props.name}
      validate={props.validate ? props.validate : () => {}}
      validateOnChange={props.validateOnChange}
    >
      {fieldProps()}
    </Field>
  );
};

export default SelectFieldDropDown;
