type QueryParams = { [key: string]: number | string | string[] };

export const getQueryParamAsObject = (url?: string): QueryParams => {
  const currentUrl = url || window.location.href;
  const urlObject = new URL(currentUrl);
  const params = new URLSearchParams(urlObject.search);
  const queryParams: QueryParams = {};

  params.forEach((value, key) => {
    if (value.includes(",")) {
      queryParams[key] = value.split(",");
    } else {
      queryParams[key] = value;
    }
  });
  return queryParams;
};

export const updatedQueryParams = ({
  queryParams,
  replaceURL = false,
}: {
  queryParams: QueryParams;
  replaceURL?: boolean;
}): string => {
  const currentParams = new URLSearchParams(window.location.search);
  Object.keys(queryParams).forEach((key) => {
    const value = queryParams[key];
    if (currentParams.has(key)) {
      currentParams.delete(key);
    }
    if (Array.isArray(value)) {
      currentParams.set(key, value.join(","));
    } else if (value) {
      currentParams.set(key, value.toString());
    }
  });

  if (replaceURL) {
    window.history.replaceState(
      null,
      "",
      `${window.location.origin}${
        window.location.pathname
      }?${currentParams.toString()}`
    );
  }

  return `${window.location.origin}${
    window.location.pathname
  }?${currentParams.toString()}`;
};
