import React from "react";
import { Route, Switch } from "react-router-dom";
import { FeatureFlag } from "react-launch-darkly";

import SettingList from "./setting-list";
import AppSettings from "components/turbo.v2/app-settings";
import NoMatchFound from "../../components/no-match-found";

const Settings = ({ match, user }) => {
  return (
    <Switch>
      <Route
        path={match.url + "/app/*"}
        exact
        render={(routeProps) => (
          <FeatureFlag
            flagKey="allow-app-settings"
            renderDefaultCallback={() => <Route component={NoMatchFound} />}
            renderFeatureCallback={() => (
              <AppSettings {...routeProps} user={user} />
            )}
          />
        )}
      />
      <Route
        path={match.url + "/"}
        render={(routeProps) => <SettingList {...routeProps} user={user} />}
      />
    </Switch>
  );
};

export default Settings;
