/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import moment from "moment/moment";
import { convertDateToLocal } from "./functions";

export const isDateLessThanTargetDate = ({
  targetDate = new Date(),
  sourceDate = new Date(),
}) => sourceDate < targetDate;

export const isDateGreaterThanTargetDate = ({
  targetDate = new Date(),
  sourceDate = new Date(),
}) => !isDateLessThanTargetDate({ targetDate, sourceDate });

export const getStartOfUtcDay = (dateString, dateFormat = "DD/MM/YYYY") => {
  // Parse the date assuming it's in DD/MM/YY format
  const momentDate = moment(dateString, dateFormat);
  const parts = dateString.split("/");
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Month is 0-based in JavaScript
  const year = momentDate.year(); // Adjust year for 'YY' format

  // Create a date object for the start of the day in local time
  const startDate = new Date(year, month, day);
  const startUTC = new Date(
    Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
  );
  return startUTC;
};

export const getEndOfUtcDay = (dateString, dateFormat = "DD/MM/YYYY") => {
  // Parse the date assuming it's in DD/MM/YY format
  const parts = dateString.split("/");
  const momentDate = moment(dateString, dateFormat);
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Month is 0-based in JavaScript
  const year = momentDate.year(); // Adjust year for 'YY' format

  // Create a date object for the end of the day in local time
  const endDate = new Date(year, month, day, 23, 59, 59, 999);
  const endUTC = new Date(
    Date.UTC(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      23,
      59,
      59,
      999
    )
  );

  return endUTC;
};

export const getStartOfLocalDay = (date: Date) => {
  const day = date.getUTCDate();
  const month = date.getUTCMonth();
  const year = date.getUTCFullYear();

  // Create a date object for the start of the day in local time
  const startDate = new Date(year, month, day);
  return startDate;
};

export const getEndOfLocalDay = (date: Date) => {
  const day = date.getUTCDate();
  const month = date.getUTCMonth();
  const year = date.getUTCFullYear();

  // Create a date object for the end of the day in local time
  const endDate = new Date(year, month, day, 23, 59, 59, 999);
  return endDate;
};

export const convertDateToUTCString = (
  date: string | number,
  showYear = false
) => {
  try {
    if (date && moment(date).isValid()) {
      const isUnix = typeof date !== "string";
      const dateMoment = isUnix ? moment.unix(date) : moment(date);
      const day = dateMoment.utc().date();
      const month = dateMoment.utc().month();
      const year = dateMoment.utc().year();
      const hours = dateMoment.utc().hours();
      const minutes = dateMoment.utc().minutes();
      const seconds = dateMoment.utc().seconds();
      const UTCDate = new Date(
        year,
        month,
        day,
        hours,
        minutes,
        seconds
      ).toISOString();
      return `${convertDateToLocal(UTCDate, showYear)} UTC`;
    }
  } catch (e) {
    console.error("Error converting date to UTC string", date);
    console.error(e);
  }

  return "N/A";
};
