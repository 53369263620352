import { useState, useEffect, ReactElement, useRef } from "react";
import { Formik, Form, FormikProps } from "formik";
import { FeatureFlag } from "react-launch-darkly";
import Button from "components/common/reusable-button";
import TextField from "components/forms/v4/text-field";
import TagsInput from "components/forms/v3/tags-input";
import { toast } from "react-toastify";

import { useFetchUserQuery } from "utils/user/user-api";

import { validateCommaSeparatedEmail } from "utils/functions";
import {
  sendInvite,
  listInvites,
} from "features/account-settings/services/multiplayer-apis";
import { inviteInterface } from "features/account-settings/services/interface";

import TeamMember from "./team-member";
import "./team-management.scss";

interface EmailFormType {
  email: string;
}

interface UsersInterface {
  isAdminUser: boolean;
  multipleInvites?: boolean;
}
const TeamManagement = (props: UsersInterface): ReactElement => {
  const [isLoading, setIsloading] = useState(false);
  const [fetchingInvites, setFetchingInvites] = useState(false);
  const [invites, setInvites] = useState<inviteInterface[]>([]);
  const { isAdminUser, multipleInvites } = props;

  const { data: loggedinUser } = useFetchUserQuery();

  const formikRef = useRef<
    FormikProps<{
      email: string;
    }>
  >();

  useEffect(() => {
    fetchInviteList();
  }, []);

  const fetchInviteList = (): void => {
    setFetchingInvites(true);
    listInvites()
      .then((res) => {
        const invite = res.data;
        setInvites(invite);
        setFetchingInvites(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onsubmit = (values: EmailFormType, resetForm: () => void): void => {
    const { email } = values;
    setIsloading(true);
    inviteTeamMember(email.replaceAll(", ", ","));
  };

  const inviteTeamMember = async (email: string): Promise<void> => {
    return sendInvite(email)
      .then((res) => {
        const invitesSent: inviteInterface[] = res.data;
        const newInvites = invitesSent.filter(
          (invite) => !invites.some((invt) => invt.id === invite.id)
        );
        setInvites((prevState) => [...newInvites, ...prevState]);
        setIsloading(false);
        formikRef.current?.resetForm();
        if (multipleInvites) {
          toast.success(
            <p className="toast-text">
              An invitation has been sent to all entered email IDs to join{" "}
              {loggedinUser?.account_name}
            </p>
          );
        } else {
          toast.success(
            <p className="toast-text">
              An invitation has been sent to {email} to join{" "}
              {loggedinUser?.account_name}
            </p>
          );
        }
      })
      .catch((error) => {
        setIsloading(false);
        const status = error?.response?.status;
        const errorData = error?.response?.data || {};
        if (status === 400) {
          const { error: errorMessage } = errorData;
          formikRef.current?.setErrors({
            email: errorMessage,
          });
        } else {
          toast.error(
            <p className="toast-text">
              Error occurred please try again, Cannot Invite
            </p>
          );
        }
      });
  };

  const removeInviteFromList = (id: number): void => {
    setInvites((prevState) => prevState.filter((invite) => invite.id !== id));
  };

  return (
    <Formik
      initialValues={{ email: "" }}
      onSubmit={(values, { resetForm }) => onsubmit(values, resetForm)}
      validate={(values) => {
        const error = validateCommaSeparatedEmail(
          values.email.replaceAll(", ", ",")
        );
        if (error) {
          return { email: error };
        }
        return {};
      }}
    >
      {(formik) => {
        formikRef.current = formik;
        return (
          <>
            <div className="account-setting-wrapper team-management-wrapper">
              <h2 className="page-heading">Team Management</h2>
              <div className="account-settings-form">
                <h3 className="main-title">Invite People</h3>
                <p className="description">
                  Super integrations start with a super team!
                </p>
                <div className="grid-auto-column gap-10px">
                  <Form>
                    <div>
                      {multipleInvites ? (
                        <TagsInput
                          name="email"
                          id="email"
                          type="url"
                          aria-describedby="tagsHelp"
                          className="form-control-lg react-tagsinput-v2"
                          placeholder="Enter email address"
                          errorPlaceHolder
                        />
                      ) : (
                        <TextField
                          placeholder="Enter email address"
                          name="email"
                          className="integry-text-field"
                          validate={validateCommaSeparatedEmail}
                          errorPlaceHolder
                        />
                      )}
                      <Button
                        title="Send Invite"
                        onClick={() => ({})}
                        className="btn btn-royal-blue send-invite-btn"
                        type="submit"
                        isLoading={isLoading}
                        disabled={
                          !formik?.values?.email ||
                          !!formik?.errors?.email ||
                          isLoading
                        }
                      />
                    </div>
                  </Form>
                </div>
              </div>

              <div className="account-settings-form">
                {invites.length > 0 && (
                  <>
                    <h3 className="main-title">My Team</h3>
                    <div>
                      <div className="team-row heading-row">
                        <div className="team-col-1 description">Name</div>
                        <div className="team-col-2 description">Email</div>
                        <div className="team-col-3 description">Actions</div>
                      </div>

                      {invites.map((invite) => (
                        <TeamMember
                          {...invite}
                          isAdminUser={isAdminUser}
                          removeInviteFromList={removeInviteFromList}
                          inviteTeamMember={inviteTeamMember}
                        />
                      ))}
                    </div>
                  </>
                )}
                {fetchingInvites && (
                  <div className="integry-spinner-sm ml-mr-auto" />
                )}
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

const FlaggedTeamManagement = (props: UsersInterface): ReactElement => (
  <FeatureFlag
    flagKey="send-multiple-invites"
    renderFeatureCallback={() => <TeamManagement {...props} multipleInvites />}
    renderDefaultCallback={() => <TeamManagement {...props} />}
  />
);

export default FlaggedTeamManagement;
