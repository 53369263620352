import { ReactElement } from "react";
import Button from "components/integry-design-system/molecules/button";

import "./styles.scss";

interface IProps {
  tabs: { title: string; id: string }[];
  disbledSaveButton?: boolean;
  selectedTab: string;
  isLoading: boolean;
  title: string;
  showFooter?: boolean;
  onTabSwitch: (tab: string) => void;
  renderSelectedTab: () => JSX.Element;
  onCancel: () => void;
  onSave: () => void;
}

const SettingWrapper = (props: IProps): ReactElement => {
  const {
    tabs,
    disbledSaveButton,
    selectedTab,
    isLoading,
    title = "Settings",
    showFooter = true,
    renderSelectedTab,
    onTabSwitch,
    onCancel,
    onSave,
  } = props;

  return (
    <div className="integration-settings-container">
      <div className="content-wrapper">
        <div className="tab-panel">
          {title && <p className="settings-title">{title}</p>}
          <div className="tab-list">
            {tabs.map((tab) => {
              return (
                <div
                  key={tab.id}
                  className={`tab-item ${
                    selectedTab === tab.id ? "active" : ""
                  }`}
                  onClick={() => {
                    onTabSwitch(tab.id);
                  }}
                  tabIndex={0}
                  role="button"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      onTabSwitch(tab.id);
                    }
                  }}
                >
                  <span>{tab.title} </span>
                </div>
              );
            })}
          </div>
        </div>

        <div className="settings-content">
          <>{renderSelectedTab()}</>
          {showFooter && (
            <div className="footer">
              <Button variation="cancel" action={onCancel}>
                Cancel
              </Button>
              <Button
                isLoading={isLoading}
                action={onSave}
                disabled={disbledSaveButton}
              >
                Save
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingWrapper;
