// Create  context to save the template type it will retun the template type and setTemplateType function

import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { TemplateType } from "../interface/templateType";

type TemplateTypeContextType = {
  templateType: TemplateType;
  isFunction: boolean;
};

const TemplateTypeContext = createContext<TemplateTypeContextType | undefined>(
  undefined
);

// Define props for the provider
interface TemplateTypeContextProviderProps {
  children: ReactElement;
  initialTemplateType?: TemplateType;
}

export const useTemplateTypeContext = (): TemplateTypeContextType => {
  const context = useContext(TemplateTypeContext);
  if (!context) {
    throw new Error(
      "useTemplateTypeContext must be used within a TemplateTypeContextProvider"
    );
  }
  return context;
};

export const TemplateTypeContextProvider = ({
  children,
  initialTemplateType = TemplateType.STANDARD,
}: TemplateTypeContextProviderProps): ReactElement => {
  const [templateType] = useState<TemplateType>(
    initialTemplateType || TemplateType.STANDARD
  );
  const [isFunction, setIsFunction] = useState(false);

  useEffect(() => {
    setIsFunction(templateType === TemplateType.FUNCTION);
  }, [templateType]);

  return (
    <TemplateTypeContext.Provider value={{ templateType, isFunction }}>
      {children}
    </TemplateTypeContext.Provider>
  );
};

export default TemplateTypeContext;
