/* eslint-disable no-shadow */
import React, {
  ChangeEvent,
  ReactElement,
  useState,
  CSSProperties,
  useEffect,
} from "react";
import "./styles.scss";
import dateTimePickerIcon from "images/date-time-picker-icon.svg";
import datePickerArrowIcon from "images/date-picker-arrow-icon.svg";

export enum dateTimeTypeEnum {
  TIME = "time",
  DATE = "date",
  DATETIME = "datetime-local",
}

export interface DateTimePickerProps {
  className?: string;
  defaultValue?: string;
  type?: dateTimeTypeEnum;
  placeholder: string;
  onDateTimeSelect: (value: string) => void;
  customStyle?: CSSProperties;
  min?: string;
  max?: string;
}

const DateTimePicker = (props: DateTimePickerProps): ReactElement => {
  const {
    className,
    defaultValue,
    onDateTimeSelect,
    placeholder,
    type = "datetime-local",
    customStyle = {},
    max = "",
    min = "",
  } = props;
  const [value, setValue] = useState<string>(defaultValue || "");

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
    onDateTimeSelect(e.target.value);
  };

  const getFormattedValue = (): string => {
    if (value) {
      const dateObj = new Date(value);
      if (type === dateTimeTypeEnum.DATETIME) {
        return dateObj.toLocaleString("en-US", {
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      }
      if (type === dateTimeTypeEnum.DATE) {
        return dateObj.toLocaleString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
      }
      if (type === dateTimeTypeEnum.TIME) {
        const timeString12hr = new Date(
          `1970-01-01T${value}Z`
        ).toLocaleTimeString("en-US", {
          timeZone: "UTC",
          hour12: true,
          hour: "numeric",
          minute: "numeric",
        });
        return timeString12hr;
      }
    }
    return "";
  };
  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    } else if (defaultValue === "") {
      setValue("");
    }
  }, [defaultValue]);
  return (
    <div
      className={`integry-datetime-picker ${className || ""}`}
      style={customStyle}
    >
      <span className="date-time-display">
        {value ? (
          <span>{getFormattedValue()}</span>
        ) : (
          <span className="date-time-placeholder">{placeholder} </span>
        )}

        <img
          className="date-time-picker-icon"
          src={dateTimePickerIcon}
          alt="date-time-picker-icon"
        />
        <img
          className="date-picker-arrow-icon"
          src={datePickerArrowIcon}
          alt="date-picker-arrow-icon"
        />
      </span>
      <input
        type={type}
        value={value}
        min={min}
        max={max}
        className="date-time-picker"
        onChange={handleOnChange}
      />
    </div>
  );
};

export default DateTimePicker;
