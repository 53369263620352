// WorkspaceContext.ts
import React, { createContext, useContext, useEffect, useState } from "react";
import { DefaultWorkspaceApp } from "types/integry-user";
import { useStore } from "react-redux";

import { useFetchUserQuery } from "utils/user/user-api";

interface WorkspaceContextValue {
  workspaceApp: DefaultWorkspaceApp | null;
}

const WorkspaceContext = createContext<WorkspaceContextValue | undefined>(
  undefined
);

const useWorkspaceAppContext = (): WorkspaceContextValue => {
  const context = useContext(WorkspaceContext);
  if (!context) {
    throw new Error("useWorkspace must be used within a WorkspaceProvider");
  }
  return context;
};

const WorkspaceAppProvider: React.FC = ({ children }) => {
  const reduxStore = useStore();

  const [workspaceApp, setWorkspaceApp] = useState<DefaultWorkspaceApp | null>(
    null
  );

  const { globallySelectedApp } = reduxStore.getState() as {
    globallySelectedApp: string;
  };
  const { data: user } = useFetchUserQuery(globallySelectedApp || "");

  useEffect(() => {
    if (user) {
      setWorkspaceApp(user.account?.default_workspace_app || null);
    }
  }, [user]);

  return (
    <WorkspaceContext.Provider value={{ workspaceApp }}>
      {children}
    </WorkspaceContext.Provider>
  );
};

export { WorkspaceAppProvider, useWorkspaceAppContext };
