import { ReactElement } from "react";

import WorkspaceVariables from "./workspace-variables";
import AppUserVariables from "./app-user-variables";

import "./styles.scss";

const Variables = (): ReactElement => {
  return (
    <>
      <div className="account-setting-wrapper team-management-wrapper">
        <h2 className="page-heading">Variables</h2>
        <WorkspaceVariables />
        <AppUserVariables />
      </div>
    </>
  );
};

export default Variables;
