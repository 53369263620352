import {
  REQUEST_BUNDLES,
  RECEIVE_BUNDLES,
  BUNDLES_SET_APP_FILTER,
  DEPLOYMENT_CARD_LOADER,
} from "../actions/bundles";

export const bundles = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_BUNDLES:
    case RECEIVE_BUNDLES:
      return Object.assign({}, state, {
        [action.appId]: getBundles(state[action.appId], action),
      });
    case DEPLOYMENT_CARD_LOADER:
      return Object.assign({}, state, {
        deploymentCardLoader: action.deploymentCardLoader,
      });
    default:
      return state;
  }
};

const getBundles = (
  state = {
    isFetching: false,
    fetchCount: 0,
    items: [],
    appsHavingBundles: [],
    filters: {
      appId: "",
    },
  },
  action
) => {
  switch (action.type) {
    case REQUEST_BUNDLES:
      return Object.assign({}, state, {
        isFetching: true,
        fetchCount: state.fetchCount + 1,
      });
    case RECEIVE_BUNDLES:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.bundles,
        appsHavingBundles: action.appsHavingBundles,
      });
    case BUNDLES_SET_APP_FILTER:
      return Object.assign({}, state, {
        filters: {
          appId: action.app_id,
        },
      });
    default:
      return state;
  }
};
