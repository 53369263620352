/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useEffect, useRef, useState } from "react";

import delIcon from "images/del-account-icon.svg";
import SelectFieldDropdown from "components/forms/v3/select-field-dropdown";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import { getNodeByMachineName, getNode } from "../../../utils/tree";

interface FieldMappingProps {
  formikSteps(): [];
  tag: string;
  path: [];
  nestedFieldPath: string;
  fieldObject: Record<string, any>;
  setFieldValue(name, val): void;
  txtIcon: string;
  flattenTree: [];
  parentFieldName: string;
}

const FieldMapping = (props: FieldMappingProps): ReactElement => {
  const {
    formikSteps,
    tag,
    path,
    nestedFieldPath,
    fieldObject,
    setFieldValue,
    txtIcon,
    flattenTree,
    parentFieldName,
  } = props;

  const [tagStep, setTagStep] = useState<any>(null);
  const [currentStep, setCurrentStep] = useState<any>(null);
  const [tagFieldOptions, setTagFieldOptions] = useState<any[]>([]);
  const { field_map, field_list } = fieldObject;
  const tagChangeRef = useRef(0);

  useEffect(() => {
    const templateSteps = formikSteps();
    const tagStepTemp = getNodeByMachineName(
      { steps: templateSteps },
      getMachineNameFromTag()
    );
    const currentStepTemp = getNode({ steps: templateSteps }, "steps", path);
    setTagStep(tagStepTemp);
    setCurrentStep(currentStepTemp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const templateSteps = formikSteps();
    const tagStepTemp = getNodeByMachineName(
      { steps: templateSteps },
      getMachineNameFromTag()
    );
    setTagStep(tagStepTemp);
    tagChangeRef.current += 1;
    if (tagChangeRef.current > 1) {
      const field_map_reset = [
        {
          field_left: "",
          field_right: "",
          id: Math.floor(Math.random() * 100),
        },
      ];
      setFieldValue(`${nestedFieldPath}.field_map`, field_map_reset);
    }
    getTagStepOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  useEffect(() => {
    getTagStepOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagStep]);

  useEffect(() => {
    let addNextField = true;
    field_map.forEach(function (fld_obj) {
      if (!fld_obj.field_left || !fld_obj.field_right) {
        addNextField = false;
      }
    });
    if (addNextField) {
      addMapping();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field_map]);

  const getMachineNameFromTag = (): string => {
    const splittedTag = tag.slice(1, tag.length - 1).split("."); // remove brackets from tag and split them by .
    return splittedTag[1];
  };

  const getTagStepOptions = (): void => {
    const splittedTag = tag.slice(1, tag.length - 1).split(".");
    const treeData = retreiveTagValue(
      splittedTag.slice(2),
      tagStep,
      getMachineNameFromTag()
    );
    if (Array.isArray(treeData)) {
      let valid = true;
      treeData.forEach((obj) => {
        if (
          !Object.prototype.hasOwnProperty.call(obj, "id") ||
          !Object.prototype.hasOwnProperty.call(obj, "title") ||
          !Object.prototype.hasOwnProperty.call(obj, "type")
        ) {
          valid = false;
        }
      });
      if (valid) {
        setTagFieldOptions(treeData);
      } else {
        setTagFieldOptions([]);
      }
    } else {
      setTagFieldOptions([]);
    }
  };

  const retreiveTagValue = (valueKey, templateStep, machineName): unknown => {
    if (templateStep) {
      const { activity } = templateStep;
      let testResult;
      let finalValue = "";
      let samples;

      if (flattenTree && flattenTree.length) {
        testResult = flattenTree.find(
          (instance: Record<string, never>) =>
            instance.step_machine_name === machineName
        );
      }
      if (testResult && testResult.out && !testResult.out.error) {
        finalValue = getNestedValue(testResult, valueKey);
      } else if (testResult && testResult.items?.length) {
        finalValue = getNestedValue(
          testResult.items[testResult.items.length - 1],
          valueKey
        );
      }
      if (!finalValue)
        if (
          activity &&
          activity.activity_output &&
          valueKey.shift() === "out"
        ) {
          try {
            samples = JSON.parse(activity && activity.activity_output);
            finalValue = getNestedValue(samples, valueKey);
          } catch (error) {
            console.error("Error in parsing activity output");
          }
        }

      // In case the value is an object/array we need to stringify to display
      if (getValueType(finalValue) === "object")
        //   finalValue = JSON.stringify(finalValue);
        return finalValue;
    } else return "";
    return "";
  };

  const getNestedValue = (object, valueKey): any => {
    if (valueKey.length === 1) return object[valueKey[0]];
    if (valueKey.length === 0) return object;

    if (object[valueKey[0]])
      return getNestedValue(object[valueKey[0]], valueKey.slice(1));

    // eslint-disable-next-line no-param-reassign
    object[valueKey[0]] = {};
    return getNestedValue(object[valueKey[0]], valueKey.slice(1));
  };

  const getValueType = (value): string => {
    switch (typeof value) {
      case "number":
        return "number";
      case "boolean":
      case "object":
        return "object";
      default:
        return "string";
    }
  };

  const addMapping = (): void => {
    if (field_map && field_list && field_map.length < field_list.length) {
      const newMapObj = {
        field_left: "",
        field_right: "",
        id: Math.floor(Math.random() * 100),
      };
      const newFieldMap = [...field_map, newMapObj];
      setFieldValue(`${nestedFieldPath}.field_map`, newFieldMap);
    }
  };

  const removeMapping = (index): void => {
    field_map.splice(index, 1);
    setFieldValue(`${nestedFieldPath}.field_map`, field_map);
  };

  const getFilteredFieldList = (curr_field_map_id): Record<string, any>[] => {
    const left_fields = extractColumn(
      field_map.filter((obj) => {
        return obj.id !== curr_field_map_id;
      }),
      "field_left"
    );
    const fields = field_list.filter((field: Record<string, never>) => {
      return left_fields.indexOf(field.id) === -1;
    });
    return fields;
  };

  function extractColumn(arr, column): [] {
    return arr.map((x) => x[column]);
  }
  const setTagForMapping = (selectedField, fomikFieldName): void => {
    console.log("setTagForMapping", selectedField, fomikFieldName);
    setFieldValue(fomikFieldName, selectedField.tag);
  };

  const getMappingToolTipText = (): string => {
    if (field_list) {
      if (field_list.length > 0) {
        if (field_map.length === field_list.length) {
          return "There are no more fields left to map";
        }
      } else {
        return "No fields available to map";
      }
    } else {
      return "No fields available to map";
    }
    return "";
  };

  const hideMappingToolTip = (): boolean => {
    return !(
      (field_list && field_map.length === field_list.length) ||
      !field_list ||
      (field_list && field_list.length === 0)
    );
  };

  const isMappingDisabled = (): boolean => {
    return (
      (field_list && field_map.length === field_list.length) ||
      !field_list ||
      (field_list && field_list.length === 0)
    );
  };

  return tagStep && currentStep && tag ? (
    <div className="field-mapping-container">
      <div className="main-content">
        <div className="field-section left-div">
          <div className="app-name-div">
            <img
              src={currentStep.app.icon_url}
              className="app-icon"
              alt="app-icon"
            />
            <span className="app-title">{`${currentStep.app.name} Field`}</span>
          </div>
        </div>
        <div className="field-section right-div">
          <div className="app-name-div">
            <img
              src={tagStep.app?.icon_url}
              className="app-icon"
              alt="app-icon"
            />
            <span className="app-title">{`${tagStep.app?.name} Field`}</span>
          </div>
        </div>
        {field_list &&
          field_map &&
          field_map.length > 0 &&
          field_map.map((obj, index) => {
            return (
              <div>
                <div className="field-section left-div">
                  <SelectFieldDropdown
                    key={`f-to-${obj.id}`}
                    options={getFilteredFieldList(obj.id)}
                    placeholder="Select a field"
                    name={`${nestedFieldPath}.field_map[${index}].field_left`}
                    txtIcon={txtIcon}
                    parentFieldName={parentFieldName}
                  />
                </div>
                <div className="field-section right-div">
                  <SelectFieldDropdown
                    key={`f-from-${obj.id}`}
                    options={tagFieldOptions}
                    placeholder="Select a field"
                    name={`${nestedFieldPath}.field_map[${index}].field_right`}
                    txtIcon={txtIcon}
                    onChange={(e) =>
                      setTagForMapping(
                        e,
                        `${nestedFieldPath}.field_map[${index}].tag`
                      )
                    }
                  />
                </div>
                <span
                  className={
                    field_map.length === 1
                      ? "del-icon-span not-allowed"
                      : "del-icon-span"
                  }
                >
                  <img
                    onClick={(e) => {
                      if (field_map.length > 1) {
                        removeMapping(index);
                      }
                    }}
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && field_map.length > 1) {
                        removeMapping(index);
                      }
                    }}
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                    role="button"
                    className="del-icon"
                    src={delIcon}
                    alt="del-icon"
                  />
                </span>
              </div>
            );
          })}
      </div>
      <div className="field-count">
        Total fields: {field_list ? field_list.length : 0}
      </div>
      {!field_list && parentFieldName && (
        <span className="no-fields">
          Add a valid {parentFieldName || "account"} field first
        </span>
      )}
      <IntegryTooltip
        hideToolTip={hideMappingToolTip()}
        tooltipText={getMappingToolTipText()}
      >
        <button
          type="button"
          className={
            isMappingDisabled()
              ? "btn map-field-btn disabled"
              : "btn map-field-btn"
          }
          onClick={() => {
            if (!isMappingDisabled()) {
              addMapping();
            }
          }}
        >
          Map another field
        </button>
      </IntegryTooltip>
    </div>
  ) : (
    <div />
  );
};

export default FieldMapping;
