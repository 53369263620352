import { CSSProperties, HTMLAttributes, useState } from "react";

import ArrowRightIcon from "images/arrow_right.svg";
import DoubleArrowRightIcon from "images/double_arrow_right.svg";
import ArrowLeftDisableIcon from "images/arrow_left_disable.svg";
import DoubleArrowLeftDisableIcon from "images/double_arrow_left_disabled.svg";

import "antd/dist/antd.css";
import "./styles.scss";

export interface IPageConfig {
  page: number | string;
  pageSize: number | string;
}

export interface IProps extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties;
  total: number;
  onPageChange?: (pageConfig: IPageConfig) => void;
  onPageSizeChange?: (pageConfig: IPageConfig) => void;
  defaultCurrentPage?: number;
  defaultPageSize?: number;
  hideTotalMessage?: boolean;
}

const IntegryPagination = ({
  customStyle = {},
  ...props
}: IProps): JSX.Element => {
  const {
    total,
    onPageChange,
    onPageSizeChange,
    defaultCurrentPage,
    defaultPageSize,
    hideTotalMessage = false,
  } = props;
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage || 1);
  const [currentPerPage, setCurrentPerPage] = useState(defaultPageSize || 10);

  const showPageButton = (pageIndex, countOfBtns): boolean => {
    let flag = true;
    let leftEdge = currentPage - 3;
    const rightEdge = currentPage + 1;
    if (currentPage + 1 === countOfBtns) {
      leftEdge = currentPage - 4;
    }
    if (currentPage === countOfBtns) {
      leftEdge = currentPage - 5;
    }
    if (pageIndex < leftEdge || pageIndex > rightEdge) {
      flag = false;
    }
    return flag;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getPageButtons = (countOfBtns): any => {
    if (countOfBtns < 1) {
      return (
        <button className="navigation-cta active-cta" type="button">
          1
        </button>
      );
    }
    return Array.from(Array(parseInt(countOfBtns, 10)).keys()).map(
      (pageNbr) => {
        if (countOfBtns > 5 && currentPage > 3) {
          if (!showPageButton(pageNbr, countOfBtns)) {
            return null;
          }
        }
        if (currentPage < 4 && countOfBtns > 5 && pageNbr > 4) {
          return null;
        }
        return (
          <button
            key={pageNbr}
            className={`navigation-cta${
              currentPage === pageNbr + 1 ? " active-cta" : ""
            }`}
            type="button"
            onClick={() => {
              handlePageChange(pageNbr + 1);
            }}
          >
            {pageNbr + 1}
          </button>
        );
      }
    );
  };

  const handlePageChange = (pageNbr = 1): void => {
    setCurrentPage(pageNbr);
    if (onPageChange) {
      onPageChange({
        page: pageNbr,
        pageSize: currentPerPage,
      });
    }
  };

  const handlePageSizeChange = (e): void => {
    setCurrentPage(1);
    if (onPageSizeChange) {
      onPageSizeChange({
        page: 1,
        pageSize: parseInt(e.target.value, 10),
      });
    }
    setCurrentPerPage(parseInt(e.target.value, 10));
  };
  const numberOfPages = Math.ceil(total / currentPerPage);
  const isLastPage = currentPage === numberOfPages;
  return (
    <div className="integry-pagination" style={{ ...customStyle }}>
      <div className="integry-pagination__pages">
        <button
          className={`navigation-cta round-cta${
            currentPage > 1 ? "" : " disabled-cta"
          }`}
          type="button"
          onClick={() => {
            if (currentPage > 1) {
              handlePageChange(1);
            }
          }}
        >
          {currentPage > 1 ? (
            <img
              src={DoubleArrowRightIcon}
              alt="d_arrow_left"
              style={{
                transform: "rotate(180deg)",
              }}
            />
          ) : (
            <img src={DoubleArrowLeftDisableIcon} alt="d_arrow_left" />
          )}
        </button>
        <button
          className="navigation-cta round-cta"
          type="button"
          onClick={() => {
            if (numberOfPages > 1 && currentPage !== 1) {
              handlePageChange(currentPage - 1);
            }
          }}
        >
          {numberOfPages > 1 && currentPage > 1 ? (
            <img
              src={ArrowRightIcon}
              alt="arrow_left"
              style={{
                transform: "rotate(180deg)",
              }}
            />
          ) : (
            <img src={ArrowLeftDisableIcon} alt="arrow_left" />
          )}
        </button>
        {getPageButtons(numberOfPages)}
        <button
          className="navigation-cta round-cta"
          type="button"
          onClick={() => {
            if (numberOfPages > 1) {
              const lastPage = `${numberOfPages}`;
              if (currentPage !== parseInt(lastPage, 10)) {
                handlePageChange(currentPage + 1);
              }
            }
          }}
        >
          {numberOfPages > 1 && !isLastPage ? (
            <img src={ArrowRightIcon} alt="arrow_right" />
          ) : (
            <img
              src={ArrowLeftDisableIcon}
              alt="arrow_right"
              style={{
                transform: "rotate(180deg)",
              }}
            />
          )}
        </button>
        <button
          className="navigation-cta round-cta"
          type="button"
          onClick={() => {
            const totalPages = numberOfPages;
            if (totalPages > 1) {
              const lastPage = `${numberOfPages}`;
              if (currentPage !== parseInt(lastPage, 10)) {
                handlePageChange(parseInt(lastPage, 10));
              }
            }
          }}
        >
          {numberOfPages > 1 && !isLastPage ? (
            <img src={DoubleArrowRightIcon} alt="d_arrow_right" />
          ) : (
            <img
              src={DoubleArrowLeftDisableIcon}
              alt="d_arrow_right"
              style={{
                transform: "rotate(180deg)",
              }}
            />
          )}
        </button>
      </div>

      <div className="flex gap-10px">
        {!hideTotalMessage && (
          <div className="align-center  txt-sm-liight-gray">
            Showing{" "}
            {currentPage === 1 ? 1 : (currentPage - 1) * currentPerPage + 1} to{" "}
            {currentPage * currentPerPage > total
              ? total
              : currentPage * currentPerPage}{" "}
            of {total} entries
          </div>
        )}
        <div className="integry-pagination__per-page">
          <select
            name="currentPages"
            id="currentPages"
            value={`${currentPerPage}`}
            onChange={handlePageSizeChange}
            className="per-page-select"
          >
            <option value="10">10 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default IntegryPagination;
