import React from "react";

const Footer = (props) => {
  const { hideFooter = false } = props;
  return (
    <nav className="nav justify-content-center footer-nav footer-nav-hide mb-4">
      <span className="nav-text">
        {!hideFooter
          ? `Copyright © ${new Date().getFullYear()} Integry. All Rights Reserved`
          : ""}
      </span>
      {!hideFooter && (
        <>
          <a
            className="nav-link"
            href="https://integry.io/careers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Jobs
          </a>
          <a
            className="nav-link"
            href="https://integry.io/blog/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Blog
          </a>
          <a
            className="nav-link"
            href="https://support.integry.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Help Center
          </a>
          <a
            className="nav-link"
            href="https://integry.io/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy
          </a>
          <a
            className="nav-link"
            href="https://integry.io/terms/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms
          </a>
        </>
      )}
    </nav>
  );
};

export default Footer;
