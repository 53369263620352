import { useState, useEffect, useRef } from "react";

const useElementVisibility = () => {
  const [isElementVisible, setIsElementVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsElementVisible(true);
        } else {
          setIsElementVisible(false);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return { elementRef, isElementVisible };
};

export default useElementVisibility;
