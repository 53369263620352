/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import DashboardWrapper from "components/header-v3/dashboard-wrapper";
import SideNavigation from "components/integry-design-system/atoms/side-navigation/side-navigation";
import IntegryLoader from "components/integry-loader";

import SettingsActionsListWithImportAI from "components/integry-design-system/organisms/actions-list-with-import-ai";

import urls from "utils/router-urls";

import companyProfileIcon from "images/account-setting/company-profile.svg";
import companyProfileSelectedIcon from "images/account-setting/company-profile-selected.svg";

import useAppBuilderAPI from "components/apps-v4/services/app-builder-apis";

import "./styles.scss";

const sideNavItems = [
  {
    name: "Actions",
    icon: companyProfileIcon,
    route: urls.appSettings_actions,
    isSelected: true,
    activatedIcon: companyProfileSelectedIcon,
  },
];

const AppSettings = (props): any => {
  const { user, location, globallySelectedApp } = props;
  const { getApp } = useAppBuilderAPI();
  const [defaultApp, setDefaultApp] = useState<any>({ id: -1 });
  useEffect(() => {
    if (globallySelectedApp !== "" || globallySelectedApp > 0) {
      getApp(globallySelectedApp).then((res) => {
        const { data } = res;
        setDefaultApp(data);
      });
    }
  }, [globallySelectedApp]);
  if (defaultApp.id < 1) {
    return (
      <DashboardWrapper user={user}>
        <div className="app-settings setting-page container">
          <div className="settings-wrapper">
            <div className="app-settings__sidebar">
              <SideNavigation
                navItems={sideNavItems}
                heading="App Settings"
                headingLogo={defaultApp.icon_url}
                currentUrl={location.pathname}
              />
            </div>
            <div className="app-settings__content">
              <IntegryLoader />
            </div>
          </div>
        </div>
      </DashboardWrapper>
    );
  }
  return (
    <DashboardWrapper user={user}>
      <div className="app-settings setting-page container">
        <div className="settings-wrapper">
          <div className="app-settings__sidebar">
            <SideNavigation
              navItems={sideNavItems}
              heading="App Settings"
              headingLogo={defaultApp.icon_url}
              currentUrl={location.pathname}
            />
          </div>
          <div className="app-settings__content">
            <Switch>
              <Route
                path="/settings/app/actions"
                render={() => (
                  <SettingsActionsListWithImportAI
                    defaultApp={defaultApp}
                    globallySelectedApp={globallySelectedApp}
                  />
                )}
              />
              <Route
                path="/settings/app/"
                render={(routeProps) => (
                  <Redirect to="/settings/app/actions" {...routeProps} />
                )}
              />
            </Switch>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default connect((state: any) => {
  return {
    globallySelectedApp: state?.globallySelectedApp,
  };
}, null)(AppSettings);
