import { forwardRef, ReactElement, useEffect, useRef, useState } from "react";

import "./tabs.scss";
import ErrorIcon from "images/templates-v6/tab-error-icon.svg";

interface TabProps {
  label: string;
  isActive: boolean;
  onClick: (tab: string) => void;
  data: { length: number; error?: boolean };
}

// renders an individual tab
const Tab = forwardRef<HTMLDivElement, TabProps>((props, ref): JSX.Element => {
  const { label, onClick, isActive, data } = props;
  return (
    <div ref={ref} className={`tab ${isActive ? `active` : ``}`} tabIndex={-1}>
      <button type="button" onClick={() => onClick(label)} tabIndex={-1}>
        <span className="text">
          {data.length !== undefined ? `${label} (${data.length})` : label}
        </span>
        {data.error && <img src={ErrorIcon} alt="error-icon" />}
      </button>
    </div>
  );
});

export interface TabsProps {
  defaultTabLabel?: string;
  children: ReactElement[];
  onTabChange?: (tab: string) => void;
}

/**
 * A tab component that allows users to switch between different views.
 *
 * Note: The tab content is not rendered lazily. It is rendered immediately.
 * Visibility is controlled by CSS.
 *
 * @prop {string} defaultTabLabel - The label of the tab to be selected by default.
 * @return {*}  {JSX.Element}
 */
const Tabs = (props: TabsProps): JSX.Element => {
  const { defaultTabLabel, children } = props;

  const [activeTab, setActiveTab] = useState(
    defaultTabLabel || children[0].props.title // set first tab to open by default
  );

  // keep a ref to the active tab for later
  const activeTabRef = useRef<HTMLDivElement>(null);

  // keep a ref to horizontally scroll the list using mouse wheel
  const tabsListRef = useRef<HTMLOListElement>(null);

  useEffect(() => {
    // set default tab if specified
    if (!activeTab) setActiveTab(defaultTabLabel);
  }, [activeTab, defaultTabLabel]);

  useEffect(() => {
    const tabsListContainer = tabsListRef.current;
    let moveContainer: { (e: WheelEvent): void };
    if (tabsListContainer) {
      moveContainer = (e: WheelEvent) => {
        // e.preventDefault();
        tabsListContainer.scrollLeft += e.deltaY;
      };
      tabsListContainer.addEventListener("wheel", moveContainer);
    }
    return () => {
      if (tabsListContainer && moveContainer) {
        tabsListContainer.removeEventListener("wheel", moveContainer);
      }
    };
  }, [tabsListRef]);

  // update active tab
  const onTabClick = (tab: string): void => {
    setActiveTab(tab);
    if (props.onTabChange) {
      props.onTabChange(tab);
    }
  };

  return (
    <div className="tabs">
      <ol className="tab-list" ref={tabsListRef}>
        {children
          .filter((c) => !!c.props.title)
          .map((child) => {
            const {
              title,
              "data-length": dataLength,
              "data-error": dataError,
            } = child.props;
            return (
              <Tab
                key={title}
                ref={activeTab === title ? activeTabRef : null}
                isActive={activeTab === title}
                label={title}
                onClick={onTabClick}
                data={{ length: dataLength, error: dataError }}
              />
            );
          })}
      </ol>
      {children.map((child) => {
        // only show active tab's content via CSS
        return (
          <div
            key={child.props.title}
            className={`tab-content ${
              child.props.title !== activeTab ? `hide` : ``
            }`}
          >
            {child.props.children}
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
