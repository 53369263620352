/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ReactElement } from "react";

import { useHistory } from "react-router-dom";
import "./side-navigation.scss";

interface IProps {
  heading: string;
  currentUrl: string;
  navItems: {
    route: string;
    icon: string;
    name: string;
    activatedIcon?: string;
  }[];
  headingLogo?: string;
}

const SideNavigation = (props: IProps): ReactElement => {
  const { heading, navItems, currentUrl, headingLogo } = props;
  const { replace } = useHistory();
  return (
    <div className="side-navigation">
      {headingLogo && (
        <div className="side-navigation-heading-logo">
          <img
            className="heading-logo-img"
            src={headingLogo}
            alt="heading-logo"
          />
        </div>
      )}
      <p className="side-navigation-heading">{heading}</p>
      <div className="route-container">
        {navItems.map((item) => {
          const isSelected = currentUrl === item.route;
          return (
            <div
              onClick={() => replace(item.route)} // using replace() to replce the current history item to new url. then if we go back. previous page will be open instead of tab.
              className={`side-nav-item ${
                isSelected ? "side-nav-item-selected" : ""
              }`}
              key={item.name}
            >
              <img
                src={
                  isSelected && item.activatedIcon
                    ? item.activatedIcon
                    : item.icon
                }
                alt="route-url"
              />
              {item.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SideNavigation;
