/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const SET_APP_BUILDER_STATE = "SET_APP_BUILDER_STATE";
export const SET_APP_BUILDER_STATE_ACTIVITIES =
  "SET_APP_BUILDER_STATE_ACTIVITIES";
export const SET_APP_BUILDER_FORM_STATUS = "SET_APP_BUILDER_FORM_STATUS";
export const SET_APP_BUILDER_ACTIVITIES_FORM_STATUS =
  "SET_APP_BUILDER_ACTIVITIES_FORM_STATUS";
export const SET_APP_BUILDER_WIZARD_MODE = "SET_APP_BUILDER_WIZARD_MODE";
export const SET_APP_BASIC_SECTION_COMPLETE = "SET_APP_BASIC_SECTION_COMPLETE";
export const SET_APP_AUTHORIZATION_SECTION_COMPLETE =
  "SET_APP_AUTHORIZATION_SECTION_COMPLETE";
export const SET_APP_ACTIVITY_SECTION_COMPLETE =
  "SET_APP_ACTIVITY_SECTION_COMPLETE";
export const SET_APP_FLOW_COMPLETE = "SET_APP_FLOW_COMPLETE";
export const SET_FIRST_ACTION_ID = "SET_FIRST_ACTION_ID";
export const SET_FLOW_ACTIVITY_TYPE = "SET_FLOW_ACTIVITY_TYPE";
export const SET_DONT_USE_FLOW_ACTIVITY_TYPE =
  "SET_DONT_USE_FLOW_ACTIVITY_TYPE";
export const SET_FIRST_TRIGGER_ID = "SET_FIRST_TRIGGER_ID";
export const SET_FIRST_QUERY_ID = "SET_FIRST_QUERY_ID";
export const SET_FIRST_AUTH_ID = "SET_FIRST_AUTH_ID";
export const SET_APP_MODEL = "SET_APP_MODEL";
export const UPDATE_APP_MODEL = "UPDATE_APP_MODEL";
export const SET_AUTH_MODEL = "SET_AUTH_MODEL";
export const UPDATE_AUTH_MODEL = "UPDATE_AUTH_MODEL";
export const SET_USER_INFO_ENDPOINT = "SET_USER_INFO_ENDPOINT";
export const SET_TOKEN_ENDPOINT = "SET_TOKEN_ENDPOINT";
export const SET_ACTIVITY_IN_ACTIVITY_MODEL = "SET_ACTIVITY_IN_ACTIVITY_MODEL";
export const UPDATE_USER_INFO_ENDPOINT = "UPDATE_USER_INFO_ENDPOINT";
export const UPDATE_TOKEN_ENDPOINT = "UPDATE_TOKEN_ENDPOINT";
export const SET_ACTION_ENDPOINT = "SET_ACTION_ENDPOINT";
export const UPDATE_ACTION_ENDPOINT = "UPDATE_ACTION_ENDPOINT";
export const UPDATE_ACTIVITY_IN_ACTIVITY_MODEL =
  "UPDATE_ACTIVITY_IN_ACTIVITY_MODEL";
export const SET_APP_BUILDER_ACTIVITIES_DATA_SOURCE_FORM_STATUS =
  "SET_APP_BUILDER_ACTIVITIES_DATA_SOURCE_FORM_STATUS";
export const RESET_APP_BUILDER = "RESET_APP_BUILDER";
export const RESET_APP_BUILDER_MODELS = "RESET_APP_BUILDER_MODELS";
export const RESET_AUTHORIZATION_FORM = "RESET_AUTHORIZATION_FORM";
export const RESET_AUTHORIZATION_MODEL = "RESET_AUTHORIZATION_MODEL";
export const RESET_ACTIVITY_FORM = "RESET_ACTIVITY_FORM";
export const RESET_ACTIVITY_MODELS = "RESET_ACTIVITY_MODELS";
export const SET_TRIGGER_ENDPOINT = "SET_TRIGGER_ENDPOINT";
export const SET_TRIGGER_ENDPOINT_MAIN = "SET_TRIGGER_ENDPOINT_MAIN";
export const SET_TRIGGER_DELETE_ENDPOINT = "SET_TRIGGER_DELETE_ENDPOINT";
export const UPDATE_TRIGGER_ENDPOINT = "UPDATE_TRIGGER_ENDPOINT";
export const UPDATE_TRIGGER_ENDPOINT_MAIN = "UPDATE_TRIGGER_ENDPOINT_MAIN";
export const UPDATE_TRIGGER_ENDPOINT_DELETE = "UPDATE_TRIGGER_ENDPOINT_DELETE";
export const APP_LOCK_ALERT_BAR_TEXT = "APP_LOCK_ALERT_BAR_TEXT";
export const setAppBuilderState = (section, form, data): any => ({
  type: SET_APP_BUILDER_STATE,
  section,
  form,
  data,
});

export const setAppBuilderActivitiesState = (section, form, data): any => ({
  type: SET_APP_BUILDER_STATE_ACTIVITIES,
  section,
  form,
  data,
});

export const setAppBuilderFormStatus = (section, form, data): any => ({
  type: SET_APP_BUILDER_FORM_STATUS,
  section,
  form,
  data,
});

export const setAppBuilderActivitiesFormStatus = (
  section: string,
  form: string,
  data
): any => ({
  type: SET_APP_BUILDER_ACTIVITIES_FORM_STATUS,
  section,
  form,
  data,
});

export const setAppBuilderActivitiesDataSourceFormStatus = (
  section,
  form,
  data
): any => ({
  type: SET_APP_BUILDER_ACTIVITIES_DATA_SOURCE_FORM_STATUS,
  section,
  form,
  data,
});

export const setAppBuilderWizardMode = (mode): any => ({
  type: SET_APP_BUILDER_WIZARD_MODE,
  mode,
});

export const setAppBuilderBasicSectionComplete = (mode): any => ({
  type: SET_APP_BASIC_SECTION_COMPLETE,
  mode,
});

export const setAppBuilderAuthorizationSectionComplete = (mode): any => ({
  type: SET_APP_AUTHORIZATION_SECTION_COMPLETE,
  mode,
});

export const setAppBuilderActivitySectionComplete = (mode): any => ({
  type: SET_APP_ACTIVITY_SECTION_COMPLETE,
  mode,
});

export const setAppBuilderAppFlowComplete = (mode): any => ({
  type: SET_APP_FLOW_COMPLETE,
  mode,
});

export const setAppBuilderFirstAuthId = (data): any => ({
  type: SET_FIRST_AUTH_ID,
  data,
});

export const setAppBuilderFlowActivityType = (data): any => ({
  type: SET_FLOW_ACTIVITY_TYPE,
  data,
});

export const setDontUseFlowActivityType = (data): any => ({
  type: SET_DONT_USE_FLOW_ACTIVITY_TYPE,
  data,
});

export const setAppBuilderFirstActionId = (data): any => ({
  type: SET_FIRST_ACTION_ID,
  data,
});

export const setAppBuilderFirstQueryId = (data): any => ({
  type: SET_FIRST_QUERY_ID,
  data,
});

export const setAppBuilderFirstTriggerId = (data): any => ({
  type: SET_FIRST_TRIGGER_ID,
  data,
});

export const resetAppBuilder = (): any => ({
  type: RESET_APP_BUILDER,
});

export const resetAuthorizationForm = (): any => ({
  type: RESET_AUTHORIZATION_FORM,
});

export const resetAuthorizationModels = (): any => ({
  type: RESET_AUTHORIZATION_MODEL,
});

export const resetActivityForm = (): any => ({
  type: RESET_ACTIVITY_FORM,
});

export const resetActivityModels = (): any => ({
  type: RESET_ACTIVITY_MODELS,
});

export const setAppModel = (data): any => ({
  type: SET_APP_MODEL,
  data,
});

export const updateAppModel = (data): any => ({
  type: UPDATE_APP_MODEL,
  data,
});

export const setAuthModel = (data): any => ({
  type: SET_AUTH_MODEL,
  data,
});

export const updateAuthModel = (data): any => ({
  type: UPDATE_AUTH_MODEL,
  data,
});

export const setUserInfoEndpoint = (data): any => ({
  type: SET_USER_INFO_ENDPOINT,
  data,
});

export const updateUserInfoEndpoint = (data): any => ({
  type: UPDATE_USER_INFO_ENDPOINT,
  data,
});

export const setTokenEndpoint = (data): any => ({
  type: SET_TOKEN_ENDPOINT,
  data,
});

export const updateTokenEndpoint = (data): any => ({
  type: UPDATE_TOKEN_ENDPOINT,
  data,
});

export const setActionEndpoint = (data): any => ({
  type: SET_ACTION_ENDPOINT,
  data,
});

export const setTriggerEndpoint = (data): any => ({
  type: SET_TRIGGER_ENDPOINT,
  data,
});

export const setTriggerEndpointMain = (data): any => ({
  type: SET_TRIGGER_ENDPOINT_MAIN,
  data,
});

export const setTriggerDeleteEndpoint = (data): any => ({
  type: SET_TRIGGER_DELETE_ENDPOINT,
  data,
});

export const updateActionEndpoint = (data): any => ({
  type: UPDATE_ACTION_ENDPOINT,
  data,
});

export const updateTriggerEndpoint = (data): any => ({
  type: UPDATE_TRIGGER_ENDPOINT,
  data,
});

export const updateTriggerEndpointMain = (data): any => ({
  type: UPDATE_TRIGGER_ENDPOINT_MAIN,
  data,
});

export const updateTriggerEndpointDelete = (data): any => ({
  type: UPDATE_TRIGGER_ENDPOINT_DELETE,
  data,
});

export const setActivityInActivityModel = (
  activityType: string,
  data
): any => ({
  type: SET_ACTIVITY_IN_ACTIVITY_MODEL,
  activityType,
  data,
});

export const updateActivityInActivityModel = (
  activityType: string,
  data
): any => ({
  type: UPDATE_ACTIVITY_IN_ACTIVITY_MODEL,
  activityType,
  data,
});

export const appLockAlertBarText = (warning): any => ({
  type: APP_LOCK_ALERT_BAR_TEXT,
  warning,
});

export const resetAppBuilderModels = (): any => ({
  type: RESET_APP_BUILDER_MODELS,
});
