/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import { useEffect, useMemo, useRef } from "react";
import { debounce } from "lodash";

export const useDebounce = (
  callback: (...args: any[]) => void,
  delay = 1000
): ((...args: any[]) => void) => {
  const callbackRef = useRef(callback);

  // Update the ref every time the function changes
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // useMemo to create a debounced version of the callback that can accept arguments
  const debouncedCallback = useMemo(() => {
    // Return a debounced function that forwards any arguments to the callback
    return debounce((...args: any[]) => {
      callbackRef.current(...args);
    }, delay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay]);

  return debouncedCallback;
};
