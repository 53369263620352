import {
  REQUEST_APP_CATEGORIES,
  RECEIVE_APP_CATEGORIES,
} from "../actions/app-categories";

export const appCategories = (
  state = { isFetching: false, items: [] },
  action
) => {
  switch (action.type) {
    case REQUEST_APP_CATEGORIES:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_APP_CATEGORIES:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.app_categories,
      });
    default:
      return state;
  }
};
