import { ReactElement, useRef } from "react";
import { Formik, FormikProps, Form, FieldArray } from "formik";

import WorkspaceVariableRow from "./workspace-variable-row";

import { useFetchWorkspaceVariableQuery } from "../../api/useWorkspaceVariables";
import { WorkspaceVariable } from "../../interface";

const WorkspaceVariables = (): ReactElement => {
  const { data: paginatedWorkspaceVariables, isLoading } =
    useFetchWorkspaceVariableQuery({
      includeEmptyRow: true,
    });

  const formikRef = useRef<
    FormikProps<{
      variables: WorkspaceVariable[];
    }>
  >();

  return (
    <>
      {!isLoading && paginatedWorkspaceVariables?.results ? (
        <Formik
          initialValues={{
            variables: paginatedWorkspaceVariables.results || [],
          }}
          onSubmit={() => {
            // do nothing
          }}
        >
          {(formik) => {
            formikRef.current = formik;
            const { values } = formik;

            return (
              <Form>
                <div className="variables workspace">
                  <div className="account-settings-form">
                    <h3 className="main-title">Workspace variables</h3>
                    <div>
                      <div className="tag-row heading-row">
                        <div className="tag-col-head">Key</div>
                        <div className="tag-col-head">Value</div>
                        <div className="tag-col-4 description" />
                      </div>

                      <FieldArray
                        name="variables"
                        render={() => (
                          <>
                            {values.variables &&
                              values.variables.length > 0 &&
                              values.variables.map((variable, index) => (
                                <WorkspaceVariableRow
                                  workspaceVariable={variable}
                                  fieldName={`variables.${index}`}
                                  key={variable.id}
                                  isLast={index === values.variables.length - 1}
                                />
                              ))}
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : null}
    </>
  );
};

export default WorkspaceVariables;
