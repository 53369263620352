import { createApi } from "@reduxjs/toolkit/query/react";
import config from "utils/config";
import { integryBaseQuery } from "utils/http";
import { ListedTemplate } from "./template-builder-interface";

interface listTemplatesResponse {
  count: number;
  links: { previous?: string; next?: string };
  meta: {
    status_counts: { status: string; count: number }[];
    template_apps: { app_id: number; app_name: string }[];
  };
  results: ListedTemplate[];
  total_pages: number;
}

export const templatesApi = createApi({
  reducerPath: "templatesApi",
  baseQuery: integryBaseQuery({
    baseUrl: config.apiBaseURL || "",
  }),
  tagTypes: ["templatesListing"],
  refetchOnMountOrArgChange: true, // this is to be removed once the other flow create/update endpoints are moved to rtk
  endpoints(build) {
    return {
      listTemplates: build.query<listTemplatesResponse, string>({
        query: (queryParams) => ({
          url: `/api/v1/templates/${queryParams ? `?${queryParams}` : ""}`,
          method: "get",
        }),
        providesTags: ["templatesListing"],
      }),
      listTemplatesWithStats: build.query<
        listTemplatesResponse,
        { queryParams: string; usage_stats: boolean }
      >({
        query: ({ queryParams, usage_stats = true }) => ({
          url: `/api/v1/templates/?version_lte=3${
            queryParams ? `&${queryParams}` : ""
          }${usage_stats ? "&usage_stats=true" : ""}`,
          method: "get",
        }),
        providesTags: ["templatesListing"],
      }),
      cloneFlow: build.mutation({
        query(flowId) {
          return {
            url: `/api/v2/templates/${flowId}/duplicate/`,
            method: "POST",
          };
        },
      }),
      archiveFlow: build.mutation({
        query(flowId) {
          return {
            url: `/api/templates/${flowId}/?publish=false&archive=true`,
            method: "PUT",
          };
        },
      }),
      unArchiveFlow: build.mutation({
        query(flowId) {
          return {
            url: `/api/templates/${flowId}/?publish=false`,
            method: "PUT",
          };
        },
      }),
      moveFlow: build.mutation({
        query({ flowId, ...data }) {
          return {
            url: `/api/v2/templates/${flowId}/app/`,
            method: "PUT",
            data,
          };
        },
      }),
      convertActivity: build.mutation({
        query({ appId, stepId }) {
          return {
            url: `/api/v1/apps/${appId}/activities/?template_step_id=${stepId}`,
            method: "POST",
          };
        },
      }),
    };
  },
});

export const {
  useListTemplatesQuery,
  useListTemplatesWithStatsQuery,
  useCloneFlowMutation,
  useMoveFlowMutation,
  useConvertActivityMutation,
  useArchiveFlowMutation,
  useUnArchiveFlowMutation,
  useLazyListTemplatesQuery,
} = templatesApi;
