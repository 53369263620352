import InfoMessage from "components/integry-design-system/molecules/info-message";
import React, { ReactElement, useState } from "react";
import infoIconImage from "images/info-icon.svg";
import infoIconNotificationPop from "images/notification-pop-icon.svg";
import externalLinkIconNotificationPop from "images/external-link-pop-icon.svg";
import PopupContainer from "components/integry-design-system/molecules/popup-container/popup-container";
import Button from "components/common/reusable-button";
import "./styles.scss";

const ERROR_NOTIFICATION_SNACKBAR_DISMISSED =
  "ERROR_NOTIFICATION_SNACKBAR_DISMISSED";

interface IErrorNotificationSetup {
  userId?: string | number;
  onClickSetupNotifications?: () => void;
  onClickYesInNotificationPopUp?: () => void;
  onDismissNotificationSnackbar?: () => void;
}

const ErrorNotificationSetup = (
  props: IErrorNotificationSetup
): ReactElement => {
  const {
    userId = "",
    onClickSetupNotifications,
    onClickYesInNotificationPopUp,
    onDismissNotificationSnackbar,
  } = props;
  const [showNotificationPop, setShowNotificationPop] = useState(false);

  const onClickLinkSetup = (): void => {
    setShowNotificationPop(true);
    if (onClickSetupNotifications) {
      onClickSetupNotifications();
    }
  };

  const onCloseNotificationPop = (): void => {
    setShowNotificationPop(false);
  };

  const onClickYesSetupNotification = (): void => {
    if (onClickYesInNotificationPopUp) {
      onClickYesInNotificationPopUp();
    }
    window.open(
      `${window.location.origin}/wapp/settings/error-notifications/`,
      "_blank"
    );
    setShowNotificationPop(false);
  };

  const onCloseInfoMessage = (): void => {
    if (onDismissNotificationSnackbar) {
      onDismissNotificationSnackbar();
    }
    localStorage.setItem(
      `${ERROR_NOTIFICATION_SNACKBAR_DISMISSED}_${userId}`,
      "true"
    );
  };

  const isInfoMessageDismissed = (): boolean => {
    if (
      localStorage.getItem(
        `${ERROR_NOTIFICATION_SNACKBAR_DISMISSED}_${userId}`
      ) &&
      localStorage.getItem(
        `${ERROR_NOTIFICATION_SNACKBAR_DISMISSED}_${userId}`
      ) === "true"
    ) {
      return true;
    }
    return false;
  };

  return isInfoMessageDismissed() ? (
    <></>
  ) : (
    <div className="error-notification-setup-box">
      <>
        <InfoMessage
          infoIcon={infoIconImage}
          infoMessage={
            <>
              Want to be notified of aborted runs?{" "}
              <span
                className="link"
                onClick={onClickLinkSetup}
                tabIndex={-1}
                role="button"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onClickLinkSetup();
                  }
                }}
              >
                Set up notifications
              </span>
            </>
          }
          showMessageProp
          onClose={onCloseInfoMessage}
        />
        <PopupContainer
          title="Set up notifications"
          contentClassName="setup-notification-popup"
          content={
            <>
              <p className="pop-content-text">
                When an error occurs in an integration created from any of your
                flows, a notification will be sent to one or more apps of your
                choice.{" "}
                <span
                  tabIndex={0}
                  role="button"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      window.open(
                        "https://support.integry.io/hc/en-us/articles/10049966750617",
                        "_blank"
                      );
                    }
                  }}
                  className="link"
                  onClick={() => {
                    window.open(
                      "https://support.integry.io/hc/en-us/articles/10049966750617",
                      "_blank"
                    );
                  }}
                >
                  Learn More
                </span>
              </p>
              <p className="pop-content-text bottom">Want to do it now?</p>
            </>
          }
          isOpen={showNotificationPop}
          close={onCloseNotificationPop}
          popupIcon={
            <>
              <img
                src={infoIconNotificationPop}
                className="pop-info-icon"
                alt="smartbox_icon"
              />
            </>
          }
          buttonSectionContent={
            <div className="button-section">
              <Button
                className="outline-btn"
                onClick={onCloseNotificationPop}
                title="I’ll do it later"
              />
              <Button
                className="solid-btn"
                onClick={onClickYesSetupNotification}
                title="Yes"
                icon={externalLinkIconNotificationPop}
                rightIcon
              />
            </div>
          }
        />
      </>
    </div>
  );
};

export default ErrorNotificationSetup;
