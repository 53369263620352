/* eslint-disable react/no-danger */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement } from "react";
import root from "react-shadow";
import { Field } from "formik";
import DOMPurify from "dompurify";

const renderField = (props, fieldProps): ReactElement => {
  const { field } = fieldProps;
  const { value = "" } = field;
  const { placeholder = "Click here to configure HTML markup…" } = props;
  const cleanHTPML = DOMPurify.sanitize(value);
  return (
    <>
      {value ? (
        <root.div className="quote">
          <div dangerouslySetInnerHTML={{ __html: cleanHTPML }} />
        </root.div>
      ) : (
        <p className="HtmlPreviewPlaceHolder">{placeholder}</p>
      )}
    </>
  );
};

const HtmlPreview = (props: {
  name: string;
  placeholder?: string;
}): ReactElement => {
  const { name } = props;
  return (
    <Field name={name}>{(fieldProps) => renderField(props, fieldProps)}</Field>
  );
};

export default HtmlPreview;
