/* eslint-disable import/prefer-default-export */

import config from "utils/config";

import { Step } from "../template-builder-v7/services/template-builder-interface";

export * from "./step-title";
export * from "./step-fields";
export * from "./steps-icon";
export * from "./steps-tags";

export const isCollapseStepOnly = ({
  templateStep,
  returnStepFlag,
}: {
  templateStep: Step;
  returnStepFlag: boolean;
}): boolean => {
  const {
    activity_type: activityType,
    activity_id: activityId,
    fields = [],
  } = templateStep;

  if (activityType === "SETUP") {
    return true;
  }
  if (activityType === "CONDITION_ELSE") {
    return true;
  }
  if (activityType === "SYSTEM_ACTIVITY") {
    if (returnStepFlag && activityId === config.abortStep.activityId) {
      return false;
    }
    return (
      fields?.filter((f) => f.activity_field_type !== "HIDDEN").length === 0 // if system activity has any visible field then it is expandable
    );
  }
  return false;
};
