/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ReactElement } from "react";
import useOutsideClick from "hooks/useOutsideClick";
import PreloadImages from "components/common/preload-images";
import kebabIcon from "images/vertical-kebab-menu.svg";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";

interface KebabMenuData {
  handleClick: () => void;
  icon?: string;
  title: string;
  isDisabled: boolean;
  tooltip?: string;
  id?: string;
}

interface CommonKebabMenuProps {
  menuItems: KebabMenuData[];
  menuIcon?: string;
  classname?: string;
  menuBottomBorder?: boolean;
}

export interface CommonKebabMenuItemProps {
  handleClick: () => void;
  icon?: string;
  title: string;
  isDisabled: boolean;
  islast: boolean;
  tooltip?: string;
  id?: string;
  menuBottomBorder?: boolean;
}
const KebabMenuItem = ({
  handleClick,
  icon,
  title,
  isDisabled,
  islast,
  tooltip,
  id,
  menuBottomBorder,
}: CommonKebabMenuItemProps): ReactElement => {
  return (
    <>
      <IntegryTooltip
        hideToolTip={!tooltip}
        tooltipText={tooltip}
        placement="right"
      >
        <button
          className="clear-buton-css expanded-step-menu-item"
          onClick={handleClick}
          type="button"
          id={id}
          disabled={isDisabled}
        >
          <>
            {icon && <img src={icon} alt={title} />}
            <span className="lable">{title}</span>
          </>
        </button>
      </IntegryTooltip>
      {menuBottomBorder && !islast && <div className="bottom-border" />}
    </>
  );
};

const CommonKebabMenu = (props: CommonKebabMenuProps): ReactElement => {
  const { menuItems, menuIcon, classname, menuBottomBorder } = props;
  const [ref, isComponentVisible, setIsComponentVisible] =
    useOutsideClick(false);
  const images = menuItems.map((item) => item.icon);
  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setIsComponentVisible((prevState) => !prevState);
        }}
        role="button"
        className="common-kebab-menu__icon"
      >
        <img src={menuIcon || kebabIcon} alt="collapse" />
        {isComponentVisible && (
          <div className={`common-kebab-menu ${classname}`} ref={ref}>
            {menuItems.map((item, index) => {
              if (item) {
                return (
                  <KebabMenuItem
                    {...item}
                    islast={index === menuItems.length - 1}
                    menuBottomBorder={menuBottomBorder}
                    key={item.title}
                  />
                );
              }
              return null;
            })}
          </div>
        )}
      </div>
      <PreloadImages images={images} />
    </>
  );
};

export default CommonKebabMenu;
