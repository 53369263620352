import { useEffect, useRef, useState } from "react";
import { FormFeedback } from "reactstrap";

import { getNodeByMachineName, getNode } from "../../../utils/tree";

import SelectFieldDropdown from "./select-field-dropdown";
import TooltipWrapper from "components/common/tooltip-wrapper";

import delIcon from "../../../images/del-account-icon.svg";
import dotLoader from "../../../images/three-dot-loader.gif";

const FieldMapping = ({
  formikSteps,
  tag = "",
  path,
  nestedFieldPath,
  fieldObject,
  setFieldValue,
  txtIcon,
  parentFieldName,
  disableFieldInput,
}) => {
  const [tagStep, setTagStep] = useState(null);
  const [tagStepOptionsError, setTagStepOptionsError] = useState(null);
  const [loadingExternalFields, setLoadingExternalFields] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);
  const [tagFieldOptions, setTagFieldOptions] = useState([]);
  const { field_map, field_list } = fieldObject;
  const tagChangeRef = useRef(0);
  useEffect(() => {
    const currentStepTemp = getNode({ steps: formikSteps() }, "steps", path);
    setCurrentStep(currentStepTemp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    addTagStep();
    tagChangeRef.current++;
    if (tagChangeRef.current > 1) {
      let field_map_reset = [
        {
          field_left: "",
          field_right: "",
          id: Math.floor(Math.random() * 100),
        },
      ];
      setFieldValue(`${nestedFieldPath}.field_map`, field_map_reset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  useEffect(() => {
    let addNextField = true;
    field_map.forEach(function (fld_obj) {
      if (!fld_obj.field_left || !fld_obj.field_right) {
        addNextField = false;
      }
    });
    if (addNextField) {
      addMapping();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field_map]);

  const getMachineNameFromTag = () => {
    const splittedTag = tag.slice(1, tag.length - 1).split("."); // remove brackets from tag and split them by .
    return splittedTag[1];
  };

  const addTagStep = () => {
    setLoadingExternalFields(true);
    const templateSteps = formikSteps();
    const tagStepTemp = getNodeByMachineName(
      { steps: templateSteps },
      getMachineNameFromTag()
    );
    if (tagStepTemp) {
      setTagStep(tagStepTemp);
      if (
        !tagStepTemp.field_list ||
        (tagStepTemp.field_list && tagStepTemp.field_list.isLoading)
      ) {
        setTimeout(() => {
          addTagStep();
        }, 5000);
      } else if (tagStepTemp.field_list && tagStepTemp.field_list.error) {
        getTagStepOptions(tagStepTemp, true);
      } else {
        getTagStepOptions(tagStepTemp);
      }
    }
  };

  const getTagStepOptions = (newTagStep, error) => {
    const stepFieldList = newTagStep.field_list;

    if (Array.isArray(stepFieldList)) {
      let valid = false;
      stepFieldList.forEach((obj) => {
        if (
          (obj.hasOwnProperty("id") &&
            obj.hasOwnProperty("title") &&
            obj.hasOwnProperty("type")) ||
          (obj.hasOwnProperty("value") && obj.hasOwnProperty("text"))
        )
          valid = true;
      });
      if (valid) {
        setTagFieldOptions(stepFieldList);
      } else {
        setTagFieldOptions([]);
      }
      setTagStepOptionsError(null);
    } else if (error) {
      setTagStepOptionsError(newTagStep.field_list.error);
    } else {
      setTagFieldOptions([]);
      setTagStepOptionsError(null);
    }
    setLoadingExternalFields(false);
  };

  const getNestedValue = (object, path) => {
    if (path.length === 1) return object[path[0]];
    else if (path.length === 0) return object;
    else {
      if (object[path[0]])
        return getNestedValue(object[path[0]], path.slice(1));
      else {
        object[path[0]] = {};
        return getNestedValue(object[path[0]], path.slice(1));
      }
    }
  };

  const getValueType = (value) => {
    switch (typeof value) {
      case "number":
        return "number";
      case "boolean":
      case "object":
        return "object";
      default:
        return "string";
    }
  };

  const addMapping = () => {
    if (field_map && field_list && field_map.length < field_list.length) {
      let newMapObj = {
        field_left: "",
        field_right: "",
        id: Math.floor(Math.random() * 100),
      };
      const newFieldMap = [...field_map, newMapObj];
      setFieldValue(`${nestedFieldPath}.field_map`, newFieldMap);
    }
  };

  const removeMapping = (index) => {
    field_map.splice(index, 1);
    setFieldValue(`${nestedFieldPath}.field_map`, field_map);
  };

  const getFilteredFieldList = (curr_field_map_id) => {
    let left_fields = extractColumn(
      field_map.filter((obj) => {
        return obj.id !== curr_field_map_id;
      }),
      "field_left"
    );
    let fields = field_list.filter((field) => {
      return left_fields.indexOf(field.id) === -1;
    });
    return fields;
  };

  function extractColumn(arr, column) {
    return arr.map((x) => x[column]);
  }
  const setTagForMapping = (selectedField, fomikFieldName) => {
    setFieldValue(fomikFieldName, selectedField.tag);
  };

  const getMappingToolTipText = () => {
    if (field_list) {
      if (field_list.length > 0) {
        if (field_map.length === field_list.length) {
          return "There are no more fields left to map";
        }
      } else if (field_list.isLoading) {
        return "Loading fields";
      } else {
        return "No fields available to map";
      }
    } else {
      return "No fields available to map";
    }
    return "";
  };

  const hideMappingToolTip = () => {
    return !(
      (field_list && field_map.length === field_list.length) ||
      !field_list ||
      (field_list && (field_list.length === 0 || field_list.isLoading))
    );
  };

  const isMappingDisabled = () => {
    return (
      (field_list && field_map.length === field_list.length) ||
      !field_list ||
      (field_list && (field_list.length === 0 || field_list.isLoading))
    );
  };

  return tagStep && currentStep?.app && tag ? (
    <div
      className="field-mapping-container"
      style={disableFieldInput ? { minHeight: "initial" } : {}}
    >
      <div className="main-content">
        <div className="field-section left-div">
          <div className="app-name-div">
            <img
              src={currentStep?.app?.icon_url}
              className="app-icon"
              alt="app-icon"
            />
            <span className="app-title">{`${currentStep?.app?.name} Field`}</span>
          </div>
          {field_list && field_list.isLoading && (
            <img
              src={dotLoader}
              alt="drop-icon"
              className="drop-icon"
              style={{ width: 30 }}
            />
          )}
        </div>
        <div className="field-section right-div">
          <div className="app-name-div">
            <img
              src={tagStep.app?.icon_url}
              className="app-icon"
              alt="app-icon"
            />
            <span className="app-title">{`${tagStep.app?.name} Field`}</span>
          </div>
          {field_list && field_list.isLoading && (
            <img
              src={dotLoader}
              alt="drop-icon"
              className="drop-icon"
              style={{ width: 30 }}
            />
          )}
        </div>

        {field_list &&
          !field_list.isLoading &&
          field_map &&
          field_map.map((obj, index) => {
            return (
              <div>
                <div className="field-section left-div">
                  <SelectFieldDropdown
                    key={`f-to-${obj.id ? obj.id : obj.value}`}
                    options={
                      field_list.error
                        ? []
                        : getFilteredFieldList(obj.id ? obj.id : obj.value)
                    }
                    placeholder={fieldObject.placeholder || "Select a field"}
                    name={`${nestedFieldPath}.field_map[${index}].field_left`}
                    txtIcon={txtIcon}
                    errorField={field_list.error}
                    parentFieldName={parentFieldName}
                    disableFieldInput={disableFieldInput}
                  ></SelectFieldDropdown>
                  {field_list.error && !disableFieldInput && (
                    <FormFeedback style={{ display: "block" }}>
                      {field_list.error}
                    </FormFeedback>
                  )}
                </div>
                <div className="field-section right-div">
                  <SelectFieldDropdown
                    isLoading={loadingExternalFields}
                    key={`f-from-${obj.id ? obj.id : obj.value}`}
                    options={tagFieldOptions}
                    errorField={tagStepOptionsError}
                    placeholder={fieldObject.placeholder || "Select a field"}
                    name={`${nestedFieldPath}.field_map[${index}].field_right`}
                    txtIcon={txtIcon}
                    onChange={(e) =>
                      setTagForMapping(
                        e,
                        `${nestedFieldPath}.field_map[${index}].tag`
                      )
                    }
                    disableFieldInput={disableFieldInput}
                  ></SelectFieldDropdown>
                  {tagStepOptionsError && !disableFieldInput && (
                    <FormFeedback style={{ display: "block" }}>
                      {tagStepOptionsError}
                    </FormFeedback>
                  )}
                </div>
                {!disableFieldInput && (
                  <span
                    className={
                      field_map.length === 1
                        ? "del-icon-span not-allowed"
                        : "del-icon-span"
                    }
                  >
                    <img
                      onClick={() => {
                        field_map.length > 1 && removeMapping(index);
                      }}
                      className="del-icon"
                      src={delIcon}
                      alt="del-icon"
                    />
                  </span>
                )}
              </div>
            );
          })}
      </div>
      {!disableFieldInput && (
        <>
          <div className="field-count">
            Total fields: {field_list ? field_list.length : 0}
          </div>
          {!field_list && parentFieldName && (
            <span className="no-fields">
              Add a valid {parentFieldName ? parentFieldName : "account"} field
              first
            </span>
          )}

          <TooltipWrapper
            hideToolTip={hideMappingToolTip()}
            tooltipText={getMappingToolTipText()}
            tooltipId={`common-editor-button-tooltip`}
          >
            <button
              type="button"
              className={
                isMappingDisabled()
                  ? "btn map-field-btn disabled"
                  : "btn map-field-btn"
              }
              onClick={() => {
                if (!isMappingDisabled()) {
                  addMapping();
                }
              }}
            >
              Map another field
            </button>
          </TooltipWrapper>
        </>
      )}
    </div>
  ) : (
    <> </>
  );
};

export default FieldMapping;
