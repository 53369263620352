/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Formik, Form } from "formik";
import { ReactElement } from "react";

export interface IFormikWrapper {
  saveCallback(values): void;
  validationSchema?: any;
  initialState: any;
  formContent?: ReactElement;
  reInitialize?: boolean;
  validateOnMount?: boolean;
  children?: ReactElement;
}

const FormikWrapper = (props: IFormikWrapper): ReactElement => {
  const {
    saveCallback,
    validationSchema,
    initialState,
    formContent,
    reInitialize = false,
    validateOnMount = false,
    children = null,
  } = props;

  return (
    <Formik
      enableReinitialize={reInitialize}
      initialValues={initialState}
      validationSchema={validationSchema}
      validateOnMount={validateOnMount}
      onSubmit={(values, actions) => {
        saveCallback(values);
        actions.setSubmitting(false);
      }}
    >
      <Form>{formContent || children}</Form>
    </Formik>
  );
};

export default FormikWrapper;
