import { EditorState, ContentState, SelectionState } from "draft-js";

export const ZERO_WIDTH_NBSP = "\uFEFF";

// Utility function to get selected text
export const getSelectedText = (
  contentState: ContentState,
  selectionState: SelectionState
): string => {
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const startOffset = selectionState.getStartOffset();
  const endOffset = selectionState.getEndOffset();
  const blockMap = contentState.getBlockMap();
  const blocks = blockMap
    .toSeq()
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat([[endKey, blockMap.get(endKey)]]);

  let selectedText = "";
  blocks.forEach((block) => {
    if (!block) {
      return;
    }
    const key = block.getKey();
    let text = block.getText();
    let start = 0;
    let end = text.length;

    if (key === startKey) {
      start = startOffset;
    }
    if (key === endKey) {
      end = endOffset;
    }

    text = text.slice(start, end);
    selectedText += text;
    // Add a newline character if not the last block
    if (key !== endKey) {
      selectedText += "\n";
    }
  });

  return selectedText;
};

export const getEditorPlainText = (newEditorState: EditorState): string => {
  const contentState = newEditorState.getCurrentContent();
  const plainText = contentState.getPlainText();
  return plainText;
};

export const convertToPillFormat = (text: string): string => {
  // Original regex pattern to find
  const regex = /\{[^{}",:\n]+\}/g;
  const cleadedText = convertToTagFormat(text);

  const validateTag = (tag: string): boolean => {
    // remove the curly braces
    const tagWithoutBraces = tag.slice(1, -1);
    // Check if the tag exists in the tag dictionary
    const containAnotherTag = tagWithoutBraces.includes("{");
    return !(tagWithoutBraces.endsWith(".") || containAnotherTag); // check if the tag is incomplete
  };

  // Function to replace each match with its bracketed version
  const pillsFormat = cleadedText.replace(regex, (match) => {
    if (validateTag(match)) {
      return `${ZERO_WIDTH_NBSP}<${match}>${ZERO_WIDTH_NBSP}`;
    }
    return match;
  });
  return pillsFormat;
};

export const convertToTagFormat = (text: string): string => {
  // Updated regex pattern to find matches within angle brackets
  const regex = /<\{[^{}]*\}>/g;
  const removeNoWidthSpaceRegix = /[\u200B-\u200D\uFEFF]/g;
  const removeNoWidthSpace = text?.replace(removeNoWidthSpaceRegix, "") || "";
  // Function to replace each bracketed match with just the curly brace part
  return removeNoWidthSpace.replace(regex, (match) => match.slice(1, -1));
};
