/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntercom } from "react-use-intercom";

import ConnectorListing from "components/turbo.v1/connector-listing";
import FlowsListing from "components/turbo.v1/flow-listing";
import CalendlyWidget from "components/turbo.v1/calendly-widget";
import MissingInfoPortal from "components/turbo.v1/missing-info-portal";
import DemographicForm from "components/turbo.v1/demographic-form";

import lang from "utils/lang/en";
import http from "utils/http";
import config from "utils/config";
import { getCompanyName } from "utils/functions";
import { track, turboMixpanelEvents } from "utils/mixpanel";

import logo from "images/logo-complete.svg";
import ArrowHeadLeftIcon from "images/arrow_head_left.svg";

import "./styles.scss";

const StepWizard = (props) => {
  const { user = {}, history } = props;
  // eslint-disable-next-line no-unused-vars
  const [isProcessing, setIsProcessing] = useState(false);
  const [steps, setSteps] = useState([]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [onboardingSurvey, setOnboardingSurvey] = useState({});
  const [showMissingInfoDialog, setShowMissingInforDialog] = useState(false);

  const { boot } = useIntercom();

  const handleMixpanelTrackEvent = ({ event = "-1", payload = {} }) => {
    track(event, user, payload);
  };

  const handleTotangoTrigger = (payload) => {
    http
      .post(
        // user.email.includes("@integry.io")
        //   ? "/trigger_totango_webhook/"
        //   : config.totangoIntegryWebhook,
        "/trigger_totango_webhook/",
        payload
      )
      // eslint-disable-next-line no-unused-vars
      .then((res) => {
        console.log("Totango event sent");
      })
      .else((e) => {
        console.error("ERROR while triggering totango event", { e });
      })
      .finally(() => {
        // window.open("/wapp/templates/v6", "_self");
      });
  };

  const handleSubmitDemographicData = (data) => {
    http
      .post("/update_user_details/", data)
      .then((res) => {})
      .catch((e) => {
        console.log({ e });
      })
      .finally(() => {});
  };

  const handleFinishOnboarding = (onboardingInfo, meetingScheduled = false) => {
    setIsProcessing(true);
    const {
      category = "",
      companyLink = "",
      companySize = "",
      promoCode = "",
      role = "",
    } = onboardingInfo.demographicFormData || {};
    handleSubmitDemographicData({
      company_size: companySize,
      platform_type: category,
      role,
      company_link: companyLink,
      promo_code: promoCode,
    });

    http
      .put("/accounts/me/mark_onboarding_completed/")
      .then((res) => {
        const { status = 500 } = res;
        if (status === 200) {
          localStorage.setItem(
            "onboardingInfo",
            JSON.stringify(onboardingInfo)
          );
          localStorage.setItem("currentOnboardingStepIndex", "0");
          localStorage.setItem(
            "isMeetingScheduled",
            meetingScheduled ? "scheduled" : "not_yet_scheduled"
          );
          window.open("/wapp/templates/v6", "_self");
          handleTotangoTrigger({
            onboardingInfo: {
              ...onboardingInfo,
              user,
              onboardingStage: meetingScheduled ? "Champ Connected" : "None",
            },
          });
        }
      })
      .catch((e) => {
        console.log("ERROR on /mark_onboarding_completed", { e });
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };
  const getCompanyWebsite = (email = "") => {
    if (email !== "") {
      const splittedEmail = email.split("@");
      if (splittedEmail[1]) {
        return `https://${splittedEmail[1]}`;
      }
      return "";
    }
    return "";
  };
  const stepsConfig = [
    {
      id: "step-0",
      title: lang.TELL_US_ABOUT_YOU,
      render: (
        <DemographicForm
          formData={onboardingSurvey.demographicFormData || {}}
          onSubmit={(formData) => {
            handleOnboardingSurveyChange({
              demographicFormData: formData,
            });
            // setOnboardingSurvey({
            //   ...onboardingSurvey,
            //   demographicFormData: formData,
            // });
            handleStepNavigation(1);
          }}
        />
      ),
      containerCSS: "demographic-step",
    },
    {
      id: "step-1",
      title: "Select a connector",
      description: "Connectors are used to build flows between apps",
      containerCSS: "connector-step",
      render: (
        <ConnectorListing
          selectedConnectorId={
            onboardingSurvey.selectedConnector
              ? onboardingSurvey.selectedConnector.id
              : -2
          }
          onConnectorSelect={(selectedConnector) => {
            handleConnectorSelect(selectedConnector);
          }}
          onConnectorNotFound={() => {
            setShowMissingInforDialog(true);
          }}
          user={user}
        />
      ),
      allowBack: true,
    },
    {
      id: "step-2",
      title: `${onboardingSurvey?.selectedConnector?.name}: Select a flow`,
      description: "A flow is a set of steps that move data between two apps",
      containerCSS: "suggested-flows-step",
      render: onboardingSurvey.selectedConnector ? (
        <FlowsListing
          selectedConnector={onboardingSurvey.selectedConnector}
          selectedFlow={onboardingSurvey.selectedFlow}
          onFlowSelect={(flow) => {
            handleFlowSelection(flow);
          }}
          onFlowNotFound={() => {
            setShowMissingInforDialog(true);
          }}
        />
      ) : null,
      allowBack: true,
    },
    {
      id: "step-3",
      title: lang.SCHEDULE_CALL_TITLE,
      description: lang.CALENDLY_SUB_CAPTION,
      containerCSS: "calendly-inline-step",
      render: (
        <CalendlyWidget
          onMeetingScheduled={() => {
            handleFinishOnboarding(onboardingSurvey, true);
          }}
          onMeetingScheduleSkipped={() => {
            handleFinishOnboarding(onboardingSurvey, false);
          }}
          sourcePage="Sign-up page"
          user={user}
        />
      ),
      allowBack: true,
    },
  ];
  const isInt = (str) =>
    !Number.isNaN(str) && Number.isInteger(parseFloat(str));

  const initOnboardingDataLS = () => {
    let onboardingInfoDraft = {};
    try {
      const onboardingInfoDraftTemp = JSON.parse(
        localStorage.getItem("onboardingInfo_draft")
      );
      onboardingInfoDraft = onboardingInfoDraftTemp[user.email] || {};
    } catch (e) {
      console.error("ERROR Converting data from LS", { e });
    }
    if (Object.keys(onboardingInfoDraft).length > 0) {
      setOnboardingSurvey(onboardingInfoDraft);
    }
  };

  useEffect(() => {
    document.title = "Get Started with Integry";
    const { location } = history;
    const { pathname = "/" } = location;
    const splitPath = pathname.split("/");
    if (splitPath[2]) {
      const stepToJump = splitPath[2];
      if (isInt(stepToJump)) {
        if (stepToJump > 1) {
          handleCurrentIndex(stepToJump - 1);
        } else {
          setCurrentStepIndex(0);
        }
      }
    } else {
      let currentIndexTemp = 0;

      try {
        const currentIndexLocalStorage = JSON.parse(
          localStorage.getItem("currentOnboardingStepIndex")
        );
        if (currentIndexLocalStorage && currentIndexLocalStorage[user.email]) {
          currentIndexTemp = currentIndexLocalStorage[user.email];
        }
      } catch (e) {
        console.error("ERROR CONVERTING STEP INDEX FROM L.S.", { e });
      }
      handleStepNavigation(currentIndexTemp);
    }
    setSteps(stepsConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepIndex, history.location.pathname]);
  useEffect(() => {
    initOnboardingDataLS();
    const userSnapIframe = document.getElementsByName("us-entrypoint-buttonV2");
    if (userSnapIframe && userSnapIframe.length > 0) {
      userSnapIframe[0].style.visibility = "hidden";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCurrentIndex = (index) => {
    if (index !== currentStepIndex) {
      setCurrentStepIndex(index);
      localStorage.setItem(
        "currentOnboardingStepIndex",
        JSON.stringify({
          [user.email]: index,
        })
      );
    }
  };
  const handleStepNavigation = (index) => {
    if (index !== currentStepIndex) {
      handleCurrentIndex(index);
      history.push(`/get-started/${index + 1}`);
    }
  };
  const handleOnboardingSurveyChange = (data) => {
    setOnboardingSurvey({
      ...onboardingSurvey,
      ...data,
    });
    localStorage.setItem(
      "onboardingInfo_draft",
      JSON.stringify({
        [user.email]: {
          ...onboardingSurvey,
          ...data,
        },
      })
    );
  };
  const handleFlowSelection = (selectedFlow) => {
    handleMixpanelTrackEvent({
      event: turboMixpanelEvents.SELECTED_FLOW_NAME.name,
      payload: {
        [turboMixpanelEvents.SELECTED_FLOW_NAME.property]:
          selectedFlow.suggestion,
      },
    });
    handleStepNavigation(3);
    handleOnboardingSurveyChange({
      selectedFlow,
    });
    // setOnboardingSurvey({
    //   ...onboardingSurvey,
    //   selectedFlow,
    // });
  };
  const handleConnectorSelect = (selectedConnector) => {
    handleMixpanelTrackEvent({
      event: turboMixpanelEvents.SELECTED_CONNECTOR_NAME.name,
      payload: {
        [turboMixpanelEvents.SELECTED_CONNECTOR_NAME.property]:
          selectedConnector.name,
      },
    });
    handleStepNavigation(2);
    handleOnboardingSurveyChange({
      selectedConnector,
    });
    // setOnboardingSurvey({
    //   ...onboardingSurvey,
    //   selectedConnector,
    // });
  };
  return (
    <div id="turbo__step-wizard" className="step-wizard">
      <div className="step-wizard__navbar">
        <img className="logo" alt="integry_logo" src={logo} />
      </div>
      <div
        className={`step-wizard__container${
          steps[currentStepIndex] && steps[currentStepIndex].containerCSS
            ? ` ${steps[currentStepIndex].containerCSS}`
            : ""
        }`}
        style={
          steps[currentStepIndex] && steps[currentStepIndex].containerStyle
            ? steps[currentStepIndex].containerStyle
            : {}
        }
      >
        <p className="step-wizard__step-counter">
          {steps[currentStepIndex] && steps[currentStepIndex].allowBack && (
            <img
              src={ArrowHeadLeftIcon}
              alt="go_to_previous_step"
              onClick={() => {
                if (currentStepIndex > 0) {
                  handleStepNavigation(currentStepIndex - 1);
                }
              }}
              style={{
                cursor: "pointer",
              }}
              aria-hidden
            />
          )}
          {`Step ${currentStepIndex + 1} of ${steps.length}`}
        </p>
        {steps[currentStepIndex] && (
          <div className="step-wizard__container--content">
            <p className="step-wizard__content--title">
              {steps[currentStepIndex].title}
            </p>
            {steps[currentStepIndex].description && (
              <p className="step-wizard__content--description">
                {steps[currentStepIndex].description}
              </p>
            )}
            <div className="step-wizard__content--layout">
              {/* {steps[currentStepIndex].render} */}
              {currentStepIndex === 0 && (
                <DemographicForm
                  formData={{
                    companyLink: getCompanyWebsite(user.email),
                    ...(onboardingSurvey.demographicFormData || {}),
                  }}
                  onSubmit={(formData) => {
                    handleOnboardingSurveyChange({
                      demographicFormData: {
                        ...formData,
                        companyName: getCompanyName(user.account_name),
                      },
                    });
                    if (user.email && !user.email.includes("mailosaur.net")) {
                      boot({
                        horizontalPadding: 50,
                        userId: user.id,
                        email: user.email,
                        name: `${user.first_name} ${user.last_name}`,
                        createdAt: user.date_joined,
                        userHash: user.intercom_hash,
                        company: {
                          name: getCompanyName(user.account_name),
                          website: formData.companyLink || "",
                          industry: formData.category,
                          companyId: formData.companyLink || user.account_name,
                        },
                        customAttributes: {
                          "Integry Account ID": user.account_id,
                          "Integry Account Name": user.account_name,
                          "First Name": user.first_name,
                          "Last Name": user.last_name,
                          "Company Size": formData.companySize,
                          "Company Link": formData.companyLink,
                          "Platform Type": formData.category,
                          Role: formData.role,
                        },
                      });
                    }
                    handleStepNavigation(1);
                    handleMixpanelTrackEvent({
                      event: turboMixpanelEvents.FILL_DEMOGRAPHIC_SURVEY.name,
                      payload: {
                        [turboMixpanelEvents.FILL_DEMOGRAPHIC_SURVEY.property
                          .COMPANY_WEBSITE]: formData.companyLink || "",
                        [turboMixpanelEvents.FILL_DEMOGRAPHIC_SURVEY.property
                          .PLATFORM_TYPE]: formData.category || "",
                        [turboMixpanelEvents.FILL_DEMOGRAPHIC_SURVEY.property
                          .COMPANY_SIZE]: formData.companySize || "",
                        [turboMixpanelEvents.FILL_DEMOGRAPHIC_SURVEY.property
                          .ROLE]: formData.role || "",
                      },
                    });
                  }}
                />
              )}
              {currentStepIndex === 1 && (
                <ConnectorListing
                  // selectedConnectorId={
                  //   onboardingSurvey.selectedConnector
                  //     ? onboardingSurvey.selectedConnector.id
                  //     : -2
                  // }
                  selectedConnectorId={-1}
                  onConnectorSelect={(selectedConnector) => {
                    handleConnectorSelect(selectedConnector);
                  }}
                  onConnectorNotFound={() => {
                    setShowMissingInforDialog(true);
                  }}
                  user={user}
                />
              )}
              {currentStepIndex === 2 && (
                <FlowsListing
                  selectedConnector={onboardingSurvey.selectedConnector}
                  // selectedFlow={onboardingSurvey.selectedFlow}
                  onFlowSelect={(flow) => {
                    handleFlowSelection(flow);
                  }}
                  onFlowNotFound={() => {
                    setShowMissingInforDialog(true);
                  }}
                />
              )}
              {currentStepIndex === 3 && (
                <CalendlyWidget
                  onMeetingScheduled={() => {
                    handleFinishOnboarding(onboardingSurvey, true);
                  }}
                  onMeetingScheduleSkipped={() => {
                    handleFinishOnboarding(onboardingSurvey, false);
                  }}
                  sourcePage="Sign-up page"
                  user={user}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {showMissingInfoDialog && (
        <MissingInfoPortal
          hideConnectorInfo={currentStepIndex > 1}
          onboardingSurvey={onboardingSurvey}
          onContinueClick={(data) => {
            handleOnboardingSurveyChange(data);
            // setOnboardingSurvey({
            //   ...onboardingSurvey,
            //   ...data,
            // });
            let mixpanelTrackPayload = {};
            if (data.flowYouWant) {
              mixpanelTrackPayload = {
                ...mixpanelTrackPayload,
                [turboMixpanelEvents.FLOW_REQUEST.property]: data.flowYouWant,
              };
            }
            if (data.appsYouWant) {
              mixpanelTrackPayload = {
                ...mixpanelTrackPayload,
                [turboMixpanelEvents.CONNECTOR_REQUEST.property]:
                  data.appsYouWant,
              };
            }
            handleMixpanelTrackEvent({
              event:
                currentStepIndex > 1
                  ? turboMixpanelEvents.FLOW_REQUEST.name
                  : turboMixpanelEvents.CONNECTOR_REQUEST.name,
              payload:
                currentStepIndex > 1
                  ? {
                      ...mixpanelTrackPayload,
                      [turboMixpanelEvents.SELECTED_CONNECTOR_NAME.property]: (
                        onboardingSurvey.selectedConnector || { name: "" }
                      ).name,
                    }
                  : mixpanelTrackPayload,
            });
            setShowMissingInforDialog(false);
            handleStepNavigation(3);
          }}
          onCancelClick={() => {
            setShowMissingInforDialog(false);
          }}
        />
      )}
    </div>
  );
};

StepWizard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.any,
};

export default StepWizard;
