import React, { Component, Fragment } from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

export default class AddTagDropdown extends Component {
  state = {
    dropdownOpen: false,
  };

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  render() {
    const { availableTags, addTag } = this.props;

    return (
      <ButtonDropdown
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        className="mt-3 btn-group-tags"
      >
        <DropdownToggle caret color="outline-primary">
          Add Tag
        </DropdownToggle>
        <DropdownMenu>
          {availableTags.map((tagGroup, i) => (
            <Fragment key={`taggroup-${i}`}>
              <DropdownItem header>{tagGroup.activityName}</DropdownItem>
              {tagGroup.tags.map((tag) => (
                <DropdownItem key={tag.value} onClick={addTag(tag.value)}>
                  {tag.label}
                </DropdownItem>
              ))}
            </Fragment>
          ))}
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
}
