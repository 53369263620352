/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import lang from "utils/lang/en";
import http from "utils/http";

import SelectedCheckIcon from "images/selected_check.svg";

import "./styles.scss";

const FlowListing = (props) => {
  const {
    selectedConnector,
    selectedFlow = { id: -1, suggestion: "-1" },
    onFlowSelect,
  } = props;
  const [suggestedFlows, setSuggestedFlows] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const listContentRef = useRef();

  const handleFlowSelection = (flow) => {
    if (onFlowSelect) {
      onFlowSelect(flow);
    }
  };
  const getLoadingPlaceholder = ({ nbrOfplaceholders = 1 }) =>
    [...Array(nbrOfplaceholders).keys()].map((item) => ({
      isLoading: true,
      id: `loading-placeholder-app-${item}`,
    }));
  const getSuggestedFlows = () => {
    setIsLoading(true);
    http
      .get(`api/v1/apps/${selectedConnector.id}/suggestions/ `)
      .then((res) => {
        const { data = [] } = res;
        const tempSuggestedFlow = [];
        data.forEach((suggestedFlowItem) => {
          const {
            activity_type = "",
            app_logo_url,
            object = {},
            suggestion = "",
          } = suggestedFlowItem;
          tempSuggestedFlow.push({
            activity_type,
            app_logo_url,
            object,
            suggestion,
          });
          setSuggestedFlows({
            [selectedConnector.id]: tempSuggestedFlow,
          });
        });
      })
      .catch((e) => {
        console.log("ERROR on /apps/:id/suggestions/ ", { e });
        setSuggestedFlows({
          [selectedConnector.id]: [],
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getSuggestedFlows();
    listContentRef.current.focus();
  }, [selectedConnector]);
  return (
    <div className="flow-listing">
      <div
        className="flow-listing__container"
        ref={listContentRef}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        {isLoading &&
          getLoadingPlaceholder({ nbrOfplaceholders: 5 }).map((flow) => (
            <div className="flow-card" key={flow.id}>
              <div
                className="flow-card__icon-container"
                style={{
                  background: "#FAFAFA",
                }}
              >
                <div className="placeholder img-placeholder">
                  <div className="animated-background" />
                </div>
              </div>
              <div className="flow-card__content-container">
                <div className="placeholder">
                  <div className="animated-background" />
                </div>
                <div
                  className="placeholder"
                  style={{
                    maxWidth: "90%",
                    marginLeft: "0",
                  }}
                >
                  <div className="animated-background" />
                </div>
              </div>
            </div>
          ))}
        {!isLoading &&
          (suggestedFlows[selectedConnector.id] || []).map((flow) => (
            <div
              className="flow-card"
              key={flow.suggestion.toLowerCase().replace(" ", "_")}
              onClick={() => {
                handleFlowSelection(flow);
              }}
              role="button"
              tabIndex={0}
              aria-hidden="true"
              style={
                // eslint-disable-next-line no-nested-ternary
                flow.suggestion.toLowerCase() ===
                selectedFlow.suggestion.toLowerCase()
                  ? { border: "1px solid #4250f0" }
                  : {}
                // : selectedConnector.color
                // ? {
                //     borderTop: `1px solid ${selectedConnector.color}`,
                //   }
                // : { borderTop: "1px solid #FFF6F0" }
              }
            >
              <div
                className="flow-card__icon-container"
                style={
                  selectedConnector.color
                    ? {
                        background: selectedConnector.color,
                        // border: `1px solid ${selectedConnector.color}`,
                      }
                    : { background: "#FFF6F0" }
                }
              >
                {flow.app_logo_url && (
                  <img
                    src={flow.app_logo_url}
                    alt={`suggestion_icon-${flow.id}`}
                  />
                )}
              </div>
              <div className="flow-card__content-container">
                <IntegryTooltip
                  tooltipText={flow.suggestion}
                  hideToolTip={flow.suggestion.length < 90}
                >
                  <p>{flow.suggestion}</p>
                </IntegryTooltip>
              </div>
              {flow.suggestion.toLowerCase() ===
                selectedFlow.suggestion.toLowerCase() && (
                <img
                  className="selected-icon"
                  src={SelectedCheckIcon}
                  alt={`${flow.id}_suggestion_selected_ico`}
                />
              )}
            </div>
          ))}
      </div>
      {/* {isLoading && <div className="loader" />} */}
      {!isLoading && (
        <div className="request-CTA__container">
          <p className="request-CTA__description">
            {lang.CANT_FIND_PLACEHOLDER}{" "}
            <button
              type="button"
              className="request-CTA__button"
              onClick={() => {
                const { onFlowNotFound } = props;
                if (onFlowNotFound) {
                  onFlowNotFound();
                }
              }}
            >
              {lang.TELL_US_MORE}
            </button>
          </p>
        </div>
      )}
    </div>
  );
};

FlowListing.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectedConnector: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  selectedFlow: PropTypes.any,
  onFlowSelect: PropTypes.func,
  onFlowNotFound: PropTypes.func,
};

export default FlowListing;
