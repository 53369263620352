/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import React, { ReactElement, useState } from "react";
import SortIconDefault from "images/sort.svg";
import SortIconDesc from "images/sort-applied.svg";
import exlaimationIconOutline from "images/exclaimation_icon_outline.svg";
import NotFoundIcon from "images/not_found_icon_lg.svg";
import StepPayloadCompnent, {
  stepMetaObjectPayload,
} from "components/integry-design-system/organisms/integration-view/step-payload-component";
import Tag from "components/integry-design-system/atoms/tag";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { track, configureOAuthAppEvents } from "utils/mixpanel";
import TruncateBullets from "images/truncate_bullets.svg";
import PolygonDownIcon from "images/dropdown_icon.svg";
import "./styles.scss";

interface ITableConfig {
  id: string | number;
  label: string;
  truncate?: boolean;
  tdStyle?: Record<string, unknown>;
  allowSorting?: boolean;
  sortingIdentifier?: string;
  type?: string;
  tooltip?: string;
}

interface IStepsTable {
  hideHeading?: boolean;
  config: ITableConfig[];
  data: any;
  user?: any;
  appId?: string;
  bundleId?: string;
}

export const statusLabels = {
  all: "All",
  ok: "OK",
  failed: "Failed",
  running: "Running",
};

export const networkCodeLabels = {
  all: "All",
  n_a: "N/A",
  Loop: "200",
  loop: "200",
};

export const networkCodeColors = {
  "2": "#749D48",
  "3": "#FFFAE2",
  "4": "#F05C42",
  "5": "#F05C42",
  N: "#666666",
  n: "#666666",
  l: "#749D48",
  L: "#749D48",
};

const InterButton = styled(Button)<any>(({ isDisabled = false, variant }) => ({
  fontFamily: ["Inter"],
  // font-family: 'Inter';
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "15px",
  color: "#4250F0",
  padding: "5px 10px",
  borderColor: "#4250F0",
  borderRadius: "5px",
  textTransform: "none",
  minWidth: "90px",
  opacity: isDisabled ? 0.5 : 1,
  "&:hover":
    variant !== "text"
      ? {
          backgroundColor: !isDisabled ? "#4250F0" : "#FFFFFF",
          color: !isDisabled ? "#FFFFFF" : "#4250F0",
          cursor: !isDisabled ? "pointer" : "not-allowed",
        }
      : {
          backgroundColor: "transparent",
        },
}));

const PayloadButton = styled(InterButton)(() => ({
  padding: "4.5px 6px",
  paddingRight: "0",
  fontWeight: "400",
  fontSize: "13px",
  lineHeight: "16px",
  maxWidth: "109px",
  minWidth: "104px",
  whiteSpace: "nowrap",
  height: "25px",
}));

const StepsTable = (props: IStepsTable): ReactElement => {
  const { hideHeading, config, data, user, appId = "", bundleId = "" } = props;
  const [sorting, setSorting] = useState<Record<string, unknown>>();
  const [fetching, setFetching] = useState(false);
  const [expandedPayloadStepId, setExpandedPayloadStepId] =
    useState<string>("-1");
  const [viewStepPayloadData, setViewStepPayloadData] =
    useState<stepMetaObjectPayload>({});

  const handleOnSorting = (sortingIdentifier: string | number): void => {
    console.log(sortingIdentifier);
  };
  const StepHeader = (): ReactElement => {
    return (
      <>
        {!hideHeading && (
          <div className="div-header">
            {config.map((configItem) => {
              const { tooltip, allowSorting } = configItem;
              if (tooltip) {
                return (
                  <div className="div-heading">
                    {configItem.label}
                    <IntegryTooltip
                      tooltipText={configItem.tooltip}
                      placement="top"
                    >
                      <img
                        width={15}
                        height={15}
                        src={exlaimationIconOutline}
                        style={{
                          marginLeft: "5px",
                          marginTop: "-2px",
                          transform: "rotateZ(180deg)",
                        }}
                        alt="tooltip_heading"
                      />
                    </IntegryTooltip>
                  </div>
                );
              }
              if (allowSorting) {
                return (
                  <div className="div-heading">
                    {configItem.label}
                    <img
                      width={15}
                      height={15}
                      style={{
                        marginLeft: "5px",
                        marginTop: "-2px",
                        cursor: "pointer",
                        opacity: !fetching ? "1" : "0.4",
                      }}
                      src={
                        sorting &&
                        sorting[configItem.sortingIdentifier || configItem.id]
                          ? SortIconDesc
                          : SortIconDefault
                      }
                      className={
                        sorting &&
                        (sorting[
                          configItem.sortingIdentifier || configItem.id
                        ] &&
                          sorting[
                            configItem.sortingIdentifier || configItem.id
                          ]) === "asc"
                          ? "table-sort-icon-asc"
                          : ""
                      }
                      alt="sort_icon"
                      onClick={() => {
                        if (!fetching) {
                          handleOnSorting(
                            configItem.sortingIdentifier || configItem.id
                          );
                        }
                      }}
                      aria-hidden="true"
                    />
                  </div>
                );
              }
              return <div className="div-heading">{configItem.label}</div>;
            })}
          </div>
        )}
      </>
    );
  };

  const StepRow = ({
    step,
    stepConfig,
    onViewPayloadClick,
    rowStyle = {},
  }): JSX.Element => {
    return (
      <div className="div-row" style={rowStyle}>
        {stepConfig.map((configItem) => {
          const {
            id,
            subConfig,
            type = "text",
            tdStyle = {},
            truncate = false,
          } = configItem;
          if (type === "tag") {
            return (
              <div className="div-column">
                <Tag
                  label={
                    statusLabels[(step[id] || "").toLowerCase()] || step[id]
                  }
                  type="run"
                />
              </div>
            );
          }
          if (truncate) {
            return (
              <div className="div-column" style={tdStyle}>
                {step[id].length > 20 ? (
                  <IntegryTooltip tooltipText={step[id]} placement="bottom">
                    <div>
                      {step[id].substring(0, 3)}
                      <img
                        src={TruncateBullets}
                        style={{
                          width: "15px",
                          margin: "0 2px",
                          marginTop: "-3px",
                          objectFit: "none",
                        }}
                        alt="truncate_bullets"
                      />
                      {step[id].substring(step[id].length - 6)}
                    </div>
                  </IntegryTooltip>
                ) : (
                  `${step[id]}`
                )}
              </div>
            );
          }
          return (
            <div
              className="div-column"
              style={
                id === "networkCode"
                  ? {
                      ...tdStyle,
                      color:
                        networkCodeColors[
                          `${step[id] || "N/A"}`.substring(0, 1)
                        ] || "#333333",
                    }
                  : tdStyle
              }
            >
              {!subConfig && step[id]}
              {subConfig && (
                <div className="div-column__group">
                  {subConfig.map((subItem) => {
                    if (subItem.type === "img") {
                      return (
                        <img
                          src={step[id][subItem.id]}
                          alt={`ico_${step[id][subItem.id]}`}
                        />
                      );
                    }
                    return <span>{step[id][subItem.id]}</span>;
                  })}
                </div>
              )}
              {id === "networkCode" && (
                <PayloadButton
                  variant="outlined"
                  size="small"
                  // isDisabled={!step.hasPayload}
                  endIcon={
                    <img
                      src={PolygonDownIcon}
                      className={
                        step.id === expandedPayloadStepId ? "rotateIcon" : ""
                      }
                      alt={`view_payload_${step.id}`}
                      style={{
                        transform: `${
                          step.id === expandedPayloadStepId
                            ? "rotateZ(-180deg)"
                            : ""
                        }`,
                      }}
                    />
                  }
                  onClick={() => {
                    if (onViewPayloadClick) {
                      onViewPayloadClick(step.id);
                    }
                    track(configureOAuthAppEvents.VIEW_PAYLOAD.name, user, {
                      [configureOAuthAppEvents.VIEW_PAYLOAD.properties.APP_ID]:
                        appId,
                      [configureOAuthAppEvents.VIEW_PAYLOAD.properties
                        .BUNDLE_ID]: bundleId,
                      [configureOAuthAppEvents.VIEW_PAYLOAD.properties
                        .AUTH_STEP]: step.title || step.id || "",
                      [configureOAuthAppEvents.VIEW_PAYLOAD.properties
                        .AUTH_EVENT]:
                        expandedPayloadStepId === step.id
                          ? "Hide Payload"
                          : "View Payload",
                    });
                  }}
                >
                  {step.id === expandedPayloadStepId
                    ? "Hide Payload"
                    : "View Payload"}
                </PayloadButton>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const StepBody = (): ReactElement => {
    return (
      <>
        {data.length > 0 && (
          <div
            className="div-body"
            style={
              !fetching
                ? {}
                : {
                    opacity: "0.5",
                  }
            }
          >
            {data.map((step: any) => {
              let rowStyle = {};
              if (step.id === expandedPayloadStepId) {
                rowStyle = {
                  ...rowStyle,
                  borderBottom: "none",
                  borderRadius: "6px 6px 0 0px",
                };
              }
              return (
                <div
                  className="row-container"
                  style={step.rowStyle || {}}
                  key={step.id}
                >
                  <StepRow
                    stepConfig={config}
                    step={step}
                    onViewPayloadClick={(stepIdToShowPayload) => {
                      if (expandedPayloadStepId === step.id) {
                        setExpandedPayloadStepId("-1");
                        setViewStepPayloadData({});
                      } else {
                        setViewStepPayloadData(step.payload);
                        // fetchStepPayloadData(stepIdToShowPayload, step);
                        setExpandedPayloadStepId(stepIdToShowPayload);
                      }
                    }}
                    rowStyle={{ ...rowStyle, ...(step.rowDivStyle || {}) }}
                  />
                  {step.id === expandedPayloadStepId &&
                    Object.keys(viewStepPayloadData).length > 0 && (
                      <div className="payload-container">
                        <StepPayloadCompnent
                          payloadData={viewStepPayloadData}
                          hideObjectTab
                        />
                      </div>
                    )}
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="step-table-div">
      <StepHeader />
      {!fetching && data.length < 1 && (
        <div className="view-record-not-found__placeholder">
          <img src={NotFoundIcon} alt="record_not_found" />
          <p>No data found</p>
        </div>
      )}
      <StepBody />
    </div>
  );
};

export default StepsTable;
