/* eslint-disable no-useless-escape */
/* eslint-disable import/prefer-default-export */

export const validateKey = (
  key: string,
  variables: { key: string }[],
  isRequired = true
): string => {
  // validate for duplicate key
  const duplicateKey =
    variables?.filter((variable) => variable.key && variable.key === key)
      .length || 0;

  if (isRequired && !key) {
    return "Key is required";
  }
  if (duplicateKey > 1) {
    return "Key already exists";
  }
  const keyRegex = /^(?!.*[\{\}\.\s\n\r\t]).*$/;
  if (!key?.match(keyRegex)) {
    return "Invalid characters: {, }, ., spaces, newlines, carriage returns, tabs.";
  }
  return "";
};
