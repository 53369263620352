/* eslint-disable @typescript-eslint/no-explicit-any */
import http from "utils/http";

const useWhitelabelAPI = (): any => {
  const getWhitelabelConfig = (appId: string | number): any => {
    return http.get(
      `/api/authorization_types/white_label/detail/?app=${appId}`
    );
  };

  const saveWhitelabelConfig = (appId: string | number, payload: any): any => {
    return http.post(
      `/api/authorization_types/white_label/save/?app=${appId}&is_white_label_enhancement=${true}`,
      payload
    );
  };

  const getWhiteLabelSteps = (authTypeId: string | number): any => {
    return http.get(`/white_label_auth_steps/?auth_type=${authTypeId}`);
  };

  const enableDisableWhiteLabelAuth = (
    whiteLabelId: string | number,
    action: string
  ): any => {
    return http.post(
      `/api/authorization_types/white_label/${whiteLabelId}/${action}/?is_white_label_enhancement=${true}`
    );
  };

  return {
    getWhitelabelConfig,
    saveWhitelabelConfig,
    getWhiteLabelSteps,
    enableDisableWhiteLabelAuth,
  };
};
export default useWhitelabelAPI;
