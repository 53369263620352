/* eslint-disable react/no-unused-prop-types */
import React, { ReactElement } from "react";
import { Field, FastField } from "formik";
import { FormFeedback } from "reactstrap";

const renderField = (props, ref) => {
  return (fieldProps) => {
    const {
      onChange,
      onBlur,
      name,
      className,
      defaultValue,
      maxLength = "",
      showCounter = false,
      disableNextLine = false,
      ...rest
    } = props;

    const { meta, field, form } = fieldProps;
    const { value } = field;
    const { setFieldValue, setFieldTouched } = form;
    const { touched, error } = meta;
    const validationClass = touched && error ? " is-invalid" : "";

    return (
      <div style={showCounter ? { position: "relative" } : {}}>
        <textarea
          {...rest}
          ref={ref}
          value={value || defaultValue || ""}
          onChange={(e) => {
            if (disableNextLine) {
              e.target.value = e.target.value.replace(/[\r\n]/g, "");
            }
            setFieldValue(props.name, e.target.value);
            if (onChange) {
              onChange(e.target.value, e);
            }
          }}
          onBlur={(e) => {
            setFieldTouched(props.name);
            if (onBlur) {
              onBlur(e);
            }
          }}
          onKeyPress={(e) => {
            if (e.keyCode === 13) {
              e.preventDefault();
            }
          }}
          className={`form-control${validationClass} ${className}`}
          maxLength={maxLength}
        />
        {showCounter && maxLength && typeof value !== "undefined" && (
          <div className="textarea-counter">
            {value?.length || 0} / {maxLength}
          </div>
        )}
        {error && <FormFeedback>{error}</FormFeedback>}
      </div>
    );
  };
};

interface TextareaProps extends React.HTMLProps<HTMLInputElement> {
  isFastField?: boolean;
  name: string;
  maxLength?: number;
  showCounter?: boolean;
  validate?(value): string;
  disableNextLine?: boolean;
}

const Textarea = React.forwardRef((props: TextareaProps, ref): ReactElement => {
  const { isFastField, name, validate } = props;
  return isFastField ? (
    <FastField name={name} validate={validate}>
      {renderField(props, ref)}
    </FastField>
  ) : (
    <Field name={name} validate={validate}>
      {renderField(props, ref)}
    </Field>
  );
});

Textarea.defaultProps = {
  isFastField: false,
  validate: () => "",
  maxLength: undefined,
  showCounter: false,
  disableNextLine: false,
};

export default Textarea;
