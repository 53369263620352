import sky from "../images/sky.svg";
import spaceman from "../images/spaceman.svg";
import { Link } from "react-router-dom";

import { useFetchUserQuery } from "utils/user/user-api";

const NoMatchFound = () => {
  const { data: user } = useFetchUserQuery();
  return (
    <div id="no-match-found">
      <div className="sky_wrapper">
        <img
          src={sky}
          alt="Sky Illustration"
          rel="preload"
          className="img-fluid sky"
        />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h1 className="heading">404</h1>
            <p className="subheading">
              You don’t have access to this link or this link is invalid.
              {user?.accounts?.length > 1 && (
                <div className="align-center">Try switching workspace.</div>
              )}
            </p>

            <div
              className="btn btn-lg btn-secondary-dark"
              onClick={() => {
                window.open("/wapp", "_self");
              }}
            >
              Go back to Flows
            </div>
            {/* <Link to="/" className="btn btn-lg btn-outline-secondary-dark">
            Help  
          </Link> */}
          </div>
          <div className="col-md-6">
            <img
              className="img-fluid spaceman"
              src={spaceman}
              alt="Spaceman Illustration"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoMatchFound;
