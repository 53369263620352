import { createApi } from "@reduxjs/toolkit/query/react";
import { ErrorNotificationInterface } from "features/account-settings/services/interface";
import config from "./config";
import { integryBaseQuery } from "./http";

export const accountsApi = createApi({
  reducerPath: "accountsApi",
  baseQuery: integryBaseQuery({
    baseUrl: config.apiBaseURL || "",
  }),
  tagTypes: ["accountSettings"],
  endpoints(build) {
    return {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // TODO: make interface for notification setting and use it here.
      getNotificationSettings: build.query<ErrorNotificationInterface, void>({
        query: () => ({
          url: "/accounts/me/notification-settings/",
          method: "get",
        }),
        providesTags: ["accountSettings"],
      }),
      updateNotificationSettings: build.mutation({
        query(data) {
          return {
            url: "/accounts/me/notification-settings/",
            method: "PUT",
            data,
          };
        },

        invalidatesTags: ["accountSettings"],
      }),
      sendSampleNotification: build.mutation({
        query(url: string) {
          return {
            url: "/send_sample_error_webhook_call/",
            method: "POST",
            data: { url },
          };
        },
      }),
    };
  },
});

export const {
  useGetNotificationSettingsQuery,
  useUpdateNotificationSettingsMutation,
  useSendSampleNotificationMutation,
} = accountsApi;
