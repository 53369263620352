import { Container } from "reactstrap";
import { connect } from "react-redux";
import bulb from "images/apps-v4/bulb-red.svg";
import Header from "./header-v3/main-navigation";
import Footer from "./footer";

const DashboardWrapper = (props) => {
  const {
    user,
    className,
    alertBar = false,
    children,
    alertText,
    hideWorkspaceDropdown = false,
  } = props;

  return (
    <div className={`primary-container-wrapper ${className}`}>
      <Header user={user} hideWorkspaceDropdown={hideWorkspaceDropdown} />
      {window?.location?.pathname?.includes("/edit") && alertBar && alertText && (
        <div className="app-lock-alert-bar">
          <div className="d-flex align-items-center">
            <img src={bulb} alt="bulb" />
            <div className="app-lock-alert-bar_text">{alertText}</div>
          </div>
        </div>
      )}
      <Container>
        {children}
        <Footer hideFooter />
      </Container>
    </div>
  );
};

export default connect((state) => ({
  alertText: state?.appBuilder?.appLockAlertText,
}))(DashboardWrapper);
