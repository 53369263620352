import { ReactElement } from "react";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";

interface ButtonProps {
  title: string | ReactElement;
  onClick: () => void;
  className: string;
  isLoading?: boolean;
  disabled?: boolean;
  icon?: string;
  tooltip?: string;
  type?: string;
  rightIcon?: boolean;
  hoverIcon?: string;
  tooltipPlacement?: "top" | "left" | "right" | "bottom";
}

const Button = (props: ButtonProps): ReactElement => {
  const {
    title,
    onClick,
    className,
    isLoading,
    disabled,
    icon,
    tooltip,
    type = "button",
    rightIcon = false,
    hoverIcon,
    tooltipPlacement,
    ...rest
  } = props;

  return (
    <>
      <IntegryTooltip
        hideToolTip={!tooltip}
        placement={tooltipPlacement || "bottom"}
        tooltipText={tooltip}
      >
        <button
          type={type.toLowerCase() === "submit" ? "submit" : "button"}
          className={`dot-loader-container ${className}`}
          onClick={(e) => {
            e.stopPropagation();
            if (onClick) {
              onClick();
            }
          }}
          disabled={disabled}
          {...rest}
        >
          {rightIcon ? (
            <>
              {isLoading ? (
                <div className="dot-loader" />
              ) : (
                <>
                  {title}
                  {icon && (
                    <>
                      <img
                        className={`icon ${hoverIcon ? "hide-on-hover" : ""}`}
                        src={icon}
                        style={{ marginLeft: "4px" }}
                        alt="icon"
                      />
                      {hoverIcon && (
                        <img
                          src={hoverIcon}
                          style={{ display: "none" }}
                          className="hover-icon"
                          alt="icon"
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {icon && (
                <>
                  <img
                    className={`icon ${hoverIcon ? "hide-on-hover" : ""}`}
                    src={icon}
                    alt="icon"
                  />
                  {hoverIcon && (
                    <img
                      src={hoverIcon}
                      style={{ display: "none" }}
                      className="hover-icon"
                      alt="icon"
                    />
                  )}
                </>
              )}
              {isLoading ? <div className="dot-loader" /> : title}
            </>
          )}
        </button>
      </IntegryTooltip>
    </>
  );
};

export default Button;
