/* eslint-disable @typescript-eslint/no-explicit-any */
// Redux toolkit Query to fetch actions. URL: https://beta.integry.io/api/v1/apps/209/templates/

import { createApi } from "@reduxjs/toolkit/query/react";
import { integryBaseQuery } from "utils/http";
import config from "utils/config";
import {
  ListedTemplate,
  Template,
} from "features/templates/template-builder-v7/services/template-builder-interface";

export const sourceFlowListing = createApi({
  reducerPath: "sourceFlowListing",
  baseQuery: integryBaseQuery({
    baseUrl: config.apiBaseURL || "",
  }),
  tagTypes: ["sourceFlowListing"],
  endpoints: (builder) => ({
    getSourceFlows: builder.query<
      ListedTemplate[],
      {
        brandingAppId: string | number;
        workspaceAppId: string | number;
      }
    >({
      query: ({ brandingAppId, workspaceAppId }) => ({
        url: `/api/v1/templates/?version_gte=7&app=${workspaceAppId}&page=1&search=&ordering=-last_modified&only_include_limited_fields=true&branding_app_id=${brandingAppId}`,
        method: "get",
      }),
      transformResponse: (response: any) => {
        return response.results;
      },
    }),
    getSourceFlowById: builder.query<
      Template,
      {
        id: number;
      }
    >({
      query: ({ id }) => ({
        url: `/api/v2/templates/${id}/`,
        method: "get",
      }),
    }),
  }),
});

export const { useGetSourceFlowsQuery, useGetSourceFlowByIdQuery } =
  sourceFlowListing;
