import { ReactElement } from "react";

import { ICheckboxProps } from "components/integry-design-system/molecules/checkbox-selection-v2";
import CheckboxDropdown from "components/integry-design-system/molecules/dropdowns/checkbox-dropdown";
import RunStatusIndicator from "../../integration-runs/components/run-status";

// eslint-disable-next-line no-shadow
export enum Status {
  OK = "OK",
  ERROR = "ERROR",
}

interface IProps {
  runStatuses: Array<string>;
  setRunStatuses: (value: Array<string>) => void;
  completedStatusNetworkCode: string;
  setCompletedStatusNetworkCode: (value: string) => void;
}

const StatusFilterDropdown = ({
  runStatuses,
  setRunStatuses,
  completedStatusNetworkCode,
  setCompletedStatusNetworkCode,
}: IProps): ReactElement => {
  const getRunStatusOptions = (): Array<ICheckboxProps> => {
    const lastRunStatusOptions = [] as Array<ICheckboxProps>;
    lastRunStatusOptions.push(
      {
        render: (
          <RunStatusIndicator
            statusType={Status.OK}
            style={{
              marginTop: "2px",
            }}
          />
        ),
        subRender: (
          <input
            type="text"
            defaultValue={completedStatusNetworkCode}
            className="network-code-filter"
            placeholder="Network Code"
            onChange={(e) => {
              setCompletedStatusNetworkCode(e.target.value);
            }}
          />
        ),
        value: Status.OK,
        label: "OK",
      },
      {
        render: (
          <RunStatusIndicator
            statusType={Status.ERROR}
            style={{
              marginTop: "2px",
            }}
          />
        ),
        value: Status.ERROR,
        label: "Error",
      }
    );
    return lastRunStatusOptions;
  };

  return (
    <CheckboxDropdown
      options={getRunStatusOptions()}
      value={runStatuses}
      onChange={setRunStatuses}
      placeholder="Status"
      tooltipText="Filter by run status"
    />
  );
};

export default StatusFilterDropdown;
