import { CSSProperties, HTMLAttributes } from "react";
import "./styles.scss";

interface IAvatar {
  iconSrc: string;
  title: string;
  label?: string;
}

interface IAttribute {
  label: string | JSX.Element;
  render: JSX.Element;
  hide?: boolean;
}

export interface ISubInfo {
  label: string;
  attributes: Array<IAttribute>;
  hide?: boolean;
}

export interface IProps extends HTMLAttributes<HTMLDivElement> {
  avatar?: IAvatar;
  renderAvatar?: () => JSX.Element;
  customStyle?: CSSProperties;
  attributes?: Array<IAttribute>;
  subInfo?: Array<ISubInfo>;
}

const GroupedInfoHeader = ({
  customStyle = {},
  ...props
}: IProps): JSX.Element => {
  const { avatar, renderAvatar, attributes, subInfo } = props;
  return (
    <div className="integry-grouped-info--header" style={{ ...customStyle }}>
      <div className="integry-grouped-info--heading">
        <div className="integry-grouped-info--heading_left-content">
          <div className="integry-grouped-info--profile">
            {avatar && (
              <>
                {avatar?.iconSrc && (
                  <img
                    className="integry-grouped-info--avatar"
                    src={avatar.iconSrc}
                    alt="logo_avatar"
                  />
                )}
                <div className="integry-grouped-info--profile__meta">
                  <span className="integry-grouped-info--profile__label">
                    {avatar.label}
                  </span>
                  {avatar?.title && (
                    <p className="integry-grouped-info--profile__title">
                      {avatar.title}
                    </p>
                  )}
                </div>
              </>
            )}
            {renderAvatar && <>{renderAvatar()}</>}
          </div>
        </div>
        <div className="integry-grouped-info--heading_right-content">
          <div className="integry-grouped-info--meta">
            {(attributes || []).map((attribute) => {
              return (
                <>
                  <div className="attribute--seperator" />
                  <div className="integry-grouped-info--meta__attribute">
                    <p className="attribute--title">{attribute.label}</p>
                    <div className="attribute--render">{attribute.render}</div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      <div className="integry-grouped-info--submeta">
        {(subInfo || []).map((info, index) => {
          return (
            <div className="integry-grouped-info--meta__container">
              <h3 className="integry-grouped-info--meta__heading">
                {info.label}
              </h3>
              <div className="integry-grouped-info--meta__content">
                {(info.attributes || []).map((attribute, i) => {
                  if (attribute.hide) return null;
                  return (
                    <>
                      <div className="integry-grouped-info--meta__attribute">
                        <p className="attribute--title">{attribute.label}</p>
                        <div className="attribute--render">
                          {attribute.render}
                        </div>
                      </div>
                      {i < (info.attributes || []).length - 1 && (
                        <div className="attribute--seperator" />
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GroupedInfoHeader;
