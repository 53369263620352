import equal from "deep-equal";
import { ActivityType } from "enums/activity-types";

export const hasChildren = (tree, childrenName) => {
  if (tree && tree[childrenName] && tree[childrenName].length > 0) {
    return true;
  }
  return false;
};

export const getNode = (tree, childrenName, path) => {
  //console.log('getNode', tree, childrenName, path);
  if (path.length === 0) {
    return tree;
  } else {
    const [idx, ...rest] = path;
    if (tree && tree[childrenName] && tree[childrenName].length > idx) {
      return getNode(tree[childrenName][idx], childrenName, rest);
    } else {
      return null;
    }
  }
};

export const getConditionSteps = (tree, childrenName, path) => {
  const response = [];
  const parentPath = path.slice(0, path.length - 1);
  const parentTree = getNode(tree, childrenName, parentPath); // moving one level up to get parent tree so that we can get all condition steps
  try {
    let currentNodeIndex = path[path.length - 1];
    let currentStep = parentTree?.[childrenName]?.[currentNodeIndex];

    if (currentStep && currentStep.activity_type === ActivityType.Condition_If)
      do {
        response.push(currentStep);
        currentNodeIndex += 1; // move to the next step
        currentStep = parentTree?.[childrenName]?.[currentNodeIndex];
      } while (
        currentStep &&
        (currentStep.activity_type === ActivityType.Condition_Else_If ||
          currentStep.activity_type === ActivityType.Condition_Else)
      );
  } catch (error) {
    console.error("Error in getConditionSteps:", error);
  }
  return response;
};

export const getNodeByMachineName = (steps, machineName, withPath = false) => {
  const flatTree = withPath
    ? flattenWithPath(steps, "steps", [])
    : flatten(steps, "steps");
  for (let i = 0; i < flatTree.length; i++) {
    if (flatTree[i].machine_name === machineName) {
      return flatTree[i];
    }
  }
  return null;
};

export const getStepByAttribute = ({
  steps,
  attributeName,
  attributeValue,
  withPath = false,
}) => {
  const flatTree = withPath
    ? flattenWithPath(steps, "steps", [])
    : flatten(steps, "steps");
  for (let i = 0; i < flatTree.length; i++) {
    if (flatTree[i][attributeName] === attributeValue) {
      return flatTree[i];
    }
  }
  return null;
};

export const addNode = (tree, childrenName, path, callback) => {
  //console.log('addNode', tree, childrenName, path);

  if (path.length === 0) {
    return {
      ...tree,
      [childrenName]: [...tree[childrenName], callback(tree[childrenName])],
    };
  } else {
    const [idx, ...rest] = path;
    return {
      ...tree,
      [childrenName]: [
        ...tree[childrenName].slice(0, idx),
        addNode(tree[childrenName][idx], childrenName, rest, callback),
        ...tree[childrenName].slice(idx + 1),
      ],
    };
  }
};

export const removeNode = (tree, childrenName, path) => {
  //console.log('removeNode', tree, childrenName, path);

  if (path.length === 1) {
    return tree
      ? tree[childrenName]
        ? {
            ...tree,
            [childrenName]: [
              ...tree[childrenName].slice(0, path[0]),
              ...tree[childrenName].slice(path[0] + 1),
            ],
          }
        : { ...tree, [childrenName]: [] }
      : {};
  } else if (path.length > 1) {
    const [idx, ...rest] = path;
    return tree
      ? tree[childrenName]
        ? {
            ...tree,
            [childrenName]: [
              ...tree[childrenName].slice(0, idx),
              removeNode(tree[childrenName][idx], childrenName, rest),
              ...tree[childrenName].slice(idx + 1),
            ],
          }
        : {
            ...tree,
            [childrenName]: [],
          }
      : {};
  } else {
    console.log(
      "removeNode must be called with a path having length 1 or great. instead called with: ",
      path
    );
  }
};

export const replaceNode = (tree, childrenName, path, newNode) => {
  //console.log('replaceNode. tree:', tree, ', path:', path, 'newNode:', newNode);

  if (!tree || !tree[childrenName]) {
    //console.log('not having a steps obj', tree);
    return {};
  }

  if (path.length === 1) {
    return {
      ...tree,
      [childrenName]: [
        ...tree[childrenName].slice(0, path[0]),
        newNode,
        ...tree[childrenName].slice(path[0] + 1),
      ],
    };
  } else if (path.length > 1) {
    const [idx, ...rest] = path;
    return {
      ...tree,
      [childrenName]: [
        ...tree[childrenName].slice(0, idx),
        replaceNode(tree[childrenName][idx], childrenName, rest, newNode),
        ...tree[childrenName].slice(idx + 1),
      ],
    };
  } else {
    console.log(
      "replaceNode must be called with a path having length 1 or great. instead called with: ",
      path
    );
  }
};

export const flatten = (tree, childrenName, isRoot = true) => {
  const response = [];
  if (isRoot) {
    tree[childrenName].forEach((child) => {
      const subResponse = flatten(child, childrenName, false);
      response.push(...subResponse);
    });
  } else {
    const treeCopy = Object.assign({}, tree);
    delete treeCopy[childrenName];
    response.push(treeCopy);
    if (tree && tree[childrenName])
      tree[childrenName].forEach((child) => {
        const subResponse = flatten(child, childrenName, false);
        response.push(...subResponse);
      });
  }
  return response;
};

export const findUnsavedSteps = (tree, path = []) => {
  const unsavedStepPaths = [];
  tree.forEach((steps, index) => {
    if (!steps.id) {
      const stepPath = [...path, index];
      unsavedStepPaths.push(stepPath);
    }
    const subResponse = findUnsavedSteps(steps.steps, [...path, index]);
    unsavedStepPaths.push(...subResponse);
  });
  return unsavedStepPaths;
};

export const createMirrorTree = (
  originalTree,
  originalChildrenName,
  mirrorChildrenName,
  callback,
  isRoot = false
) => {
  //console.log('createMirrorTree', originalTree);
  if (isRoot) {
    return {
      [mirrorChildrenName]: originalTree[originalChildrenName].map((subtree) =>
        createMirrorTree(
          subtree,
          originalChildrenName,
          mirrorChildrenName,
          callback
        )
      ),
    };
  } else {
    return {
      [mirrorChildrenName]: originalTree[originalChildrenName].map((subtree) =>
        createMirrorTree(
          subtree,
          originalChildrenName,
          mirrorChildrenName,
          callback
        )
      ),
    };
  }
};

export const insertNode = (tree, childrenName, path, callback) => {
  if (path.length === 1) {
    return {
      ...tree,
      [childrenName]: [
        ...tree[childrenName].slice(0, path[0] + 1),
        callback(tree[childrenName]),
        ...tree[childrenName].slice(path[0] + 1),
      ],
    };
  } else if (path.length > 1) {
    const [idx, ...rest] = path;
    return {
      ...tree,
      [childrenName]: [
        ...tree[childrenName].slice(0, idx),
        insertNode(tree[childrenName][idx], childrenName, rest, callback),
        ...tree[childrenName].slice(idx + 1),
      ],
    };
  } else {
    console.log(
      "replaceNode must be called with a path having length 1 or great. instead called with: ",
      path
    );
  }
};

export const insertMultipleNodes = (tree, childrenName, path, callback) => {
  // insert multiple nodes at given path
  if (path.length === 1) {
    const stepsToBeInserted = callback(tree[childrenName]);
    return {
      ...tree,
      [childrenName]: [
        ...tree[childrenName].slice(0, path[0] + 1),
        ...(Array.isArray(stepsToBeInserted)
          ? stepsToBeInserted
          : [stepsToBeInserted]),
        ...tree[childrenName].slice(path[0] + 1),
      ],
    };
  } else if (path.length > 1) {
    const [idx, ...rest] = path;
    return {
      ...tree,
      [childrenName]: [
        ...tree[childrenName].slice(0, idx),
        insertMultipleNodes(
          tree[childrenName][idx],
          childrenName,
          rest,
          callback
        ),
        ...tree[childrenName].slice(idx + 1),
      ],
    };
  } else {
    console.log(
      "replaceNode must be called with a path having length 1 or great. instead called with: ",
      path
    );
  }
};

export const insertNodeBeforeNode = (tree, childrenName, path, callback) => {
  if (path.length === 1) {
    return {
      ...tree,
      [childrenName]: [
        ...tree[childrenName].slice(0, path[0]),
        callback(tree[childrenName]),
        ...tree[childrenName].slice(path[0]),
      ],
    };
  } else if (path.length > 1) {
    const [idx, ...rest] = path;
    return {
      ...tree,
      [childrenName]: [
        ...tree[childrenName].slice(0, idx),
        insertNodeBeforeNode(
          tree[childrenName][idx],
          childrenName,
          rest,
          callback
        ),
        ...tree[childrenName].slice(idx + 1),
      ],
    };
  } else {
    console.log(
      "replaceNode must be called with a path having length 1 or great. instead called with: ",
      path
    );
  }
};

export const insertMultipleNodesBeforeNode = (
  tree,
  childrenName,
  path,
  callback
) => {
  if (path.length === 1) {
    const stepsToBeInserted = callback(tree[childrenName]);
    return {
      ...tree,
      [childrenName]: [
        ...tree[childrenName].slice(0, path[0]),
        ...(Array.isArray(stepsToBeInserted)
          ? stepsToBeInserted
          : [stepsToBeInserted]),
        ...tree[childrenName].slice(path[0]),
      ],
    };
  } else if (path.length > 1) {
    const [idx, ...rest] = path;
    return {
      ...tree,
      [childrenName]: [
        ...tree[childrenName].slice(0, idx),
        insertMultipleNodesBeforeNode(
          tree[childrenName][idx],
          childrenName,
          rest,
          callback
        ),
        ...tree[childrenName].slice(idx + 1),
      ],
    };
  } else {
    console.log(
      "replaceNode must be called with a path having length 1 or great. instead called with: ",
      path
    );
  }
};

export const getNodeWithParent = (tree, keyPath, path, source, callback) => {
  //console.log('getNodeWithParent', source, tree, keyPath, path);
  tree.forEach((step, index, arr) => {
    if ([...path, index].join("") === keyPath.join("")) {
      return callback(step, index, arr, path);
    }
    if (step.steps) {
      return getNodeWithParent(
        step.steps,
        keyPath,
        [...path, index],
        source,
        callback
      );
    }
  });
};

export const replaceNodeSteps = (tree, childrenName, path, newNode) => {
  //console.log('replaceNode. tree:', tree, ', path:', path, 'newNode:', newNode);

  if (path.length === 0) {
    return {
      ...tree,
      [childrenName]: newNode,
    };
  } else if (path.length > 0) {
    const [idx, ...rest] = path;
    return {
      ...tree,
      [childrenName]: [
        ...tree[childrenName].slice(0, idx),
        replaceNodeSteps(tree[childrenName][idx], childrenName, rest, newNode),
        ...tree[childrenName].slice(idx + 1),
      ],
    };
  } else {
    console.log(
      "replaceNode must be called with a path having length 1 or great. instead called with: ",
      path
    );
  }
};

export const validateTemplateTree = (tree, path = []) => {
  if (!tree) {
    console.error("invalid tree", tree, path);
  } else {
    tree.steps.map((step, index) => {
      if (!step) {
        console.error("invalid in map", tree, step, path);
      }
      return validateTemplateTree(step, [...path, index]);
    });
  }
};

const createPathPrefixForTags = (path, root = true) => {
  if (path.length === 0) {
    return ``;
  } else {
    const [idx, ...rest] = path;
    return `${root ? "{" : ""}steps.${idx}.${createPathPrefixForTags(
      rest,
      false
    )}`;
  }
};

export const flattenIdAndPath = (
  tree,
  childrenName,
  path,
  isRoot = true,
  response = {},
  type
) => {
  if (isRoot) {
    tree[childrenName].forEach((child, index) => {
      const subResponse = flattenIdAndPath(
        child,
        childrenName,
        [...path, index],
        false,
        response,
        type
      );
      response = { ...response, ...subResponse };
    });
  } else {
    const treeCopy = Object.assign({}, tree);
    delete treeCopy[childrenName];
    response[treeCopy.step_uniqeId] = response[treeCopy.step_uniqeId] || {};
    response[treeCopy.step_uniqeId][type] = {
      path: path,
      tag: createPathPrefixForTags(path),
    };
    tree[childrenName].forEach((child, index) => {
      const subResponse = flattenIdAndPath(
        child,
        childrenName,
        [...path, index],
        false,
        response,
        type
      );
      response = { ...response, ...subResponse };
    });
  }
  return response;
};

export const flattenWithPath = (tree, childrenName, path, isRoot = true) => {
  const response = [];
  if (isRoot) {
    tree[childrenName].forEach((child, index) => {
      const currentPath = [...path, index];
      const subResponse = flattenWithPath(
        { ...child, path: currentPath },
        childrenName,
        currentPath,
        false
      );
      response.push(...subResponse);
    });
  } else {
    const treeCopy = Object.assign({}, tree);
    delete treeCopy[childrenName];
    response.push(treeCopy);
    tree[childrenName].forEach((child, index) => {
      const currentPath = [...path, index];
      const subResponse = flattenWithPath(
        { ...child, path: currentPath },
        childrenName,
        currentPath,
        false
      );
      response.push(...subResponse);
    });
  }
  return response;
};
export const replaceOldTagWithId = (treeIds) => {
  let oldTagKeys = {};
  Object.keys(treeIds).forEach((key) => {
    const oldTag = treeIds[key].old.tag;
    oldTagKeys[oldTag] = { ...treeIds[key], id: key };
  });
  return oldTagKeys;
};

export const updateTagAfterDragNDrop = (
  step,
  tagsMappings,
  path,
  isRoot = false
) => {
  let newStep = step;
  if (!isRoot) {
    if (newStep.app_name === "Conditions" && newStep.condition_obj) {
      let { conditions } = newStep.condition_obj;
      newStep.condition_obj.conditions = conditions.map((condition) => {
        let { operand_1, operand_2 } = condition;
        condition.operand_1 = createUpdatedTagAfterDragNDrop(
          tagsMappings,
          operand_1,
          path
        );
        condition.operand_2 = createUpdatedTagAfterDragNDrop(
          tagsMappings,
          operand_2,
          path
        );
        return condition;
      });
    } else if (newStep.hasOwnProperty("fields")) {
      newStep.fields = newStep.fields.map((field) => {
        field.default_value = createUpdatedTagAfterDragNDrop(
          tagsMappings,
          field.default_value,
          path
        );
        return field;
      });
    }
  }

  newStep.steps = newStep.steps.map((step, index) =>
    updateTagAfterDragNDrop(step, tagsMappings, [...path, index])
  );

  return newStep;
};

const createUpdatedTagAfterDragNDrop = (tagsMappings, defaultValues, path) => {
  ////console.log('andpath ', defaultValues);
  let re = /({[^{}"]*?})/g;
  if (defaultValues && defaultValues.length > 0) {
    defaultValues = defaultValues.split(re).filter(Boolean); // split muiltiple tags from default value
    defaultValues = defaultValues.map((defaultValue) => {
      let lastIndex = defaultValue.lastIndexOf("steps.");
      let prefixIndex = defaultValue.indexOf(".", lastIndex + 6); //get index of '.' after last 'step.' occurance.
      let oldTagPrefix = defaultValue.substring(0, prefixIndex + 1);
      if (tagsMappings[oldTagPrefix]) {
        ////console.log('andpath ', tagsMappings[oldTagPrefix], oldTagPrefix);
        const currentTagMapping = tagsMappings[oldTagPrefix];

        const isValid = isTagAccessableForStep(
          path,
          currentTagMapping.new.path
        );
        const newTag = isValid
          ? defaultValue.replace(oldTagPrefix, currentTagMapping.new.tag)
          : "";
        return newTag;
      }
      return defaultValue;
    });
    return defaultValues.join("");
  }
  return defaultValues;
};

const isTagAccessableForStep = (stepPath, tagPath, root = true) => {
  //console.log('andpath pp', stepPath, tagPath, root);
  const [tagParent, ...remainingTagPath] = tagPath;
  const [stepParent, ...remainingStepPath] = stepPath;
  if (root && stepPath.join() === tagPath.join()) {
    //step is accessing itself.
    return true;
  } else if (tagParent === stepParent) {
    // step and tag has same parent so can't decide if tag is valid or not.
    if (remainingTagPath.length > 0 && remainingStepPath.length > 0) {
      return isTagAccessableForStep(remainingStepPath, remainingTagPath, false); // test again with remaining path.
    } else if (remainingTagPath.length === 0 && remainingStepPath.length > 0) {
      return true; // step is refrencing it parent so tag is valid
    } else if (remainingTagPath.length > 0 && remainingStepPath.length === 0) {
      return false; //parent is refrencing its child so tag is invalid
    } else return true;
  } else if (tagParent < stepParent) {
    ////console.log('andpath', 'tagPath[0] < stepPath[0]');
    return true;
  } else {
    ////console.log('andpath', 'tagPath[0] > stepPath[0]');
    return false;
  }
};

export const isTemplateStepEqual = (preveiousStep, currentStep) => {
  const prevStepData = {
    title: preveiousStep.title,
    is_visible: preveiousStep.is_visible,
    step_obj_ref: preveiousStep.step_obj_ref,
    button_text: preveiousStep.button_text,
    summary: preveiousStep.summary,
    auth_description: preveiousStep.auth_description,
    comments: preveiousStep.comments,
    machine_name: preveiousStep.machine_name,
    is_custom_machine_name: preveiousStep.is_custom_machine_name,
    step_condition: preveiousStep.step_condition,
    fields: Array.isArray(preveiousStep.fields)
      ? preveiousStep.fields.map((f) => ({
          default_value: f.defaultValue,
          data: f.data,
        }))
      : [],
    condition_obj: preveiousStep.condition_obj,
    authorization_id: preveiousStep.authorization_id,
    authorization: preveiousStep.authorization,
    variables: preveiousStep.variables,
    activity_id: preveiousStep.activity_id,
    authorization_type_id: preveiousStep.authorization_type_id,
    authorization_type: preveiousStep.authorization_type,
  };
  const currentStepData = {
    title: currentStep.title,
    is_visible: currentStep.is_visible,
    step_obj_ref: currentStep.step_obj_ref,
    button_text: currentStep.button_text,
    summary: currentStep.summary,
    auth_description: currentStep.auth_description,
    comments: currentStep.comments,
    machine_name: currentStep.machine_name,
    is_custom_machine_name: currentStep.is_custom_machine_name,
    step_condition: currentStep.step_condition,
    fields: Array.isArray(currentStep.fields)
      ? preveiousStep.fields.map((f) => ({
          default_value: f.defaultValue,
          data: f.data,
        }))
      : [],
    condition_obj: currentStep.condition_obj,
    authorization_id: currentStep.authorization_id,
    authorization: currentStep.authorization,
    variables: currentStep.variables,
    activity_id: currentStep.activity_id,
    authorization_type_id: currentStep.authorization_type_id,
    authorization_type: currentStep.authorization_type,
  };
  return equal(prevStepData, currentStepData);
};

export const getNodeByIds = (
  tree,
  childrenName,
  path,
  ids,
  isRoot = true,
  response = [],
  completeTree = {}
) => {
  if (isRoot) {
    tree[childrenName].forEach((child, index) => {
      const subResponse = getNodeByIds(
        child,
        childrenName,
        [...path, index],
        ids,
        false,
        response,
        tree
      );
      response = subResponse;
    });
  } else {
    const treeCopy = Object.assign({}, tree);
    if (treeCopy.id) {
      // if steps is saved then we dont want to saved its child
      // but if step is not saved, its child needs to saved too.
      delete treeCopy[childrenName];
    }
    if (!treeCopy.parent_id) {
      // if parent is not set of a setp, add it
      treeCopy.parent_id = getStepParentId(completeTree, path);
    }

    if (ids.indexOf(treeCopy.step_uniqeId) > -1) {
      response = [
        ...response,
        {
          ...treeCopy,
          weight: path[path.length - 1] + 1,
        },
      ];
      /* response[treeCopy.step_uniqeId] = {
        ...tree,
        weight: path[path.length - 1] + 1
      }; */
    }

    tree[childrenName].forEach((child, index) => {
      const subResponse = getNodeByIds(
        child,
        childrenName,
        [...path, index],
        ids,
        false,
        response,
        completeTree
      );
      response = subResponse;
    });
  }
  return response;
};

export const getStepParentId = (tree, path) => {
  const parentPath = [...path];
  parentPath.splice(-1, 1); //remove last array element
  if (path.length === 1) {
    // node is at firest level, return root node Id.
    return tree?.meta?.root_node_id;
  } else {
    const parentNode = getNode(tree, "steps", parentPath);
    return parentNode ? parentNode.id : null;
  }
};

const replaceTag = (tags, oldTag, newTag) => {
  let re = /({[^{}"]*?})/g;
  if (tags && tags.length > 0) {
    tags = tags.split(re).filter(Boolean); // split muiltiple tags from default value
    tags = tags.map((tag) => {
      let machineName = tag.split(".")[0].replace("{", "");
      if (machineName === oldTag) {
        return tag.replace(oldTag, newTag);
      }
      return tag;
    });
    return tags.join("");
  }
  return tags;
};
export const updateTagAfterUpdatingMachineName = (
  step,
  oldName,
  newName,
  isRoot = false
) => {
  let newStep = step;
  if (!isRoot) {
    if (newStep.app_name === "Conditions" && newStep.condition_obj) {
      let { conditions } = newStep.condition_obj;
      newStep.condition_obj.conditions = conditions.map((condition) => {
        let { operand_1, operand_2 } = condition;
        condition.operand_1 = replaceTag(operand_1, oldName, newName);
        condition.operand_2 = replaceTag(operand_2, oldName, newName);
        return condition;
      });
    } else if (newStep.activity_type === "VARIABLE") {
      let { variables } = newStep;
      newStep.variables = variables.map((variable) => {
        let { name, value } = variable;
        variable.name = replaceTag(name, oldName, newName);
        variable.value = replaceTag(value, oldName, newName);
        return variable;
      });
    } else if (newStep.hasOwnProperty("fields")) {
      if (newStep.activity_type === "TRIGGER" && newStep.step_condition_obj) {
        const { operand_1, operand_2 } = newStep.step_condition_obj;
        newStep.step_condition_obj.operand_1 = replaceTag(
          operand_1,
          oldName,
          newName
        );
        newStep.step_condition_obj.operand_2 = replaceTag(
          operand_2,
          oldName,
          newName
        );
      }
      newStep.fields = newStep.fields.map((field) => {
        field.default_value = replaceTag(field.default_value, oldName, newName);
        return field;
      });
    }
  }

  newStep.steps = newStep.steps.map((step, index) =>
    updateTagAfterUpdatingMachineName(step, oldName, newName)
  );

  return newStep;
};

export const getStepsWeight = (
  tree,
  childrenName,
  path = [],
  isRoot = true,
  response = {}
) => {
  if (isRoot) {
    tree[childrenName].forEach((child, index) => {
      const subResponse = getStepsWeight(
        child,
        childrenName,
        [...path, index],
        false,
        response
      );
      response = { ...response, ...subResponse };
    });
  } else {
    if (tree.id) {
      response[tree.id.toString()] = path[path.length - 1] + 1;
    }
    tree[childrenName].forEach((child, index) => {
      const subResponse = getStepsWeight(
        child,
        childrenName,
        [...path, index],
        false,
        response
      );
      response = { ...response, ...subResponse };
    });
  }
  return response;
};

export const getFieldListSteps = (tree, childrenName, isRoot = true) => {
  const response = [];
  if (isRoot) {
    tree[childrenName].forEach((child) => {
      const subResponse = getFieldListSteps(child, childrenName, false);
      response.push(...subResponse);
    });
  } else {
    const treeCopy = Object.assign({}, tree);
    delete treeCopy[childrenName];
    //console.log(treeCopy);
    if (
      treeCopy?.activity?.activity_output_url ||
      treeCopy?.activity?.fields_list?.length ||
      treeCopy?.field_list?.length
    ) {
      response.push(treeCopy);
    } else if (
      treeCopy.activity_type == "TRIGGER" ||
      treeCopy.activity_type == "QUEYR"
    ) {
      // if current step is trigger or Query and has atleast one FIELD_LIST type field. then show it in app select dropdown in field mapping
      const fields = treeCopy.fields || [];
      const hasFieldList = fields.some(
        (field) => field.activity_field_type === "FIELD_LIST"
      );
      if (hasFieldList) {
        response.push(treeCopy);
      }
    }

    tree[childrenName]?.forEach((child) => {
      const subResponse = getFieldListSteps(child, childrenName, false);
      response.push(...subResponse);
    });
  }
  return response;
};

export const getMappingSourceSteps = (
  tree,
  childrenName,
  isRoot = true,
  completeTree = []
) => {
  // Retrun Array of steps whose Mapping can be re-use in hidden Feild Mapping
  const response = [];
  if (isRoot) {
    tree[childrenName].forEach((child) => {
      const subResponse = getMappingSourceSteps(
        child,
        childrenName,
        false,
        tree
      );
      response.push(...subResponse);
    });
  } else {
    const treeCopy = Object.assign({}, tree);
    delete treeCopy[childrenName];
    if (treeCopy.activity_type === "ACTION" && treeCopy.is_visible) {
      const fields = treeCopy.fields || [];
      let hasFieldList = false;
      fields.forEach((field) => {
        if (field.activity_field_type === "FIELD_MAPPING" && field.is_visible) {
          hasFieldList = true;
        }
      });
      if (hasFieldList) {
        response.push(treeCopy);
      }
    }

    tree[childrenName].forEach((child) => {
      const subResponse = getMappingSourceSteps(
        child,
        childrenName,
        false,
        completeTree
      );
      response.push(...subResponse);
    });
  }
  return response;
};

export const containTags = (str = "") => {
  // Check if str contian any template tag.
  if (str && typeof str === "string") {
    return str.indexOf("{steps.") > -1 || str.indexOf("{meta") > -1;
  }
  return false;
};

export const getmachineNameFromTag = (tag = "") => {
  if (tag && typeof tag === "string") {
    const machineName = tag.split(".").length > 0 ? tag.split(".")[1] : tag;
    return machineName;
  }
  return tag;
};

export const getObjectPathFromTag = (tag = "") => {
  /* return object path from tag.
  {steps.mailchimp_get_all_subscribers.out.members}, return members
  
  */
  if (tag && typeof tag === "string") {
    const stepMachineName = getmachineNameFromTag(tag);
    const rootTag = `{steps.${stepMachineName}.out}`; // if tag dont point to nesned object, then return empty string
    if (rootTag === tag) {
      return "";
    }
    // replate { and } from tag
    const newTag = tag.replace("{", "").replace("}", "");
    // split tag by out. and get last element
    const objectPath = newTag.split(`steps.${stepMachineName}.out.`)[1];
    return objectPath;
  }
  return tag;
};

export function moveArrayElement(array, sourceIndex, destinationIndex) {
  if (
    sourceIndex < 0 ||
    sourceIndex >= array.length ||
    destinationIndex < 0 ||
    destinationIndex >= array.length
  ) {
    throw new Error("Index out of bounds");
  }

  const newArray = [...array]; // Create a copy of the array
  const [element] = newArray.splice(sourceIndex, 1); // Remove the element from the source index
  newArray.splice(destinationIndex, 0, element); // Insert the element at the destination index

  return newArray;
}

export function flattenStepsObject(input) {
  const result = {};

  function flattenArray(array, result) {
    array.forEach((item) => {
      const { machine_name, ...rest } = item;
      result[machine_name] = { machine_name, ...rest };

      if (item.steps && item.steps.length > 0) {
        flattenArray(item.steps, result);
      }
    });
  }

  if (input.steps && input.steps.length > 0) {
    flattenArray(input.steps, result);
  }

  return { steps: result };
}

export const countNodesRecursively = (tree, childrenName) => {
  // Check if stepsObj is valid and has a 'steps' property that is an array
  if (!tree || !Array.isArray(tree[childrenName])) {
    return 0;
  }

  // Initialize count with the length of the current 'steps' array
  let count = tree[childrenName].length;

  // Recursively count items in nested 'steps' using forEach
  tree[childrenName].forEach((node) => {
    count += countNodesRecursively(node, childrenName);
  });

  return count;
};

const updateStepAttributes = (step, attributes) => {
  const updatedStep = { ...step };
  for (const key in attributes) {
    updatedStep[key] = attributes[key];
  }
  return updatedStep;
};

export const updateStepsAttributes = ({
  templateSteps,
  attributes,
  preUpdateCheck = () => true, // function to check if step should be updated, default to true
}) => {
  const updatedTemplateSteps = templateSteps.map((step) => {
    const shouldUpdate = preUpdateCheck(step);
    const updatedStep = shouldUpdate
      ? updateStepAttributes(step, attributes)
      : step;
    if (step.steps && step.steps.length > 0) {
      updatedStep.steps = updateStepsAttributes({
        templateSteps: updatedStep.steps,
        attributes,
      });
    }
    return updatedStep;
  });
  return updatedTemplateSteps;
};
