import { createApi } from "@reduxjs/toolkit/query/react";
import { integryBaseQuery } from "utils/http";
import { PaginatedResponse } from "types/paginated-response";
import { HTTPCallApp } from "features/templates/interface/http-call";

type PublicAppsResponse = PaginatedResponse<
  HTTPCallApp[],
  Record<string, unknown>
>;

export const usePublicAppsAPI = createApi({
  reducerPath: "publicApps",
  baseQuery: integryBaseQuery(),
  tagTypes: ["publicApps"],
  endpoints: (builder) => ({
    listPublicAppsPaginated: builder.query<
      PublicAppsResponse,
      {
        public_only?: boolean;
        page: number;
        page_size?: number;
        search: string;
      }
    >({
      query: (args) => {
        const {
          public_only = true,
          page = 1,
          page_size = 10,
          search = "",
        } = args || {};
        return {
          url: `/api/v1/apps/?page=${page}&page_size=${page_size}&http_call_apps_list=true&search=${search}&public_only=${public_only}`,
          method: "GET",
        };
      },
      transformResponse: (response: PublicAppsResponse, meta, args) => {
        return {
          ...response,
          current_page: args.page || 1,
          next_page: args.page + 1,
          has_more: !!response.links.next,
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { search = "" } = queryArgs || {};
        return `${endpointName}-${search}`;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, { arg }) => {
        if (arg.page === 1) {
          return newItems;
        }
        return {
          ...newItems,
          results: [...currentCache.results, ...newItems.results],
        };
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg?.page !== previousArg?.page ||
          currentArg?.search !== previousArg?.search
        );
      },
      providesTags: ["publicApps"],
    }),
  }),
});

export const {
  useListPublicAppsPaginatedQuery,
  useLazyListPublicAppsPaginatedQuery,
} = usePublicAppsAPI;
