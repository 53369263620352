/* eslint-disable react/prop-types */
import { connect } from "react-redux";
import { animated } from "react-spring";
import { useHistory } from "react-router-dom";

import DashboardWrapper from "components/dashboard-wrapper";
import { dangerouslyUpdateOnboardingState } from "store/actions/onboarding-state";
// eslint-disable-next-line import/extensions
import { FLOW_TYPES } from "../store/reducers/onboarding-state";

const CanduDashboard = (props) => {
  const { user, onboardingState, updateOnboardingState } = props;
  const history = useHistory();

  const marketplaceDeploymentUrl = onboardingState.default_marketplace_url;
  const sdkDeploymentUrl = onboardingState.default_sdk;

  const onFlowInit = (flowType) => {
    switch (flowType) {
      case FLOW_TYPES.SDK:
        updateOnboardingState({ onboardingFlowType: FLOW_TYPES.SDK });
        history.push(`/deployments/v3/`);
        break;
      case FLOW_TYPES.MARKETPLACE:
        updateOnboardingState({ onboardingFlowType: FLOW_TYPES.MARKETPLACE });
        history.push(`/deployments/v3/`);
        break;
      default:
        break;
    }
  };
  return (
    <animated.div>
      <DashboardWrapper user={user}>
        {onboardingState && (
          <div
            id="candu-wrapper"
            ref={(r) => {
              if (r) {
                if (window.Candu)
                  window.Candu.init({
                    clientToken: "4aH3WguA2g",
                    // Optionally provide end user ID
                    userId: user.email,
                    callbacks: {
                      visitDeploymentListingFromMarketplace: () =>
                        onFlowInit("marketplace"),
                      visitDeploymentListingFromSDK: () => onFlowInit("sdk"),
                      buildGuidedFlows: () => history.push(`/templates/v6/`),
                      visitMarketplaceDeploy: () => {
                        window
                          .open(`https://${marketplaceDeploymentUrl}`, "_blank")
                          .focus();
                      },
                      createConnector: () => history.push("/apps/v4/"),
                      visitSDKDeploy: () => {
                        window.open(
                          `/wapp/deployments/v3/sdk-preview?deploymentId=${sdkDeploymentUrl}`,
                          "_blank"
                        );
                      },
                      openIntercom: () =>
                        window.Intercom && window.Intercom("show"),
                    },
                  });
              }
            }}
          />
        )}
      </DashboardWrapper>
    </animated.div>
  );
};

const ConnectedCanduDashboard = connect(
  (state) => ({
    onboardingState: state.onboardingState,
  }),
  (dispatch) => ({
    updateOnboardingState(data) {
      dispatch(dangerouslyUpdateOnboardingState(data));
    },
  })
)(CanduDashboard);

export default ConnectedCanduDashboard;
