/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect, ReactElement, useRef } from "react";
import { Formik, FormikProps } from "formik";
import SearchBarV3 from "components/common/search-input-field/searchbar-v3";
import TextField from "components/forms/v4/text-field";
import { track } from "utils/mixpanel";
import { showErrorToast } from "components/common/custom-toast/custom-toast";
import { tagInterface } from "features/account-settings/services/interface";
import addIcon from "images/account-setting/add-action-icon.svg";
import openLink from "images/open-link.svg";
import SortIconDefault from "images/sort.svg";
import SortIconDesc from "images/sort-applied.svg";
import { listAccountTags, createAccountTag } from "./services/tags-apis";

import AccountTag from "./account-tag";
import "./tags.scss";

interface TagFormType {
  name: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TagsManagement = (props: { user: any }): ReactElement => {
  const { user } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [tags, setTags] = useState<tagInterface[]>([]);
  const [newTag, setNewTag] = useState("");
  const [editTagId, setEditTagId] = useState(0);
  const [search, setSearch] = useState("");
  const [creatingTag, setCreatingTag] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [sorting, setSorting] = useState<any>({
    updated_on: "asc",
  });
  const formikRef = useRef<
    FormikProps<{
      name: string;
    }>
  >();
  useEffect(() => {
    fetchTagsList(search, sorting);
  }, []);

  useEffect(() => {
    // when creating tag is true then auto focus the new tag input field
    if (creatingTag) document.getElementById("new-tag")?.focus();
  }, [creatingTag]);

  const searchTags = (tag): void => {
    track("Tag searched", user, { source: "Tag Management" });
    setSearch(tag);
    fetchTagsList(tag, sorting);
  };

  const fetchTagsList = (tag, sortOrder): void => {
    listAccountTags(tag, sortOrder)
      .then((res) => {
        const results = res.data;
        setTags(results);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const removeTagFromList = (id: number): void => {
    setTags((prevState) => prevState.filter((tag) => tag.id !== id));
  };

  const updateTagInList = (id: number, tagName: string): void => {
    setTags((prevState) =>
      prevState.map((tag) => {
        if (tag.id === id) {
          return { ...tag, name: tagName };
        }
        return tag;
      })
    );
  };

  const handleAddTag = (event): void => {
    if (event.key === "Enter") {
      const tagValue = event.target.value;
      event.preventDefault();
      event.stopPropagation();
      setIsSaving(true);
      createAccountTag(tagValue)
        .then((res) => {
          setCreatingTag(false);
          fetchTagsList("", sorting);
          setNewTag("");
          formikRef.current?.resetForm();
          track("New tag added", user, { source: "Tag Management" });
        })
        .catch((e) => {
          setIsSaving(false);
          showErrorToast(
            "Tag creation failed",
            `${e.response.data.unique_error}`
          );
        });
    }
  };
  const handleCreateTag = (): void => {
    setCreatingTag(true);
  };

  const onsubmit = (values: TagFormType, resetForm: () => void): void => {
    setIsSaving(true);
    createAccountTag(newTag);
  };

  const handleChange = (e): void => {
    setNewTag(e.target.value);
  };

  const validateTag = (name): string => {
    return "";
  };
  const handleOnSorting = (id): void => {
    let tempSorting = {};
    tempSorting = {
      [id]: sorting[id] === "desc" ? "asc" : "desc",
    };
    setSorting(tempSorting);
    fetchTagsList(search, tempSorting);
  };
  return (
    <Formik
      initialValues={{ name: "" }}
      onSubmit={(values, { resetForm }) => onsubmit(values, resetForm)}
      validate={(values) => {
        const error = validateTag(values.name);
        if (error) {
          return { email: error };
        }
        return {};
      }}
    >
      {(formik) => {
        formikRef.current = formik;
        return (
          <>
            <div className="account-setting-wrapper tag-management-wrapper">
              <h2 className="page-heading">Tags</h2>
              <a
                href="https://support.integry.io/hc/en-us/articles/17174130592921-How-to-Manage-Tags-"
                className="doc-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more about tags
                <img src={openLink} alt="open link" />
              </a>
              <div className="account-settings-form">
                <>
                  <h3 className="main-title">Create and manage tags</h3>
                  <SearchBarV3 placeholder="Search" onSearch={searchTags} />
                  <div>
                    <div className="tag-row heading-row">
                      <div
                        className="tag-col-head"
                        onClick={() => {
                          handleOnSorting("name");
                        }}
                      >
                        Name
                        <img
                          src={sorting.name ? SortIconDesc : SortIconDefault}
                          className={
                            sorting.name && sorting.name === "asc"
                              ? "table-sort-icon-asc"
                              : ""
                          }
                          alt="sort_icon"
                          aria-hidden="true"
                        />
                      </div>
                      <div
                        className="tag-col-head account-count-head"
                        onClick={() => {
                          handleOnSorting("actions_count");
                        }}
                      >
                        Actions
                        <img
                          src={
                            sorting.actions_count
                              ? SortIconDesc
                              : SortIconDefault
                          }
                          className={
                            sorting.actions_count &&
                            sorting.actions_count === "asc"
                              ? "table-sort-icon-asc"
                              : ""
                          }
                          alt="sort_icon"
                          aria-hidden="true"
                        />
                      </div>
                      <div
                        className="tag-col-head"
                        onClick={() => {
                          handleOnSorting("updated_on");
                        }}
                      >
                        Last Modified
                        <img
                          src={
                            sorting.updated_on ? SortIconDesc : SortIconDefault
                          }
                          className={
                            sorting.updated_on && sorting.updated_on === "asc"
                              ? "table-sort-icon-asc"
                              : ""
                          }
                          alt="sort_icon"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="tag-col-4 description" />
                    </div>

                    {tags.length > 0 &&
                      tags.map((tag) => (
                        <AccountTag
                          {...tag}
                          removeTagFromList={removeTagFromList}
                          validateTag={validateTag}
                          editTagId={editTagId}
                          setEditTagId={setEditTagId}
                          updateTagInList={updateTagInList}
                          user={user}
                        />
                      ))}
                    {creatingTag && (
                      <div className="tag-row member-row action-row">
                        <div className="tag-col">
                          <TextField
                            placeholder="Enter tag"
                            name="tag_name"
                            defaultValue=""
                            className="integry-text-field tag-name-field"
                            id="new-tag"
                            validate={validateTag}
                            onKeyDown={handleAddTag}
                            onKeyPress={handleChange}
                            errorPlaceHolder
                          />
                          {isSaving && (
                            <div className="integry-spinner-sm ml-mr-auto" />
                          )}
                        </div>
                      </div>
                    )}
                    <div className="tag-row member-row  action-row">
                      <div className="tag-col">
                        <span className="add-tag" onClick={handleCreateTag}>
                          <img src={addIcon} alt="Add" /> Create Tag
                        </span>
                      </div>
                    </div>
                  </div>
                </>
                {/* {isLoading && <div className="integry-spinner-sm ml-mr-auto" />} */}
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};
export default TagsManagement;
