import http from "../../utils/http";

export const REQUEST_APP_CATEGORIES = "REQUEST_APP_CATEGORIES";

export const requestAppCategories = () => ({
  type: REQUEST_APP_CATEGORIES,
});

export const RECEIVE_APP_CATEGORIES = "RECEIVE_APP_CATEGORIES";

export const receiveAppCategories = (json) => ({
  type: RECEIVE_APP_CATEGORIES,
  app_categories: json,
});

export const fetchAppCategories = () => {
  return (dispatch) => {
    dispatch(requestAppCategories());

    return http.get("/api/app_categories/").then(
      (response) => {
        dispatch(receiveAppCategories(response.data));
      },
      (error) => {
        console.log(
          "Error occurred while fetching app categories in fetchAppCategories() action.",
          error
        );
      }
    );
  };
};
