/* eslint-disable jsx-a11y/interactive-supports-focus */
import blueTick from "images/turbo.v2/blue-tick.svg";
import { ReactElement } from "react";

import "./CommonDropdownMenu.scss";

interface CommonDropdownMenuProps {
  options: { label: string; value: string; icon?: string }[];
  onChange: (value: string) => void;
  closeMenu: () => void;
  value: string;
}

const CommonDropdownMenu = (props: CommonDropdownMenuProps): ReactElement => {
  const { options, onChange, closeMenu, value } = props;
  return (
    <div
      className="navbar-dropdown-menu-container"
      onClick={() => closeMenu && closeMenu()}
      role="menu"
    >
      <div className="workspace-list-container integry-scrollbar-v2">
        {options.map((option) => (
          <div
            className="navbar-dropdown-menu-item hide-separator flex-space-between"
            onClick={(e) => {
              onChange(option.value);
            }}
            key={option.value}
          >
            <div className="flex gap-8px">
              {option.icon && (
                <img
                  src={option.icon}
                  alt="workspace-icon"
                  className="workspace-icon"
                />
              )}
              <div className="workspace-name overflow-elipsis">
                {option.label}
              </div>
            </div>
            {option.value === value && (
              <img src={blueTick} alt="arrow-icon" height={9} width={12} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommonDropdownMenu;
