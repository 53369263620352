/**
 * Return a valid Python identifier with access constructs.
 * For a number, return it as an index, e.g. `[0]`.
 * For a string which is a valid Python identifier, return it as an attribute, e.g. `.name`.
 * For a string which is not a valid Python identifier, return it as a key, e.g. `["name"]`.
 */
export const getValidPythonIdentifierWithAccess = (value: string): string => {
  let identifier = "";
  if (parseInt(value, 10) >= 0) {
    identifier += `[${value}]`;
  } else if (!getIsValidPythonIdentifier(value)) {
    identifier += `["${value}"]`;
  } else {
    identifier += `.${value}`;
  }
  return identifier;
};

const getIsValidPythonIdentifier = (value: string): boolean => {
  const regex = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
  const reservedWords = [
    "False",
    "None",
    "True",
    "and",
    "as",
    "assert",
    "async",
    "await",
    "break",
    "class",
    "continue",
    "def",
    "del",
    "elif",
    "else",
    "except",
    "finally",
    "for",
    "from",
    "global",
    "if",
    "import",
    "in",
    "is",
    "lambda",
    "nonlocal",
    "not",
    "or",
    "pass",
    "raise",
    "return",
    "try",
    "while",
    "with",
    "yield",
  ];

  return regex.test(value) && !reservedWords.includes(value);
};

export const convertDotNotationToArrayAccess = (key: string): string => {
  if (parseInt(key, 10) >= 0) return `[${key}]`;
  return `.${key}`;
};

export const convertFirstLetterToLowerCase = (key: string): string => {
  return key[0].toLowerCase() + key.slice(1);
};
