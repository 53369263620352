/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import http from "utils/http";
import config from "utils/config";

const useAppBuilderAPI = () => {
  const getAppDetailFromUrl = (appUrl: string) =>
    http.get(`/api/app_info_by_url/?url=${appUrl}`);

  const createApp = (data: any) => {
    const url = "/app/";
    return http.post(url, data);
  };

  const updateApp = (data: any, appId: number) => {
    const url = `/app/${appId}/`;
    return http.put(url, data);
  };

  const getApp = (appId: number) => {
    const url = `/app/${appId}/`;
    return http.get(url);
  };

  const createAuth = (data: any) => {
    const url = "/api/authorization_types/";
    return http.post(url, data);
  };

  const createActivity = (data: any) => {
    const url = "/api/activities/";
    return http.post(url, data);
  };

  const updateActivity = (data: any, activityId: number, appId: number) => {
    const url = `/api/activities/${activityId}/`;
    return http.put(url, { app: appId, ...data });
  };

  const getActivity = (activityId: number) => {
    const url = `/api/activities/${activityId}/`;
    return http.get(url);
  };

  const updateAuth = (data: any, authId: number) => {
    const url = `/api/authorization_types/${authId}/`;
    return http.put(url, data);
  };

  const getAuth = (authId: number) => {
    const url = `/api/authorization_types/${authId}/`;
    return http.get(url);
  };

  const getAppBaseUrls = (appId: number) =>
    http.get(`/app/${appId}/app_base_urls/`);

  const createBaseUrl = (data: any, appId) => {
    const base_urls = [data];
    const url = `/app/${appId}/base_url/`;
    return http.post(url, {
      base_urls,
    });
  };

  const createEndpoint = (data: any) => {
    const url = "/api/endpoints/";
    return http.post(url, data);
  };

  const updateEndpoint = (endpointId: number, data: any) => {
    const url = `/api/endpoints/${endpointId}/`;
    return http.put(url, data);
  };

  const getEndpoint = (endpointId: number) => {
    const url = `/api/endpoints/${endpointId}/`;
    return http.get(url);
  };

  const testEndpoint = (
    data: any,
    endpointId: number,
    accountId: number,
    sdkQueryParams: string,
    pagination_state: any,
    parentTags = null as any
  ) => {
    const params = {
      authorization_id: accountId,
      input_data: {
        requestBody: {
          ...data,
        },
        parentTags,
      },
      pagination_state,
    };
    const url = `/endpoints/test/${endpointId}?${sdkQueryParams}`;
    return http.post(url, params, {
      baseURL: config.microservicesBaseUrl,
    });
  };

  const testEndpointAction = (
    data: any,
    actionId: number,
    accountId: number,
    sdkQueryParams: string
  ) => {
    const params = {
      authorization_id: accountId,
      input_data: {
        ...data,
      },
    };
    const url = `/endpoints/test/action/${actionId}?${sdkQueryParams}`;
    return http.post(url, params, {
      baseURL: config.microservicesBaseUrl,
    });
  };

  const testEndpointTrigger = (
    data: any,
    triggerId: number,
    accountId: number,
    sdkQueryParams: string
  ) => {
    const params = {
      authorization_id: accountId,
      input_data: {
        ...data,
      },
    };
    const url = `/endpoints/test/trigger/${triggerId}?${sdkQueryParams}`;
    return http.post(url, params, {
      baseURL: config.microservicesBaseUrl,
    });
  };

  const renameAccount = (
    data: any,
    accountId: number,
    sdkQueryParams: string
  ) => {
    const url = `/connected-accounts/${accountId}/?${sdkQueryParams}`;
    return http.put(url, data);
  };

  const getAutoCompleteTags = (paramString: string, sdkQueryParams: string) => {
    const url = `/endpoints/autocomplete-tags/?${sdkQueryParams}&${paramString}`;
    return http.get(url);
  };

  const testAccountConnection = (
    accountId: number,
    sdkQueryParams: string,
    userAccountId: number
  ) => {
    const url = `${config.microservicesBaseUrl}auth/verify?account_id=${userAccountId}&authorization_id=${accountId}&${sdkQueryParams}`;
    return http.post(url);
  };

  const testResponseProcessing = (
    template: any,
    payloadData: any,
    validationSchema: any = null
  ) => {
    const payload = {
      template,
      data: {
        ...payloadData,
      },
      validationSchema,
    };
    const url = `/endpoints/template/render`;
    return http.post(url, payload);
  };

  const getHistory = (triggerId, testGuid, sdkQueryParams, lastCreatedAt) => {
    const url =
      `${config.microservicesBaseUrl}endpoints/test/trigger/${triggerId}/${testGuid}/history?${sdkQueryParams}` +
      `${lastCreatedAt ? `&created_after=${lastCreatedAt}` : ""}`;
    return http.get(url);
  };

  const createLock = (appId: number) => http.post(`app/${appId}/lock/`);

  const refreshLock = (appId: number) =>
    http.post(`refresh_lock/`, {
      type: "app",
      id: appId,
    });

  const appUnlock = (appId: number) => http.post(`app/${appId}/unlock/`);

  const createActionLock = (actionId: number) =>
    http.post(`api/activities/${actionId}/lock/`);

  const refreshActionLock = (actionId: number) =>
    http.post(`refresh_lock/`, {
      type: "activity",
      id: actionId,
    });

  const unlockAction = (actionId: number) =>
    http.post(`api/activities/${actionId}/unlock/`);

  const createAuthLock = (authId: number) =>
    http.post(`api/authorization_types/${authId}/lock/`);

  const refreshAuthLock = (authId: number) =>
    http.post(`refresh_lock/`, {
      type: "authorizationtype",
      id: authId,
    });

  const getAppLockLogs = (appId: number) => {
    return http.get(`/app_locks/${appId}/`);
  };

  const unlockAuth = (authId: number) =>
    http.post(`api/authorization_types/${authId}/unlock/`);

  return {
    getAppDetailFromUrl,
    createApp,
    updateApp,
    createAuth,
    updateAuth,
    createActivity,
    updateActivity,
    createBaseUrl,
    getAppBaseUrls,
    createEndpoint,
    updateEndpoint,
    testEndpoint,
    renameAccount,
    getAutoCompleteTags,
    testEndpointAction,
    testAccountConnection,
    getAuth,
    getApp,
    getActivity,
    getEndpoint,
    testResponseProcessing,
    testEndpointTrigger,
    getHistory,
    createLock,
    refreshLock,
    createAuthLock,
    refreshAuthLock,
    createActionLock,
    refreshActionLock,
    unlockAction,
    unlockAuth,
    appUnlock,
    getAppLockLogs,
  };
};

export default useAppBuilderAPI;
