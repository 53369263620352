import { useState, ChangeEvent, ReactElement } from "react";
import useDebounced from "hooks/useDebounce";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import "./styles.scss";

interface IProps {
  debounceTime?: number;
  placeholder?: string;
  onChange: (value: string) => void;
}

const NumberRangeInput = ({
  debounceTime = 500,
  placeholder = "e.g., 10 or 20-60",
  onChange,
}: IProps): ReactElement => {
  const [inputValue, setInputValue] = useState<string>("");
  const [error, setError] = useState<string>("");
  const debouncedOnChange = useDebounced(onChange, debounceTime);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    const isValid = /^(\d+(-\d+)?|\d+)?$/.test(value); // validate number or number range
    const isRange = /^\d+-$/.test(value); // allow typing range, i.e 9- or 9-9

    if (isValid || isRange) {
      setInputValue(value);
      setError("");
      debouncedOnChange(value);
    } else {
      setError("Enter a valid number or number range (e.g. 10 or 10-20)");
    }
  };

  return (
    <IntegryTooltip tooltipText="E.g. 10 or 10-20">
      <div className="number-range-input-container">
        <input
          type="text"
          value={inputValue}
          onChange={handleChange}
          placeholder={placeholder}
          className={`number-range-input ${inputValue ? "has_value" : ""}`}
        />
        {error && (
          <p className="error-message" style={{ color: "red" }}>
            {error}
          </p>
        )}
      </div>
    </IntegryTooltip>
  );
};

export default NumberRangeInput;
