export const getActivities = (state, appId, activityType) => {
  if (activityType === "all") {
    return appId && state[appId]
      ? { isFetching: false, items: state[appId] }
      : { isFetching: true, items: [] };
  } else {
    return appId && state[appId] && state[appId][activityType]
      ? state[appId][activityType]
      : { isFetching: true, items: [] };
  }
};
