import TooltipWrapper from "../../common/tooltip-wrapper";

const SelectFieldDropOptions = ({
  show,
  options,
  setSelectedOption,
  txtIcon,
  parentFieldName,
  isFieldList,
  hidePillDescription,
}) => {
  const getMaxLengths = (name, value) => {
    const maxTotalLength = 20;
    let maxNameLength = 0;
    let maxValueLength = 0;
    let nameLength = name?.length || 0;
    let valueLength = value ? value.length : 0;
    let totalLength = nameLength + valueLength;
    if (totalLength <= maxTotalLength) {
      maxNameLength = nameLength;
      maxValueLength = valueLength;
    } else if (totalLength > maxTotalLength) {
      if (nameLength <= 10) {
        maxNameLength = nameLength;
        maxValueLength = maxTotalLength - maxNameLength;
      } else {
        maxNameLength = 10;
        maxValueLength = 10;
      }
    }
    return { maxNameLength, maxValueLength, totalLength };
  };
  let valueKey = "";
  let idKey = "";
  let filteredOptions = options.filter(
    (option) => !!option.id || !!option.value
  );
  if (filteredOptions && filteredOptions.length) {
    valueKey = filteredOptions[0].title ? "title" : "text";
    idKey = filteredOptions[0].value ? "value" : "id";
    filteredOptions = filteredOptions.filter(
      (opt) => opt[valueKey] && opt[idKey]
    );
  }

  return (
    show && (
      <div className="select-options-div">
        <div className="option-list">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((opt) => {
              let maxLimits = getMaxLengths(opt[valueKey], opt[idKey]);
              return (
                <TooltipWrapper
                  hideToolTip={maxLimits.totalLength <= 20 || isFieldList}
                  tooltipText={opt[valueKey] + " | " + opt[idKey]}
                  tooltipId={`tag-tooltip`}
                >
                  <div
                    className="single-option"
                    onClick={() => {
                      setSelectedOption(opt);
                    }}
                  >
                    <span>
                      <img
                        src={isFieldList ? opt.icon : txtIcon}
                        alt="text-icon"
                        style={{ width: "18px" }}
                        className={`${isFieldList ? "field-list-icon" : ""}`}
                      ></img>
                    </span>
                    <span className={`${isFieldList ? "text" : "badge"}`}>
                      {isFieldList
                        ? opt[valueKey]
                        : opt[valueKey].length > maxLimits.maxNameLength
                        ? `${opt[valueKey].substring(
                            0,
                            maxLimits.maxNameLength
                          )}...`
                        : opt[valueKey]}
                    </span>
                    {!isFieldList && !hidePillDescription && (
                      <span className="text">
                        {opt[idKey] &&
                        opt[idKey].length > maxLimits.maxValueLength
                          ? `${opt[idKey].substring(
                              0,
                              maxLimits.maxValueLength
                            )}...`
                          : opt[idKey]}
                      </span>
                    )}
                  </div>
                </TooltipWrapper>
              );
            })
          ) : (
            <span className="no-option no-fields">
              {/* Your {parentFieldName ? parentFieldName : "account"} doesnt have
              any custom fields added */}
              No options
            </span>
          )}
        </div>
      </div>
    )
  );
};

export default SelectFieldDropOptions;
