import {
  REQUEST_GRANT_TYPES,
  RECEIVE_GRANT_TYPES,
} from "../actions/grant-types";

export const grantTypes = (
  state = { isFetching: false, items: [] },
  action
) => {
  switch (action.type) {
    case REQUEST_GRANT_TYPES:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_GRANT_TYPES:
      console.log(state);
      return Object.assign({}, state, {
        isFetching: false,
        items: action.grant_types,
      });
    default:
      return state;
  }
};
