import { createApi } from "@reduxjs/toolkit/query/react";
import { integryBaseQuery } from "utils/http";
import { PaginatedResponse } from "types/paginated-response";
import { AvailableMarketplaceApps } from "../interface";

type Response = PaginatedResponse<
  AvailableMarketplaceApps[],
  Record<string, unknown>
>;

export const useAvailableMarketplaceApps = createApi({
  // List marketplace apps which can be added
  reducerPath: "availableMarketplaceApps",
  baseQuery: integryBaseQuery(),
  tagTypes: ["availableMarketplaceApps"],
  endpoints: (builder) => ({
    getAvailableMarketplaceApps: builder.query<
      Response,
      { search: string } | void
    >({
      query: (params) => ({
        url: `/api/v1/apps/?marketplace_apps_list=true&page=1&page_size=10000&search=${
          params?.search || ""
        }`,
        method: "GET",
      }),
      providesTags: ["availableMarketplaceApps"],
    }),
  }),
});

export const { useGetAvailableMarketplaceAppsQuery } =
  useAvailableMarketplaceApps;
