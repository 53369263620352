import { createApi } from "@reduxjs/toolkit/query/react";
import { integryBaseQuery } from "utils/http";
import { FunctionsUsage } from "../interface/functionUsage";

export const useFunctionsUsageAPI = createApi({
  reducerPath: "function-usage",
  baseQuery: integryBaseQuery(),
  tagTypes: ["function-usage"],
  endpoints(build) {
    return {
      getFunctionsUsage: build.query<FunctionsUsage[], void>({
        query: () => ({
          url: `/api/v1/usage/function-call-counts/`,
          method: "GET",
        }),
      }),
    };
  },
});

export const { useGetFunctionsUsageQuery } = useFunctionsUsageAPI;
