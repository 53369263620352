import { createApi } from "@reduxjs/toolkit/query/react";
import { integryBaseQuery } from "utils/http";

import { generateIntegrationConfig } from "legacy-features/integrations/helper-utils";

// import { getIntegrationTooltip } from "../utils";
import { PaginatedRuns, TransformedRunsPaginated } from "../interface";
import { transfromRuns } from "../utils";

export const useIntegrationRunsAPI = createApi({
  reducerPath: "integration-runs",
  baseQuery: integryBaseQuery(),
  tagTypes: ["integration-runs"],
  endpoints(build) {
    return {
      getIntegrationRuns: build.query<
        TransformedRunsPaginated,
        {
          integrationId: number;
          page: number;
          pageSize?: number;
          search?: string;
          networkCode?: string;
          completedStatusNetworkCode?: string;
          runStatuses: string[];
          showBillableRunStatus: boolean;
          start_time_range_start?: string;
          start_time_range_end?: string;
          run_id?: string;
          poll_id?: string;
        }
      >({
        query: ({
          integrationId,
          page,
          search,
          pageSize,
          runStatuses,
          start_time_range_start = "",
          start_time_range_end = "",
          networkCode = "",
          completedStatusNetworkCode = "",
          run_id,
          poll_id,
        }) => {
          const runStatus = runStatuses
            .map((status) => {
              if (status === "COMPLETED") {
                return `${status}${
                  completedStatusNetworkCode
                    ? `:${completedStatusNetworkCode}`
                    : ""
                }`;
              }
              if (status === "FAILED") {
                return `${status}${networkCode ? `:${networkCode}` : ""}`;
              }
              return status;
            })
            .join(",");
          return {
            url: `/api/v1/integrations/${integrationId}/runs/?search=${encodeURIComponent(
              search || ""
            )}&page=${page}&page_size=${pageSize}&status=${runStatus}&start_time_range_start=${start_time_range_start}&start_time_range_end=${start_time_range_end}${
              run_id ? `&id=${run_id}` : ""
            }&poll_id=${poll_id}`,
            method: "GET",
          };
        },
        transformResponse: (response: PaginatedRuns, meta, args) => {
          const runs = transfromRuns(response.results);
          return {
            ...response,
            meta: {
              ...response.meta,
              integration: generateIntegrationConfig(
                response.meta.integration,
                args.showBillableRunStatus,
                false
              ),
            },
            results: runs,
            current_page: args.page || 1,
            next_page: args.page + 1,
            has_more: response.total_pages > args.page,
          };
        },
        serializeQueryArgs: ({ endpointName, queryArgs }) => {
          const {
            runStatuses = [],
            networkCode = "",
            integrationId = "",
            search = "",
            completedStatusNetworkCode = "",
            start_time_range_start = "",
            start_time_range_end = "",
          } = queryArgs;
          return `${endpointName}-${integrationId}-${runStatuses.join(",")}-${
            search || ""
          }-${networkCode || ""}-${completedStatusNetworkCode || ""}-${
            start_time_range_start || ""
          }-${start_time_range_end || ""}`;
        },
        // Always merge incoming data to the cache entry
        merge: (currentCache, newItems, { arg }) => {
          if (arg.page === 1) {
            return newItems;
          }
          return {
            ...newItems,
            results: [...currentCache.results, ...newItems.results],
          };
        },
        // Refetch when the page arg changes
        forceRefetch({ currentArg, previousArg }) {
          return (
            currentArg !== previousArg ||
            currentArg?.runStatuses !== previousArg?.runStatuses
          );
        },
        keepUnusedDataFor: 0,
        providesTags: ["integration-runs"],
      }),
      toggleIntegrationStatus: build.mutation<
        { status: string },
        { integrationId: number; status: "enable" | "disable" }
      >({
        query: ({ integrationId, status }) => ({
          url: `/v2/integration/${integrationId}/${status}/`,
          method: "PUT",
        }),
        invalidatesTags: ["integration-runs"],
      }),
    };
  },
});

export const {
  useGetIntegrationRunsQuery,
  useToggleIntegrationStatusMutation,
} = useIntegrationRunsAPI;
