import moment from "moment/moment";
import cloneDeep from "clone-deep";
import inflection from "inflection";

import urls from "utils/router-urls";
import { TemplateType } from "features/templates/interface/templateType";

export function getObjectPropertiesRecursive(inputObj, prefix) {
  const flat = [];

  for (let prop in inputObj) {
    let propx;
    if (inputObj.hasOwnProperty(prop)) {
      if (prefix.length > 0) {
        propx = prefix + "." + prop;
      } else {
        propx = prop;
      }

      if (typeof inputObj[prop] === "object") {
        if (inputObj[prop] && inputObj[prop].constructor !== Array) {
          flat.push(...getObjectPropertiesRecursive(inputObj[prop], propx));
        }
      } else {
        flat.push(propx);
      }
    }
  }

  return flat;
}

export function getHumanLabelForActivityFieldType(type) {
  switch (type) {
    case "SELECT":
      return "Dropdown";
    default:
      return "Textfield";
  }
}

export function formatTime(time) {
  return moment(time).format("YYYY-MM-DD");
}

export function formatDate(date, requiredFormat = "YYYY-MM-DD") {
  return moment(date).format(requiredFormat);
}

export function getTimeZone(date) {
  const dateAsString = date.toString();
  const timezone = dateAsString.match(/\(([^)]+)\)$/)[1];
  return timezone;
}

export function formatTimeStamp(time, requiredFormat = "MM/DD/YYYY h:m a") {
  return moment.unix(time).format(requiredFormat);
}

export function momentToDate(date) {
  return moment(date).toDate();
}

export function setCharAt(str, index, chr) {
  if (index > str.length - 1) return str;
  return str.substr(0, index) + chr + str.substr(index + 1);
}

export function doNothing() {}

export function disableEnter(event) {
  if ((event.charCode || event.keyCode) === 13) {
    event.preventDefault();
  }
}

export function isValidDate(date) {
  var regExp = /^\d{4}-\d{2}-\d{2}$/; //yyyy-mm-dd
  return regExp.test(date);
}

export function isValidTime24H(time) {
  var regExp = /^([01]\d|2[0-3]):?([0-5]\d)$/; //13:12
  return regExp.test(time);
}

export function decodeJSON(json, returnArray) {
  try {
    return JSON.parse(json);
  } catch (e) {
    // console.log("Invalid JSON");
    return returnArray ? [] : {};
  }
}

export function encodeJSON(json) {
  try {
    return JSON.stringify(json);
  } catch (e) {
    // console.log("Invalid JSON object", json);
    return "";
  }
}

export function getAbbrNum(number, decPlaces = 1) {
  //convert number to 1K fomrat
  decPlaces = Math.pow(10, decPlaces);
  let abbrev = ["K", "M", "B", "T"];
  for (let i = abbrev.length - 1; i >= 0; i--) {
    let size = Math.pow(10, (i + 1) * 3);
    if (size <= number) {
      number = Math.round((number * decPlaces) / size) / decPlaces;
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }
      number += abbrev[i];
      break;
    }
  }

  return number;
}

export function timestampToDatetimeField(timestamp) {
  let date = new Date(timestamp);

  const ten = (i) => (i < 10 ? "0" + i : i);
  let YYYY = date.getFullYear();
  let MM = ten(date.getMonth() + 1);
  let DD = ten(date.getDate());
  let HH = ten(date.getHours());
  let II = ten(date.getMinutes());
  let SS = ten(date.getSeconds());

  return `${YYYY}-${MM}-${DD}T${HH}:${II}:${SS}`;
}

export function capitalizeFirstLetter(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}

export function removeAllSpaces(s) {
  if (typeof s !== "string") return "";
  return s.replace(/\s/g, "");
}

export function reorderArray(array, source, destination) {
  let clonedArray = cloneDeep(array);
  clonedArray.splice(destination, 0, clonedArray.splice(source, 1)[0]);
  return clonedArray;
}

export function getQueryParamsFromURL() {
  let urlParams = window.location.search;
  let params = "";
  if (urlParams) {
    params = urlParams.substring(1);
    params = `{"${params.replace(/&/g, '","').replace(/=/g, '":"')}"}`;
  }
  return decodeJSON(params);
}

export function getSelectedParamFromURL(param) {
  const params = getQueryParamsFromURL();
  return params[param];
}

export function debounceFunction(func, delay) {
  let timer;
  return function () {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func();
    }, delay);
  };
}

export function getHtmlElementValue(elementId) {
  return document.getElementById(elementId).value;
}

export function validateUrl(value) {
  let error = "";
  if (value) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    if (!pattern.test(value)) {
      error = "Please enter a valid url.";
    }
  }
  return error;
}

export function validateEmail(value) {
  let error = "";
  if (value) {
    var pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!pattern.test(value)) {
      error = "Please enter a valid email address.";
    }
  }
  return error;
}

export function validateCommaSeparatedEmail(value) {
  let error = "";
  if (value) {
    var pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(,(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))*$/;
    if (!pattern.test(value)) {
      error = "Please enter a valid email address.";
    }
  }
  return error;
}

export const copyStringToClipboard = (str) => {
  if (!navigator.clipboard) {
    // Create new element
    const el = document.createElement("textarea");
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute("readonly", "");

    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand("copy");
    // Remove temporary element
    try {
      document.body.removeChild(el);
    } catch (e) {
      console.log(e);
    }
  } else {
    navigator.clipboard.writeText(str).catch(() => {
      console.error("Could not copy to clipboard"); // error
    });
  }
};

export const getCompanyName = (accountName = "") => {
  if (accountName && typeof accountName === "string") {
    return capitalizeFirstLetter(accountName.split("_")[0]);
  }
  return accountName;
};

export const verifyTemplateVersion = (templateMeta, history) => {
  // this function verifies if the template is opened in the corrrect version. If not, we do a auto redirect to correct link

  const { version, id, type } = templateMeta;
  if (version) {
    const isUnifiedTest = version === 3;
    const isConditionalLogicEnabled = version === 2;
    const flowBuilder = version === 7;
    const isFunctionTemplate = type === TemplateType.FUNCTION;
    const flowUrl = `${
      isUnifiedTest
        ? urls.templateUrlV6
        : isConditionalLogicEnabled
        ? urls.templateUrlDefault
        : "/templates/v1"
    }/${id}/edit/data-flow/`;

    let finalURL = flowBuilder ? `${urls.flowBuilder}/${id}/edit` : flowUrl;

    if (isFunctionTemplate) {
      finalURL = `${urls.functionEdit.replace(":templateId", id)}`;
    }

    if (window.location.pathname !== `/wapp${finalURL}`) {
      history.push(finalURL);
    }
  }
};

export const convertDateToLocal = (datetime, showYear = false) => {
  let dateString = "N/A";
  let tempDatetime = "N/A";
  if (datetime && moment(datetime).isValid()) {
    const isUnix = typeof datetime !== "string";
    if (isUnix) {
      tempDatetime = moment.unix(datetime).format("YYYY-MM-DDTHH:mm:ss");
    } else {
      tempDatetime = datetime;
    }
    const currentDate = new Date();
    const localTimeZoneDate = moment.utc(tempDatetime).local(isUnix);
    if (localTimeZoneDate.isSame(currentDate, "year") && showYear === false) {
      dateString = `${localTimeZoneDate.format("MMM DD, h:mm A")}`;
    } else {
      dateString = `${localTimeZoneDate.format("MMM DD, yyyy h:mm A")}`;
    }
  }
  return dateString;
};

export const convertDateToStandard = (datetime) => {
  let dateString = "N/A";
  if (moment(datetime).isValid()) {
    const currentDate = new Date();
    const localTimeZoneDate = moment(datetime);
    if (localTimeZoneDate.isSame(currentDate, "year")) {
      dateString = `${localTimeZoneDate.format("MMM DD, h:mm A")}`;
    } else {
      dateString = `${localTimeZoneDate.format("MMM DD, yyyy, h:mm A")}`;
    }
  }
  return dateString;
};

export const generateUserInitials = (userObject) => {
  if (userObject) {
    const { first_name, last_name, email } = userObject;
    if (first_name && last_name) {
      return `${first_name?.[0]?.toUpperCase()}${last_name?.[0]?.toUpperCase()}`;
    }
    return email?.[0]?.toUpperCase();
  }
};

export const concatStringWithAnd = (stringArray) => {
  if (Array.isArray(stringArray)) {
    return stringArray.reduce((total, currentValue, index) => {
      return stringArray.length - 1 === index
        ? `${total ? `${total} and ` : ""}${currentValue}`
        : `${total ? `${total}, ` : ""}${currentValue}`;
    }, "");
  }
  return "";
};

export const getFieldName = (field) => {
  switch (field.type) {
    case "SELECT":
      return `${inflection.titleize(
        field.data_src_endpoint ? "Dynamic" : "Static"
      )} dropdown`;

    default: {
      return `${inflection.titleize(field.type)}`;
    }
  }
};

export const sortAtoZByField = ({ data = [], field = "name" }) => {
  return data.sort(function (a, b) {
    var valueA = a[field].toUpperCase(); // Convert value to uppercase
    var valueB = b[field].toUpperCase();

    if (valueA < valueB) {
      return -1; // a should come before b in the sorted order
    }
    if (valueA > valueB) {
      return 1; // a should come after b in the sorted order
    }
    return 0; // value are equal
  });
};

export function ignoreKeys(event) {
  // List of key codes to ignore
  const ignoredKeys = [
    "Enter",
    "Tab",
    "ArrowUp",
    "ArrowDown",
    "ArrowLeft",
    "ArrowRight",
    "Home",
    "End",
    "PageUp",
    "PageDown",
  ];

  // Check if the pressed key is in the ignoredKeys list
  if (ignoredKeys.includes(event.key)) {
    // Ignore the event
    // event.preventDefault();
    return true;
  }
  return false;
}

export function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export function toCurlCommand({ method, url, headers, body = "" }) {
  let curlCmd = `curl -X ${method.toUpperCase()} '${url}'`;

  for (const [key, value] of Object.entries(headers)) {
    curlCmd += ` -H '${key}: ${value}'`;
  }

  if (body) {
    if (typeof body === "object") {
      body = JSON.stringify(body);
    }
    curlCmd += ` -d '${body}'`;
  }

  return curlCmd;
}

export function incrementLastIndex(arr) {
  if (Array.isArray(arr) === false) {
    throw new Error("Argument must be an array");
  }

  if (arr.length === 0) {
    throw new Error("Array cannot be empty");
  }

  // Create a copy of the array
  const newArr = arr.slice();

  // Increment the last element
  newArr[newArr.length - 1]++;

  return newArr;
}
