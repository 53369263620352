import * as React from "react";

const LoadingIcon: React.FC<{
  color: string;
}> = ({ color = "#fff" }) => (
  /*  <svg width={27} height={6} fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx={3} cy={3} r={3} fill={color} />
    <circle cx={14} cy={3} r={3} fill={color} fillOpacity={0.5} />
    <circle cx={24} cy={3} r={3} fill={color} fillOpacity={0.3} />
  </svg> */
  <svg width={30} height={6} fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx={3} cy={3} r={3} fill={color}>
      <animate
        attributeName="opacity"
        from="0.3"
        to="1"
        dur={1}
        begin="0s"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx={14} cy={3} r={3} fill={color}>
      <animate
        attributeName="opacity"
        from="0.3"
        to="1"
        dur={1}
        begin="0.2s"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx={24} cy={3} r={3} fill={color}>
      <animate
        attributeName="opacity"
        from="0.3"
        to="1"
        dur={1}
        begin="0.4s"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
);

export default LoadingIcon;
