import { ReactElement } from "react";

import greyscale_integry_loader from "../images/greyscale-integry-loader.gif";

interface IntegryLoaderProps {
  className?: string;
  styles?: Record<string, unknown>;
}

const IntegryLoader = (props: IntegryLoaderProps): ReactElement => {
  const { className, styles } = props;
  return (
    <img
      className={className || "integry-loader"}
      style={styles && styles}
      src={greyscale_integry_loader}
      alt=""
    />
  );
};

IntegryLoader.defaultProps = {
  className: "",
  styles: {},
};

export default IntegryLoader;
