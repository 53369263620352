/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { debounce, orderBy } from "lodash";

import ConnectorCard from "components/turbo.v1/connector-card";

import lang from "utils/lang/en";
import http from "utils/http";
import { track, turboMixpanelEvents } from "utils/mixpanel";

import searchBarIcon from "images/search-bar-icon.svg";
import xIcon from "images/x.svg";

import "./styles.scss";

const ConnectorListing = (props) => {
  const { selectedConnectorId = -1, user } = props;
  const [scrollCounter, setScrollCounter] = useState(0);
  const [isFirstTimeLoad, setIsFirstTimeLoad] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryPlaceholder, setSearchQueryPlaceholder] = useState("");
  const [connectors, setConnectors] = useState({});
  const [nextPageAPI, setNextPageAPI] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [totalConnectorsCount, setTotalConnectorsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const listInnerRef = useRef();
  const listContentRef = useRef();

  const handleMixpanelOnSearchEvent = ({ searchValue, event }) => {
    if (searchValue && searchValue !== "") {
      track(event, user, {
        [turboMixpanelEvents.SEARCH_CONNECTOR.property]: searchValue,
      });
    }
  };

  const debounceHandleSearch = useCallback(
    debounce((value) => {
      setIsFirstTimeLoad(true);
      setSearchQueryPlaceholder(value);
      setConnectors({});
      // getConnectors(connectors, value);
      getConnectors([], value);
      handleMixpanelOnSearchEvent({
        searchValue: value,
        event: turboMixpanelEvents.SEARCH_CONNECTOR.name,
      });
    }, 500),
    []
  );
  const instantHandleSearch = (value) => {
    setIsFirstTimeLoad(true);
    setSearchQueryPlaceholder(value);
    setConnectors({});
    getConnectors([], value);
  };

  const getConnectors = useCallback(
    debounce(
      (
        currentConnectors = [],
        searchQueryValue = "",
        nextPage,
        totalConnectors = 0
      ) => {
        if (
          totalConnectors === 0 ||
          totalConnectors > Object.keys(currentConnectors || {}).length
        ) {
          setIsLoading(true);
          http
            .get(
              !nextPage
                ? `/api/v1/apps/?search=${encodeURIComponent(searchQueryValue)}`
                : `${nextPage}${
                    !(
                      nextPage.includes("?search") ||
                      nextPage.includes("&search")
                    )
                      ? encodeURIComponent(searchQueryValue)
                      : ""
                  }`
            )
            .then((res) => {
              const { data = {} } = res;
              const { count = 0, next, results } = data;
              let tempConnectors = {};
              results.forEach((newConnector) => {
                tempConnectors = {
                  ...tempConnectors,
                  [newConnector.id]: {
                    ...newConnector,
                    nameLowercase: (newConnector.name || "").toLowerCase(),
                  },
                };
              });
              setConnectors({ ...currentConnectors, ...tempConnectors });
              setTotalConnectorsCount(count);
              let nextPageUrl = next;
              if (nextPageUrl) {
                nextPageUrl = nextPageUrl.replace("http://", "https://");
              }
              setNextPageAPI(nextPageUrl);
              if (count === 0) {
                handleMixpanelOnSearchEvent({
                  searchValue: searchQueryValue,
                  event:
                    turboMixpanelEvents.SEARCH_CONNECTOR_CONNECTOR_NOT_FOUND
                      .name,
                });
              }
            })
            .catch((e) => {
              console.log("ERROR on /app/?view=all", { e });
              setIsLoading(false);
              setTimeout(() => {
                setIsFirstTimeLoad(false);
              }, 500);
            })
            .finally(() => {
              setIsLoading(false);
              setTimeout(() => {
                setIsFirstTimeLoad(false);
              }, 500);
            });
        }
      },
      100
    ),
    []
  );

  const handleSearch = (value = "") => {
    setSearchQuery(value);
    debounceHandleSearch(value);
  };

  const handleConnectorSelect = (selectedConnector) => {
    const { onConnectorSelect } = props;
    if (onConnectorSelect) {
      onConnectorSelect(selectedConnector);
    }
  };

  const handleScroll = () => {
    if (!isLoading) {
      getConnectors(connectors, searchQuery, nextPageAPI, totalConnectorsCount);
      setScrollCounter(scrollCounter + 1);
    }
  };

  const getLoadingPlaceholder = ({ nbrOfplaceholders = 1 }) =>
    [...Array(nbrOfplaceholders).keys()].map((item) => ({
      isLoading: true,
      id: `loading-placeholder-app-${item}`,
    }));

  const convertConnectorsToArray = (connectorsObj = {}) => {
    const connectorsArr = [];
    if (Object.keys(connectorsObj).length < 1) {
      return [];
    }
    Object.keys(connectorsObj).forEach((keyIndex) => {
      connectorsArr.push(connectorsObj[keyIndex]);
    });
    return orderBy(connectorsArr, ["nameLowercase"], ["asc"]);
  };

  useEffect(() => {
    getConnectors(connectors);
    setTimeout(() => {
      setIsFirstTimeLoad(false);
    }, 1000);
  }, []);

  useEffect(() => {
    const stepWizardDOM = document.getElementById("turbo__step-wizard");
    if (stepWizardDOM) {
      stepWizardDOM.addEventListener("scroll", () => {
        if (stepWizardDOM) {
          const { scrollTop, scrollHeight, clientHeight } = stepWizardDOM;
          if (scrollTop + clientHeight > scrollHeight - 332) {
            handleScroll();
          }
        }
      });
    } else {
      window.addEventListener("wheel", (event) => {
        const delta = Math.sign(event.deltaY);
        if (delta === 1 && listInnerRef.current) {
          const { scrollTop, scrollHeight, clientHeight } =
            listInnerRef.current;
          if (scrollTop + clientHeight > scrollHeight - 332) {
            handleScroll();
          }
        }
      });
    }
    window.addEventListener("keyup", (event) => {
      if (event.code === "ArrowDown") {
        if (listInnerRef.current) {
          const { scrollTop, scrollHeight, clientHeight } =
            listInnerRef.current;
          if (scrollTop + clientHeight > scrollHeight - 1) {
            handleScroll();
          }
        }
      }

      const whitelistedCodes = ["ArrowUp", "ArrowDown"];
      if (whitelistedCodes.includes(event.code)) {
        listContentRef.current.focus();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectors, isLoading]);

  return (
    <div className="connector-listing">
      <div className="connector-listing__search-container">
        <input
          className="connector-listing__search-bar"
          placeholder={lang.SEARCH_CONNECTOR_PLACEHOLDER}
          value={searchQuery}
          onChange={(e) => {
            if (!isLoading) {
              handleSearch(e.target.value);
            }
          }}
        />
        <span className="search-bar-icon">
          {searchQuery && searchQuery !== "" ? (
            <img
              className="search-icon"
              alt="search-icon"
              src={xIcon}
              onClick={() => {
                setSearchQuery("");
                instantHandleSearch("");
              }}
              style={{ cursor: "pointer" }}
              aria-hidden="true"
            />
          ) : (
            <img
              className="search-icon"
              alt="search-icon"
              src={searchBarIcon}
            />
          )}
        </span>
      </div>
      {!isLoading &&
        !isFirstTimeLoad &&
        searchQueryPlaceholder &&
        searchQueryPlaceholder !== "" &&
        convertConnectorsToArray(connectors || {}).length < 1 && (
          <p className="connector-listing__empty-list-description">
            Your search&nbsp;
            <b>{` - ${searchQueryPlaceholder} - `}</b>
            &nbsp;did not match any result
          </p>
        )}
      <div className="connector-listing__container" ref={listInnerRef}>
        <div
          id="connector-listing__content"
          className="connector-listing__content"
          ref={listContentRef}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
        >
          {[
            ...convertConnectorsToArray(connectors || {}),
            ...(convertConnectorsToArray(connectors || {}).length === 0 &&
            isLoading
              ? getLoadingPlaceholder({ nbrOfplaceholders: 40 })
              : []),
          ].map((connector) => {
            const { id, name, icon_url, publishing_status } = connector;
            if (connector.isLoading) {
              return (
                <ConnectorCard
                  key={id}
                  isLoading={connector.isLoading}
                  customStyle={{}}
                />
              );
            }
            if (publishing_status !== "PUBLISHED") {
              return null;
            }
            return (
              <ConnectorCard
                key={id}
                title={name}
                iconSrc={icon_url}
                isSelected={id === selectedConnectorId}
                customStyle={
                  id === selectedConnectorId
                    ? {
                        border: "1px solid #4250f0",
                      }
                    : {}
                }
                onCardClick={() => {
                  handleConnectorSelect(connector);
                }}
              />
            );
          })}
        </div>
        {!isLoading &&
          !isFirstTimeLoad &&
          totalConnectorsCount - 1 < Object.keys(connectors || {}).length && (
            <div className="request-CTA__container">
              <p className="request-CTA__description">
                {lang.REQUEST_A_CONNECTOR_PLACEHOLDER}{" "}
                <button
                  type="button"
                  className="request-CTA__button"
                  onClick={() => {
                    const { onConnectorNotFound } = props;
                    if (onConnectorNotFound) {
                      onConnectorNotFound();
                    }
                  }}
                >
                  {lang.TELL_US_MORE}
                </button>
              </p>
            </div>
          )}
        {convertConnectorsToArray(connectors || {}).length > 0 && (
          <div
            className="loader"
            style={isLoading ? {} : { visibility: "hidden" }}
          />
        )}
        {/* {!isLoading &&
          !isFirstTimeLoad &&
          totalConnectorsCount > Object.keys(connectors || {}).length && (
            <div
              style={{
                position: "relative",
                marginTop: "80px",
              }}
            >
              <span
                className="arrow-container"
                onClick={() => {
                  handleScroll();
                }}
                aria-hidden="true"
              >
                <div className="arrow" />
                <div className="arrow" />
                <div className="arrow" />
              </span>
            </div>
          )} */}
      </div>
    </div>
  );
};

ConnectorListing.propTypes = {
  selectedConnectorId: PropTypes.number,
  onConnectorSelect: PropTypes.func,
  onConnectorNotFound: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.any,
};

export default ConnectorListing;
