import { ReactElement, useState } from "react";
import { HTTPCallApp } from "features/templates/interface/http-call";
import OverflowTooltip from "components/common/overflow-tooltip";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";

import SearchBar from "components/common/search-input-field/searchbar-v3";
import ConfigureWhiteLabelAuth from "components/integry-design-system/organisms/configure-white-label-auth";
import { WhiteLabelFormRenderModes } from "components/integry-design-system/organisms/marketplace/apps/whitelabel-form";
import { ReactComponent as GearIcon } from "images/navbar-dropdown/gear-icon-hollow.svg";
import { useFetchUserQuery } from "utils/user/user-api";

import ListingTable, {
  createColumnHelper,
} from "components/integry-design-system/organisms/ListingTable/ListingTable";

import { ReactComponent as BackIcon } from "images/back_arrow_blue.svg";

import { useListPublicAppsPaginatedQuery } from "../api/usePublicAppsAPI";
import "./ConfigureApps.scss";

const ConfigureApps = (): ReactElement => {
  const [selectedApp, setSelectedApp] = useState<HTTPCallApp | null>(null);
  const [queryArgumets, setQueryArgumets] = useState<{
    search: string;
    page: number;
  }>({
    search: "",
    page: 1,
  });

  const {
    data: publicApps,
    isLoading: isLoadingApps,
    isFetching: isFetchingApps,
    refetch,
  } = useListPublicAppsPaginatedQuery({
    page: queryArgumets.page,
    page_size: 25,
    search: queryArgumets.search,
  });
  const { data: user } = useFetchUserQuery();

  const columnHelper = createColumnHelper<HTTPCallApp>();

  const checkIsOAuth2 = (app: HTTPCallApp): boolean => {
    return app.authorization_type.type_id === "OAuth2-1";
  };

  const columns = [
    columnHelper.accessor("name", {
      header: () => "Title",
      cell: (info) => (
        <div className="flex align-center gap-10px">
          <img
            src={info.row.original.icon_url}
            alt="appicon"
            width={24}
            height={24}
            style={{ borderRadius: "50%" }}
          />
          <OverflowTooltip text={info.getValue()} />
        </div>
      ),
    }),

    columnHelper.accessor("machine_name", {
      header: () => "Name",
      cell: (info) => <OverflowTooltip text={info.getValue()} />,
    }),

    columnHelper.accessor("id", {
      header: () => "ID",
    }),

    columnHelper.accessor("authorization_type", {
      header: () => "Authentication",
      cell: (info) => {
        const { is_whitelabelled, is_whitelabelling_required } =
          info.row.original;
        const isOAuth2 = checkIsOAuth2(info.row.original);
        if (isOAuth2 && !is_whitelabelled && is_whitelabelling_required) {
          return (
            <span className="flex gap-10px default-text semi-bold">
              OAuth 2.0
              <span className="txt-red"> Not configured</span>
            </span>
          );
        }

        if (isOAuth2) {
          return is_whitelabelled
            ? `OAuth 2.0 with ${user?.account_name}`
            : "OAuth 2.0 with Integry";
        }

        return info.getValue().name;
      },
    }),

    columnHelper.accessor("is_workspace", {
      header: () => "",
      cell: (info) => {
        const isOAuth2 = checkIsOAuth2(info.row.original);
        const tooltipText = isOAuth2 ? "" : "Nothing to configure";
        return (
          <IntegryTooltip
            tooltipText={tooltipText}
            disabled={isOAuth2}
            toolTipStyles={{
              zIndex: 100,
            }}
          >
            <div id={`configure-apps-tooltip-${info.row.original.id}`}>
              <GearIcon
                width={15}
                height={15}
                style={{
                  opacity: isOAuth2 ? 1 : 0.5,
                }}
              />
            </div>
          </IntegryTooltip>
        );
      },
    }),
  ];

  const showMainLoader =
    isLoadingApps || (isFetchingApps && queryArgumets.page === 1);

  return (
    <div className="configure-apps">
      {!selectedApp && (
        <div className="configure-apps-wrapper">
          <div className="text-default txt-md semi-bold">Apps</div>
          <SearchBar
            searchQueryProp={queryArgumets.search}
            onSearch={(search) => {
              setQueryArgumets({
                page: 1,
                search,
              });
            }}
            placeholder="Search for an app"
          />
          <div className="configure-apps-table integry-scrollbar-v2">
            <ListingTable<HTTPCallApp>
              showHoverOnRow
              data={publicApps?.results || []}
              hasMore={showMainLoader ? false : !!publicApps?.has_more}
              columns={columns}
              fetchMore={() => {
                setQueryArgumets((prev) => ({
                  ...prev,
                  page: prev.page + 1,
                }));
              }}
              isLoading={showMainLoader}
              onRowClick={(row) => {
                setSelectedApp(row);
              }}
              getRowClassName={(row) => {
                const { authorization_type } = row;
                const isOAuth2 = authorization_type.type_id === "OAuth2-1";
                return isOAuth2 ? "" : "disabled-row";
              }}
            />
          </div>
        </div>
      )}
      {selectedApp && (
        <div className="white-label-wrapper">
          <div className="whitelable-auth-header">
            <div className="flex gap-10px align-center">
              <BackIcon
                onClick={() => {
                  setSelectedApp(null);
                }}
                className="cursor-pointer"
              />
              <img
                src={selectedApp.icon_url}
                alt="appicon"
                width={24}
                height={24}
                style={{ borderRadius: "50%" }}
              />
              <div className="text-default txt-xl bold">
                {selectedApp.name}
                <div className="flex gap-10px align-center">
                  <span className="text-default txt-md">
                    <span className="semi-bold">Name:</span>{" "}
                    {selectedApp.machine_name}
                  </span>
                  <span className="text-default txt-md">
                    <span className="semi-bold">ID:</span> {selectedApp.id}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="whitelable-auth-body integry-scrollbar-v2">
            <div className="text-defalut txt-lg bold">
              Configure OAuth 2.0 Developer App
            </div>
            <ConfigureWhiteLabelAuth
              renderMode={WhiteLabelFormRenderModes.PLAIN}
              configureAppObject={{
                ...selectedApp,
                iconUrl: selectedApp.icon_url,
                key: "",
                secret: "",
              }}
              user={user}
              bundleId={`${user?.default_marketplace}`}
              handleClose={() => {
                // setShowWhiteLabelApp(false);
              }}
              onEnableWhiteLabel={() => {
                if (queryArgumets.page === 1) {
                  refetch();
                } else {
                  setQueryArgumets({
                    ...queryArgumets,
                    page: 1,
                  });
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfigureApps;
