/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-lonely-if */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable no-shadow */
import React, { ReactElement, useEffect, useState } from "react";
import fullScreenIcon from "images/full-screen-icon-editor.svg";
import copyIcon from "images/copy-icon-editor.svg";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-textmate";
import "ace-builds/webpack-resolver";
import { encodeJSON, capitalizeFirstLetter } from "utils/functions";

import {
  decodeJSON,
  decodeJSONReturnSameIfFalse,
  handleKeyDown,
} from "legacy-features/integration-view/utils";
import InfoMessage from "components/integry-design-system/molecules/info-message";
import ToggleSectionIcon from "components/integry-design-system/atoms/toggle-section-icon";
import infoIconImage from "images/info-icon.svg";
import IntegryTooltip from "components/integry-design-system/molecules/integry-tooltip/integry-tooltip-v2";
import useOnClickOutside from "utils/use-onclick-outside";
import { ConditionObj } from "features/templates/template-builder-v7/services/template-builder-interface";
import closeIcon from "images/cross.svg";
import RecursiveTags from "components/forms/v2/recursive-tags";
import PayloadCompnentLoader from "./loader";

import "./styles.scss";

enum tabOptions {
  REQUEST,
  RESPONSE,
  OBJECT,
  OUTPUT,
  CONDITION,
}

export enum sectionNames {
  REQUEST_URL = "REQUEST_URL",
  REQUEST_HEADERS = "REQUEST_HEADERS",
  REQUEST_BODY = "REQUEST_BODY",
  RESPONSE_HEADERS = "RESPONSE_HEADERS",
  RESPONSE_BODY = "RESPONSE_BODY",
  OBJECT_HEADERS = "OBJECT_HEADERS",
  OBJECT_BODY = "OBJECT_BODY",
}

export interface Filter {
  relation: string;
  conditions: Condition[];
  result?: boolean | undefined;
  id: string;
}

export interface Condition {
  id: any;
  operand_1: string;
  operator: string;
  operand_2: string;
  result: boolean;
}
export interface stepMetaObjectPayload {
  parsedUrl?: string;
  parsedHeaders?: string;
  parsedObjectOutput?: any;
  parsedRequestTemplate?: string;
  parsedResponseTemplate?: string;
  remoteResponse?: string;
  requestTemplate?: string;
  responseHeaders?: string;
  objectOutput?: string;
  objectHeaders?: string;
  infoMessage?: [{ message: string; type: "INFO" | "WARNING" | "ERROR" }] | any;
  requestHttpVerb?: string;
  activityType?: string;
  appNetworkDocumentationLink?: string;
  appName?: string;
  defaultTab?: string;
  statusCode?: number;
  filters?: Filter;
  stepCondition?: {
    result: "true" | "false";
    conditions: ConditionObj["conditions"];
  };
}

export interface IStepPayloadComponent {
  payloadData: stepMetaObjectPayload;
  activityType?: string;
  hideObjectTab?: boolean;
  hideRequestTab?: boolean;
  hideResponseTab?: boolean;
  customHeight?: string[];
  onTabChange?: (activeTab) => void;
  tabSwitch?: string;
  waitingForRequest?: boolean;
  showOutputTab?: boolean;
  showConditionTab?: boolean;
  hiddenSection?: Array<sectionNames>;
  increaseHeightOnFullScreen?: boolean;
  isLoading?: boolean;
  tabConfig?: {
    request?: {
      title?: string;
    };
    response?: {
      title?: string;
    };
  };
}

const StepPayloadCompnent = (props: IStepPayloadComponent): ReactElement => {
  const [showParsedDataInBody, setShowParsedDataInBody] = useState(true);
  const [selectedTab, setSelectedTab] = useState(() => {
    if (!hideResponseTab) return tabOptions.RESPONSE;
    if (hideRequestTab) return tabOptions.REQUEST;
    return tabOptions.OBJECT;
  });
  const [isHeaderCopied, setIsHeaderCopied] = useState(false);
  const [isBodyCopied, setIsBodyCopied] = useState(false);
  const [fullScreenData, setFullScreenData] = useState({
    heading: "",
    editorData: "",
    isEditorDataCopied: false,
  });
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [increaseHeaderHeight, setIncreaseHeaderHeight] = useState(false);
  const [increaseBodyHeight, setIncreaseBodyHeight] = useState(false);
  const [showHeaders, setShowHeaders] = useState(false);
  const [showBody, setShowBody] = useState(true);

  const {
    payloadData,
    hideObjectTab = false,
    hideRequestTab = false,
    hideResponseTab = false,
    customHeight = [],
    onTabChange = () => null,
    tabSwitch = tabOptions.RESPONSE,
    waitingForRequest = false,
    showOutputTab = false,
    showConditionTab = false,
    activityType,
    increaseHeightOnFullScreen = false,
    tabConfig: customTabConfig = {},
    // visibleSections by defalut will be all sections
    hiddenSection = [],
    isLoading = false,
  } = props;

  const isCodeStep = activityType === "CODE";
  const divRef = React.useRef<HTMLDivElement>(null);
  const tabConfig = {
    request: {
      title: "Request",
    },
    response: {
      title: "Response",
    },
    ...customTabConfig,
  };
  useEffect(() => {
    const shouldShowRequestTabByDefault =
      payloadData?.defaultTab === "REQUEST" ||
      activityType === "TRIGGER" ||
      (!payloadData?.remoteResponse &&
        !payloadData?.parsedResponseTemplate &&
        !payloadData?.responseHeaders &&
        (payloadData?.parsedHeaders ||
          payloadData?.requestTemplate ||
          payloadData?.parsedRequestTemplate));
    if (shouldShowRequestTabByDefault) {
      setSelectedTab(tabOptions.REQUEST);
    }
  }, []);

  useEffect(() => {
    if (tabSwitch === "RESPONSE" && selectedTab !== tabOptions.RESPONSE)
      setSelectedTab(tabOptions.RESPONSE);
    else if (tabSwitch === "REQUEST" && selectedTab !== tabOptions.REQUEST)
      setSelectedTab(tabOptions.REQUEST);
  }, [tabSwitch]);

  useEffect(() => {
    if (
      selectedTab === tabOptions.REQUEST &&
      payloadData?.activityType === "ACTION"
    ) {
      if (!showParsedDataInBody) {
        setShowParsedDataInBody(true);
      }
    }
  }, [selectedTab]);

  const onSelectTab = (tabOption: tabOptions): void => {
    setSelectedTab(tabOption);
    onTabChange(tabOption);
  };

  const getHeaders = (): string => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let headerData: any = "{}";
    if (selectedTab === tabOptions.REQUEST) {
      headerData = payloadData?.parsedHeaders || "";
    } else if (selectedTab === tabOptions.RESPONSE) {
      headerData = payloadData?.responseHeaders || "";
    } else if (selectedTab === tabOptions.OBJECT) {
      headerData = payloadData?.objectHeaders || "";
    } else if (selectedTab === tabOptions.CONDITION) {
      headerData = encodeJSON(payloadData?.stepCondition?.conditions) || "";
    }
    const decodedJSON = decodeJSON(headerData);
    return headerData === ""
      ? ""
      : decodedJSON
      ? JSON.stringify(decodedJSON, null, "\t")
      : "";
  };

  const getBodyData = (): string => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let bodyData: any = "{}";
    if (selectedTab === tabOptions.REQUEST) {
      if (showParsedDataInBody) {
        bodyData = payloadData?.parsedRequestTemplate || "";
      } else {
        bodyData = payloadData?.requestTemplate || "";
      }
    } else if (selectedTab === tabOptions.RESPONSE) {
      if (showParsedDataInBody) {
        bodyData = payloadData?.parsedResponseTemplate ?? "";
      } else {
        bodyData = payloadData?.remoteResponse ?? "";
      }
    } else if (selectedTab === tabOptions.OBJECT) {
      if (showParsedDataInBody) {
        bodyData = payloadData?.parsedObjectOutput || "";
      } else {
        bodyData = payloadData?.objectOutput || "";
      }
    } else if (selectedTab === tabOptions.OUTPUT) {
      return decodeJSONReturnSameIfFalse(
        payloadData?.parsedRequestTemplate || ""
      );
    } else if (selectedTab === tabOptions.CONDITION) {
      bodyData = payloadData?.stepCondition?.result || "";
    }
    const decodedJSON = decodeJSONReturnSameIfFalse(bodyData);
    if (isCodeStep && showParsedDataInBody && decodedJSON?.code) {
      return decodedJSON?.code;
    }
    if (bodyData === "") {
      return "";
    }
    if (typeof decodedJSON === "number") {
      return `${decodedJSON}`;
    }
    if (decodedJSON || decodedJSON === false) {
      return JSON.stringify(decodedJSON, null, "\t");
    }
    return "";
  };

  const copyHeader = (): void => {
    navigator.clipboard.writeText(getHeaders());
    setIsHeaderCopied(true);
    setTimeout(() => {
      setIsHeaderCopied(false);
    }, 1600);
  };

  const copyBody = (): void => {
    navigator.clipboard.writeText(getBodyData());
    setIsBodyCopied(true);
    setTimeout(() => {
      setIsBodyCopied(false);
    }, 1600);
  };

  const copyEditor = (): void => {
    navigator.clipboard.writeText(fullScreenData.editorData);
    setFullScreenData((prev) => ({ ...prev, isEditorDataCopied: true }));
    setTimeout(() => {
      setFullScreenData((prev) => ({ ...prev, isEditorDataCopied: false }));
    }, 1600);
  };

  const handleHeaderFullScreen = (): void => {
    if (increaseHeightOnFullScreen) {
      setIncreaseHeaderHeight(!increaseHeaderHeight);
    } else {
      setFullScreenData({
        heading: "Headers",
        editorData: getHeaders(),
        isEditorDataCopied: false,
      });
      setShowFullScreen(true);
    }
  };

  const handleBodyFullScreen = (): void => {
    if (increaseHeightOnFullScreen) {
      setIncreaseBodyHeight(!increaseBodyHeight);
    } else {
      setFullScreenData({
        heading: "Body",
        editorData: getBodyData(),
        isEditorDataCopied: false,
      });
      setShowFullScreen(true);
    }
  };

  const closeFullScreen = (): void => {
    setShowFullScreen(false);
    setFullScreenData({
      heading: "",
      editorData: "",
      isEditorDataCopied: false,
    });
  };

  const clickOutside = (evt): void => {
    if (showFullScreen) {
      closeFullScreen();
    }
  };

  const hideRawParsedToggle = (): boolean => {
    let hideToggle = false;
    if (
      selectedTab === tabOptions.REQUEST &&
      payloadData?.activityType === "ACTION"
    ) {
      hideToggle = true;
    }
    return hideToggle;
  };

  const isHeaderVisible = (): boolean => {
    switch (selectedTab) {
      case tabOptions.REQUEST:
        return !hiddenSection.includes(sectionNames.REQUEST_HEADERS);
      case tabOptions.RESPONSE:
        return !hiddenSection.includes(sectionNames.RESPONSE_HEADERS);
      case tabOptions.OBJECT:
        return !hiddenSection.includes(sectionNames.OBJECT_HEADERS);
      case tabOptions.CONDITION:
        return false;
      default:
        return false;
    }
  };

  const isBodyVisible = (): boolean => {
    switch (selectedTab) {
      case tabOptions.CONDITION:
        return false;
      default:
        return true;
    }
  };
  useOnClickOutside(divRef, clickOutside);

  const getHeaderHeight = (): string => {
    if (selectedTab === tabOptions.CONDITION) {
      return increaseHeaderHeight ? "600px" : "400px";
    }
    return increaseHeaderHeight ? "400px" : "110px";
  };

  const getBodyHeight = (): string => {
    if (customHeight[selectedTab]) {
      return customHeight[selectedTab];
    }
    if (selectedTab === tabOptions.CONDITION) {
      return increaseBodyHeight ? "400px" : "110px";
    }
    return increaseBodyHeight ? "600px" : "295px";
  };

  return (
    <>
      {isLoading ? (
        <PayloadCompnentLoader />
      ) : (
        <div className="step-payload-component">
          <div className="tab-section">
            {!hideRequestTab && (
              <span
                className={`tab-item ${
                  selectedTab === tabOptions.REQUEST ? "active" : ""
                }`}
                onClick={(e) => {
                  onSelectTab(tabOptions.REQUEST);
                }}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  handleKeyDown(e, onSelectTab, [tabOptions.REQUEST]);
                }}
              >
                {tabConfig.request.title}
              </span>
            )}
            {showConditionTab && (
              <span
                className={`tab-item ${
                  selectedTab === tabOptions.CONDITION ? "active" : ""
                }`}
                onClick={(e) => {
                  onSelectTab(tabOptions.CONDITION);
                }}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  handleKeyDown(e, onSelectTab, [tabOptions.CONDITION]);
                }}
              >
                Conditions
              </span>
            )}
            {!hideResponseTab && (
              <span
                className={`tab-item ${
                  selectedTab === tabOptions.RESPONSE ? "active" : ""
                }`}
                onClick={(e) => {
                  onSelectTab(tabOptions.RESPONSE);
                }}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  handleKeyDown(e, onSelectTab, [tabOptions.RESPONSE]);
                }}
              >
                {tabConfig.response.title}
              </span>
            )}
            {!hideObjectTab && (
              <span
                className={`tab-item ${
                  selectedTab === tabOptions.OBJECT ? "active" : ""
                }`}
                onClick={(e) => {
                  onSelectTab(tabOptions.OBJECT);
                }}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  handleKeyDown(e, onSelectTab, [tabOptions.OBJECT]);
                }}
              >
                Object
              </span>
            )}
            {showOutputTab && (
              <span
                className={`tab-item ${
                  selectedTab === tabOptions.OUTPUT ? "active" : ""
                }`}
                onClick={(e) => {
                  onSelectTab(tabOptions.OUTPUT);
                }}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  handleKeyDown(e, onSelectTab, [tabOptions.OUTPUT]);
                }}
              >
                Output
              </span>
            )}

            {payloadData?.statusCode && (
              <span className="status-code">
                Status: <span>{payloadData?.statusCode}</span>
              </span>
            )}
            {waitingForRequest && (
              <span className="waiting-for-request">
                <span className="integry-spinner-sm spinner-v2 spinner-v2sm" />
                <span>Waiting for a request...</span>
              </span>
            )}
          </div>
          {selectedTab === tabOptions.REQUEST &&
            !hiddenSection.includes(sectionNames.REQUEST_URL) && (
              <div className="url-section">
                <div className="url-verb">{payloadData?.requestHttpVerb}</div>
                <div className="url-value">
                  <input value={payloadData?.parsedUrl} />
                </div>
              </div>
            )}
          {selectedTab === tabOptions.OUTPUT ? (
            <div className="output-payload-container">
              {/* <RecursiveTags
            value={decodeJSON(payloadData?.parsedRequestTemplate || "")}
            hideAddFieldButton
            objectKey="objectKey"
            tagRole="presentation"
            root
          /> */}
              {Object.keys(getBodyData()).length > 0 &&
                Object.keys(getBodyData()).map((key) => (
                  <RecursiveTags
                    objectKey={key}
                    value={getBodyData()?.[key]}
                    hideAddFieldButton
                    tagRole="presentation"
                    root
                  />
                ))}
            </div>
          ) : (
            <>
              {isHeaderVisible() && (
                <div className="header-section">
                  <div className="heading-div">
                    <h6
                      className="heading flex gap-5px cursor-pointer"
                      onClick={() => setShowHeaders(!showHeaders)}
                    >
                      Headers
                      <ToggleSectionIcon
                        isExpanded={!showHeaders}
                        onClick={() => setShowHeaders(!showHeaders)}
                      />
                    </h6>
                    <div />
                    {showHeaders && (
                      <div className="icons-div">
                        <img
                          src={fullScreenIcon}
                          alt="full-screen-icon"
                          className="full-screen-icon"
                          onClick={handleHeaderFullScreen}
                          tabIndex={0}
                          role="button"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleHeaderFullScreen();
                            }
                          }}
                        />
                        <IntegryTooltip
                          tooltipId="copy-header"
                          tooltipText={isHeaderCopied ? "Copied" : "Copy"}
                          effect="solid"
                          placement="bottom"
                        >
                          <img
                            src={copyIcon}
                            onClick={copyHeader}
                            tabIndex={0}
                            role="button"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                copyHeader();
                              }
                            }}
                            alt="copy-icon"
                            className="copy-icon"
                          />
                        </IntegryTooltip>
                      </div>
                    )}
                  </div>
                  {showHeaders && (
                    <div className="headers-editor ace-json-viewer2 black-white">
                      <AceEditor
                        mode="json"
                        theme="textmate"
                        // className="datap"
                        fontSize={12}
                        showGutter
                        height={getHeaderHeight()}
                        width="100%"
                        wrapEnabled
                        showPrintMargin={false}
                        setOptions={{
                          showLineNumbers: true,
                          tabSize: 1,
                        }}
                        value={getHeaders() || ""}
                        readOnly
                      />
                    </div>
                  )}
                </div>
              )}
              {isBodyVisible() && (
                <div className="body-section">
                  <div className="heading-div">
                    <h6
                      className="heading flex gap-5px cursor-pointer"
                      onClick={() => setShowBody(!showBody)}
                    >
                      Body
                      <ToggleSectionIcon
                        isExpanded={!showBody}
                        onClick={() => setShowBody(!showBody)}
                      />
                    </h6>
                    <div />
                    {showBody && (
                      <div className="icons-div">
                        {!hideRawParsedToggle() && (
                          <>
                            <div className="separator" />
                            <div className="raw-toggle">
                              <div className="checkbox-field">
                                Raw
                                <label className="switch" htmlFor="raw_switch">
                                  <input
                                    name="raw_switch"
                                    id="raw_switch"
                                    checked={showParsedDataInBody}
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={(e) => {
                                      setShowParsedDataInBody(e.target.checked);
                                    }}
                                  />
                                  <span className="slider round" />
                                </label>
                                Parsed
                              </div>
                            </div>
                          </>
                        )}
                        <img
                          src={fullScreenIcon}
                          alt="full-screen-icon"
                          className="full-screen-icon"
                          onClick={handleBodyFullScreen}
                          tabIndex={0}
                          role="button"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleBodyFullScreen();
                            }
                          }}
                        />
                        <IntegryTooltip
                          tooltipId="copy-body"
                          tooltipText={isBodyCopied ? "Copied" : "Copy"}
                          effect="solid"
                          placement="bottom"
                        >
                          <img
                            src={copyIcon}
                            onClick={copyBody}
                            tabIndex={0}
                            role="button"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                copyBody();
                              }
                            }}
                            alt="copy-icon"
                            className="copy-icon"
                          />
                        </IntegryTooltip>
                      </div>
                    )}
                  </div>
                  {showBody && (
                    <>
                      <div className="body-editor ace-json-viewer2 black-white">
                        <AceEditor
                          mode={isCodeStep ? "python" : "json"}
                          theme="textmate"
                          className="datap"
                          fontSize={12}
                          showGutter
                          height={getBodyHeight()}
                          width="100%"
                          wrapEnabled
                          showPrintMargin={false}
                          setOptions={{
                            showLineNumbers: true,
                            tabSize: 1,
                          }}
                          value={getBodyData() || ""}
                          readOnly
                        />
                      </div>
                      {payloadData.infoMessage &&
                        payloadData.infoMessage.length > 0 && (
                          <div className="info-alert-div">
                            <InfoMessage
                              infoIcon={infoIconImage}
                              infoMessage={payloadData?.infoMessage[0].message}
                              showMessageProp
                            />
                          </div>
                        )}
                      {selectedTab === tabOptions.RESPONSE &&
                        payloadData?.appNetworkDocumentationLink && (
                          <div className="info-alert-div">
                            <InfoMessage
                              infoIcon={infoIconImage}
                              infoMessage={
                                <a
                                  className="link"
                                  href={
                                    payloadData?.appNetworkDocumentationLink
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  View {payloadData?.appName} API docs
                                </a>
                              }
                              showMessageProp
                            />
                          </div>
                        )}
                    </>
                  )}
                </div>
              )}

              {selectedTab === tabOptions.CONDITION && (
                <div className="body-section">
                  <div className="condition-section">
                    <span className="txt-sm-dark-gray">
                      {payloadData?.stepCondition?.conditions}
                    </span>{" "}
                    <span className="text-mid-gray semi-bold">=</span>{" "}
                    <span
                      className={`${
                        payloadData.stepCondition?.result === "true"
                          ? "txt-green"
                          : "txt-red"
                      }`}
                    >
                      {capitalizeFirstLetter(
                        payloadData?.stepCondition?.result
                      )}
                    </span>
                  </div>
                </div>
              )}
            </>
          )}

          {showFullScreen && (
            <div className="fullscreen-wrapper">
              <div className="fullscreen-editor container" ref={divRef}>
                <div className="heading-div">
                  <h6 className="heading">{fullScreenData.heading}</h6>
                  <div />
                  <div className="icons-div">
                    <IntegryTooltip
                      tooltipId="copy-editor"
                      tooltipText={
                        fullScreenData.isEditorDataCopied ? "Copied" : "Copy"
                      }
                      effect="solid"
                      placement="bottom"
                    >
                      <img
                        src={copyIcon}
                        onClick={copyEditor}
                        tabIndex={0}
                        role="button"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            copyEditor();
                          }
                        }}
                        alt="copy-icon"
                        className="copy-icon"
                      />
                    </IntegryTooltip>
                    <img
                      src={closeIcon}
                      alt="close-icon"
                      className="close-icon"
                      onClick={closeFullScreen}
                      tabIndex={0}
                      role="button"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          closeFullScreen();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="body-editor ace-json-viewer2 black-white">
                  <AceEditor
                    mode="html"
                    theme="textmate"
                    // className="datap"
                    fontSize={12}
                    showGutter
                    height="70vh"
                    width="100%"
                    wrapEnabled
                    showPrintMargin={false}
                    setOptions={{
                      showLineNumbers: true,
                      tabSize: 1,
                    }}
                    value={fullScreenData.editorData || ""}
                    readOnly
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default StepPayloadCompnent;
export { PayloadCompnentLoader };
