import { useCallback, useRef } from "react";

// Define the hook with generic type F to represent a function type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useDebounced<F extends (...args: any[]) => void>(
  fn: F,
  delay: number
): [(...args: Parameters<F>) => void, () => void] {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedFn = useCallback(
    (...args: Parameters<F>) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        fn(...args);
      }, delay);
    },
    [fn, delay] // Dependency array includes the function and the delay
  );

  const cancel = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []); // No dependencies, as it just needs to clear the timeout

  return [debouncedFn, cancel];
}

export default useDebounced;
