import { createApi } from "@reduxjs/toolkit/query/react";
import { integryBaseQuery } from "utils/http";
import config from "utils/config";
import { TemplateBrandingAppStep } from "features/templates/interface/template-branding-app-step";

export const useTemplateBrandingAppStep = createApi({
  reducerPath: "templateBrandingAppStep",
  baseQuery: integryBaseQuery({
    baseUrl: config.apiBaseURL || "",
  }),
  tagTypes: ["templateBrandingAppStep"],
  endpoints: (builder) => ({
    getTemplateBrandingAppStep: builder.query<
      TemplateBrandingAppStep,
      {
        templateId: string | number;
      }
    >({
      query: ({ templateId }) => ({
        url: `/api/v1/templates/${templateId}/branding-app-steps/`,
        method: "GET",
      }),
    }),
    getStepMappingByApp: builder.mutation<
      {
        template_step_mapping: Record<string, string>;
        activity_mapping: Record<string, string>;
      },
      {
        templateId: string | number;
        appId: string | number | undefined;
      }
    >({
      query: ({ templateId, appId }) => ({
        url: `/api/v1/templates/${templateId}/clone-activity-suggestions/?app_id=${appId}`,
        method: "POST",
      }),
      transformResponse: (response: {
        template_step_mapping: Record<string, string>;
        activity_mapping: Record<string, string>;
      }) => {
        const template_step_mapping = {};
        const activity_mapping = {};

        // Upodate activity_mapping keys and appent "_" to all keys
        Object.keys(response.activity_mapping).forEach((key) => {
          activity_mapping[`_${key}`] = response.activity_mapping[key];
        });
        // Update template_step_mapping keys and appent "_" to all keys
        Object.keys(response.template_step_mapping).forEach((key) => {
          template_step_mapping[`_${key}`] =
            response.template_step_mapping[key];
        });

        return {
          ...response,
          activity_mapping,
          template_step_mapping,
        };
      },
    }),
  }),
});

export const {
  useGetTemplateBrandingAppStepQuery,
  useGetStepMappingByAppMutation,
} = useTemplateBrandingAppStep;
