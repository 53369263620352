import React from "react";
import "./styles.scss";

type IProps = {
  customStyle?: React.CSSProperties;
  className?: string;
  borderRadius?: string;
};

const PlaceholderBar: React.FC<IProps> = (props) => {
  const { customStyle = {}, borderRadius, className = "" } = props;
  return (
    <div className={`placeholder ${className}`} style={customStyle}>
      <div
        className="animated-background"
        style={borderRadius ? { borderRadius } : {}}
      />
    </div>
  );
};
export default PlaceholderBar;
