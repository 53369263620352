import { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import "./styles.scss";

const ToggleSectionIcon = (props: {
  className?: string;
  isExpanded: boolean;
  onClick?: () => void;
}): ReactElement => {
  const { isExpanded, className = "", onClick } = props;
  return (
    <div
      className={`toggle-section-icon ${
        isExpanded ? "angle-up" : "angle-down"
      } ${className}`}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronDown} />
    </div>
  );
};

export default ToggleSectionIcon;
