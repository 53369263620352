import { useEffect } from "react";

const useDisableScroll = (isOpen: boolean): void => {
  useEffect(() => {
    if (isOpen) {
      // Save the original styles
      const originalOverflow = document.body.style.overflow;
      const originalPaddingRight = document.body.style.paddingRight;

      // Calculate the scrollbar width
      const scrollBarWidth =
        window.innerWidth - document.documentElement.clientWidth;

      // Apply styles to prevent body scroll and compensate for scrollbar
      document.body.style.overflow = "hidden";
      if (scrollBarWidth > 0) {
        document.body.style.paddingRight = `${scrollBarWidth}px`;
      }

      // Cleanup function to restore the original styles
      return () => {
        document.body.style.overflow = originalOverflow;
        document.body.style.paddingRight = originalPaddingRight;
      };
    }
  }, [isOpen]);
};

export default useDisableScroll;
