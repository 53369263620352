/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import { createApi } from "@reduxjs/toolkit/query/react";

import { normalizeGrantTypes } from "store/actions/grant-types";
import { parseEndpointTypesJSON } from "store/actions/endpoints";
import config from "./config";
import { integryBaseQuery } from "./http";

export const integryApi = createApi({
  reducerPath: "integryApi",
  baseQuery: integryBaseQuery({
    baseUrl: config.apiBaseURL || "",
  }),
  endpoints(build) {
    return {
      getAuthTypes: build.query({
        query: () => ({
          url: "/api/auth_types/",
          method: "get",
        }),
      }),
      getGrantTypes: build.query({
        query: () => ({
          url: "/api/authorization_types/grant_types/",
          method: "get",
        }),
        transformResponse: (response) => normalizeGrantTypes(response),
      }),
      getEndpointTypes: build.query({
        query: () => ({
          url: "/api/endpoint/types/",
          method: "get",
        }),
        transformResponse: (response) => parseEndpointTypesJSON(response),
      }),
    };
  },
});

export const {
  useGetAuthTypesQuery,
  useGetGrantTypesQuery,
  useGetEndpointTypesQuery,
} = integryApi;
