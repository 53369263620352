/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { FixedSizeList as List } from "react-window";
import Select, { components } from "react-select";
import ReactTooltip from "react-tooltip";

const CustomHeaderDropdown = (props) => {
  const { options, value, onChange, setTooltip } = props;
  const Option = (propsChild) => {
    const { label, data, listStyle = {} } = propsChild;
    const [isOverflown, setIsOverflown] = useState(false);
    const { innerProps } = propsChild;
    const { id } = innerProps;

    useEffect(() => {
      const element = document.getElementById(id);
      setIsOverflown(element?.scrollWidth > element?.clientWidth);
    }, [id]);
    return (
      <div
        onMouseEnter={() => setTooltip(false)}
        data-tip={isOverflown ? label : ""}
        data-place="left"
        style={listStyle}
      >
        <components.Option {...propsChild}>
          <img
            src={data?.icon_url}
            alt=" "
            style={{
              width: "15px",
              height: "15px",
              marginRight: "10px",
              borderRadius: "50%",
            }}
          />
          <span>{label}</span>
        </components.Option>
        <ReactTooltip
          backgroundColor="#fff"
          textColor="#000"
          borderColor="#eee"
          className="tooltip-options"
        />
      </div>
    );
  };

  const MenuList = (innerProps) => {
    const height = 35;
    const { children, getValue } = innerProps;
    const [valueForOffset] = getValue();
    const initialOffset = options.indexOf(valueForOffset) * height;
    return (
      <List
        height={300}
        width={230}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {/* {({ index, style }) => <div style={style}>{children[index]}</div>} */}
        {({ index, style }) => {
          const option = children[index];
          return <Option {...option.props} listStyle={style} />;
        }}
      </List>
    );
  };

  const colourStyles = {
    control: (provided) => ({
      ...provided,
      boxShadow: "none",
      border: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "0px !imprtant",
      width: "12px",
    }),
    menu: (provided) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
    }),
    option: (provided) => ({
      ...provided,
      maxHeight: "27px",
      minWidth: "197px",
      maxWidth: "197px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis !important",
      backgroundColor: "transparent",
      color: "#666666",
      fontSize: "13px",
      lineHeight: "15.73px",
      fontFamily: "Inter",
      marginRight: "0px !important",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#D4E1F8",
      },
    }),
    menuList: (base) => ({
      ...base,
      minHeight: "auto",
      maxHeight: "321px",
      minWidth: "200px",
      maxWidth: "200px",
      background: "#FFFFFF",
      marginLeft: "-35px",
      borderBottomLeftRadius: "3px",
      borderBottomRightRadius: "3px",
      boxShadow: "0px 2px 7px rgba(162, 162, 162, 0.25)",
      marginTop: "3px",
      padding: "0px",
      "::-webkit-scrollbar": {
        width: "3px",
        borderRadius: "22px",
      },
      "::-webkit-scrollbar-track": {
        background: "#ECECEC",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#D4E1F8",
        borderRadius: "22px",
        height: "59px",
      },
      ":hover": {
        color: "#4250F0 !important",
      },
      ":active": {
        color: "#4250F0 !important",
      },
    }),
  };
  return (
    <Select
      styles={colourStyles}
      isSearchable={false}
      value={value ?? ""}
      onChange={onChange}
      components={
        options.length > 20
          ? { IndicatorSeparator: () => null, MenuList }
          : {
              IndicatorSeparator: () => null,
              Option,
            }
      }
      getOptionLabel={(option) => option?.name}
      getOptionValue={(option) => option?.id}
      options={options}
    />
  );
};

export default CustomHeaderDropdown;
