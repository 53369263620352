/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import PlaceholderBar from "atomic-components/atom/placeholder-bar";

interface RenderTableProps {
  config: any[];
  node?: number;
  rowCount?: number;
  onInViewport?: () => void;
}

const SkeletonLoader = (props: RenderTableProps): ReactElement => {
  const { config, node = 0, onInViewport } = props;
  const rowCount = props.rowCount || 1;
  const data = new Array(rowCount).fill(0);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView && onInViewport) {
      onInViewport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <>
      {data.length > 0 && (
        <div className="div-body" ref={ref}>
          {data.map((step: any) => {
            let rowStyle = {};
            if (node > 0) {
              const indent = {
                left: ((111 - node * 10) / 1209) * 100,
                right: ((203 - node * 10) / 1209) * 100,
                padding: 24 - node * 10,
              };
              rowStyle = {
                ...rowStyle,
                padding: indent.padding < 0 ? "0px" : `0 ${indent.padding}px`,
              };
            }
            return (
              <div
                className="row-container"
                style={step.rowStyle || {}}
                key={step.id}
              >
                <StepRow
                  config={config}
                  rowStyle={{ ...rowStyle, ...(step.rowDivStyle || {}) }}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

const StepRow = ({ config, rowStyle = {} }): JSX.Element => (
  <div className="div-row div-row-new" style={rowStyle}>
    {config.map(() => (
      <div className="div-column">
        <PlaceholderBar
          customStyle={{
            maxWidth: "80%",
            borderRadius: "4px",
            margin: "0px",
          }}
        />
      </div>
    ))}
  </div>
);

export default SkeletonLoader;
