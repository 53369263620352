import hmacSHA256 from "crypto-js/hmac-sha256";
import { useFetchUserQuery } from "utils/user/user-api";

type GetAuthParamsType = {
  appKey?: string;
  secret?: string;
  userId?: string;
};

const useHashBasedAuth = (): {
  getAuthParams: (arg?: GetAuthParamsType) => string;
} => {
  const { data: user } = useFetchUserQuery();
  const getAuthParams = (
    arg: GetAuthParamsType = {
      appKey: "",
      secret: "",
      userId: user?.email,
    }
  ): string => {
    const { appKey = "", secret, userId } = arg;
    const key = appKey || user?.account?.default_workspace_app?.key || "";
    const appSecret =
      secret || user?.account?.default_workspace_app?.secret || "";

    const hash = hmacSHA256(userId, appSecret).toString();

    return `user_id=${encodeURIComponent(
      userId || ""
    )}&app_key=${encodeURIComponent(key)}&hash=${encodeURIComponent(hash)}`;
  };
  return { getAuthParams };
};

export default useHashBasedAuth;
