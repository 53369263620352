/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect } from "react";
import PropTypes from "prop-types";
import xIcon from "images/x.svg";
import "./styles.scss";
import { propTypes } from "react-bootstrap/esm/Image";

const RemainingTrialDaysPopup = (props) => {
  const { onClose, remainingDays, onlyClose } = props;
  // const { show } = useIntercom();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const handleOnCloseClick = (bookCall = true) => {
    if (bookCall) {
      if (onClose) {
        onClose();
      }
    } else {
      onlyClose();
    }
  };
  return (
    <div
      className="turbo-signup-success-portal"
      onClick={handleOnCloseClick}
      aria-hidden="true"
    >
      <div
        className="osp-container"
        onClick={(e) => e.stopPropagation()}
        aria-hidden="true"
      >
        <div className="osp-container__heading">
          {/* <img src={CheckCircleIcon} alt="check_circle_ico" /> */}
          {remainingDays <= 0 ? (
            <p>Your free trial has ended</p>
          ) : (
            <p>Your free trial is ending</p>
          )}
        </div>
        <div className="osp-container__content">
          {remainingDays <= 0 ? (
            <p>
              But the show must go on. Upgrade to a paid plan and offer
              delightful integrations to your users.
            </p>
          ) : (
            <p>
              If you like what you&apos;ve seen, upgrade to a paid plan and
              offer delightful integrations to your users
            </p>
          )}
        </div>
        <div className="osp-container__actions">
          <button onClick={handleOnCloseClick} type="button">
            Book a call
          </button>
        </div>
        <img
          src={xIcon}
          alt="close_icon"
          className="osp-close-btn"
          aria-hidden="true"
          onClick={() => {
            handleOnCloseClick(false);
          }}
        />
      </div>
    </div>
  );
};

RemainingTrialDaysPopup.propTypes = {
  onClose: PropTypes.func,
  remainingDays: propTypes.string,
  onlyClose: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
};

export default RemainingTrialDaysPopup;
