import {
  REQUEST_ACCOUNTS,
  RECEIVE_ACCOUNTS,
  REMOVE_ACCOUNT,
  ADD_ACCOUNT,
  UPDATE_ACCOUNT,
  SET_CONNECTED_ACCOUNT,
  RESET_ACCOUNTS,
} from "../actions/accounts";

export const accountsByApp = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ACCOUNTS:
    case RECEIVE_ACCOUNTS:
    case REMOVE_ACCOUNT:
      return Object.assign({}, state, {
        [action.app_id]: accountsByType(state[action.app_id], action),
      });
    case ADD_ACCOUNT:
      return Object.assign({}, state, {
        [action.app_id]: accountsByType(state[action.app_id], action),
      });
    case UPDATE_ACCOUNT:
      return Object.assign({}, state, {
        [action.app_id]: accountsByType(state[action.app_id], action),
      });
    case SET_CONNECTED_ACCOUNT:
      return Object.assign({}, state, {
        [action.app_id]: accountsByType(state[action.app_id], action),
      });
    case RESET_ACCOUNTS:
      return {};
    default:
      return state;
  }
};

const accountsByType = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ACCOUNTS:
    case RECEIVE_ACCOUNTS:
    case REMOVE_ACCOUNT:
      return Object.assign({}, state, {
        [action.authorization_type_id]: accounts(
          state[action.authorization_type_id],
          action
        ),
      });
    case ADD_ACCOUNT:
      return Object.assign({}, state, {
        [action.authorization_type_id]: accounts(
          state[action.authorization_type_id],
          action
        ),
      });
    case UPDATE_ACCOUNT:
      return Object.assign({}, state, {
        [action.authorization_type_id]: accounts(
          state[action.authorization_type_id],
          action
        ),
      });
    case SET_CONNECTED_ACCOUNT:
      return Object.assign({}, state, {
        [action.authorization_type_id]: accounts(
          state[action.authorization_type_id],
          action
        ),
      });
    default:
      return state;
  }
};

const accounts = (
  state = {
    isFetching: false,
    fetchCount: 0,
    items: [],
    connectedAccount: null,
  },
  action
) => {
  switch (action.type) {
    case REQUEST_ACCOUNTS:
      return Object.assign({}, state, {
        isFetching: true,
        fetchCount: state.fetchCount + 1,
      });
    case RECEIVE_ACCOUNTS:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.accounts,
      });
    case REMOVE_ACCOUNT:
      return {
        ...state,
        items: state.items.filter(
          (account) => String(account.id) !== String(action.account_id)
        ),
      };
    case ADD_ACCOUNT:
      return {
        ...state,
        items: [...state.items, action.account],
      };
    case UPDATE_ACCOUNT:
      return {
        ...state,
        items: [
          ...state.items.filter(
            (account) => String(account.id) !== String(action.account.id)
          ),
          action.account,
        ],
      };
    case SET_CONNECTED_ACCOUNT:
      return {
        ...state,
        connectedAccount: action.account,
      };
    default:
      return state;
  }
};
