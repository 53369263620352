import http from "../../utils/http";

export const REQUEST_AUTH_TYPES = "REQUEST_AUTH_TYPES";

export const requestAuthTypes = () => ({
  type: REQUEST_AUTH_TYPES,
});

export const RECEIVE_AUTH_TYPES = "RECEIVE_AUTH_TYPES";

export const receiveAuthTypes = (json) => ({
  type: RECEIVE_AUTH_TYPES,
  auth_types: json,
});

export const fetchAuthTypes = () => {
  return (dispatch) => {
    dispatch(requestAuthTypes());

    return http.get("/api/auth_types/").then(
      (response) => {
        dispatch(receiveAuthTypes(response.data));
      },
      (error) => {
        console.log(
          "Error occurred while fetching auth types in fetchAuthTypes() action.",
          error
        );
      }
    );
  };
};
