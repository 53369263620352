// react component for split button
import { ReactElement } from "react";
import CheckboxSelection, {
  ICheckboxProps,
} from "components/integry-design-system/molecules/checkbox-selection-v2";
import CreatableDropdown from "components/integry-design-system/atoms/dropdown/creatable-dropdown/createable-dropdown";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import { capitalizeFirstLetter } from "utils/functions";
import ToggleSectionIcon from "components/integry-design-system/atoms/toggle-section-icon";

import "./styles.scss";

interface IProps {
  tooltipText?: string;
  placeholder?: string;
  options: ICheckboxProps[];
  value: string[];
  debounceTime?: number;
  className?: string;
  onChange: (value: string[]) => void;
}

const CheckboxDropdown = (props: IProps): ReactElement => {
  const {
    options,
    tooltipText,
    onChange,
    value,
    placeholder = "Select",
    debounceTime = 1000,
    className = "",
  } = props;
  // generate label for dropdown
  // if all options are selected, show "All Selected"
  // if no option is selected, show "None Selected"
  // if some options are selected, show "first Selected option lable + count"
  const getLabel = (): string => {
    if (value.length === 0) {
      return "";
    }
    if (value.length === 1) {
      return `${options.find((option) => option.value === value[0])?.label}`;
    }
    if (value.length === options.length) {
      return "All Selected";
    }
    return capitalizeFirstLetter(
      `${options.find((option) => option.value === value[0])?.label} + ${
        value.length - 1
      }`
    );
  };

  return (
    <div
      className={`checkbox-dropdown ${className}`}
      onClick={(e) => e.stopPropagation()}
    >
      <CreatableDropdown
        renderControl={({ isMenuOpen }) => {
          return (
            <IntegryTooltip
              tooltipText={tooltipText}
              hideToolTip={!tooltipText || isMenuOpen}
            >
              <>
                {value.length > 0 ? (
                  <div
                    className={`checkbox-dropdown__dropdown__control ${
                      value.length > 0 ? "highlight-border" : ""
                    } txt-sm-dark-gray ${isMenuOpen ? " is-open" : ""}`}
                  >
                    {getLabel()}
                    <ToggleSectionIcon isExpanded={!isMenuOpen} />
                  </div>
                ) : (
                  <div
                    className={`checkbox-dropdown__dropdown__control txt-sm-liight-gray ${
                      isMenuOpen ? " is-open" : ""
                    }`}
                  >
                    {placeholder}
                    <ToggleSectionIcon isExpanded={!isMenuOpen} />
                  </div>
                )}
              </>
            </IntegryTooltip>
          );
        }}
        renderMenu={() => (
          <div className="checkbox-dropdown__dropdown_menu integry-scrollbar-v2">
            <CheckboxSelection
              value={value}
              onOptionSelect={onChange}
              options={options}
              debounceTime={debounceTime}
            />
          </div>
        )}
      />
    </div>
  );
};

export default CheckboxDropdown;
