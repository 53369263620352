import "./styles.scss";

import { CSSProperties, HTMLAttributes } from "react";

import FilterPill, {
  IPill,
} from "components/integry-design-system/molecules/filter-pill";

export interface IProps extends HTMLAttributes<HTMLDivElement> {
  pills: Array<IPill>;
  customStyle?: CSSProperties;
  onClose?: (pill: IPill) => void;
  onClearClick?: () => void;
}

const GroupedFilterPills = ({
  customStyle = {},
  ...props
}: IProps): JSX.Element => {
  const { pills = [], onClose, onClearClick } = props;

  const handleOnClose = (pill: IPill): void => {
    if (onClose) {
      onClose(pill);
    }
  };

  const handleOnClearClick = (): void => {
    if (onClearClick) {
      onClearClick();
    }
  };

  return (
    <div className="integry-grouped-filter-pills" style={{ ...customStyle }}>
      {onClearClick && pills.length > 0 && (
        <span
          className="integry-grouped-filter-pills__clear-cta"
          onClick={() => {
            handleOnClearClick();
          }}
          aria-hidden="true"
        >
          Clear All
        </span>
      )}
      {pills.map((pill) => {
        return pill ? <FilterPill {...pill} onClose={handleOnClose} /> : <></>;
      })}
    </div>
  );
};

export default GroupedFilterPills;
