/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
/* eslint-disable react/no-danger */
import { HTMLAttributes, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { ICrumb } from "components/integry-design-system/molecules/breadcrumb";
import DashboardWrapper from "components/dashboard-wrapper";
import {
  integrationStatusLabels,
  integrationStatusColorCodes,
} from "legacy-features/integrations/integrations-view/integration-listing";
import { IPill } from "components/integry-design-system/molecules/filter-pill";
import Tag from "components/integry-design-system/atoms/tag";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";

import { DateRangeValue } from "components/integry-design-system/molecules/date-range-picker/date-range-v2";

import lang from "utils/lang/en";
import urls from "utils/router-urls";
import { useFetchUserQuery } from "utils/user/user-api";
import { getQueryParamAsObject, updatedQueryParams } from "utils/query-params";

import exlaimationIconOutline from "images/exclaimation_icon_outline.svg";

import { Run } from "../interface";
import { useGetIntegrationRunsQuery } from "../api/useIntegrationRuns";
import RunsListing from "./listing";
import RunViewGroupInfoHeader from "./grouped-info-header";
import RunSteps from "../../integration-run-steps/components";
import "./styles.scss";

interface IProps extends HTMLAttributes<HTMLDivElement> {
  integrationIdParam: string;
  runIdParam?: string;
  showBillableRunStatus?: boolean;
  defaultTab: "runs" | "polls";
}

type QueryParams = {
  search?: string;
  run_statuses?: string | string[];
  start_time_range_start?: string;
  start_time_range_end?: string;
  run_id?: string;
  poll_id?: string;
};

const RunsView = (props: IProps): JSX.Element => {
  const history = useHistory();
  const { integrationIdParam, runIdParam } = props;

  const [showPaginationLoader, setShowPaginationLoader] = useState(false);

  const [fetching, setFetching] = useState(true);
  const [nextToken] = useState("");
  const [filters, setFilters] = useState<Array<IPill> | [] | undefined | null>(
    []
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [sorting, setSorting] = useState<any>({
    start_time: "desc",
  });
  const [allowStepSearch, setAllowStepSearch] = useState(false);
  const [runsConfigs] = useState<Run[]>([]);
  const [runs, setRuns] = useState<Run[]>([]);
  const [selectedRunId, setSelectedRunId] = useState<string>(runIdParam || "");
  const [highlightedRow, setHighlightedRow] = useState<string>("");

  const [poolingInterval, setPollingInterval] = useState(0);

  const queryParams = getQueryParamAsObject() as QueryParams;

  const [runStartTimeRange, setRunStartTimeRange] =
    useState<DateRangeValue | null>(() => {
      const start = queryParams.start_time_range_start
        ? new Date(queryParams.start_time_range_start)
        : "";
      const end = queryParams.start_time_range_end
        ? new Date(queryParams.start_time_range_end)
        : "";

      if (start && end) {
        return {
          startDate: start,
          endDate: end,
          key: "selection",
        };
      }
      return null;
    });

  // If Run steps are opened from URL, we will pass run_id to API call to get only selcted run.
  // runIdFromUrl is visible in the serach bar. user can change value in search bar.
  // when user types in the search bar, we will remove the runIdFromUrl fitler and perform search on all runs.
  const [page, setPage] = useState(() => {
    let statuses: string[] = [];
    if (queryParams?.run_statuses) {
      statuses = Array.isArray(queryParams?.run_statuses)
        ? queryParams?.run_statuses
        : [queryParams?.run_statuses];
    }
    return {
      page: 1,
      pageSize: 20,
      searchTxt: queryParams?.search || "",
      runStatuses: statuses,
      networkCode: "",
      completedStatusNetworkCode: "",
      runId: runIdParam || queryParams?.run_id || "",
      pollId: queryParams?.poll_id || "",
    };
  });

  const pageNumberRef = useRef<number>(1);

  useEffect(() => {
    const previousRuns = showPaginationLoader ? runs : [];
    if (runsConfigs && !fetching) {
      setRuns([...previousRuns, ...runsConfigs]);
      setShowPaginationLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runsConfigs, fetching]);

  const queryStringRef = useRef("");

  const { data: user } = useFetchUserQuery();

  const updateURL = (queryParams: Partial<QueryParams>): void => {
    const updatedParams = {
      search: page.searchTxt,
      run_statuses: page.runStatuses,
      run_id: page.runId || "",
      start_time_range_start:
        runStartTimeRange?.[0]?.startDate.toISOString() || "",
      start_time_range_end: runStartTimeRange?.[0]?.endDate.toISOString() || "",
      ...queryParams,
    };
    updatedQueryParams({
      queryParams: updatedParams,
      replaceURL: true,
    });
  };

  const {
    data: paginatedRuns,
    isLoading,
    isFetching,
  } = useGetIntegrationRunsQuery(
    {
      integrationId: parseInt(integrationIdParam || "0", 10),
      page: page.page,
      pageSize: page.pageSize,
      search: page.searchTxt,
      runStatuses: page.runStatuses,
      showBillableRunStatus: !!props.showBillableRunStatus,
      networkCode: page.networkCode,
      completedStatusNetworkCode: page.completedStatusNetworkCode,
      start_time_range_start: runStartTimeRange?.startDate?.toISOString() || "",
      start_time_range_end: runStartTimeRange?.endDate?.toISOString() || "",
      run_id: page.runId || "",
      poll_id: page.pollId || "",
    },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: poolingInterval,
    }
  );

  useEffect(() => {
    setPollingInterval(0);
  }, [paginatedRuns?.count]);

  const { meta: runsMeta } = paginatedRuns || {};

  useEffect(() => {
    queryStringRef.current = window.location.search;
  }, []);

  const onRetryRun = (runId: string): void => {
    setPollingInterval(2000);
  };

  const handlePageState = (pageConfig): void => {
    // console.log("handlePageState", pageConfig);
    pageNumberRef.current = pageConfig.page;
    // setPage(pageConfig);
    setPage((prevState) => ({
      ...prevState,
      page: pageConfig.page,
      pageSize: pageConfig.pageSize,
    }));
  };

  const generateBreadcrumbConfig = (): Array<ICrumb> => {
    const tempCrumbs = [
      {
        label: `Integrations`,
        url: urls.integrations,
      },
      {
        label: `ID: ${integrationIdParam}`,
        url: urls.integrations,
      },
    ];
    return tempCrumbs;
  };

  const getIntegrationStatusLabel = (integrationStatus: string): string => {
    if (integrationStatusLabels[integrationStatus] === "Paused") {
      return "Blocked";
    }
    return integrationStatusLabels[integrationStatus] || integrationStatus;
  };

  const renderHeadingTitle = (integrationIdParam: string): JSX.Element => {
    const { integration } = runsMeta || {};
    if (!integration) {
      return <></>;
    }
    const {
      integrationId,
      integrationName,
      integrationStatus,
      integrationStatusTooltip,
    } = integration;
    return (
      <div className="integraion-view-heading">
        <div className="heading flex-column gap-5px">
          <div className="attribute-integration_status flex align-center flex-gap-10px">
            <h2 className="view-sub-heading">Integration: {integrationId} </h2>
            <div
              style={{
                color:
                  integrationStatusColorCodes[integrationStatus.toLowerCase()],
              }}
            >
              <div>
                <Tag
                  label={getIntegrationStatusLabel(integrationStatus)}
                  type="integration"
                />
              </div>
              {}
            </div>

            {integrationStatusTooltip !== "N/A" && (
              <IntegryTooltip
                tooltipText={integrationStatusTooltip}
                hideToolTip={!integrationStatusTooltip}
              >
                <img
                  src={exlaimationIconOutline}
                  style={{
                    width: "15px",
                    height: "15px",
                    marginLeft: "5px",
                    transform: "rotateZ(180deg)",
                    cursor: "pointer",
                  }}
                  alt="tooltip_heading"
                />
              </IntegryTooltip>
            )}
          </div>

          <div>
            <h3 className="app-name">{integrationName}</h3>
          </div>
        </div>
      </div>
    );
  };

  const onRowSelect = (run: Run): void => {
    setSelectedRunId(run.id);
    setHighlightedRow(run.id);
    history.push(
      urls.runTasks
        .replace(":integrationId", integrationIdParam)
        .replace(":runId", run.id)
    );
  };

  const renderGroupedInfoHeader = (): JSX.Element => (
    <RunViewGroupInfoHeader
      showPollData
      integrationConfig={runsMeta?.integration}
      template={runsMeta?.template || null}
    />
  );

  const tableConfig = {
    config: [
      {
        id: "id",
        label: "ID",
        truncate: true,
        allowCopy: true,
        tdStyle: {
          whiteSpace: "break-spaces",
          maxWidth: "330px",
          minWidth: "120px",
          wordBreak: "keep-all",
        },
      },
      {
        id: "start_time",
        label: "Start Time",
        allowSorting: true,
        sortingIdentifier: "start_time",
        showTooltip: true,
        tooltipField: "start_time_full",
        hideSameValueTooltip: true,
      },
      {
        id: "trigger_app",
        subConfig: [
          {
            id: "icon_url",
            type: "img",
          },
          {
            id: "run_title",
            style: {
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
          },
        ],
        itemStyle: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          overflow: "hidden",
        },
        tdStyle: {
          maxWidth: "unset",
        },
        label: "Trigger",
        //  allowSorting: true,
        sortingIdentifier: "title",
        //  hide: flowConfig?.isDirectAction,
      },

      {
        id: "steps_count",
        label: "Steps",
      },
      {
        id: "duration_string",
        label: "Duration",
      },
      {
        id: "status",
        label: "Status",
        type: "tag",
        subColumnId: "network_code",
        subColumnType: "code",
        tooltip: (
          <div
            dangerouslySetInnerHTML={{ __html: lang.LAST_RUN_STATUS_TOOLTIP }}
          />
        ),
        tooltip_nonBillable: (
          <div
            dangerouslySetInnerHTML={{
              __html: lang.LAST_RUN_STATUS_TOOLTIP_OLD,
            }}
          />
        ),
      },
    ],
  };

  const onFilterChange = (filters): void => {
    setPage((prevState) => ({
      ...prevState,
      ...filters,
      page: 1,
      pageSize: 20,
    }));
  };

  return (
    <DashboardWrapper user={user} className="" alertBar>
      <div className="user-integration-runs-wrapper integration-runs-v2">
        <RunsListing
          {...props}
          flowId={runsMeta?.template.id || ""}
          tableConfig={tableConfig}
          generateBreadcrumbConfig={generateBreadcrumbConfig}
          renderHeadingTitle={renderHeadingTitle}
          renderGroupedInfoHeader={renderGroupedInfoHeader}
          setFetching={setFetching}
          loading={isLoading || (isFetching && page.page === 1)}
          fetching={poolingInterval > 0}
          totalCount={paginatedRuns?.count || 0}
          template={runsMeta?.template}
          integrationConfig={runsMeta?.integration}
          runsConfigs={paginatedRuns?.results || []}
          nextToken={nextToken}
          showPaginationLoader={showPaginationLoader}
          hasMore={!!paginatedRuns?.has_more}
          setShowPaginationLoader={setShowPaginationLoader}
          runs={paginatedRuns?.results || []}
          searchTxt={page.runId || page.searchTxt}
          setSearchTxt={(searchString) => {
            updateURL({ search: searchString, run_id: "", poll_id: "" });
            onFilterChange({ searchTxt: searchString, runId: "", pollId: "" });
            // setPage({ page: 1, pageSize: 10, searchTxt: searchString });
          }}
          networkCode={page.networkCode}
          setNetworkCode={(value) => {
            onFilterChange({ networkCode: value });
          }}
          completedStatusNetworkCode={page.completedStatusNetworkCode}
          setCompletedStatusNetworkCode={(value) => {
            onFilterChange({ completedStatusNetworkCode: value });
          }}
          filters={filters}
          setFilters={setFilters}
          sorting={sorting}
          setSorting={setSorting}
          allowStepSearch={allowStepSearch}
          setAllowStepSearch={setAllowStepSearch}
          page={page}
          setPage={handlePageState}
          onRowSelect={onRowSelect}
          runStatuses={page.runStatuses}
          runStartTimeRange={runStartTimeRange}
          isPollBasedIntegration={!!runsMeta?.integration?.lastPollId}
          setRunStartTimeRange={(value) => {
            updateURL({
              start_time_range_start: value?.startDate?.toISOString() || "",
              start_time_range_end: value?.endDate?.toISOString() || "",
            });
            setPage((prevState) => ({ ...prevState, page: 1, pageSize: 10 }));
            setRunStartTimeRange(value);
          }}
          setRunStatuses={(status) => {
            updateURL({ run_statuses: status });
            if (!status.includes("FAILED")) {
              onFilterChange({ runStatuses: status, networkCode: "" });
            } else if (!status.includes("COMPLETED")) {
              onFilterChange({
                runStatuses: status,
                completedStatusNetworkCode: "",
              });
            } else {
              onFilterChange({ runStatuses: status });
            }
            // setPage((prevState) => ({ ...prevState, page: 1, pageSize: 10 }));
            // setRunStatuses(status);
          }}
          onRetryRun={onRetryRun}
          selectedRunId={highlightedRow}
          hasRuns={!!paginatedRuns?.meta.has_runs}
          defaultTab={props.defaultTab}
        />
        {runsMeta?.template && runsMeta?.integration && selectedRunId && (
          <RunSteps
            flowId={runsMeta?.template.id}
            integrationId={runsMeta?.integration.integrationId}
            runId={selectedRunId}
            onClose={() => {
              history.push(
                urls.integrationRuns.replace(
                  ":integrationId",
                  integrationIdParam
                )
              );
              updateURL({}); // map current state to query params after redirecting to integration runs page
              setSelectedRunId("");
            }}
          />
        )}
      </div>
    </DashboardWrapper>
  );
};
export default RunsView;
