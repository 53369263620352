import { ReactElement, CSSProperties } from "react";
import ReactModal from "react-modal";

import Button from "components/common/reusable-button";

import CancelButton from "images/cross.svg";

import "./popup-container.scss";

export interface PopupContainerProps {
  onButtonClick?: () => void;
  buttonClassName?: string;
  buttonText?: string;
  title: ReactElement | string;
  content: ReactElement | string;
  popupIcon: ReactElement;
  close: () => void;
  isOpen: boolean;
  buttonSectionContent?: ReactElement;
  overlayClassName?: string;
  contentClassName?: string;
  shouldCloseOnOverlayClick?: boolean;
  headerStyle?: CSSProperties;
}

const PopupContainer = (props: PopupContainerProps): ReactElement => {
  const {
    onButtonClick,
    isOpen,
    close,
    popupIcon,
    title,
    content,
    buttonText,
    buttonClassName,
    buttonSectionContent,
    overlayClassName,
    contentClassName,
    shouldCloseOnOverlayClick = false,
    headerStyle = {},
  } = props;
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={close}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      overlayClassName={`IntegryModal__Overlay integry-pop-overlay ${overlayClassName}`}
      className={`IntegryModal__Content alert-modal integry-popup ${contentClassName}`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          className="popup-close-button"
          onClick={close}
          onKeyDown={close}
          tabIndex={-1}
          role="button"
        >
          <img
            src={CancelButton}
            alt="Cancel"
            className="float-right cursor-pointer"
          />
        </div>

        <div className="allign-center base-image"> {popupIcon}</div>

        <h2 className="popup-title text-center" style={headerStyle || {}}>
          {title}
        </h2>
        <div className="message-content text-center">
          <>{content}</>
        </div>
        <div className="text-center">
          {buttonSectionContent || (
            <Button
              className={`action-button allign-center ${buttonClassName || ""}`}
              onClick={onButtonClick || close}
              title={buttonText || "OK"}
            />
          )}
        </div>
      </div>
    </ReactModal>
  );
};

export default PopupContainer;
