import { lazy, ReactElement } from "react";
import { Route, Switch } from "react-router-dom";

import { useFetchUserQuery } from "utils/user/user-api";
import { TemplateType } from "features/templates/interface/templateType";
import urls from "utils/router-urls";

const FunctionList = lazy(() => import("./FunctionListing/FunctionListing"));
const ConfigureTemplate = lazy(
  () => import("features/templates/template-builder-v7/configure-template")
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Functions = ({ match }): ReactElement => {
  const { data: user } = useFetchUserQuery();

  return (
    <Switch>
      <Route path={`${match.url}/`} exact render={() => <FunctionList />} />
      <Route
        path={urls.functionCreate}
        exact
        render={(routeProps) => (
          <ConfigureTemplate
            scenario="CREATE"
            user={user}
            {...routeProps}
            type={TemplateType.FUNCTION}
          />
        )}
      />
      <Route
        path={urls.functionEdit}
        exact
        render={(routeProps) => (
          <ConfigureTemplate
            scenario="EDIT"
            user={user}
            {...routeProps}
            type={TemplateType.FUNCTION}
          />
        )}
      />
      {/*     <Route
      path={match.url + "/create/"}
      render={(routeProps) => (
        <ConfigureTemplate scenario="CREATE" user={user} {...routeProps} />
      )}
    />
    <Route
      path={match.url + "/:templateId/edit/"}
      render={(routeProps) => (
        <ConfigureTemplate scenario="EDIT" user={user} {...routeProps} />
      )}
    /> */}
    </Switch>
  );
};

export default Functions;
