import { track as trackEvent } from "utils/mixpanel";
import { useFetchUserQuery } from "utils/user/user-api";

interface propertiesType {
  [x: string]: string | number | null | undefined;
}
const useTrack = (): {
  track: (eventName: string, properties?: propertiesType) => void;
} => {
  const { data: user } = useFetchUserQuery();
  const track = (eventName: string, properties?: propertiesType): void => {
    trackEvent(eventName, user, properties || {});
  };
  return { track };
};

export default useTrack;
