/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { ReactElement, useState } from "react";

import { Button } from "@mui/material";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";

import CopyIcon from "images/copy-icon-editor.svg";

interface IProps {
  textToCopy: () => string | string;
  showOnlyIcon?: boolean;
  tooltipText?: string;
}

const CopyButton = ({
  textToCopy,
  showOnlyIcon = true,
  tooltipText = "Copy",
}: IProps): ReactElement => {
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const copyLink = (): void => {
    const text = typeof textToCopy === "function" ? textToCopy() : textToCopy;
    navigator.clipboard.writeText(text);
    setIsLinkCopied(true);
    setTimeout(() => {
      setIsLinkCopied(false);
    }, 5000);
  };

  return (
    <IntegryTooltip
      tooltipText={isLinkCopied ? "Copied" : tooltipText}
      placement="bottom"
    >
      <div className="copy-icon">
        {showOnlyIcon ? (
          <img
            src={CopyIcon}
            alt="copy_button"
            onClick={copyLink}
            className="cursor-pointer"
          />
        ) : (
          <Button
            style={{
              color: "#4250F0",
              marginRight: "-7px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "15px",
              textAlign: "right",
              textTransform: "none",
            }}
            endIcon={<img src={CopyIcon} alt="copy_link" />}
            size="small"
            onClick={copyLink}
          >
            Copy Link
          </Button>
        )}
      </div>
    </IntegryTooltip>
  );
};

export default CopyButton;
