import http from "../../utils/http";
import uniqBy from "lodash/uniqBy";

export const REQUEST_BUNDLES = "REQUEST_BUNDLES";

export const requestBundles = (appId) => ({
  type: REQUEST_BUNDLES,
  appId,
});

export const RECEIVE_BUNDLES = "RECEIVE_BUNDLES";

export const receiveBundles = (json, appId) => ({
  type: RECEIVE_BUNDLES,
  bundles: json,
  appId,
  appsHavingBundles: uniqBy(
    json.map((bundle) => bundle.app_instance),
    "id"
  ),
});

export const fetchBundles = (appId) => {
  return (dispatch) => {
    if (appId) {
      dispatch(requestBundles(appId));
      return http.get(`/api/bundles/?app_id=${appId}`).then(
        (response) => {
          dispatch(receiveBundles(response.data, appId));
        },
        (error) => {
          console.log(
            "Error occurred while fetching bundles in fetchBundles() action.",
            error
          );
        }
      );
    }
  };
};

export const BUNDLES_SET_APP_FILTER = "BUNDLES_SET_APP_FILTER";

export const setAppFilter = (app_id) => ({
  type: BUNDLES_SET_APP_FILTER,
  app_id,
});

export const DEPLOYMENT_CARD_LOADER = "DEPLOYMENT_CARD_LOADER";
export const deploymentCardLoader = (flag) => ({
  type: DEPLOYMENT_CARD_LOADER,
  deploymentCardLoader: flag,
});
