import { useState, useEffect, useRef, ReactElement } from "react";
import { useInView } from "react-intersection-observer";

import PlaceholderBar from "atomic-components/atom/placeholder-bar";
import IntegryPopover from "components/integry-design-system/atoms/integry-popover-v2";
import ToggleSectionIcon from "components/integry-design-system/atoms/toggle-section-icon";

import useDebounced from "hooks/useCancleableDebounce";

import { ReactComponent as BlueTick } from "images/turbo.v2/blue-tick.svg";
import LoadingIcon from "../button/LoadingIcon";

import "./AsyncDropdown.scss";

interface Option {
  id: string | number;
  label: string;
}

export interface LoadOptionsResponse {
  options: Option[];
  hasMore?: boolean;
}

interface AsyncDropdownProps {
  placeholder?: string;
  isDisabled?: boolean;
  defaultValue?: string;
  addBottomBorderOnFirstOption?: boolean;
  menuFooter?: ReactElement | null;
  onChange?: (option: Option) => void;
  loadOptions?: ({
    search,
    page,
  }: {
    search: string;
    page: number;
  }) => Promise<LoadOptionsResponse>;
}

const AsyncDropdown = ({
  placeholder = "Search...",
  isDisabled = false,
  addBottomBorderOnFirstOption = false,
  defaultValue = "",
  menuFooter = null,
  onChange,
  loadOptions,
}: AsyncDropdownProps): ReactElement => {
  const [searchString, setSearchString] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const controlRef = useRef<HTMLDivElement>(null);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  useEffect(() => {
    fetchOptions({
      search: searchString,
      pageNumber: page,
      setDefaultOptions: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getElementWidth = (element): string => {
    if (element?.getBoundingClientRect) {
      const rect = element.getBoundingClientRect();
      return `${rect.width}px`;
    }
    return "auto";
  };

  const fetchOptions = async ({
    search = searchString,
    pageNumber = 1,
    setDefaultOptions = false,
  }: {
    search?: string;
    pageNumber?: number;
    setDefaultOptions?: boolean;
  }): Promise<void> => {
    if (loadOptions) {
      setIsLoading(true);
      const { options: newOptions, hasMore: newHasMore = false } =
        await loadOptions({
          search,
          page: pageNumber,
        });
      if (setDefaultOptions) {
        const defaultOption = newOptions.find(
          (option) =>
            option.id === defaultValue || option.label === defaultValue
        );
        if (defaultOption) {
          setSelectedOption(defaultOption);
        }
      }
      setOptions(newOptions);
      setHasMore(newHasMore);
      setIsLoading(false);
    }
  };

  const fetchNextPage = async (): Promise<void> => {
    if (loadOptions) {
      const { options: newOptions, hasMore: newHasMore = false } =
        await loadOptions({
          search: searchString,
          page: page + 1,
        });
      setOptions([...options, ...newOptions]);
      setHasMore(newHasMore);
      setPage(page + 1);
    }
  };

  const handleInputChange = (value: string): void => {
    setPage(1);
    fetchOptions({
      search: value,
      pageNumber: 1,
    });
  };
  const [debouncedSearch] = useDebounced(handleInputChange, 300);

  const handleFocus = (): void => {
    setShowDropdown(true);
  };

  const handleOptionClick = (option: Option): void => {
    setSelectedOption(option);
    setShowDropdown(false);
    setSearchString(""); // Clear search string
    if (onChange) onChange(option);
  };

  return (
    <div className="async-dropdown" ref={controlRef}>
      <IntegryPopover
        show={showDropdown}
        close={() => {
          if (searchString) {
            setSearchString("");
            debouncedSearch("");
          }
          setShowDropdown(false);
        }}
        containerStyle={{ zIndex: "10005", top: "10px" }}
        positions={["bottom"]}
        hideArrow
        popoverContent={
          <div
            style={{
              width: getElementWidth(controlRef.current),
            }}
            className="async-dropdown__menu"
          >
            <div className="integry-scrollbar-v2 async-dropdown__menu-options">
              {options.map((option, index) => (
                <div
                  key={option.id}
                  onClick={() => handleOptionClick(option)}
                  style={{ padding: "8px", cursor: "pointer" }}
                  className={`txt-sm-dark-gray async-dropdown__option ${
                    addBottomBorderOnFirstOption && index === 0 && !searchString
                      ? "bottom-border"
                      : ""
                  } ${selectedOption?.id === option.id ? "selected" : ""}`}
                >
                  {option.label}
                  {selectedOption?.id === option.id && (
                    <BlueTick className="async-dropdown__selected-icon" />
                  )}
                </div>
              ))}

              {options.length === 0 && !isLoading && (
                <div style={{ padding: "8px" }} className="txt-sm-liight-gray">
                  {searchString ? "No options found" : "No options"}
                </div>
              )}

              {hasMore && (
                <div
                  ref={ref}
                  style={{
                    padding: "8px",
                  }}
                >
                  <PlaceholderBar
                    customStyle={{
                      width: "80%",
                      margin: "unset",
                      borderRadius: "4px",
                    }}
                  />
                </div>
              )}
            </div>

            {menuFooter && (
              <div
                className="async-dropdown__menu-footer"
                onClick={() => setShowDropdown(false)}
              >
                {menuFooter}
              </div>
            )}
          </div>
        }
      >
        <div
          className={`async-dropdown__control ${isDisabled ? "disabled" : ""}`}
        >
          <input
            type="url"
            autoComplete="off"
            title=""
            disabled={isDisabled}
            value={showDropdown ? searchString : selectedOption?.label || ""}
            onChange={(e) => {
              const { value } = e.target;
              setSearchString(value);
              debouncedSearch(value);
            }}
            onFocus={handleFocus}
            placeholder={
              showDropdown ? selectedOption?.label || placeholder : placeholder
            }
            style={{ width: "100%", boxSizing: "border-box" }}
            className="txt-sm-dark-gray async-dropdown__control-input"
          />
          {isLoading ? (
            <LoadingIcon color="#4250F0" />
          ) : (
            <ToggleSectionIcon
              isExpanded={!showDropdown}
              onClick={() => setShowDropdown(!showDropdown)}
            />
          )}
        </div>
      </IntegryPopover>
    </div>
  );
};

export default AsyncDropdown;
