import config from "./config";
import http from "./http";

export const track = (eventName, user, extraProperties = {}) => {
  const event = {
    event: eventName,
    properties: {
      "User ID": user.id,
      "User Email": user.email,
      Username: user.username,
      "Account ID": user.account_id,
      "Account Name": user.account_name,
      ...extraProperties,
    },
  };

  http
    .post(config.mixpanelEndpoint, [event])
    .then((response) => {
      // console.log('Mixpanel event tracked successfully.', event);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const connectorTrackEvents = {
  initiation: "Connector creation Initiated",
  info_added: "Basic Connector Info Added",
  auth_test: "Auth Tested",
  activity_test: "Activity Tested",
};

/** https://docs.google.com/spreadsheets/d/1b5YzfMe2T7yHQhUPhnI14mI2mg7s6kgv/edit#gid=699661610 */
export const turboMixpanelEvents = {
  SELECTED_CONNECTOR_NAME: {
    property: "selected_connector_name",
    name: "Select a connector",
  },
  FLOW_REQUEST: {
    property: "flow_request",
    name: "Request a flow",
  },
  CONNECTOR_REQUEST: {
    property: "connector_request",
    name: "Request a connector",
  },
  SELECTED_FLOW_NAME: {
    property: "selected_flow_name",
    name: "Select a flow",
  },
  SCHEDULE_ONBOARDING_CALL: {
    name: "Schedule onboarding call",
    property: {
      CALL_TIMESTAMP: "call_timestamp",
      SOURCE_PAGE: "source_page",
      MEETING_STATUS: "meeting_status",
    },
  },
  SKIP_SCHEDULING_CALL: {
    name: "Skip scheduling a call",
    property: "calendly-widget__actions",
  },
  FILL_DEMOGRAPHIC_SURVEY: {
    name: "Fill demographic survey",
    property: {
      COMPANY_WEBSITE: "company_website",
      PLATFORM_TYPE: "platform_type",
      COMPANY_SIZE: "company_size",
      ROLE: "role",
    },
  },
  SEARCH_CONNECTOR: {
    name: "Search for a connector",
    property: "search_term",
  },
  SEARCH_CONNECTOR_CONNECTOR_NOT_FOUND: {
    name: "Connector not found",
    property: "search_term",
  },
  SCHEDULE_SUPPORT_CALL: {
    name: "Schedule support call",
    property: "source_page",
  },
  GOTO_CONNECTORS_CTA_CLICK: {
    name: "Go to Connectors from confirmation pop-up",
    property: "connectors",
  },
  SEARCH_CONNECTOR_LISTING: {
    name: "Search for a connector",
    property: "search_term",
  },
  SORT_CONNECTOR_LISTING: {
    name: "Sort connectors list",
    property: "sort_by",
  },
};

export const integrationViewEvents = {
  VIEW_INTEGRAITONS_LINK_CLICK_OLD: {
    property: "all_integrations",
    trigger: "User clicks on All Integrations",
    definition:
      "Accessing All Integrations from the kebab menu on flow card on the flow listing page",
    type: "Event Property",
    name: "View All Integrations",
    note: "This was made obsolete on July 2022",
  },
  VIEW_INTEGRAITONS_LINK_CLICK: {
    property: "all_integrations",
    properties: {
      ALL_INTEGRATIONS: "all_integrations",
      SOURCE: "source",
      FLOW_NAME: "flow_name",
    },
    trigger: "User clicks on All Integrations",
    definition:
      "Accessing All Integrations from the kebab menu on flow card on the flow listing page",
    type: "Event Property",
    name: "View All Integrations",
  },
  SEARCH_INTEGRAITON: {
    name: "Search for an Integration",
    trigger:
      "User searches for an integration inside the All Integrations page",
    definition: "1 character typed in the search field",
    type: "Event Property",
    property: "search_term",
  },
  FILTER_INTEGRAITON: {
    name: "Filter Integrations",
    trigger: "User clicked on Apply in the Filter section",
    definition:
      "Under the filter section user selected, Date created, Last Run Start, Last Run Status, Integration Status and clicked apply",
    type: "Event Property",
    property: "filter_name",
  },
  ENABLE_INTEGRAITON: {
    name: "Enable Integration",
    trigger: "User clicked on enable",
    definition:
      "User clicked on enable from the kebab menu on all Integration page",
    type: "Event Property",
    property: "status",
    properties: {
      STATUS: "status",
      FLOW_NAME: "flow_name",
      INTEGRATION_ID: "integration_id",
    },
  },
  DISABLE_INTEGRAITON: {
    name: "Disable Integration",
    trigger: "User clicked on enable",
    definition:
      "User clicked on disable from the kebab menu on all Integration page then clicked on disable on the confirmation pop up",
    type: "Event Property",
    property: "status",
    properties: {
      STATUS: "status",
      FLOW_NAME: "flow_name",
      INTEGRATION_ID: "integration_id",
    },
  },
  DELETE_INTEGRAITON: {
    name: "Delete Integration",
    trigger: "User clicked on delete",
    definition:
      "User clicked on delete from the kebab menu on all Integration page then clicked on delete on the confirmation pop up",
    type: "Event Property",
    property: "status",
    properties: {
      STATUS: "status",
      FLOW_NAME: "flow_name",
      INTEGRATION_ID: "integration_id",
    },
  },
  VIEW_RUNS: {
    name: "View Runs",
    trigger: "User clicked on View Runs",
    definition: "User clicked on View Runs button on all Integration page",
    type: "Event Property",
    property: "view_runs",
    properties: {
      FLOW_NAME: "flow_name",
      INTEGRATION_ID: "integration_id",
    },
  },
  SEARCH_RUNS: {
    name: "Search for a Run",
    trigger: "User searches for a run inside the Run view section",
    definition: "1 character typed in the search field",
    type: "Event Property",
    property: "search_term",
  },
  FILTER_RUNS: {
    name: "Filter Runs",
    trigger: "User clicked on Apply in the Filter section",
    definition:
      "Under the filter section user selected, Start Time, Last Run Status, Netwrok code and clicked apply",
    type: "Event Property",
    property: "filter_name",
  },
  VIEW_STEPS: {
    name: "View Steps",
    trigger: "User clicked on View Steps",
    definition: "User clicked on View Steps button under the Runs view section",
    type: "Event Property",
    property: "view_steps",
    defaultValue: "view Steps",
    properties: {
      FLOW_NAME: "flow_name",
      RUN_ID: "run_id",
      INTEGRATION_ID: "integration_id",
    },
  },
  VIEW_STEP_PAYLOAD: {
    name: "View Payload",
    trigger: "User clicked on View Payload",
    definition:
      "User clicked on View Payload button under the Steps view section",
    type: "Event Property",
    property: "view_payload",
    defaultValue: "view Payload",
    properties: {
      FLOW_NAME: "flow_name",
      RUN_ID: "run_id",
      INTEGRATION_ID: "integration_id",
      STEP_ID: "step_id",
    },
  },
  VIEW_POLL_DETAILS: {
    name: "View Poll Details",
    trigger: `User clicks "View Details" for last poll status`,
    definition: `On the Integration page, user clicks on "View
      Details" next to the last poll status`,
    properties: {
      INTEGRATION_ID: "integration_id",
      NETWORK_CODE: "network_code",
    },
  },
  HOVER_ON_BLOCK_STATUS: {
    name: "Hovered on Block status",
    trigger: `Users hovers on Blocked status`,
    definition: `Users sees a Blocked integration and hovers
    on the i icon next to it`,
    properties: {
      INTEGRATION_ID: "integration_id",
      BLOCKED_REASON: "blocked_reason",
    },
  },
};

export const errorNotificationEvents = {
  BEGIN_ERROR_NOTIFICATION_SETUP: {
    name: "Begin error notification setup",
    property: "flow_name",
  },
  ERROR_NOTIFICATION_SETUP_COMPLETED: {
    name: "Error notification successfully setup",
    property: "flow_name",
  },
  MUTE_ERROR_NOTIFICATION_SETUP: {
    name: "Mute error notifications",
    property: "mute_interval",
  },
  SEND_NOTIFICATION_TO_URL: {
    name: "Send notifications to a URL",
    property: "notification_url",
  },
  DELETE_NOTIFICATION_APP: {
    name: "Delete a notification app",
    property: "flow_name",
  },
  GO_TO_ERROR_NOTIFICATIONS: {
    name: "Go to error notifications",
    trigger: "User clicked the link in Integration View",
    definition: "User clicked on 'Set up Notifications'",
    type: "Event Property",
    property: "snackbar_source_page",
    defaultValue: "runs view",
  },
  CONTINUE_TO_ERROR_NOTIFICATIONS_FROM_DIALOG: {
    name: "Continue to error notifications from dialog",
    trigger: "User confirmed notification set up dialog",
    definition: "User clicked on 'Yes' in the 'Set up notifications' dialog",
    type: "Event Property",
    property: "snackbar_source_page",
    defaultValue: "runs view",
  },
  DISMISS_ERROR_NOTIFICATIONS_SNACKBAR: {
    name: "Dismiss error notifications snackbar",
    trigger: "User clicked X on the snackbar",
    definition: "User dismissed the snackbar",
    type: "Event Property",
    property: "snackbar_source_page",
    defaultValue: "runs view",
  },
  DISABLE_NOTIFICATIONS_FOR_FLOW: {
    name: "Disable notifications for a flow",
    trigger: "User disables notifications for a flow in the flow builder",
    definition:
      'User turns the "Error notifications" toggle off for a flow in the flow builder',
    type: "Event Property",
    property: "flow_name",
    defaultValue: "Import Contacts from Sendinblue",
  },
};

export const configureOAuthAppEvents = {
  GOTO_MARKETPLACE_APPS: {
    name: "Go to apps in marketplace settings",
    trigger: "User clicked on apps",
    definition:
      "User clicked on the apps tab on their marketplace settings page",
    type: "Event Property",
    properties: {
      APPS_MARKETPLACE: "apps_marketplace",
      SOURCE: "source",
    },
    definition: "What did user do after clicking on apps tab",
  },
  GOTO_MARKETPLACE_APPS_REQUEST: {
    name: "Go to app requests in marketplace settings",
    trigger: "User clicked on app requests",
    definition:
      "User clicked on the app requests tab on their marketplace settings page",
    type: "Event Property",
    properties: {
      APPS_MARKETPLACE: "apps_request_marketplace",
      SOURCE: "source",
    },
    definition: "What did user do after clicking on apps tab",
  },
  CONFIGURE_OAUTH_APP: {
    name: "Configure an OAuth app",
    trigger: "User clicked on configure",
    definition:
      "User clicked on an app in the OAuth section on the marketplace settings page",
    type: "Event Property",
    properties: {
      SOURCE: "source",
      BUNDLE_ID: "bundle_id",
      APP_ID: "app_id",
    },
    definition: "User picked an OAuth app to White label",
  },
  SWITCH_WHITELABEL_AUTH: {
    name: `Switch to White labeled Auth`,
    trigger: `User clicked on "White-labeled Auth"`,
    definition: "User switched from Default auth to Whitelabeled Auth",
    type: "Event Property",
    properties: {
      BUNDLE_ID: "bundle_id",
      APP_ID: "app_id",
    },
  },
  SWITCH_DEFAULT_AUTH: {
    name: "Switch to Default Auth",
    trigger: `User clicked on "Default Auth"`,
    definition: "User switched from Whitelabeled Auth to Default auth",
    type: "Event Property",
    properties: {
      BUNDLE_ID: "bundle_id",
      APP_ID: "app_id",
    },
  },
  COPY_REDIRECT_URL: {
    name: "Copy Redirect URL",
    trigger: `User clicked on "copy" Redirect Url`,
    definition: "User clicked on the clipboard/copy icon of the redirect url",
    type: "Event Property",
    properties: {
      APP_ID: "app_id",
      BUNDLE_ID: "bundle_id",
    },
  },
  ENTER_CLIENT_ID: {
    name: "Enter Client ID",
    trigger: `User entered text in "Client ID" field`,
    definition: "User typed text in Client ID field",
    type: "Event Property",
    properties: {
      APP_ID: "app_id",
      BUNDLE_ID: "bundle_id",
      CLIENT_ID: "client_id",
    },
  },
  ENTER_CLIENT_SECRET: {
    name: "Enter Client Secret",
    trigger: `User entered text in "Client Secret" field`,
    definition: "User typed text in Client Secret field",
    type: "Event Property",
    properties: {
      APP_ID: "app_id",
      BUNDLE_ID: "bundle_id",
      CLIENT_SECRET: "client_secret",
    },
  },
  INITIATE_TEST: {
    name: "Initiate Test",
    trigger: `User tested the configuration`,
    definition: "User clicked the test button",
    type: "Event Property",
    properties: {
      APP_ID: "app_id",
      BUNDLE_ID: "bundle_id",
      CLIENT_ID: "client_id",
      CLIENT_SECRET: "client_secret",
      SCOPE: "scope",
    },
  },
  VIEW_PAYLOAD: {
    name: "View Payload of a White labeled Auth Step",
    trigger: `User clicked on View Payload`,
    definition:
      "User clicked on View Payload button under the Steps section in the white label auth screen",
    type: "Event Property",
    properties: {
      APP_ID: "app_id",
      BUNDLE_ID: "bundle_id",
      AUTH_STEP: "auth_step",
      AUTH_EVENT: "auth_event",
    },
  },
  ENTER_SCOPES: {
    name: "Enter Scopes",
    trigger: `User entered text in the Scopes field`,
    definition: "User entered Scopes in the scopes field",
    type: "Event Property",
    properties: {
      APP_ID: "app_id",
      BUNDLE_ID: "bundle_id",
      SCOPE: "scope",
    },
  },
  VIEW_LEARN_MORE_SCOPES_CLICK: {
    name: "View Learn about Scopes Link",
    trigger: `User gets redirected to the Learn about Scopes page`,
    definition: "User clicked on Learn about Scopes link",
    type: "Event Property",
    properties: {
      APP_ID: "app_id",
      BUNDLE_ID: "bundle_id",
    },
  },
};

export const marketplaceTurboEvents = {
  TELL_US_MORE: {
    name: "IU App Requests",
    trigger: "User requests for a missing app",
    type: "Event Property",
    properties: {
      APP_NAME: "app_name",
      USECASE: "usecase",
      SOURCE: "source",
      REQUESTED_BY: "requested_by",
    },
  },
};

export const actionOnboardingEvents = {
  ONBOARDING_VIDEO_PLAY: {
    name: "Play onboarding video",
    trigger: "IU watches onboarding video",
    type: "Event Property",
  },

  ONBOARDING_GUIDE_VIEW: {
    name: "View onboarding guide",
    trigger: "IU clicks the link to read onboarding guide",
    type: "Event Property",
  },

  DETAILED_PRICING_PLAN_VIEW: {
    name: "View detailed pricing plan",
    trigger: "IU clicks to view detailed pricing plan",
    type: "Event Property",
  },

  APP_FLOWS_VIEW: {
    name: "View app flows",
    trigger: "IU clicks to view app flows",
    type: "Event Property",
  },
};

export const addActionsEvents = {
  // event for app search
  APP_SEARCH: {
    name: "Search for an app",
    trigger: "User searches for an app",
    type: "Event Property",
    properties: {
      SEARCH_TERM: "search_term",
      APP_NAME: "app_name",
    },
  },
  // event for App not found
  APP_NOT_FOUND: {
    name: "App not found",
    trigger: "We couldn't find the app that the user searched for",
    type: "Event Property",
    properties: {
      SEARCH_TERM: "search_term",
    },
  },
  // event for Request an app
  REQUEST_AN_APP: {
    name: "Tell us more to request an app",
    trigger: "User requests for a missing app",
    type: "Event Property",
    properties: {
      connector_request: "connector_request",
      flow_request: "flow_request",
      source_name: "source_name",
    },
  },
  ADD_AN_ACTION: {
    name: "Add action to account",
    trigger: "User add action to account",
    type: "Event Property",
    properties: {
      ACTION_NAME: "action_name",
      APP_NAME: "app_name",
    },
  },
  ACTION_SEARCH: {
    name: "Search for an action",
    trigger: "User searches for an action",
    type: "Event Property",
    properties: {
      ACTION_NAME: "action_name",
      APP_NAME: "app_name",
    },
  },
  ADD_AN_ACTION_CTA_CLICK: {
    name: "Click Add Actions CTA on Actions listing",
    trigger: "IU clicks Add Actions CTA",
    type: "Event Property",
    properties: {
      SOURCE: "source",
    },
  },
};

export const embedEvents = {
  ON_EMBED_CTA_CLICK: {
    name: "Click on Embed CTA",
    trigger: "User clicks on Embed CTA",
    type: "Event Property",
    properties: {
      SOURCE: "source",
    },
  },
  ON_GENERATE_CODE_CTA_CLICK: {
    name: "Generate Code",
    trigger: `IU clicks Generate Code`,
    type: "Event Property",
    properties: {
      SOURCE: "source",
    },
  },
};
