/* eslint-disable no-shadow */
import { ReactElement } from "react";

import TooltipWrapper from "../tooltip-wrapper";

export interface IntegryTooltipProps {
  tooltipText: string | JSX.Element | HTMLElement | undefined;
  tooltipId?: string;
  disabled?: boolean;
  hideToolTip?: boolean;
  toolTipStyles?: Record<string, unknown>;
  placement?: "top" | "left" | "right" | "bottom";
  children: ReactElement;
  className?: string;
  triggerManually?: boolean;
  show?: boolean;
}

const IntegryTooltip = (props: IntegryTooltipProps): ReactElement => {
  const { placement, toolTipStyles, tooltipId } = props;
  return (
    <TooltipWrapper
      {...props}
      placement={placement}
      tooltipId={tooltipId || "integry-tooltip"}
      toolTipStyles={{ ...toolTipStyles, opacity: 1 }}
    />
  );
};

IntegryTooltip.defaultProps = {
  tooltipId: "",
  disabled: false,
  hideToolTip: false,
  toolTipStyles: {},
  placement: "bottom",
};

export default IntegryTooltip;
