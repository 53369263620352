import http from "utils/http";
import { AxiosPromise } from "axios";

export const createAccountTag = (tag: string): AxiosPromise => {
  return http.post("/api/v1/tags/", { name: tag });
};
export const updateAccountTag = (id: number, tag: string): AxiosPromise => {
  return http.put(`/api/v1/tags/${id}/`, { name: tag });
};
export const deleteAccountTag = (id: number): AxiosPromise => {
  return http.delete(`/api/v1/tags/${id}/`);
};
export const listAccountTags = (search: string, sortOrder): AxiosPromise => {
  let queryParams = "";
  if (search) {
    queryParams = `?search=${search}`;
  }
  if (Object.keys(sortOrder).length > 0) {
    const sortKey = Object.keys(sortOrder)[0];
    if (!queryParams.startsWith("?")) {
      queryParams = `?order_by=${
        sortOrder[sortKey] === "desc" ? "-" : ""
      }${sortKey}`;
    } else {
      queryParams = `${queryParams}&order_by=${
        sortOrder[sortKey] === "desc" ? "-" : ""
      }${sortKey}`;
    }
  }
  const uri = `/api/v1/tags/${queryParams}`;
  return http.get(uri);
};
