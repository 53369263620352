/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CSSProperties,
  HTMLAttributes,
  useState,
  useEffect,
  useRef,
} from "react";
import useOnClickOutside from "utils/use-onclick-outside";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";

import MeatballsIcon from "images/meatballs_menu_black.svg";
import MeatballsActiveIcon from "images/meatballs_menu_blue.svg";
import uuid from "uuid";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";

import "./styles.scss";

export interface IMenuItems {
  value: string;
  label: string | JSX.Element;
  disabled?: boolean;
  tooltip?: string;
  tooltipPlacement?: "top" | "bottom" | "left" | "right" | undefined;
  type?: string;
  className?: string;
  onItemSelect?: () => void;
  onToggleChange?: (checked: boolean) => void;
  divider?: boolean;
  iconPrefix?: string;
  meta?: any;
  labelStyle?: CSSProperties;
  disableClick?: boolean;
}

export interface IProps extends HTMLAttributes<HTMLDivElement> {
  items: Array<IMenuItems>;
  style?: CSSProperties;
  customStyle?: CSSProperties;
  onSelect?: (string) => void;
  onMenuActive?: (visibility: boolean) => void;
  disabledMenu?: boolean;
}

const ToggleSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#3BB568",
    "&:hover": {
      backgroundColor: alpha("#3BB568", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#3BB568",
  },
  "& .MuiSwitch-switchBase": {
    color: "#999999",
    "&:hover": {
      backgroundColor: alpha("#999999", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-track": {
    backgroundColor: "#999999",
  },
}));

const MeatballsMenu = ({ customStyle = {}, ...props }: IProps): JSX.Element => {
  const controlId = `meatballs-menu-${uuid.v4()}`;
  const {
    items = [],
    onSelect,
    onMenuActive,
    disabledMenu,
    style = {},
  } = props;
  const meatballsMenuContainerRef = useRef<any>(null);
  const [showMenu, setShowMenu] = useState(false);
  const [ctxPostion, setCtxPosition] = useState({
    x: 0,
    y: 0,
  });
  useEffect(() => {
    const ele = document.getElementById(controlId);
    if (ele) {
      const rect = ele.getBoundingClientRect();
      setCtxPosition({
        x: rect.x,
        y: rect.y,
      });
    }
  }, []);
  useOnClickOutside(meatballsMenuContainerRef, (event) => {
    handleMenuVisibility(false);
  });
  const handleMenuVisibility = (showFlag = false): void => {
    setShowMenu(showFlag);
    if (onMenuActive) {
      onMenuActive(showFlag);
    }
  };
  const handleOnSelect = (selectedValue): void => {
    if (onSelect) {
      onSelect(selectedValue);
    }
    setShowMenu(false);
  };
  return (
    <div
      id={controlId}
      className={`integry-meatballs-menu ${disabledMenu ? "is-disabled" : ""}`}
      ref={meatballsMenuContainerRef}
      style={style}
    >
      <img
        src={!showMenu ? MeatballsIcon : MeatballsActiveIcon}
        alt="meatballs_ico"
        onClick={(e) => {
          document.body.click();
          e.stopPropagation();
          if (!disabledMenu) handleMenuVisibility(!showMenu);
        }}
        aria-hidden="true"
      />
      {showMenu && (
        <div
          className="meatballs-menu__items"
          style={{
            ...customStyle,
            // position: "fixed",
            // top: ctxPostion.y + 10,
            // left: ctxPostion.x - 10,
          }}
        >
          {items.map((item) => {
            const {
              value,
              label,
              onItemSelect,
              onToggleChange,
              disabled = false,
              tooltip = "",
              tooltipPlacement = "top",
              className = "",
              divider = false,
              iconPrefix,
              labelStyle = {},
              disableClick = false,
              type = "default",
            } = item;
            if (tooltip && tooltip !== "") {
              return (
                <>
                  {divider && <div className="meatballs-menu__item--divider" />}
                  <IntegryTooltip
                    tooltipText={tooltip}
                    placement={tooltipPlacement || "top"}
                  >
                    <div
                      className={`meatballs-menu__item${
                        disabled ? " meatballs-menu__item--disabled" : ""
                      } ${className}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!disabled && !disableClick) {
                          if (onItemSelect) {
                            onItemSelect();
                            setShowMenu(false);
                          } else {
                            handleOnSelect(value);
                          }
                        }
                      }}
                      aria-hidden="true"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: type === "toggle" ? "100%" : "auto",
                        }}
                      >
                        {iconPrefix && (
                          <img
                            src={iconPrefix}
                            alt={`${label}_ico`}
                            style={{
                              marginRight: "10px",
                            }}
                            width="15px"
                            height="15px"
                          />
                        )}
                        {type === "default" && (
                          <span style={labelStyle}>{label}</span>
                        )}
                        {type === "toggle" && (
                          <span
                            style={{
                              ...labelStyle,
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            {label}
                            <ToggleSwitch
                              checked={value === "true"}
                              size="small"
                              onChange={(e) => {
                                const { checked } = e.target;
                                if (onToggleChange) {
                                  onToggleChange(checked);
                                }
                              }}
                            />
                          </span>
                        )}
                      </div>
                    </div>
                  </IntegryTooltip>
                </>
              );
            }
            return (
              <>
                {divider && <div className="meatballs-menu__item--divider" />}
                <div
                  className={`meatballs-menu__item ${className}${
                    disabled ? " meatballs-menu__item--disabled" : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!disabled && !disableClick) {
                      if (onItemSelect) {
                        onItemSelect();
                        setShowMenu(false);
                      } else {
                        handleOnSelect(value);
                      }
                    }
                  }}
                  aria-hidden="true"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: type === "toggle" ? "100%" : "auto",
                    }}
                  >
                    {iconPrefix && (
                      <img
                        src={iconPrefix}
                        alt={`${label}_ico`}
                        width="15px"
                        height="15px"
                        style={{
                          marginRight: "10px",
                          minWidth: "15px",
                        }}
                      />
                    )}
                    {type === "default" && (
                      <span style={labelStyle}>{label}</span>
                    )}
                    {type === "toggle" && (
                      <span
                        style={{
                          ...labelStyle,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        {label}
                        <ToggleSwitch
                          checked={value === "true"}
                          size="small"
                          onChange={(e) => {
                            const { checked } = e.target;
                            if (onToggleChange) {
                              onToggleChange(checked);
                            }
                          }}
                        />
                      </span>
                    )}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MeatballsMenu;
