/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  CSSProperties,
  HTMLAttributes,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { FeatureFlag } from "react-launch-darkly";
import { styled } from "@mui/material/styles";
import { Button, IconButton } from "@mui/material";
import Slide from "react-reveal/Slide";
import { toast } from "react-toastify";

import Breadcrumb, {
  ICrumb,
} from "components/integry-design-system/molecules/breadcrumb";

import PreviewSetup from "legacy-features/integrations/preview-setup";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import Tag from "components/integry-design-system/atoms/tag";
import { IPill } from "components/integry-design-system/molecules/filter-pill";
import GroupedFilterPills from "components/integry-design-system/molecules/grouped-filter-pills";
import SearchBar from "stories/Atoms/searchbar/searchbar";
import MeatballsMenu from "components/integry-design-system/molecules/meatballs-menu";
import IntegryPagination from "components/integry-design-system/molecules/pagination";
import RadioSelection, {
  IRadioOptions,
} from "components/integry-design-system/molecules/radio-selection";
import DateTimePicker, {
  dateTimeTypeEnum,
} from "components/integry-design-system/molecules/date-time-picker";

import http from "utils/http";
import lang from "utils/lang/en";
import {
  track,
  integrationViewEvents as trackEvent,
  errorNotificationEvents,
} from "utils/mixpanel";
import { convertDateToStandard } from "utils/functions";
import { getMyAppById } from "selectors/apps";
import RunsPlaceholder from "features/end-users/runs-view/runs-placeholder";

import FunnelIconActive from "images/filter_funnel_active.svg";
// import xIcon from "images/x.svg";
import filterSidebarCloseBtn from "images/filter-sidebar-close-btn-icon.svg";
import exlaimationIconOutline from "images/exclaimation_icon_outline.svg";
import NotFoundIcon from "images/not_found_icon_lg.svg";
import SortIconDefault from "images/sort.svg";
import SortIconDesc from "images/sort-applied.svg";
import CopyIconActive from "images/copy_icon_active.svg";
import TruncateBullets from "images/truncate_bullets.svg";
import EyeIcon from "images/eye_primary.svg";
import NoRunsFoundIcon from "images/no-runs-icon.svg";

import { IIntegration } from "legacy-features/integrations/integrations-view/integration-listing";
import "legacy-features/integrations/styles.scss";
import Typography from "components/integry-design-system/molecules/typography";
import useOnClickOutside from "utils/use-onclick-outside";
import IntegryDialoguePop from "components/integry-design-system/molecules/integry-dialogue-pop";
import ErrorNotificationSetup from "components/integry-design-system/organisms/integration-view/error-notification-setup";
import { useGetNotificationSettingsQuery } from "utils/accounts-api";
import CheckboxSelection from "components/integry-design-system/molecules/checkbox-selection";
import "./styles.scss";
import { TableConfig } from "../services/interface";

export interface IFetchOptions {
  search?: string | undefined | null;
  filtersQuery?: Array<IPill> | undefined | null;
  pageConfig?: {
    page: number;
    pageSize: number;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortOrder?: any;
  searchSteps?: boolean;
}
export interface IRun {
  runId: string;
  triggerApp: {
    iconSrc: string;
    name: string;
    triggerType?: string;
  };
  triggerName: string;
  triggerType: string;
  startTime: string;
  duration: string;
  status: string;
  networkCode: string;
  gcpLog: string;
  hasSteps: boolean;
  networkCodeDescription?: string;
}

export interface IFlow {
  flowName: string;
  flowId: string;
  flowLogo: string;
  flowStatus: string;
  flowColor: string;
  isDirectAction: boolean;
}

export interface IProps extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties;
  flowId: string;
  integrationIdParam?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  app?: any;
  showBillableRunStatus?: boolean;
  tableConfig: TableConfig;
  setFetching: (arg: boolean) => void;
  fetchData: (
    fId,
    integrationId,
    options: IFetchOptions,
    skipFetching?: boolean,
    pollFrequency?: number
  ) => void;
  loading: boolean;
  fetching: boolean;
  totalCount: number;
  flowConfig: IFlow | undefined | null;
  integrationConfig: IIntegration | undefined | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  runsConfigs: any;

  generateBreadcrumbConfig: (
    flowConfig: IFlow | undefined | null,
    integrationIdParam: string
  ) => Array<ICrumb>;
  renderHeadingTitle: (integrationId: string) => JSX.Element;
  openRun: (
    flowId: string,
    integrationIdParam: string,
    runId: string,
    run: any
  ) => void;
  renderGroupedInfoHeader: (
    flowConfig: IFlow | undefined | null,
    integrationConfig: IIntegration | undefined | null,
    showPollData: boolean
  ) => JSX.Element;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useQuery = (): any => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const InterButton = styled(Button)<any>(({ isDisabled = false, variant }) => ({
  fontFamily: ["Inter"],
  // font-family: 'Inter';
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "15px",
  color: "#4250F0",
  padding: "5px 10px",
  borderColor: "#4250F0",
  borderRadius: "5px",
  textTransform: "none",
  minWidth: "90px",
  opacity: isDisabled ? 0.5 : 1,
  "&:hover":
    variant !== "text"
      ? {
          backgroundColor: !isDisabled ? "#4250F0" : "#FFFFFF",
          color: !isDisabled ? "#FFFFFF" : "#4250F0",
          cursor: !isDisabled ? "pointer" : "not-allowed",
        }
      : {
          backgroundColor: "transparent",
        },
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FilterButton = styled(InterButton)<any>(({ isDisabled = false }) => ({
  fontWeight: 500,
  color: "#3518FB",
  padding: "10px 9px",
  background: "rgba(53, 24, 251, 0.1)",
  borderRadius: "4px",
  opacity: 1,
  width: "68px",
  minWidth: "68px",
  heigth: "35px",
  "&:hover": {
    backgroundColor: "rgba(53, 24, 251, 0.2)",
    color: "#3518FB",
  },
}));

const IntegrationsView = ({
  customStyle = {},
  ...props
}: IProps): JSX.Element => {
  const {
    flowId = "-1",
    integrationIdParam = "-1",
    user,
    app,
    showBillableRunStatus = false,
    tableConfig,
    fetchData,
    setFetching,
    loading,
    fetching,
    totalCount,
    flowConfig,
    integrationConfig,
    runsConfigs,
    generateBreadcrumbConfig,
    renderHeadingTitle,
    openRun,
    renderGroupedInfoHeader,
  } = props;

  const history = useHistory();
  const query = useQuery();
  const [filters, setFilters] = useState<Array<IPill> | [] | undefined | null>(
    []
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [sorting, setSorting] = useState<any>({
    start_time: "desc",
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [draftFilters, setDraftFilters] = useState<any>({});
  const [allowApplyCTA, setAllowApplyCTA] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");
  const [allowStepSearch, setAllowStepSearch] = useState(false);
  const [page, setPage] = useState({
    page: 1,
    pageSize: 10,
  });
  const [showFilters, setShowFilters] = useState(false);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [showRetryRun, setShowRetryRun] = useState("-1");
  const [showPreviewSetup, setShowPreviewSetup] = useState(false);

  const sidebarDivRef = useRef<HTMLDivElement>(null);
  const queryStringRef = useRef("");

  const {
    data: notificationSettings,
    isLoading: isNotificationSettingLoading,
    refetch: refetchNotificationSettings,
  } = useGetNotificationSettingsQuery();

  const allowApplyFilter = (dFilter): boolean => {
    let flag = false;
    (Object.keys(dFilter) || []).forEach((dfKey) => {
      if (dFilter[dfKey]) {
        flag = true;
      }
    });
    return flag;
  };

  const handleOnSorting = (id): void => {
    let tempSorting = {};
    tempSorting = {
      [id]: sorting[id] === "desc" ? "asc" : "desc",
    };
    setSorting(tempSorting);
    fetchData(flowId, integrationIdParam, {
      search: searchTxt,
      filtersQuery: filters,
      pageConfig: page,
      sortOrder: tempSorting,
      searchSteps: allowStepSearch,
    });
  };

  const handleOnApplyFilter = (filterConfig): void => {
    const tempFilters: Array<IPill> = [];
    (Object.keys(filterConfig) || []).forEach((filterKey) => {
      const filterObj = filterConfig[filterKey];
      const dateKeys = ["stepStartTime"];
      if (dateKeys.includes(filterKey)) {
        if (filterObj) {
          const formatedStartDate = convertDateToStandard(filterObj.startDate);
          const formatedEndDate = convertDateToStandard(filterObj.endDate);
          tempFilters.push({
            id: filterKey,
            label: filterLabels[filterKey],
            valueToShow: `${formatedStartDate} - ${formatedEndDate}`,
            value: filterObj,
          });
        }
      } else {
        tempFilters.push({
          id: filterKey,
          label: filterLabels[filterKey],
          value: filterObj,
          valueToShow: filterObj,
        });
      }
    });
    setFilters(tempFilters);
    // setShowFilters(false);
    setAllowApplyCTA(false);
    setPage({
      page: 1,
      pageSize: page.pageSize,
    });
    fetchData(flowId, integrationIdParam, {
      search: searchTxt || "",
      filtersQuery: tempFilters || [],
      pageConfig: {
        page: 1,
        pageSize: page.pageSize,
      },
      sortOrder: sorting,
      searchSteps: allowStepSearch,
    });
    if (props.user) {
      let filterNameValue = "";
      tempFilters.forEach((filter) => {
        filterNameValue = `${filterNameValue}, ${filter.label} = ${filter.valueToShow}`;
      });
      track(trackEvent.FILTER_RUNS.name, props.user, {
        [trackEvent.FILTER_RUNS.property]: filterNameValue,
      });
    }
  };

  const handlePageChange = (pageConfig): void => {
    setPage(pageConfig);
    fetchData(flowId, integrationIdParam, {
      search: searchTxt,
      filtersQuery: filters,
      pageConfig,
      sortOrder: sorting,
      searchSteps: allowStepSearch,
    });
  };

  const handleSearch = (searchText, currenConfigs): void => {
    setSearchTxt(searchText);
    setPage({
      page: 1,
      pageSize: page.pageSize,
    });
    fetchData(flowId, integrationIdParam, {
      search: searchText,
      filtersQuery: filters,
      pageConfig: {
        page: 1,
        pageSize: page.pageSize,
      },
      sortOrder: sorting,
      searchSteps: allowStepSearch,
    });
    if (user && searchText !== "") {
      track(trackEvent.SEARCH_RUNS.name, user, {
        [trackEvent.SEARCH_RUNS.property]: searchText,
      });
    }
  };

  const handleAllowStepSearch = (stepSearch: boolean): void => {
    setAllowStepSearch(stepSearch);
    fetchData(flowId, integrationIdParam, {
      search: searchTxt,
      filtersQuery: filters,
      pageConfig: page,
      sortOrder: sorting,
      searchSteps: stepSearch,
    });
  };

  const handleCopyIntegrationLink = (): void => {
    let stringifiedFilters = ``;
    if ((filters || []).length > 0) {
      try {
        stringifiedFilters = JSON.stringify(filters);
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        stringifiedFilters = "";
        console.error("ERROR converting filters to string", e);
      }
    }
    // navigator.clipboard.writeText(
    //   `${process.env.REACT_APP_API_BASE_URL}/wapp/view/flow/${flowId}/integration/${integrationIdParam}/runs${window.location.search}`
    // );
    navigator.clipboard.writeText(window.location.href);
  };

  const handleCopyRunLink = (runId): void => {
    // navigator.clipboard.writeText(
    //   `${process.env.REACT_APP_API_BASE_URL}/wapp/view/flow/${flowId}/integration/${integrationIdParam}/run/${runId}/steps`
    // );
    const url = window.location.href;
    const regex = /\/users\/(\d+)\/app\/(\d+)/;
    const match = url.match(regex);

    // Extract the "users" and "app" values from the match
    let usersValue = "";
    let appValue = "";

    if (match) {
      usersValue = match[1] || "";
      appValue = match[2] || "";
    }
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_API_BASE_URL}/wapp/view/users/${usersValue}/app/${appValue}/flow/${flowId}/integration/${integrationIdParam}/run/${runId}/steps`
    );
  };

  const handleRetryRunLink = (runId): void => {
    setFetching(true);
    setShowRetryRun("-1");
    http
      .post(
        `api/v1/templates/${flowId}/integrations/${integrationIdParam}/runs/${runId}/retry/`
      )
      .then((response) => {
        // const { message = "Run retried successfully" } = response;
        toast.success(`Your retry request is being processed.`);
        // setTimeout(() => {
        fetchData(
          flowId,
          integrationIdParam,
          {
            search: searchTxt,
            filtersQuery: filters,
            pageConfig: {
              page: 1,
              pageSize: page.pageSize,
            },
            sortOrder: {
              start_time: "desc",
            },
            searchSteps: allowStepSearch,
          },
          true,
          19
        );
        setPage({
          page: 1,
          pageSize: page.pageSize,
        });
        setSorting({
          start_time: "desc",
        });
        // }, 4000);
      })
      .catch((e) => {
        console.error({ e });
        toast.error(`Failed to retry the run. ${e.message}`);
        setFetching(false);
      })
      .finally(() => {
        setShowRetryRun("-1");
      });
  };

  const handleOnFilterClear = (): void => {
    setFilters([]);
    setDraftFilters({});
    setPage({
      page: 1,
      pageSize: page.pageSize,
    });
    fetchData(flowId, integrationIdParam, {
      search: searchTxt,
      filtersQuery: [],
      pageConfig: {
        page: 1,
        pageSize: page.pageSize,
      },
      sortOrder: sorting,
      searchSteps: allowStepSearch,
    });
  };

  const handleOnFilterClose = (pill): void => {
    const tempFilters: Array<IPill> = [];
    (filters || []).forEach((filter) => {
      if (filter.id !== pill.id) {
        tempFilters.push(filter);
      }
    });
    setFilters(tempFilters);
    // if (draftFilters[pill.id]) {
    //   setDraftFilters({
    //     ...draftFilters,
    //     [pill.id]: null,
    //   });
    // }
    let tempDraftFilters = {};
    Object.keys(draftFilters).forEach((filterKey) => {
      if (filterKey !== pill.id) {
        tempDraftFilters = {
          ...tempDraftFilters,
          [filterKey]: draftFilters[filterKey],
        };
      }
    });
    setDraftFilters(tempDraftFilters);
    setPage({
      page: 1,
      pageSize: page.pageSize,
    });
    fetchData(flowId, integrationIdParam, {
      search: searchTxt,
      filtersQuery: tempFilters,
      pageConfig: {
        page: 1,
        pageSize: page.pageSize,
      },
      sortOrder: sorting,
      searchSteps: allowStepSearch,
    });
  };

  const renderHeading = (): JSX.Element => {
    // document.title = `Runs of Integration(${integrationIdParam})`;
    document.title = `Runs of Integration - Flows - Integry`;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {renderHeadingTitle(integrationIdParam)}
        <div>
          <FeatureFlag
            flagKey="allow-preview-setup-steps"
            renderDefaultCallback={() => null}
            renderFeatureCallback={() =>
              !flowConfig?.isDirectAction && (
                <Button
                  style={{
                    color: "#4250F0",
                    marginRight: "14px",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "15px",
                    textAlign: "right",
                    textTransform: "none",
                  }}
                  endIcon={<img src={EyeIcon} alt="copy_link" />}
                  size="small"
                  onClick={() => {
                    setShowPreviewSetup(true);
                  }}
                >
                  Preview Setup
                </Button>
              )
            }
          />

          <IntegryTooltip
            tooltipText={isLinkCopied ? "Link Copied" : "Copy Link"}
            placement="bottom"
          >
            {/* <FeatureFlag
              flagKey="allow-preview-setup-steps"
              renderDefaultCallback={() => (
                <IconButton
                  color="primary"
                  aria-label="copy-link"
                  component="span"
                  onClick={() => {
                    handleCopyIntegrationLink();
                    setIsLinkCopied(true);
                    setTimeout(() => {
                      setIsLinkCopied(false);
                    }, 5000);
                  }}
                  style={{ color: "#4250F0", marginRight: "-7px" }}
                >
                  <img src={CopyIconActive} alt="copy_link" />
                </IconButton>
              )}
              renderFeatureCallback={() => (
                <Button
                  style={{
                    color: "#4250F0",
                    marginRight: "-7px",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "15px",
                    textAlign: "right",
                    textTransform: "none",
                  }}
                  endIcon={<img src={CopyIconActive} alt="copy_link" />}
                  size="small"
                  onClick={() => {
                    handleCopyIntegrationLink();
                    setIsLinkCopied(true);
                    setTimeout(() => {
                      setIsLinkCopied(false);
                    }, 5000);
                  }}
                >
                  Copy Link
                </Button>
              )}
            /> */}
            <Button
              style={{
                color: "#4250F0",
                marginRight: "-7px",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "15px",
                textAlign: "right",
                textTransform: "none",
              }}
              endIcon={<img src={CopyIconActive} alt="copy_link" />}
              size="small"
              onClick={() => {
                handleCopyIntegrationLink();
                setIsLinkCopied(true);
                setTimeout(() => {
                  setIsLinkCopied(false);
                }, 5000);
              }}
            >
              Copy Link
            </Button>
          </IntegryTooltip>
        </div>
      </div>
    );
  };

  const renderSubHeading = (): JSX.Element => {
    return <h2 className="view-sub-heading">Runs</h2>;
  };

  const renderSearchBar = (
    currenConfigs,
    isDisabled = false
  ): React.ReactElement => {
    return (
      <SearchBar
        onSearch={(searchText) => {
          if (!isDisabled) {
            handleSearch(searchText, currenConfigs);
          }
        }}
        disabled={isDisabled}
        placeholder={lang.SEARCH_RUN_PLACEHOLDER}
        defaultValue={searchTxt}
        searchCallbackDependency={`allowStepSearch_${allowStepSearch},filters_${JSON.stringify(
          filters
        )},page_${JSON.stringify(page)}`}
      />
    );
  };

  const renderMeatballsMenu = (
    run,
    allowGCPAccess = false,
    retryRun = false
  ): JSX.Element => {
    const menuItems = [
      {
        label: "Copy Link",
        value: "copy-run-link",
        onItemSelect: () => {
          if (run.hasSteps) {
            handleCopyRunLink(run.runId);
          }
        },
        disabled: false,
        tooltip: "",
      },
    ];
    if (allowGCPAccess) {
      menuItems.push({
        label: "View GCP Log",
        value: "view_gcp_log",
        onItemSelect: () => {
          if (run.gcpLog) {
            window.open(run.gcpLog, "_blank");
          }
        },
        tooltip: "",
        disabled: false,
      });
    }
    if (retryRun) {
      menuItems.unshift({
        label: "Retry",
        value: "retry-run",
        tooltip:
          (
            integrationConfig || { integrationStatus: "INACTIVE" }
          ).integrationStatus.toLowerCase() !== "active"
            ? lang.DISABLED_RETRY_RUN_COPY
            : "",
        disabled:
          (
            integrationConfig || { integrationStatus: "INACTIVE" }
          ).integrationStatus.toLowerCase() !== "active",
        onItemSelect: () => {
          setShowRetryRun(run.runId);
        },
      });
    }
    return <MeatballsMenu items={menuItems} />;
  };

  const showNotificationMessage = (): boolean => {
    if (
      isNotificationSettingLoading === false &&
      notificationSettings?.is_error_notification_enabled === false
    ) {
      return true;
    }
    return false;
  };

  const onClickSetupNotifications = (): void => {
    track(errorNotificationEvents.GO_TO_ERROR_NOTIFICATIONS.name, props.user, {
      [errorNotificationEvents.GO_TO_ERROR_NOTIFICATIONS.property]: "Runs",
    });
  };

  const onClickYesInNotificationPopUp = (): void => {
    track(
      errorNotificationEvents.CONTINUE_TO_ERROR_NOTIFICATIONS_FROM_DIALOG.name,
      props.user,
      {
        [errorNotificationEvents.CONTINUE_TO_ERROR_NOTIFICATIONS_FROM_DIALOG
          .property]: "Runs",
      }
    );
  };

  const onDismissNotificationSnackbar = (): void => {
    track(
      errorNotificationEvents.DISMISS_ERROR_NOTIFICATIONS_SNACKBAR.name,
      props.user,
      {
        [errorNotificationEvents.DISMISS_ERROR_NOTIFICATIONS_SNACKBAR.property]:
          "Runs",
      }
    );
  };

  const renderTable = (): JSX.Element => {
    const offset = page.pageSize * page.page;
    return (
      <>
        <table className="integry-table">
          <thead>
            <tr>
              {tableConfig.config.map((configItem) => {
                if (configItem.tooltip) {
                  return (
                    <th>
                      {configItem.label}
                      <IntegryTooltip
                        tooltipText={
                          configItem.tooltip_nonBillable
                            ? showBillableRunStatus
                              ? configItem.tooltip
                              : configItem.tooltip_nonBillable
                            : configItem.tooltip
                        }
                        placement="top"
                      >
                        <img
                          width={15}
                          height={15}
                          src={exlaimationIconOutline}
                          style={{
                            marginLeft: "5px",
                            marginTop: "-1px",
                            transform: "rotateZ(180deg)",
                          }}
                          alt="tooltip_heading"
                        />
                      </IntegryTooltip>
                    </th>
                  );
                }
                if (configItem.allowSorting) {
                  return (
                    <th>
                      {configItem.label}
                      <img
                        width={15}
                        height={15}
                        style={{
                          marginLeft: "5px",
                          marginTop: "-2px",
                          cursor: "pointer",
                        }}
                        src={
                          sorting[configItem.sortingIdentifier || configItem.id]
                            ? SortIconDesc
                            : SortIconDefault
                        }
                        className={
                          sorting[
                            configItem.sortingIdentifier || configItem.id
                          ] &&
                          sorting[
                            configItem.sortingIdentifier || configItem.id
                          ] === "asc"
                            ? "table-sort-icon-asc"
                            : ""
                        }
                        alt="sort_icon"
                        onClick={() => {
                          handleOnSorting(
                            configItem.sortingIdentifier || configItem.id
                          );
                        }}
                        aria-hidden="true"
                      />
                    </th>
                  );
                }
                return <th>{configItem.label}</th>;
              })}
              {fetching && <th>Loading...</th>}
              {!fetching && (
                <th>
                  {totalCount > 0 ? offset - (page.pageSize - 1) : 0}-
                  {offset > totalCount ? totalCount : offset} of {totalCount}
                </th>
              )}
            </tr>
          </thead>
          {runsConfigs.length > 0 && (
            <tbody
              style={
                !fetching
                  ? {}
                  : {
                      opacity: "0.5",
                    }
              }
            >
              {runsConfigs.map((run: IRun) => {
                return (
                  <tr>
                    {tableConfig.config.map((configItem) => {
                      const {
                        id,
                        subConfig,
                        type = "text",
                        tdStyle = {},
                        showTooltip = false,
                        tooltipField = "",
                        hideSameValueTooltip = false,
                      } = configItem;
                      if (type === "code") {
                        const codeLabel =
                          statusLabels[run[id]] ||
                          networkCodeLabels[run[id]] ||
                          run[id];
                        const codeColor =
                          networkCodeColors[
                            `${run[id] || "N/A"}`.substring(0, 1)
                          ] || "#333333";
                        return (
                          <td>
                            <div className="integry-table--column">
                              <IntegryTooltip
                                hideToolTip={
                                  !showTooltip ||
                                  (!networkCodeDescriptionLabels[run[id]] &&
                                    run[tooltipField] === "N/A")
                                }
                                tooltipText={
                                  networkCodeDescriptionLabels[run[id]] ||
                                  run[tooltipField] ||
                                  ""
                                }
                                placement="bottom"
                              >
                                <span
                                  style={{
                                    color: codeColor,
                                  }}
                                >
                                  {codeLabel}
                                </span>
                              </IntegryTooltip>
                            </div>
                          </td>
                        );
                      }
                      if (type === "tag") {
                        const tagLabel = statusLabels[run[id]] ||
                          networkCodeLabels[run[id]] || [run[id]];
                        return (
                          <td>
                            <div className="integry-table--column">
                              <Tag label={tagLabel} type="run" />
                            </div>
                          </td>
                        );
                      }
                      if (configItem.truncate) {
                        return (
                          <td>
                            <div
                              className="integry-table--column"
                              style={tdStyle}
                            >
                              {run[id].length > 20 ? (
                                <IntegryTooltip
                                  tooltipText={run[id]}
                                  placement="bottom"
                                >
                                  <div>
                                    {run[id].substring(0, 3)}
                                    <img
                                      src={TruncateBullets}
                                      style={{
                                        width: "15px",
                                        margin: "0 2px",
                                        marginTop: "-3px",
                                        objectFit: "none",
                                      }}
                                      alt="truncate_bullets"
                                    />
                                    {run[id].substring(run[id].length - 6)}
                                  </div>
                                </IntegryTooltip>
                              ) : (
                                `${run[id]}`
                              )}
                            </div>
                          </td>
                        );
                      }
                      return (
                        <td>
                          <div
                            className="integry-table--column"
                            style={tdStyle}
                          >
                            <IntegryTooltip
                              hideToolTip={
                                !showTooltip ||
                                run[tooltipField] === "N/A" ||
                                (hideSameValueTooltip &&
                                  run[id] === run[tooltipField])
                              }
                              tooltipText={run[tooltipField] || ""}
                              placement="bottom"
                            >
                              <div>
                                {!subConfig &&
                                  (statusLabels[run[id]] ||
                                    networkCodeLabels[run[id]] || [run[id]])}
                                {subConfig &&
                                  subConfig.map((subItem) => {
                                    if (subItem.type === "img") {
                                      return (
                                        <img
                                          src={run[id][subItem.id]}
                                          alt={`ico_${run[id][subItem.id]}`}
                                        />
                                      );
                                    }
                                    return <span>{run[id][subItem.id]}</span>;
                                  })}
                              </div>
                            </IntegryTooltip>
                          </div>
                        </td>
                      );
                    })}
                    <td
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto auto",
                        gridGap: "11px",
                      }}
                    >
                      <InterButton
                        variant="outlined"
                        size="small"
                        isDisabled={!run.hasSteps}
                        onClick={() => {
                          if (run.hasSteps) {
                            if (user) {
                              track(trackEvent.VIEW_STEPS.name, props.user, {
                                [trackEvent.VIEW_STEPS.properties.FLOW_NAME]: (
                                  flowConfig || { flowName: "" }
                                ).flowName,
                                [trackEvent.VIEW_STEPS.properties.RUN_ID]:
                                  run.runId,
                                [trackEvent.VIEW_STEPS.properties
                                  .INTEGRATION_ID]: (
                                  integrationConfig || { integrationId: "" }
                                ).integrationId,
                              });
                            }
                            openRun(flowId, integrationIdParam, run.runId, run);
                          }
                        }}
                        style={{
                          width: "48px",
                          height: "25px",
                          minWidth: "48px",
                          padding: "5px",
                        }}
                      >
                        View
                      </InterButton>
                      <FeatureFlag
                        flagKey="allow-gcp-log-access"
                        renderFeatureCallback={() => (
                          <FeatureFlag
                            flagKey="iu-retry-run"
                            renderFeatureCallback={() => {
                              return renderMeatballsMenu(run, true, true);
                            }}
                            renderDefaultCallback={() => {
                              return renderMeatballsMenu(run, true);
                            }}
                          />
                        )}
                        renderDefaultCallback={() => (
                          <FeatureFlag
                            flagKey="iu-retry-run"
                            renderFeatureCallback={() => {
                              return renderMeatballsMenu(run, false, true);
                            }}
                            renderDefaultCallback={() => {
                              return renderMeatballsMenu(run);
                            }}
                          />
                        )}
                      />
                      {showRetryRun === run.runId && (
                        <IntegryDialoguePop
                          dialogueType="warning"
                          title="Retry Run"
                          description="Are you sure you want to retry this run? This will generate a new run with the payload of this run, and it may impact the user's data in the target app(s)."
                          cancelBtnLabel="Cancel"
                          actionBtnLabel="Yes"
                          onCancelClick={() => {
                            setShowRetryRun("-1");
                          }}
                          performActionCallback={() => {
                            handleRetryRunLink(run.runId);
                          }}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        {!fetching && runsConfigs.length < 1 && (
          <div className="view-record-not-found__placeholder">
            {!integrationConfig?.hasRuns ? (
              <img src={NoRunsFoundIcon} alt="record_not_found" />
            ) : (
              <img src={NotFoundIcon} alt="record_not_found" />
            )}
            <FeatureFlag
              flagKey="allow-preview-setup-steps"
              renderDefaultCallback={() => (
                <p>
                  {!integrationConfig?.hasRuns
                    ? lang.INTEGRATION_HAS_NOT_RUN
                    : lang.INTEGRATIONS_NOT_FOUND_COPY_RUNS}
                </p>
              )}
              renderFeatureCallback={() =>
                !flowConfig?.isDirectAction ? (
                  <p>
                    {!integrationConfig?.hasRuns ? (
                      <span>
                        {lang.INTEGRATION_HAS_NOT_RUN_1},{" "}
                        <span
                          className="view-record-not-found__placeholder--cta"
                          onClick={() => {
                            setShowPreviewSetup(true);
                          }}
                          aria-hidden="true"
                        >
                          Preview Setup
                        </span>
                        .
                      </span>
                    ) : (
                      lang.INTEGRATIONS_NOT_FOUND_COPY_RUNS
                    )}
                  </p>
                ) : (
                  <p>
                    {!integrationConfig?.hasRuns
                      ? lang.INTEGRATION_HAS_NOT_RUN
                      : lang.INTEGRATIONS_NOT_FOUND_COPY_RUNS}
                  </p>
                )
              }
            />
          </div>
        )}
        {!fetching && runsConfigs.length > 0 && showNotificationMessage() && (
          <FeatureFlag
            flagKey="setup-error-integration"
            renderFeatureCallback={() => (
              <ErrorNotificationSetup
                userId={user.id}
                onClickSetupNotifications={onClickSetupNotifications}
                onClickYesInNotificationPopUp={onClickYesInNotificationPopUp}
                onDismissNotificationSnackbar={onDismissNotificationSnackbar}
              />
            )}
            renderDefaultCallback={() => <></>}
          />
        )}
        {!fetching && runsConfigs.length > 0 && (
          <IntegryPagination
            defaultCurrentPage={page.page}
            defaultPageSize={page.pageSize}
            total={totalCount}
            customStyle={{ marginTop: "20px" }}
            onPageChange={(pageConfig) => {
              handlePageChange(pageConfig);
            }}
            onPageSizeChange={(pageConfig) => {
              handlePageChange(pageConfig);
            }}
          />
        )}
        {/* {fetching && <IntegryLoader />} */}
      </>
    );
  };

  const mapURLQueryStringToState = (): void => {
    const windowQueryParams = new URLSearchParams(window.location.search);
    if (windowQueryParams.has("search")) {
      setSearchTxt(windowQueryParams.get("search") || "");
      windowQueryParams.delete("search");
    }
    if (windowQueryParams.has("page")) {
      setPage({
        page: Number(windowQueryParams.get("page")) || 1,
        pageSize: Number(windowQueryParams.get("page_size")) || 10,
      });
      windowQueryParams.delete("page");
      windowQueryParams.delete("page_size");
    }
    if (windowQueryParams.has("ordering")) {
      if (windowQueryParams.get("ordering") === "start_time") {
        setSorting({ start_time: "asc" });
      } else if (windowQueryParams.get("ordering") === "-start_time") {
        setSorting({ start_time: "desc" });
      }
      windowQueryParams.delete("ordering");
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const draftFilterConfig: any = {
      stepStartTime: {
        startDate: "",
        endDate: "",
        convertionType: "localDateString",
      },
      networkCode: "",
      status: "",
    };
    windowQueryParams.forEach((value, key) => {
      try {
        if (key === "start_time_range_start" && value) {
          draftFilterConfig.stepStartTime.startDate = new Date(
            Number(value) * 1000
          ).toISOString();
        } else if (key === "start_time_range_end" && value) {
          draftFilterConfig.stepStartTime.endDate = new Date(
            Number(value) * 1000
          ).toISOString();
        } else if (key === "network_code" && value) {
          draftFilterConfig.networkCode = value;
        } else if (key === "status" && value) {
          draftFilterConfig.status = value;
        }
      } catch (e) {
        console.log("failed to set filter query string, due to invalid values");
      }
    });
    if (
      !draftFilterConfig.stepStartTime.startDate &&
      !draftFilterConfig.stepStartTime.endDate
    ) {
      delete draftFilterConfig.stepStartTime;
    }
    if (!draftFilterConfig.networkCode) {
      delete draftFilterConfig.networkCode;
    }
    if (!draftFilterConfig.status) {
      delete draftFilterConfig.status;
    }
    setDraftFilters(draftFilterConfig);
    const tempFilters: Array<IPill> = [];
    (Object.keys(draftFilterConfig) || []).forEach((filterKey) => {
      const filterObj = draftFilterConfig[filterKey];
      const dateKeys = ["stepStartTime"];
      if (dateKeys.includes(filterKey)) {
        if (filterObj) {
          const formatedStartDate = convertDateToStandard(filterObj.startDate);
          const formatedEndDate = convertDateToStandard(filterObj.endDate);
          tempFilters.push({
            id: filterKey,
            label: filterLabels[filterKey],
            valueToShow: `${formatedStartDate} - ${formatedEndDate}`,
            value: filterObj,
          });
        }
      } else {
        tempFilters.push({
          id: filterKey,
          label: filterLabels[filterKey],
          value: filterObj,
          valueToShow: filterObj,
        });
      }
    });
    setFilters(tempFilters);
  };

  useEffect(() => {
    refetchNotificationSettings();
    queryStringRef.current = window.location.search;
    mapURLQueryStringToState();
  }, []);

  useEffect(() => {
    const filtersFromQueryParam = query.get("filters");
    if (filtersFromQueryParam) {
      let filtersJSON = [];
      try {
        filtersJSON = JSON.parse(decodeURIComponent(filtersFromQueryParam));
      } catch (e) {
        console.error("ERROR while converting filters from URI", e);
      }
      if (filtersJSON.length > 0) {
        setFilters(filtersJSON);
      }
      fetchData(flowId, integrationIdParam, {
        search: searchTxt,
        filtersQuery: filtersJSON,
        pageConfig: page,
        sortOrder: sorting,
        searchSteps: allowStepSearch,
      });
    } else {
      // fetchFlowDetails(flowId);
      fetchData(flowId, integrationIdParam, {
        search: searchTxt,
        filtersQuery: filters,
        pageConfig: page,
        sortOrder: sorting,
        searchSteps: allowStepSearch,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowId, query]);

  const clickOutsideSidebar = (evt): void => {
    if (showFilters) {
      setShowFilters(false);
    }
  };

  const getRunStatusOptions = (
    showRunAsCompletedFlag = false
  ): Array<IRadioOptions> => {
    const runStatusOptions = [
      {
        label: "All",
        value: "all",
      },
    ] as Array<IRadioOptions>;
    if (showRunAsCompletedFlag) {
      runStatusOptions.push(
        {
          render: <Tag label="Completed" type="run" />,
          value: "completed",
        },
        {
          render: <Tag label="Filtered" type="run" />,
          value: "filtered",
        }
      );
    } else {
      runStatusOptions.push({
        render: <Tag label="OK" type="run" />,
        value: "ok",
      });
    }
    runStatusOptions.push(
      {
        render: <Tag label="Failed" type="run" />,
        value: "failed",
      },
      {
        render: (
          <Tag
            customStyle={{
              background: "#DCEAFF",
              color: "#5194F8",
            }}
            label="Running..."
            type="run"
          />
        ),
        value: "running",
      }
    );
    return runStatusOptions;
  };

  useOnClickOutside(sidebarDivRef, clickOutsideSidebar);

  // if (loading) {
  //   // return <IntegryLoader />;
  //   return <RunsPlaceholder />;
  // }
  return (
    <div className="integry-views" style={{ ...customStyle }}>
      {integrationConfig && integrationConfig.integrationName ? (
        <div className="view-breadcrumb">
          <Breadcrumb
            crumbs={generateBreadcrumbConfig(flowConfig, integrationIdParam)}
            onNavClick={(url) => {
              history.push(url);
            }}
          />
        </div>
      ) : (
        <div className="view-breadcrumbs-loading" />
      )}
      <h2 className="view-heading">{renderHeading()}</h2>

      {integrationConfig && integrationConfig.integrationName ? (
        <div className="view-header-info">
          <FeatureFlag
            flagKey="show-poll-data"
            renderFeatureCallback={() => (
              <>
                {renderGroupedInfoHeader(flowConfig, integrationConfig, true)}
              </>
            )}
            renderDefaultCallback={() => (
              <>
                {renderGroupedInfoHeader(flowConfig, integrationConfig, false)}
              </>
            )}
          />
        </div>
      ) : (
        <div className="view-header-info-loading" />
      )}
      {renderSubHeading()}
      <div className="view-filters">
        <div className="view-filters__search-bar">
          {renderSearchBar({
            filters,
            page,
            allowStepSearch,
          })}
          <FeatureFlag
            flagKey="include-payload-in-search-integration-view"
            renderFeatureCallback={() => (
              <CheckboxSelection
                options={[
                  {
                    label: "Include steps",
                    value: "true",
                    labelToolTip:
                      "This will search the payloads of each step of each run and could take a long time to execute.",
                  },
                ]}
                onOptionSelect={(selectedOption) => {
                  if (
                    selectedOption.length > 0 &&
                    selectedOption[0] === "true"
                  ) {
                    handleAllowStepSearch(true);
                  } else {
                    handleAllowStepSearch(false);
                  }
                }}
              />
            )}
            renderDefaultCallback={() => <></>}
          />
        </div>
        <div className="view-filters__pills">
          <GroupedFilterPills
            onClearClick={() => {
              handleOnFilterClear();
            }}
            onClose={(pill) => {
              handleOnFilterClose(pill);
            }}
            pills={(filters || []).map((filter) => {
              if (filter.id === "status") {
                return {
                  ...filter,
                  value: statusLabels[filter.value] || filter.valueToShow,
                };
              }
              if (filter.id === "networkCode") {
                return {
                  ...filter,
                  value: networkCodeLabels[filter.value] || filter.valueToShow,
                };
              }
              return {
                ...filter,
                value: filter.valueToShow,
              };
            })}
            customStyle={{
              marginRight: "7px",
            }}
          />
          <FilterButton
            startIcon={<img src={FunnelIconActive} alt="filter_icon" />}
            onClick={() => {
              setShowFilters(!showFilters);
            }}
          >
            Filter
          </FilterButton>
        </div>
      </div>
      {loading ? (
        <RunsPlaceholder />
      ) : (
        <>
          <div className="view-table">{renderTable()}</div>
        </>
      )}
      {(true || showFilters) && (
        <div ref={sidebarDivRef} className="react-reveal-slider">
          <Slide right collapse when={showFilters}>
            <div className="wrapper-drawer">
              <button
                className="filter-sidebar-close-btn"
                type="button"
                onClick={() => {
                  setShowFilters(false);
                }}
              >
                <img src={filterSidebarCloseBtn} alt="close_drawer" />
              </button>
              <div className="view-drawer">
                <div className="view-drawer__actions--top">
                  {/* <button
                  className="view-drawer__actions__cta"
                  type="button"
                  onClick={() => {
                    setShowFilters(false);
                  }}
                >
                  <img src={xIcon} alt="close_drawer" />
                </button> */}
                </div>
                <div className="view-drawer__heading">
                  <span>Filter</span>
                  <button
                    type="button"
                    className="view-drawer__actions__cta"
                    onClick={() => {
                      setDraftFilters({});
                      setAllowApplyCTA(false);
                    }}
                  >
                    Clear all
                  </button>
                </div>
                <div className="view-drawer__seperator" />
                <div className="view-drawer__group">
                  <p>
                    Start Time
                    {draftFilters.stepStartTime &&
                      (draftFilters.stepStartTime.startDate ||
                        draftFilters.stepStartTime.endDate) && (
                        <button
                          type="button"
                          className="view-drawer__actions__cta"
                          onClick={() => {
                            setDraftFilters({
                              ...draftFilters,
                              stepStartTime: null,
                            });
                            setAllowApplyCTA(
                              allowApplyFilter({
                                ...draftFilters,
                                stepStartTime: null,
                              })
                            );
                          }}
                        >
                          Clear
                        </button>
                      )}
                  </p>
                  <DateRange
                    start={(draftFilters.stepStartTime || {}).startDate || ""}
                    end={(draftFilters.stepStartTime || {}).endDate || ""}
                    onDateRangeChange={(dateRangeValue) => {
                      setDraftFilters({
                        ...draftFilters,
                        stepStartTime: dateRangeValue,
                      });
                      if (
                        dateRangeValue?.startDate &&
                        dateRangeValue?.endDate &&
                        new Date(dateRangeValue?.startDate) >
                          new Date(dateRangeValue?.endDate)
                      ) {
                        setAllowApplyCTA(false);
                      } else {
                        setAllowApplyCTA(true);
                      }
                    }}
                  />
                </div>
                <div className="view-drawer__seperator" />
                <div className="view-drawer__group">
                  <p>Status</p>
                  <FeatureFlag
                    flagKey="show-run-completed-filtered"
                    renderFeatureCallback={() => (
                      <RadioSelection
                        defaultValue={draftFilters.status || "all"}
                        onRadioSelection={(selection) => {
                          setDraftFilters({
                            ...draftFilters,
                            status: selection.value,
                          });
                          setAllowApplyCTA(true);
                        }}
                        options={getRunStatusOptions(true)}
                      />
                    )}
                    renderDefaultCallback={() => (
                      <RadioSelection
                        defaultValue={draftFilters.status || "all"}
                        onRadioSelection={(selection) => {
                          setDraftFilters({
                            ...draftFilters,
                            status: selection.value,
                          });
                          setAllowApplyCTA(true);
                        }}
                        options={getRunStatusOptions()}
                      />
                    )}
                  />
                </div>
                <div className="view-drawer__seperator" />
                <div className="view-drawer__group">
                  <p>Network Code</p>
                  <RadioSelection
                    defaultValue={draftFilters.networkCode || "all"}
                    onRadioSelection={(selection) => {
                      setDraftFilters({
                        ...draftFilters,
                        networkCode: selection.value,
                      });
                      setAllowApplyCTA(true);
                    }}
                    options={[
                      {
                        label: "All",
                        value: "all",
                      },
                      {
                        render: (
                          <span style={{ color: "#749D48" }}>
                            2xx (Success)
                          </span>
                        ),
                        value: "2xx",
                      },
                      {
                        render: (
                          <span style={{ color: "#F05C42" }}>
                            4xx (Client eror)
                          </span>
                        ),
                        value: "4xx",
                      },
                      {
                        render: (
                          <span style={{ color: "#F05C42" }}>
                            5xx (Server error)
                          </span>
                        ),
                        value: "5xx",
                      },
                      {
                        render: <span style={{ color: "#666666" }}>N/A</span>,
                        value: "n/a",
                      },
                    ]}
                  />
                </div>
                <div className="view-drawer__seperator" />
                <div className="view-drawer__actions--bottom">
                  <button
                    className={`view-drawer__actions__cta${
                      allowApplyCTA ? "" : " disabled"
                    }`}
                    type="button"
                    onClick={() => {
                      if (allowApplyCTA) {
                        handleOnApplyFilter(draftFilters);
                      }
                    }}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </Slide>
        </div>
      )}
      {integrationConfig?.integrationId && showPreviewSetup && (
        <PreviewSetup
          flow={flowConfig}
          integration={integrationConfig}
          user={user}
          app={app}
          onClose={() => {
            setShowPreviewSetup(false);
          }}
        />
      )}
    </div>
  );
};

// export default IntegrationsView;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect((state: any) => {
  return {
    app: getMyAppById(state.myApps, state.globallySelectedApp),
  };
})(IntegrationsView);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const DateRange = ({ start = "", end = "", onDateRangeChange }) => {
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const onDateChange = ({ id, value }): void => {
    let tempDateRange = { ...dateRange, [id]: value };
    const date1 = new Date(tempDateRange.startDate);
    const date2 = new Date(tempDateRange.endDate);
    if (date1.getTime() > date2.getTime()) {
      tempDateRange = {
        startDate: tempDateRange.endDate,
        endDate: tempDateRange.startDate,
      };
    }
    // switch (id) {
    //   case "startDate":
    //     if (tempDateRange.endDate === "") {
    //       tempDateRange = {
    //         ...tempDateRange,
    //         endDate: `${value.split("T")[0]}T23:59`,
    //       };
    //     }
    //     break;
    //   case "endDate":
    //     if (tempDateRange.startDate === "") {
    //       tempDateRange = {
    //         ...tempDateRange,
    //         startDate: `${value.split("T")[0]}T00:00`,
    //       };
    //     }
    //     break;
    //   default:
    //     break;
    // }
    if (tempDateRange.startDate || tempDateRange.endDate || onDateRangeChange) {
      onDateRangeChange(tempDateRange);
    }
    setDateRange(tempDateRange);
  };
  useEffect(() => {
    setDateRange({
      startDate: start,
      endDate: end,
    });
  }, [start, end]);

  return (
    <>
      <DateTimePicker
        defaultValue={dateRange.startDate}
        onDateTimeSelect={(value) => {
          onDateChange({
            id: "startDate",
            value: `${value}T00:00:00`,
            // value,
          });
        }}
        placeholder="Start Date"
        type={dateTimeTypeEnum.DATE}
        customStyle={{
          marginBottom: "10px",
        }}
        max={
          dateRange.endDate.includes("T")
            ? dateRange.endDate.split("T")[0]
            : dateRange.endDate
        }
      />
      <DateTimePicker
        defaultValue={dateRange.endDate}
        onDateTimeSelect={(value) => {
          onDateChange({
            id: "endDate",
            value: `${value}T23:59:59`,
            // value,
          });
        }}
        placeholder="End Date"
        type={dateTimeTypeEnum.DATE}
        // min={dateRange.startDate}
        min={
          dateRange.startDate.includes("T")
            ? dateRange.startDate.split("T")[0]
            : dateRange.startDate
        }
      />
      {start && end && new Date(start) > new Date(end) && (
        <Typography variation="2" color="#f05c42">
          start time should be less than end Time
        </Typography>
      )}
    </>
  );
};

const filterLabels = {
  stepStartTime: "Start Time",
  status: "Status",
  networkCode: "Network Code",
};

export const statusLabels = {
  all: "All",
  ok: "OK",
  failed: "Failed",
  running: "Running",
  completed: "Completed",
  filtered: "Filtered",
  FILTERED: "Filtered",
  COMPLETED: "Completed",
  true: "True",
  false: "False",
};

export const networkCodeLabels = {
  all: "All",
  n_a: "N/A",
  Loop: "200",
  loop: "200",
};

export const networkCodeDescriptionLabels = {
  Loop: "OK",
  loop: "OK",
};

export const networkCodeColors = {
  "2": "#749D48",
  "3": "#FFFAE2",
  "4": "#F05C42",
  "5": "#F05C42",
  N: "#666666",
  n: "#666666",
  l: "#749D48",
  L: "#749D48",
};
