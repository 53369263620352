import { ReactElement, useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import config from "utils/config";
import {
  Link,
  NavLink as RouterNavLink,
  useLocation,
  withRouter,
} from "react-router-dom";
import { FeatureFlag } from "react-launch-darkly";
import { User } from "types/integry-user";
import NavbarDropdown from "components/integry-design-system/molecules/dropdowns/navbar-dropdown/navbar-dropdown";
import Tag from "components/integry-design-system/atoms/tag";

import helpCenterIcon from "images/navbar-dropdown/help-center.svg";

import "core-js/fn/string/starts-with";

import GlobalAppFormV3 from "../common/global-app-form-v3";

import logo from "../../images/logo-complete.svg";

import Urls, { externalUrl } from "../../utils/router-urls";

import "./styles.scss";

interface IProps {
  user: User | undefined;
  hideWorkspaceDropdown?: boolean;
}

const HeaderWithoutRouter = ({
  user,
  hideWorkspaceDropdown,
}: IProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { pathname } = location;

  const toggle = (): void => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ position: "sticky", top: 0, zIndex: 1002 }}>
      <Navbar
        color="white"
        light
        expand="md"
        className="dashboard-header dashboard-navbar"
      >
        <NavbarBrand tag={Link} to="/">
          <img src={logo} alt="Integry Logo Complete" height={38} />
        </NavbarBrand>

        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="primary-navbar mx-auto" navbar>
            {/*  <NavItem active={pathname.startsWith("/dashboard")}>
                <NavLink tag={RouterNavLink} to="/dashboard">
                  Home
                </NavLink>
                <div className="navItem-Bar" />
              </NavItem> */}

            <FeatureFlag
              flagKey="function-logs"
              renderFeatureCallback={() => (
                <NavItem active={pathname.startsWith(Urls.functionCalls)}>
                  <NavLink tag={RouterNavLink} to={Urls.functionCalls}>
                    Function Calls
                  </NavLink>
                  <div className="navItem-Bar" />
                </NavItem>
              )}
              renderDefaultCallback={() => <></>}
            />

            <FeatureFlag
              flagKey="functions"
              renderFeatureCallback={() => (
                <NavItem active={pathname.startsWith(Urls.functions)}>
                  <NavLink tag={RouterNavLink} to={Urls.functions}>
                    Functions
                  </NavLink>
                  <div className="navItem-Bar" />
                </NavItem>
              )}
              renderDefaultCallback={() => <></>}
            />

            <FeatureFlag
              flagKey="maketplace-v2"
              renderFeatureCallback={() => (
                <NavItem active={pathname.startsWith(Urls.marketplaceV2)}>
                  <NavLink tag={RouterNavLink} to={Urls.marketplaceV2}>
                    Marketplace
                  </NavLink>
                  <div className="navItem-Bar" />
                </NavItem>
              )}
              renderDefaultCallback={() => <></>}
            />

            {/* ACTIONS */}
            {user?.account_id !== config.adminAccountId && (
              <FeatureFlag
                flagKey="actions-turbo"
                renderFeatureCallback={() => (
                  <NavItem active={pathname.startsWith(Urls.actions)}>
                    <NavLink tag={RouterNavLink} to={Urls.actions}>
                      Actions
                    </NavLink>
                    <div className="navItem-Bar" />
                  </NavItem>
                )}
                renderDefaultCallback={() => <></>}
              />
            )}

            {/* FLOWS */}
            <FeatureFlag
              flagKey="flows-tab"
              renderFeatureCallback={() => (
                <NavItem
                  active={
                    pathname.startsWith("/templates") ||
                    pathname.startsWith("/integration-view") ||
                    pathname.startsWith("/view/flow")
                  }
                >
                  <>
                    <NavLink tag={RouterNavLink} to={Urls.templateUrlV6}>
                      <div className="flex gap-5px" style={{ height: "17px" }}>
                        Flows
                        <Tag
                          label="V6"
                          type="grey"
                          customStyle={{ fontSize: "12px" }}
                        />
                      </div>
                    </NavLink>
                    <div className="navItem-Bar" />
                  </>
                </NavItem>
              )}
              renderDefaultCallback={() => <></>}
            />

            <FeatureFlag
              flagKey="flows-beta-tab"
              renderFeatureCallback={() => (
                <NavItem active={pathname.startsWith(Urls.flowListing)}>
                  <>
                    <NavLink tag={RouterNavLink} to={Urls.flowListing}>
                      Flows
                    </NavLink>
                    <div className="navItem-Bar" />
                  </>
                </NavItem>
              )}
              renderDefaultCallback={() => <></>}
            />

            {/* USERS */}
            <FeatureFlag
              flagKey="end-user-tab"
              renderFeatureCallback={() => (
                <NavItem active={pathname.startsWith(Urls.endUsers)}>
                  <NavLink tag={RouterNavLink} to={Urls.endUsers}>
                    Users
                  </NavLink>
                  <div className="navItem-Bar" />
                </NavItem>
              )}
              renderDefaultCallback={() => <></>}
            />

            {/* DEPLOYMENTS */}
            <FeatureFlag
              flagKey="legacy-deployments-tab"
              renderFeatureCallback={() => (
                <FeatureFlag
                  flagKey="deployments-tab"
                  renderFeatureCallback={() => (
                    <NavItem active={pathname.startsWith("/deployments")}>
                      <NavLink tag={RouterNavLink} to={Urls.deploymentsUrlV3}>
                        Deployments
                      </NavLink>
                      <div className="navItem-Bar" />
                    </NavItem>
                  )}
                  renderDefaultCallback={() => <></>}
                />
              )}
              renderDefaultCallback={() => <></>}
            />

            {/* CONNECTORS */}
            <FeatureFlag
              flagKey="connectors-tab"
              renderFeatureCallback={() => (
                <NavItem
                  active={
                    pathname.startsWith("/apps") ||
                    pathname.startsWith("/external-app")
                  }
                >
                  <FeatureFlag
                    flagKey="apps-v4"
                    renderFeatureCallback={() => (
                      <>
                        <NavLink tag={RouterNavLink} to="/apps/v4/">
                          Connectors
                        </NavLink>
                        <div className="navItem-Bar" />
                      </>
                    )}
                    renderDefaultCallback={() => (
                      <>
                        <NavLink tag={RouterNavLink} to="/apps/v3">
                          Connectors
                        </NavLink>
                        <div className="navItem-Bar" />
                      </>
                    )}
                  />
                </NavItem>
              )}
              renderDefaultCallback={() => <></>}
            />

            {/* <NavItem
                active={
                  pathname.startsWith("/templates") ||
                  pathname.startsWith("/integration-view") ||
                  pathname.startsWith("/view/flow")
                }
              >
                <FeatureFlag
                  flagKey="templates-v6"
                  renderFeatureCallback={() => (
                    <>
                      <FeatureFlag
                        flagKey="flows-rebranding"
                        renderFeatureCallback={() => (
                          <NavLink tag={RouterNavLink} to={Urls.templateUrlV6}>
                            Integrations
                          </NavLink>
                        )}
                        renderDefaultCallback={() => (
                          <NavLink tag={RouterNavLink} to={Urls.templateUrlV6}>
                            Flows
                          </NavLink>
                        )}
                      />
                      <div className="navItem-Bar" />
                    </>
                  )}
                  renderDefaultCallback={() => (
                    <>
                      <NavLink tag={RouterNavLink} to={Urls.templateUrlFlagged}>
                        Flows
                      </NavLink>
                      <div className="navItem-Bar" />
                    </>
                  )}
                />
              </NavItem> */}

            {/* INSIGHTS */}
            {user?.email && user.email.includes("@integry.io") && (
              <FeatureFlag
                flagKey="insight-page"
                renderFeatureCallback={() => (
                  <NavItem active={pathname.startsWith("/insights")}>
                    <NavLink tag={RouterNavLink} to="/insights/">
                      Insights
                    </NavLink>
                    <div className="navItem-Bar" />
                  </NavItem>
                )}
                renderDefaultCallback={() => <></>}
              />
            )}

            <FeatureFlag
              flagKey="integrations-tab"
              renderFeatureCallback={() => (
                <NavItem active={pathname.startsWith(Urls.integrations)}>
                  <NavLink tag={RouterNavLink} to={Urls.integrations}>
                    Integrations
                  </NavLink>
                  <div className="navItem-Bar" />
                </NavItem>
              )}
              renderDefaultCallback={() => <></>}
            />

            <FeatureFlag
              flagKey="users-tab-v2"
              renderFeatureCallback={() => (
                <NavItem active={pathname.startsWith(Urls.users)}>
                  <NavLink tag={RouterNavLink} to={Urls.users}>
                    Users
                  </NavLink>
                  <div className="navItem-Bar" />
                </NavItem>
              )}
              renderDefaultCallback={() => <></>}
            />
          </Nav>

          <GlobalAppFormV3
            disabled={false}
            hideWorkspaceDropdown={hideWorkspaceDropdown}
          />
          <div className="header-actions">
            <div className="docs-link-wrapper">
              <a
                href={externalUrl.helpCenter}
                className="navbar-dropdown-menu-item"
                target="_blank"
                rel="no noreferrer"
              >
                <img src={helpCenterIcon} alt="help-center-icon" />
                Docs
              </a>
            </div>
            <NavbarDropdown user={user} />
          </div>
        </Collapse>
      </Navbar>
    </div>
  );
};

const Header = withRouter(HeaderWithoutRouter);
export default Header;
