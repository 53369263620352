/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useEffect, useState } from "react";
import IntegryModal from "components/integry-design-system/molecules/integry-modal";
import { toast } from "react-toastify";

import MediaUploadComponent from "components/integry-design-system/molecules/media-upload-component";
import Button from "components/common/reusable-button";
import http from "utils/http";

import "./styles.scss";

interface IProps {
  app: any;
  onStatusUpdate?: any;
  onClearJobStatus?: any;
}

// interface IJob {
//   id: number;
//   status: "ENQUEUED" | "IN_PROGRESS" | "FAILED" | "COMPLETED";
//   summary?: string;
// }

const ImportWithAI = (props: IProps): ReactElement => {
  const { app, onStatusUpdate, onClearJobStatus } = props;
  const [showUploadModal, setShowUploadModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fetchingSatatus, setFetchingStatus] = useState(false);
  const [uploadedFileURL, setUploadedFileURL] = useState("");
  const [additionalDetails, setAdditionalDetails] = useState("");
  const [jobStatus, setJobStatus] = useState<any>({
    [app.id]: {
      id: -1,
      status: "COMPLETED",
      summary: "",
    },
  });
  const allowImportStatuses = ["COMPLETED", "FAILED"];
  useEffect(() => {
    setJobStatus({
      [app.id]: {
        id: -1,
        status: "COMPLETED",
        summary: "",
      },
    });
    getUploadStatus();
  }, [app.id]);

  const handleImport = (): void => {
    http
      .post(`api/apps/${app.id}/activities/imports/`, {
        app_id: app.id,
        additional_details: additionalDetails,
        file_url: uploadedFileURL,
      })
      .then((res) => {
        const { data } = res;
        const { id = -1, status = "COMPLETED", summary = "" } = data;
        setJobStatus({
          [app.id]: {
            id,
            status,
            summary,
          },
        });
        getUploadStatus();
      })
      .catch((e) => {
        console.error("ERROR while importing", e);
      });
  };
  const getUploadStatus = (): void => {
    setFetchingStatus(true);
    http
      .get(`api/apps/${app.id}/activities/imports/status/`)
      .then((res) => {
        const { data } = res;
        const { job } = data;
        if (job.id && job.app.id === app.id) {
          if (job.status !== "COMPLETED") {
            setTimeout(() => {
              getUploadStatus();
            }, 3000);
          }
          setJobStatus({ [app.id]: job });
          if (job.status === "COMPLETED" || job.status === "FAILED") {
            if (onStatusUpdate) {
              onStatusUpdate(job);
            }
          }
        } else {
          setJobStatus({
            [app.id]: {
              id: -1,
              status: "COMPLETED",
              summary: "",
            },
          });
        }
      })
      .catch((e) => {
        console.error("ERROR while fetching upload status", { e });
        setJobStatus({
          [app.id]: {
            id: -1,
            status: "COMPLETED",
            summary: "",
          },
        });
      })
      .finally(() => {
        setFetchingStatus(false);
      });
  };
  return (
    <>
      <div className="import-with-AI">
        <button
          onClick={() => {
            if (
              !allowImportStatuses.includes(
                (jobStatus[app.id] || { id: -1, status: "COMPLETED" }).status
              )
            ) {
              toast.warn(
                <p className="toast-text">
                  Another import is in progress, Please wait till it is
                  completed.
                </p>
              );
            } else {
              setShowUploadModal(true);
              onClearJobStatus();
            }
          }}
          disabled={
            !allowImportStatuses.includes(
              (jobStatus[app.id] || { id: -1, status: "COMPLETED" }).status
            )
          }
          type="button"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            gap: "10px",
          }}
          className="import-pop-cta"
        >
          {jobStatus[app.id] &&
          !allowImportStatuses.includes(jobStatus[app.id].status) ? (
            <>
              <div className="integry-spinner-sm" />
              {`Import is ${jobStatus[app.id].status
                .replace("_", " ")
                .toLowerCase()}`}
            </>
          ) : (
            <>Import</>
          )}
        </button>
        {showUploadModal && (
          <IntegryModal
            title="Import your API"
            className="import-actions-ai-modal"
            onClose={() => {
              setShowUploadModal(false);
            }}
            footer={
              <div className="import-actions-footer">
                <div />
                <div>
                  <Button
                    title="Cancel"
                    onClick={() => {
                      setShowUploadModal(false);
                    }}
                    className="btn btn-gray cancel-btn"
                    type="button"
                  />
                  <Button
                    title="Import"
                    onClick={() => {
                      setShowUploadModal(false);
                      handleImport();
                    }}
                    className="btn btn-royal-blue import-btn"
                    type="button"
                    disabled={uploadedFileURL === ""}
                  />
                </div>
              </div>
            }
          >
            <>
              <div className="field-wrap">
                <p className="field-label">Upload file(s)</p>
                <MediaUploadComponent
                  media={[]}
                  allowedMediaLabel="Supports: doc, docx, json, pdf, txt"
                  allowedUploadMediaType={generateMediaTypesForUploder()}
                  pasteFieldPlaceholder="Paste a URL here"
                  singleUpload
                  handleUploadMedia={(files) => {
                    if (files[0]) {
                      const { URL = "" } = files[0];
                      setUploadedFileURL(URL);
                    } else {
                      setUploadedFileURL("");
                    }
                  }}
                />
              </div>
              <div className="field-wrap description-field">
                <p className="field-label">Description</p>
                <textarea
                  onChange={(e) => {
                    const { value } = e.target;
                    setAdditionalDetails(value);
                  }}
                  placeholder="Enter additional details about the imported API. For example, “Add an extra query parameter to all endpoints.”"
                />
              </div>
            </>
          </IntegryModal>
        )}
      </div>
    </>
  );
};

const generateMediaTypesForUploder = (): string => {
  let mediaTypes = "";
  Object.keys(allowedMediaTypes).forEach((mediaKey) => {
    mediaTypes = `${mediaTypes}${allowedMediaTypes[mediaKey]},`;
  });
  return mediaTypes;
};

const allowedMediaTypes = {
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  doc: "application/msword",
  json: "application/json",
  pdf: "application/pdf",
  txt: "text/plain",
};

export default ImportWithAI;
