/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { ReactElement, useEffect, useState } from "react";
import infoCancelIcon from "images/info-cancel-icon.svg";
import infoIconImage from "images/info-icon.svg";

import "./styles.scss";

export interface IInfoMessage {
  infoIcon?: string;
  infoMessage?: ReactElement | string;
  showMessageProp: boolean;
  onClose?: () => void;
}

const InfoMessage = (props: IInfoMessage): ReactElement => {
  const { infoIcon, infoMessage, showMessageProp, onClose } = props;
  const [showMessage, setShowMessage] = useState(showMessageProp);

  useEffect(() => {
    setShowMessage(showMessageProp);
  }, [showMessageProp]);

  const onClickClose = (): void => {
    setShowMessage(false);
    if (onClose) {
      onClose();
    }
  };

  return showMessage ? (
    <div className="info-mesage-div">
      <img src={infoIcon} alt="info-icon" className="info-icon" />
      <p className="info-message">
        <>{infoMessage}</>
      </p>
      <img
        src={infoCancelIcon}
        alt="info-cancel-icon"
        className="info-cancel-icon"
        onClick={onClickClose}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onClickClose();
          }
        }}
      />
    </div>
  ) : (
    <></>
  );
};

InfoMessage.defaultProps = {
  infoIcon: infoIconImage,
  infoMessage: "Info Message Placeholder",
};

export default InfoMessage;
