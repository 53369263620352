import {
  REQUEST_ACTIVITY_FIELDS,
  RECEIVE_ACTIVITY_FIELDS,
} from "../actions/activity-fields";

export const activityFields = (
  state = {
    isFetching: false,
    lists: [],
  },
  action
) => {
  switch (action.type) {
    case REQUEST_ACTIVITY_FIELDS:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_ACTIVITY_FIELDS:
      return Object.assign({}, state, {
        isFetching: false,
        [action.activityId]: action.lists,
      });
    default:
      return state;
  }
};
