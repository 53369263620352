import { ReactElement } from "react";
import SelectV1 from "components/forms/v4/select";

import "./styles.scss";

// Wrapper for existing Select Field with new Styling

interface IProps {
  placeholder?: string;
  name: string;
  className?: string;
  classNamePrefix?: string;
  isClearable?: boolean;
  disableSeparator?: boolean;
  isLoading?: boolean;
  iconField?: string;
  labelProperty?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: Array<{ [key: string]: any }>; // Adjust the type based on the structure of your options
  readonly?: boolean;
  isDisabled?: boolean;
  addBottomBorderOnFirstOption?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (selectedOption: any) => void;
  onBlur?: () => void;
}

const Select = (props: IProps): ReactElement => {
  const {
    placeholder,
    name,
    className = "",
    classNamePrefix = "",
    isClearable = false,
    disableSeparator = true,
    isLoading = false,
    iconField = "icon_url",
    labelProperty = "name",
    options = [],
    addBottomBorderOnFirstOption = false,
    isDisabled = false,
    onChange,
    onBlur,
    ...rest
  } = props;
  return (
    <SelectV1
      className={`app-auth-dropdown${className}  ${
        addBottomBorderOnFirstOption ? "add-bottom-border" : ""
      }`}
      classNamePrefix={`app-select${classNamePrefix}`}
      placeholderText={placeholder}
      name={name}
      options={options}
      isClearable={isClearable}
      disableSeparator={disableSeparator}
      labelProperty={labelProperty}
      onChange={(
        authorization_type,
        has_authorization_setup,
        selectedOption
      ) => {
        if (onChange) onChange(selectedOption);
      }}
      isLoading={isLoading}
      iconField={iconField}
      isDisabled={isDisabled}
      {...rest}
    />
  );
};

export default Select;
