/* eslint-disable @typescript-eslint/no-explicit-any */
/* 
Utils to generate tags for steps
*/

import {
  Step,
  Field,
  Template,
} from "features/templates/template-builder-v7/services/template-builder-interface";
import { decodeJSON } from "utils/functions";
import config from "utils/config";
import { getNode } from "utils/tree";

import {
  WorkspaceVariable,
  AppUserVariable,
} from "features/account-settings/variables/interface";

import storageIcon from "images/intg_view_storage.svg";

import { getStepFiledDefaultValue } from "./step-fields";
import { TemplateStepFieldDataType } from "../enum/templateField";

export const geTagsFromTestResult = (step: Step): Record<string, never> => {
  let testResult = {};
  if (step.test_output) {
    if (step.test_output?.out) {
      try {
        testResult = {
          ...testResult,
          out: JSON.parse(step.test_output?.out),
        };
      } catch (e) {
        console.log(e);
        testResult = {
          ...testResult,
          out: null,
        };
      }
    }
    if (step.test_output?.in) {
      try {
        testResult = {
          ...testResult,
          in: JSON.parse(step.test_output?.in),
        };
      } catch (e) {
        console.log(e);
        testResult = {
          ...testResult,
          in: null,
        };
      }
    }
  }
  return testResult;
};

const getOuputFromActivityField = ({
  activityFields,
  machineName,
}: {
  activityFields: Field[];
  machineName: string;
}): Record<string, any> => {
  let output = {};
  try {
    const activityField = activityFields.find(
      (field) =>
        (field.machine_name || field.activity_field_machine_name) ===
        machineName
    );
    if (activityField) {
      output = decodeJSON(activityField.default_value);
    }
  } catch (error) {
    console.error("Error in parsing activity output", error);
  }
  return output;
};

export const getCodeStepTags = (step: Step): Record<string, any> => {
  if (step?.activity?.type === "CODE") {
    try {
      const codeStepOutput =
        getOuputFromActivityField({
          activityFields: step.fields,
          machineName: "output",
        })?.result?.parsedResponseTemplate || "";
      return { out: decodeJSON(codeStepOutput), status: "" };
    } catch (error) {
      console.error("Error in parsing activity output", error);
    }
  }
  return {};
};

export const getConditionStepTags = (step: Step): Record<string, any> => {
  const conditionTags = {
    status: "",
    network_code: "",
  };
  return conditionTags;
};

export const getStorageTags = (
  steps: Step[]
): {
  runStorageTags: Record<string, any>;
  IntegrationStorageTags: Record<string, any>;
} => {
  const { storage } = config;
  const { set_value, append_value, increment_value, decrement_value } = storage;
  const storageActivityIds = [
    set_value.id.toString(),
    append_value.id.toString(),
    increment_value.id.toString(),
    decrement_value.id.toString(),
  ];
  let keys = {} as Record<string, any>;
  let storageV3Tags = {} as Record<string, any>;
  let isStorageV3 = false;
  // recursive function to get all keys from storage steps
  const getKeys = (step: Step): Record<string, any> => {
    if (
      step.app_id === config.storage.appId &&
      storageActivityIds.includes(step.activity_id.toString())
    ) {
      if (!isStorageV3) {
        // check if atleast one step is storage is v3
        isStorageV3 = step.activity_version === 3;
      }
      const key = getStepFiledDefaultValue(step.fields, "key");
      const scope = getStepFiledDefaultValue(
        step.fields,
        "scope"
      ).toLowerCase();
      if (key) {
        keys = {
          ...keys,
          [scope]: {
            ...keys[scope],
            [key]: {
              value: "",
            },
          },
        };
        storageV3Tags = {
          ...storageV3Tags,
          [scope]: {
            ...storageV3Tags[scope],
            [key]: "",
          },
        };
      }
    }
    if (step.steps && step.steps.length > 0) {
      step.steps.forEach((s: Step) => getKeys(s));
    }
    return keys;
  };
  steps.forEach((step) => getKeys(step));

  const runStorageTags = {
    customTags: {
      ...(isStorageV3 ? storageV3Tags?.run : keys?.run || {}),
    },
    comments: "Run Variables",
    isCustom: true,
    customTagIcon: storageIcon,
    machine_name: "storage.run",
    isExpandableOnly: {
      run: true,
      integration: true,
    },
  };
  const IntegrationStorageTags = {
    customTags: {
      ...(isStorageV3 ? storageV3Tags?.integration : keys?.integration || {}),
    },
    comments: "Integration Variables",
    isCustom: true,
    customTagIcon: storageIcon,
    machine_name: "storage.integration",
    isExpandableOnly: {
      run: true,
      integration: true,
    },
  };
  return { runStorageTags, IntegrationStorageTags };
};

export const transfromWorkspaceVariablesToTags = (
  variables: WorkspaceVariable[]
): Record<string, any> => {
  const tags = variables.reduce((acc, variable) => {
    const { key, value } = variable;
    return {
      ...acc,
      [key]: value,
    };
  }, {});

  const variablesTags = {
    customTags: tags,
    comments: "Workspace Variables",
    isCustom: true,
    machine_name: "storage.workspace",
    customTagIcon: storageIcon,
  };
  return variablesTags;
};

export const transfromAppUserVariablesToTags = (
  variables: AppUserVariable[]
): Record<string, any> => {
  const tags = variables.reduce((acc, variable) => {
    const { key, value } = variable;
    return {
      ...acc,
      [key]: value,
    };
  }, {});

  const variablesTags = {
    customTags: tags,
    comments: "User Variables",
    isCustom: true,
    machine_name: "storage.user",
    customTagIcon: storageIcon,
  };
  return variablesTags;
};

export const getMachineNameFromTag = (tag: string): string => {
  const splittedTag = tag
    .replace("{", "")
    .replace("}", "")
    .replace("steps.", "")
    .split("."); // remove brackets from tag and split them by .
  return splittedTag?.[0] || tag;
};

export const getFunctionParameterTags = (
  template: Template
): Record<string, any> => {
  const parameterStep = getFunctionStep(template);

  const params = parameterStep?.fields.reduce((acc, field) => {
    const { machine_name = "", default_value, fields, data_type } = field;
    const hasNestedFields =
      (data_type === TemplateStepFieldDataType.OBJECT ||
        data_type === TemplateStepFieldDataType.OBJECT_ARRAY) &&
      fields?.length > 0;

    let defaultValue = hasNestedFields
      ? fields.reduce((nestedAcc, nestedField) => {
          return {
            ...nestedAcc,
            [nestedField.machine_name]: nestedField.default_value,
          };
        }, {})
      : default_value;

    if (data_type === TemplateStepFieldDataType.OBJECT_ARRAY) {
      defaultValue = [defaultValue];
    }

    return {
      ...acc,
      [machine_name]: defaultValue,
    };
  }, {});

  const paramsTags = {
    customTags: params,
    comments: "Parameters",
    isCustom: true,
    customTagIcon: config.parameters.icon,
    machine_name: "parameters",
  };

  return paramsTags;
};

const getFunctionStep = (template_: Template): Step | null => {
  const paramStep = getNode(template_, "steps", [1]) as Step;
  if (paramStep.activity_id === config.parameters.activityId) {
    return paramStep;
  }
  return null;
};
