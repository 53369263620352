import React, {
  useState,
  CSSProperties,
  ChangeEvent,
  useEffect,
  ReactElement,
} from "react";
import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";

import "./styles.scss";

export interface IRadioOptions {
  label?: string | ReactElement;
  value: string;
  render?: JSX.Element;
  disabled?: boolean;
  tooltip?: string;
  tooltipPlacement?: "bottom" | "top" | "left" | "right" | undefined;
}

export interface IProps {
  customStyle?: CSSProperties;
  onRadioSelection?: ({ value }: { value: string }) => void;
  options: Array<IRadioOptions>;
  defaultValue?: string;
  className?: string;
  disabled?: boolean;
  showBorders?: boolean;
}

const RadioSelection = ({
  customStyle = {},
  ...props
}: IProps): JSX.Element => {
  const [value, setValue] = useState("");
  const {
    onRadioSelection,
    options = [],
    defaultValue,
    className,
    disabled = false,
    showBorders = false,
  } = props;

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!disabled) {
      setValue(e.target.value);
      if (onRadioSelection) {
        onRadioSelection({
          value: e.target.value,
        });
      }
    }
  };

  const handleOnClick = (changeValue): void => {
    if (!disabled) {
      setValue(changeValue);
      if (onRadioSelection) {
        onRadioSelection({
          value: changeValue,
        });
      }
    }
  };

  return (
    <div className={`integry-radio-selection ${className || ""}`}>
      {options.map((option) => {
        return (
          <IntegryTooltip
            className="dark-tooltip"
            tooltipText={option.tooltip}
            placement={option.tooltipPlacement || "bottom"}
            hideToolTip={!option.tooltip || option.tooltip === ""}
          >
            <div
              key={`${option.value}`}
              className={`integry-control-group ${
                option.value === value && showBorders
                  ? "integry-control-group--selected"
                  : ""
              } ${showBorders ? "cursorPointer" : ""}
            ${disabled || option.disabled ? "disabled" : ""}`}
              aria-hidden="true"
              onClick={() => {
                if (showBorders) {
                  if (!option.disabled) handleOnClick(option.value);
                }
              }}
            >
              <input
                type="radio"
                className={`integry-radio-selection__input ${
                  disabled ? "disabled" : ""
                } `}
                value={`${option.value}`}
                onChange={handleOnChange}
                checked={option.value === value}
                disabled={disabled || option.disabled}
              />
              {!option.render && (
                <span
                  className={`integry-radio-selection__label ${
                    disabled || option.disabled ? "disabled" : ""
                  }`}
                  onClick={() => {
                    if (!option.disabled) handleOnClick(option.value);
                  }}
                  aria-hidden="true"
                >
                  {option.label}
                </span>
              )}
              {option.render && (
                <div
                  className={`integry-radio-selection__label ${
                    disabled || option.disabled ? "disabled" : ""
                  }`}
                  onClick={() => {
                    if (!option.disabled) handleOnClick(option.value);
                  }}
                  aria-hidden="true"
                >
                  {option.render}
                </div>
              )}
            </div>
          </IntegryTooltip>
        );
      })}
    </div>
  );
};

export default RadioSelection;
