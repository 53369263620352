/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
import Tag from "components/integry-design-system/atoms/tag";
import { ReactElement } from "react";
import { toast } from "react-toastify";
import redCrossIcon from "images/red-cross-icon.svg";
import moment from "moment";
import {
  breadCrumbConfigObject,
  flowMetaObject,
  integrationMetaObject,
  IntegrationMetaSubInfoArrayProps,
  integrationViewDataObject,
  integrationViewTypeEnum,
  runMetaObject,
  singleModule,
  stepMetaObject,
} from "../interfaces";

const singleStep = {
  stepId: "0026124",
  stepName: "When Contact Updated with Updated",
  startTime: "Apr 07, 10:14 AM",
  duration: "2s",
  status: "OK",
  networkCode: "403",
  hasSteps: false,
  stepApp: {
    iconSrc:
      "https://storage.googleapis.com/app-services-bucket/public/0294e41b-878b-4815-ab39-ba2b22d15ff7.png",
    name: "Sendinblue",
  },
  payload: {
    parsedUrl: "https://api.sendinblue.com/v3/contacts/update",
    parsedHeaders: '{"Content-Type":"application/json"}',
    parsedObjectOutput:
      '{"email":"","firstname":"","lastname":"","attributes":[{"key":"","value":""}]}',
    parsedRequestTemplate:
      '{"email":"","firstname":"","lastname":"","attributes":[{"key":"","value":""}]}',
    parsedResponseTemplate:
      '{"email":"","firstname":"","lastname":"","attributes":[{"key":"","value":""}]}',
    remoteResponse:
      '{"email":"","firstname":"","lastname":"","attributes":[{"key":"","value":""}]}',
    requestTemplate:
      '{"email":"","firstname":"","lastname":"","attributes":[{"key":"","value":""}]}',
    responseHeaders: '{"Content-Type":"application/json"}',
    infoMessage: [{ message: "hello", type: "INFO" }],
  },
  count: 2,
};

export const defaultIntegrationViewState = {
  flowId: "123",
  flowName: "Survey monkey",
  flowLogo:
    "https://prod.smassets.net/assets/static/images/surveymonkey/favicon.svg",
  flowStatus: "Published",
  integration: {
    integrationId: "118083",
    userId: "fc3...005",
    appAuthId: "ian@surveymonkey.com",
    dateCreated: "Apr 07, 10:14 AM",
    lastRunStart: "Apr 07, 10:14 AM",
    lastRunStatus: "Failed",
    integrationStatus: "Disabled",
    hasRuns: false,
  },
  integrations: [
    {
      integrationId: "118083",
      userId: "fc3...005",
      appAuthId: "ian@surveymonkey.com",
      dateCreated: "Apr 07, 10:14 AM",
      lastRunStart: "Apr 07, 10:14 AM",
      lastRunStatus: "Failed",
      integrationStatus: "Disabled",
      hasRuns: false,
    },
    {
      integrationId: "118084",
      userId: "fc3...006",
      appAuthId: "man@surveymonkey.com",
      dateCreated: "Apr 08, 10:14 AM",
      lastRunStart: "Apr 08, 10:14 AM",
      lastRunStatus: "Failed",
      integrationStatus: "Disabled",
      hasRuns: false,
    },
  ],
  run: {
    runId: "0026124",
    triggerName: "Pre-delete autohooks",
    triggerType: "Webhook",
    startTime: "Apr 07, 10:14 AM",
    duration: "2s",
    status: "Failed",
    networkCode: "403",
    hasSteps: false,
    triggerApp: {
      iconSrc: "",
      name: "Sendinblue",
    },
  },
  runs: [
    {
      runId: "0026124",
      triggerName: "Pre-delete autohooks",
      triggerType: "Webhook",
      startTime: "Apr 07, 10:14 AM",
      duration: "2s",
      status: "Failed",
      networkCode: "403",
      hasSteps: false,
      triggerApp: {
        iconSrc: "",
        name: "Sendinblue",
      },
    },
    {
      runId: "0026374",
      triggerName: "Pre-delete autohooks",
      triggerType: "Webhook",
      startTime: "Apr 07, 10:14 AM",
      duration: "2s",
      status: "OK",
      networkCode: "N/A",
      hasSteps: false,
      triggerApp: {
        iconSrc: "",
        name: "Sendinblue",
      },
    },
  ],
  steps: [
    {
      ...singleStep,
      hasSteps: true,
      steps: [
        { ...singleStep },
        { ...singleStep },
        { ...singleStep },
        { ...singleStep },
      ],
    },
    {
      ...singleStep,
      hasSteps: false,
      steps: [],
    },
    {
      ...singleStep,
      hasSteps: true,
      steps: [
        { ...singleStep },
        { ...singleStep },
        { ...singleStep },
        { ...singleStep },
      ],
    },
    {
      ...singleStep,
      hasSteps: true,
      steps: [
        { ...singleStep },
        { ...singleStep },
        { ...singleStep },
        { ...singleStep },
      ],
    },
  ],
} as integrationViewDataObject;

export const handleKeyDown = (
  event,
  callback,
  callbackArgs = [] as any
): void => {
  if (event.key === "Enter") {
    callback(...callbackArgs);
  }
};

export const getBreadCrumbsConfig = (
  integrationViewType: integrationViewTypeEnum,
  integrationViewData: integrationViewDataObject
): breadCrumbConfigObject => {
  const breadCrumbsConfig = {
    moduleName: "Flows",
    moduleLink: "/templates/v6",
    currentPageName: "",
    nestedModules: [] as singleModule[],
  };
  breadCrumbsConfig.nestedModules.push({
    moduleName: integrationViewData.flowName,
    moduleLink: `/templates/v6/${integrationViewData.flowId}/edit/data-flow`,
  });
  if (integrationViewType === integrationViewTypeEnum.ALL_INTEGRATIONS) {
    breadCrumbsConfig.currentPageName = "All Integrations";
  } else if (integrationViewType === integrationViewTypeEnum.INTEGRATION_RUNS) {
    breadCrumbsConfig.nestedModules.push({
      moduleName: "All Integrations",
      moduleLink: `/integration-view/flow/${integrationViewData.flowId}/integrations`,
    });
    breadCrumbsConfig.currentPageName = `Integration ${integrationViewData.integration?.integrationId}`;
  } else if (
    integrationViewType === integrationViewTypeEnum.INTEGRATION_RUN_STEPS
  ) {
    breadCrumbsConfig.nestedModules.push({
      moduleName: "All Integrations",
      moduleLink: `/integration-view/flow/${integrationViewData.flowId}/integrations`,
    });
    breadCrumbsConfig.nestedModules.push({
      moduleName: `Integration ${integrationViewData.integration?.integrationId}`,
      moduleLink: `/integration-view/flow/${integrationViewData.flowId}/integration/${integrationViewData.integration?.integrationId}/runs`,
    });
    breadCrumbsConfig.currentPageName = `Run ${integrationViewData.run?.runId}`;
  }
  return breadCrumbsConfig;
};

export const getFlowMetaData = (
  integrationViewData: integrationViewDataObject
): flowMetaObject => {
  return {
    flowId: integrationViewData.flowId,
    flowName: integrationViewData.flowName,
    flowLogo: integrationViewData.flowLogo,
    flowStatus: integrationViewData.flowStatus,
  };
};

export const getIntegrationMetaData = (
  integrationViewData: integrationViewDataObject
): integrationMetaObject | undefined => {
  const { integration } = integrationViewData;
  if (integration) {
    return {
      integrationId: integration.integrationId,
      userId: integration.userId,
      appAuthId: integration.appAuthId,
      dateCreated: integration.dateCreated,
      lastRunStart: integration.lastRunStart,
      lastRunStatus: integration.lastRunStatus,
      integrationStatus: integration.integrationStatus,
      hasRuns: integration.hasRuns,
    };
  }
};

export const getListingData = (
  integrationViewData: integrationViewDataObject,
  integrationViewType: integrationViewTypeEnum
): integrationMetaObject[] | runMetaObject[] | stepMetaObject[] | undefined => {
  if (integrationViewType === integrationViewTypeEnum.ALL_INTEGRATIONS) {
    const { integrations } = integrationViewData;
    return integrations;
  }
  if (integrationViewType === integrationViewTypeEnum.INTEGRATION_RUNS) {
    const { runs } = integrationViewData;
    return runs;
  }
  if (integrationViewType === integrationViewTypeEnum.INTEGRATION_RUN_STEPS) {
    const { steps } = integrationViewData;
    return steps;
  }
};

export const getListingTitle = (
  integrationViewType: integrationViewTypeEnum
): string => {
  let listingTitle = "";
  if (integrationViewType === integrationViewTypeEnum.INTEGRATION_RUNS) {
    listingTitle = "Runs";
  } else if (
    integrationViewType === integrationViewTypeEnum.INTEGRATION_RUN_STEPS
  ) {
    listingTitle = "Steps";
  }
  return listingTitle;
};

export const formatDate = (dateString: string | undefined): string => {
  if (dateString) {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }
  return "";
};

export const convertDateToUnixTimeStamp = (dateString: string): string => {
  if (dateString) {
    const date = new Date(dateString);
    const unixTimestamp = Math.floor(date.getTime() / 1000);
    return `${unixTimestamp}`;
  }
  return "";
};

export const FILTER_KEY_LABELS = {
  status: "Status",
  startTime: "Start Time",
  networkCode: "Network Code",
  dateCreated: "Date Created",
  lastRunStart: "Last Run Start",
  lastRunStaus: "Last Run Status",
  integrationStatus: "Integration Status",
};

export const FILTER_KEY_QUERY_PARAM_CAMEL_CASE = {
  status: "status",
  start_time_range_start: "startTimeStart",
  start_time_range_end: "startTimeEnd",
  network_code: "networkCode",
  created_date_gt: "dateCreatedStart",
  created_date_lt: "dateCreatedEnd",
  run_start_gt: "lastRunStartStart",
  run_start_lt: "lastRunStartEnd",
  run_status: "lastRunStaus",
  status_2: "integrationStatus",
};

export const getIntegrationMetaSubInfoArray = (
  integrationMetaData: integrationMetaObject | undefined
): IntegrationMetaSubInfoArrayProps => {
  return {
    attributes: [
      {
        label: "Integration ID",
        render: <>{integrationMetaData?.integrationId}</>,
      },
      {
        label: "User ID",
        render: <>{integrationMetaData?.userId}</>,
      },
      {
        label: "App Auth ID",
        render: <>{integrationMetaData?.appAuthId}</>,
      },
      {
        label: "Date Created",
        render: <>{formatDate(integrationMetaData?.dateCreated)}</>,
      },
      {
        label: "Last Run Start",
        render: <>{formatDate(integrationMetaData?.lastRunStart)}</>,
      },
      {
        label: "Last Run Status",
        render: (
          <Tag label={integrationMetaData?.lastRunStatus || ""} type="run" />
        ),
      },
      {
        label: "Integration Status",
        render: <>{integrationMetaData?.integrationStatus}</>,
      },
    ],
  };
};

export const getIntegrationMetaRunSubInfoArray = (
  integrationRunMetaData: runMetaObject | undefined
): IntegrationMetaSubInfoArrayProps => {
  return {
    attributes: [
      {
        label: "Run ID",
        render: <>{integrationRunMetaData?.runId}</>,
      },
      {
        label: "Trigger App",
        render: (
          <>
            <img
              src={integrationRunMetaData?.triggerApp?.iconSrc}
              alt="trigger-app"
              style={{ height: "24px", width: "24px", marginRight: "5px" }}
            />
            <span>{integrationRunMetaData?.triggerApp?.name}</span>
          </>
        ),
      },
      {
        label: "Trigger Name",
        render: <>{integrationRunMetaData?.triggerName}</>,
      },
      {
        label: "Trigger Type",
        render: <>{integrationRunMetaData?.triggerType}</>,
      },
      {
        label: "Start Time",
        render: (
          <>
            {moment(integrationRunMetaData?.startTime).format(
              "MMM DD, hh:mm A"
            )}
          </>
        ),
      },
      {
        label: "Duration",
        render: <>{integrationRunMetaData?.duration}s</>,
      },
      {
        label: "Run Status",
        render: <Tag label={integrationRunMetaData?.status || ""} type="run" />,
      },
    ],
  };
};

export const getSearchBarPlaceholder = (
  integrationViewType: integrationViewTypeEnum
): string => {
  if (integrationViewType === integrationViewTypeEnum.INTEGRATION_RUNS) {
    return "Search by Run ID or Trigger App";
  }
  return "Search by Integration ID, User ID or App Auth";
};

export const templateErrorMessage = (
  title: string,
  msg: string
): ReactElement => {
  return (
    <div>
      <div className="lable error">{title}</div>
      <div className="message">{msg}</div>
    </div>
  );
};

const customCloseButton = (): ReactElement => {
  return <img className="custom-close-toast" src={redCrossIcon} alt="Close" />;
};

export const renderApiError = (title: string, msg: string): void => {
  toast.error(templateErrorMessage(title, msg), {
    autoClose: 5000,
    position: "bottom-left",
    closeButton: customCloseButton(),
  });
};

export const setFlowMetaData = (integrationViewData, template): void => {
  integrationViewData.flowId = template.id;
  integrationViewData.flowLogo = template.icon_url;
  integrationViewData.flowName = template.name;
  integrationViewData.flowStatus = template.publishing_status;
};

export const setCountAndMetaKeys = (
  data,
  integrationViewData: integrationViewDataObject,
  integrationViewType: integrationViewTypeEnum
): void => {
  integrationViewData.count = data.count;
  integrationViewData.totalPages = data.total_pages;
  if (integrationViewType === integrationViewTypeEnum.ALL_INTEGRATIONS) {
    const { template } = data.meta;
    setFlowMetaData(integrationViewData, template);
  } else if (
    integrationViewType === integrationViewTypeEnum.INTEGRATION_RUNS ||
    integrationViewType === integrationViewTypeEnum.INTEGRATION_RUN_STEPS
  ) {
    const { integration } = data.meta;
    const { template } = data.meta;
    setFlowMetaData(integrationViewData, template);
    integrationViewData.integration = {
      integrationId: integration.id,
      userId: integration.creator,
      appAuthId: integration.app_auth_id,
      dateCreated: integration.created,
      lastRunStart:
        integration?.last_run_start_time || integration?.last_run_start,
      lastRunStatus:
        integration?.last_run_response_code || integration?.last_run_status,
      integrationStatus: integration.status,
      hasRuns: integration.has_runs,
    };
  }
};

export const getMappedStep = (runStep): stepMetaObject => ({
  stepId: runStep.id,
  stepName: runStep.title,
  startTime: runStep.start_time,
  duration: runStep.duration_seconds,
  status: runStep.status,
  networkCode: runStep.network_code,
  hasSteps: runStep.child_steps?.length > 0,
  stepApp: {
    iconSrc: runStep.app.icon_url,
    name: runStep.app.name,
  },
  payload: {
    parsedUrl: runStep.payload.parsed_url,
    parsedHeaders: runStep.payload.parsed_headers,
    parsedObjectOutput: runStep.payload.parsed_object_output,
    parsedRequestTemplate: runStep.payload.parsed_request_template,
    parsedResponseTemplate: runStep.payload.parsed_response_template,
    remoteResponse: runStep.payload.remote_response,
    requestTemplate: runStep.payload.request_template,
    responseHeaders: runStep.payload.response_headers,
    infoMessage: runStep.payload.info_message,
    requestHttpVerb: runStep.payload.request_http_verb,
  },
  count: runStep.child_steps?.length || 0,
});

export const mapApiResponseToIntegrationViewState = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data,
  integrationViewType: integrationViewTypeEnum
): integrationViewDataObject => {
  const integrationViewData = {
    flowId: "",
    flowName: "",
    flowLogo: "",
    flowStatus: "",
    integration: undefined,
    integrations: [],
    run: undefined,
    runs: [],
    count: 0,
    totalPages: 0,
  } as integrationViewDataObject;
  if (data && data.meta) {
    setCountAndMetaKeys(data, integrationViewData, integrationViewType);
  }
  if (integrationViewType === integrationViewTypeEnum.ALL_INTEGRATIONS) {
    if (data && data.results.length > 0) {
      integrationViewData.integrations = data.results.map((integrationObj) => {
        return {
          integrationId: integrationObj.id,
          userId: integrationObj.creator,
          appAuthId: integrationObj.app_auth_id,
          dateCreated: integrationObj.created,
          lastRunStart: integrationObj.last_run_start_time,
          lastRunStatus: integrationObj.last_run_response_code,
          integrationStatus: integrationObj.status,
          hasRuns: integrationObj.has_runs,
        };
      });
    }
  } else if (integrationViewType === integrationViewTypeEnum.INTEGRATION_RUNS) {
    if (data && data.results.length > 0) {
      integrationViewData.runs = data.results.map((runObj) => {
        return {
          runId: runObj.id,
          triggerName: runObj.trigger_name,
          triggerType: runObj.trigger_type,
          startTime: runObj.start_time,
          duration: runObj.duration_seconds,
          status: runObj.status,
          networkCode: runObj.network_code,
          hasSteps: runObj.has_steps || false,
          triggerApp: {
            iconSrc: runObj.trigger_app.icon_url,
            name: runObj.trigger_app.name,
          },
          logLink: runObj.log_link,
        };
      });
    }
  } else if (
    integrationViewType === integrationViewTypeEnum.INTEGRATION_RUN_STEPS
  ) {
    if (data) {
      const { run } = data;
      if (run) {
        integrationViewData.run = {
          runId: run?.id,
          triggerName: run?.trigger_name,
          triggerType: run?.trigger_type,
          startTime: run?.start_time,
          duration: run?.duration_seconds,
          status: run?.status,
          networkCode: run?.network_code,
          hasSteps: run?.has_steps,
          triggerApp: {
            iconSrc: run?.trigger_app?.icon_url,
            name: run?.trigger_app?.name,
          },
          logLink: run.log_link,
        };
      }
      if (run?.run_steps?.length > 0) {
        integrationViewData.steps = run?.run_steps.map((runStep) => {
          return {
            ...getMappedStep(runStep),
            steps: runStep.child_steps?.map((childStep) => ({
              ...getMappedStep(childStep),
            })),
          };
        });
      }
    }
  }
  return integrationViewData;
};

export const decodeJSON = (json: string): any => {
  try {
    return JSON.parse(json);
  } catch (e) {
    console.log("Invalid JSON");
    return "";
  }
};

export const decodeJSONReturnSameIfFalse = (json: string): any => {
  try {
    return JSON.parse(json);
  } catch (e) {
    console.log("Invalid JSON");
    return json;
  }
};
