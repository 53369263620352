import { ReactElement } from "react";
import { toast } from "react-toastify";
import redCrossIcon from "images/red-cross-icon.svg";
import { convertDateToLocal } from "utils/functions";
import { convertDateToUTCString } from "utils/date-utils";

import { Integration, TrasformedIntegration } from "../interface";

export const templateErrorMessage = (
  title: string,
  msg: string
): ReactElement => {
  return (
    <div>
      <div className="lable error">{title}</div>
      <div className="message">{msg}</div>
    </div>
  );
};

const customCloseButton = (): ReactElement => {
  return <img className="custom-close-toast" src={redCrossIcon} alt="Close" />;
};

export const renderApiError = (title: string, msg: string): void => {
  toast.error(templateErrorMessage(title, msg), {
    autoClose: 5000,
    position: "bottom-left",
    closeButton: customCloseButton(),
  });
};

export const getIntegrationTooltip = (
  integrationStatus: string,
  app_name: string | undefined,
  timestamp: string | undefined,
  lastUpdated?: string | undefined,
  last_modified_by?: { username: string } | undefined
): string => {
  const convertedTime = convertDateToLocal(timestamp);

  const integrationStatusTooltips = {
    AUTH_MISSING: `Authentication failed for ${app_name} on ${convertedTime}. User needs to re-authenticate their account and enable the integration.`,
    auth_missing: `Authentication failed for ${app_name} on ${convertedTime}. User needs to re-authenticate their account and enable the integration.`,
    PAUSED: "User limit is reached.",
    paused: "User limit is reached.",
    "5XX_ERRORS": `Too many server errors from ${app_name} since ${convertedTime} You can try enabling the integration. If the issue persists, please contact Integry support.`,
    "4XX_ERRORS": `Too many errors from ${app_name} since ${convertedTime} due to a configuration issue. User needs to create a new integration.`,
  };
  if (integrationStatus === "AUTH_MISSING" && (!app_name || !convertedTime)) {
    return `Authentication failed. User needs to re-authenticate their account and enable the integration.`;
  }
  return (
    `${
      integrationStatusTooltips[integrationStatus]
        ? `${integrationStatusTooltips[integrationStatus]}`
        : ""
    }` || "N/A"
  );
};

export const lastModifiedTooltip = (
  integrationStatus: string,
  lastUpdated: string,
  last_modified_by: { username: string } | undefined
): string => {
  const isBlocked = !!BLOCKED_STATUS_TYPES[integrationStatus];
  const { username } = last_modified_by || {};
  const lastUpdateTime = lastUpdated ? convertDateToLocal(lastUpdated) : "";
  const lastUpdatedByString = lastUpdateTime
    ? `Modified by${
        last_modified_by ? ` ${isBlocked ? "Integry" : username}` : ""
      }`
    : "";

  return lastUpdatedByString;
};

const BLOCKED_STATUS_TYPES = {
  PAUSED: "Blocked",
  Paused: "Blocked",
  paused: "Blocked",
  AUTH_MISSING: "Blocked",
  auth_missing: "Blocked",
  "5XX_ERRORS": "Blocked",
  "4XX_ERRORS": "Blocked",
  blocked: "Blocked",
  Blocked: "Blocked",
  BLOCKED: "Blocked",
};

export const lastRunLabels = {
  "200": "OK",
  OK: "OK",
  HAS_NOT_RUN: "No runs as yet",
  has_not_run_old: "Hasn't run",
  FAILED: "Aborted",
  ABORTED: "Aborted",
  RUNNING: "Running...",
  "running...": "Running...",
  "N/A": "N/A",
  COMPLETED: "Completed",
  FILTERED: "Filtered",
};

export const getViewPayloadButtonLabel = (activityType: string): string => {
  return activityType === "CODE" ? "View Code" : "View Payload";
};

export const getHidePayloadButtonLabel = (activityType: string): string => {
  return activityType === "CODE" ? "Hide Code" : "Hide Payload";
};

export const transformIntegration = (
  integration: Integration
): TrasformedIntegration => {
  const {
    app_auth_id,
    created,
    has_runs,
    last_run_start_time,
    last_run_status,
    user_profile_link,
    error_code,
    last_poll_time,
    first_error_received_at,
    error_app_name,
    last_poll_id,
    template_id,
    integration_name,
    user_identity,
    status,
    last_modified_by,
    last_modified_on,
    app_user_external_id,
    runs_this_month,
  } = integration;
  const transfomredIntegrations = {
    ...integration,
    userId: app_auth_id,
    created: convertDateToUTCString(created),
    date_created_full: convertDateToUTCString(created, true),
    last_run_start_time:
      convertDateToUTCString(last_run_start_time) === "N/A"
        ? "No runs as yet"
        : convertDateToUTCString(last_run_start_time),
    last_run_start_time_full: convertDateToUTCString(last_run_start_time, true),
    last_modified_on: convertDateToUTCString(last_modified_on),
    last_modified_on_full: convertDateToUTCString(last_modified_on, true),
    last_modified_tooltip: lastModifiedTooltip(
      status,
      last_modified_on,
      last_modified_by
    ),
    last_run_status: last_run_status || "HAS_NOT_RUN",
    last_run_tooltip: !last_run_start_time
      ? "No runs as yet"
      : `Last run at ${convertDateToUTCString(last_run_start_time, true)} -- ${
          lastRunLabels[last_run_status]
        }`,
    status: status || "N/A",
    app_user_external_id: app_user_external_id || "N/A",
    integration_status_tooltip: getIntegrationTooltip(
      status,
      error_app_name,
      first_error_received_at,
      last_modified_on,
      last_modified_by
    ),
    has_runs,
    user_profile_link,

    last_poll_status: error_code ? "failed" : "OK",
    last_poll_Time: convertDateToUTCString(last_poll_time),
    first_error_received_at: convertDateToUTCString(
      first_error_received_at,
      true
    ),
    error_app_name,
    last_poll_id,
    error_code,
    template_id,
    integration_name: integration_name || "N/A",
    account_id: user_identity || "N/A",
    runs_this_month: integration.runs_this_month || 0,
    runs_this_month_title: integration.runs_this_month?.toLocaleString() || "0",
    runs_this_month_tooltip: `${runs_this_month} run${
      runs_this_month === 1 ? "" : "s"
    } this month`,
  };
  return transfomredIntegrations;
};
