/* eslint-disable no-unused-vars */
import http from "../../../../utils/http";

export const getTamplateUser = (ApiUrl) => http.get(ApiUrl);

export const getUserIntegrations = (ApiUrl) => http.get(ApiUrl);

export const getMoreIntegrationRuns = (ApiUrl) => http.get(`/api/v2${ApiUrl}`);

export const getMoreRunTasks = (ApiUrl) => http.get(`/api/v2${ApiUrl}`);

export const getIntegrationSteps = (templateId, intgId, filter) =>
  http.get(
    `/api/v2/templates/${templateId}/integrations/${intgId}/${
      filter || "?status=all&range=7d"
    }`
  );

export const getRunTasks = (
  templateId,
  intgId,
  runId,
  searchString,
  filter
) => {
  let search = "";
  if (window.location.search !== "") {
    search = window.location.search.replace("?", "&");
  }
  return http.get(
    `/api/v2/templates/${templateId}/integrations/${intgId}/runs/${runId}/?search=${
      searchString || ""
    }&status=${filter || ""}${search}`
  );
};

export const getTaskDetails = (templateId, intgId, taskId) =>
  http.get(
    `/api/v2/templates/${templateId}/integrations/${intgId}/tasks/${taskId}/`
  );

export const getTaskIterations = (ApiUrl) => http.get(ApiUrl);

export const enableDisableIntegration = (
  integrationId,
  status,
  sdkQueryParams
) => http.put(`/v2/integration/${integrationId}/${status}/`);

export const enableDisableIntegrationOld = (
  integrationId,
  status,
  sdkQueryParams
) => http.put(`/v2/integration/${integrationId}/${status}/?${sdkQueryParams}`);

export const deleteIntegration = (integrationId, sdkQueryParams) =>
  http.delete(`/v2/integration/${integrationId}/`);

export const deleteIntegrationOld = (integrationId, sdkQueryParams) =>
  http.delete(`/v2/integration/${integrationId}/?${sdkQueryParams}`);
