/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useEffect, useRef, useState } from "react";

import moment from "moment";

import IntegryTooltip from "components/common/tooltip-wrappers/integry-tooltip";
import { stepMetaObjectPayload } from "components/integry-design-system/organisms/integration-view/step-payload-component";
import lang from "utils/lang/en";
import http from "utils/http";
import { RunSteps } from "features/integration-run-steps/interface";
import { decodeJSON } from "utils/functions";

import exlaimationIconOutline from "images/exclaimation_icon_outline.svg";
import NotFoundIcon from "images/not_found_icon_lg.svg";
import SortIconDefault from "images/sort.svg";
import SortIconDesc from "images/sort-applied.svg";

import { renderApiError } from "legacy-features/integrations/helper-utils";
import RenderTable from "./table";
import "legacy-features/integrations/styles.scss";
import "./styles.scss";
import SkeletonLoader from "./skeleton-loader";

interface StepListigTableProps {
  flowId: string | number;
  integrationIdParam: string;
  runIdParam: string;
  steps: RunSteps[];
  fetching: boolean;
  hasMore: boolean;
  expandedPayloadStepId: string;
  sorting: Record<string, string>;
  tableConfig: any;
  triggerId?: number;
  setExpandedPayloadStepId: (id: string) => void;
  handleOnSorting: (sortingIdentifier: string) => void;
  fetchMoreSteps: () => void;
}

const StepListigTable = (props: StepListigTableProps): ReactElement => {
  const {
    flowId,
    integrationIdParam,
    runIdParam,
    steps,
    fetching,
    sorting,
    expandedPayloadStepId,
    tableConfig,
    triggerId = "",
    hasMore,
    fetchMoreSteps,
    handleOnSorting,
    setExpandedPayloadStepId,
  } = props;

  const [collapsedStepId, setCollapsedStepId] = useState<Array<string>>([]);
  const [expandedStepId, setExpandedStepId] = useState<Array<string>>([]);

  const expandedStepIdRef = useRef(expandedStepId);

  useEffect(() => {
    expandedStepIdRef.current = expandedStepId;
  }, [expandedStepId]);

  const [viewStepPayloadData, setViewStepPayloadData] =
    useState<stepMetaObjectPayload>({});

  const handleStepExpansion = (stepId: string): void => {
    addToExpandedSteps(stepId);
  };

  const initiallyToExpandedSteps = (stepId: string): void => {
    if (!collapsedStepId.includes(stepId)) {
      addToExpandedSteps(stepId);
    }
  };

  const addToExpandedSteps = (stepId: string): void => {
    let tempExpandedIds: Array<string> = [];
    const tempCollapsedIds: Array<string> = [];
    if (expandedStepIdRef.current.includes(stepId)) {
      expandedStepIdRef.current.forEach((id) => {
        if (id !== stepId) {
          tempExpandedIds.push(id);
        } else {
          tempCollapsedIds.push(id);
        }
      });
    } else {
      tempExpandedIds = [...expandedStepIdRef.current, stepId];
    }
    setCollapsedStepId(tempCollapsedIds);
    setExpandedStepId(tempExpandedIds);
    expandedStepIdRef.current = tempExpandedIds;
    setExpandedPayloadStepId("-1");
  };

  const updateTriggerOldData = (step): boolean => {
    if (step.activityType === "TRIGGER") {
      /** NOTE: ADDED DATE DIFF LOGIC TO Update the trigger for old data to show as requests and not response. */
      const dateDiffInMins = moment("2022-07-19T15:35:00").diff(
        step.startTimeRaw,
        "minutes"
      );
      if (dateDiffInMins > 0) {
        return true;
      }
    }
    return false;
  };

  const fetchStepPayloadData = (stepId: number, step: any): void => {
    const uri = triggerId
      ? `api/v1/templates/${flowId}/test-triggers/${triggerId}/runs/${runIdParam}/steps/${stepId}/payload/`
      : `/api/v1/templates/${flowId}/integrations/${integrationIdParam}/runs/${runIdParam}/steps/${stepId}/payload/`;
    const isCodeStep = step.activityType === "CODE";
    http
      .get(uri)
      .then((response) => {
        let payloadData: stepMetaObjectPayload = {};
        const {
          // info_message = [],
          parsed_headers,
          parsed_object_output,
          parsed_request_template,
          parsed_response_template,
          parsed_url,
          remote_response,
          request_http_verb,
          request_template,
          response_headers,
          step_condition,
        } = response.data?.payload;
        payloadData = {
          parsedUrl: parsed_url,
          parsedHeaders: parsed_headers,
          parsedObjectOutput: parsed_object_output,
          parsedRequestTemplate: parsed_request_template,
          parsedResponseTemplate: parsed_response_template,
          remoteResponse: remote_response,
          requestTemplate: isCodeStep
            ? parsed_request_template
            : request_template,
          responseHeaders: response_headers,
          // infoMessage: info_message,
          infoMessage: [],
          requestHttpVerb: (request_http_verb || "").toUpperCase(),
          activityType: step.activityType,
          appNetworkDocumentationLink:
            step?.stepApp?.network_code_documentation_link || "",
          appName: step?.stepApp?.name || "Step App",
        };
        if (step_condition) {
          payloadData.stepCondition = decodeJSON(step_condition);
        }
        if (updateTriggerOldData(step)) {
          // NOTE: Switch request and response data to update old triggers
          payloadData.parsedHeaders = response_headers;
          payloadData.responseHeaders = parsed_headers;
          payloadData.parsedRequestTemplate = parsed_response_template;
          payloadData.parsedResponseTemplate = parsed_request_template;
          payloadData.requestTemplate = remote_response;
          payloadData.remoteResponse = request_template;
        }
        setViewStepPayloadData(payloadData);
      })
      .catch((error) => {
        renderApiError("Fetching Step Payload Failed.", error.message);
        console.error({ error });
        setViewStepPayloadData({});
        setExpandedPayloadStepId("-1");
      });
  };

  return (
    <div className="step-listing-table">
      <div className="div-table">
        <div className="div-header div-header-new table-row">
          {tableConfig.steps.config.map((configItem) => {
            const { tooltip, allowSorting } = configItem;
            if (tooltip && allowSorting) {
              return (
                <div className="div-heading">
                  {configItem.label}
                  <IntegryTooltip
                    tooltipText={configItem.tooltip}
                    placement="top"
                  >
                    <img
                      width={15}
                      height={15}
                      src={exlaimationIconOutline}
                      style={{
                        marginLeft: "5px",
                        marginTop: "-2px",
                        transform: "rotateZ(180deg)",
                      }}
                      alt="tooltip_heading"
                    />
                  </IntegryTooltip>
                  <img
                    width={15}
                    height={15}
                    style={{
                      marginLeft: "5px",
                      marginTop: "-2px",
                      cursor: "pointer",
                      opacity: !fetching ? "1" : "0.4",
                    }}
                    src={
                      sorting[configItem.sortingIdentifier || configItem.id]
                        ? SortIconDesc
                        : SortIconDefault
                    }
                    className={
                      sorting[configItem.sortingIdentifier || configItem.id] &&
                      sorting[configItem.sortingIdentifier || configItem.id] ===
                        "asc"
                        ? "table-sort-icon-asc"
                        : ""
                    }
                    alt="sort_icon"
                    onClick={() => {
                      if (!fetching) {
                        handleOnSorting(
                          configItem.sortingIdentifier || configItem.id
                        );
                      }
                    }}
                    aria-hidden="true"
                  />
                </div>
              );
            }
            if (tooltip) {
              return (
                <div className="div-heading">
                  {configItem.label}
                  <IntegryTooltip
                    tooltipText={configItem.tooltip}
                    placement="top"
                  >
                    <img
                      width={15}
                      height={15}
                      src={exlaimationIconOutline}
                      style={{
                        marginLeft: "5px",
                        marginTop: "-2px",
                        transform: "rotateZ(180deg)",
                      }}
                      alt="tooltip_heading"
                    />
                  </IntegryTooltip>
                </div>
              );
            }
            if (allowSorting) {
              return (
                <div className="div-heading">
                  {configItem.label}
                  <img
                    width={15}
                    height={15}
                    style={{
                      marginLeft: "5px",
                      marginTop: "-2px",
                      cursor: "pointer",
                      opacity: !fetching ? "1" : "0.4",
                    }}
                    src={
                      sorting[configItem.sortingIdentifier || configItem.id]
                        ? SortIconDesc
                        : SortIconDefault
                    }
                    className={
                      sorting[configItem.sortingIdentifier || configItem.id] &&
                      sorting[configItem.sortingIdentifier || configItem.id] ===
                        "asc"
                        ? "table-sort-icon-asc"
                        : ""
                    }
                    alt="sort_icon"
                    onClick={() => {
                      if (!fetching) {
                        handleOnSorting(
                          configItem.sortingIdentifier || configItem.id
                        );
                      }
                    }}
                    aria-hidden="true"
                  />
                </div>
              );
            }
            return <div className="div-heading">{configItem.label}</div>;
          })}
        </div>
        {!fetching &&
          steps.length < 1 && ( // Empty Message
            <div className="view-record-not-found__placeholder">
              <img src={NotFoundIcon} alt="record_not_found" />
              <p>{lang.INTEGRATIONS_NOT_FOUND_COPY_STEPS}</p>
            </div>
          )}

        <div className="table-row-container integry-scrollbar-v2">
          {!fetching && (
            <RenderTable
              config={tableConfig.steps.config}
              data={steps}
              node={0}
              autoExpandInError={autoExpandInError}
              expandedStepIdRef={expandedStepIdRef}
              initiallyToExpandedSteps={initiallyToExpandedSteps}
              expandedPayloadStepId={expandedPayloadStepId}
              handleStepExpansion={handleStepExpansion}
              setExpandedPayloadStepId={setExpandedPayloadStepId}
              setViewStepPayloadData={setViewStepPayloadData}
              fetchStepPayloadData={fetchStepPayloadData}
              viewStepPayloadData={viewStepPayloadData}
              expandedStepId={expandedStepId}
              fetching={fetching}
              integrationId={integrationIdParam}
              runId={runIdParam}
            />
          )}
          {fetching && (
            <SkeletonLoader config={tableConfig.steps.config} rowCount={3} />
          )}
          {hasMore && !fetching && (
            <SkeletonLoader
              config={tableConfig.steps.config}
              rowCount={1}
              onInViewport={fetchMoreSteps}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StepListigTable;

const autoExpandInError = [
  "SCHEDULE",
  "CONDITION_IF",
  "CONDITION_ELSEIF",
  "CONDITION_ELSE",
  "LOOP",
  "DO_WHILE_LOOP",
];
