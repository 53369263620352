import {
  REQUEST_STATIC_LISTS,
  RECEIVE_STATIC_LISTS,
} from "../actions/static-lists";

export const staticLists = (
  state = {
    isFetching: false,
    lists: {
      operators: [],
      activity_types: {},
      actions: [],
      verbs: [],
      return_data_formats: [],
      field_types: [],
    },
  },
  action
) => {
  switch (action.type) {
    case REQUEST_STATIC_LISTS:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_STATIC_LISTS:
      return Object.assign({}, state, {
        isFetching: false,
        lists: action.lists,
      });
    default:
      return state;
  }
};
