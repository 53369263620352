import orderBy from "lodash/orderBy";
import take from "lodash/take";
import http from "../../utils/http";

export const REQUEST_MY_APPS = "REQUEST_MY_APPS";

export const requestMyApps = () => ({
  type: REQUEST_MY_APPS,
});

export const RECEIVE_MY_APPS = "RECEIVE_MY_APPS";

export const receiveMyApps = (json) => ({
  type: RECEIVE_MY_APPS,
  apps: json,
});

const getSelectedAppFromLS = (filteredApps) => {
  const lsGlobalSelectedAppId = localStorage.getItem("globallySelectedApp");
  const intGlobalSelectedAppId = parseInt(lsGlobalSelectedAppId || "0", 10);
  let flag = false;
  filteredApps.forEach((app) => {
    if (app.id === intGlobalSelectedAppId) {
      flag = true;
    }
  });
  if (!flag) {
    const firstItemInAppList = orderBy(filteredApps, "name", "asc")[0];
    localStorage.setItem("globallySelectedApp", firstItemInAppList.id);
    return firstItemInAppList.id;
  }
  return intGlobalSelectedAppId;
};

export const fetchMyApps = () => (dispatch) => {
  dispatch(requestMyApps());

  return http.get("/app/").then(
    (response) => {
      dispatch(receiveMyApps(response.data));
      const filteredApps = orderBy(
        response.data.filter((item) => item.is_workspace),
        "name",
        "asc"
      );
      if (filteredApps.length > 0) {
        dispatch(setGloballySeletedAppAuto(getSelectedAppFromLS(filteredApps)));
      }
    },
    (error) => {
      console.log(
        "Error occurred while fetching my apps in fetchMyApps() action.",
        error
      );
    }
  );
};

export const REQUEST_ALL_APPS = "REQUEST_ALL_APPS";

export const requestAllApps = () => ({
  type: REQUEST_ALL_APPS,
});

export const RECEIVE_ALL_APPS = "RECEIVE_ALL_APPS";

export const receiveAllApps = (json) => ({
  type: RECEIVE_ALL_APPS,
  apps: json,
});

export const fetchAllApps = () => (dispatch) => {
  dispatch(requestAllApps());

  return http.get("/app/?view=all").then(
    (response) => {
      dispatch(receiveAllApps(response.data));

      take(
        orderBy(JSON.parse(JSON.stringify(response.data)), "name", "asc"),
        10
      )
        .filter((app) => app.icon_url)
        .map((app) => {
          const image = new Image();
          image.src = app.icon_url;
          return image;
        });
    },
    (error) => {
      console.log(
        "Error occurred while fetching all apps in fetchAllApps() action.",
        error
      );
    }
  );
};

export const SET_GLOBALLY_SELECTED_APP = "SET_GLOBALLY_SELECTED_APP";

export const setGloballySeletedApp = (appId) => {
  localStorage.setItem("globallySelectedApp", `${appId}`);
  return {
    type: SET_GLOBALLY_SELECTED_APP,
    appId,
  };
};

export const REMOVE_MY_APP = "REMOVE_MY_APP";
export const removeAppFromMyApp = (appId) => ({
  type: REMOVE_MY_APP,
  appId,
});

export const REMOVE_ALL_APP = "REMOVE_ALL_APP";

export const removeAppFromAllApp = (appId) => ({
  type: REMOVE_ALL_APP,
  appId,
});

export const SET_GLOBALLY_SELECTED_APP_AUTO = "SET_GLOBALLY_SELECTED_APP_AUTO";

export const setGloballySeletedAppAuto = (appId) => ({
  type: SET_GLOBALLY_SELECTED_APP_AUTO,
  appId,
});

export const SET_USER_ID = "SET_USER_ID";

export const setUserId = (userId) => ({
  type: SET_USER_ID,
  userId,
});
