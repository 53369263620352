import PropTypes from "prop-types";

import SelectedCheckIcon from "images/selected_check.svg";

import "./styles.scss";

const ConnectorCard = (props) => {
  const {
    title,
    iconSrc,
    onCardClick,
    isLoading = false,
    isSelected = false,
    customStyle = {},
  } = props;
  if (isLoading) {
    return (
      <div className="connector-card">
        <div className="connector-card__icon">
          <div className="placeholder">
            <div className="animated-background" />
          </div>
        </div>
        <div className="connector-card__title">
          <div className="placeholder">
            <div className="animated-background" />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      className="connector-card"
      onClick={onCardClick}
      role="button"
      tabIndex={0}
      aria-hidden="true"
      style={customStyle}
    >
      {iconSrc && <img className="connector-card__icon" src={iconSrc} alt="" />}
      {!iconSrc && (
        <div className="connector-card__icon">
          <div className="placeholder" />
        </div>
      )}
      <p className="connector-card__title">{title}</p>
      {isSelected && (
        <img
          className="selected-icon"
          src={SelectedCheckIcon}
          alt={`${title}_selected_ico`}
        />
      )}
    </div>
  );
};

ConnectorCard.propTypes = {
  title: PropTypes.string,
  iconSrc: PropTypes.string,
  onCardClick: PropTypes.func,
  isLoading: PropTypes.bool,
  isSelected: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  customStyle: PropTypes.any,
};

export default ConnectorCard;
