/* eslint-disable import/prefer-default-export */
// function to validate json
// funciont will accept a string or JSON Object and return a boolean.

export const isValidJson = (
  json: string | Record<string, unknown>
): boolean => {
  try {
    if (typeof json === "string") {
      JSON.parse(json);
    } else {
      JSON.stringify(json);
    }
    return true;
  } catch (e) {
    return false;
  }
};

export const formatJsonString = (json: string): string => {
  try {
    const formattedValue = JSON.stringify(JSON.parse(json), null, 2);
    return formattedValue;
  } catch (e) {
    console.error(e);
    return json;
  }
};
