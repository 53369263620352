import { createApi } from "@reduxjs/toolkit/query/react";

import config from "utils/config";
import { integryBaseQuery } from "utils/http";
import {
  CreateWorkspaceVariableRequest,
  WorkspaceVariable,
  PaginatedWorkspaceVariables,
} from "../interface";

interface WorkspaceVariableQueryArgs {
  includeEmptyRow: boolean;
}

export const useWorkspaceVariableAPI = createApi({
  reducerPath: "workSpaceVariables",
  baseQuery: integryBaseQuery({
    baseUrl: config.apiBaseURL || "",
  }),
  tagTypes: ["workSpaceVariables"],
  endpoints(build) {
    return {
      fetchWorkspaceVariable: build.query<
        PaginatedWorkspaceVariables,
        WorkspaceVariableQueryArgs | void
      >({
        query: () => ({
          url: "/api/v1/account-storage/?page_size=10000",
          method: "get",
        }),
        providesTags: ["workSpaceVariables"],
        transformResponse(
          response: PaginatedWorkspaceVariables,
          meta: unknown,
          args: WorkspaceVariableQueryArgs | void
        ) {
          const { includeEmptyRow } = args || { includeEmptyRow: false };
          if (!includeEmptyRow) {
            return response;
          }
          return {
            ...response,
            results: [
              ...response.results,
              {
                id: 0,
                key: "",
                value: "",
                isNew: true,
              },
            ],
          };
        },
      }),
      createWorkspaceVariable: build.mutation<
        WorkspaceVariable,
        CreateWorkspaceVariableRequest
      >({
        query(data: CreateWorkspaceVariableRequest) {
          return {
            url: "/api/v1/account-storage/",
            method: "POST",
            data,
          };
        },
        invalidatesTags: ["workSpaceVariables"],
      }),
      updateWorkspaceVariable: build.mutation({
        query(data: WorkspaceVariable) {
          return {
            url: `/api/v1/account-storage/${data.id}`,
            method: "PATCH",
            data,
          };
        },
        invalidatesTags: ["workSpaceVariables"],
      }),

      deleteWorkspaceVariable: build.mutation({
        query({ id }: { id: number }) {
          return {
            url: `/api/v1/account-storage/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["workSpaceVariables"],
      }),
    };
  },
});

export const {
  useFetchWorkspaceVariableQuery,
  useCreateWorkspaceVariableMutation,
  useUpdateWorkspaceVariableMutation,
  useDeleteWorkspaceVariableMutation,
} = useWorkspaceVariableAPI;
