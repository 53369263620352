import { createApi } from "@reduxjs/toolkit/query/react";
import { integryBaseQuery } from "utils/http";
import { transformIntegration } from "../utils";
import {
  IntegrationPaginated,
  TransformedIntegrationPaginated,
} from "../interface";

const getSortValue = (sortObject: { [key: string]: string }): string => {
  const key = Object.keys(sortObject)[0];
  const value = sortObject[key];
  return `${value === "desc" ? "-" : ""}${key}`;
};

export const useIntegrationsAPI = createApi({
  reducerPath: "integrations",
  baseQuery: integryBaseQuery(),
  tagTypes: ["integrations"],
  endpoints(build) {
    return {
      getIntegrations: build.query<
        TransformedIntegrationPaginated,
        {
          page: number;
          pageSize?: number;
          search?: string;
          lastRunStatuses: string[];
          integrationStatus: string[];
          runStartTime: string;
          runEndTime: string;
          sorting: { [key: string]: string };
        }
      >({
        query: ({
          page,
          search,
          pageSize,
          lastRunStatuses,
          integrationStatus,
          runStartTime,
          runEndTime,
          sorting,
        }) => ({
          url: `/api/v1/integrations/?search=${encodeURIComponent(
            search || ""
          )}&page=${page}&page_size=${pageSize}&last_run_status=${lastRunStatuses.join(
            ","
          )}&status=${integrationStatus.join(
            ","
          )}&last_run_start_time_range_start=${runStartTime}&last_run_start_time_range_end=${runEndTime}&ordering=${getSortValue(
            sorting
          )}`,
          method: "GET",
        }),
        transformResponse: (response: IntegrationPaginated, meta, args) => {
          const integrations = response.results.map(transformIntegration);

          return {
            ...response,
            results: integrations,
            current_page: args.page || 1,
            next_page: args.page + 1,
            has_more: response.total_pages > args.page,
          };
        },
        providesTags: ["integrations"],
      }),
      toggleIntegrationStatus: build.mutation<
        { status: string },
        { integrationId: number; status: "enable" | "disable" }
      >({
        query: ({ integrationId, status }) => ({
          url: `/v2/integration/${integrationId}/${status}/`,
          method: "PUT",
        }),
        invalidatesTags: ["integrations"],
      }),
      enablePollIntegration: build.mutation<
        { status: string },
        {
          templateId: number;
          integrationId: number;
        }
      >({
        query: ({ templateId, integrationId }) => ({
          url: `/api/v3/templates/${templateId}/integrations/${integrationId}/enable/`,
          method: "PUT",
        }),
        invalidatesTags: ["integrations"],
      }),
      deleteIntegration: build.mutation<
        { status: string },
        {
          integrationId: number;
        }
      >({
        query: ({ integrationId }) => ({
          url: `/v2/integration/${integrationId}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["integrations"],
      }),
    };
  },
});

export const {
  useGetIntegrationsQuery,
  useToggleIntegrationStatusMutation,
  useEnablePollIntegrationMutation,
  useDeleteIntegrationMutation,
} = useIntegrationsAPI;
