import http from "../../utils/http";

export const REQUEST_SETS = "REQUEST_SETS";

export const requestSets = (app_id) => ({
  type: REQUEST_SETS,
  app_id,
});

export const RECEIVE_SETS = "RECEIVE_SETS";

export const receiveSets = (app_id, json) => ({
  type: RECEIVE_SETS,
  app_id,
  sets: json,
});

export const fetchSets = (app_id) => {
  return (dispatch) => {
    dispatch(requestSets(app_id));

    return http.get(`/api/endpoint/sets/?app_id=${app_id}`).then(
      (response) => {
        dispatch(receiveSets(app_id, response.data.sets));
      },
      (error) => {
        console.log(
          "Error occurred while fetching sets in fetchSets() action.",
          error
        );
      }
    );
  };
};
