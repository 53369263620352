import { useState, useRef, useEffect } from "react";
import AutosizeInput from "react-input-autosize";

const ControlledAutosizeInput = ({
  initialValue,
  setLastInputRef,
  setFocusedRef,
  index,
  setTextFieldValueInTag,
  tagsLength,
  placeholder,
  setFocusClass,
  formikField,
  disableTagField,
  isCustomTag,
  type,
}) => {
  const [value, setValue] = useState(initialValue);
  const ref = useRef(null);
  const valueRef = useRef(value);

  useEffect(() => {
    return () => {
      valueRef.current !== initialValue &&
        setTextFieldValueInTag(index, valueRef.current, null, false, "blur");
    };
  }, []);

  useEffect(() => {
    if (valueRef.current !== value) valueRef.current = value;
  }, [value]);

  useEffect(() => {
    if (setLastInputRef) {
      setLastInputRef(ref);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsLength]);

  useEffect(() => {
    setValue(initialValue);
    try {
      formikField.form.validateField(formikField.field.name);
    } catch (e) {
      console.log(`Field ${formikField.field.name} not exist`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);
  return (
    <AutosizeInput
      value={value}
      ref={ref}
      autoComplete="off"
      data_index={index}
      type={type}
      className={`tag-pills-input ${
        index === 0 && tagsLength === 1 ? "auto-width" : ""
      }`}
      style={type === "time" || type === "date" ? { marginRight: 10 } : {}}
      placeholder={index === 0 && tagsLength === 1 ? placeholder : ""}
      onFocus={(event) => {
        setFocusClass("focused");
        setFocusedRef(ref);
      }}
      onChange={(event) => {
        setValue(event.target.value);
      }}
      onClick={(e) => {
        if (type !== "time" && type !== "date") {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
      onBlur={() => {
        setTextFieldValueInTag(index, value, ref, false, "blur");
        if (!isCustomTag) {
          setFocusClass("");
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          setTextFieldValueInTag(index, value, ref, true);
        }
      }}
      disabled={disableTagField}
    />
  );
};

export default ControlledAutosizeInput;
