// RTK hooks for flow testing
import { createApi } from "@reduxjs/toolkit/query/react";
import { integryBaseQuery } from "utils/http";
import config from "utils/config";

import { Completion } from "./flow-testing-interface";

export const flowTestingApi = createApi({
  reducerPath: "flow-testing",
  baseQuery: integryBaseQuery({
    baseUrl: config.apiBaseURL || "",
  }),
  tagTypes: ["flow-testing"],
  endpoints(build) {
    return {
      initiateTriggerTest: build.mutation({
        query: ({ triggerId, stepId, params }) => ({
          url: `/endpoints/test/trigger/${triggerId}?template_step_id=${stepId}&${params}`,
          method: "POST",
        }),
        invalidatesTags: ["flow-testing"],
      }),
      getIncomingWebhookHistory: build.query({
        query: ({ stepId, params }) => ({
          url: `/endpoints/test/trigger/webhook/${stepId}/history?${params}`,
          method: "GET",
        }),
        providesTags: ["flow-testing"],
      }),
      terminateTriggerTest: build.mutation({
        query: ({ triggerId, stepId, params }) => ({
          url: `/endpoints/test/trigger/${triggerId}/end?${params}&template_step_id=${stepId}`,
          method: "GET",
        }),
        invalidatesTags: ["flow-testing"],
      }),
      getApiCompletions: build.mutation<Completion, { prompt: string }>({
        query: ({ prompt }) => ({
          baseUrl: `https://us-central1-integry-app.cloudfunctions.net`,
          url: `/openai`,
          method: "POST",
          data: {
            prompt,
          },
        }),
      }),
    };
  },
});

export const {
  useInitiateTriggerTestMutation,
  useGetIncomingWebhookHistoryQuery,
  useTerminateTriggerTestMutation,
  useGetApiCompletionsMutation,
} = flowTestingApi;
