import { createApi } from "@reduxjs/toolkit/query/react";
import { integryBaseQuery } from "utils/http";
import { convertDateToUTCString } from "utils/date-utils";

import { hideNetworkCode, hideStatus } from "../utils";
import { RunStepsResponse } from "../interface";

const getExecutionSequenceNumberRange = (
  executionSequenceNumber: string
): {
  start: string;
  end: string;
} => {
  const [start = "", end = ""] = executionSequenceNumber.split("-");
  return { start, end };
};

export const runStepsAPI = createApi({
  reducerPath: "run-tasks",
  baseQuery: integryBaseQuery(),
  tagTypes: ["run-tasks"],
  endpoints(build) {
    return {
      getStepsOfRun: build.query<
        RunStepsResponse,
        {
          itnegration_id: string;
          run_id: string;
          page: number;
          stepStatus: string[];
          search?: string;
          executionSequenceNumber?: string;
          orderBy: string;
        }
      >({
        query: ({
          itnegration_id,
          run_id,
          page,
          search,
          stepStatus,
          executionSequenceNumber = "",
          orderBy = "",
        }) => {
          const { start, end } = getExecutionSequenceNumberRange(
            executionSequenceNumber
          );
          return {
            url: `/api/v1/integrations/${itnegration_id}/runs/${run_id}/steps/?search=${encodeURIComponent(
              search || ""
            )}&page=${page}&page_size=50&status=${stepStatus.join(
              ","
            )}&execution_sequence_number_range_start=${
              start || ""
            }&execution_sequence_number_range_end=${
              end || ""
            }&ordering=${orderBy}`, // &start_time_range_start=${runStartTime}&start_time_range_end=${runEndTime}
            method: "GET",
          };
        },
        transformResponse: (response: RunStepsResponse, meta, args) => {
          return {
            ...response,
            results: response.results.map((step) => ({
              ...step,
              start_time: convertDateToUTCString(step.start_time),
              network_code_label: hideNetworkCode(step)
                ? "N/A"
                : `${step.network_code}`,
              status: hideStatus(step) ? "N/A" : step.status,
              network_code_description: hideNetworkCode(step)
                ? "N/A"
                : step.network_code_description,
            })),
            current_page: args.page || 1,
            next_page: args.page + 1,
            has_more: response.total_pages > args.page,
          };
        },
        serializeQueryArgs: ({ endpointName, queryArgs }) => {
          return `${endpointName}-${queryArgs.run_id}`;
        },
        // Always merge incoming data to the cache entry
        merge: (currentCache, newItems, { arg }) => {
          if (arg.page === 1) {
            return newItems;
          }
          return {
            ...newItems,
            results: [...currentCache.results, ...newItems.results],
          };
        },
        // Refetch when the page arg changes
        forceRefetch({ currentArg, previousArg }) {
          return currentArg !== previousArg;
        },
      }),
      getStepChildren: build.query<
        RunStepsResponse,
        {
          itnegration_id: string;
          run_id: string;
          step_id?: string;
          page: number;
        }
      >({
        query: ({ itnegration_id, run_id, step_id, page }) => ({
          url: `/api/v1/integrations/${itnegration_id}/runs/${run_id}/steps/${step_id}/children/?page=${page}`,
          method: "GET",
        }),
        transformResponse: (response: RunStepsResponse, meta, args) => {
          return {
            ...response,
            current_page: args.page || 1,
            next_page: args.page + 1,
            has_more: response.total_pages > args.page,
          };
        },
        serializeQueryArgs: ({ endpointName, queryArgs }) => {
          return `${endpointName}-${queryArgs.step_id}`;
        },
        // Always merge incoming data to the cache entry
        merge: (currentCache, newItems, { arg }) => {
          if (arg.page === 1) {
            return newItems;
          }
          return {
            ...newItems,
            results: [...currentCache.results, ...newItems.results],
          };
        },
        // Refetch when the page arg changes
        forceRefetch({ currentArg, previousArg }) {
          return currentArg !== previousArg;
        },
      }),
    };
  },
});

export const { useGetStepsOfRunQuery, useGetStepChildrenQuery } = runStepsAPI;
