/* eslint-disable react/require-default-props */
import "./styles.scss";

import React, { forwardRef } from "react";
import PropTypes from "prop-types";

// VARIATIONS
// variation-1: 9px;
// variation-2: 12px;
// variation-3: 13px;
// variation-4: 14px;
// variation-5: 16px;
// variation-6: 22px;
// variation-7: 26px;

// FONT WEIGHTS
// light: 300;
// regular: 400;
// semibold: 500;
// bold: 600;
// extrabold: 700;

const Typography: React.FC<{
  variation?: "1" | "2" | "3" | "4" | "5" | "6" | "7";
  weight?: "light" | "regular" | "semibold" | "bold" | "extrabold";
  center?: boolean;
  color?: string;
  className?: string;
}> = forwardRef(
  (
    {
      children,
      variation = "2",
      weight = "regular",
      center = false,
      color = "",
      className = "",
      ...rest
    },
    ref: React.Ref<HTMLDivElement> | null
  ) => {
    return (
      <div className={`reusable-typography-wrapper ${className}`}>
        <span
          className={`typography variation-${variation} ${weight} ${
            center ? "center" : ""
          } ${className}`}
          style={color ? { color } : {}}
          {...rest}
          ref={ref}
        >
          {children}
        </span>
      </div>
    );
  }
);

Typography.displayName = "Typography";
Typography.propTypes = {
  variation: PropTypes.oneOf(["1", "2", "3", "4", "5", "6"]),
  weight: PropTypes.oneOf([
    "light",
    "regular",
    "semibold",
    "bold",
    "extrabold",
  ]),
  center: PropTypes.bool,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Typography;
