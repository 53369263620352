import { ReactElement } from "react";
import { toast } from "react-toastify";
import redCrossIcon from "images/red-cross-icon.svg";
import { convertDateToLocal } from "utils/functions";

export const templateErrorMessage = (
  title: string,
  msg: string
): ReactElement => {
  return (
    <div>
      <div className="lable error">{title}</div>
      <div className="message">{msg}</div>
    </div>
  );
};

const customCloseButton = (): ReactElement => {
  return <img className="custom-close-toast" src={redCrossIcon} alt="Close" />;
};

export const renderApiError = (title: string, msg: string): void => {
  toast.error(templateErrorMessage(title, msg), {
    autoClose: 5000,
    position: "bottom-left",
    closeButton: customCloseButton(),
  });
};

export const getIntegrationStatus = (
  integrationStatus: string,
  errorCode: number
): string => {
  return integrationStatus;
  /*  if (errorCode === 401 || errorCode === 403) {
    return "AUTH_MISSING";
  }
  if (errorCode >= 400 && errorCode <= 499) {
    return "4xx_errors";
  }
  if (errorCode >= 500 && errorCode <= 599) {
    return "5xx_errors";
  }
  return integrationStatus; */
};

export const getIntegrationTooltip = (
  integrationStatus: string,
  app_name: string | undefined,
  timestamp: string | undefined,
  lastUpdated?: string | undefined,
  last_modified_by?: any
): string => {
  const { user_name } = last_modified_by || {};
  const convertedTime = convertDateToLocal(timestamp);
  const lastUpdateTime = lastUpdated ? convertDateToLocal(lastUpdated) : "";
  // eslint-disable-next-line no-unneeded-ternary
  const isBlocked = BLOCKED_STATUS_TYPES[integrationStatus] ? true : false;
  const lastUpdatedByString = lastUpdateTime
    ? `Changed on ${lastUpdateTime}${
        last_modified_by ? ` by ${isBlocked ? "Integry" : user_name}` : ""
      }`
    : "";
  const integrationStatusTooltips = {
    AUTH_MISSING: `Authentication failed for ${app_name} on ${convertedTime}. User needs to re-authenticate their account and enable the integration.`,
    auth_missing: `Authentication failed for ${app_name} on ${convertedTime}. User needs to re-authenticate their account and enable the integration.`,
    PAUSED: "User limit is reached.",
    paused: "User limit is reached.",
    "5xx_errors": `Too many server errors from ${app_name} since ${convertedTime} You can try enabling the integration. If the issue persists, please contact Integry support.`,
    "4xx_errors": `Too many errors from ${app_name} since ${convertedTime} due to a configuration issue. User needs to create a new integration.`,
  };
  if (integrationStatus === "AUTH_MISSING" && (!app_name || !convertedTime)) {
    return `Authentication failed. User needs to re-authenticate their account and enable the integration.\n ${lastUpdatedByString}`;
  }
  return (
    `${
      integrationStatusTooltips[integrationStatus]
        ? `${integrationStatusTooltips[integrationStatus]}\n`
        : ""
    }${lastUpdatedByString}` || "N/A"
  );
};

const BLOCKED_STATUS_TYPES = {
  PAUSED: "Blocked",
  Paused: "Blocked",
  paused: "Blocked",
  AUTH_MISSING: "Blocked",
  auth_missing: "Blocked",
  "5xx_errors": "Blocked",
  "4xx_errors": "Blocked",
  blocked: "Blocked",
  Blocked: "Blocked",
  BLOCKED: "Blocked",
};

export const getViewPayloadButtonLabel = (activityType: string): string => {
  return activityType === "CODE" ? "View Code" : "View Payload";
};

export const getHidePayloadButtonLabel = (activityType: string) => {
  return activityType === "CODE" ? "Hide Code" : "Hide Payload";
};
