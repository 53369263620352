/* eslint-disable import/prefer-default-export */
import config from "utils/config";
import startsWith from "lodash/startsWith";
import { RunSteps } from "../interface";

export const hideNetworkCode = (run: RunSteps): boolean => {
  const hideStuts = [
    "CONDITION",
    "DO_WHILE_LOOP",
    "CODE",
    "SETUP",
    "SCHEDULE",
    "SYSTEM_ACTIVITY",
    "WAIT",
    "ABORT",
    "VARIABLE",
  ];
  if (run.activity_id === config.abortStep.activityId && run.network_code) {
    return false;
  }

  return (
    !run.network_code ||
    hideStuts.some((status) => startsWith(run.activity_type, status))
  );
};

export const hideStatus = (run: RunSteps): boolean => {
  const hideStuts = [
    "DO_WHILE_LOOP",
    "SETUP",
    "SCHEDULE",
    "SYSTEM_ACTIVITY",
    "WAIT",
    "ABORT",
    "VARIABLE",
    "CONDITION_ELSE",
  ];
  return hideStuts.some((status) => run.activity_type === status);
};
