import { Fragment, ReactElement, useState } from "react";
import { useFormikContext } from "formik";
import CommonIntegryModal from "components/common/common-integry-modal";
import { toast } from "react-toastify";
import useDebounced from "hooks/useCancleableDebounce";
import TextFieldWithTitle from "components/forms/v4/fields-with-title-and-description";

import { uniqueId } from "lodash-es";

import MeatballsMenu, {
  IMenuItems,
} from "components/integry-design-system/molecules/meatballs-menu";

import deleteIcon from "images/delete_bin_red.svg";
import deletePopupIcon from "images/delete-tag.svg";

import {
  useCreateWorkspaceVariableMutation,
  useUpdateWorkspaceVariableMutation,
  useDeleteWorkspaceVariableMutation,
} from "../../api/useWorkspaceVariables";
import { WorkspaceVariable } from "../../interface";

import { validateKey } from "../../utils/validations";

const WorkspaceVariableRow = ({
  workspaceVariable,
  fieldName,
  isLast,
}: {
  workspaceVariable: WorkspaceVariable;
  fieldName: string;
  isLast: boolean;
}): ReactElement => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { values, getFieldMeta, setFieldValue } = useFormikContext<{
    variables: WorkspaceVariable[];
  }>();

  const [createWorkspaceVariable] = useCreateWorkspaceVariableMutation();
  const [updateWorkspaceVariable] = useUpdateWorkspaceVariableMutation();
  const [deleteWorkspaceVariable] = useDeleteWorkspaceVariableMutation();

  const option: IMenuItems = {
    label: "Delete",
    value: "delete",
    iconPrefix: deleteIcon,
    className: "delete",
    disabled: isLast,
    tooltip: "",
    tooltipPlacement: "bottom",
  };

  const onTagChange = async (variable: WorkspaceVariable): Promise<void> => {
    const { id, key, value, isNew } = variable;

    if (!key) {
      return;
    }
    if (isNew) {
      try {
        const newVariable = await createWorkspaceVariable({
          key,
          value,
        }).unwrap();

        if (newVariable) {
          setFieldValue(`${fieldName}.id`, newVariable.id);
          toast.success("Workspace variable created successfully");
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      updateWorkspaceVariable({
        id,
        key,
        value,
      });
    }
  };

  const [debouncedOnTagChange, cancleChange] = useDebounced(onTagChange, 300);

  return (
    <Fragment key={workspaceVariable.id}>
      <div
        className={`tag-row member-row ${isLast ? "last" : ""}`}
        key={workspaceVariable.id}
      >
        <div className="tag-col">
          <TextFieldWithTitle
            name={`${fieldName}.key`}
            placeholder="Key"
            control="input"
            validate={(input: string) =>
              validateKey(input, values.variables, !workspaceVariable.isNew)
            }
            validateOnChange
            onBlur={(value: string) => {
              const validationError = validateKey(value, values.variables);
              if (!validationError) {
                debouncedOnTagChange(workspaceVariable);
              }
            }}
            onChange={(key) => {
              // When the key is changed of last variable, add new variable
              setTimeout(() => {
                const variables = getFieldMeta("variables")
                  .value as WorkspaceVariable[];
                if (isLast) {
                  setFieldValue("variables", [
                    ...variables.slice(0, variables.length - 1),
                    { ...workspaceVariable, key },
                    {
                      id: uniqueId("workspaceVariable_"),
                      key: "",
                      value: "",
                      isNew: true,
                    },
                  ]);
                }
              }, 10);
            }}
          />
        </div>
        <div className="tag-col">
          <TextFieldWithTitle
            name={`${fieldName}.value`}
            placeholder="Value"
            control="input"
            onFocus={() => {
              cancleChange();
            }}
            onBlur={() => {
              const validationError = validateKey(
                workspaceVariable.key,
                values.variables
              );
              if (!validationError) {
                debouncedOnTagChange(workspaceVariable);
              }
            }}
          />
        </div>
        <div className="tag-col">
          <MeatballsMenu
            items={[option]}
            onSelect={() => {
              // delete new variable directly~
              if (workspaceVariable.isNew) {
                const newVariables = values.variables.filter(
                  (v) => v.id !== workspaceVariable.id
                );
                setFieldValue("variables", newVariables);
              } else {
                setShowDeleteModal(true);
              }
            }}
            customStyle={{ marginLeft: "5px" }}
          />
        </div>
      </div>

      <CommonIntegryModal
        show={showDeleteModal}
        close={() => {
          setShowDeleteModal(false);
        }}
        title={`Delete ‘${workspaceVariable.key}’`}
        message={`Deleting the ‘${workspaceVariable.key}’ variable will cause any flows that use it to stop working. \n\nAre you sure?`}
        icon={deletePopupIcon}
        action={() => {
          const newVariables = values.variables.filter(
            (v) => v.id !== workspaceVariable.id
          );

          setFieldValue("variables", newVariables);
          deleteWorkspaceVariable({
            id: workspaceVariable.id,
          });
          setShowDeleteModal(false);
        }}
        actionButtonTitle="Delete"
        modalClass="remove-user-modal"
      />
    </Fragment>
  );
};

export default WorkspaceVariableRow;
